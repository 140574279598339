<template>
  <div>
    <modal :name="modal_brand" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">Edit Other Payment Mode</span>
        </div>
        <div class="v-modal-body">
          <div class="v-modal-layout">
            <form>
              <div class="form-group mt-0 contactGroup">
                <input
                  @input="exists=false"
                  v-model.trim="details.name"
                  class="inputContact"
                  type="text"
                  name="name"
                  v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                  autofocus
                  :maxlength="paymentmode"
                />
                <span class="highlightContact"></span>
                <span class="barContact"></span>
                <label class="labelContact">
                  Other Payment Mode Name
                  <sup>★</sup>
                </label>
                <span
                  class="invalid-feedback-form"
                  v-show="errors.has('name')"
                  style="display:contents;"
                >{{ errors.first('name') }}</span>
                <p
                  class="pull-right text-right text-grey font-600 fs-12 pt-1"
                >{{paymentmode - details.name.length}} / 30</p>
                <div v-show="exists" class="text-danger fs-13">Payment Mode Exists</div>
              </div>
              <div class="row">
                <div class="col-lg-12 pt-3">
                  <label class="text-secondary pr-2">Is Other Payment Modes Available ?</label>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        v-model="details.status"
                        type="radio"
                        class="form-check-input"
                        name="optionsRadios"
                        value="true"
                        id="optionsRadios2"
                      />
                      <span class="radio-icon"></span>
                      <span>Yes</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input
                        v-model="details.status"
                        type="radio"
                        class="form-check-input"
                        name="optionsRadios"
                        value="false"
                        id="optionsRadios2"
                      />
                      <span class="radio-icon"></span>
                      <span>No</span>
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <div class="v-modal-footer text-center pt-5">
              <button
                class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
                @click="editNewPayment()" :disabled="edit_new_payment_api_inprogress"
              >CANCEL</button>
              <button
                class="btn btn-secondary btn-smm text-uppercase ml-5" :disabled="edit_new_payment_api_inprogress"
                @click="CloseeditAddPaymentMode()"
              >SAVE
                  <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_new_payment_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import VModal from 'vue-js-modal'
import { EventBus } from './eventBus/nav-bar-event'
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: ['modal_brand'],
  data() {
    return {
      paymentmode: 30,
      exists: false,
      details: {
        name:'',
        status:''
      },
      edit_new_payment_api_inprogress: false
    }
  },
  components: {
    VModal,
    HalfCircleSpinner
  },
  methods: {
    editNewPayment() {
      this.$modal.hide('edit-payment-method')
      this.exists = false
    },
    CloseeditAddPaymentMode() {
      this.edit_new_payment_api_inprogress = true;
      this.$http
        .post('/outlet/update_payment_method', this.details)
        .then(res => {
          if (res.data.status_id == 1) {
            this.$swal(
              'Updated',
              'Successfully Updated Payment Mode',
              'success'
            )
            this.$emit('editPayment', true)
            this.$modal.hide('edit-payment-method')
            this.exists = false
          } else {
            this.exists = true
          }
          this.edit_new_payment_api_inprogress = false;
        }).catch((err) => {
          this.edit_new_payment_api_inprogress = false;
        })
    },
    getPaymentById(payment_id) {
      this.$http
        .post('outlet/get_payment_method_by_id', {
          payment_id: payment_id
        })
        .then(res => {
          if (res.data.status_id) {
            this.details = res.data.response
          }
        })
    }
  },
  created() {
    const dict = {
      custom: {
        name: {
          required: () => 'Payment Mode is Required',
          regex: () => 'Enter Valid Payment Mode'
        }
      }
    }
    this.$validator.localize('en', dict)
  },
  mounted() {
    EventBus.$on('editPayment', payment_id => {
      this.getPaymentById(payment_id)
    })
  }
}
</script>
