<template>
    <div class="page-layout blank">
		<div class="pb-10">
            <div class="tabs-promotion text-center">
                <span>
                <button class="btn general_promotion" @click="GeneralComponetActive()" :class="{'general_promotion_active': GeneralActiveTag }">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/megaphone_general.svg" v-if="GeneralActiveTag">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/megaphone_blue.svg" v-if="!GeneralActiveTag"> <br> General Promotions
                </button>
                <button class="btn vochers_promotion" @click="CouponComponentsActive()" :class="{'vochers_promotion_active': VochersActiveTag}">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/coupon_vochers.svg" v-if="!VochersActiveTag">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vochers_white.svg" v-if="VochersActiveTag"><br>
                    Vouchers/Coupons
                </button>
                <button class="btn customer_promotion" @click="CustomerPromotions()" :class="{'customer_promotion_active': CustomerActiveTag }">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customer_promotion.svg" v-if="!CustomerActiveTag">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customer-pro-white.svg" v-if="CustomerActiveTag"><br>
                    Customer Based Promotions
                </button>
                </span>
            </div>
			<general-promotion v-if="GeneralActiveTag"></general-promotion>
            <vochers-promotion v-if="VochersActiveTag"></vochers-promotion>
            <customer-promotion v-if="CustomerActiveTag"></customer-promotion>
		</div>
    </div>
</template>
<script>
import GeneralPromotion from "./GeneralPromotion";
import VochersPromotion from "./VochersPromotion";
import CustomerPromotion from "./CustomerPromotion";
import CreateGeneralPromotion from "./CreateGeneralPromotion";
export default {
    data() {
			return {
                GeneralActiveTag : true,
                VochersActiveTag: false,
                CustomerActiveTag:false
			};
		},
methods: {
    GeneralComponetActive() {
        this.GeneralActiveTag = true,
        this.VochersActiveTag = false,
        this.CustomerActiveTag = false
    },
    CouponComponentsActive() {
        this.GeneralActiveTag = false,
        this.VochersActiveTag = true,
        this.CustomerActiveTag = false
    },
    CustomerPromotions() {
        this.GeneralActiveTag = false,
        this.VochersActiveTag = false,
        this.CustomerActiveTag = true
    }
},
    components: {
        GeneralPromotion,
        VochersPromotion,
        CustomerPromotion,
        CreateGeneralPromotion
    }
}
</script>

<style>
.tabs-promotion{
    margin-top: 30px;
}
.btn.general_promotion{
    width: 232px;
    margin-right: -3px;
    height: 81px;
    font-weight: 600;
    text-transform: capitalize!important;
    background-color: #fff;
    color: #00448b;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
    border: 1px solid #00448b!important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.btn.vochers_promotion{
   width: 232px;
    height: 81px;
    font-weight: 600;
    text-transform: capitalize!important;
  background-color: #fff;
  color: #00448b;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
  border: 1px solid #00448b!important;
  border-left: 0px!important;
    border-right: 0px!important;
    margin-right: -4px;
}
.btn.customer_promotion{
   width: 232px;
    height: 81px;
    font-weight: 600;
    text-transform: capitalize!important;
  background-color: #fff;
  color: #00448b;
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
  border: 1px solid #00448b!important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.btn.general_promotion.general_promotion_active, .btn.vochers_promotion.vochers_promotion_active, .btn.customer_promotion.customer_promotion_active{
    text-transform: capitalize!important;
    background-color: #00448b!important;
    color: #fff!important;
}
</style>
