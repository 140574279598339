<template>
     <div class="page-layout blank">
		<div class="pb-10">
			<h2 class="headerblock">
				<div class="row">
					<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
						<div class="list-title" style="font-weight: 500;">
							
						</div>
					</div>
					<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
						<div class="breadcome-heading  d-xl-inline">
							<div class="form-group formsearch has-warning has-feedback">
								<input type="text" class="form-control textremove holder" placeholder="Search...">
								<!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" v-on:click=" searchkey = '',start = 1,skip = 0, filterProducts()"></span> -->
								<span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
							</div>
						</div>
						<el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
							<a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)" style="margin-top: 2px;padding-top: 2px;">
								<i class="icons icon-arrow-left"></i>
							</a>
						</el-tooltip>
					</div>
				</div>
			</h2>
            <div class="page-layout carded left-sidebar">
				<div class="page-content-wrapper">
					<div class="page-content">
						<div class="content-settings">
							<div class="create-heading">Create Open-Menu</div>
							<div class="row mt-4 pl-2">
								<div class="col-md-12" style="display: inherit;">
									<div class="col-md-6">
										<div class="group-setup mb-4 mt-4">
											<input class="digi-form" v-model="openMenu.name" type="text" v-validate="'required'" name="outletName"  required>
											<span class="highlight digi-highlight"></span>
											<span class="bar digi-bar"></span>
											<label class="digi-label">Open - Menu Name<sup>★</sup></label>
											<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
											<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
										</div>
									</div>
									<div class="col-md-6">
										<div class="group-setup mb-4 mt-4">
											<input class="digi-form" v-model="openMenu.description" type="text" v-validate="'required'" name="outletName"  required>
											<span class="highlight digi-highlight"></span>
											<span class="bar digi-bar"></span>
											<label class="digi-label">Open - Menu Description<sup>★</sup></label>
											<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
											<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-4 pl-2">
								<div class="col-md-12" style="display: inherit;">
									<div class="col-md-6">
										 <label class="text-secondary pr-2 fw-600 pb-1">Open-Menu Type : </label><br>
										<div class="form-check form-check-inline">
											<label class="form-check-label">
												<input v-model="openMenu.type" type="radio" class="form-check-input" name="optionsRadios"
														id="optionsRadios2" value="food"/>
												<span class="radio-icon"></span>
												<span>Food</span>
											</label>
										</div>
										<div class="form-check form-check-inline">
											<label class="form-check-label">
												<input v-model="openMenu.type" type="radio" class="form-check-input" name="optionsRadios"
													id="optionsRadios2" value="beverage"/>
												<span class="radio-icon"></span>
												<span>Bevarage</span>
											</label>
										</div>
										<div class="form-check form-check-inline">
											<label class="form-check-label">
												<input v-model="openMenu.type" type="radio" class="form-check-input" name="optionsRadios"
													id="optionsRadios2" value="both">
												<span class="radio-icon"></span>
												<span>Both</span>
											</label>
										</div>
									</div>
									<div class="col-md-6">
										<div class="group-setup mb-4 mt-4">
											<input v-model="openMenu.quantity" class="digi-form" type="text" v-validate="'required'" name="outletName"  required>
											<span class="highlight digi-highlight"></span>
											<span class="bar digi-bar"></span>
											<label class="digi-label">Open-Menu Quantity<sup>★</sup></label>
											<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
											<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
										</div>
									</div>
								</div>
							</div>
							<div v-for="(val, index) in openMenu.products" :key="index">
								<div class="row mt-4 pl-2">
									<div class="col-md-12" style="display: inherit;">
										<div class="col-md-6">
											<div class="group-setup mb-4 mt-4">
												<label style="color:#00448b;">Product<sup>★</sup></label>
												<v-select v-model="openMenu.products[index].product_id" :options="products" label="product_name" ></v-select>
												<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
												<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
											</div>
										</div>
										<div class="col-md-6">
											<div class="group-setup mb-4 mt-4">
												
												<label style="color:#00448b;">Variant<sup>★</sup></label>
												<v-select v-model="openMenu.products[index].variant_id" :options="variants" label="variant_name"></v-select>
												<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
												<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
											</div>
										</div>
									</div>
								</div>
								<div class="row mt-4 pl-2">
									<div class="col-md-12" style="display: inherit;">
										<div class="col-md-6">
											<div class="group-setup mb-4 mt-4">
												<input class="digi-form" type="text" v-validate="'required'" name="outletName"  required>
												<span class="highlight digi-highlight"></span>
												<span class="bar digi-bar"></span>
												<label class="digi-label">Quantity<sup>★</sup></label>
												<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
												<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
											</div>
										</div>
										<div class="col-md-6">
											<div class="group-setup mb-4 mt-4">
												<input class="digi-form" type="text" v-validate="'required'" name="outletName"  required>
												<span class="highlight digi-highlight"></span>
												<span class="bar digi-bar"></span>
												<label class="digi-label">Unit Price<sup>★</sup></label>
												<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
												<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row col-md-12">
								<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
									<div class="list-title" style="font-weight: 500;">
										<button class="btn btn-secondary btn-smm" @click="addProducts(openMenu.products.variant_id,openMenu.products.product_id)">+ Add New Product</button>
									</div>
								</div>
							</div>
							<div class="row mt-4 pl-2">
								<div class="col-md-12" style="display: inherit;">
									<div class="col-md-6">
										<div class="group-setup mb-4 mt-4">
											<input class="digi-form" type="text" v-validate="'required'" name="outletName"  required>
											<span class="highlight digi-highlight"></span>
											<span class="bar digi-bar"></span>
											<label class="digi-label">Estimated Unit Price<sup>★</sup></label>
											<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
											<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
										</div>
									</div>
									<div class="col-md-6">
										<div class="group-setup mb-4 mt-4">
											<input class="digi-form" type="text" v-validate="'required'" name="outletName"  required>
											<span class="highlight digi-highlight"></span>
											<span class="bar digi-bar"></span>
											<label class="digi-label">Selling Price<sup>★</sup></label>
											<!-- <span class="text-danger fs-13" v-show="errors.has('outletName') && ifSubmitted">Outlet Name is Required</span>
											<span class="text-danger fs-13" v-if="false">Duplicate Outlet Name</span> -->
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-4 pl-2">
								<div class="col-md-12">
									<el-switch
										v-model="value1"
										inactive-text="Available For Menu : ">
									</el-switch>
								</div>
							</div>
							 <div class="row pt-6 text-center" style="margin-top: 20px">
								<div class="col-lg-12">
									<div class="list-title" style="font-weight: 500;">
										<button type="button" class="btn btn-link" @click="$router.go(-1)">Cancel</button>
										<a class="btn btn-secondary btn-smm" style="text-transform:uppercase!important;">Save </a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
     </div>
</template>
<style>
.content-settings {
    max-width: 960px;
    width: 100%;
    margin: 0 auto 20px;
}
</style>
<script>
export default {
	data(){
		return {
			openMenu:{
				name:'',
				description:'',
				type:'both',
				quantity:0,
				// products:[],
				estimated_price:0,
				selling_price:0,
				products:[{
					product_id:'',
					product_quantity:0,
					product_price:0,
					variant_id:'',
					product_name:'',
					variant_name:''
				}]
			},
			products:[],
			product_name:'',
			variant_name:'',
			product_quantity:0,
			product_price:0,
			variants:[],
			value1:""

		}
	},
	methods:{
		getProductsForOpenMenu(){
			this.$http.post("/products/get_products_for_open_menu",{
				searchdata:''
			}).then(res => {
				if (res.data.status_id == 1){
					this.products = res.data.response
				}else{
					this.products = []
				}
			})
		},
		addProducts(variant,products) {}
	},
	created(){
		this.getProductsForOpenMenu()
	},
	watch:{
		product_name(){
			this.variants = this.product_name.variants
		}
	}
}
</script>