<template>
    <div class="page-layout blank">
        <div class="pb-5 pt-5">
            <div class="w-100 page-border">
                <div>
                    <table class="table border-collapse">
                        <thead>
                            <tr>
                                <th class="text-center">S.No</th>
                                <th class="text-center">Printer Name</th>
                                <th class="text-center">Category</th>
                                <th class="text-center">Receipts</th>
                                <th class="text-center">Invoice</th>
                                <th class="text-center">No. of Copies</th>
                            </tr>
                        </thead>
                        <tbody v-if="ajaxCallInProgress">
                            <tr>
                                <td colspan="6" class="text-center justify-content-center">
                                    <div class="digi-table-loader">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="ajaxCallInProgress == false">
                            <tr v-for="(printer, indx) in AllPrinters" :key="indx">
                                <td class="text-center">{{indx+1}}</td>
                                <td class="text-center">{{printer.printer_name}}</td>
                                <td class="text-center" style="width:400px">
                                    <div v-for="(group, group_index) in printer.category_ids" :key="group_index">
                                        <div class="d-flex align-items-end">
                                            <multiselect v-model="printer.category_ids[group_index]"
                                                class="digi-multiselect category-tag category-multiple-addon mt-1"
                                                open-direction="bottom" :max-height="200" label="name"
                                                :loading="category_loading" placeholder="Search Printer Categories"
                                                track-by="category_id" @open="fetchCategoryDropdown"
                                                :options="FilteredCategories" :multiple="true"
                                                @search-change="searchPrinterCategory">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">Printer
                                                        Categories Not Found</div>
                                                </template>
                                                <template slot="noResult">
													<div class="text-secondary text-center fw-600">No Results Found
													</div>
												</template>
                                                <template slot="afterList">
                                                    <div v-observe-visibility="reachedEndOfCategoryOptions" />
                                                </template>
                                            </multiselect>
                                            <i class="icon icon-minus-circle text-danger pointer"
                                                v-if="printer.category_ids.length > 1"
                                                @click="RemoveGroup(printer.category_ids, printer.no_of_copies, group_index)"></i>
                                            <i class="icon icon-plus-circle text-success pointer"
                                                v-if="printer.category_ids.length == group_index+1"
                                                @click="AddGroup(printer.category_ids, printer.no_of_copies)"></i>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <el-checkbox class="printer-checkbox" v-model="printer.receipt">
                                    </el-checkbox>
                                </td>
                                <td class="text-center">
                                    <el-checkbox class="printer-checkbox" v-model="printer.invoice">
                                    </el-checkbox>
                                </td>
                                <td class="text-center">
                                    <div style="height:38px" class="d-flex justify-content-center align-items-end" v-for="(copy, copy_index) in printer.no_of_copies" :key="copy_index">
                                        <input type="number" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model="printer.no_of_copies[copy_index]"
                                            placeholder="Enter No. of Copies" class="copies-field">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-center align-items-center pb-2 mt-3">
                    <button class="btn btn-success text-white text-capitalize pl-10 pr-10"
                        style="border-radius: 20px !important" @click="UpdatePrinterConfig">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>

<script>
    import {
        SweetModal
    } from 'sweet-modal-vue'
    import printerAPI from './mixins/printerAPI'
    export default ({
        mixins: [printerAPI],
        data() {
            return {
                category_options: [],
                AllPrinters: [],
                gathered_ids: [],
                modal_msg: '',
                ajaxCallInProgress: false,
                counter: 0,

                // lazy loading
                category_reached: false,
                category_scrollable: false,
                category_loading: false,
                category_count: 0,
                temp_category: [],
                search_printer_key: ''
            }
        },
        components: {
            SweetModal,
        },
        methods: {
            async searchPrinterCategory(category) {
                this.search_printer_key = category
                let params = {
                    search_key: this.search_printer_key,
                    skip: 0,
                    limit: 50,
                    outlet_id: this.loggedInUser.outlet_id
                }
                let response = await this.PrinterDropdown(params)
                this.category_options = response.response
                this.category_options = this.category_options.filter(v => !(this.gathered_ids.some(e => e
                    .category_id === v.category_id)))
                const find_dup_categories = this.category_options.map(o => o.name)
                const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                this.category_options = filteredupcategory
                this.category_count = this.category_options.length
                this.category_scrollable = false,
                    this.category_loading = false
            },
            async reachedEndOfCategoryOptions(reached) {
                console.log(reached)
                // this.category_reached = reached
                if (reached) {
                    if (this.category_scrollable == false) {
                        this.category_loading = true
                        let response = await this.PrinterDropdown({
                            skip: this.category_count,
                            limit: 50,
                            search_key: this.search_printer_key,
                            outlet_id: this.loggedInUser.outlet_id
                        })
                        this.temp_category = response.response.length
                        if (response.skip == this.category_count) {
                            if (this.temp_category > 0) {
                                let tempFiles = response.response;
                                tempFiles = tempFiles.filter(v => !(this.gathered_ids.some(e => e
                                    .category_id === v.category_id)))
                                tempFiles.forEach(el => {
                                    this.category_options.push(el)
                                });
                                const find_dup_categories = this.category_options.map(o => o.name)
                                const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                                this.category_options = filteredupcategory
                                this.category_count += response.response.length;
                                this.category_loading = false
                            } else {
                                this.category_scrollable = true
                                this.category_loading = false
                            }
                        }
                        this.category_loading = false
                    }
                }
            },
            async printersList() {
                this.ajaxCallInProgress = true
                try {
                    let params = {
                        outlet_id: this.loggedInUser.outlet_id,
                        printer_selection: true
                    }
                    let response = await this.managePrinters(params)
                    this.AllPrinters = response.response.all_printers
                    this.ajaxCallInProgress = false
                    this.AllPrinters.forEach((p, i) => {
                        if (p.category_ids.length == 0) {
                            p.category_ids.push([])
                        }
                        if (p.no_of_copies.length == 0) {
                             p.no_of_copies.push(0)
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            },
            async UpdatePrinterConfig() {
                localStorage.setItem('printer_data', JSON.stringify(this.createJson));
                var printer_data = JSON.parse(localStorage.getItem('printer_data'));
                printer_data.all_printers.forEach((print, print_index) => {
                    print.category_ids.forEach((category, category_index) => {
                        category.forEach((c, ci) => {
                            category.push(c.category_id)
                        })
                    })
                    delete print.printer_name
                })
                printer_data.all_printers.forEach((print, print_index) => {
                    print.category_ids.forEach((category, category_index) => {
                        category.splice(0, Math.abs(category.length) / 2)
                    })
                })

                // Removal of duplicate fields

                // printer_data.all_printers.forEach((print, print_index) => {
                //     this.counter = 0
                //     print.category_ids.forEach((category, category_index) => {
                //         if(category.length == 0) {
                //             print.no_of_copies.splice(category_index-this.counter, 1)
                //             this.counter += 1
                //         }
                //     })
                // })
                // printer_data.all_printers.forEach((print, print_index) => {
                //     print.category_ids = print.category_ids.filter((el) => el != el.length)
                // })
                // console.log(printer_data.all_printers)
                this.callUpdatePrinterConfigAPI(printer_data)
            },
            async callUpdatePrinterConfigAPI(printer_data) {
                var response = await this.UpdatePrinter(printer_data)
                if(response.status_id == 1){
                    this.modal_msg = response.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.printersList()
                    }, 2000)
                } else if(response.status_id == 0) {
                    this.modal_msg = 'Please Enter All The Fields'
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.printersList()
                    }, 2000)
                }
            },
            async fetchCategoryDropdown() {
                this.gathered_ids = []
                this.category_options = []
                try {
                    let params = {
                        skip: 0,
                        limit: 50,
                        search_key: "",
                        outlet_id: this.loggedInUser.outlet_id
                    }
                    let response = await this.PrinterDropdown(params)
                    this.category_options = response.response
                    const find_dup_categories = this.category_options.map(o => o.name)
                    const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                    this.category_options = filteredupcategory
                    this.category_count = response.response.length
                    // this.AllPrinters.forEach((p, i) => {
                    //     p.category_ids.forEach((c, j) => {
                    //         c.forEach((g, k) => {
                    //             this.gathered_ids.push(g)
                    //         })
                    //     })
                    // })
                    // this.category_options = this.category_options.filter(v => !(this.gathered_ids.some(
                    //     e => e.category_id === v.category_id)))
                } catch (error) {
                    console.log(error)
                }
            },
            AddGroup(arr, copies) {
                arr.push([])
                copies.push([])
            },
            RemoveGroup(arr, copies, index) {
                arr.splice(index, 1)
                copies.splice(index, 1)
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            createJson() {
                return {
                    all_printers: this.AllPrinters
                }
            },
            FilteredCategories: function () {
                this.AllPrinters.forEach((p, i) => {
                    p.category_ids.forEach((c, j) => {
                        c.forEach((g, k) => {
                            this.gathered_ids.push(g)
                        })
                    })
                })
                const find_dup_categories = this.category_options.map(o => o.name)
                const filteredupcategory = this.category_options.filter(({name}, index) => !find_dup_categories.includes(name, index + 1))
                this.category_options = filteredupcategory
                return this.category_options = this.category_options.filter(v => !(this.gathered_ids.some(
                        e => e.category_id === v.category_id)))
            }
        },
        mounted() {
            this.printersList()
        }
    })
</script>

<style scoped>
    .page-border {
        border: 2px solid #00448b !important;
        border-radius: 25px;
    }

    .table thead th {
        border-bottom: none !important;
        font-weight: bold;
        font-size: 13px !important;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #00448b;
        text-transform: uppercase;
        vertical-align: middle;
    }

    .copies-field {
        width: 130px;
        border: 1px solid #c6c7c9 !important
    }

    .copies-field::placeholder {
        color: #4d4f5c !important;
    }

    .category-tag::placeholder {
        color: #4d4f5c !important;
    }

    @media screen and (max-width: 720px) {
        .copies-field {
            width: 60px
        }
    }
</style>