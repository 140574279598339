<template>
    <div class="">
        <div>
            <ul class="nav nav-tabs nav-site" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#cash-register" role="tab"
                        aria-controls="cash-register" aria-expanded="true">Cash Register</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#printer-settings1" role="tab"
                        aria-controls="printer-settings1">Printer Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#network-setting" role="tab"
                        aria-controls="network-setting">Network Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#receipt_settings" role="tab"
                        aria-controls="receipt_settings">Receipt Settings</a>
                </li>
            </ul>
            <div class="tab-content mt-6">
                <div role="tabpanel" class="tab-pane fade active show bg-white py-3" id="cash-register" style="margin: 0 15px;border-radius: 6px;"
                    aria-labelledby="cash-register">
                    <div class="col-md-12 row" style="display: -webkit-box!important;">
                        <div class="col-md-6">
                            <button type="submit" class="btn btn-outline-secondary btn-smm"
                                @click="newCashRegister()">+ Add New Cash Register</button>
                        </div>
                        <!-- <div class="col-md-6 talignEnd">
                            <button type="submit" class="btn btn-secondary btn-smm"
                                @click="updateCashiers()">SAVE</button>
                        </div> -->
                    </div>
                    <div class="col-md-12" style="padding-top: 8px;">
                        <div class="table-row">
                            <table class="table table-cash-outlet">
                                <thead>
                                    <tr>
                                        <th>Cash Register ID</th>
                                        <th>SERVER URL</th>
                                        <th>POS ID</th>
                                        <!-- <th class="hidden-sm-down">Printer <br> Available</th> -->
                                        <th class="hidden-md-down">Printer URL</th>
                                        <th class="hidden-sm-down">Printer Make</th>
                                        <th class="hidden-md-down">Model</th>
                                        <th class="hidden-md-down">Type</th>
                                        <!-- <th class="hidden-md-down">Phone No.</th> -->
                                        <th>Admin</th>
                                    </tr>
                                </thead>
                                <tbody v-if="ajaxCallInProgress">
                                    <tr>
                                        <td colspan="7" class="text-center justify-content-center">
                                            <div class="digi-table-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="cashRegisters && cashRegisters.length!==0 && !ajaxCallInProgress">
                                    <tr v-for="cashRegister in cashRegisters" :key="cashRegister.id">
                                        <td style="padding: 0.6rem 1.2rem!important;">{{cashRegister.id}}</td>
                                        <td style="padding: 0.6rem 1.2rem!important;">{{cashRegister.server_url}}</td>
                                        <td style="padding: 0.6rem 1.2rem!important;">{{cashRegister.pos_id}}</td>
                                        <!-- <td style="padding: 0.6rem 1.2rem!important;" class="hidden-md-down">{{cashRegister.phone_number}}</td> -->
                                        <!-- <td style="padding: 0.6rem 1.2rem!important;" class="hidden-sm-down">
                                    <el-switch v-model="cashRegister.is_printer_available" @change="printerStatusInCashRegister(cashRegister.uuid,cashRegister.is_printer_available)"></el-switch>
                                </td> -->
                                        <td style="padding: 0.6rem 1.2rem!important;" class="hidden-md-down"
                                            v-if="cashRegister.is_printer_available">
                                            {{cashRegister.printer_server_url || '-'}}</td>
                                        <td style="padding: 0.6rem 1.2rem!important;" v-else>-</td>
                                        <td style="padding: 0.6rem 1.2rem!important;"
                                            v-if="cashRegister.is_printer_available">
                                            {{cashRegister.printer_make || '-'}}</td>
                                        <td style="padding: 0.6rem 1.2rem!important;" v-else>-</td>
                                        <td style="padding: 0.6rem 1.2rem!important;"
                                            v-if="cashRegister.is_printer_available">
                                            {{cashRegister.printer_model_no || '-'}}</td>
                                        <td style="padding: 0.6rem 1.2rem!important;" v-else>-</td>
                                        <td style="padding: 0.6rem 1.2rem!important;"
                                            v-if="cashRegister.is_printer_available">{{cashRegister.printer_type}}</td>
                                        <td style="padding: 0.6rem 1.2rem!important;" v-else>-</td>
                                        <td style="padding: 0.6rem 1.2rem!important;">
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                <button class="btn btn-icon companyactions actionicons"
                                                    style="padding: 0.4rem .2rem;"
                                                    @click="editCashRegister(cashRegister)">
                                                    <i class="icon icon-pencil"></i>
                                                </button>
                                            </el-tooltip>
                                            <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                                                <button type="button" class="btn btn-icon actionicons"
                                                    style="padding: 0.4rem .2rem;"
                                                    @click="deleteCashRegister(cashRegister.uuid)">
                                                    <i class="icon icon-delete"></i>
                                                </button>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="!ajaxCallInProgress && cashRegisters.length == 0">
                                    <tr>
                                        <td style="padding: 0.6rem 1.2rem!important;font-weight:600;" colspan="7"
                                            class="text-center">No Cash Registers Added</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12 pl-6 pt-4">
                        <label class="col-form-label text-secondary fs-14" style="font-size:14px!important;">
                            Assign Cashiers
                            <sup>★</sup>
                        </label>
                        <div class="position-relative">
                            <v-select multiple v-model="cashiers" :options="FilteredCashiersOption" :hide-selected="true"   label="full_name" @open="getEmployeesForNonCashier"
                                placeholder="Please Assign Cashiers" @search="getEmployeesForNonCashier"
                                @search:blur="getEmployeesForNonCashier" class="cash-v-select">
                                <template slot="option" slot-scope="option">{{ option.full_name }}</template>
                            </v-select>
                            <div class="d-flex align-items-center" v-if="edit_assign_cashier" style="position: absolute;right: 0;bottom: 5px;">
                                <i class="icon icon-check-circle text-success pointer" @click="updateCashiers"></i>
                                <i class="icon icon-close-circle text-danger pointer ml-3" @click="hideEditAssignCashier"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pl-6 pt-8">
                        <label class="col-form-label text-secondary fs-14" style="font-size:14px!important;">
                            Assign Non-Cashiers to Cash Registers
                        </label>
                        <div class="mt-4" v-for="(no_cashier, non_cashier_index) in non_cashier_list_data" :key="non_cashier_index+'non_cashier_index'">
                            <span class="fs-14 text-capitalize">
                                {{non_cashier_index+1}}. {{no_cashier.name}}
                            </span>
                            <div class="position-relative">
                                <v-select multiple v-model="no_cashier.user_ids" :options="FilteredCashiersOption"              label="full_name" :hide-selected="true" @open="getEmployeesForNonCashier"
                                    placeholder="Please Assign Non Cashiers" @search="getEmployeesForNonCashier"
                                    @search:blur="getEmployeesForNonCashier" class="cash-v-select non-cash-v-select mt-2">
                                    <template slot="option" slot-scope="option">{{ option.full_name }}</template>
                                </v-select>
                                <div class="d-flex align-items-center" v-if="edit_assign_non_cashier" style="position: absolute;right: 0;bottom: 5px;">
                                    <i class="icon icon-check-circle text-success pointer" @click="updateNonCashiers(no_cashier.register_id, no_cashier.user_ids)"></i>
                                    <i class="icon icon-close-circle text-danger pointer ml-3" @click="resetNonCashiers(no_cashier.register_id, non_cashier_index)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="printer-settings1" aria-labelledby="printer-settings1">
                    <div class="d-flex flex-nowrap justify-content-between pl-2 pr-2">
                        <span class="text-white font-weight-bold" style="font-size:16px;">Printer Settings</span>
                        <div class="list-title" style="font-weight: 500;">
                            <a class="btn btn-secondary btn-smm"
                                style="background-color:#fff!important;color:#00448b!important;"
                                @click="addNewPrinter()">+ Add New Printer</a>
                        </div>
                    </div>
                    <div class="page-layout carded ml-4 mr-4 mb-4 mt-5"
                        style="border-radius: 12px;border: 1px solid #a8adb4!important;">
                        <div class="demo-content">
                            <table class="table table-scroll">
                                <thead>
                                    <tr>
                                        <th>Printer Make</th>
                                        <th>Model</th>
                                        <th>Type</th>
                                        <th class="hidden-sm-down">Printer IP</th>
                                        <!-- <th>PRINTER AVAILABILITY</th> -->
                                        <!-- <th class="hidden-sm-down">PRINT <br>TICKETS</th>
                                        <th class="hidden-sm-down">PRINT <br>Invoice</th>
                                        <th class="hidden-sm-down">PRINT <br>Receipt</th> -->
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="all_printers.length < 1">
                                    <tr>
                                        <td colspan="8" class="text-center font-weight-bold justify-content-center">No
                                            Printer Available</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-for="(printer,index) in all_printers" :key="index">
                                        <!-- <td class="textCaps" style="padding: 0 10px!important;max-width: 140px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">{{printer.printer_name}}</td> -->
                                        <td class="" style="padding: 0.6rem 1.2rem!important;">
                                            {{printer.printer_make || '-'}}</td>
                                        <td class="" style="padding: 0.6rem 1.2rem!important;">
                                            {{printer.printer_model_no || '-'}}</td>
                                        <td class="" style="padding: 0.6rem 1.2rem!important;">
                                            {{printer.printer_type || '-'}}</td>
                                        <td class="" style="padding: 0.6rem 1.2rem!important;">{{printer.ip_address}}
                                        </td>
                                        <!-- <td>
                                        <el-switch
                                            v-model="printer.status"
                                            v-on:change="change_printer_status(printer.status,printer.printer_id)"
                                        ></el-switch>
                                        </td> -->
                                        <!-- <td>
                                            <button class="btn btn-icon actionicons"
                                                style="border-radius: 0px !important;">
                                                <img v-if="printer.printer_functions && printer.printer_functions.tickets"
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/variant.svg"
                                                    class="download-icon" />
                                                <img v-else
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/novariant.svg"
                                                    class="download-icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn btn-icon actionicons"
                                                style="border-radius: 0px !important;">
                                                <img v-if="printer.printer_functions && printer.printer_functions.invoices"
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/variant.svg"
                                                    class="download-icon" />
                                                <img v-else
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/novariant.svg"
                                                    class="download-icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn btn-icon actionicons"
                                                style="border-radius: 0px !important;">
                                                <img v-if="printer.printer_functions && printer.printer_functions.receipts"
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/variant.svg"
                                                    class="download-icon" />
                                                <img v-else
                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/novariant.svg"
                                                    class="download-icon" />
                                            </button>
                                        </td> -->
                                        <td style="padding: 0 10px!important;">
                                            <el-tooltip class="item" effect="dark" content="View" placement="top">
                                                <a class="btn btn-icon companyactions actionicons"
                                                    style="padding: 0.4rem .2rem;"
                                                    @click="viewPrinter(printer.printer_id)">
                                                    <i class="icon icon-eye"></i>
                                                </a>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                <a class="btn btn-icon companyactions actionicons"
                                                    style="padding: 0.4rem .2rem;"
                                                    @click="editPrinter(printer.printer_id)">
                                                    <i class="icon icon-pencil"></i>
                                                </a>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                <a class="btn btn-icon actionicons"
                                                    @click="confirmDeletePrinter(printer.printer_id)"
                                                    style="padding: 0.4rem .2rem;">
                                                    <i class="icon icon-delete"></i>
                                                </a>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="network-setting" aria-labelledby="network-setting">
                    <div class="col-md-12">
                        <div class="col-md-12 row justify-content-between no-gutters align-items-center">
                            <span class="text-white font-weight-bold pl-2">Router Settings</span>
                            <button class="btn btn-smm btn-secondary btnWhite" @click="addRouter()">+Add New
                                Router</button>
                        </div>
                        <div class="col-md-12" style="padding-top: 8px;">
                            <div class="table-row">
                                <table class="table table-cash-outlet network-setting-table">
                                    <thead>
                                        <tr>
                                            <th>Router Name</th>
                                            <th class="hidden-sm-down">Router Password</th>
                                            <th>IP Address</th>
                                            <th class="text-center">ADMIN</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="getAllRouterSetting.length < 1">
                                        <tr>
                                            <td colspan="4" class="text-center justify-content-center">No Router Setting
                                                Found</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(router,index) in getAllRouterSetting" :key="index">
                                            <td>{{router.name}}</td>
                                            <td class="hidden-sm-down" v-if="routerids.includes(router._id)">
                                                {{router.password}}</td>
                                            <td v-else>
                                                <input type="password" v-model="router.password" disabled
                                                    style="background: transparent !important;">
                                            </td>
                                            <td>{{router.ip_address}}</td>
                                            <td style="padding: 0rem 0.6rem!important;">
                                                <span v-if="routerids.includes(router._id)">
                                                    <el-tooltip class="item" effect="dark" content="Hide Password"
                                                        placement="top">
                                                        <button class="btn btn-icon companyactions actionicons mr-1"
                                                            @click="viewrouterpassword(router._id)"
                                                            style="padding: 0.4rem .2rem;">
                                                            <i class="icon icon-eye-off"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </span>
                                                <span v-else>
                                                    <el-tooltip class="item" effect="dark" content="Show Password"
                                                        placement="top">
                                                        <button class="btn btn-icon companyactions actionicons mr-1"
                                                            @click="viewrouterpassword(router._id)"
                                                            style="padding: 0.4rem .2rem;">
                                                            <i class="icon icon-eye"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </span>
                                                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                    <button class="btn btn-icon companyactions actionicons mr-1"
                                                        @click="editRouter(router._id)" style="padding: 0.4rem .2rem;">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-tooltip>
                                                <el-tooltip class="ite m" effect="dark" content="Delete"
                                                    placement="top">
                                                    <button type="button" class="btn btn-icon actionicons"
                                                        @click="deleteRouter(router)" style="padding: 0.4rem .2rem;">
                                                        <i class="icon icon-delete"></i>
                                                    </button>
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-5">
                        <div class="col-md-12 row justify-content-between no-gutters align-items-center">
                            <span class="text-white font-weight-bold pl-2">Wifi Settings</span>
                            <button class="btn btn-smm btn-secondary btnWhite" @click="addWifiNetwork()">+Add New Wifi
                                Network</button>
                        </div>
                        <div class="col-md-12" style="padding-top: 8px;">
                            <div class="table-row">
                                <table class="table table-cash-outlet network-setting-table">
                                    <thead>
                                        <tr>
                                            <th>Wifi name</th>
                                            <th>Wifi password</th>
                                            <th>ADMIN</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="getAllWifiSetting.length < 1">
                                        <tr>
                                            <td colspan="3" class="text-center justify-content-center">No Wifi Setting
                                                Found</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(wifi,index) in getAllWifiSetting" :key="index">
                                            <td>{{wifi.name}}</td>
                                            <td v-if="wifiids.includes(wifi._id)">{{wifi.password}}</td>
                                            <td v-else>
                                                <input type="password" v-model="wifi.password" disabled
                                                    style="background: transparent !important;">
                                            </td>
                                            <td style="padding: 0rem 0.6rem!important;">
                                                <span v-if="wifiids.includes(wifi._id)">
                                                    <el-tooltip class="item" effect="dark" content="Hide Password"
                                                        placement="top">
                                                        <button class="btn btn-icon companyactions actionicons mr-1"
                                                            @click="viewwifipassword(wifi._id)">
                                                            <i class="icon icon-eye-off"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </span>
                                                <span v-else>
                                                    <el-tooltip class="item" effect="dark" content="Show Password"
                                                        placement="top">
                                                        <button class="btn btn-icon companyactions actionicons mr-1"
                                                            @click="viewwifipassword(wifi._id)">
                                                            <i class="icon icon-eye"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </span>
                                                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                    <button class="btn btn-icon companyactions actionicons mr-1"
                                                        style="padding: 0.4rem .2rem;" @click="editWifi(wifi._id)">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                    <button type="button" class="btn btn-icon actionicons mr-1"
                                                        style="padding: 0.4rem .2rem;" @click="deleteWifi(wifi)">
                                                        <i class="icon icon-delete"></i>
                                                    </button>
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-5 pb-5">
                        <div class="col-md-12 row justify-content-between no-gutters align-items-center">
                            <span class="text-white font-weight-bold pl-2">ISP Providers</span>
                            <button class="btn btn-smm btn-secondary btnWhite" @click="createISPProvider()">+Add New ISP
                                Provider</button>
                        </div>
                        <div class="col-md-12" style="padding-top: 8px;">
                            <div class="table-row">
                                <table class="table table-cash-outlet network-setting-table">
                                    <thead>
                                        <tr>
                                            <th class="whiteSpace">ISP Provider Name</th>
                                            <th class="hidden-md-down">Upload Speed</th>
                                            <th class="hidden-md-down">Download Speed</th>
                                            <th class="whiteSpace">Telephone No.</th>
                                            <th>Emails</th>
                                            <th>Admin</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="getAllIspSetting.length < 1">
                                        <tr>
                                            <td colspan="6" class="text-center justify-content-center">No ISP Providers
                                                Found</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(isp,index) in getAllIspSetting" :key="index">
                                            <td
                                                style="max-width:250px !important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">
                                                {{isp.name}}</td>
                                            <td class="hidden-md-down">{{isp.upload_speed}}</td>
                                            <td class="hidden-md-down">{{isp.download_speed}}</td>
                                            <td class="whiteSpace" style="display:block !important;">
                                                {{isp.phone_number[0]}}
                                                <br />
                                                <span class="text-secondary font-weight-bold fs-12"
                                                    v-if="isp.phone_number.length > 1">+{{isp.phone_number.length - 1}}more</span>
                                            </td>
                                            <td style="display:block !important;" class="whiteSpace">
                                                <span
                                                    style="max-width:135px !important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">
                                                    {{isp.email[0]}}
                                                </span>
                                                <br />
                                                <span class="text-secondary font-weight-bold fs-12"
                                                    v-if="isp.email.length > 1">+{{isp.email.length - 1}}more</span>
                                            </td>
                                            <td style="padding: 0rem 0.6rem!important;">
                                                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                    <button class="btn btn-icon companyactions actionicons mr-1"
                                                        style="padding: 0.4rem .2rem;" @click="editIsp(isp._id)">
                                                        <i class="icon icon-pencil"></i>
                                                    </button>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                    <button type="button" class="btn btn-icon actionicons mr-1"
                                                        style="padding: 0.4rem .2rem;" @click="deleteIsp(isp)">
                                                        <i class="icon icon-delete"></i>
                                                    </button>
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade pl-3 pr-3" id="receipt_settings"
                    aria-labelledby="receipt_settings">
                    <div class="digi-table-loader" v-if="ajaxCallInProgressnew">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div v-if="!ajaxCallInProgressnew">
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-lg-6">
                                <label class="text-secondary pr-2 fw-600 pb-1">Receipt Format Configuration :</label>
                                <br />
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="generalDetails.receipt_format" type="radio"
                                            class="form-check-input" name="receiptFormat" id="optionsRadios1"
                                            value="restaurant" readonly />
                                        <span class="radio-icon"></span>
                                        <span>Restaurant</span>
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="generalDetails.receipt_format" type="radio"
                                            class="form-check-input" name="receiptFormat" id="optionsRadios2"
                                            value="restaurant_and_hotels" readonly />
                                        <span class="radio-icon"></span>
                                        <span>Restaurant and Hotels</span>
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label">
                                        <input v-model="generalDetails.receipt_format" type="radio"
                                            class="form-check-input" name="receiptFormat" id="optionsRadios3"
                                            value="events" readonly />
                                        <span class="radio-icon"></span>
                                        <span>Events</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-lg-6">
                                <label class="text-secondary pr-2 fw-600 pb-1">Address Format for Invoices :</label>
                                <br />
                                <!-- <label class="switch">
                                    <input type="checkbox" v-model="outlet.use_short_address_format_for_invoice">
                                    <span class="slider round"></span>
                                </label> -->
                                <el-switch style="display: block"
                                    v-model="generalDetails.use_short_address_format_for_invoice" active-color="#00448b"
                                    inactive-color="#00448b" active-text="Long Form" inactive-text="Short Form">
                                </el-switch>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-lg-6">
                                <label class="text-secondary pr-2 fw-600 pb-1">Address Format for Receipts :</label>
                                <br />
                                <el-switch style="display: block"
                                    v-model="generalDetails.use_short_address_format_for_receipt" active-color="#00448b"
                                    inactive-color="#00448b" active-text="Long Form" inactive-text="Short Form">
                                </el-switch>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-lg-12">
                                <label class="text-secondary pr-2 fw-600 pb-1">Receipt Settings :</label>
                                <br />
                                <el-checkbox v-model="generalDetails.is_round_off_required">Enable Roundoff
                                </el-checkbox>
                                <el-checkbox v-model="print_receipt_by_default">Print Receipt by Default
                                </el-checkbox>
                                <!-- <el-checkbox v-model="generalDetails.add_tips">Add Tips</el-checkbox> -->
                                <el-checkbox v-model="generalDetails.enable_double_receipts">Enable Double Receipts
                                </el-checkbox>

                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="col-lg-3" v-if="outlet">
                                <label class="text-secondary pr-2 fw-600 pb-1 whiteSpace">Other Receipt Settings
                                    :</label>
                                <br />
                                <el-checkbox v-model="generalDetails.add_wifi_info" @change="addWifiInfo()">Add Wifi
                                    Info</el-checkbox>
                            </div>
                            <div v-if="generalDetails.add_wifi_info" class="col-md-8 row justify-content-between">
                                <div class="col-lg-5 pt-3" style="display: inline-flex;">
                                    <label class="text-secondary pr-2 fw-600 pt-1">Username:</label>
                                    <input class="form-control wifi_input" type="username" name="username"
                                        v-model="generalDetails.guest_wifi_info.username">
                                </div>
                                <div class="col-lg-5 pt-3" style="display: inline-flex;">
                                    <label class="text-secondary pr-2 fw-600 pt-1">Password:</label>
                                    <input class="form-control wifi_input" type="text" name="password"
                                        v-model="generalDetails.guest_wifi_info.password">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                            style="display: -webkit-inline-box;">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                alt="Vertical Cash" />
                            <div class="w-100" style="margin-left: 15px;">
                                <label class="text-secondary pr-2 fw-600 pb-1 whiteSpace">Add Tagline for Receipt
                                    :</label>
                                <br />
                                <input type="text" class="w-100" maxlength="150" v-model="outlet_tagline" style="padding: 2.3px 10px 1.8px 10px;border-radius: 1px;border: solid 0.5px #777;background-color: #fbfbfb;" placeholder="Enter Tagline" />
                                <span class="float-right">
                                    {{outlet_tagline.length}}/150
                                </span>
                            </div>
                        </div>
                        <div class="pb-5 text-center mt-10">
                            <button class="btn btn-link btn-smm" :disabled="edit_save_register_printer_receipt_settings_api_inprogress"
                                style="text-transform: uppercase !important;font-size: 12px !important;color:#fff!important;border:1px solid #fff!important;"
                                @click="setGeneralDetails()">RESET</button>
                            <button @click="saveEditedOutlet('generalDetails', outlet, generalDetails)" :disabled="edit_save_register_printer_receipt_settings_api_inprogress"
                                style="background-color:#fff!important;color:#00448b!important;" type="submit"
                                class="btn btn-secondary btn-smm ml-5">SAVE
                                <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_save_register_printer_receipt_settings_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-cash-register modal_name="create-cash" @created-cash-register="getAllCashRegisters">
        </create-cash-register>
        <edit-cash-register modal_name="edit-cash" @edited-cash-register="getAllCashRegisters"
            :editCashRegisterData="editCashRegisterData"></edit-cash-register>
        <add-new-printer :outlet="outlet" modal_brand="add-printer" @updatePrinter="manage_printers"></add-new-printer>
        <edit-printer :outlet="outlet" @updatePrinter="manage_printers" modal_brand="edit-printer-modal"></edit-printer>
        <view-printer modal_name="printer-view"></view-printer>
        <add-router modal_name="create-router" @saverouter="getRouterSettings()"></add-router>
        <edit-router modal_name="update-router" @editrouter="getRouterSettings()"></edit-router>
        <add-wifi modal_name="create-wifi" @savewifi="getWifiSettings()"></add-wifi>
        <edit-wifi modal_name="update-wifi" @editwifi="getWifiSettings()"></edit-wifi>
        <add-isp modal_name="create-isp" @saveisp="getIspSettings()"></add-isp>
        <edit-isp modal_name="update-isp" @editisp="getIspSettings()"></edit-isp>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>
<script>
    import {
        SweetModal
    } from 'sweet-modal-vue'
    import {
        EventBus
    } from './eventBus/nav-bar-event'

    import AddRouter from './CreateRouter'
    import EditRouter from './EditRouter'
    import AddWifi from './createWifiNetwork'
    import EditWifi from './EditWifiNetwork'
    import AddIsp from './CreateISPProvider'
    import EditIsp from './EditISPProvider'
    import AddNewPrinter from './AddNewPrinter'
    import EditPrinter from './EditPrinter'
    import ViewPrinter from './ViewPrinter'
    import CreateCashRegister from './CreateCashRegister'
    import EditCashRegister from './EditCashRegister'

    import OutletServices from './mixins/outlets'
    import cashRegister from './mixins/cashRegister'
    import Users from './mixins/user'
    import { HalfCircleSpinner } from "epic-spinners";
    export default {
        props: ['generalDetailsnew'],
        data() {
            return {
                gathered_cashier: [],
                routerids: [],
                wifiids: [],
                cashRegisters: [],
                cashiers: [],
                cashiersList: [],
                edit_assign_cashier: false,
                edit_assign_non_cashier: false,
                active_cashier_index: 0,
                non_cashiers_ist: [],
                all_printers: [],
                editprinter: {},
                getAllRouterSetting: [],
                getAllWifiSetting: [],
                getAllIspSetting: [],
                editCashRegisterData: {
                    id: '',
                    server_url: '',
                    pos_id: ''
                },
                modal_msg: '',
                ajaxCallInProgress: false,
                ajaxCallInProgressnew: false,
                outlet: {
                    receipt_format: '',
                    add_wifi_info: false,
                    use_short_address_format_for_receipt: false,
                    use_short_address_format_for_invoice: false,
                    is_round_off_required: false,
                    prompt_user_to_print: false,
                    add_tips: false,
                    enable_double_receipts: false,
                    guest_wifi_info: {
                        username: '',
                        password: ''
                    }
                },
                generalDetails: {
                    name: this.generalDetailsnew.name,
                    website: this.generalDetailsnew.website,
                    email: this.generalDetailsnew.email,
                    profile_pic: this.generalDetailsnew.profile_pic,
                    phone_number: this.generalDetailsnew.phone_number,
                    currency: {
                        name: this.generalDetailsnew.currency.name,
                        symbol: this.generalDetailsnew.currency.symbol,
                    },
                    receipt_format: this.generalDetailsnew.receipt_format,
                    address: {
                        street: this.generalDetailsnew.address.street,
                        street2: this.generalDetailsnew.address.street2,
                        city: this.generalDetailsnew.address.city,
                        state: this.generalDetailsnew.address.state,
                        township: this.generalDetailsnew.address.township,
                        zipcode: this.generalDetailsnew.address.zipcode,
                        country: this.generalDetailsnew.address.country,
                    },
                    short_address: {
                        street: this.generalDetailsnew.short_address.street,
                        city: this.generalDetailsnew.short_address.city,
                        state: this.generalDetailsnew.short_address.state,
                        zipcode: this.generalDetailsnew.short_address.zipcode,
                        country: this.generalDetailsnew.short_address.country,
                    },
                    use_short_address_format_for_receipt: this.generalDetailsnew.use_short_address_format_for_receipt,
                    use_short_address_format_for_invoice: this.generalDetailsnew.use_short_address_format_for_invoice,
                    add_wifi_info: this.generalDetailsnew.add_wifi_info,
                    guest_wifi_info: this.generalDetailsnew.guest_wifi_info,
                    add_tips: this.generalDetailsnew.add_tips,
                    enable_double_receipts: this.generalDetailsnew.enable_double_receipts,
                    is_round_off_required: this.generalDetailsnew.is_round_off_required,
                    prompt_user_to_print: this.generalDetailsnew.prompt_user_to_print,
                },
                print_receipt_by_default: this.generalDetailsnew.print_receipt_by_default,
                outlet_tagline: '',
                edit_save_register_printer_receipt_settings_api_inprogress: false
            }
        },
        mixins: [OutletServices, cashRegister, Users],
        components: {
            SweetModal,
            AddRouter,
            EditRouter,
            AddWifi,
            EditWifi,
            AddIsp,
            EditIsp,
            AddNewPrinter,
            EditPrinter,
            ViewPrinter,
            CreateCashRegister,
            EditCashRegister,
            HalfCircleSpinner
        },
        methods: {
            hideEditAssignCashier() {
                // this.edit_assign_cashier = false;
                this.cashiers = JSON.parse(localStorage.getItem('cashier_ids'));
            },
            resetNonCashiers(register_id, index) {
                // this.edit_assign_non_cashier = false;
                let non_cashier_list_data_from_local = JSON.parse(localStorage.getItem('non_cashier_list_data'));
                this.non_cashier_list_data[index] = non_cashier_list_data_from_local.filter(item => item.register_id == register_id)[0];
                this.$forceUpdate();
            },
            addWifiInfo() {
                if (this.generalDetails.add_wifi_info) {
                    this.generalDetails.guest_wifi_info = {
                        username: '',
                        password: ''
                    }
                } else {
                    this.generalDetails.guest_wifi_info = null
                }
            },
            saveEditedOutlet(formScope, oldDetails, editedDetails) {
                this.$validator.validate(formScope + '.*').then(isValid => {
                    this.ifGeneralDetailsSubmitted = true
                    if (this.isPhoneNumberInvalid) {
                        return
                    }
                    if (isValid) {
                        const changes = this.getChanges(oldDetails, editedDetails)
                        changes._id = this.$route.query.outletId
                        this.editOutletServiceCall(changes)
                    } else {}
                })
            },
            getChanges(json1, json2) {
                const result = {}
                for (let elem in json2)
                    if (json2[elem] !== json1[elem]) result[elem] = json2[elem]
                return result
            },
            async editOutletServiceCall(params) {
                try {
                    this.edit_save_register_printer_receipt_settings_api_inprogress = true;
                    if (typeof params.phone_number == 'object') {
                        params.phone_number = params.phone_number['e164']
                    }
                    params['print_receipt_by_default'] = this.print_receipt_by_default;
                    params['outlet_tagline'] = this.outlet_tagline;
                    let res = await this.outletEdit(params)
                    let response = await this.validateUser()
                    let userDetails = response.user_details
                    let newLoggedInUser = {
                        access_change_discount: userDetails.access_change_discount,
                        access_remove_item: userDetails.access_remove_item,
                        auto_logout: userDetails.auto_logout,
                        auto_logout_mins: userDetails.auto_logout_mins,
                        hide_row_with_value_zero: userDetails.hide_row_with_value_zero || false,
                        show_place_order_in_checkout: userDetails.show_place_order_in_checkout || false,
                        print_receipt_by_default: userDetails.print_receipt_by_default || false,
                        uid: userDetails.user_id,
                        full_name: userDetails.full_name,
                        email: userDetails.email,
                        profile_pic: userDetails.profile_pic,
                        actions: userDetails.actions,
                        is_setup_done: userDetails.is_setup_done,
                        isAdmin: userDetails.user_role === 'default_admin',
                        is_authorized: userDetails.is_authorized,
                        is_loaded: true,
                        has_order: userDetails.has_order,
                        outlet: userDetails.outlet,
                        business_day_start_time:
                        userDetails.business_day_start_time,
                        role: userDetails.user_role,
                        outlet_type: userDetails.outlet_type,
                        pin: userDetails.pin,
                        is_cashier: userDetails.is_cashier,
                        cashier_session: userDetails.cashier_session || null,
                        service_type: userDetails.service_type || null,
                        enable_fast_checkout: userDetails.enable_fast_checkout,
                        enable_double_receipts: userDetails.enable_double_receipts,
                        enable_course_firing: userDetails.enable_course_firing,
                        default_deliver_together: userDetails.default_deliver_together,
                        outlet_id: userDetails.outlet_id,
                        configure_payment_device:userDetails.configure_payment_device,

                    }
                    this.$store.commit('setLoggedInUser', newLoggedInUser)
                    if (userDetails.is_cashier && userDetails.cashier_session)
                        EventBus.$emit('updateBills', true)

                    this.ifGeneralDetailsSubmitted = false
                    this.ifOtherDetailsSubmitted = false
                    this.updateView(res)
                    this.$swal({
                        title: res.message,
                        text: '',
                        // icon: "success",
                        type: 'success'
                    })
                    this.edit_save_register_printer_receipt_settings_api_inprogress = false;
                } catch (reason) {
                    this.ifGeneralDetailsSubmitted = false
                    this.ifOtherDetailsSubmitted = false
                    this.$swal({
                        title: 'Cannot Edit',
                        text: reason || '',
                        // icon: "warning",
                        // buttons: ["ok"],
                        type: 'warning'
                    })
                    this.edit_save_register_printer_receipt_settings_api_inprogress = false;
                }
            },
            updateView(response) {
                this.outlet = response.outlet
                if (!this.outlet.details) {
                    this.outlet.details = {}
                }
                this.tax_rules = response.tax_rules
                this.other_charges = response.other_charges
                this.prompt_user_to_print = this.outlet.prompt_user_to_print
                this.setGeneralDetails()
                this.setTaxDetails()
                this.setOtherDetails()
                this.getManageDetails()
            },
            setGeneralDetails() {
                this.generalDetails = JSON.parse(
                    JSON.stringify({
                        name: this.outlet.name,
                        website: this.outlet.website,
                        email: this.outlet.email,
                        profile_pic: this.outlet.profile_pic,
                        phone_number: this.outlet.phone_number,
                        tax_id: this.outlet.tax_id,
                        currency: this.outlet.currency,
                        use_short_address_format_for_receipt: this.outlet
                            .use_short_address_format_for_receipt,
                        use_short_address_format_for_invoice: this.outlet.use_short_address_format_for_invoice,
                        address: {
                            street: this.outlet.address.street,
                            street2: this.outlet.address.street2,
                            city: this.outlet.address.city,
                            state: this.outlet.address.state,
                            township: this.outlet.address.township,
                            zipcode: this.outlet.address.zipcode,
                            country: this.outlet.address.country
                        },
                        short_address: {
                            street: this.outlet.short_address.street,
                            city: this.outlet.short_address.city,
                            state: this.outlet.short_address.state,
                            zipcode: this.outlet.short_address.zipcode,
                            country: this.outlet.short_address.country
                        },
                        receipt_format: this.outlet.receipt_format,
                        add_wifi_info: this.outlet.add_wifi_info,
                        guest_wifi_info: this.outlet.guest_wifi_info,
                        add_tips: this.outlet.add_tips,
                        enable_double_receipts: this.outlet.enable_double_receipts,
                        is_round_off_required: this.outlet.is_round_off_required,
                        prompt_user_to_print: this.outlet.prompt_user_to_print,
                    })
                )
            },
            setTaxDetails() {
                this.taxDetails = JSON.parse(
                    JSON.stringify({
                        tax_rules: this.outlet.tax_rules
                    })
                )
            },
            setOtherDetails() {
                this.otherDetails = JSON.parse(
                    JSON.stringify({
                        no_of_tables: this.outlet.no_of_tables,
                        report_emails: this.outlet.report_emails,
                        table_prefix: this.outlet.table_prefix,
                        opening_hours: this.outlet.opening_hours,
                        default_timings: this.outlet.default_timings || this.otherDetails.default_timings,
                        prompt_user_to_print: this.outlet.prompt_user_to_print,
                        is_round_off_required: this.outlet.is_round_off_required,
                        double_discount: this.outlet.double_discount,
                        additional_charge_with_discount: this.outlet.additional_charge_with_discount,
                        business_day_start_time: this.outlet.business_day_start_time,
                        enable_fast_checkout: this.outlet.enable_fast_checkout,
                        delete_void_order: this.outlet.delete_void_order || false,
                        can_waiter_see_sales_data: this.outlet.can_waiter_see_sales_data || false,
                        show_loyalty_when_value_zero: this.outlet.show_loyalty_when_value_zero || false,
                        show_promotion_when_value_zero: this.outlet.show_promotion_when_value_zero || false,
                        courses_settings: this.outlet.courses_settings
                    })
                )
            },
            async getManageDetails() {
                try {
                    if (
                        this.outlet.outlet_type === 'vendor' &&
                        !this.outlet.details.popup_store
                    ) {
                        this.getPopupstoresAvailable()
                    } else if (
                        this.outlet.outlet_type === 'vendor' &&
                        this.outlet.details.popup_store
                    ) {
                        let params = {
                            popup_store: this.outlet.details.popup_store,
                            account_id: this.outlet.details.account_id
                        }
                        let res = await this.outletGetPopupStoreDetails(params)
                        this.popup_details = res.popup_details
                    } else if (this.outlet.outlet_type === 'pop-up') {
                        this.getExistingandNewVendors()
                    }
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            printerStatusInCashRegister(cashRegisterUuid, printerStatus) {
                this.$http.post('/outlet/change_status_cash_register_printer', {
                        uuid: cashRegisterUuid,
                        is_printer_available: printerStatus,
                        outlet_id: this.$route.query.outletId
                    })
                    .then(res => {
                        if (res.data.status_id == 1) {
                            this.$toasted.global.success(res.data.response);
                        } else {
                            this.$toasted.global.error(res.data.reason);
                        }
                    })
            },
            addRouter() {
                this.$modal.show('create-router')
            },
            editRouter(routerId) {
                EventBus.$emit('getRouterDetailsByID', routerId)
                this.$modal.show('update-router')
            },
            addWifiNetwork() {
                this.$modal.show('create-wifi')
            },
            editWifi(wifiId) {
                EventBus.$emit('getWifiDetailsByID', wifiId)
                this.$modal.show('update-wifi')
            },
            createISPProvider() {
                this.$modal.show('create-isp')
            },
            editIsp(ispId) {
                EventBus.$emit('getIspDetailsByID', ispId)
                this.$modal.show('update-isp')
            },
            addNewPrinter() {
                this.$modal.show('add-printer')
            },
            cancelPrinter() {
                this.$modal.hide('add-printer')
            },
            savePrinter() {
                this.$modal.hide('add-printer')
            },
            editPrinter(printer_id) {
                EventBus.$emit('editPrinter', printer_id)
                this.$modal.show('edit-printer-modal')
            },
            viewPrinter(printer_id) {
                EventBus.$emit('viewPrinterById', printer_id)
                this.$modal.show('printer-view')
            },
            canceleditPrinter() {
                this.$modal.hide('edit-printer-modal')
            },
            newCashRegister() {
                this.$modal.show('create-cash')
            },
            editCashRegister(cashRegister) {
                this.editCashRegisterData = cashRegister
                this.$modal.show('edit-cash')
            },
            async change_printer_status(status, printer_id) {
                try {
                    let params = {
                        printer_id: printer_id,
                        status: status
                    }
                    let res = await this.outletChangePrinterStatus(params)
                    this.modal_msg = res.message
                    if (res.status_id == 1) {
                        this.$toasted.global.success(this.modal_msg);
                    } else {
                        this.$toasted.global.error(this.modal_msg);
                    }
                    this.manage_printers()
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async confirmDeletePrinter(printer_id) {
                let result = await this.$swal({
                    title: 'Are you sure?',
                    text: 'Are you sure to delete the printer ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                })
                if (result.value) {
                    this.deletePrinter(printer_id)
                }
            },
            async deletePrinter(printer_id) {
                try {
                    let params = {
                        printer_id: printer_id,
                        outlet_id: this.outlet._id
                    }
                    let res = await this.outletDeletePrinter(params)
                    this.modal_msg = res.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                    }, 1000);
                    this.manage_printers()
                } catch (reason) {
                    //    TODO: Handle Reason
                    this.modal_msg = reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 2000);
                }
            },
            async manage_printers() {
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId
                    }
                    let res = await this.outletManagePrinters(params)
                    this.all_printers = res.response.all_printers
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getRouterSettings() {
                try {
                    let params = {
                        type: 'router_settings'
                    }
                    let res = await this.networkSettings(params)
                    this.getAllRouterSetting = res.response
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getWifiSettings() {
                try {
                    let params = {
                        type: 'wifi_settings'
                    }
                    let res = await this.networkSettings(params)
                    this.getAllWifiSetting = res.response
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getIspSettings() {
                try {
                    let params = {
                        type: 'isp_settings'
                    }
                    let res = await this.networkSettings(params)
                    this.getAllIspSetting = res.response
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            deleteRouter(router) {
                this.$swal({
                    title: 'Are you sure?',
                    text: 'Are you sure to delete the Router Setting ' + router.name + ' ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        this.deleteRouterSetting(router)
                    }
                })
            },
            async deleteRouterSetting(router) {
                let response = await this.$http.post(
                    '/outlet/settings_delete/' + router._id
                )
                if (response.data.status_id === 1) {
                    this.getRouterSettings()
                    this.$swal({
                        title: 'Router Setting Deleted Successfully',
                        text: '',
                        type: 'success'
                    })
                } else {
                    this.modal_msg = response.data.response
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                }
            },
            deleteWifi(wifi) {
                this.$swal({
                    title: 'Are you sure?',
                    text: 'Are you sure to delete the Wifi Setting ' + wifi.name + ' ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        this.deleteWifiSetting(wifi)
                    }
                })
            },
            async deleteWifiSetting(wifi) {
                let response = await this.$http.post(
                    '/outlet/settings_delete/' + wifi._id
                )
                if (response.data.status_id === 1) {
                    this.getWifiSettings()
                    this.$swal({
                        title: 'Wifi Setting Deleted Successfully',
                        text: '',
                        type: 'success'
                    })
                } else {
                    this.modal_msg = response.data.response
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                }
            },
            deleteIsp(isp) {
                this.$swal({
                    title: 'Are you sure?',
                    text: 'Are you sure to delete the ISP Provider ' + isp.name + ' ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then(result => {
                    if (result.value) {
                        this.deleteIspSetting(isp)
                    }
                })
            },
            async deleteIspSetting(isp) {
                let response = await this.$http.post('/outlet/settings_delete/' + isp._id)
                if (response.data.status_id === 1) {
                    this.getIspSettings()
                    this.$swal({
                        title: 'ISP Provider Deleted Successfully',
                        text: '',
                        type: 'success'
                    })
                } else {
                    this.modal_msg = response.data.response
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                }
            },
            async getOutletFromId() {
                this.ajaxCallInProgressnew = true
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId,
                        with_tax_rules: false
                    }
                    let res = await this.outletGetOutletFromId(params)
                    if (res.status_id == 1) {
                        this.outlet = res.outlet
                        this.print_receipt_by_default = this.outlet.print_receipt_by_default
                        this.outlet_tagline = res.outlet.outlet_tagline || ''
                        this.ajaxCallInProgressnew = false
                    }
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            viewwifipassword(id) {
                if (this.wifiids.find(x => x === id)) {
                    this.wifiids = this.wifiids.filter(function (x) {
                        if (x !== id) {
                            return x;
                        }
                    });
                } else {
                    this.wifiids.push(id)
                }
            },
            viewrouterpassword(id) {
                if (this.routerids.find(x => x === id)) {
                    this.routerids = this.routerids.filter(function (x) {
                        if (x !== id) {
                            return x;
                        }
                    });
                } else {
                    this.routerids.push(id)
                }
            }
        },
        computed:{
            loggedInUser() {
                return this.$store.state.loggedInUser
            },
            FilteredCashiersOption: function () {
                this.gathered_cashier = []
                this.cashiers.forEach((cash, k) => {
                    this.gathered_cashier.push(cash)
                })

                this.non_cashier_list_data.forEach((no_cashier, i) => {
                    no_cashier.user_ids.forEach((c, j) => {
                        this.gathered_cashier.push(c)
                    })
                })
                return this.non_cashiers_ist = this.non_cashiers_ist.filter(v => !(this.gathered_cashier.some(
                        e => e._id === v._id)))
            },
        },
        mounted() {
            this.getAllCashRegisters();
            this.getEmployeesForCashier();
            this.getEmployeesForNonCashier('');
            this.manage_printers()
            this.getRouterSettings();
            this.getWifiSettings();
            this.getIspSettings();
            this.getOutletFromId();
        },
        beforeDestroy() {
            localStorage.removeItem('cashier_ids');
            localStorage.removeItem('no_cashier_edit_user_ids');
            localStorage.removeItem('non_cashier_list_data');
        }
    }
</script>
<style scoped>
    .borderBottom {
        border-bottom: 1px solid #a8adb4 !important;
    }

    .form-control.wifi_input {
        display: block !important;
        width: 100% !important;
        height: 32px !important;
        padding: 7px 7px !important;
        font-size: 13px !important;
        line-height: 1.5384616 !important;
        color: #303031 !important;
        border: 1px solid #a8adb4 !important;
        border-radius: 12px !important;
        -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075) !important;
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075) !important;
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !important;
    }

    .general-settings-tab-outlet {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
        margin: 16px;
    }

    .general-settings-tab-outlet .inputContact,
    .general-settings-tab-outlet .digi-form {
        border-bottom: 1px solid #a8adb4 !important;
        background: transparent !important;
    }

    .general-settings-tab-outlet .vue-tel-input {
        background: transparent !important;
        border: transparent !important;
        border-bottom: 1px solid #a8adb4 !important;
    }

    .tax-tab-outlet {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
        margin: 16px;
        padding-top: 20px;
        padding-bottom: 7px;
    }

    /* .payment-outlet-tab{
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #dee8f2;
  margin: 16px;
  padding-bottom: 20px;
} */
    .mediaSize .other-table-content {
        padding: 20px;
        background-color: #dee8f2 !important;
        padding-bottom: 20px;
        border-radius: 12px;
    }

    .mediaSize.other-settings-tab-outlet .inputContact {
        border-bottom: solid 1px #a8adb4 !important;
        background: transparent;
    }

    .other-settings-other .vue-tags-input {
        background-color: transparent !important;
    }

    .other-settings-other .vue__time-picker input.display-time {
        background: transparent !important;
    }

    .send-report-div {
        background-color: #dee8f2 !important;
        padding-bottom: 20px;
        border-radius: 12px;
    }

    .business-div {
        background-color: #dee8f2 !important;
        padding-bottom: 20px;
        border-radius: 12px;
        margin-top: 20px;
    }

    .business-div .vue__time-picker input.display-time {
        background: #dee8f2 !important;
    }

    input:required {
        box-shadow: none !important;
    }

    .icon-edit-void,
    .icon-delete-void {
        cursor: pointer;
    }

    .form-check.form-check-inline.void-checkbox {
        border-radius: 20px;
        border: solid 0.5px #c7e1ff;
        background-color: #ebf6ff;
        padding: 7px 7px 6px 11px;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .form-check.form-check-inline.void-checkbox .form-check-label input[type='checkbox']~.checkbox-icon {
        top: -1px !important;
        left: -1px !important;
        font-size: 22px !important;
    }

    .voiddiv {
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
    }

    .voiddiv_cancelled {
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
    }

    .form-check.form-check-inline.void-checkbox .form-check-label input[type='checkbox']:checked~.checkbox-icon {
        color: #00448b !important;
    }

    .icon-edit-void .icon.icon-pencil {
        color: #00448b !important;
        font-size: 18px !important;
        padding-left: 8px !important;
    }

    .void-pophover {
        position: absolute;
        background-color: #fff;
        z-index: 9999;
        width: 400px;
    }

    .void-pophover .popover {
        max-width: 400px;
    }

    .form-control.void-input-pop {
        border: 1px solid #cdd2da !important;
        border-radius: 24px 0px 0px 24px !important;
        border-right: 0px !important;
    }

    .icon-delete-void .icon.icon-delete {
        color: #ff6565 !important;
        font-size: 18px !important;
    }

    @media (max-width: 1100px) {
        .col-4.settings-cols {
            flex: 0 0 80% !important;
            max-width: 80% !important;
        }
    }

    @media (max-width: 1100px) {
        .col-8.settings-cols {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            margin-bottom: 20px;
        }
    }

    .contactActiveTab {
        color: #fff !important;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-right: solid 18px #cdd2da;
        background-color: #00448b;
        border-right-color: #00448b;
        overflow: inherit !important;
        padding-left: 42px !important;
        font-weight: 600;
        background: rgba(0, 0, 0, 0.38);
    }

    .supplyactive.contactActiveTab {
        border-top: 26px solid transparent;
        border-bottom: 26px solid transparent;
        border-right: solid 18px #cdd2da;
        background-color: #00448b;
        border-right-color: #00448b;
        overflow: inherit !important;
        padding-left: 42px !important;
        font-weight: 600;
        background: rgba(0, 0, 0, 0.38);
        color: #fff !important;
    }

    .scmminustop {
        margin-top: -15px;
    }

    label[for='taxCode'] {
        display: none;
    }

    .res-settings {
        box-shadow: 0 1px 5px 0 rgba(187, 132, 132, 0.16) !important;
        background-color: #dee8f2 !important;
        margin-bottom: 16px;
        padding-bottom: 6px;
        border-radius: 8px;
    }

    .select-card-checkbox {
        height: 80px;
        line-height: 79px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .occupiedTable-regenerate {
        cursor: pointer;
        width: 100px;
        height: 100px;
        font-weight: 600;
        padding-top: 36px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        -webkit-box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: -webkit-gradient(linear,
                left top,
                left bottom,
                from(#00448b),
                color-stop(51%, #00448b),
                to(#69b3fc));
        background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
    }

    .table-row {
        border: 1px solid #a8adb3;
        border-radius: 18px;
        margin-top: 10px;
        background-color: #dee8f2;
    }

    .v-select.cash-v-select input[type='search'] {
        position: absolute !important;
    }

    .outletdetails label.digi-label-outlet {
        top: 13px;
        position: absolute;
        font-size: 12px;
        color: #aaa;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .digi-form:focus~label.digi-label-outlet,
    .digi-form:valid~label.digi-label-outlet {
        top: -15px;
        font-size: 13px;
        color: #00448b;
    }

    .heading-details {
        font-weight: 600;
        color: #00448b;
        font-size: 14px;
    }

    .verticalTab {
        height: 41px;
        display: flex;
        width: 100%;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        position: relative;
        justify-content: center;
        color: #f7f6f6 !important;
        font-weight: 600;
    }

    .verticalTab:hover {
        background-color: #eeeeee47;
    }

    .contactSidebar {
        padding-bottom: 30px;
        background-image: url('https://cdn.digicollect.com/cdn/posv2/arora1.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
    }

    /* background-size: 310px 1500px; */
    .table.table-cash-outlet tbody tr {
        border-bottom: 1px solid #a8adb4;
    }

    .table.table-cash-outlet tbody tr:last-child {
        border-bottom: none !important;
    }

    .table.table-payment tbody tr {
        border-bottom: 1px solid #a8adb4;
    }

    .table.table-payment tbody tr:last-child {
        border-bottom: none !important;
    }

    .contactTitle {
        text-align: center;
        display: block;
        font-size: 14px;
        color: #fff !important;
        font-weight: 600 !important;
        padding-top: 6px;
        width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
    }

    .existingVendors {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        border-left: none !important;
        cursor: pointer;
        border-radius: 20px 0 0 20px !important;
    }

    .newReq {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        cursor: pointer;
        border-left: none !important;
        border-radius: 0 20px 20px 0 !important;
        margin-left: -3px;
    }

    .hotel-website {
        font-size: 14px;
        font-weight: 600;
        color: #00448b !important;
    }

    .table.table-outlet-details td {
        padding: 0.6rem 1.2rem !important;
    }

    .decline {
        font-size: 14px;
        font-weight: 600;
        color: #e82828 !important;
        padding-right: 20px;
    }

    .popTitle {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        display: block;
        padding-top: 6px;
        padding-bottom: 2px;
    }

    .new-count {
        color: #ffa104 !important;
        font-weight: 600;
    }

    .popAvailable {
        font-size: 14px;
        font-weight: bold;
        color: #00448b;
        line-height: 35px;
    }

    .text-bold {
        font-weight: 600;
    }

    .text-muted {
        color: #4e5764 !important;
        font-weight: 600 !important;
    }

    .font-weight-600.text-green,
    .font-weight-600.text-green i {
        color: #1a9347 !important;
    }

    .font-weight-600.text-red,
    .font-weight-600.text-red i {
        color: red !important;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .card-outlet {
        border-radius: 7px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #cdd2da;
        margin-top: 50px;
        margin-right: 26px;
    }

    .outlet-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }

    @media (min-width: 992px) {}

    @media (min-width: 1200px) {}

    @media (max-width: 1100px) {
        .mediaSize {
            padding: 0 0 !important;
        }

        .talignEnd {
            text-align: center !important;
        }
    }

    .currentPopup {
        overflow-x: hidden;
    }

    .nav-tabs.nav-site {
        border-bottom: 1px solid #cdd2da;
        padding-left: 14px;
        padding-right: 14px;
    }

    .nav-tabs.nav-site .nav-item {
        margin-bottom: -2px;
    }

    .nav-tabs.nav-site .nav-link.active {
        box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16) !important;
        background: #00448b !important;
        color: #fff !important;
        border-bottom: 1px solid transparent;
        font-weight: 600;
    }

    .nav-tabs.nav-site .nav-link {
        height: 35px !important;
        border: 1px solid #cdd2da;
        background-color: #efefef;
        color: #00448b;
        margin-right: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-bottom: 1px;
    }

    .table td {
        vertical-align: middle;
        color: #4e5764;
        font-weight: 600;
    }

    .nav-tabs.nav-site .nav-link.active:not(.dropdown-toggle):after,
    .nav-tabs.nav-site .nav-item.show .nav-link:not(.dropdown-toggle):after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: transparent;
    }

    .whiteSpace {
        white-space: nowrap;
    }

    @media screen and (max-width: 1200px) {
        .tableOverflow {
            overflow-x: auto;
            display: block;
        }
    }

    @media(max-width:1000px) {
        .form-control.wifi_input {
            width: auto !important;
        }
    }

    .tableOverflow:hover::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #94bef0;
        border: 4px solid transparent;
        background-clip: content-box;
        /* THIS IS IMPORTANT */
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border: 9px solid #00448b;
    }

    .displayNone {
        display: none !important;
    }

    .btnWhite {
        background-color: #fff !important;
        color: #00448b !important;
    }

    .network-setting-table thead,
    .network-setting-table tr,
    .network-setting-table td,
    .network-setting-table th {
        display: flex;
        flex: 1 1 0%;
    }

    .network-setting-table tbody tr,
    .network-setting-table tbody td {
        align-items: center;
        font-weight: 600;
    }

    .network-setting-table tbody {
        width: 100%;
        max-height: 390px;
        overflow-y: auto;
    }

    .table.network-setting-table tbody tr {
        border-bottom: 1px solid #a8adb4 !important;
    }

    .talignEnd {
        text-align: end;
    }
</style>