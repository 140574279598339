<template>
    <modal
        :name="modal_brand_change"
        transition="nice-modal-fade"
        class="final-modal"
    >
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title"
                    >ADD NEW BRAND</span
                >
            </div>
            <div class="v-modal-body">
                <div class="v-modal-layout">
                    <form>
                        <div class="form-group mt-0 contactGroup">
                            <input
                                class="inputContact"
                                v-validate="'required'"
                                v-model="brand.name"
                                type="text"
                                name="name"
                                :maxlength="limit.name"
                                autofocus
                                required
                                id="brand_name_crec_brand"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">
                                Brand Name
                                <sup>★</sup>
                            </label>
                            <span
                                class="invalid-feedback-form"
                                v-show="errors.has('name')"
                                style="display:inline;"
                                >{{ errors.first('name') }}</span
                            >
                            <span
                                class="invalid-feedback-form"
                                v-if="brandNameExists"
                                >{{ modal_msg }}</span
                            >
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >
                                {{ limit.name - brand.name.length }} / 45
                            </p>
                        </div>
                        <div class="form-group contactGroup">
                            <input
                                class="inputContact"
                                v-validate="'required'"
                                type="text"
                                v-model="brand.supplier"
                                name="supplier"
                                :maxlength="limit.supplier"
                                required
                                id="supplier_crec_brand"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">
                                Supplier
                                <sup>★</sup>
                            </label>
                            <span
                                class="invalid-feedback-form"
                                v-show="errors.has('supplier')"
                                style="display:inline;"
                                >{{ errors.first('supplier') }}</span
                            >
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >
                                {{ limit.supplier - brand.supplier.length }}
                                / 60
                            </p>
                        </div>
                    </form>
                    <div class="v-modal-footer text-center pt-5">
                        <a class="btn btn-link btn-smm" @click="cancel"
                            >CANCEL</a
                        >
                        <button
                            class="btn btn-secondary btn-smm text-uppercase"
                            :disabled="saving"
                            @click="save"
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import brandServices from './mixins/brands'
import { EventBus } from './eventBus/nav-bar-event'

function initialState() {
    return {
        limit: {
            supplier: 60,
            name: 45
        },
        brand: {
            name: '',
            supplier: ''
        },
        saving: false,
        brandNameExists: false
    }
}

export default {
    props: ['modal_brand_change'],
    data() {
        return initialState()
    },
    mixins: [brandServices],
    methods: {
        reset() {
            Object.assign(this.$data, initialState())
        },
        async save() {
            try {
                this.saving = true
                let result = await this.$validator.validate()
                if (result) {
                    let params = this.brand
                    let res = await this.brandsAdd(params)
                    EventBus.$emit('getBrands')
                    EventBus.$emit('createdBrandId', res.message._id)
                    this.$swal(
                        'Created!',
                        'Brand Added Successfully.',
                        'success'
                    )
                    this.reset()
                    // this.$emit('close', true)
                    this.$modal.hide('brand-create')
                } else {
                    this.saving = false
                }
            } catch (result) {
                this.modal_msg = result
                // this.$refs.warning_modal.open()
                this.brandNameExists = true
                this.saving = false
            }
        },
        cancel() {
            this.reset()
            // this.$emit('close', false)
            this.$modal.hide('brand-create')
        }
    },
    created() {
        const dict = {
            custom: {
                name: {
                    required: () => 'Brand Name is Required'
                },
                supplier: {
                    required: () => 'Supplier Name is Required'
                }
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
