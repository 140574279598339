export default {
  methods: {
    async createCharge(param) {
      return new Promise((resolve, reject) => {
        this.$http
          .post("outlet/create_other_tax", param)
          .then(response => {
            if (response.data.status_id == 1) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(error => {
            this.$log.debug(error)
            reject(error)
          });
      })
    },
    async deleteCharge(param) {
      return new Promise((resolve, reject) => {
        this.$http
          .post("outlet/delete_other_tax", param)
          .then(response => {
            if (response.data.status_id == 1) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(error => {
            this.$log.debug(error)
            reject(error)
          });
      })
    },
    async getCharges(param) {
      return new Promise((resolve, reject) => {
        this.$http
          .post("outlet/get_other_tax", param)
          .then(response => {
            if (response.data.status_id == 1) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(error => {
            this.$log.debug(error)
            reject(error)
          });
      })
    },
    async updateCharge(param) {
      return new Promise((resolve, reject) => {
        this.$http
          .post("outlet/edit_other_tax", param)
          .then(response => {
            if (response.data.status_id == 1) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(error => {
            this.$log.debug(error)
            reject(error)
          });
      })
    }
  },
}