<template>
    <div class="page-layout simple scmdashboardblock right-sidebar inner-sidebar">
        <div class="pb-10">
            <div class="account-type-block">
                <!-- <div id="mail" style="background-image: linear-gradient(126deg, #0086f8 0%, #0081ef 50%, #004fe3 99%);border-radius: 15px;"> -->
                <div id="mail" style="background: #00448b;border-radius: 15px;">
                    <div class="row pt-4">
                        
                        <div class="col-lg-6 col-md-6 col-sm-6 pl-9">
                            <!-- <date-picker
                                class="ml-3 calendarWidth"
                                v-model="dateRangeDashboard"
                                @confirm="onDateChange()"
                                format="DD-MM-YYYY hh:mm A"
                                :first-day-of-week="7"
                                range
                                :show-second="false"
                                :shortcuts="shortcuts"
                                :lang="lang"
                                :clearable="true"
                                confirm
                                :not-after="new Date()"
                                confirm-text="APPLY"
                                type="datetime"
                            ></date-picker> -->
                            <date-picker v-model="dateRangeDashboard" class="calendarWidth scmdashboarddate" placeholder="Select Date Range" range lang="en" type="datetime" format="DD/MM/YYYY" confirm confirm-text="APPLY" style="width: 230px !important;"></date-picker>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6" v-if="loggedInUser.isAdmin">
                            <form class="form-inline">
                                <div class="form-group" style="padding-top:2px;">
                                    <label for="staticEmail2" class="mr-1" style="color: #fff;font-size: 14px;font-weight: 600;">Select Outlet</label>
                                    <!-- <label class="fs-12 d-inline-block" style="color: #303031;font-weight: 600;">Select Outlet</label> -->
                                    <v-select v-model="outlet" 
                                    :options="outlets" 
                                    label="name" 
                                    @change="loadDashboardScm"
                                    placeholder="Select Outlet" 
                                    class="scmdashboard-vselect scmdropdown"
                                        style="width: 180px;height: 34px;"/>
                                </div>
                            </form>
                        </div>
                    </div>
                     <div class="scmdashboard-list">
                        <div class="scmdashboard-list-item">
                            <router-link to="/supplychain">
                                <div class="scmdashboard-wrapper">
                                    <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/audit.svg">
                                </div>
                                <div class="scmdashboard-name">Audit</div>
                            </router-link>
                        </div>
                        <div class="scmdashboard-list-item">
                            <div class="scmdashboard-wrapper">
                                <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/products.svg">
                            </div>
                            <div class="scmdashboard-name">Order Products</div>
                        </div>
                        <div class="scmdashboard-list-item">
                            <div class="scmdashboard-wrapper">
                                <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/vendors.svg">
                            </div>
                            <div class="scmdashboard-name">Vendors</div>
                        </div>
                        <div class="scmdashboard-list-item">
                            <router-link to="/recipe">
                                <div class="scmdashboard-wrapper">
                                    <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/recipe.svg">
                                </div>
                                <div class="scmdashboard-name">Recipe</div>
                            </router-link>
                        </div>
                        <div class="scmdashboard-list-item">
                            <div class="scmdashboard-wrapper">
                                <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/warehouse.svg">
                            </div>
                            <div class="scmdashboard-name">Warehouse</div>
                        </div>
                        <div class="scmdashboard-list-item">
                            <div class="scmdashboard-wrapper">
                                <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/invoices.svg">
                            </div>
                            <div class="scmdashboard-name">Invoices</div>
                        </div>
                        <div class="scmdashboard-list-item">
                            <div class="scmdashboard-wrapper">
                                <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/logistics.svg">
                            </div>
                            <div class="scmdashboard-name">Logistics</div>
                        </div>
                        <div class="scmdashboard-list-item">
                            <div class="scmdashboard-wrapper">
                                <img class="scmdashboard-images" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/reports.svg">
                            </div>
                            <div class="scmdashboard-name">Reports</div>
                        </div>
                    </div>
                    <div class="content-section scmdashboardblock-section" >
                        <div class="center-section" style="overflow-y: auto;margin: 10px;border-radius: 15px;overflow-x: hidden;">
                            <div class="crmaccounttypecard scmdashboardtype-over-card personal">
                                <div class="crmaccounttypecard-body mb-10">
                                    <div class="">
                                        
                                        <div style="font-size: 16px;color: #fff;font-weight: 600;margin: 0 auto;text-align: center;">
                                            Stock Count
                                        </div>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                        <div class="blue-box-border">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="table-responsive px-4 py-3" style="overflow: hidden !important;">
                                                        <table class="table sctable table-scroll">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-uppercase" style="color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;border-bottom: 1px solid transparent !important;">
                                                                        Category
                                                                    </th>
                                                                    <th class="text-uppercase" style="text-align:center;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;border-bottom: 1px solid transparent !important;"> 
                                                                        No of Items
                                                                    </th>
                                                                    <th class="text-uppercase" style="color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px 55px !important;border-bottom: 1px solid transparent !important;">
                                                                        Value
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="mt-5" v-if="ajaxCallinprogress">
                                                                    <td colspan="3" style="justify-content: center;">
                                                                        <div class="digi-table-loader dashboard-scm-gif">
                                                                            <div></div>
                                                                            <div></div>
                                                                            <div></div>
                                                                            <div></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr v-if="dashboard_data.length == 0 && !ajaxCallinprogress">
                                                                    <td colspan="3" class="justify-content-center">
                                                                        No Stock Count Found
                                                                    </td>
                                                                </tr>
                                                                <tr v-for="(stockcount, index) in dashboard_data" :key="index">
                                                                    <td style="padding: 1px 5px !important;width: 33.3333%;word-break: break-word;">
                                                                        <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;text-transform: capitalize;display: flex;">
                                                                             <div  class="img-icon-dash pr-3">
                                                                                <img :src="stockcount.image_url" style="border-radius:18px;width:18px !important;" />
                                                                            </div><span style="width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;">{{stockcount.category_name || '-'}}</span>
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px !important;text-align:center;width: 33.3333%;">
                                                                        <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                            {{stockcount.stock_count || '0'}}
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 5px 3px 55px !important;width: 33.3333%;">
                                                                        <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                            {{stockcount.stock_price}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="font-size: 16px;color: #fff;font-weight: 600;padding:20px 10px 0px 10px;margin: 0 auto;text-align: center;">
                                            Purchase Order - Status
                                        </div>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                        <div class="py-5">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-6 text-center">
                                                    <div class="purchaseOrder mx-2">
                                                        <h5 style="font-size:16px;line-height: 1.19;color: #303031;font-weight: bold;padding-top:5px;position: absolute;left: 47%;">0</h5>
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-dashboard-issued.svg" style="width:60px; ">
                                                        <h5 style="font-size:16px;line-height: 2;color: #303031;font-weight: bold;margin: 5px 15px;left: 37%;margin-top: -24px;">0</h5>
                                                    </div>
                                                    <h6 style="font-size:16px;line-height: 1.19;color: #fff;font-weight: bold;margin: 20px 5px;">Issued</h6>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-6 text-center">
                                                    <div class="purchaseOrder mx-2">
                                                        <h5 style="font-size:16px;line-height: 1.19;color: #303031;font-weight: bold;padding-top:5px;position: absolute;left: 47%;">0</h5>
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-dashboard-pending.svg">
                                                        <h5 style="font-size:16px;line-height: 2;color: #303031;font-weight: bold !important;margin: 5px 15px;left: 37%;margin-top: -24px;">0</h5>
                                                    </div>
                                                    <h6 style="font-size:16px;line-height: 1.19;color: #fff;font-weight: bold;margin: 20px 5px;">Pending</h6>
                                                </div>
                                                <div class="col-lg-4  col-md-4 col-sm-6 text-center">
                                                    <div class="purchaseOrder mx-2">
                                                        <h5 style="font-size:16px;line-height: 1.19;color: #303031;font-weight: bold;padding-top:5px;position: absolute;left: 47%;">0</h5>
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-dashboard-closed.svg">
                                                        <h5 style="font-size:16px;line-height: 2;color: #303031;font-weight: bold;margin: 5px 15px;left: 37%;margin-top: -24px;">0</h5>
                                                    </div>
                                                    <h6 style="font-size:16px;line-height: 1.19;color: #fff;font-weight: bold;margin: 20px 5px;">Closed</h6>
                                                </div>
                                            </div>
                                        </div>
                                         <h6 style="font-size: 16px;font-weight: 600;line-height: 1.19;color: #fff;text-align: center;margin:20px 10px 0px 10px;">Purchase Order - Completed</h6>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                            <div class="blue-box-border">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="table-responsive px-4 py-3" style="overflow: hidden !important;">
                                                            <table class="table">
                                                                <!-- <thead>
                                                                    <tr style="border-bottom: 1px solid transparent !important;">
                                                                        <th class="text-uppercase" style="text-align:left;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px 30px !important;border-bottom: 1px solid transparent !important;">
                                                                            CATEGORY
                                                                        </th>
                                                                        <th class="text-uppercase" style="text-align:left;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;border-bottom: 1px solid transparent !important;"> 
                                                                            NO OF ITEMS
                                                                        </th>
                                                                    </tr>
                                                                </thead> -->
                                                                <tbody>
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 5px 5px 5px 30px !important;width: 50%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                Daily
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 5px 5px !important;text-align:left;width: 50%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                               0
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 5px 5px 5px 30px !important;width: 50%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                WTD
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 5px 5px !important;text-align:left;width: 50%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                               0
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 5px 5px 5px 30px !important;width: 50%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                MTD
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 5px 5px !important;text-align:left;width: 50%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                               0
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 5px 5px 5px 30px !important;width: 50%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                YTD
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 5px 5px !important;text-align:left;width: 50%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                               0
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        <div style="font-size: 16px;color: #fff;font-weight: 600;padding: 20px 10px 0px 10px;margin: 0 auto;text-align: center;">
                                            Audit - Status
                                        </div>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                        <div class="blue-box-border">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="table-responsive px-3 py-3" style="overflow: hidden !important;">
                                                        <table class="table  sctable table-scroll">
                                                            <thead>
                                                                <tr style="border-bottom: 1px solid transparent !important;">
                                                                    <th class="text-capitalize" style="font-weight: 600;color: #303031;font-size: 14px !important;width: 50%;">
                                                                        Category
                                                                    </th>
                                                                    <th class="text-capitalize" style="font-weight: 600;color: #303031;font-size: 14px !important;width: 25%;padding: 10px 100px 10px 40px !important;text-align:center;"> 
                                                                        Variance Quantity
                                                                    </th>
                                                                     <th class="text-capitalize" style="font-weight: 600;color: #303031;font-size: 14px !important;width: 25%;text-align:center;"> 
                                                                       Variance Value
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="mt-5" v-if="ajaxCallinprogress">
                                                                    <td colspan="3" style="justify-content: center;">
                                                                        <div class="digi-table-loader dashboard-scm-gif">
                                                                            <div></div>
                                                                            <div></div>
                                                                            <div></div>
                                                                            <div></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr v-if="dashboard_data.length == 0 && !ajaxCallinprogress">
                                                                    <td colspan="3" class="justify-content-center">
                                                                        No Audit - Status Found
                                                                    </td>
                                                                </tr>
                                                                <tr v-for="(auditcount, index) in dashboard_data" :key="index">
                                                                    <td style="width: 50%;padding: 0.1rem 1.2rem;">
                                                                        <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;text-transform: capitalize;word-break: break-word;display: flex;">
                                                                             <div  class="img-icon-dash pr-3">
                                                                                <img :src="auditcount.image_url" style="border-radius:18px;" />
                                                                            </div>
                                                                            <span style="width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;">{{auditcount.category_name}}</span>
                                                                        </span>
                                                                    </td>
                                                                    <td style="padding: 3px 100px 3px 40px !important;text-align:center;width: 25%;">
                                                                        <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                            {{auditcount.variance_count || '0'}}
                                                                        </span>
                                                                    </td>
                                                                     <td style="text-align:center;width: 25%;padding: 0.3rem 1.2rem!important;">
                                                                        <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                            {{auditcount.variance_value || '0'}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fuse-sidebar scmdashboardtype-sidebar sidebar left-positioned open locked-open" style="border-radius: 15px;overflow-y: auto;overflow-x: hidden;margin: 10px 5px;box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.4);border: solid 1px #00ffeb;background-color: #005dae;">
                            <div class="preview-elements details-block h-100">
                                <div class="row h-100 pb-3">
                                    <div class="col-lg-12 mt-2">
                                        <div class="px-3 py-2 h-100" style="border-radius: 15px;">
                                    
                                            <h6 style="font-size: 16px;font-weight: 600;line-height: 1.19;color: #fff;text-align: center;margin: 20px 10px 0px 10px;">Transfers</h6>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-lg-4 col-md-4 col-sm-6 text-center">
                                                        <div class="purchaseOrder mx-2">
                                                            <h5 style="font-size:16px;line-height: 1.19;color: #303031;font-weight: bold;padding-top:5px;position: absolute;left: 47%;">{{transfer_in_count}}</h5>
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-dashboard-transfer-in.svg">
                                                            <h5 style="font-size:16px;line-height: 2;color: #303031;font-weight: bold;margin: 5px 15px;left: 37%;margin-top: -24px;">{{transfer_in_price}}</h5>
                                                        </div>
                                                        <h6 style="font-size:16px;line-height: 1.19;color: #fff;font-weight: bold;margin: 20px 5px;">Transfers In</h6>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-6 text-center">
                                                        <div class="purchaseOrder mx-2">
                                                            <h5 style="font-size:16px;line-height: 1.19;color: #303031;font-weight: bold;padding-top:5px;position: absolute;left: 47%;">{{ transfer_out_count}}</h5>
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-dashboard-transfer-out.svg">
                                                            <h5 style="font-size:16px;line-height: 2;color: #303031;font-weight: bold;margin: 5px 15px;left: 37%;margin-top: -24px;">{{transfer_out_price}}</h5>
                                                        </div>
                                                        <h6 style="font-size:16px;line-height: 1.19;color: #fff;font-weight: bold;margin: 20px 5px;">Transfers Out</h6>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-sm-6 text-center">
                                                        <div class="purchaseOrder mx-2">
                                                            <h5 style="font-size:16px;line-height: 1.19;color: #303031;font-weight: bold;padding-top:5px;position: absolute;left: 47%;">0</h5>
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-dashboard-transfer-pending.svg" style="width:60px">
                                                            <h5 style="font-size:16px;line-height: 2;color: #303031;font-weight: bold;margin: 5px 15px;left: 37%;margin-top: -24px;">0</h5>
                                                        </div>
                                                        <h6 style="font-size:16px;line-height: 1.19;color: #fff;font-weight: bold;margin: 20px 5px;">Transfers Pending</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <!-- <div class="col-lg-12">
                                                        <div class="table-responsive px-4 py-3" style="overflow: hidden !important;">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr style="border-bottom: 1px solid transparent !important;">
                                                                        <th class="text-uppercase" style="text-align:center;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;border-right: 1px solid #fff;border-bottom: 1px solid transparent !important;">
                                                                            Transfers In
                                                                        </th>
                                                                        <th class="text-uppercase" style="text-align:center;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;border-right: 1px solid #fff;border-bottom: 1px solid transparent !important;"> 
                                                                            Transfers Out
                                                                        </th>
                                                                        <th class="text-uppercase" style="text-align:center;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;border-bottom: 1px solid transparent !important;">
                                                                            Transfers Pending
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 10px 5px !important;border-right: 1px solid #fff;width: 33.3333%;text-align:center;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                44
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 10px 5px !important;border-right: 1px solid #fff;text-align:center;width: 33.3333%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                4,546
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 10px 5px !important;width: 33.3333%;text-align:center;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                123
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>

                                            <h6 style="font-size: 16px;font-weight: 600;line-height: 1.19;color: #fff;text-align: center;margin: 20px 10px 0px 10px;">Wastage</h6>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                            <div class="blue-box-border">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="table-responsive px-4 py-3" style="overflow: hidden !important;">
                                                            <table class="table sctable table-scroll">
                                                                <thead>
                                                                    <tr style="border-bottom: 1px solid transparent !important;">
                                                                        <th class="" style="text-align:left;color: #303031;font-size: 14px !important;width: 50%;padding: 5px 5px 8px !important;">
                                                                            Category
                                                                        </th>
                                                                        <th class="" style="text-align:center;color: #303031;font-size: 14px !important;width: 25%;padding: 5px 5px 8px !important;"> 
                                                                            Quantity
                                                                        </th>
                                                                        <th class="" style="text-align:left;color: #303031;font-size: 14px !important;width: 25%;padding: 5px 5px 8px 15px !important;">
                                                                            Value
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="mt-5" v-if="ajaxCallinprogress">
                                                                        <td colspan="3" style="justify-content: center;">
                                                                            <div class="digi-table-loader dashboard-scm-gif">
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-if="dashboard_data.length == 0 && !ajaxCallinprogress">
                                                                        <td colspan="3" class="justify-content-center">
                                                                            No Wastage Found
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(wastagecount, index) in dashboard_data" :key="index"> 
                                                                        <td style="padding: 4px 5px !important;width: 50%;text-align:left;word-break: break-word;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;text-transform: capitalize;display:flex;">
                                                                                <div class="img-icon-dash pr-3">
                                                                                    <img :src="wastagecount.image_url" style="border-radius:18px;width:20px !important;" />
                                                                                </div>
                                                                                <span style="width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;">{{wastagecount.category_name}}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 6px 5px !important;text-align:center;width: 25%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                {{wastagecount.wastage_count || '0'}}
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 6px 5px 6px 15px !important;width: 25%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                {{wastagecount.wastage_price}}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <h6 style="font-size: 16px;font-weight: 600;line-height: 1.19;color: #fff;text-align: center;margin: 20px 10px 0px 10px;">Void</h6>
                                        <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                            <div class="blue-box-border">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="table-responsive px-4 py-3" style="overflow: hidden !important;">
                                                            <table class="table sctable table-scroll">
                                                                <thead>
                                                                    <tr style="border-bottom: 1px solid transparent !important;">
                                                                        <th class="" style="text-align:left;color: #303031;font-size: 14px !important;width: 50%;padding: 5px 5px 8px !important;">
                                                                            Category
                                                                        </th>
                                                                        <th class="" style="text-align:center;color: #303031;font-size: 14px !important;width: 25%;padding: 5px 5px 8px !important;"> 
                                                                            Quantity
                                                                        </th>
                                                                        <th class="" style="text-align:left;color: #303031;font-size: 14px !important;width: 25%;padding: 5px 5px 8px 15px !important;">
                                                                            Value
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="mt-5" v-if="ajaxCallinprogress">
                                                                        <td colspan="3" style="justify-content: center;">
                                                                            <div class="digi-table-loader dashboard-scm-gif">
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-if="dashboard_data.length == 0 && !ajaxCallinprogress">
                                                                        <td colspan="3" class="justify-content-center">
                                                                            No Void Found
                                                                        </td>
                                                                    </tr>
                                                                    <tr  v-for="(voidcount, index) in dashboard_data" :key="index"> 
                                                                        <td style="padding: 6px 5px !important;width: 50%;text-align:left;word-break: break-word;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;text-transform: capitalize;display:flex;">
                                                                                <div class="img-icon pr-3">
                                                                                    <img :src="voidcount.image_url" style="border-radius:18px;width:20px !important;" />
                                                                                </div>
                                                                                <span style="width: 150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;">{{voidcount.category_name}}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 6px 5px !important;text-align:center;width: 25%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                {{voidcount.void_count || '0'}}
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 6px 5px 6px 15px !important;width: 25%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                {{voidcount.void_price}}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 style="font-size: 16px;font-weight: 600;line-height: 1.19;color: #fff;text-align: center;margin: 20px 10px 0px 10px;">Price Feed</h6>
                                            <hr style="border-top: 1px solid #ffffff45!important;margin-bottom: 15px;margin-top: 10px;">
                                            <div class="blue-box-border">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="table-responsive px-4 py-3" style="overflow: hidden !important;">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr style="border-bottom: 1px solid transparent !important;">
                                                                        <th class="text-uppercase" style="text-align:left;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;">
                                                                            Name
                                                                        </th>
                                                                        <th class="text-uppercase" style="text-align:center;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px !important;"> 
                                                                            Price
                                                                        </th>
                                                                        <th class="text-uppercase" style="text-align:left;color: #303031;font-size: 14px !important;width: 33.3333%;padding: 5px 5px 8px 15px !important;">
                                                                            Value
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 6px 5px !important;width: 33.3333%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                Onions
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 6px 5px !important;text-align:center;width: 33.3333%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                               0
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 6px 5px 6px 15px !important;width: 33.3333%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                Kg
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr style="height: 0px;">
                                                                        <td style="padding: 3px 5px !important;width: 33.3333%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                Vodka Bottle
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 3px 5px !important;text-align:center;width: 33.3333%;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                               0
                                                                            </span>
                                                                        </td>
                                                                        <td style="padding: 3px 5px 3px 15px !important;width: 33.3333%;text-align:left;">
                                                                            <span class="text-bold" style="font-size: 13px;font-weight: 600;color: #303031;">
                                                                                Ltr
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center px-2 py-3 mt-10">
                                                <button type="button" class="btn btn-alerts w-100">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exclamation.svg" class="mr-1" style="width: 24px;margin-bottom: 6px;"> 
                                                    <span class="mt-1">ALERTS</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import DashboardScmServices from '@/components/mixins/dashboardscm.js'
export default {
    data() {
        return {
            outlets: [],
            dashboard_data: [],
            currency: [],
            outlet: {
                _id: null,
                name: "All Outlets"
            },
            transfer_in_count: 0,
            transfer_in_price: 0,
            transfer_out_count: 0,
            transfer_out_price: 0,
          ajaxCallinprogress: false,
            // startDate: new Date(new Date().getFullYear(), 0, 1),
            // endDate: new Date(new Date().getFullYear() + 1, 0, 1),
            dateRangeDashboard: [],
            // lang: {
            //     type: "en",
            //     days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            //     months: [
            //         "Jan",
            //         "Feb",
            //         "Mar",
            //         "Apr",
            //         "May",
            //         "Jun",
            //         "Jul",
            //         "Aug",
            //         "Sep",
            //         "Oct",
            //         "Nov",
            //         "Dec"
            //     ],
            //     pickers: [
            //         "next 7 days",
            //         "next 30 days",
            //         "previous 7 days",
            //         "previous 30 days"
            //     ],
            //     placeholder: {
            //         date: "Select Date",
            //         dateRangeDashboard: "Select Date Range"
            //     }
            // },
            // confirmText: {
            //     type: String,
            //     default: "APPLY"
            // },
            // shortcuts: [
            //     {
            //         text: "Today",
            //         onClick: () => {
            //             this.startDate = new Date(
            //                 new Date().getFullYear(),
            //                 new Date().getMonth(),
            //                 new Date().getDate()
            //             );
            //             this.endDate = new Date();
            //             this.dateRangeDashboard = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
            //         }
            //     },
            //     {
            //         text: "This Week",
            //         onClick: () => {
            //             this.startDate = new Date(
            //                 new Date().getFullYear(),
            //                 new Date().getMonth(),
            //                 new Date().getDate() - new Date().getDay()
            //             );
            //             this.endDate = new Date();
            //             this.dateRangeDashboard = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
            //         }
            //     },
            //     {
            //         text: "This Month",
            //         onClick: () => {
            //             this.startDate = new Date(
            //                 new Date().getFullYear(),
            //                 new Date().getMonth(),
            //                 1
            //             );
            //             this.endDate = new Date();
            //             this.dateRangeDashboard = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
            //         }
            //     },
            //     {
            //         text: "This Year",
            //         onClick: () => {
            //             this.startDate = new Date(
            //                 new Date().getFullYear(),
            //                 0,
            //                 1
            //             );
            //             this.endDate = new Date();
            //             this.dateRangeDashboard = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
            //         }
            //     }
            // ],
        };
    },
    mixins: [DashboardScmServices],
    components: { 
        DatePicker
    },
    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        async loadDashboardScm() {
        this.ajaxCallinprogress = true;
           try {
               let response = await this.getScmDashboard({outlet_id:this.loggedInUser.outlet_id})
               this.dashboard_data = response.dashboard_data;
               this.transfer_in_count = response.transfer_in_count;
               this.transfer_in_price = response.transfer_in_price;
               this.transfer_out_count = response.transfer_out_count;
               this.transfer_out_price = response.transfer_out_price;
               this.currency = response.currency;
            this.ajaxCallinprogress = false;
           } catch (reason) {
               
           }
       },
    //   onDateChange() {
    //     this.endDate;
    //     this.dateRangeDashboard[1];
    //     if (!this.dateRangeDashboard[0] && !this.dateRangeDashboard[1]) {
    //         this.startDate = new Date();
    //         this.startDate;
    //         this.endDate = new Date();
    //         this.dateRangeDashboard = [this.startDate, this.endDate];
    //     }
    //   },  
    },
    computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    }
  },
    created() {
        this.$http.post("/outlet/get", {}).then(res => {
        this.selected = {
            _id: null,
            name: "All Outlets"
        };
        this.outlets = [
            {
                _id: null,
                name: "All Outlets"
            }
        ].concat(res.data.outlets);
    });

    },
    mounted() {
        this.loadDashboardScm();
    },
};
</script>
<style scoped>

.page-layout.simple.scmdashboardblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.scmdashboardblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.digi-table-loader.dashboard-scm-gif div{
    background: #00448b!important;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 10px;
}
.content-section.scmdashboardblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.scmdashboardtype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 600px;
  min-width: 600px;
  max-width: 600px;
}
.fuse-sidebar.scmdashboardtype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.scmdashboardblock.right-sidebar.inner-sidebar
  .content-section.scmdashboardblock-section
  .sidebar.locked-open {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #00448b;
}
.page-layout.simple.scmdashboardblock.right-sidebar.inner-sidebar
  .content-section.scmdashboardblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.scmdashboardtype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.scmdashboardblock.right-sidebar.inner-sidebar
  .content-section.scmdashboardblock-section
  .center-section {
  order: 1;
  padding: 10px 0px;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.scmdashboardblock.left-sidebar.inner-sidebar
  .content-section.scmdashboardblock-section
  .center-section,
.page-layout.simple.scmdashboardblock.right-sidebar.inner-sidebar
  .content-section.scmdashboardblock-section
  .center-section {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.scmdashboardtype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.scmdashboardtype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.scmdashboardtype-list-item .scmdashboardtype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.scmdashboardtype-list-item .scmdashboardtype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.scmdashboardtype-list-item img.scmdashboardtype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.scmdashboardtype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.digi-list-timeline__items {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 15px 0 15px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items_stocks {
    position: relative;
    padding: 0;
    margin: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: .3rem 0;
    margin: .5rem 0;
    border-bottom: 0.5px solid #fff;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:first-child {
    padding-top: 0;
    margin-top: 0;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item:last-child {
    border-bottom: 0px solid transparent;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #303031;
    font-weight: 600;
}

.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__text {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 6px 0px 0px 6px;
}
.digi-list-timeline__items_stocks .digi-list-timeline__item.stockswhite .digi-list-timeline__time {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100px;
    padding: 10px 0 10px 5px;
    font-size: 14px;
    color: #00448b;
    font-weight: 600;
    background-color: #fff;
    border-radius: 0px 6px 6px 0px;
}
.scmdashboard-list {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    flex: 0 0 auto;
    padding: 15px 0;
    max-height: none!important;
}
.scmdashboard-list-item {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
    min-width: 110px;
    min-height: 150px;
    width: 110px;
    height: 150px;
    padding: 10px 0;
    margin: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
}
.scmdashboard-list-item .scmdashboard-wrapper {
    font-size: 100px!important;
    width: 100px!important;
    height: 100px!important;
    min-width: 100px!important;
    min-height: 100px!important;
    line-height: 70px!important;
    text-align: center;
}
.scmdashboard-list-item .scmdashboard-wrapper.selected {
    border: 1px solid #fff;
    border-radius: 27px;
}
.scmdashboard-list-item img.scmdashboard-images {
    font-size: 85px!important;
    width: 85px!important;
    height: 85px!important;
    min-width: 85px!important;
    min-height: 85px!important;
    line-height: 85px!important;
    display: inline-block;
}
.scmdashboard-name {
    padding: 8px 5px 0;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #fff;
}
.blue-box-border {
    border-radius: 12px;
    box-shadow: 0 1.5px 16.5px 0 rgba(0, 0, 0, 0.07);
    border: solid 0.5px rgba(255, 255, 255, 0.58);
    background-color: #dee8f2!important;
}
.lightblue-box-border {
    border-radius: 6px;
    box-shadow: 0 1.5px 16.5px 0 rgba(0, 0, 0, 0.07);
    border: solid 0.5px rgba(255, 255, 255, 0.58);
    background-image: linear-gradient(to bottom, #005dae, #0c95fe);
}

.btn.btn-alerts {
    background-color: #c40000 !important;
    border-color: transparent !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    color: #fff !important;
    font-size: 18px !important;
    letter-spacing: 0.3px !important;
    text-transform: uppercase !important;
    height: 48px !important;
    line-height: 48px !important;
}
.scmdashboardblock .table td {
    border-bottom: 1px solid #4e57641f!important;
}
.scmdashboardblock .table thead th {
    border-bottom: 1px solid #4e57641f!important;
}

@media (max-width:1000px){
    .content-section.scmdashboardblock-section {
        display: block !important;
        min-height: 0;
        border-radius: 15px;
        position: relative;
        top: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
    }
    .fuse-sidebar.scmdashboardtype-sidebar {
        display: block !important;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }
}
.purchaseOrder{
    background-color: rgb(210, 225, 241) !important;
    border-radius: 18px;
    line-height: 10;
}
.table.sctable.table-scroll tbody tr{
    border-bottom: none !important;
    height: 34px !important;
}
</style>    