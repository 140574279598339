<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-600">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">STOP CASHIER SESSION</span>
            </div>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="">
                <div class="d-form">
                    <div class="widget-header pl-4 pr-2 row no-gutters align-items-center justify-content-between header-total-stop">
                        <div class="col">
                            <span class="h5">Total Sales</span>
                        </div>
                        <span class="pr-2" v-if="cashierSessionData.sales">{{cashierSessionData.sales.total}}</span>
                    </div>
                    <div class="mb-1 mt-5">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card-cash-others d-flex" style="box-shadow: 0 1px 9.5px 0 rgba(0, 0, 0, 0.43);border-radius: 7.5px;padding-left: 15px;background: #016ecb;">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cash-new-round.svg" style="padding-top: 20px !important;height:63px;"/>
                                    <div id="widget9-total-budget" class="p-4">
                                        <div class="item-title font-600 text-white">Cash Sales</div>
                                        <div class="pt-2 h6" style="width:125px !important;word-break: break-all !important;">
                                            <span class="item-value font-600" style="color: #6dd471 !important;">{{cashierSessionData.sales.cash}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card-cash-others d-flex" style="border-radius: 7.5px;box-shadow: 0 1px 9.5px 0 rgba(0, 0, 0, 0.43);padding-left: 15px;background: #016ecb;">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cash-round.svg" style="padding-top: 20px !important;height:63px;"/>
                                    <div id="widget9-total-budget" class="p-4">
                                        <div class="item-title font-600 text-white">Card Sales</div>
                                        <div class="pt-2 h6" style="width:125px !important;word-break: break-all !important;">
                                            <span class="item-value font-600" style="color: #6dd471 !important;">{{cashierSessionData.sales.card}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="card-cash-others d-flex" style="border-radius: 7.5px;box-shadow: 0 1px 9.5px 0 rgba(0, 0, 0, 0.43);padding-left: 15px;background: #016ecb;">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/others-round.svg" style="padding-top: 20px !important;height:63px;"/>
                                    <div id="widget9-total-budget" class="p-4">
                                        <div class="item-title text-white font-600">Others Sales</div>
                                        <div class="pt-2 h6" style="width:125px !important;word-break: break-all !important;">
                                            <span class="item-value font-600" style="color: #6dd471 !important;">{{cashierSessionData.sales.others}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group contactGroup pl-5 pr-5">
                        <label style="color:#00448b;font-size:14px;margin-bottom: 0px!important;">Cash Register ID</label>
                        <input
                            readonly
                            class="inputContact"
                            type="text"
                            name="brandName"
                            autofocus
                            :value="cashierSessionData.drawer_name"
                            style="padding: 8px 10px 2px 0px!important;"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                    </div>
                    <div class="form-group contactGroup pl-5 pr-5">
                        <label style="color:#00448b;font-size:14px;margin-bottom: 0px!important;">Drawer In Amount</label>
                        <input
                            class="inputContact"
                            type="text"
                            name="brandName"
                            readonly
                            focus
                            :value="cashierSessionData.drawer_in_amount"
                            style="padding: 8px 10px 2px 0px!important;"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                    </div>
                    <div class="form-group contactGroup pl-5 pr-5">
                        <label class style="color:#00448b;font-size:14px;margin-bottom: 0px!important;">Total Cash Received</label>
                        <input
                            class="inputContact"
                            type="text"
                            name="brandName"
                            readonly
                            :value="cashierSessionData.cash_received"
                            style="padding: 8px 10px 2px 0px!important;"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                    </div>
                    <div class="form-group contactGroup pl-5 pr-5">
                        <label class style="color:#00448b;font-size:14px;margin-bottom: 0px!important;">Drawer Out Amount</label>
                        <input
                            class="inputContact"
                            type="text"
                            :value="cashierSessionData.drawer_out_amount"
                            style="padding: 8px 10px 2px 0px!important;"
                            name="brandName"
                            readonly
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                    </div>
                </div>
            </div>
            </div>
            <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                <button class="btn btn-white btn-smm btn-no-border-blue text-uppercase mr-3" style="border:1px solid #00448b;" :disabled="end_cashier_session_api_inprogress" @click="cancelStopSession()">CANCEL</button>
                <button class="btn btn-secondary btn-smm text-uppercase" :disabled="end_cashier_session_api_inprogress" @click="confirmStop()">CONFIRM
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="end_cashier_session_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </modal>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue'
import Swal from 'sweetalert2'
import cashierServices from './mixins/cashier'
import userServices from './mixins/user'
import { EventBus } from './eventBus/nav-bar-event'
import { HalfCircleSpinner } from "epic-spinners";
export default {
    props: ['modal_name', 'cashierSessionData'],
    data() {
        return {
            modal_msg: '',
            end_cashier_session_api_inprogress: false
        }
    },
    mixins: [cashierServices, userServices],
    components: {
        SweetModal,
        HalfCircleSpinner
    },
    methods: {
        cancelStopSession() {
            this.$modal.hide(this.modal_name)
        },
        // getCashierSessionDetails() {
        //     this.$http.get('/cashier/get_drawer_out_summary').then(res => {
        //         if (res.data.status_id == 1) {
        //             this.cashierSessionData = res.data
        //         }
        //     })
        // },
        /**
         * This method or function is used to stop the cashier session 
         * if all order are closed
         * then else need to close all orders to stop the cashier session
         * @param {drawer_uuid} - drawer_uuid is the String
         * @returns {response} - Get the result
         */
        async stopSession() {
        try {
            this.end_cashier_session_api_inprogress = true;
            let params = {
                drawer_uuid: this.cashierSessionData.drawer_uuid
            }
            let res = await this.endSession(params)
            if(res.status_id == 1){
                this.modal_msg = 'Successfully Closed Cashier Session.'
                this.$refs.success_modal.open();
                EventBus.$emit('updateHomeScreendata')
                setTimeout(async () => {
                    let response = await this.validateUser()
                    let userDetails = response.user_details
                    this.$store.commit('setLoggedInUser', {
                        access_change_discount: userDetails.access_change_discount,
                        access_remove_item: userDetails.access_remove_item,
                        auto_logout: userDetails.auto_logout,
                        auto_logout_mins: userDetails.auto_logout_mins,
                        hide_row_with_value_zero: userDetails.hide_row_with_value_zero || false,
                        show_place_order_in_checkout: userDetails.show_place_order_in_checkout || false,
                        print_receipt_by_default: userDetails.print_receipt_by_default || false,
                        uid: userDetails.user_id,
                        full_name: userDetails.full_name,
                        email: userDetails.email,
                        profile_pic: userDetails.profile_pic,
                        actions: userDetails.actions,
                        is_setup_done: userDetails.is_setup_done,
                        isAdmin: userDetails.user_role === 'default_admin',
                        is_authorized: userDetails.is_authorized,
                        is_loaded: true,
                        has_order: userDetails.has_order,
                        outlet: userDetails.outlet,
                        business_day_start_time:
                        userDetails.business_day_start_time,
                        role: userDetails.user_role,
                        outlet_type: userDetails.outlet_type,
                        pin: userDetails.pin,
                        is_cashier: userDetails.is_cashier,
                        cashier_session: userDetails.cashier_session || null,
                        service_type: userDetails.service_type || null,
                        enable_fast_checkout: userDetails.enable_fast_checkout,
                        enable_double_receipts: userDetails.enable_double_receipts,
                        enable_course_firing: userDetails.enable_course_firing,
                        default_deliver_together: userDetails.default_deliver_together,
                        outlet_id: userDetails.outlet_id,
                        configure_payment_device:userDetails.configure_payment_device,
                    })
                    this.$refs.success_modal.close()
                    this.cancelStopSession()
                    this.$emit('showcashier', 0)
                    this.end_cashier_session_api_inprogress = false;
                    this.$router.push('/home')
                }, 2000)
            }
            } catch (reason) {
                this.modal_msg = reason
                this.$refs.warning_modal.open()
                this.end_cashier_session_api_inprogress = false;
            }
        },
        confirmStop() {
            Swal({
                title: 'Alert',
                text: 'Are You Sure You Want To End Cashier Session',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                this.stopSession()
                }
            })
        }
    },
    destroyed() {
        // important to clean up!!
        document.removeEventListener('click', this.documentClick)
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser
        }
    }
}
</script>

<style>
.header-total-stop {
    color: #fff;
    height: 48px;
    margin-top: 14px;
    /* margin-right: 20px;
    margin-left: 20px; */
    border-radius: 6px;
    font-weight: 600;
    box-shadow: 0px -3px 8px 0 rgba(0, 0, 0, 0.06);
    background-image: linear-gradient(to left, #00448b, #005dae 50%, #1088f7);
}
.sales-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90% !important;
}
</style>
