<template>
  <div class="page-layout blank">
    <div class="pb-10 pt-2">
      <div class="page-content">
        <ul class="nav nav-tabs nav-site" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="general-tab" data-toggle="tab" href="#general-tab-pane" role="tab" aria-controls="genaral" aria-expanded="true">
              General
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="taxes-tab" data-toggle="tab" href="#taxes-tab-pane" role="tab" aria-controls="taxes-tab-pane">
              Taxes
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane fade show active" id="general-tab-pane" aria-labelledby="general-tab">
            <div class="row">
              <div class="col-md-6">
                <h6 class="heading-details pb-4">COMPANY DETAILS</h6>
                <div style="border-right:solid 1px #cdd2da;padding-right: 15px;">
                  <div class="row first-middle-input">
                    <div class="col-lg-3">
                      <div class="profile-left pt-4">
                        <div class="profile-image pl-3">
                          <my-upload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="300" langType="en" :height="300" url="/upload" :params="params" :headers="headers" img-format="png"></my-upload>
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/default-user-picture.svg" style="width: 90px;">
                        </div>
                        <div class="mb-4 mt-4 profile-button">
                          <!-- <a class="btn btn-secondary btn-smm" @click="toggleShow">Upload Image</a> -->
                          <a class="btn btn-secondary btn-smm" @click="toggleShow">Change Picture</a>
                          <!-- <a class="btn btn-secondary btn-smm" @click="showPayBill()">Pay</a> -->
                          <!-- <a href="javascript:void(0)" class="btn btn-secondary btn-sm">Change Picture</a> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-9 pl-7">
                      <div class="row mt-4">
                        <div class="col-sm-12 pl-2">
                          <!-- <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6> -->
                          <div class="form-group contactGroup mb-4">
                            <input class="inputContact" type="text" value="Green View Cafe" required>
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Company Name</label>
                          </div>
                          <div class="form-group contactGroup pt-4">
                            <input class="inputContact" type="text" required>
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">Website</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group contactGroup">
                        <input class="inputContact" type="text">
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">Email</label>
                      </div>
                    </div>
                    <div class="col-md-12 pt-4">
                      <label for="inputSattus" class="col-form-label text-secondary">Phone Number</label>
                      <vue-tel-input placeholder="Enter Your Phone Number">
                      </vue-tel-input>
                    </div>
                  </div>
                  <h6 class="heading-details pb-1 pt-4">TAX RULES</h6>
                  <div class="form-check form-check-inline d-inline-block">
                    <label class="form-check-label form-check-tax">
                      <input class="form-check-input" type="radio" name="salutation" value="with_variant">
                      <span class="radio-icon"></span>
                      <span class="form-check-description" style="color: #4e5764;font-size: 12px;line-height: 1.25;">Use Tax Inclusive Prices</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline d-inline-block">
                    <label class="form-check-label form-check-tax">
                      <input class="form-check-input" type="radio" name="salutation" value="without_variant">
                      <span class="radio-icon"></span>
                      <span class="form-check-description" style="color: #4e5764;font-size: 12px;line-height: 1.25;">Use Tax Exclusive Prices</span>
                    </label>
                  </div>
                  <h6 class="heading-details pb-4 pt-4">LOCALE SETTINGS</h6>
                  <div class="row">
                    <div class="col-md-6">
                      <select class="form-control" name="country-select" hidden placeholder="Select Country" value="" style="max-width: 100% !important;">
                        <option hidden value="" selected>Select Country</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Island">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of</option>
                        <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaçao">Curaçao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                        <option value="Korea, Republic of">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestine, State of">Palestine, State of</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Réunion">Réunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthélemy">Saint Barthélemy</option>
                        <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-Leste">Timor-Leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela, Bolivarian Republic of">Venezuela, Bolivarian Republic of</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                        <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <select class="form-control" name="currency-select" placeholder="Select Currency" hidden value="" style="max-width: 100% !important;">
                        <option hidden value="" selected>Select Currency</option>
                        <option data-symbol="$">USD ($)</option>
                        <option data-symbol="₹">INR (₹)</option>
                        <option data-symbol="€">EUR (€)</option>
                        <option data-symbol="£">GBP (£)</option>
                        <option data-symbol="¥">JPY (¥)</option>
                        <option data-symbol="K">MMK (K)</option>
                        <option data-symbol="S$">SGD (S$)</option>
                        <option data-symbol="₦">NGN (₦)</option>
                        <option data-symbol="฿">THB (฿)</option>
                      </select>
                    </div>
                  </div>
                  <h6 class="heading-details pb-4 pt-4">ADDRESS</h6>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group form-bottom">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="group">
                              <input class="digi-form" type="text" required>
                              <span class="highlight digi-highlight"></span>
                              <span class="bar digi-bar"></span>
                              <label class="digi-label">Street</label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="group">
                              <input class="digi-form" type="text" required>
                              <span class="highlight digi-highlight"></span>
                              <span class="bar digi-bar"></span>
                              <label class="digi-label">City </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="group">
                              <input class="digi-form" type="text" required>
                              <span class="highlight digi-highlight"></span>
                              <span class="bar digi-bar"></span>
                              <label class="digi-label">State</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group form-top row">
                        <div class="col-lg-4">
                          <div class="group">
                            <input class="digi-form" type="text" required>
                            <span class="highlight digi-highlight"></span>
                            <span class="bar digi-bar"></span>
                            <label class="digi-label">Zip Code</label>
                          </div>
                        </div>
                        <div class="col-lg-8 mb-5">
                          <!-- <label class="country-label">Country</label> -->
                          <select class="form-control" name="country-select" placeholder="Select Country" style="max-width: 100% !important;">
                            <option hidden value="" selected>Select Country</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Island">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of</option>
                            <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaçao">Curaçao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestine, State of">Palestine, State of</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Réunion">Réunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Barthélemy">Saint Barthélemy</option>
                            <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena, Ascension and Tristan da Cunha</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                            <option value="South Sudan">South Sudan</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-Leste">Timor-Leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela, Bolivarian Republic of">Venezuela, Bolivarian Republic of</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center pt-5">
                    <a href="javascript:;" class="btn btn-link btn-smm" style="text-transform: uppercase !important;">CANCEL</a>
                    <a href="javascript:;" class="btn btn-secondary btn-smm">SAVE</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h6 class="heading-details pb-4">ACCOUNT DETAILS</h6>
                <div class="right-details">
                  <div class="row mt-6">
                    <div class="col-sm-12">
                      <!-- <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6> -->
                      <div class="form-group contactGroup">
                        <input class="inputContact" type="text" value="Green View Cafe" required>
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">Company Name</label>
                      </div>
                      <div class="form-group">
                        <label for="inputSattus" class="col-form-label text-secondary">Email</label>
                        <div class="input-group email-group">
                          <input type="text" class="form-control" placeholder="Enter Your Email-Id" name="email">
                          <!-- <span class="input-group-addon" id="basic-addon2">@example.com</span> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pt-5">
                      <label for="inputSattus" class="col-form-label text-secondary">Phone Number</label>
                      <vue-tel-input placeholder="Enter Your Phone Number">
                      </vue-tel-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane fade" id="taxes-tab-pane" aria-labelledby="taxes-tab">
            <div class="row">
              <div class="col-md-8">
              </div>
              <div class="col-md-4">
                <div class="list-title pt-2 pull-right" style="font-weight: 500;">
                  <a class="btn btn-secondary btn-smm" @click="createTax()">+ Add New Tax Rule </a>
                  <!-- <a class="btn btn-secondary btn-smm">+ Import New Server </a>
                                    <a class="btn btn-secondary btn-smm">+ Export New Server </a> -->
                </div>
              </div>
            </div>
            <div class="page-layout carded left-sidebar">
              <div class="page-content-wrapper">
                <div class="page-content">
                  <div class="demo-content">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>TAX NAME</th>
                          <th>TAX CODE</th>
                          <th>TAX RATE (%)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            CGST
                          </td>
                          <td>GSTIN123456789</td>
                          <td>18%</td>
                          <td>
                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                              <a class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;" @click="editTax()">
                                <i class="icon icon-pencil"></i>
                              </a>
                            </el-tooltip>
                            <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                              <a class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                <i class="icon icon-delete"></i>
                              </a>
                            </el-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            SGST
                          </td>
                          <td>GSTIN123456789</td>
                          <td>18%</td>
                          <td>
                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                              <a class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;" @click="editTax()">
                                <i class="icon icon-pencil"></i>
                              </a>
                            </el-tooltip>
                            <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                              <a class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                <i class="icon icon-delete"></i>
                              </a>
                            </el-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal name="create-tax" transition="nice-modal-fade" class="final-modal">
        <div class="v-modal-content">
          <div class="v-modal-header">
            <span class="v-modal-dialog-title v-modal-title">ADD NEW TAX RULE</span>
          </div>
          <div class="v-modal-body" style="padding: 15px 0px;overflow-y: auto;overflow-x: hidden;">
            <div class="v-modal-layout">
              <div class="row pl-5 pr-5">
                <div class="col-sm-12">
                  <!-- <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6> -->
                  <div class="form-group contactGroup">
                    <input class="inputContact" type="text" value="Service Tax" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Tax Name</label>
                  </div>
                  <div class="form-group contactGroup">
                    <input class="inputContact" type="text" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Tax Code</label>
                  </div>
                  <div class="form-group contactGroup">
                    <input class="inputContact" type="text" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Tax Rate (%)</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-modal-footer text-center pt-5">
              <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideTax()">CANCEL</a>
              <button type="submit" class="btn btn-secondary btn-smm" @click="hideTax()">SAVE</button>
            </div>
          </div>
        </div>
      </modal>
      <modal name="edit-tax" transition="nice-modal-fade">
        <div class="v-modal-content">
          <div class="v-modal-header">
            <span class="v-modal-dialog-title v-modal-title">EDIT TAX RULE</span>
          </div>
          <div class="v-modal-body" style="padding: 15px 0px;overflow-y: auto;overflow-x: hidden;">
            <div class="v-modal-layout">
              <div class="row pl-5 pr-5">
                <div class="col-sm-12">
                  <!-- <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6> -->
                  <div class="form-group contactGroup">
                    <input class="inputContact" type="text" value="Service Tax" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Tax Name</label>
                  </div>
                  <div class="form-group contactGroup">
                    <input class="inputContact" type="text" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Tax Code</label>
                  </div>
                  <div class="form-group contactGroup">
                    <input class="inputContact" type="text" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Tax Rate (%)</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-modal-footer text-center pt-5">
              <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideEditTax()">CANCEL</a>
              <button type="submit" class="btn btn-secondary btn-smm" :disabled='isUploadingInProgress' @click="hideEditTax()">SAVE</button>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<style scoped>
.payselected {
  position: absolute;
  top: -1rem;
  right: -0.8rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}
.payselected.online {
  background: #06bb00;
  color: #fff;
}
.payselected.online:before {
  content: "\ea7b";
}
.card-title.paytype {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #b5b5b5;
}
.card-body.pay-body {
  padding: 1rem !important;
  border-left: 6px solid #a1caff00 !important;
  margin-bottom: 15px !important;
  border-radius: 0px;
}
.time-pick > .el-date-editor.el-input,
.time-pick > .el-date-editor.el-input__inner {
  width: 125px !important;
}
.time-pick > .el-date-editor.el-input > span.el-input__prefix,
.el-input__prefix {
  display: none !important;
}
.time-pick
  > .el-date-editor.el-input
  > span.el-input__prefix
  > .el-input__icon {
  display: none !important;
}
.el-input__icon {
  line-height: 25px !important;
}
.heading-details {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: 0.7px;
  text-align: left;
  color: #00448b;
  padding-top: 15px;
}
.nav-tabs.nav-site {
  border-bottom: 1px solid #cdd2da;
  padding-left: 14px;
  padding-right: 14px;
}
.nav-tabs.nav-site .nav-item {
  margin-bottom: -1px;
}
.nav-tabs.nav-site .nav-link.active {
  box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16) !important;
  background: #fff !important;
  border-bottom: 1px solid transparent;
}
.nav-tabs.nav-site .nav-link {
  height: 35px !important;
  border: 1px solid #cdd2da;
  background-color: #efefef;
  color: #00448b;
  margin-right: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.nav-tabs.nav-site .nav-item .btn:not(:disabled):not(.disabled):active,
.nav-tabs.nav-site .nav-item .btn:not(:disabled):not(.disabled).active {
  border: 1px solid #cdd2da;
  border-bottom: 1px solid #fff;
  background-color: transparent;
}
.nav-tabs.nav-site .nav-link.active:not(.dropdown-toggle):after,
.nav-tabs.nav-site .nav-item.show .nav-link:not(.dropdown-toggle):after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: transparent;
}

.tabsblock {
  padding: 0 14px;
}

.country-label {
  top: -20px;
  font-size: 12px;
  color: #00448b;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;
}

.input-group > .form-control {
  width: 1% !important;
  border-bottom: 0px solid transparent !important;
}

.input-group .input-group-addon.showpass i {
  cursor: pointer !important;
}
.input-group .input-group-addon.showpass {
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #cdd2da;
}
.input-group .input-group-append.showpassbtn {
  margin-left: 5px;
}
.input-group.email-group {
  border-bottom: 1px solid #cdd2da;
}
.form-check-label.form-check-tax input[type="radio"] ~ .radio-icon {
  left: -2px;
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #8dbcf5;
  border-radius: 50% !important;
  margin-left: 3px;
  margin-right: 3px;
}
.outlet-hours {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #303031;
  padding: 20px 0px 10px;
}
.tfoot-total-order {
  border-top: solid 0.5px #94bef0;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablesubtotal tr {
  height: 10px !important;
}
.table.tablesubtotal th {
  padding: 4px !important;
}
.tablesubtotaltd,
.tablesubtotalth {
  text-align: right !important;
}
.table.tablecart th.thcart {
  color: #303031;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px !important;
  padding: 5px 8px !important;
  text-transform: none !important;
}
.table.tablecart td.tdcart {
  color: #4e5764;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px;
  padding: 5px 8px !important;
}
.table.tablecart tfoot th.tfcart {
  padding: 10px 8px !important;
}
.table.tablecart tfoot tr {
  border-top: dotted 0.5px #b8b9bb;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablecart tbody tr {
  height: auto !important;
}
a.add-customer-heading {
  font-weight: 600;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #00448b !important;
}
.border-dotted {
  border-bottom: solid 1px #b8b9bb;
  border-bottom-style: dashed;
}
</style>
<script>
import { EventBus } from "./eventBus/nav-bar-event.js";
import myUpload from "vue-image-crop-upload";
import axios from 'axios'

export default {
  data() {
    return {
      dialogImageUrl: "",
      show: false,
      checked: false,
      checked2: false,
      checked1: false,
      checked4: false,
      checked5: false,
      checked6: false,
      valueMon: true,
      valueTue: true,
      valueWed: true,
      valueThr: true,
      valueFri: true,
      valueSat: true,
      valueSun: false,
      valueTimeStartMon: new Date(2018, 9, 10, 8, 59),
      valueTimeEndMon: new Date(2018, 9, 10, 19, 59),
      valueTimeStartTue: "",
      valueTimeEndTue: "",
      valueTimeStartWed: "",
      valueTimeEndWed: "",
      valueTimeStartThr: "",
      valueTimeEndThr: "",
      valueTimeStartFri: "",
      valueTimeEndFri: "",
      valueTimeStartSat: "",
      valueTimeEndSat: "",
      valueTimeStartSun: "",
      valueTimeEndSun: "",
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      },
      imgDataUrl: "", // the datebase64 url of created image
      isUploadingInProgress: false
    };
  },
  components: {
    "my-upload": myUpload
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    toggleShow() {
      this.show = !this.show;
    },
    blobToFile(theBlob, fileName){
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        const myFile = new File([theBlob], 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png', {
            type: theBlob.type,
        });
        return myFile;
    },
    async cropSuccess(imgData, field){
        // this.imgData = imgData;
        const base64 = await fetch(imgData);
        const blob = await base64.blob();
        let fill = this.blobToFile(blob, 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png');
        this.uploadAttachment(fill);
    },
    cropUploadSuccess(jsonData, field){
        // TODO:
    },
    cropUploadFail(status, field){
        // TODO:
    },
    uploadAttachment(newFile) {
        try {
            var file = newFile;
            if (file) {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                let attachment = {
                    // id: uuid(),
                    name: file.name,
                    size: JSON.stringify(file.size),
                    source: source,
                    progress: 0,
                    created_time: new Date(),
                    error: ""
                }
                this.getResumableURL(file, attachment);
            }
        } catch (error) {
            console.log(error);
        }
    },
    getResumableURL(file, attachment) {
        try {
            this.isUploadingInProgress = true;
            attachment.uploading = true;
            let data = {
                file: attachment.name
            }
            data = new FormData()
            data.append('file',file)
            this.$http.post('https://cloudapis.digicollect.com/external/upload_file', data,{
                onUploadProgress: function (progressEvent) {
                    let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    if (progress < 100) {
                        attachment.progress = progress;
                    }
                }.bind(this)
            })
            .then(response => {
                let resumable_url = response.data.url;
                this.imgDataUrl = resumable_url;
                this.isUploadingInProgress = false;
            })
            .catch(error => {
                console.log(error.data.reason)
            })
        } catch (error) {
            console.log(error);
        }
    },
    createTax() {
      this.$modal.show("create-tax");
    },
    hideTax() {
      this.$modal.hide("create-tax");
    },
    editTax() {
      this.$modal.show("edit-tax");
    },
    hideEditTax() {
      this.$modal.hide("edit-tax");
    },
    createOutlet() {
      this.$modal.show("create-outlet");
    },
    hideOutlet() {
      this.$modal.hide("create-outlet");
    },
    editOutlet() {
      this.$modal.show("edit-outlet");
    },
    hideEdiOutlet() {
      this.$modal.hide("edit-outlet");
    },
    companyTimeline() {
      this.isCompanyTimeline = true;
      this.isCompanyRelationship = false;
      this.isCompanygallery = false;
    },
    relationshipPage() {
      this.isCompanyRelationship = true;
      this.isCompanyTimeline = false;
      this.isCompanygallery = false;
    },
    galleryPage() {
      this.isCompanyRelationship = false;
      this.isCompanyTimeline = false;
      this.isCompanygallery = true;
    }
  },
  mounted() {
    EventBus.$emit("customerdetailsLoad", false);
  }
};
</script>
