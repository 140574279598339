export default {
    methods: {
        async getScmDashboard(params) {
            return new Promise(async(resolve, reject) => {
                try{
                    let response = await this.$http.post("/scm/get_dashboard_data", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        }
    }
}