<template>
    <div class="page-layout blank" style="margin: 0 auto;">
        <div class="pb-10">
            <div class="p-8">
                <div class="access-block">
                    <!-- <h1 class="access-title">
                        You need a DigiCollect Suite account to access Apps</h1> -->
                    <div class="access-message-block">
                        <!-- <div class="title-header">You don't have permission to access the specified resource.</div> -->
                        <div class="access-message">
                           Your Organization didn't set up DigiPOS Application yet. Please contact Administrator for
                           more details.
                        </div>
                        <!-- <a class="btn btn-secondary">Read FAQS</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    created() {
        if(this.loggedInUser.is_setup_done) {
            this.$router.push('/');
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        }
    },
}
</script>

<style scoped>
.access-block {
    width: 680px;
    margin: 0 auto;
}

.access-title {
    color: #303031;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 56px;
    margin-top: 80px;
    text-align: center;
}
.access-message-block {
    background: #fff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 150px 68px 38px;
    border: 0;
    font-size: 16px;
    margin: 0;
    outline: 0;
    vertical-align: baseline;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.title-header {
    color: #303031;
    font-size: 18px;
    font-weight: 400;
}

.access-message {
    color: #00448b;
    font-size:20px;
    font-weight: 500;
    margin: 16px 0 28px;
    text-align: center;
}
</style>