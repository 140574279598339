import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            count: 0,
            limit: 10,
            skip: 0,
            start: 1,
            end: 0,
            passed_key: {
                key: 'name',
                value: 1
            },
            warning_modal: '',
            modal_msg: '',
            searchdata: '',
            totalSubCategories: 0,
            ajaxCallInProgress: false,
            showIllustrator: false
        }
    },
    methods: {
        savesubcategory() {
            this.subcategory.status = true
            this.$http
                .post('/categories/create_sub_category', this.subcategory)
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.$modal.hide('create-sub-category')
                        this.$emit('saving')
                        this.$swal({
                            title: 'Sub Category Added Successfully',
                            text: '',
                            type: 'success'
                        })
                        this.subcategory = {
                            name: '',
                            description: '',
                            image_url: '',
                            category_id: ''
                        }
                        this.temp_category_id = ''
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
        },
        updateSubCatgory(subcategory) {
            this.$http
                .post('/categories/edit_sub_category', subcategory)
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.$modal.hide('edit-sub-category')
                        if (this.source == 'fromCategory') {
                            this.$emit('saved')
                        } else {
                            this.$emit('savingEdited')
                        }
                        this.$swal({
                            title: 'Sub Category Updated Successfully',
                            text: '',
                            type: 'success'
                        })
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getAllSubCategories()
            // if (this.count == 0) this.getAllSubCategories();
            // else this.count = 0;
        },
        getAllSubCategories(param) {
            if (param == 'prev') this.count--
            else if (param == 'next') this.count++
            else if (param == 'init') {
                this.count = 0
            } else if (param == 'searchdata') {
                this.count = 0
            }
            this.ajaxCallInProgress = true
            this.allSubCategories = []
            this.$http
                .post('/categories/manager_sub_category', {
                    skip: this.count * this.limit,
                    limit: this.limit,
                    searchdata: this.searchdata.trim(),
                    sort_key: this.passed_key.key,
                    sort_value: this.passed_key.value
                })
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.allSubCategories =
                            response.data.response.all_sub_categories
                        this.totalSubCategories =
                            response.data.response.sub_category_count
                        this.start = this.count * this.limit + 1
                        this.end =
                            this.count * this.limit + this.limit <
                            this.totalSubCategories
                                ? this.count * this.limit + this.limit
                                : this.totalSubCategories
                    }
                    this.ajaxCallInProgress = false
                    if (
                        this.allSubCategories.length == 0 &&
                        !this.ajaxCallInProgress &&
                        !this.searchdata
                    ) {
                        this.showIllustrator = true
                    } else {
                        this.showIllustrator = false
                    }
                })
        },
        confirmDelete(subcategory) {
            Swal({
                title: 'Are you sure?',
                text:
                    'Are you sure To delete the Sub Category ' +
                    subcategory.name +
                    ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.deleteSubCategory(subcategory)
                }
            })
        },
        deleteSubCategory(subcategory) {
            this.$http
                .post('/categories/delete_sub_category', {
                    sub_category_id: subcategory.sub_category_id
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        Swal(
                            'Deleted!',
                            subcategory.name +
                                ' Sub Category has been deleted.',
                            'success'
                        )
                        this.checkPageCount()
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },
        async getAllCategories(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/categories/get_all_categories_for_sub_category',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.reason)
                }
            })
        },
        checkPageCount() {
            if (this.count >= 1) {
                if (this.totalSubCategories <= this.count * this.limit + 1) {
                    this.count--
                }
            }
            this.getAllSubCategories()
        }
    },
    computed: {
        ...mapGetters({
            loggedInUser: 'loggedInUser'
        })
    }
}
