<template>
  <div class="page-layout blank employees-manage">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="list-title d-flex" style="font-weight: 500;">
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <a class="btn btn-secondary btn-smm" @click="addSection">+ Add New Add-On Section</a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading d-xl-inline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  type="text"
                  class="form-control textremove holder"
                  id="inputWarning2"
                  aria-describedby="inputWarning2Status"
                  placeholder="Search..."
                  v-model="search"
                  @input="getAllAddonsSections('search')"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right mr-1" v-if="totalAddonSection">
              <span
                class="page-info px-2 hidden-md-down"
              >{{start}} - {{end}} of {{totalAddonSection}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                  style="padding:.1rem !important;"
                  :disabled="count <=0"
                  :class="{'disabled':ajaxCallInProgress}"
                  v-on:click="getAllAddonsSections('prev')"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                  style="padding:.1rem !important;"
                  :disabled="end >= totalAddonSection"
                  :class="{'disabled':ajaxCallInProgress}"
                  v-on:click="getAllAddonsSections('next')"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
            <p class="text-center text-secondary fs-28 font-600 pt-5">No Add-On Section Yet!!!</p>
          </div>
        </div>
      </div>
      <div v-if="!showIllustrator">
        <div class="category-manage-info">ADD-ON SECTION</div>
        <div
          class="page-layout carded left-sidebar"
          style="background-color: #00448b!important;padding: 10px!important;"
        >
          <div class="page-content-wrapper">
            <div class="page-content">
              <div class="demo-content">
                <table class="table table-scroll">
                  <thead>
                    <tr>
                      <th>
                        <span v-on:click="setPasskey('name')" class="cursor-th">
                          <span
                            v-if="passed_key.key=='name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          NAME
                        </span>
                      </th>
                      <th style="padding-left:20px !important;">DESCRIPTION</th>
                      <th style="padding-left:20px !important;">No.OF ADD-ONS</th>
                      <th style="padding-left:20px !important;">Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="ajaxCallInProgress" style="border-bottom: transparent !important">
                      <td colspan="6" class="text-center justify-content-center">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="getAllAddonSection.length == 0 && !ajaxCallInProgress"
                      style="background-color: transparent !important;border-bottom: none !important;"
                    >
                      <td v-if="search" colspan="7" class="text-center justify-content-center">
                        <strong style="display:block;padding-top:38px;">
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                          <br />
                          <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                          >No Search Results Found</p>
                        </strong>
                      </td>
                    </tr>
                    <tr v-for="addonsection in getAllAddonSection" :key="addonsection._id">
                      <td
                        style="width: 450px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;padding:0px 0px 0px 25px !important;"
                        class="font-weight-bold"
                        :title="addonsection.name"
                      >{{addonsection.name}}</td>
                      <td
                        style="width: 500px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;"
                        class="font-weight-bold"
                        :title="addonsection.description"
                      >{{addonsection.description || '-'}}</td>
                      <td class="text-center">
                        <el-tooltip content="View Addons" placement="top">
                          <div
                            class="chip chipproducts"
                            @click="showAddon(addonsection._id)"
                            :class="{'clickDisabled':addonsection.addon_count == 0}"
                          >
                            <div
                              class="circle-profile-photo prj-total-users"
                            >{{ addonsection.addon_count }}</div>
                            <span v-if="addonsection.addon_count > 1">Add-Ons</span>
                            <span v-else>Add-On</span>
                          </div>
                        </el-tooltip>
                        <!-- <el-tooltip content="View Addons" placement="top">
                          <span :class="{'clickDisabled':addonsection.addon_count == 0}"
                            style="padding: 0px 24px!important;cursor:pointer"
                            class="pointer text-secondary font-weight-bold fs-14 text-center" 
                            @click="showAddon(addonsection._id)"                           
                          >{{addonsection.addon_count}}</span>
                        </el-tooltip>-->
                      </td>
                      <td style="padding-left: 0px!important;">
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                          <button
                            class="btn btn-icon companyactions actionicons"
                            @click="editsection(addonsection._id)"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button
                            type="button"
                            class="btn btn-icon actionicons"
                            @click="deletesection(addonsection)"
                          >
                            <i class="icon icon-delete"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-section modal_section="addon-create-section" @savesection="getAllAddonsSections()"></create-section>
    <edit-section modal_name="addon-update-section" @editsection="getAllAddonsSections()"></edit-section>
    <view-addons modal_name="addon-view" :sectionId="sectionId"></view-addons>
  </div>
</template>
<script>
import addonsection from './mixins/addonsection'
import VModal from 'vue-js-modal'
import { SweetModal } from 'sweet-modal-vue'
const CreateSection = () => import('./CreateSection')
const EditSection = () => import('./EditSection')
const ViewAddons = () => import('./ViewAddons')
import { EventBus } from './eventBus/nav-bar-event'
export default {
  mixins: [addonsection],
  data() {
    return {
      getAllAddonSection: [],
      sectionId: '',
      totalAddonSection: ''
    }
  },
  methods: {
    showAddon(sectionId) {
      this.sectionId = sectionId
      EventBus.$emit('addonBySectionId', sectionId)
      this.$modal.show('addon-view')
    },
    addSection() {
      this.$modal.show('addon-create-section')
    },
    editsection(sectionId) {
      setTimeout(() => {
        EventBus.$emit('editSectionDetails', sectionId)
        this.$modal.show('addon-update-section')
      }, 500);
    },
    deletesection(details) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure To delete the Add-On Section ' + details.name + ' ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00448b',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(result => {
        if (result.value) {
          this.confirmSectionDelete(details)
        }
      })
    },
    confirmSectionDelete(details) {
      this.$http
        .post('/add_ons/section/delete', { _id: details._id })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$swal(
              'Deleted!',
              details.name + ' Add-On Section has been deleted ',
              'success'
            )
            this.checkPageCount()
          } else {
            this.modal_msg = res.data.response
            this.$swal({
              title: this.modal_msg,
              text: '',
              type: 'warning'
            })
          }
        })
        .catch(res => {
          this.modal_msg = res.response.data.response
          this.$swal({
            title: this.modal_msg,
            text: '',
            type: 'warning'
          })
        })
    }
  },
  components: {
    CreateSection,
    EditSection,
    ViewAddons
  },
  created() {
    this.getAllAddonsSections()
  }
}
</script>
<style scoped>
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.table.table-scroll tbody tr {
  border-bottom: 1px solid #a8adb4;
}
.page-content {
  background-color: transparent !important;
}
.clickDisabled {
  pointer-events: none !important;
}
</style>