<template>
    <div style="padding: 14px;" class="w-100 view-cour-comp">
        <div class="text-right">
            <el-tooltip class="item" effect="dark" content="Close" placement="top-start">
                <span @click="manageorderScreen()" class="pointer">
                    <i class="icon icon-close pr-1" style="color:#ff7b7b!important;font-size: 30px!important;"></i>
                </span>
            </el-tooltip>
        </div>
        <div :class="{OnHoldClass:orderdetails.isOrderOnHold}">
            <div class="top-view-order">
                <div class="page-header text-auto p-1 Feb pt-4 pb-3">
                    <!-- HEADER CONTENT-->
                    <div class="header-content d-flex font-600">
                        <!-- HEADER TOP -->
                        <div class="row w-100 text-white">
                            <div class="col-md-4" style="border-right: 1px solid #fff;">
                                <div class="d-flex pb-3">
                                    <div class="col" v-if="orderdetails.table_number">Table No :
                                        {{orderdetails.table_number}}</div>
                                    <div class="col" v-else>{{orderdetails.order_type || 'Take Away'}}</div>
                                    <div class="col text-right">
                                        <i class="icon icon-clock"
                                            style="color:#fff!important;font-size: 15px!important;"></i>
                                        {{orderdetails.start_time}}
                                    </div>
                                </div>
                                <div class="d-flex pb-3">
                                    <div class="col">Order ID : {{orderdetails.order_id}}</div>
                                    <div class="col text-right">Sequence No : {{orderdetails.order_number}}</div>
                                </div>
                                <div class="d-flex pb-3">
                                    <div class="col">Customer Name : {{orderdetails.customer_name}}</div>
                                </div>
                                <div class="d-flex pb-3" style="border-bottom:2px solid rgba(255, 255, 255, 0.36);">
                                    <div class="col">
                                        <span class="text-white pr-3">Send All Together : </span>
                                        <el-switch v-model="orderdetails.can_send_all_together"
                                            @change="sendAllTogetherOrder(orderdetails.can_send_all_together)">
                                        </el-switch>
                                    </div>
                                </div>
                                <div class="d-flex pb-2 pt-3">
                                    <div class="col" v-if="orderdetails.showFire">
                                        <span class="text-white pr-3">Start Order :</span>
                                        <el-switch :disabled="!orderdetails.canFire" v-model="orderdetails.isFired"
                                            @change="orderFire(orderdetails.isFired)"></el-switch>
                                    </div>
                                    <div class="col"
                                        v-if="orderdetails.showPrepared && loggedInUser.role != 'default_order_taker'">
                                        <span class="text-white pr-3">Finish Order :</span>
                                        <span v-if="orderdetails.canPrepare">
                                            <el-switch :disabled="!orderdetails.canPrepare"
                                                v-model="orderdetails.isPrepared"
                                                @change="prepareOrder(orderdetails.isPrepared)"></el-switch>
                                        </span>
                                        <span v-else>
                                            <el-switch v-model="orderdetails.isPrepared"
                                                @change="prepareOrder(orderdetails.isPrepared)"></el-switch>
                                        </span>
                                    </div>
                                    <div class="col" v-if="orderdetails.showDelivered">
                                        <span class="text-white pr-3">Deliver Order :</span>
                                        <span v-if="orderdetails.canDeliver">
                                            <el-switch :disabled="!orderdetails.canDeliver"
                                                v-model="orderdetails.isDelivered"
                                                @change="deliverOrder(orderdetails.isDelivered)"></el-switch>
                                        </span>
                                        <span v-else>
                                            <el-switch v-model="orderdetails.isDelivered"
                                                @change="deliverOrder(orderdetails.isDelivered)"></el-switch>
                                        </span>
                                    </div>
                                    <div class="col pull-right" v-if="orderdetails.showHold">
                                        <span class="text-white pr-3">Hold Order :</span>
                                        <el-switch v-model="orderdetails.isOrderOnHold"
                                            @change="holdOrderApiCall(orderdetails.isOrderOnHold)"></el-switch>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="border-right: 1px solid #fff;">
                                <div class="d-block pb-3">
                                    <div class="d-flex">
                                        <div class="col-3" style="white-space: nowrap;">Order Status</div>
                                        <div class="col text-right">
                                            <el-tooltip class="item" effect="dark" content="Add Item" placement="top">
                                                <span v-if="!orderdetails.isPrepaid" class="mr-4"
                                                    style="cursor:pointer;" @click="addMoreItems(orderdetails._id)">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus.svg" />
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Hold" placement="top">
                                                <span style="cursor:pointer;" class="mr-4" v-if="orderdetails.showHold"
                                                    @click="holdOrderApiCall(true)">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/on-hold.svg" />
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Order Summary"
                                                placement="top">
                                                <span class="mr-3" style="cursor:pointer;"
                                                    @click="showPayBill(orderdetails._id)">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/newspaper.svg" />
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Move Items" placement="top">
                                                <span
                                                    v-if="!orderdetails.isPrepaid && orderdetails.order_type == 'Dine In'"
                                                    class="mr-4" style="cursor:pointer;"
                                                    @click="showMoveItems(orderdetails._id,orderdetails)">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/hold-mamnage.svg" />
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                <span @click="DeleteOrderModal(orderdetails._id)" class="mr-4"
                                                    style="cursor:pointer;"
                                                    v-if="loggedInUser.role != 'default_order_taker' && !orderdetails.isPrepaid">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/trash-manage.svg" />
                                                </span>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="view-vizard-big d-flex mt-6 mb-3">
                                        <div class="stepwizard" v-if="orderdetails.order_status"
                                            style="left:6% !important;">
                                            <div class="stepwizard-row setup-panel maxstepwizard">
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.active == 1,'redCircle': orderdetails.order_status.active == 0,'orangeCircle': orderdetails.order_status.active == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.active == 1,'redCircle': orderdetails.order_status.active == 0,'orangeCircle': orderdetails.order_status.active == 2}">O</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.fire == 1,'redCircle': orderdetails.order_status.fire == 0,'orangeCircle': orderdetails.order_status.fire == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.fire == 1,'redCircle': orderdetails.order_status.fire == 0,'orangeCircle': orderdetails.order_status.fire == 2}">S</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.prepared == 1,'redCircle': orderdetails.order_status.prepared == 0,'orangeCircle': orderdetails.order_status.prepared == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.prepared == 1,'redCircle': orderdetails.order_status.prepared == 0,'orangeCircle': orderdetails.order_status.prepared == 2}">F</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.delivered == 1,'redCircle': orderdetails.order_status.delivered == 0,'orangeCircle': orderdetails.order_status.delivered == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.delivered == 1,'redCircle': orderdetails.order_status.delivered == 0,'orangeCircle': orderdetails.order_status.delivered == 2}">D</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block pb-3 display_status_checkbox">
                                    <div class="col pb-1">Display Status for :</div>
                                    <div class="col">
                                        <el-checkbox v-model="orderCourse_display" :disabled="!disable_course">
                                            <span style="color:#fff !important;font-size: 13px !important;"
                                                class="font-weight-bold">Courses</span>
                                        </el-checkbox>
                                        <el-checkbox v-model="orderItem_display" :disabled="!disable_item">
                                            <span style="color:#fff !important;font-size: 13px !important;"
                                                class="font-weight-bold">Items</span>
                                        </el-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 position-relative">
                                Notes:
                                <textarea class="text-area-manage pr-7" v-model="orderdetails.order_note"
                                    :disabled="order_note_disabled"></textarea>
                                <div v-if="order_note_disabled" class="position-absolute" style="right: 25px;top: 25px;">
                                    <i @click="editOrderNoteEnable()" class="icon icon-pencil text-white fs-16 pointer"></i>
                                </div>
                                <div v-else class="position-absolute" style="right: 25px;top: 25px;">
                                    <i @click="editOrderNoteDisable()" class="icon icon-close text-white fs-16 pointer"></i>
                                </div>
                                <button v-if="!order_note_disabled" @click="saveOrderNoteEdit(orderdetails.order_note)" class="btn btn-success btn-smm-new text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-content-card" style="overflow: auto;">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="color:#303031!important;">ITEMS</th>
                            <th></th>
                            <th></th>
                            <th class="text-center" style="color:#303031!important;">QTY</th>
                            <th style="color:#303031!important;">STARTED?</th>
                            <th style="color:#303031!important;">FINISHED?</th>
                            <th style="color:#303031!important;">DELIVERED?</th>
                            <th class="text-center" style="width: 20%;color:#303031!important;">STATUS</th>
                            <th style="width: 1% !important;color:#303031!important;"></th>
                        </tr>
                    </thead>
                    <tbody v-if="ajaxCallInProgress">
                        <tr>
                            <td colspan="8" class="text-center">
                                <div class="digi-table-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else-if="orderdetailsCourse.length <=0">
                        <tr>
                            <td colspan="8" class="text-center">
                                <img
                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_data_view_order.svg" />
                                <br />
                                <span class="text-secondary font-600" style="font-size:18px!important;">
                                    All Items are Deleted or Moved
                                    <br />to Another Table
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody style="background: #f6f6f6!important;" v-else>
                        <template v-for="(course,cindex) in orderdetailsCourse">
                            <tr style="background-color: #ddecff;height: 40px;" :key="cindex">
                                <td style="padding:0.2rem 1.2rem;color: #303031;" class="font-600">Course
                                    {{course.course}}</td>
                                <td style="padding:0.2rem 1.2rem;color: #303031;" class="whiteSpace font-600">
                                    <span>Send Together : </span>
                                    <el-switch v-model="course.send_together"
                                        @change="sendTogetherCourse(course.send_together,course.course)"></el-switch>
                                </td>
                                <td style="padding:0.2rem 1.2rem;color: #303031;" class="whiteSpace font-600"
                                    v-if="course.showHold">
                                    <span v-if="!course.isHold">Hold Course : </span>
                                    <span v-else>Remove Hold : </span>
                                    <el-switch v-model="course.isHold"
                                        @change="holdCourse(course.isHold,course.course)"></el-switch>
                                </td>
                                <td v-else></td>
                                <td style="padding:0.2rem 1.2rem;color: #303031;"></td>
                                <td v-if="course.isHold" class="font-600"
                                    style="color: #303031;padding:0.2rem 1.2rem!important;">
                                    <el-tooltip content="On Hold" effect="dark" placement="top">
                                        <span class="ml-3">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg"
                                                style="width: 19px;height: 30px!important; margin-left: -3px;" />
                                        </span>
                                    </el-tooltip>
                                </td>
                                <td style="padding:0.2rem 1.2rem!important;" v-else>
                                    <el-checkbox v-if="course.showFire" :disabled="(course.isFired && course.canFire) || course.isRefund"
                                        @input="courseFire(course.isFired,course.course)" class="orderPrepared"
                                        style="left: 8px;" v-model="course.isFired"></el-checkbox>
                                    <span></span>
                                </td>
                                <td class="tdcart font-600" style="color: #303031;padding:0.2rem 1.2rem!important;">
                                    <el-checkbox v-if="course.showPrepared"
                                        :disabled="!course.isFired || course.isDelivered || loggedInUser.role == 'default_order_taker' || course.isHold || course.isRefund"
                                        class="orderPrepared" @input="coursePrepare(course.isPrepared,course.course)"
                                        style="left: 8px;" v-model="course.isPrepared"></el-checkbox>
                                </td>
                                <td class="tdcart font-600" style="color: #303031;padding:0.2rem 1.2rem!important;">
                                    <el-checkbox v-if="course.showDelivered"
                                        :disabled="!course.isFired || !course.isPrepared || course.isRefund" class="orderPrepared"
                                        style="left: 8px;" @input="courseDelivered(course.isDelivered,course.course)"
                                        v-model="course.isDelivered"></el-checkbox>
                                </td>
                                <td style="padding:0.2rem 1.2rem;" class="text-center" v-if="orderCourse_display">
                                    <div class="view-vizard-small d-flex" v-if="course.course_status && !course.isRefund">
                                        <div class="stepwizard">
                                            <div class="stepwizard-row setup-panel">
                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer greenCircle">
                                                        <a class="btn-circle greenCircle">O</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.fire == 1,'redCircle': course.course_status.fire == 0,'orangeCircle': course.course_status.fire == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.fire == 1,'redCircle': course.course_status.fire == 0,'orangeCircle': course.course_status.fire == 2}">S</a>
                                                    </div>
                                                </div>

                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.prepared == 1,'redCircle': course.course_status.prepared == 0,'orangeCircle': course.course_status.prepared == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.prepared == 1,'redCircle': course.course_status.prepared == 0,'orangeCircle': course.course_status.prepared == 2}">F</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.delivered == 1,'redCircle': course.course_status.delivered == 0,'orangeCircle': course.course_status.delivered == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.delivered == 1,'redCircle': course.course_status.delivered == 0,'orangeCircle': course.course_status.delivered == 2}">D</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" v-else-if="course.course_status && course.isRefund">
                                        <span class="fs-14 text-danger fw-600">REFUND</span>
                                    </div>
                                </td>
                                <td v-else></td>
                                <td>
                                    <!-- <template v-if="orderdetails.can_item_refund">
                                        <span v-if="(loggedInUser.role == 'default_admin' || loggedInUser.role == 'default_manager') && orderdetails.isPrepaid && !course.isRefund" @click="showRefundPopup(course.course, orderdetails._id)">
                                            <img src="/static/images/refund_icon.svg" width="16" style="width: 20px;height: 20px !important;" :class="[order._id, 'collapse-icon', 'show']" />
                                        </span>
                                    </template> -->
                                </td>
                            </tr>
                            <template v-for="(item,index) in course.item_details">
                                <tr style="height: 40px;" :key="index">
                                    <td style="padding:0.4rem 1.2rem;" class="text-secondary font-600">
                                        <el-tooltip v-if="item.product_type == 'Non-Veg'" content="Non-Veg"
                                            placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                style="margin-top: -4px;width: 11px;" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.product_type == 'Veg'" content="Veg"
                                            placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                style="margin-top: -4px;width: 11px;" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.product_type == 'Egg'" content="Egg"
                                            placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                style="margin-top: -4px;width: 11px;" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.product_type == 'Pescatarian'" content="Pescatarian"
                                            placement="top">
                                            <img style="width:11px;margin-top: -4px;"
                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme//https://cdn.digicollect.com/cdn/posv2/psec.svg" />
                                        </el-tooltip>
                                        <span v-if="item.hasVariant">
                                            <span style="word-break:break-all;" v-if="item.variant_name != ''">{{item.product_name}}</span>
                                            <span style="word-break:break-all;" v-else>{{item.product_name}}</span>
                                            <span v-if="item.is_combo">
                                                <el-popover placement="left-end" width="200" trigger="hover"
                                                    popper-class="combo-popover">
                                                    <div v-for="(ic,index) in item.combo_details" :key="index">
                                                        <span>{{ic.product_name}} X {{ic.quantity}}</span>
                                                    </div>
                                                    <el-button slot="reference"
                                                        style="background: transparent !important;padding:12px 0px !important;">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                    </el-button>
                                                </el-popover>
                                            </span>
                                        </span>
                                        <span style="word-break:break-all;" v-else>{{item.product_name}}
                                            <span v-if="item.is_combo">
                                                <el-popover placement="left-end" width="200" trigger="hover"
                                                    popper-class="combo-popover">
                                                    <div v-for="(ic,index) in item.combo_details" :key="index">
                                                        <span>{{ic.product_name}} X {{ic.quantity}}</span>
                                                    </div>
                                                    <el-button slot="reference"
                                                        style="background: transparent !important;padding:12px 0px !important;">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                    </el-button>
                                                </el-popover>
                                            </span>
                                        </span>
                                        <br />
                                        <span v-if="item.item_note !=''">
                                            Note :
                                            <span :title="item.item_note" style="color:red!important;text-overflow:ellipsis;overflow:hidden;max-width:150px;width:150px;display:block;">{{item.item_note}}</span>
                                        </span>
                                    </td>
                                    <td style="padding:0.4rem 1.2rem;" class="text-center"></td>
                                    <td style="padding:0.4rem 1.2rem;" class="text-center"></td>
                                    <td class="text-secondary font-600 text-center" style="padding:0.4rem 1.2rem;">
                                        {{item.selected_quantity}}</td>
                                    <td style="padding:0.4rem 1.2rem;" class="tdcart"
                                        :class="{'pointerEventsNone':item.isFired}"
                                        v-if="!item.isHold && !item.unAvailable">
                                        <el-checkbox class="orderPrepared" style="left: 8px;" v-model="item.isFired"
                                            @input="itemFire(item)" :disabled="item.isDelivered || item.isPrepared || item.item_status == 'refund'">
                                        </el-checkbox>
                                    </td>
                                    <td v-if="item.isHold" style="padding:0.2rem 1.2rem;">
                                        <el-tooltip content="On Hold" effect="dark" placement="top">
                                            <span class="ml-3">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg"
                                                    style="width: 19px;height: 30px!important; margin-left: -3px;" />
                                            </span>
                                        </el-tooltip>
                                    </td>
                                    <td v-if="item.unAvailable" style="padding:0.2rem 1.2rem;">
                                        <el-tooltip class="item" effect="dark" content="Item Unavailable"
                                            placement="top-start">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/item_unavailable.svg"
                                                style="width: 34px!important;height: 19px!important;" />
                                        </el-tooltip>
                                    </td>
                                    <td class="tdcart" style="padding:0.4rem 1.2rem;">
                                        <el-checkbox class="orderPrepared" style="left: 8px;"
                                            @input="itemPreparded(item)" v-model="item.isPrepared"
                                            :disabled="!item.isFired || item.isDelivered || loggedInUser.role == 'default_order_taker' || item.unAvailable || item.isHold || item.item_status == 'refund'">
                                        </el-checkbox>
                                    </td>
                                    <td class="tdcart" style="padding:0.4rem 1.2rem;">
                                        <el-checkbox class="orderPrepared" style="left: 8px;" v-model="item.isDelivered"
                                            @input="itemDelivered(item)" :disabled="!item.isFired || !item.isPrepared || item.item_status == 'refund'">
                                        </el-checkbox>
                                    </td>
                                    <td style="padding:0.4rem 1.2rem;" class="text-center" v-if="orderItem_display">
                                        <div class="" v-if="item.item_status == 'refund'">
                                            <span class="fs-14 text-danger fw-600">REFUND</span>
                                        </div>
                                        <div class="view-vizard-small d-flex" v-else>
                                            <div class="stepwizard">
                                                <div class="stepwizard-row setup-panel">
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer greenCircle">
                                                            <a class="btn-circle greenCircle">O</a>
                                                        </div>
                                                    </div>
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer"
                                                            :class="{ 'greenCircle': item.isFired,'redCircle':!item.isFired}">
                                                            <a class="btn-circle"
                                                                :class="{ 'greenCircle':item.isFired,'redCircle':!item.isFired}">S</a>
                                                        </div>
                                                    </div>
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer"
                                                            :class="{ 'greenCircle': item.isPrepared,'redCircle':!item.isPrepared}">
                                                            <a class="btn-circle"
                                                                :class="{ 'greenCircle': item.isPrepared,'redCircle':!item.isPrepared}">F</a>
                                                        </div>
                                                    </div>
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer"
                                                            :class="{ 'greenCircle': item.isDelivered,'redCircle':!item.isDelivered}">
                                                            <a class="btn-circle"
                                                                :class="{ 'greenCircle': item.isDelivered,'redCircle':!item.isDelivered}">D</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else></td>
                                    <td style="padding:0.4rem 1.2rem;">
                                        <div v-if="item.item_status != 'refund'" class="d-flex align-items-center">
                                            <el-dropdown>
                                                <span class="el-dropdown-link pointer">
                                                    <i class="icon icon-dots-vertical iconHorizontal"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown" class="waiterDropdown">
                                                    <span @click="itemAvailable(item)"
                                                        v-if="!item.isHold && loggedInUser.role != 'default_order_taker' && !item.isDelivered">
                                                        <el-dropdown-item v-if="!item.unAvailable">Unavailable
                                                        </el-dropdown-item>
                                                        <el-dropdown-item v-else>Available</el-dropdown-item>
                                                    </span>
                                                    <span v-if="item.show_delete && loggedInUser.access_remove_item && !orderdetails.isPrepaid "
                                                        @click="DeleteModal(item.item_id,item.selected_quantity,item.product_name,item.variant_name)">
                                                        <el-dropdown-item>Delete</el-dropdown-item>
                                                    </span>
                                                    <span @click="itemHold(item)"
                                                        v-if="!item.unAvailable && loggedInUser.role != 'default_order_taker' && !item.isDelivered && item.isFired">
                                                        <el-dropdown-item v-if="item.isHold">Unhold</el-dropdown-item>
                                                        <el-dropdown-item v-else>Hold</el-dropdown-item>
                                                    </span>
                                                    <span @click="addNoteModal(cindex, index)" >
                                                        <el-dropdown-item>{{item.item_note ? 'Edit Item Note' : 'Add Item Note'}}</el-dropdown-item>
                                                    </span>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                            <span v-if="item.approval_message != ''">
                                                <el-tooltip class="item" effect="dark" :content="item.approval_message" placement="top">
                                                    <i class="icon icon-information" style="width: 15px !important;height: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                                </el-tooltip>
                                            </span>
                                            <el-tooltip class="item" effect="dark" content="WithDraw" placement="top">
                                                <i class="icon-minus-circle text-danger pointer ml-2" v-if="item.show_delete == false" @click="withdrawItem(orderdetails._id, item.item_id, 'item')" style="width: 15px !important;font-size: 15px !important;color: #00448b;position: relative;"></i>
                                            </el-tooltip>
                                        </div>
                                    </td>
                                </tr>
                                <tr :key="index" style="height: auto !important;">
                                    <td colspan="9" style="padding: 0 !important;">
                                        <div class="b-r-7 pl-2 pt-2 pb-1 ml-1 mr-3" style="background-color:#fff !important;border-radius: 0px 0px 4px 4px;">
                                            <div class="text-blue font-600 fs-14 py-1">
                                                <span>{{item.variant_name || ""}}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <template v-if="item.modifiers">
                                <tr v-if="item.modifiers.hasOwnProperty('for_secondary') || item.modifiers.hasOwnProperty('for_primary')" :key="index">
                                    <td colspan="9" style="padding: 0px!important;">
                                        <div class="b-r-7 pl-2 pt-2 pb-1 ml-1 mr-3 mb-2"
                                            style="background-color:#fff !important;border-radius: 0px 0px 4px 4px;">
                                            <div>
                                                <div class="bg-white mb-2" v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                    <span class="font-weight-bold">{{modifier.m_item_name}}</span>
                                                    <span class="text-green ml-1 font-weight-bold" v-if="modifier.quantity > 0"> X {{modifier.quantity}}</span>
                                                </div>
                                                
                                                <div v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index"
                                                    class="d-block w-100 mb-2 bg-white">
                                                    <div class="text-blue font-600 fs-14 py-1">
                                                        <span>{{modifier.modifier_name}}</span>
                                                    </div>
                                                    <div class="col pl-0 mb-1" v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                        <div class="d-flex ml-0 pl-0">
                                                            <span class="font-weight-bold"
                                                                >{{modifier_item.m_item_name}}</span>
                                                            <span v-if="modifier_item.quantity > 0" class="text-green ml-1 font-weight-bold"> X {{modifier_item.quantity}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </template>
                                <!-- <tr v-if="item.modifiers.length>0" :key="index">
                                    <td colspan="9" style="padding: 0px!important;">
                                        <div class="b-r-7 pl-2 pt-1 pb-1 ml-3 mr-3 mb-2"
                                            style="background-color:#fff !important;border-radius: 12px;">
                                            <div class="text-green font-600">Modifiers :</div>
                                            <div class="d-flex flex-wrap mt-1">
                                                <div v-for="(modifier,modifier_index) in item.modifiers" :key="modifier_index"
                                                    class="d-flex justify-content-between align-items-center">
                                                    <div class="col pl-0 mb-1">
                                                        <div class="d-flex flex-wrap ml-0 pl-0">
                                                            <span class="fw-bold text-capitalize whiteSpace" style="color: #000 !important;">{{modifier.modifier_name}}</span>
                                                            <span class="ml-1" v-if="modifier.modifier_list.length > 0">-</span>
                                                            <span v-for="(modifier_item, modifier_item_index) in modifier.modifier_list" :key="'modifier_item_index'+modifier_item_index" class="ml-1 whiteSpace text-capitalize"
                                                                >
                                                                {{modifier.modifier_list[modifier_item_index]}}
                                                                <span v-if="modifier.modifier_list.length > 1 && (modifier_item_index < (modifier.modifier_list.length - 1))">,</span>
                                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->
                            </template>
                        </template>
                    </tbody>
                </table>
                <div class="text-center pt-3 pb-3">
                    <button class="btn btn-smm btn-secondary" style="background-color: #1a9347;"
                        v-if="orderdetails.isReadyToClose && orderdetails.isPrepaid"
                        @click="showPayBill(orderdetails._id)">Close Order</button>
                    <button class="btn btn-smm btn-secondary"
                        v-if="orderdetails.isReadyToClose && !orderdetails.isPrepaid"
                        @click="showPayBill(orderdetails._id)" style="background-color: #1a9347;">Pay and Close</button>
                </div>
            </div>
        </div>
        <div class="onholddiv" v-if="orderdetails.isOrderOnHold">
            <button class="btn btn-checkout" @click="unholdOrderApiCall(orderdetails.isOrderOnHold)">Remove
                Hold</button>
        </div>
        <pay-bill :for_approvals="false" @update_cust="update_cust" @updateOrderItems="addMoreItems(orderdetails._id)" modal_name="pay-bill" :summary="order" :isPopUp="isPopUp"
            @updateCheckout="updateSummary" :editOrder="false" :inProgress="inProgress" :isDelivered="isDelivered"
            @close="closePayBill" v-if="showbillpay" :storeorderId="storeorderId" :sourcemanage="'viewOrder'">
        </pay-bill>
        <delete-item @hideDeleteItemModal="hideDeleteItemModal" @item_delete_success="itemDeleteSuccess" :order_id="order_id" :delete_quantity="delete_quantity" :item_id="item_id"
            :product_name="product_name" :product_quantity="delete_quantity" :DeleteOrderDiv="DeleteOrderDiv"
            :temp_order_id="temp_order_id" modal_name="delete-item-modal" :isOrderActive="true"
            @checkorderStatus="checkorderStatus" @checkitemStatus="checkitemStatus" v-if="deletevieworder">
        </delete-item>
        <move-items @hideMoveItems="hideMoveItems" v-if="moveItemsModal == true" :move_orders="order"
            :created_time="start_time" modal_name="move-items"></move-items>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
        <add-note modal_name="add-note-checkout" v-if="show_add_note_item" :note="note" @addNote="addItemNote" @closeNote="AddNoteModalHide"></add-note>
        <view-order-refund v-if="is_view_order_refund" from_which_page="view_order" :order_id="order_id" :course_number="course_number" @hideRefundPop="hideRefundPopup" modal_name="view-order-refund" ></view-order-refund>
    </div>
</template>
<script>
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'
    import ManageOrderService from './mixins/manage_order.js'
    import orderServices from './mixins/orders'
    import PayBill from './PayBill'
    import DeleteItem from './DeleteItem'
    import {
        SweetModal
    } from "sweet-modal-vue";
    import AddNote from "./AddNote";
    import Swal from 'sweetalert2'
    import printers from "./mixins/printers";
    const MoveItems = () => import('./MoveItems.vue')
    const ViewOrderRefund = () => import('./ViewOrderRefund')
    export default {
        mixins: [ManageOrderService, orderServices, printers],
        data() {
            return {
                hold_order: '',
                hold_course: '',
                modal_msg: '',
                orderdetails: '',
                orderdetailsCourse: '',
                showPreparedCard: false,
                isPrepared: false,
                isDelivered: false,
                isHold: false,
                isFired: false,
                orderType: null,
                hold_Status: false,
                order: '',
                isPopUp: false,
                order_id: '',
                delete_quantity: '',
                item_id: '',
                product_name: '',
                product_quantity: '',
                DeleteOrderDiv: false,
                temp_order_id: '',
                selectedOrderId: '',
                moveItemsModal: false,
                start_time: '',
                order_id: null,
                orderCourse_display: false,
                orderItem_display: false,
                disable_course: false,
                disable_item: false,
                inProgress: false,
                deletevieworder: false,
                showbillpay: false,
                ajaxCallInProgress: false,
                storeorderId: '',
                order_note_disabled: true,
                show_add_note_item: false,
                note: {
                    courseIndex: null,
                    itemIndex: null,
                    value: "",
                    name: null
                },
                is_view_order_refund: false,
                course_number: 0,
            }
        },
        components: {
            PayBill,
            DeleteItem,
            MoveItems,
            SweetModal,
            AddNote,
            ViewOrderRefund
        },
        methods: {
            showRefundPopup(course, order_id) {
                this.course_number = course;
                this.order_id = order_id
                this.is_view_order_refund = true;
                setTimeout(() => {
                    this.$modal.show("view-order-refund")
                }, 500);
            },
            hideRefundPopup() {
                this.is_view_order_refund = false;
                setTimeout(() => {
                    this.$modal.hide("view-order-refund")
                    this.getOrderById()
                }, 500);
            },
            editOrderNoteEnable() {
                this.order_note_disabled = false;
            },
            editOrderNoteDisable() {
                this.order_note_disabled = true;
            },
            async saveOrderNoteEdit(note) {
                try {
                    // let category_data = []
                    // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                    // if(data){
                    //     data.forEach(y => {
                    //         y.printers.forEach(x => {
                    //             if(y._id == x.category_id){
                    //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                    //             }
                    //         })
                    //     })
                    // }
                    let params = {
                        orderId: this.$route.params.id,
                        note: note,
                        // printer_details: category_data
                    };
                    let res = await this.orderNote(params);
                    this.$toasted.global.success("Successfully Updated Order Note");
                    this.order_note_disabled = true;
                    this.getOrderById()
                    // if(data){
                        // data.forEach(y => {
                            // y.printers.forEach(x => {
                                if(res.ticket_details.length == 1){
                                    res.ticket_details.forEach(z => {
                                        // if(x.category_id == z.category_id){
                                            this.store_ticket_data = z
                                            Swal({
                                                title: 'Print Tickets',
                                                text: 'Do You Want to Print Ticket ?',
                                                type: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#00448b',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Yes',
                                                cancelButtonText: 'Continue Without Printing',
                                                allowOutsideClick: false
                                            }).then(result => {
                                                if (result.value) {
                                                    this.printTicket(z)
                                                } else {
                                                }
                                            })
                                        // }
                                    })
                                }
                                else{
                                    Swal({
                                        title: 'Print Tickets',
                                        text: 'Do You Want to Print Tickets ?',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#00448b',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'Continue Without Printing',
                                        allowOutsideClick: false
                                    }).then(result => {
                                        if (result.value) {
                                            res.ticket_details.forEach(z => {
                                                this.store_ticket_data = z
                                                this.printTicket(z)
                                            })
                                        } else {
                                        }
                                    })
                                }
                            // })
                        // })
                    // }
                }
                catch(error) {
                    this.$toasted.global.error(error);
                    this.order_note_disabled = true;
                }
            },
            addNoteModal(cindex, itemIndex) {
                this.note.courseIndex = cindex;
                this.note.itemIndex = itemIndex;
                this.note.value = this.orderdetailsCourse[cindex].item_details[itemIndex].item_note;
                let name = this.orderdetailsCourse[cindex].item_details[itemIndex].product_name;
                if (this.orderdetailsCourse[cindex].item_details[itemIndex].variant_name) {
                    name += "/" + this.orderdetailsCourse[cindex].item_details[itemIndex].variant_name;
                }
                this.note.name = name;
                this.show_add_note_item = true;
                setTimeout(() => {
                    this.$modal.show("add-note-checkout");
                }, 500);
            },
            AddNoteModalHide() {
                this.show_add_note_item = false;
                setTimeout(() => {
                    this.$modal.hide("add-note-checkout");
                }, 500);
            },
            async addItemNote(note) {
                try {
                    // let category_data = []
                    // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                    // if(data){
                    //     data.forEach(y => {
                    //         y.printers.forEach(x => {
                    //             if(y._id == x.category_id){
                    //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                    //             }
                    //         })
                    //     })
                    // }
                    let item = this.orderdetailsCourse[note.courseIndex].item_details[note.itemIndex];
                    let params = {
                        itemId: item.item_id,
                        orderId: this.$route.params.id,
                        note: note.value,
                        // printer_details: category_data
                    };
                    let res = await this.orderAddItemNote(params);
                    this.modal_msg = res.message
                    if (res.status_id == 1) {
                        this.$toasted.global.success(this.modal_msg);
                    } else {
                        this.$toasted.global.error(this.modal_msg);
                    }
                    this.getOrderById()
                    this.show_add_note_item = false;
                    setTimeout(() => {
                        this.$modal.hide("add-note-checkout");
                    }, 500);
                    // if(data){
                        // data.forEach(y => {
                            // y.printers.forEach(x => {
                                if(res.ticket_details.length == 1){
                                    res.ticket_details.forEach(z => {
                                        // if(x.category_id == z.category_id){
                                            this.store_ticket_data = z
                                            Swal({
                                                title: 'Print Tickets',
                                                text: 'Do You Want to Print Ticket ?',
                                                type: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#00448b',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Yes',
                                                cancelButtonText: 'Continue Without Printing',
                                                allowOutsideClick: false
                                            }).then(result => {
                                                if (result.value) {
                                                    this.printTicket(z)
                                                } else {
                                                }
                                            })
                                        // }
                                    })
                                }
                                else{
                                    Swal({
                                        title: 'Print Tickets',
                                        text: 'Do You Want to Print Tickets ?',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#00448b',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'Continue Without Printing',
                                        allowOutsideClick: false
                                    }).then(result => {
                                        if (result.value) {
                                            res.ticket_details.forEach(z => {
                                                this.store_ticket_data = z
                                                this.printTicket(z)
                                            })
                                        } else {
                                        }
                                    })
                                }
                            // })
                        // })
                    // }
                } catch (reason) {
                    this.$toasted.global.error(reason);
                }
            },
            async withdrawItem(order_id, order_item_id, type) {
                try {
                    let params = {
                        order_id: order_id,
                    };
                    if(type == 'item') {
                        params.order_item_id = order_item_id
                        params.action = 'delete_order_item'
                    } else if(type == 'order') {
                        params.action = 'order_discount'
                    }
                    let response = await this.withdrawItemAndOrder(params);
                    this.modal_msg = response.message;
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close()
                        this.getOrderById()
                    }, 2000);
                }
                catch(err) {
                    this.modal_msg = err;
                    this.$refs.warning_modal.open();
                    setTimeout(() => this.$refs.warning_modal.close(), 2000);
                }
            },
            itemDeleteSuccess() {
                this.getOrderById()
                this.deletevieworder = false
                setTimeout(() => {
                    this.$modal.hide('delete-item-modal')
                }, 500);
                //this.$router.push('/manageorder')
            },
            hideDeleteItemModal(){
                console.log('hideDeleteItemModal')
                this.deletevieworder = false
                this.getOrderById()
            },
            hideMoveItems() {
                this.getOrderById()
                this.moveItemsModal = false
                this.$modal.hide('move-items')
            },
            showMoveItems(order_id, order) {
                this.start_time = order.start_time
                if (order_id) {
                    this.selectedOrderId = order_id
                }
                this.$http.post('/orders/summary', {
                        order_id: this.selectedOrderId,
                        in_progress: true,
                        for_move_items: true
                    })
                    .then(res => {
                        if (res.data.status_id == 1) {
                            this.order = res.data.order
                            this.inProgress = true
                            this.isDelivered = false
                            this.moveItemsModal = true
                            setTimeout(() => {
                                this.$modal.show('move-items')
                            }, 300)
                        } else {
                            this.$toasted.global.error(res.data.reason)
                        }
                    })
            },
            DeleteModal(item_id, quantity, product_name, variant_name = '') {
                this.deletevieworder = true
                this.item_id = item_id
                this.delete_quantity = quantity
                this.order_id = this.$route.params.id
                if (variant_name) {
                    this.product_name = product_name + '/' + variant_name
                } else {
                    this.product_name = product_name
                }
                this.temp_order_id = ''
                this.DeleteOrderDiv = false
                setTimeout(() => {
                    this.$modal.show('delete-item-modal')
                }, 500);
            },
            DeleteOrderModal(order_id, temp_order_id) {
                this.deletevieworder = true
                this.item_id = ''
                this.delete_quantity = ''
                this.product_name = ''
                this.order_id = order_id
                this.temp_order_id = temp_order_id
                this.DeleteOrderDiv = true
                this.$modal.show('delete-item-modal')
            },
            cancelDeleteItem() {
                this.deletevieworder = false
                this.$modal.hide('delete-item-modal')
            },
            addMoreItems(orderId) {
                if (this.orderdetails.order_type == 'Dine In') {
                    this.$router.push({
                        name: 'TakeOrderNew',
                        params: {
                            orderId,
                            editOrder: true,
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'TakeOrderNew',
                        params: {
                            orderId,
                            editOrder: true,
                            orderType: this.orderdetails.order_type
                        }
                    })
                }
            },
            updateSummary() {
                this.showPayBill()
            },
            update_cust() {
                this.getOrderById()
            },
            closePayBill(boolean) {
                this.showbillpay = false
                this.$modal.hide('pay-bill')
                if (boolean) {
                    this.$router.push({
                        name: 'ManageOrder'
                    })
                }
            },
            showPayBill(order_id) {
                this.storeorderId = order_id
                this.showbillpay = true
                if (order_id) {
                    this.selectedOrderId = order_id
                }
                let params = {
                    order_id: this.selectedOrderId,
                    web: true
                }
                if (!this.orderdetails.isDelivered) params.in_progress = true
                this.$http.post('/orders/summary', params).then(res => {
                    if (res.data.status_id == 1) {
                        this.order = res.data.order
                        EventBus.$emit('summaryChanged', res.data.order)
                        this.isDelivered = true
                        this.inProgress = false
                        setTimeout(() => {
                            this.$modal.show('pay-bill')
                        }, 100)
                    } else {
                        this.$toasted.global.error(res.data.reason)
                    }
                })
            },
            manageorderScreen() {
                if (this.$route.params && this.$route.params.dineIn) {
                    this.$router.push({
                        name: 'OrderTableView'
                    })
                } else {
                    this.$router.push({
                        name: 'ManageOrder',
                        params: {
                            orderType: this.$route.params.orderType
                        }
                    })
                }
            },
            checkorderStatus() {
                this.manageorderScreen()
            },
            async checkitemStatus() {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'WEB'
                    }
                    if (params.order_id) {
                        let res = await this.getorderDetails(params)
                        if (res.response.course_details.length) {
                            this.maintainOrderState(res)
                        } else {
                            this.manageorderScreen()
                        }
                    }
                } catch (reason) {}
            },
            maintainOrderState(res) {
                this.orderdetails = res.response.order_details
                this.orderdetailsCourse = res.response.course_details
                for (var course_index in this.orderdetailsCourse) {
                    for (var item_index in this.orderdetailsCourse[course_index].item_details) {
                        if (this.orderdetailsCourse[course_index].item_details[item_index].item_status == 'prepared') {
                            this.orderdetailsCourse[course_index].item_details[item_index].preparedCount++
                            this.orderdetailsCourse[course_index].item_details[item_index].isPrepared = true
                            this.orderdetailsCourse[course_index].item_details[item_index].isHold = false
                            this.orderdetailsCourse[course_index].item_details[item_index].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[item_index].isFired = true
                            this.orderdetailsCourse[course_index].item_details[item_index].isDelivered = false
                        } else if (this.orderdetailsCourse[course_index].item_details[item_index].item_status ==
                            'placed') {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'preparing'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'delivered'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'hold'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'unavailable'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                        } else {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = false
                        }
                    }
                }
            },
            async getOrderById() {
                try {
                    this.ajaxCallInProgress = true
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'WEB'
                    }
                    if (params.order_id) {
                        let res = await this.getorderDetails(params)
                        console.log(res)
                        this.stateMaintain(res)
                        this.maintainOrderState(res)
                        this.ajaxCallInProgress = false
                    }
                } catch (reason) {
                    if(reason == 'Order Not Found') {
                        this.$router.push('/manageorder')
                    } else {
                        this.$toasted.global.error(reason)
                    }

                }
            },
            stateMaintain(res) {
                if (res.response.order_details.display_status) {
                    this.disable_course = res.response.order_details.display_status.courses
                    this.disable_item = res.response.order_details.display_status.items
                    this.orderCourse_display =
                        res.response.order_details.display_status.courses
                    this.orderItem_display = res.response.order_details.display_status.items
                }
            },
            // Send Together For Order,Course
            async sendAllTogetherOrder(sendallTogether) {
                try {
                    let params = {
                        target: 'ORDER',
                        action: sendallTogether,
                        order_id: this.$route.params.id,
                        get_details: true
                    }
                    let res = await this.sendAllTogetherApiCall(params)
                    let temp_res = {
                        response: res
                    }
                    this.maintainOrderState(temp_res)
                    this.$toasted.global.success(res.message)
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async sendTogetherCourse(sendtogethercourse, courseno) {
                try {
                    let params = {
                        target: 'COURSE',
                        action: sendtogethercourse,
                        course: courseno,
                        order_id: this.$route.params.id,
                        get_details: true
                    }
                    let res = await this.sendAllTogetherApiCall(params)
                    let temp_res = {
                        response: res
                    }
                    this.maintainOrderState(temp_res)
                    this.$toasted.global.success(res.message)
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Order Operations
            async prepareOrder(isprepared) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isPrepared: isprepared,
                        get_details: true
                    }
                    let res = await this.orderPrepareApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async deliverOrder(isdelivered) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isDelivered: isdelivered,
                        get_details: true
                    }
                    let res = await this.orderDeliveApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            movePage(ticket_data) {
                // this.$toasted.global.success(this.responseMessage);
                if(this.loggedInUser.print_receipt_by_default) {
                    this.printTicket(ticket_data)
                    this.$emit("close", true);
                    this.placing_order_in_progress = false
                } else {
                    Swal({
                        title: 'Print Tickets',
                        text: 'Do You Want to Print Ticket ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#00448b',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Continue Without Printing',
                        allowOutsideClick: false
                    }).then(result => {
                        if (result.value) {
                            this.printTicket(ticket_data)
                            this.$emit("close", true);
                            this.placing_order_in_progress = false
                        } else {
                            this.$emit("close", true);
                            this.placing_order_in_progress = false
                        }
                    })
                }
            },
            // Fire For Order,Course,Item
            async orderFire(fireOrder) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'ORDER',
                        action: fireOrder,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        // this.$toasted.global.success(res.response.message)
                        this.$toasted.global.success('Started Successfully')
                        if(res.response.ticket_details.length > 0){
                        if(res.response.ticket_details.length == 1){
                            res.response.ticket_details.forEach(z => {
                                this.store_ticket_data = z
                                this.movePage(z);
                            })
                        }
                        else{
                            if(this.loggedInUser.print_receipt_by_default) {
                                res.response.ticket_details.forEach(z => {
                                    this.store_ticket_data = z
                                    this.printTicket(z)
                                })
                            } else {
                                Swal({
                                    title: 'Print Tickets',
                                    text: 'Do You Want to Print Tickets ?',
                                    type: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#00448b',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'Continue Without Printing',
                                    allowOutsideClick: false
                                }).then(result => {
                                    if (result.value) {
                                        res.response.ticket_details.forEach(z => {
                                            this.store_ticket_data = z
                                            this.printTicket(z)
                                        })
                                    } else {
                                        this.$emit("close", true);
                                        this.placing_order_in_progress = false
                                    }
                                })
                            }
                        }
                    }
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async courseFire(fireCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'COURSE',
                        action: fireCourse,
                        course: courseno,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemFire(item) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'ITEM',
                        action: item.isFired,
                        course: item.course,
                        item_id: item.item_id,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Hold and Unhold For Order,Course and Item
            async holdOrderApiCall(ishold) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isHold: ishold,
                        get_details: true
                    }
                    let res = await this.holdWholeOrder(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    setTimeout(() => {
                        this.orderdetails.isOrderOnHold = false
                    }, 500)
                    this.$toasted.global.error(reason)
                }
            },
            async unholdOrderApiCall() {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isHold: false,
                        get_details: true
                    }
                    let res = await this.holdWholeOrder(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (error) {
                    setTimeout(() => {
                        this.orderdetails.isOrderOnHold = false
                    }, 500)
                    this.$toasted.global.error(reason)
                }
            },
            async holdCourse(status, courseno) {
                let params = {
                    order_id: this.$route.params.id,
                    isHold: status,
                    course: courseno
                }
                try {
                    let res = await this.courseHoldApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.orderdetailsCourse.forEach((element, cindex) => {
                        if (element.course == courseno) {
                            this.orderdetailsCourse[cindex].isHold = false
                        }
                    })

                    this.$toasted.global.error(reason)
                }
            },
            async itemHold(item) {
                if (item.isHold == false) {
                    item.isHold = true
                } else {
                    item.isHold = false
                }
                try {
                    let params = {
                        isHold: item.isHold,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemHoldApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Based on Course Operations
            async coursePrepare(prepareCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isPrepared: prepareCourse,
                        course: courseno
                    }
                    let res = await this.coursePrepareApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.orderdetailsCourse.forEach((element, cindex) => {
                        if (element.course == courseno) {
                            this.orderdetailsCourse[cindex].isPrepared = false
                        }
                    })
                    this.$toasted.global.error(reason)
                }
            },
            async courseDelivered(deliverCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isDelivered: deliverCourse,
                        course: courseno
                    }
                    let res = await this.courseDeliverApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Based on Item Operations
            async itemPreparded(item) {
                try {
                    let params = {
                        isPrepared: item.isPrepared,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemPreparedApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemDelivered(item) {
                try {
                    let params = {
                        isDelivered: item.isDelivered,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemDeliveredApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemAvailable(item) {
                if (item.unAvailable == false) {
                    item.unAvailable = true
                } else {
                    item.unAvailable = false
                }
                try {
                    let params = {
                        unAvailable: item.unAvailable,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemAvailableApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async getOrderByIdB2C() {
                try {
                    this.ajaxCallInProgress = true
                    let res = await this.getorderB2CDetails()
                    console.log(res)
                    this.stateMaintain(res)
                    this.maintainOrderState(res)
                    this.ajaxCallInProgress = false
                } catch (reason) {
                    this.$toasted.global.error(reason)
                    this.order_message = reason
                    this.ajaxCallInProgress = false
                    if(this.order_message == 'Order Closed Successfully.') {
                        if(this.loggedInUser.role == 'customer'){
                            // window.location.href = localStorage.getItem("set_location")
                        }
                    }
                }
                // try {
                //     this.ajaxCallInProgress = true
                //     let res = await this.getorderB2CDetails()
                //     this.order_details = res
                //     this.stateMaintain(res)
                //     this.maintainOrderState(res)
                //     this.ajaxCallInProgress = false
                // } catch (reason) {
                //     this.$toasted.global.error(reason)
                //     this.order_message = reason
                //     this.ajaxCallInProgress = false
                //     if(this.order_message == 'Order Closed Successfully.') {
                //         if(this.loggedInUser.role == 'customer'){
                //             window.location.href = localStorage.getItem("set_location")
                //         }
                //     }
                // }
            },
        },
        mounted() {
            if(this.$route.path.startsWith('/view_customer_order')) {
                this.getOrderByIdB2C()
            } else {
            this.getOrderById()
            }
            if (this.loggedInUser.outlet_type == 'pop-up') {
                this.isPopUp = true
            } else {
                this.isPopUp = false
            }
            EventBus.$on('PaymentComplete', value => {
                this.manageorderScreen()
            })
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }
        }
    }

</script>

<style scoped>
    .top-view-order {
        border-radius: 5px;
        background-image: linear-gradient(278deg, #3a3985 36%, #007bff 74%);
    }

    .text-area-manage {
        border-radius: 5px;
        background: transparent;
        color: red;
        border: 1px solid #fff;
        width: 100%;
        padding: 5px;
        height: 140px;
    }

    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 6px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
    }

    .round input[type='checkbox'] {
        visibility: hidden;
    }

    .round input[type='checkbox']:checked+label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }

    .page-content-card {
        border-radius: 12px;
        border: solid 2.5px #f6f6f6;
        background-color: #fff;
        margin-top: 20px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .addon-view {
        border-radius: 6px;
        border: 2px solid #eee;
    }

    .view-vizard-big {
        background-color: #fff;
        border-radius: 35px;
        padding: 8px;
    }

    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
    }

    .stepwizard-row {
        display: table-row;
    }

    .stepwizard-row:before {
        top: 16px;
        bottom: 0;
        position: absolute;
        content: ' ';
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: 0;
    }

    .stepwizard-step {
        display: table-cell;
        text-align: center;
        position: relative;
    }

    .wizardSteps {
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        color: #94bef0 !important;
        text-align: center;
        display: initial;
        padding: 6px 0;
        font-size: 13px;
        font-weight: 600;
        line-height: 2.5;
        border-radius: 15px;
        text-decoration: none;
    }

    .view-vizard-big .stepwizard-step {
        left: 8% !important;
    }

    .view-vizard-big .btn-circle {
        line-height: 2.7 !important;
    }

    .view-vizard-small .stepwizard-step {
        left: 0% !important;
    }

    .view-vizard-small .wizardSteps {
        width: 26px !important;
        height: 26px !important;
    }

    .view-vizard-small .stepwizard-row:before {
        width: 80% !important;
        top: 14px !important;
    }

    .view-vizard-small .btn-circle {
        line-height: 2 !important;
    }

    .wizardSteps {
        border: none !important;
    }

    .greenCircle {
        background: #1a9347 !important;
        color: #fff !important;
    }

    .redCircle {
        background: #e82828 !important;
        color: #fff !important;
    }

    .orangeCircle {
        background: #f5a623 !important;
        color: #fff !important;
    }

    .btn-circle {
        padding: 5px 0 !important;
        line-height: 2.6 !important;
    }

    .whiteSpace {
        white-space: nowrap;
    }

    .pointerEventsNone {
        pointer-events: none !important;
    }

    .maxstepwizard:before {
        width: 80% !important;
    }

    .onholddiv {
        position: absolute;
        width: 96.2%;
        text-align: center;
        height: 40px;
        background-color: #606266;
        opacity: 1;
        top: 40%;
        right: 0px;
        left: 12px;
        margin-left: 15px;
        z-index: 999 !important;
        pointer-events: all !important;
    }

    .OnHoldClass {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }

    .onholddiv .btn-checkout {
        border-radius: 23px;
        top: 3px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031;
        padding: 0px 10px;
        margin: 0px;
        font-size: 14px !important;
        font-weight: 600;
        height: 3.4rem;
        line-height: 2.6rem;
        text-transform: capitalize;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .view-cour-comp .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #46d830 !important;
        border-color: #46d830 !important;
        border-radius: 50% !important;
    }

    .view-cour-comp .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #46d830 !important;
        border-color: #46d830 !important;
        border-radius: 50% !important;
    }

    .view-cour-comp .el-checkbox__inner {
        border-radius: 50% !important;
    }
    .bg-white{
        background-color: #fff;
    }
</style>
