<template>
  <div class="page-layout simple registerblock right-sidebar inner-sidebar">
    <div class="pb-0">
      <div class="account-type-block">
        <div id="mail" class="bg-secondary" style="margin: 6px;">
          <div class="d-flex align-items-center justify-content-between">
            <button
              class="btn btn-smm text-secondary d-block mt-3 bg-white ml-3"
              @click="addMeasurement()"
            >+Add New Unit of Measurement</button>
            <div class="d-flex mt-2">
              <div class="pr-1" v-if="measurements.length && !ajaxCallinprogress">
                <span
                  class="page-info px-2 hidden-md-down text-white"
                  style="position:relative;top:2px;"
                > {{getSkipCount}} -{{total>skip+limit?skip+limit:total}} of {{total}}</span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                    <i
                      class="icon icon-chevron-left text-white"
                      @click="previousPage()"
                      style="font-size: 20px;width: 20px;height: 20px;line-height: 20px;"
                    ></i>
                  </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button type="button" class="btn btn-icon d-none d-md-inline pagination-button">
                    <i
                      class="icon icon-chevron-right text-white"
                      @click="nextPage()"
                      style="font-size: 20px;width: 20px;height: 20px;line-height: 20px;"
                    ></i>
                  </button>
                </el-tooltip>
              </div>
              <div class="breadcome-heading mr-4">
                <div class="input-search">
                  <button type="submit" class="input-search-btn">
                    <i class="searchicon icon-magnify" aria-hidden="true"></i>
                  </button>
                  <input
                    type="text"
                    class="form-control-new"
                    @input="onkeySearch"
                    v-model="search_key"
                    name
                    placeholder="Search..."
                  />
                </div>
                <!-- <div class="form-group formsearch has-warning has-feedback bg-white">
                            <input @input="onkeySearch" v-model="search_key" type="text" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                </div>-->
              </div>
            </div>
          </div>
          <div
            class="content-section registerblock-section"
            style="border-radius: 0px 15px 15px 0px;"
          >
            <div
              class="center-section"
              style="background-color: #dee8f2;overflow-y: auto;margin: 10px;border-radius: 15px;padding: 0px 0px 0px 0px!important;"
            >
              <div class="crmaccounttypecard crmaccounttype-over-card personal">
                <div class="crmaccounttypecard-body mb-0">
                  <table class="table table-reports table-fixed">
                    <thead>
                      <tr>
                        <th>MEASUREMENT</th>
                        <th>UNIT</th>
                        <th>ABBREVIATION</th>
                        <th class="word-break">CONVERSION UNIT</th>
                        <th>UNIT OF MEASUREMENT</th>
                        <th>ADMIN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="ajaxCallinprogress" class="d-flex justify-content-center">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </tr>
                      <tr
                        class="text-center text-secondary"
                        v-if="!ajaxCallinprogress && !measurements.length && search_key.length == 0"
                        style="justify-content: center;"
                      >No Measurements</tr>
                      <tr
                        class="text-center text-secondary"
                        v-if="!ajaxCallinprogress && !measurements.length && search_key.length"
                        style="justify-content: center;border-bottom: 0px solid transparent !important"
                      >
                        <td class="mt-10" style="display: block;position: absolute;top: 35px;">
                          <img
                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg"
                            class="pl-20"
                            style="padding-top: 20px;"
                          />
                          <br />
                          <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                          >No Search Results Found</p>
                        </td>
                      </tr>
                      <tr v-for="(measurement,index) in measurements" :key="index" v-else>
                        <td
                          class="text-truncate d-block"
                          :title="measurement.name"
                        >{{measurement.name}}</td>
                        <td class="text-truncate d-block">{{measurement.conversion_unit.no_of_unit}}</td>
                        <td class="text-truncate d-block">{{measurement.abbreviation_um}}</td>
                        <td v-if="measurement.conversion_unit.conversion_value == '-'"
                          class="text-truncate d-block"
                        >-</td>
                        <td v-else
                          class="text-truncate d-block"
                        >{{formatPrice(measurement.conversion_unit.conversion_value)}}</td>
                        <td
                          class="text-truncate d-block"
                          :title="measurement.conversion_unit.to_unit.name || measurement.conversion_unit.to_unit"
                        >{{measurement.conversion_unit.to_unit.name || measurement.conversion_unit.to_unit}}</td>
                        <td class="text-truncate">
                          <div class="pointer">
                            <button
                              class="btn btn-icon actionicons"
                              @click="editMeasurement(measurement)"
                            >
                              <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                <i
                                  class="icon icon-pencil text-darkBlue editIcon"
                                  style="margin-right: 2px;"
                                ></i>
                              </el-tooltip>
                            </button>
                            <button
                              class="btn btn-icon actionicons"
                              @click="deleteMeasurement(measurement)"
                            >
                              <el-tooltip
                                class="item"
                                effect="dark"
                                content="Delete"
                                placement="top"
                              >
                                <i class="icon icon-delete text-darkBlue deleteIcon"></i>
                              </el-tooltip>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-measurement v-if="showMeasure == true" @loadMeasure="loadMeasure" modal_name="add-measurement"></add-measurement>
    <edit-measurement
      @closeMeasure="closeMeasure"
      :measurement_detail="editMeasurements"
      v-if="editMeasure"
      modal_name="edit-measurement"
    ></edit-measurement>
    <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
  </div>
</template>
<script>
import ScmServices from '../mixins/scm.js'
const AddMeasurement = () => import('./AddMeasurement.vue')
const EditMeasurement = () => import('./EditMeasurement.vue')
import Swal from 'sweetalert2'
import { SweetModal } from 'sweet-modal-vue'
export default {
  data() {
    return {
      ajaxCallinprogress: false,
      measurements: [],
      editMeasurements: '',
      editMeasure: false,
      success_msg: null,
      warning_msg: null,
      search_key: '',
      skip: 0,
      limit: 10,
      total: 0,
      showMeasure: false
    }
  },
  mixins: [ScmServices],
  components: {
    AddMeasurement,
    EditMeasurement,
    SweetModal
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onkeySearch() {
      this.skip = 0
      this.loadMeasurements()
    },
    nextPage() {
      let skip_temp =
        this.skip + this.limit > this.total
          ? this.total
          : this.skip + this.limit
      if (skip_temp >= this.total) {
        return
      }
      this.skip = skip_temp
      this.loadMeasurements()
    },
    previousPage() {
      if (this.skip == 0) {
        return
      }
      this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit
      this.loadMeasurements()
    },
    addMeasurement() {
      this.showMeasure = true
      setTimeout(() => {
        this.$modal.show('add-measurement')
      }, 500);
    },
    editMeasurement(measurement) {
      this.editMeasure = true
      this.editMeasurements = measurement
      setTimeout(() => {
        this.$modal.show('edit-measurement')
      }, 1000)
    },
    async loadMeasurements() {
      this.measurements = []
      this.ajaxCallinprogress = true
      let response = await this.getScmMeasurements({
        search_key: this.search_key,
        skip: this.skip,
        limit: this.limit
      })
      this.measurements = response.unit_of_measurements
      this.total = response.total
      this.ajaxCallinprogress = false
    },
    async deleteMeasurement(measurement) {
      Swal.fire({
        title: 'Alert',
        text:
          'Are you sure want to delete' +
          ' ' +
          JSON.stringify(measurement.name) +
          ' ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'DELETE'
      })
        .then(result => {
          if (result.value) {
            try {
              let response = this.deleteMeasurementByID(measurement._id)
              setTimeout(() => {
                this.loadMeasurements('')
              }, 500)
            } catch (error) {
              this.$log.debug(error)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    closeMeasure() {
      this.editMeasure = false
      this.loadMeasurements('')
    },
    loadMeasure() {
      this.showMeasure = false
      setTimeout(() => {
        this.loadMeasurements('')
      }, 500);
    }
  },
  mounted() {
    this.loadMeasurements('')
  },
  computed: {
    getSkipCount() {
      if (this.total == 0) {
        return 0
      } else {
        let skip_count = this.skip > this.total ? this.total : this.skip + 1
        return skip_count
      }
    }
  }
}
</script>
<style scoped>
.table-fixed tbody {
  display: block;
  width: 100%;
  height: 433px;
  max-height: 433px;
  overflow-y: auto;
}
.table-fixed thead,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: flex;
  flex: 1 1 0%;
}
.table-fixed tbody tr,
.table-fixed tbody td {
  align-items: center;
  font-weight: 600;
}
.table-fixed thead th {
  align-items: center;
}
.table-fixed tfoot {
  line-height: 52px;
}
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.summaryBreakdown {
  overflow-x: auto;
  background-color: #00448b;
  padding: 10px;
}
.table.table-reports {
  border-radius: 7.5px;
  background-color: #dee8f2;
}
.table.table-reports tbody tr {
  border-bottom: 1px solid #a8adb4 !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #94bef0;
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 9px solid #00448b;
}
.calendarWidth.mx-datepicker-range {
  width: 350px !important;
}
.page-layout.simple.registerblock {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.account-type-block {
  position: relative;
  height: 100%;
  margin: 0px;
}
.content-section.registerblock-section {
  display: flex;
  min-height: 0;
  border-radius: 15px;
  background-color: #00448b;
  position: relative;
  top: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
}
.reduceTop {
  padding-top: 0px !important;
}
.fuse-sidebar.crmaccounttype-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}
.fuse-sidebar.crmaccounttype-sidebar.left-positioned {
  right: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar.locked-open {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none;
  border: 2px solid #00448b;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .sidebar {
  order: 2;
  overflow-y: visible;
  overflow-x: visible;
}
.fuse-sidebar.crmaccounttype-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  order: 1;
  padding: 10px 0px;
  background-color: #00448b;
  border-radius: 15px 0px 0px 15px;
}
.page-layout.simple.registerblock.left-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section,
.page-layout.simple.registerblock.right-sidebar.inner-sidebar
  .content-section.registerblock-section
  .center-section {
  flex: 1 1 250px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.contactinfo {
  min-height: 8.8rem;
}
.contactinfo span.name {
  font-size: 18px;
  font-weight: 600;
}
.avatar-wrapper-contactinfo {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 56px;
  min-width: 60px;
  text-align: center;
  margin: 0 auto;
}
.avatar-wrapper-contactinfo .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #e0e3e6;
}
.contactinfo .last-message {
  max-width: 180px;
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
}
.contactinfo .last-message-time {
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.contactinfo .unread-message-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.crmaccounttype-list {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  padding: 15px 0;
  max-height: none !important;
}
.crmaccounttype-list-item {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: center;
  min-width: 165px;
  min-height: 150px;
  width: 165px;
  height: 150px;
  padding: 10px 0;
  margin: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}
.crmaccounttype-list-item .crmaccounttype-wrapper {
  font-size: 100px !important;
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  line-height: 70px !important;
  text-align: center;
}
.crmaccounttype-list-item .crmaccounttype-wrapper.selected {
  border: 1px solid #fff;
  border-radius: 27px;
}
.crmaccounttype-list-item img.crmaccounttype-images {
  font-size: 85px !important;
  width: 85px !important;
  height: 85px !important;
  min-width: 85px !important;
  min-height: 85px !important;
  line-height: 85px !important;
  display: inline-block;
}
.crmaccounttype-name {
  padding: 8px 5px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fff;
}
.list-group .list-group-item > .avatar.avatarbank {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.list-group .list-group-item > .avatar.avatarbank > img {
  max-width: 110px;
  max-height: 70px;
}
.terminate-buttons {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.terminate-left,
.terminate-right {
  float: none !important;
}
.headertextcrm {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
  margin-top: 30px;
  color: #fff;
  margin-right: 15px;
  margin-left: 15px;
}
.ptext {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.12px;
  color: #ffffff;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 15px;
}
.list-group.account-group .list-group-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  padding: 24px 34px;
  min-height: 4.8rem;
  line-height: 1.6rem;
  text-decoration: none;
  margin-bottom: 30px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 45px;
  cursor: pointer;
}
.list-group.account-group .list-group-item > .list-item-content {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.14px;
  text-align: left;
  color: #00448b;
}
.list-group.account-group .list-group-item > .avatar {
  width: 60px;
  height: 60px;
  margin: 0 0rem 0 20px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.list-group.account-group .list-group-item > .avatar > img {
  max-width: 60px;
  max-height: 60px;
}
.mat-table-scm {
  background-color: transparent;
  box-shadow: none;
  display: block;
  margin: 0px 0px 20px;
}
.mat-header-row-scm,
.mat-row-scm {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  padding: 0px 0px;
}

.mat-header-cell-scm {
  font-size: 14px;
  font-weight: 600;
  color: #00448b;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: inherit;
  text-transform: uppercase;
}
.mat-cell-scm,
.mat-footer-cell,
.mat-header-cell-scm {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: inherit;
}
.mat-header-cell-scm.mat-header-first,
.mat-cell-scm.mat-cell-scm-first {
  flex: 1 1 150px;
  box-sizing: border-box;
  max-width: 150px;
  min-width: 150px;
}
.mat-header-cell-scm {
  box-shadow: 4px 0 0px -2px #94bef0;
}
/* .word-break{
  word-break: break-all;
} */
.mat-header-cell-scm:last-child,
.mat-header-cell-scm:first-child {
  box-shadow: none;
}
.mat-header-cell-scm.mat-shadow:first-child {
  box-shadow: 4px 0 0px -2px #94bef0 !important;
}
.mat-header-cell-scm.mat-header-image,
.mat-cell-scm.mat-cell-scm-image {
  flex: 1 1 60px;
  box-sizing: border-box;
  max-width: 60px;
  min-width: 60px;
}
.mat-header-cell-scm.mat-header-last,
.mat-cell-scm.mat-cell-scm-last {
  flex: 1 1 85px;
  box-sizing: border-box;
  max-width: 85px;
  min-width: 85px;
}
.mat-header-cell-scm.mat-header-cell-scm-posfinal,
.mat-cell-scm.mat-cell-scm-posfinal {
  flex: 1 1 125px;
  box-sizing: border-box;
  max-width: 125px;
  min-width: 125px;
}
.mat-header-cell-scm.mat-header-cell-scm-wastage,
.mat-cell-scm.mat-cell-scm-wastage {
  flex: 1 1 80px;
  box-sizing: border-box;
  max-width: 80px;
  min-width: 80px;
}
.mat-header-row-scm {
  min-height: 50px;
  padding-left: 10px;
  padding-bottom: 8px;
}
.mat-table-scm .mat-row-scm {
  position: relative;
  cursor: pointer;
  min-height: 60px;
  background-color: transparent;
  border-bottom: 1px solid #a8adb4;
}
.mat-table-scm .mat-row-scm:hover {
  box-shadow: 2.5px 1.5px 5px 0 rgba(0, 0, 0, 0.16);
}
.mat-header-row-scm,
.mat-row-scm {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  align-items: center;
  border-bottom-color: #a8adb4;
  padding-left: 10px;
}
.mat-cell-scm .mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
  font-weight: 400 !important;
  line-height: 1;
}
.mat-cell-scm,
.mat-footer-cell {
  font-size: 14px;
  color: #303031;
  font-weight: 600;
}
.avatar.avatar-border {
  border: 1.5px solid rgb(205, 210, 218);
  background-color: rgba(255, 255, 255, 0);
  width: 47px !important;
  height: 47px !important;
  border-radius: 50%;
  display: inline-block;
  min-width: 47px !important;
}
.avatar.avatar-border img {
  width: 37px !important;
  height: 37px !important;
  border-radius: 50%;
  min-width: 37px !important;
  max-width: 37px !important;
  max-height: 37px !important;
}
.mat-cell-scm .list-group .list-group-item h3 {
  font-size: 14px !important;
  font-weight: 600;
}
.mat-cell-scm .list-group .list-group-item p {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #4e5764 !important;
}
.mat-cell-scm .list-group .list-group-item > .avatar {
  margin: 0 1rem 0 0 !important;
}
.des-list-box {
  border-radius: 5px;
  padding: 7px 10px;
  color: #fff;
  width: 110px;
  text-align: center;
}
.mat-row-scm:hover .eyeIcon {
  color: #00448b !important;
}

.mat-row-scm:hover .editIcon {
  color: #00448b !important;
}

.mat-row-scm:hover .deleteIcon {
  color: #00448b !important;
}
.mat-row-scm:hover .cloudIcon {
  color: #e82828 !important;
}
.list-group.list-cat {
  padding: 8px 30px;
}
.list-group.list-cat .list-group-item.two-line {
  min-height: 5.2rem;
}
.list-group.list-cat .list-group-item {
  background-color: transparent !important;
}
.list-group.list-cat .list-group-item h3 {
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: 0.77px;
  color: #fff;
  padding-bottom: 5px;
}
.list-group.list-cat .list-group-item p {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 1.23;
  letter-spacing: 0.56px;
  color: #fff;
}
.category-badge.category-badge--dot.category-badge--xl {
  line-height: 15px;
  min-height: 15px;
  min-width: 15px;
  height: 15px;
  width: 15px;
  font-size: 0;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  border-radius: 50%;
  border: 2px solid #fff;
}
.category-badge.category-badge--veg {
  color: #fff;
  background: #008000;
}
.category-badge.category-badge--nonveg {
  color: #fff;
  background: #d40000;
}
.category-badge.category-badge--liquid {
  color: #fff;
  background: #fec502;
}
.category-badge.category-badge--transparent {
  color: transparent;
  background: transparent;
  border: 2px solid transparent !important;
}
.positiveCost {
  background-color: #f5a623;
  padding: 5px;
  width: 110px;
  max-width: 110px;
  color: #303031;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
}
.zeroCost {
  background-color: #1a9347;
  padding: 5px;
  width: 110px;
  max-width: 110px;
  color: #303031;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
}
.negativeCost {
  background-color: #e82828;
  padding: 5px;
  width: 110px;
  max-width: 110px;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
}
.exportButton {
  width: auto;
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #1a9347;
}
.exportButton img {
  width: 13px;
}
.pdfButton img {
  width: 13px;
}
.printButton img {
  width: 13px;
}
.pdfButton {
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #e82828;
  width: 66px;
  font-size: 14px;
}
.printButton {
  width: 66px;
  font-size: 14px;
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #00448b;
}
.export-index {
  z-index: 99999 !important;
}
.el-dropdown-menu__item {
  color: #303030 !important;
}
.addspace {
  margin-left: 20px;
}
.input-search {
  position: relative;
}
.input-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  background: #fff;
  border: none;
  border-radius: 0 200px 200px 0;
}
.input-search-btn .searchicon {
  margin: 0 3px;
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: #00448b;
  line-height: 16px;
}
.form-control-new {
  display: block;
  width: 100%;
  padding: 1.429rem 1rem;
  font-size: 1rem;
  line-height: 1.571429;
  color: #303031;
  background-color: #fff;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #e4eaec;
  border-radius: 0.215rem;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-sizing: border-box;
  height: 2.573rem;
  font-weight: 100;
  border-color: #e4eaec;
  -webkit-transition: -webkit-box-shadow 0.25s linear, border 0.25s linear,
    color 0.25s linear, background-color 0.25s linear;
  -o-transition: box-shadow 0.25s linear, border 0.25s linear,
    color 0.25s linear, background-color 0.25s linear;
  transition: box-shadow 0.25s linear, border 0.25s linear, color 0.25s linear,
    background-color 0.25s linear;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input-search-btn + .form-control-new {
  padding-right: 50px;
}
.input-search .form-control-new {
  border-radius: 200px;
}
.darkBlue{
  color: #00448b !important;
}
</style>    