<template>
    <div class="dropdown-menu dropdown-apps" aria-labelledby="dropdownShortcutMenu" style="top: 15px !important;">
        <div class="uk-grid uk-grid-width-medium-1-3 uk-margin-bottom uk-text-center">
            <div v-if="api_call_in_progress && filter_allProduct.length === 0">
                <div class="digi-table-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="logoblock mt-0 d-flex align-items-center" v-else-if="!api_call_in_progress && filter_allProduct.length === 0">
                <span class="w-100">Products not found.</span>
            </div>
            <div v-else v-for="product in filter_allProduct" :key="product._id">
                <a :href="product.product_name == 'DigiPOS' ? 'javascript:' : product.public_url" :target="product.product_name == 'DigiPOS' ? '' : '_blank'" class="logoblock mt-0 d-flex align-items-center text-decoration-none">
                    <div v-if="product.product_name == 'Accounting'" class="mr-1">
                        <i class="logo-accounting-bookeeping material-icons md-36 md-color-orange-600"></i>
                    </div>
                    <div v-if="product.product_name == 'Chat'" class="mr-1">
                        <i class="logo-chat material-icons md-36 md-color-light-green-600"></i>
                    </div>
                    <div v-if="product.product_name == 'Cloud'" class="mr-1">
                        <i class="logo-cloud material-icons md-36 md-color-orange-600"></i>
                    </div>
                    <div v-if="product.product_name == 'DigiPOS'" class="mr-1">
                        <i class="logo-pos material-icons md-36 md-color-blue-600"></i>
                    </div>
                    <div v-if="product.product_name == 'SCM'" class="mr-1">
                        <i class="logo-scm material-icons md-36 md-color-blue-600"></i>
                    </div>
                    <div v-if="product.product_name == 'Forms'" class="mr-1">
                        <i class="logo-forms material-icons md-36 md-color-cyan-600"></i>
                    </div>
                    <div v-if="product.product_name == 'DigiHelp'" class="mr-1">
                        <i class="logo-help-desk material-icons md-36 md-color-blue-600"></i>
                    </div>
                    <div v-if="product.product_name == 'CRM'" class="mr-1">
                        <i class="logo-crm material-icons md-36 md-color-blue-600"></i>
                    </div>
                    <div v-if="product.product_name == 'DigiExpense'" class="mr-1">
                        <i class="logo-expence-management material-icons md-36 md-color-blue-600" style="font-size: 22px !important;"></i>
                    </div>
                    <div v-if="product.product_name == 'DigiHRMS'" class="mr-1">
                        <i class="logo-hrms material-icons md-36 md-color-blue-600"></i>
                    </div>
                    <div v-if="product.product_name == 'DigiAdmin'" class="mr-1">
                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/admin.png" height="25" width="25" />
                    </div>
                    <span v-if="product.product_name.includes('Digi')" class="uk-text-muted uk-display-block new-blue mt-2 fw-500">{{product.product_name}}</span>
                    <span v-else class="uk-text-muted uk-display-block new-blue mt-2 fw-500">{{'Digi'+product.product_name}}</span>
                    <hr>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import global from './global.js';
import crm from "./mixins/crm.js"
export default {
    mixins:[crm],
    data(){
        return{
            allProduct:[],
            purchased_product:[],
            search_key:'',
            filter_purchased_product:[],
            filter_allProduct:[],
            value:false,
            api_call_in_progress:false,
        }
    },
    methods:{
        async getAllDigiProducts(){
            this.api_call_in_progress = true;
            try {
                let params = {
                    skip: 0,
                    limit: 20
                }
                let res = await this.$http.get(global.ADMIN_API + `/products/list?skip=${params.skip}&limit=${params.limit}`);
                // console.log(res);
                if(res.data.status_id == 1){
                    if(res.data.response.length > 0){
                        this.filter_allProduct = res.data.response;
                    }
                this.api_call_in_progress = false;
                }
            } catch (reason) {
                this.api_call_in_progress = false;
                console.log(reason)
            }
        },
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        loggedInUserCompanyId() {
            return this.$store.getters.loggedInUserCompanyId
        }
    } ,
    mounted(){
        this.api_call_in_progress = true;
        setTimeout(() => {
            this.getAllDigiProducts()
        }, 3000);
    }
}
</script>
<style scoped>
.header{
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
}
.dropdown-apps {
    width: 240px;
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    top: 11px !important;
}
.dropdown-menu {
    -webkit-border-radius: 8px!important;
    border-radius: 8px!important;
    border: 1px solid #cdd2da!important;
    -webkit-box-shadow: 3px 2px 20px rgba(0,0,0,.2)!important;
    box-shadow: 3px 2px 20px rgba(0,0,0,.2)!important;
}
.uk-margin-bottom {
    margin-bottom: 16px !important;
}
.uk-grid:after,
.uk-grid:before {
    content: "";
    display: block;
    overflow: hidden;
}
[class*="uk-grid-width"] > * {
    box-sizing: border-box;
    width: 100%;
}
.uk-grid-width-medium-1-3 > * {
     width: 33.333%;
}
.uk-margin-top {
    margin-top: 15px !important;
}
@media (min-width: 1220px) {
    /* .uk-grid > * {
        padding-left: 0px;
    } */
}
.uk-grid > * {
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
    margin: 0;
    width: 100%;
    padding: 5px 10px 7px 17px;
    border-bottom: solid 1px #cce3fc;
    float: left;
    text-align: center;
    text-decoration: none;
}
.uk-grid > *:hover {
    text-decoration: none;
    background: #cce3fc !important;
}
i.material-icons.md-36 {
    font-size: 28px !important;
    line-height: 36px;
    /* height: 36px;
    width: 36px; */
    padding-bottom: 15px;
    color: #00448b !important;
}
.uk-grid > * > :last-child {
    margin-bottom: 0;
}
.uk-text-muted {
    color: #005dae !important;
}
.uk-display-block {
    display: block !important;
}
.logoblock .uk-display-block {
    margin-top: 8px;
    position: relative;
    left: 20px;
}
div.title.h6 {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.6px;
    color: #00448b;
}
.border-img {
    width: 202px;
    height: 2px;
    object-fit: contain;
    border-radius: 19px;
    padding-left: 10px;
    margin-bottom: 5px;
}
.headerproducts {
    padding: 5px 10px 0px 10px;
}
.viewtxt {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0.5px;
    color: #303031;
    padding-right: 6px;
}
.search-group {
    margin-bottom: 0px;
    padding: 10px 10px;
}
.input-search {
    position: relative;
}
.input-search .input-search-close, .input-search .input-search-icon {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 2.573rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.input-search .input-search-icon {
    left: 0px;
    font-size: 16px;
    color: #00448b;
    text-align: center;
    pointer-events: none;
    width: 16px;
    height: 16px;
    line-height: 16px;
}
.input-search .input-search-icon+.search-control {
    padding-left: 2.4rem;
}
.search-control:focus + .input-search .input-search-icon {
    color: #00448b !important;
}
.search-control {
    height: 2.573rem;
    -webkit-appearance: none;
    border-color: #005dae;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: -webkit-box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear;
    -o-transition: box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear;
    transition: box-shadow .25s linear,border .25s linear,color .25s linear,background-color .25s linear;
    -moz-appearance: none;
}
.search-control {
    display: block;
    width: 100%;
    padding: .429rem 1.072rem;
    font-size: 14px;
    line-height: 1.571429;
    color: #303031;
    background-color: #fff;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-bottom: 1px solid #cdd2da !important;
    border-radius: 0px;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    overflow: visible;
    margin: 0;
}
.search-control:focus {
    border-color: #005dae !important;
}
button.input-search-close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
}
.input-search-close.icon {
    font-size: 16px;
    line-height: 16px;
}
.input-search .input-search-close {
    right: 8px;
}
.product-placement{
    position: relative;
    left: 6px;
}
.product-data{
    position: relative;
    left: -6px;
}
.display-none{
    display: none !important;
}
.decoration-none{
    text-decoration: none !important;   
}
.logo-pos:before {
    font-size: 20px;
}
.logo-crm:before {
    font-size: 22px;
}
</style>
