<template>
    <div class="page-layout blank employees-manage">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row flex-nowrap">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div class="list-title d-flex" style="font-weight: 500;">
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-left"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-right"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <a class="btn btn-secondary btn-smm" @click="showAddons">+ Add New Add-On</a>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                        <div class="breadcome-heading d-xl-inline">
                            <div class="form-group formsearch has-warning has-feedback">
                                <input type="text" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search..." v-model="search" @input="getAllAddons('search')"/>
                                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="pull-right mr-1" v-if="totalAddons">
                            <span class="page-info px-2 hidden-md-down"> {{start}} - {{end}} of {{totalAddons}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item" :disabled="count <=0" :class="{'disabled':ajaxCallInProgress}" v-on:click="getAllAddons('prev')">
                                    <i class="icon icon-chevron-left"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item"  :disabled="end >= totalAddons" :class="{'disabled':ajaxCallInProgress}" v-on:click="getAllAddons('next')">
                                    <i class="icon icon-chevron-right"></i>
                                </button>
                            </el-tooltip>
                        </div>
                        <router-link to="/addonsectionmanage" class="btn btn-secondary btn-smm pull-right hidden-md-down" :class="{'mr-1':!totalAddons}">
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/settingWhite.svg" class="pr-1">
                            <span>Manage Add-On Sections</span>
                        </router-link>
                    </div>
                </div>
            </h2>
            <div v-if="showIllustrator" class="row no-orders-row">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
                        <p class="text-center text-secondary fs-28 font-600 pt-5">No Add-On Yet!!!</p>
                    </div>
                </div>
            </div>
            <div v-if="!showIllustrator">
                <div class="category-manage-info" style="top: 48px!important;">ADD-ON</div>
                <div v-if="!showIllustrator" class="page-layout carded left-sidebar" style="background-color: #00448b!important;padding: 10px!important;">
                    <div class="page-content-wrapper">
                        <div class="page-content">
                            <div class="demo-content">
                                <table class="table table-scroll">
                                    <thead>
                                        <tr>
                                            <th style="width:1% !important;flex: 0 0 45px !important;"></th>
                                            <th style="flex: 0 0 216px;">
                                                <span v-on:click="setPasskey('name')" class="cursor-th" >
                                                    <span
                                                        v-if="passed_key.key=='name' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'name'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span
                                                        style="font-size: 16px !important;"
                                                        v-else
                                                        :class="{'hide-arrow': passed_key.key != 'name'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    NAME
                                                </span>
                                            </th>
                                            <th>Category</th>
                                            <th>
                                                <span v-on:click="setPasskey('addon_section')" class="cursor-th">
                                                    <span
                                                        v-if="passed_key.key=='addon_section' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'addon_section'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span
                                                        style="font-size: 16px !important;"
                                                        v-else
                                                        :class="{'hide-arrow': passed_key.key != 'addon_section'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    Section
                                                </span>
                                            </th>
                                            <th class="hidden-md-down">
                                                <span v-on:click="setPasskey('quantity')" class="cursor-th">
                                                    <span
                                                        v-if="passed_key.key=='quantity' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'quantity'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span
                                                        style="font-size: 16px !important;"
                                                        v-else
                                                        :class="{'hide-arrow': passed_key.key != 'quantity'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    Qty
                                                </span>
                                            </th>
                                            <th class="hidden-md-down">
                                                <span v-on:click="setPasskey('type_id.name')" class="cursor-th">
                                                    <span
                                                        v-if="passed_key.key=='type_id.name' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'type_id.name'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span
                                                        style="font-size: 16px !important;"
                                                        v-else
                                                        :class="{'hide-arrow': passed_key.key != 'type_id.name'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    Add-On Type
                                                </span>
                                            </th>
                                            <th class="hidden-sm-down">
                                                <span v-on:click="setPasskey('price')" class="cursor-th">
                                                    <span
                                                        v-if="passed_key.key=='price' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'price'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span
                                                        style="font-size: 16px !important;"
                                                        v-else
                                                        :class="{'hide-arrow': passed_key.key != 'price'}"
                                                        class="sorting-icon"
                                                    >
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    Price
                                                </span>
                                            </th>
                                            <th>Status</th>
                                            <th>Admin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="ajaxCallInProgress" style="border-bottom: transparent !important">
                                        <td colspan="6" class="text-center justify-content-center">
                                            <div class="digi-table-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="getAllAddonsForManage.length == 0 && !ajaxCallInProgress" style="background-color: transparent !important;border-bottom: none !important;">
                                        <td v-if="search" colspan="7" class="text-center justify-content-center">
                                            <strong style="display:block;padding-top:38px;">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;"/>
                                            <br />
                                            <p
                                                class="text-secondary font-20"
                                                style="font-size: 22px; font-weight: 600;"
                                            >No Search Results Found</p>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr v-for="addOn in getAllAddonsForManage" :key="addOn._id">
                                        <td style="width:1% !important;flex: 0 0 45px !important;">
                                        <el-popover
                                            placement="right"
                                            class="productAvatar pointer"
                                            width="124"
                                            trigger="hover"
                                        >
                                            <div v-if="addOn.image_url" class="img-icon">
                                            <img :src="addOn.image_url" style="border-radius:18px;" />
                                            </div>
                                            <div v-else>
                                            <avatar
                                                :username="addOn.name.replace(/ .*/,'')"
                                                :src="addOn.image_url"
                                                :size="100"
                                                :rounded="true"
                                                color="#fff"
                                            ></avatar>
                                            </div>
                                            <a
                                            slot="reference"
                                            class="lightBlue moreMembers"
                                            style="text-decoration: none!important;"
                                            >
                                            <avatar
                                                :username="addOn.name.replace(/ .*/,'')"
                                                :src="addOn.image_url"
                                                :size="40"
                                                :rounded="true"
                                                color="#fff"
                                            ></avatar>
                                            </a>
                                        </el-popover>
                                        </td>
                                        <td class="font-weight-bold" style="padding: 0px 0px 0px 25px !important;width: 225px;">                                        
                                            <!-- <input type='color' :value='addOn.type_id.color_code' id='colour' disabled> -->
                                            <div class="showType" v-if="addOn.type_id.name == 'N/A'" :class="{'veg':addOn.type_id.name == 'Veg','nonveg':addOn.type_id.name == 'Non-Veg','egg':addOn.type_id.name == 'Egg','pescatarian':addOn.type_id.name == 'Pescatarian'}">
                                                <div></div>
                                            </div>
                                            <el-tooltip class="item" effect="dark" :content="addOn.type_id.name" placement="top" v-else>
                                                <div class="showType" :class="{'veg':addOn.type_id.name == 'Veg','nonveg':addOn.type_id.name == 'Non-Veg','egg':addOn.type_id.name == 'Egg','pescatarian':addOn.type_id.name == 'Pescatarian'}">
                                                    <div></div>
                                                </div>   
                                            </el-tooltip>
                                            <span :title="addOn.name" style="width: 170px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;">{{addOn.name}}</span>
                                        </td>
                                        <td class="font-weight-bold" style="width: 270px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;" :title="addOn.categories[0].name">{{addOn.categories[0].name}}<br />
                                        <span
                                            class="text-secondary font-weight-bold fs-12"
                                            v-if="addOn.categories.length > 1"
                                        >+{{addOn.categories.length - 1}}more</span></td>
                                        <td style="width: 250px;;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:block;padding:0 0 0 22px!important;" class="font-weight-bold" :title="addOn.addon_section.name">{{addOn.addon_section.name}}</td>
                                        <td class="font-weight-bold hidden-md-down" style="padding: 0 0 0 16px !important;">{{addOn.quantity}}</td>
                                        <td class="font-weight-bold hidden-md-down" style="padding: 0 0 0 16px !important;">{{addOn.type_id.name}}</td>
                                        <td class="font-weight-bold hidden-sm-down" style="padding: 0 0 0 16px !important;">{{addOn.formatted_price}}</td>
                                        <td style="padding: 0 0 0 0px !important;">
                                        <el-switch
                                            v-model="addOn.is_available"
                                            @change="addonStatus(addOn.is_available,addOn._id)"
                                        ></el-switch>
                                        </td>
                                        <td>
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                <button
                                                class="btn btn-icon companyactions actionicons"
                                                @click="editAddon(addOn._id)"
                                                style="padding: 0.4rem .2rem;"
                                                >
                                                <i class="icon icon-pencil"></i>
                                                </button>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                <button
                                                type="button"
                                                class="btn btn-icon actionicons"
                                                @click="deleteAddon(addOn)"
                                                style="padding: 0.4rem .2rem;"
                                                >
                                                <i class="icon icon-delete"></i>
                                                </button>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-addon modal_name="addon-create" @addonSaved="getAllAddons()" v-if="createAddon" @hideCreateAddon="hideCreateAddon"></create-addon>
        <edit-addon modal_name="addon-edit" @addonEdited="getAllAddons()" v-if="addonedit" @hideeditAddon="hideeditAddon"></edit-addon>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
const CreateAddon = () => import('./CreateAddon')
const EditAddon = () => import('./EditAddon')
import addons from './mixins/addons'
import { EventBus } from './eventBus/nav-bar-event'
export default {
  mixins: [addons],
  data() {
    return {
      getAllAddonsForManage: [],
      addonId: '',
      totalAddons:'',
      createAddon:false,
      addonedit:false
    }
  },
  methods: {
    showAddons() {
     this.createAddon = true
     setTimeout(() => {
      this.$modal.show('addon-create')
     }, 100);
    },
    hideCreateAddon(){
        this.createAddon = false
    },
    hideeditAddon(){
        this.addonedit = false
    },
    editAddon(addonId) {
      this.addonedit = true
      setTimeout(() => {
         EventBus.$emit('addonDetails',addonId)
        this.$modal.show('addon-edit') 
      }, 500);
    },
    addonStatus(status, addonId) {
      this.$http
        .post('/add_ons/addon/change_status_of_addons', {
          _id: addonId,
          is_available: status
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.$toasted.global.success(res.data.message)
          } else {
              this.handelError(addonId)
            this.$toasted.global.error(res.data.reason)
          }
        })
    },
    handelError(addonId){
        this.getAllAddonsForManage.forEach((el,i)=>{
            if(el._id == addonId){
                el.is_available = true
            }
        })
    },
    deleteAddon(details) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure To delete the Add-on ' + details.name + ' ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00448b',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(result => {
        if (result.value) {
          this.confirmAddonDelete(details)
        }
      })
    },
    confirmAddonDelete(details) {
      this.$http
      .post("/add_ons/addon/delete", { addon_id: details._id })
      .then(res => {
          if (res.data.status_id == 1) {
              this.$swal("Deleted!", details.name + " Add-On has been deleted ", "success");
              this.checkPageCount();
          } else {
              this.modal_msg = res.data.reason;
              this.$swal({
                  title: this.modal_msg,
                  text: '',
                  type: 'warning'
              })
          }
      })
      .catch(res => {
          this.modal_msg = res.response.data.reason;
          this.$swal({
              title: this.modal_msg,
              text: '',
              type: 'warning'
          })
      });
    }
  },
  components: {
    CreateAddon,
    Avatar,
    EditAddon
  },
  created() {
    this.getAllAddons()
  }
}
</script>
<style scoped>
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.table.table-scroll tbody tr {
  border-bottom: 1px solid #a8adb4;
}
.page-content {
  background-color: transparent !important;
}
.avatar {
  background: #00448b;
}
.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
.img-icon img {
  width: 100px !important;
}
.addonType input{
    color: #00448b !important;
}
/* input[type=color] {
    width: 20px;
    height: 20px; 
    border-radius: 4px !important;
    overflow: hidden;
    padding: 5px 5px 5px 5px !important;
    margin-right: 5px;
}

input[type=color]::-webkit-color-swatch {
    border: none;
    border-radius: 4px !important;
    padding: 0;
}

input[type=color]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 4px !important;
    padding: 0;
} */
</style>