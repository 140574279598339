<template>
    <div class="page-layout blank">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row flex-nowrap">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div class="list-title" style="font-weight: 500;white-space: nowrap !important;"
                            v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write">
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()"
                                :to="{ path: $routerHistory.previous().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-left"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()"
                                :to="{ path: $routerHistory.next().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-right"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <a class="btn btn-secondary btn-smm" @click="createproduct()">+ Add New Ingredient</a>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <div class="breadcome-heading">
                            <div class="form-group formsearch has-warning has-feedback">
                                <input :maxlength="searchcustomer" type="text" class="form-control textremove holder"
                                    placeholder="Search..." v-model="searchkey" @input="get_all_products('search')" />
                                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="pull-right" v-if="total_products">
                            <span class="page-info px-2 hidden-md-down"> {{start}} - {{end}} of
                                {{total_products}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button :disabled="count <= 0" :class="{'disabled':ajaxCallinprogress}"
                                    v-on:click="get_all_products('prev')" type="button"
                                    style="padding:.1rem !important;"
                                    class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-left"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button :class="{'disabled':ajaxCallinprogress}" :disabled="end >= total_products"
                                    v-on:click="get_all_products('next')" type="button"
                                    style="padding:.1rem !important;"
                                    class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-right"></i>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </h2>
            <div v-if="showIllustrator" class="row no-orders-row">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
                        <p class="text-center text-secondary fs-28 font-600 pt-5">No Ingredients Yet!!!</p>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="!showIllustrator">
                    <div class="category-manage-info">INGREDIENT</div>
                    <div class="page-layout carded left-sidebar"
                        style="background-color: #00448b!important;padding: 10px!important;">
                        <div class="page-content-wrapper">
                            <div class="page-content">
                                <div class="demo-content">
                                    <table class="table table-inventery-manage">
                                        <thead>
                                            <tr>
                                                <th style="width:1%;" colspan="2"></th>
                                                <th style="width:12%;">
                                                    <span v-on:click="setPasskey('name')" class="cursor-th">
                                                        <span v-if="passed_key.key=='name' && passed_key.value== -1"
                                                            :class="{'hide-arrow': passed_key.key != 'name'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-down"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else
                                                            :class="{'hide-arrow': passed_key.key != 'name'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-up"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        NAME
                                                    </span>
                                                </th>
                                                <th style="width:6%;">
                                                    <span v-on:click="setPasskey('quantity')" class="cursor-th">
                                                        <span v-if="passed_key.key=='quantity' && passed_key.value== -1"
                                                            :class="{'hide-arrow': passed_key.key != 'quantity'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-down"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else
                                                            :class="{'hide-arrow': passed_key.key != 'quantity'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-up"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        QTY
                                                    </span>
                                                </th>
                                                <th v-on:click="setPasskey('price')" class="cursor-th"
                                                    style="width:9%;">
                                                    <span v-if="passed_key.key=='price' && passed_key.value== -1"
                                                        :class="{'hide-arrow': passed_key.key != 'price'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-down"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else
                                                        :class="{'hide-arrow': passed_key.key != 'price'}"
                                                        class="sorting-icon">
                                                        <i class="icon-arrow-up"
                                                            style="font-size: 16px !important;"></i>
                                                    </span>
                                                    PRICE
                                                </th>
                                                <th class="cursor-th" style="width:9%;padding-left: 27px!important;">SKU
                                                <th style="width:9%;" class="hidden-md-down">
                                                    <span v-on:click="setPasskey('category')" class="cursor-th">
                                                        <span v-if="passed_key.key=='category' && passed_key.value== -1"
                                                            :class="{'hide-arrow': passed_key.key != 'category'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-down"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else
                                                            :class="{'hide-arrow': passed_key.key != 'category'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-up"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        CATEGORY
                                                    </span>
                                                </th>
                                                <th style="width:9%;" class="hidden-md-down">
                                                    <span v-on:click="setPasskey('sub_category')" class="cursor-th">
                                                        <span
                                                            v-if="passed_key.key=='sub_category' && passed_key.value== -1"
                                                            :class="{'hide-arrow': passed_key.key != 'sub_category'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-down"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else
                                                            :class="{'hide-arrow': passed_key.key != 'sub_category'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-up"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        SUB CATEGORY
                                                    </span>
                                                </th>
                                                <th style="width:9%;white-space: nowrap;" class="hidden-md-down">
                                                    <span v-on:click="setPasskey('product_type_name')"
                                                        class="cursor-th">
                                                        <span
                                                            v-if="passed_key.key=='product_type_name' && passed_key.value== -1"
                                                            :class="{'hide-arrow': passed_key.key != 'product_type_name'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-down"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else
                                                            :class="{'hide-arrow': passed_key.key != 'product_type_name'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-up"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        PRODUCT TYPE
                                                    </span>
                                                </th>
                                                <th style="width:9%;white-space: nowrap;">
                                                    <span v-on:click="setPasskey('status')" class="cursor-th">
                                                        <span v-if="passed_key.key=='status' && passed_key.value== -1"
                                                            :class="{'hide-arrow': passed_key.key != 'status'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-down"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        <span style="font-size: 16px !important;" v-else
                                                            :class="{'hide-arrow': passed_key.key != 'status'}"
                                                            class="sorting-icon">
                                                            <i class="icon-arrow-up"
                                                                style="font-size: 16px !important;"></i>
                                                        </span>
                                                        STATUS
                                                    </span>
                                                </th>
                                                <th style="width:9%;text-align: center;">Admin</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="products.length==0 && !ajaxCallinprogress">
                                            <tr
                                                style="background-color: transparent !important;border-bottom: none !important;">
                                                <td v-if="searchkey" colspan="11" class="text-center">
                                                    <strong style="display:block;padding-top:38px;">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" />
                                                        <br />
                                                        <p class="text-secondary font-20"
                                                            style="font-size: 22px; font-weight: 600;">No Search Results
                                                            Found</p>
                                                    </strong>
                                                </td>
                                                <td v-else colspan="11" class="text-center">
                                                    <strong>No Ingredients</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="ajaxCallinprogress">
                                            <tr>
                                                <td colspan="11" class="text-center">
                                                    <div class="digi-table-loader">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-for="(item,index) in products" :key="index">
                                            <tr class="tr-no-color">
                                                <td style="padding: 0px 8px!important;">
                                                    <span @click.stop="item.show = !item.show">
                                                        <i v-if="item.variant && item.show"
                                                            class="icon-arrow-down-drop icon-down"></i>
                                                        <i v-if="item.variant && !item.show"
                                                            class="icon-menu-right text-secondary icon-right"></i>
                                                    </span>
                                                </td>
                                                <td style="padding: 0px 0px!important;">
                                                    <el-popover placement="right" class="productAvatar pointer"
                                                        width="124" trigger="hover">
                                                        <div v-if="item.image">
                                                            <img :src="item.image" style="border-radius:50px;" />
                                                        </div>
                                                        <div v-else>
                                                            <!-- <p class="image-p-td">Image Not Available</p> -->
                                                            <avatar :username="item.name.replace(/ .*/,'')"
                                                                :src="item.image" :size="100" :rounded="true"
                                                                color="#fff"></avatar>
                                                        </div>
                                                        <a slot="reference" class="lightBlue moreMembers"
                                                            style="text-decoration: none !important;">
                                                            <avatar :username="item.name.replace(/ .*/,'')"
                                                                :src="item.image" :size="40" :rounded="true"
                                                                color="#fff"></avatar>
                                                        </a>
                                                    </el-popover>
                                                </td>
                                                 <td
                                                    class="text-truncate"
                                                    >
                                                    <span class="d-flex">
                                                    <!-- <el-tooltip class="item" effect="dark" :content="item.type" placement="top" v-if="item.type != 'N/A' && !item.variant">
                                                        <div class="showType mt-1" :class="{'veg':item.type == 'Veg','nonveg':item.type== 'Non-Veg','egg':item.type == 'Egg','pescatarian':item.type == 'Pescatarian'}">
                                                            <div></div>
                                                        </div>   
                                                    </el-tooltip>
                                                    <div class="showType mt-1" v-else :class="{'veg':item.type == 'Veg','nonveg':item.type== 'Non-Veg','egg':item.type == 'Egg','pescatarian':item.type == 'Pescatarian'}">
                                                        <div></div>
                                                    </div>                          -->
                                                    <span :title="item.name"
                                                    style="max-width: 120px;padding-right: 0px!important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 3px!important;">
                                                        <a
                                                        class="product-name"
                                                        @click="showproductvariants(item._id)"
                                                        >{{item.name}}</a>
                                                    </span>
                                                    </span>
                                                </td>
                                                <td class="text-truncate" v-if="item.variant">-</td>
                                                <td class="text-truncate" v-else>{{item.quantity}}</td>
                                                <td class="text-truncate" v-if="item.price">{{item.price}}</td>
                                                <td class="text-truncate" v-else>-</td>
                                                <td class="text-truncate">{{item.stock_keeping_unit}}</td>
                                                <td class="text-truncate hidden-md-down" style="max-width:140px"
                                                    :title="item.category">{{item.category || '-'}}</td>
                                                <td class="text-truncate hidden-md-down" style="max-width:140px"
                                                    :title="item.sub_category">{{item.sub_category || '-'}}</td>
                                                <td class="hidden-md-down text-truncate" :title="item.product_type_name"
                                                    style="max-width:140px">{{item.product_type_name || '-'}}</td>
                                                <td
                                                    v-if="loggedInUser.actions.inventory && loggedInUser.actions.inventory.write">
                                                    <el-switch v-model="item.status"
                                                        v-on:change="changestatus(item.status, item._id)"></el-switch>
                                                </td>
                                                <td v-else>
                                                    <el-switch v-model="item.status"></el-switch>
                                                </td>
                                                <td v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                                                    style="padding: 0px 10px!important;text-align: center;white-space:nowrap">
                                                    <el-tooltip class="item" effect="dark" content="Edit"
                                                        placement="top">
                                                        <button class="btn btn-icon companyactions actionicons"
                                                            @click="editproductmodal(item._id)"
                                                            style="padding: 0.4rem .2rem;">
                                                            <i class="icon icon-pencil"></i>
                                                        </button>
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="dark" content="Delete"
                                                        placement="top">
                                                        <button type="button" class="btn btn-icon actionicons"
                                                            @click="deleteproduct(item)" style="padding: 0.4rem .2rem;">
                                                            <i class="icon icon-delete"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </td>
                                            </tr>
                                            <tr class="varitants-table-tr product-tbody" v-if="item.show"
                                                v-for="(i,index) in item.variants" :key="index">
                                                <td style="padding: 0px 8px!important;"></td>
                                                <td style="padding: 0px 0px!important;"></td>
                                                <td
                                                    class="text-truncate d-flex"                          
                                                    >
                                                    <div class="showType mt-5" v-if="i.type == 'N/A'" :class="{'veg':i.type == 'Veg','nonveg':i.type== 'Non-Veg','egg':i.type == 'Egg','pescatarian':i.type == 'Pescatarian'}">
                                                        <div></div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :content="i.type" placement="top" v-else>
                                                        <div class="showType mt-5" :class="{'veg':i.type == 'Veg','nonveg':i.type== 'Non-Veg','egg':i.type == 'Egg','pescatarian':i.type == 'Pescatarian'}">
                                                            <div></div>
                                                        </div>   
                                                    </el-tooltip>
                                                    <span :title="i.variant_name"
                                                    style="width: 120px;padding-right: 0px!important;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 18px!important;">
                                                    <a class="font-600" style="color:#303031;">{{i.variant_name}}</a>
                                                    </span>
                                                    </td>
                                                <td class="text-truncate">{{i.variant_quantity}}</td>
                                                <td class="text-truncate">{{i.variant_price}}</td>
                                                <td class="hidden-md-down">-</td>
                                                <td class="hidden-md-down">-</td>
                                                <td>-</td>
                                                <td class="hidden-md-down">-</td>
                                                <td>
                                                    <el-switch v-model="i.status"
                                                        v-on:change="change_variant_status(i.status, item._id, i.id)">
                                                    </el-switch>
                                                </td>
                                                <td
                                                    v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <products-variants modal_name="product_info" :productId="productDetailsId"></products-variants>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="success_modal_create" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal_create" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>
<style scoped>
    .icon-arrow-down-drop.icon-down,
    .icon-menu-right.icon-right {
        color: #00448b !important;
        cursor: pointer;
    }

    .v-modal-layout {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* .sorting-icon{
    cursor: pointer;
    visibility: hidden;
} */
    .table-inventery-manage tbody {
        border-bottom: 1px solid #a8adb4;
    }

    .image-p-td {
        text-align: center;
        vertical-align: middle;
        padding-top: 30px;
    }

    th:hover .sorting-icon {
        visibility: visible !important;
        cursor: pointer;
    }

    .varitants-table-tr.product-tbody {
        background-color: #7abbfd1f !important;
    }

    .closeTax {
        position: absolute;
        right: 12px;
    }

    .avatar.avatar-trans {
        background-color: transparent !important;
    }

    .el-select {
        display: inline-block;
        position: relative;
        width: 100% !important;
    }

    .companyTimelineTab {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        cursor: pointer;
        font-weight: 500;
        border-radius: 20px 0 0 20px !important;
    }

    .companyRelationTab {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        border-left: none !important;
        cursor: pointer;
        /* border-radius: 0 20px 20px 0 !important; */
    }

    .companyGalleryTab {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        cursor: pointer;
        border-left: none !important;
        border-radius: 0 20px 20px 0 !important;
        margin-left: -3px;
    }

    .activeClass {
        background: #00448b !important;
        color: #fff !important;
        font-weight: bold;
    }

    .avatar {
        background: #00448b;
    }

    .avatar {
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        line-height: 4rem;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 16px !important;
        font-weight: 600;
        text-align: center;
        color: #fff;
        background-color: #c3c3ba;
    }

    .product-name {
        color: #00448b !important;
        font-weight: 600 !important;
        cursor: pointer;
        text-transform: capitalize !important;
    }

    .table.table-inventery-manage {
        border-radius: 7.5px;
        background-color: #dee8f2;
    }

    .table.table-inventery-manage td {
        padding: 0px 0px 0px 28px !important;
        font-weight: 600;
    }

    .taxes-heading {
        font-size: 14px;
        font-weight: bold;
        color: #00448b;
        padding-left: 14px;
    }

    .v-modal-layout {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .contactGroup select.form-control {
        max-width: 100% !important;
        max-width: 100% !important;
    }

    .el-upload--picture-card {
        color: #00448b !important;
        font-weight: 600 !important;
    }

    .el-input__inner {
        color: #303031 !important;
    }

    .el-upload .icon-plus {
        background-color: #00448b;
        color: #fff;
        border-radius: 100px;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: transparent !important;
    }

    .varitants-table-tr {
        background-color: #f5f6f9 !important;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }

    .table-striped tbody:nth-of-type(odd) {
        background-color: #f5f6f9 !important;
    }

    .varitants-table-tr.table-striped tbody tr:nth-of-type(odd) {
        background-color: transparent !important;
    }

    .table-inventery-manage thead th span {
        cursor: pointer;
    }

    .table-striped tbody:nth-of-type(odd) {
        background-color: #f5f6f9 !important;
    }

    .hide-arrow {
        visibility: hidden;
    }

    .sorting-icon {
        cursor: pointer;
    }

    .cursor-th {
        cursor: pointer;
    }

    .btn-icon:disabled {
        background: transparent !important;
    }

    @media (max-width: 1200px) {
        .table.table-inventery-manage th {
            padding: 1.4rem 5px !important;
        }

        .table.table-inventery-manage td {
            padding: 0px 5px 0px 25px !important;
        }
    }

    .productType {
        width: 70px;
    }

    .borderColor .el-checkbox__inner {
        border-color: #94bef0 !important;
    }
</style>

<script>
    import VueVisible from 'vue-visible'
    import myUpload from 'vue-image-crop-upload'
    import Avatar from 'vue-avatar'
    import {
        SweetModal
    } from 'sweet-modal-vue'
    import VueTagsInput from '@johmun/vue-tags-input'

    import ForBidden from './ForBidden'
    import ProductsVariants from './ProductsVariants'

    import products from './mixins/products.js'
    import productServices from './mixins/products.js'
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'

    export default {
        data() {
            return {
                showVariantsTr: false,
                searchcustomer: 100,
                editTax: [],
                editVariants: [],
                dialogImageUrl: '',
                dialogVisible: false,
                isCompanyTimeline: true,
                isCompanyRelationship: false,
                isCompanygallery: false,
                show: false,
                imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg', // the datebase64 url of created image
                product_details: {},
                disabled: true,
                image: '',
                picture_message: 'Upload Image',
                modal_msg: '',
                variants_list: [],
                gallerylist: [],
                name: '',
                productDetailsId: '',
                createByProduct:false
            }
        },
        components: {
            'my-upload': myUpload,
            Avatar,
            SweetModal,
            ProductsVariants,
            ForBidden,
            VueVisible
        },
        mixins: [productServices],
        methods: {
            createcategory() {
                this.$modal.show('category-create')
            },
            showbrandproduct() {
                this.$modal.show('brand-create')
            },
            showVariants(variants) {
                this.showVariantsTr = !this.showVariantsTr
            },
            editRemoveVariants: function (index) {
                this.editVariants.splice(index, 1)
            },
            handleRemove(file) {
                file.product_id = this.product_details._id
                this.$http
                    .post('/products/remove_products_from_gallery', file)
                    .then(res => {
                        if (res.data.status_id == 1) {
                            this.modal_msg = 'Removed Image From Gallery'
                        }
                    })
            },
            handlePictureCardPreview(file) {
                // this.productObj.gallery.push(file)
                if (file.url) {
                    this.image = file.url
                    this.dialogVisible = true
                } else {
                    this.image = file.response.url
                    this.dialogVisible = true
                }
            },
            toggleShow() {
                this.show = !this.show
            },
            /**
             * crop success
             *
             * [param] imgDataUrl
             * [param] field
             */
            cropSuccess(imgDataUrl, field) {
                this.imgDataUrl = imgDataUrl
                this.picture_message = 'Change Image'
            },
            /**
             * upload success
             *
             * [param] jsonData  server api return data, already json encode
             * [param] field
             */
            cropUploadSuccess(jsonData, field) {
                this.edit_product_details.image = jsonData
                this.picture_message = 'Change Image'
            },
            /**
             * upload fail
             *
             * [param] status    server api return error status, like 500
             * [param] field
             */
            cropUploadFail(status, field) {
                // TODO:
            },
            createproduct(){
                this.$router.push({ name: 'AddIngredientNew' })
            },
            // createproduct() {
            //     this.skuexists = false
            //     this.productObj = {
            //         name: '',
            //         description: '',
            //         quantity: 0,
            //         price: 0,
            //         currency: '',
            //         product_id: '',
            //         stock_keeping_unit: '',
            //         brand: '',
            //         category: '',
            //         status: true,
            //         tags: [],
            //         tax: [],
            //         variants: [],
            //         type: {
            //             vegetarian: false,
            //             vegan: false,
            //             spicy: false,
            //             signature_dish: false,
            //             halal: false
            //         },
            //         total_tax: 0,
            //         variant: '',
            //         image: '',
            //         gallery: []
            //     }
            //     this.variant_name = ''
            //     this.variant_quantity = ''
            //     this.variant_price = ''
            //     this.tax_name = ''
            //     this.tax_percentage = ''
            //     this.$modal.show('product-create')
            // },
            hidecreateproduct() {
                this.skuexists = false;
                (this.productObj = {
                    name: '',
                    description: '',
                    quantity: 0,
                    price: 0,
                    currency: '',
                    product_id: '',
                    stock_keeping_unit: '',
                    brand: '',
                    category: '',
                    status: true,
                    tags: [],
                    tax: [],
                    variants: [],
                    type: {
                        vegetarian: false,
                        vegan: false,
                        spicy: false,
                        signature_dish: false,
                        halal: false
                    },
                    total_tax: 0,
                    variant: '',
                    image: '',
                    gallery: []
                }),
                (this.variant_name = '')
                this.variant_quantity = ''
                this.variant_price = ''
                this.tax_name = ''
                this.tax_percentage = ''
                this.$modal.hide('product-create')
            },
            hideeditproduct() {
                this.$modal.hide('product-edit')
            },
            hideproduct() {
                this.$modal.hide('product-details')
            },
            companyTimeline() {
                this.isCompanyTimeline = true
                this.isCompanyRelationship = false
                this.isCompanygallery = false
            },
            relationshipPage() {
                this.isCompanyRelationship = true
                this.isCompanyTimeline = false
                this.isCompanygallery = false
            },
            galleryPage() {
                this.isCompanyRelationship = false
                this.isCompanyTimeline = false
                this.isCompanygallery = true
            },
            setProduct(product) {
                this.product_details = product
                this.$store.commit('setProductDetails', this.product_details)
                this.$modal.hide('product-create')
            },
            showUploadedImages(gallery) {
                return 'https://cdn.digicollect.com/cdn/dcpos/pics/47521fc0-f9d4-11e8-8890-f23c91b0d497/8525bb7f-ff8c-41d1-bf45-0cdd93cbbd3e.png'
            },
            showproductvariants(product_id) {
                this.productDetailsId = product_id
                EventBus.$emit('ProductVariant', product_id)
                this.$modal.show('product_info')
                // e.preventDefault()
                // e.stopPropagation()
            },
            editproductmodal(product_id) {
                // EventBus.$emit('editProductDetails', product_id)
                // this.$modal.show('product-edit')
                this.$router.push({
                    name: 'EditIngredientNew',
                    query: { id: product_id },
                })
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser
            }
        },

        mounted() {
            EventBus.$emit('settingsLoad', false)
            EventBus.$emit('customerdetailsLoad', false)
            EventBus.$emit('reportsLoad', false)
            EventBus.$on('ProductVariantChange', product => {
                this.products.forEach(element => {
                    if (element._id == product.product_id) {
                        element.variants.forEach(elementIn => {
                            if (elementIn.id == product.variant_id) {
                                elementIn.status = product.status
                            }
                        })
                    }
                })
            })
            EventBus.$on('ProductStatusChange', product => {
                this.products.forEach(element => {
                    if (element._id == product.product_id) {
                        element.status = product.status
                    }
                })
            })
            EventBus.$on('ProductVariantsDynamicChange', product => {
                this.products.forEach(element => {
                    if (element._id == product.product_id) {
                        element.variants.forEach(elementIn => {
                            elementIn.status = product.status
                        })
                    }
                })
            })
            EventBus.$on('Show Category', modal_name => {
                this.$modal.hide('product-create')
            })
            EventBus.$on('updateProductList', res => {
                this.get_all_products()
            })
            if (this.$route.query.add_product == 'true') {
                this.createproduct()
            }
        },
        created() {
            this.get_all_products('init')
            this.get_categories_for_products()
            this.get_brands_for_products()
        },
        destroyed() {
            EventBus.$off('ProductVariantChange')
            EventBus.$off('ProductStatusChange')
            EventBus.$off('ProductVariantsDynamicChange')
            EventBus.$off('Show Category')
            EventBus.$off('updateProductList')
        }
    }

</script>
