<template>
  <div class="page-layout blank closed-orders">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row">
          <div class="d-flex col-lg-2 col-md-2 col-sm-3">
            <router-link
              style="color: transparent!important;"
              :disabled="!$routerHistory.hasPrevious()"
              :to="{ path: $routerHistory.previous().path }"
            >
              <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-left"></i>
                </button>
              </el-tooltip>
            </router-link>
            <router-link
              style="color: transparent!important;"
              :disabled="!$routerHistory.hasForward()"
              :to="{ path: $routerHistory.next().path }"
            >
              <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-right"></i>
                </button>
              </el-tooltip>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 resetAlign">
            <date-picker
              v-if="convertUTC == false"
              v-model="dateRange"
              @confirm="get_order_history()"
              format="MM/DD/YYYY hh:mm A"
              :first-day-of-week="7"
              range
              :show-second="false"
              :shortcuts="shortcuts"
              :lang="lang"
              :clearable="true"
              :not-after="new Date()"
              confirm-text="APPLY"
              type="datetime"
            ></date-picker>
            <date-picker
              v-else
              v-model="dateRange"
              @confirm="get_order_history()"
              format="MM/DD/YYYY hh:mm A"
              :first-day-of-week="7"
              range
              :show-second="false"
              :shortcuts="shortcuts"
              :lang="lang"
              :clearable="true"
              confirm
              :not-after="new Date()"
              confirm-text="APPLY"
              type="datetime"
            ></date-picker>
          </div>
          <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
            <flat-pickr
              v-model="enddate"
              class="form-control"
              placeholder="Select End Date "
              name="date">
            </flat-pickr>
          </div>-->
          <div class="col-lg-1 hidden-md-down">
            <a class="btn btn-secondary btn-smm text-uppercase" @click="resetDate">Reset</a>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  type="text"
                  class="form-control textremove holder"
                  v-model="searchdata"
                  :maxlength="searchcustomer"
                  placeholder="Search..."
                  @input="get_order_history('searchdata')"
                />
                <!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" v-on:click=" searchkey = '',start = 1,skip = 0, filterProducts()"></span> -->
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <!-- <router-link
                  :disabled="!$routerHistory.hasForward()"
                  :to="{ path: $routerHistory.next().path }">
              <el-tooltip class="item pull-right" effect="dark" content="Click to Go Forward" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                  </el-tooltip>
            </router-link>
            <router-link
                  :disabled="!$routerHistory.hasPrevious()"
                  :to="{ path: $routerHistory.previous().path }">
                <el-tooltip class="item pull-right" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
              </el-tooltip>
            </router-link>-->
            <!-- <el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
              <a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)" style="margin-top: 2px;padding-top: 2px;">
                <i class="icons icon-arrow-left"></i>
              </a>
            </el-tooltip>-->
            <div v-if="!showIllustrator && total_orders" class="pull-right">
              <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{total_orders}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  :class="{'disabled':ajaxCallinprogress}"
                  :disabled="count<=0"
                  v-on:click="get_order_history('prev')"
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  :class="{'disabled':ajaxCallinprogress}"
                  :disabled="end >= total_orders"
                  type="button"
                  v-on:click="get_order_history('next')"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-orders-yet.svg" style="width: 26%;margin-top:20px;" />
          </div>
        </div>
      </div>
      <div v-if="!showIllustrator">
        <div class="category-manage-info">ORDER HISTORY</div>
        <div class="page-layout carded left-sidebar mt-2"
          style="overflow: auto !important;padding-bottom: 5px;">
          <div class="page-content-wrapper" style="background-color: #005dae!important;padding: 10px!important;">
            <div class="page-content">
              <div class="demo-content">
                <table class="table table-closed-orders">
                  <thead>
                    <tr>
                      <th style="padding: 1rem 0.5rem!important;width: 1%;">
                        <span v-on:click="setPasskey('order_id')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='order_id' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'order_id'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'order_id'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          ORDER ID
                        </span>
                      </th>
                      <!-- <th  class="whiteSpace" width="1%"></th> -->
                      <th class="whiteSpace" style="padding: 1rem 0.5rem !important;">
                        <span v-on:click="setPasskey('customer_name')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='customer_name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'customer_name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'customer_name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          CUSTOMER
                          <br />
                          <span class="pl-5">NAME</span>
                        </span>
                      </th>
                      <th class="whiteSpace" style="padding: 1rem 0.5rem !important;">
                        <span v-on:click="setPasskey('company_name')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='company_name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'company_name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'company_name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          COMPANY
                          <br />
                          <span class="pl-5">NAME</span>
                        </span>
                      </th>
                      <th class="whiteSpace" style="padding: 1rem 0.5rem !important;">
                        <span v-on:click="setPasskey('customer_tax_code')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='customer_tax_code' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'customer_tax_code'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'customer_tax_code'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          TAX CODE
                        </span>
                      </th>
                      <!-- <th>
                        <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span>
                        COMPLETED BY
                      </th>-->
                      <th class="hidden-sm-down" style="padding: 1rem 0.5rem !important;">
                        <span v-on:click="setPasskey('employee_name')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='employee_name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'employee_name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'employee_name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          CLOSED BY
                        </span>
                      </th>
                      <th
                        class="hidden-md-down whiteSpace"
                        style="padding: 1rem 0.5rem !important;"
                      >
                        <span v-on:click="setPasskey('created_time')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='created_time' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'created_time'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'created_time'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          Order
                          <br />
                          <span class="pl-5">Start Time</span>
                        </span>
                      </th>
                      <th
                        class="hidden-md-down whiteSpace"
                        style="padding: 1rem 0.5rem !important;"
                      >
                        <span v-on:click="setPasskey('closed_time')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='closed_time' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'closed_time'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'closed_time'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          Order
                          <br />
                          <span class="pl-5">Closed Time</span>
                        </span>
                      </th>
                      <th
                        class="hidden-md-down whiteSpace"
                        style="padding: 1rem 0.5rem !important;"
                      >
                        <span v-on:click="setPasskey('reason')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='reason' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'reason'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'reason'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          REASON
                        </span>
                      </th>
                      <th
                        class="hidden-md-down whiteSpace"
                        style="padding: 1rem 0.5rem !important;"
                      >
                        <span v-on:click="setPasskey('comments')" class="cursor-th">
                          <!-- <i class="icon-arrow-down" style="font-size: 16px!important;"></i> -->

                          <span
                            v-if="passed_key.key=='comments' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'comments'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'comments'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                          COMMENTS
                        </span>
                      </th>
                      <!-- <th class="hidden-md-down">
                        <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span>
                        Outlet
                      </th>-->
                      <th>
                        <!-- <span><i class="icon-arrow-down" style="font-size: 16px!important;"></i></span> -->
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="ajaxCallinprogress">
                    <tr>
                      <td colspan="10" class="text-center justify-content-center">
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-if="!history.length" style="height: 100%;">
                      <td
                        colspan="10"
                        class="text-center justify-content-center"
                        style="font-size:14px;color: #303031;"
                      >
                        <!-- <strong v-if="searchdata">No Orders Found</strong> -->
                        <!-- <strong v-else>No Orders</strong> -->
                        <div v-if="searchdata" class="noorders" style="height:10%;margin-top:40px;">
                          <div>
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" />
                          </div>
                          <span
                            class="font-weight-bold text-secondary"
                            style="font-size:20px !important;"
                          >No Search Result Found</span>
                        </div>
                        <div v-else class="noorders">
                          <img
                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-orders-yet.svg"
                            style="height: 40%;margin-top:20px;"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr v-else v-for="(order,index) in history" :key="index">
                      <td class="whiteSpace">
                        <!-- <a class="product-name" @click="showOrderHistory(order._id,order.order_id,order.status)">{{order.order_id}}</a> -->
                        <a
                          class="product-name"
                          style="padding-right: 10px;width: 76px;"
                        >{{order.order_id|| '-'}}</a>
                        <span class="paid-label" v-if="order.status == 'closed'">Closed</span>
                        <span class="unpaid-label" v-else-if="order.status == 'cancelled'">Cancelled</span>
                        <span class="unpaid-label" style="padding: 5px 20px !important;" v-else-if="order.status == 'refund'">Refund</span>
                        <span class="void-label" v-else>Void</span>
                      </td>
                      <td
                        class="product-name"
                        style="text-transform:capitalize!important;max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        :title="order.customer_name"
                      >{{order.customer_name|| '-'}}</td>
                      <td
                        class="product-name"
                        style="text-transform:capitalize!important;max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        :title="order.customer_company"
                      >{{order.customer_company || '-'}}</td>
                      <td
                        class="product-name"
                        style="text-transform:capitalize!important;max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding: 17px 0px 13px 23px !important;"
                        :title="order.customer_tax_code"
                      >{{order.customer_tax_code|| '-'}}</td>
                      <!-- <td>Jack Sparrow</td> -->
                      <td
                        class="hidden-sm-down"
                        style="text-transform:capitalize!important;max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        :title="order.employee_name"
                      >{{order.employee_name|| '-'}}</td>
                      <td
                        class="hidden-md-down"
                        style="padding-top:19px;"
                      >
                      <!-- {{order.created_time|| '-'}} -->
                        {{order.created_time.slice(3,5) + '/' + order.created_time.slice(0,2) + '/' + order.created_time.slice(6,) || '-'}}
                      </td>
                      <td
                        class="hidden-md-down"
                        style="padding-top:19px;"
                      >
                      <!-- {{order.closed_time|| '-'}} -->
                      {{order.closed_time.slice(3,5) + '/' + order.closed_time.slice(0,2) + '/' + order.closed_time.slice(6,) || '-'}}
                      </td>
                      <td
                        class="hidden-md-down"
                        style="overflow: hidden;text-overflow: ellipsis;text-transform: capitalize;padding-top:19px;"
                        :title="order.reason"
                      >{{order.reason|| '-'}}</td>
                      <td
                        class="hidden-md-down"
                        style="max-width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-top:19px;"
                        :title="order.comments"
                      >{{order.comments || '-'}}</td>
                      <!-- <td class="hidden-md-down">{{order.outlet_name}}</td> -->
                      <td style="padding: 11px 8px!important;">
                        <div class="d-flex align-items-center">
                          <!-- <el-tooltip
                            class="item"
                            effect="dark"
                            content="Refund"
                            placement="top"
                          >
                          <div class="mr-2" style="padding: 0.4rem 0.2rem !important;" v-if="order.status == 'closed'" @click="showRefundPopup(order._id)">
                              <img src="/static/images/refund_icon.svg" width="16" style="width: 16px;" :class="[order._id, 'collapse-icon', 'show']" />
                          </div>
                          </el-tooltip> -->
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="View Order History"
                            placement="top"
                          >
                            <button
                              class="btn btn-icon companyactions actionicons"
                              style="padding: 0.4rem .2rem;"
                              @click="showOrderHistory(order._id,order.order_id,order.status)"
                            >
                              <i class="icon icon-eye" style="color:#00448b!important;"></i>
                            </button>
                          </el-tooltip>
                          <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                            <button
                              class="btn btn-icon companyactions actionicons"
                              style="padding: 0.4rem .2rem;"
                              @click="deleteOrderHistory(order._id)"
                              v-if="order.status == 'closed' && order.can_delete"
                            >
                              <i class="icon icon-delete mr-2" style="color:#00448b!important;"></i>
                            </button>
                          </el-tooltip>
                        </div>
                      </td>
                      <!-- <td>
                        <button class="btn btn-secondary btn-smm" >
                          ReopenP
                        </button>
                      </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <delete-item
      modal_name="delete-item-modal"
      :order_id="order_id"
      DeleteOrderDiv="DeleteOrderDiv"
      :isOrderActive="isOrderActive"
      @closed_order_history_delete="get_order_history('init')"
    ></delete-item>
    <show-order-details
      modal_name="order-show-details"
      :orderShowData="orderShowData"
      :status="status"
      :order_id="order_id"
      :orderId="orderId"
      @hideModal="hideOrderHistory"
      v-if="orderHistoryShow"
    ></show-order-details>
    <manage-order-refund v-if="is_manage_order_refund" :order_id="order_id" @hideRefundPop="hideRefundPopup" modal_name="manage-order-refund" ></manage-order-refund>
  </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event.js'
//import flatPickr from 'vue-flatpickr-component';
//import 'flatpickr/dist/flatpickr.css';
import VModal from 'vue-js-modal'
import myUpload from 'vue-image-crop-upload'
import CreateProduct from './CreateProduct'
import DatePicker from 'vue2-datepicker'
import VueHtmlToPaper from 'vue-html-to-paper'
import DeleteItem from './DeleteItem'
import ShowOrderDetails from './ShowOrderDetails'
const ManageOrderRefund = () => import('./ManageOrderRefund')
export default {
  props: ['modal_name', 'type', 'view_type'],
  data() {
    return {
      orderShowData: '',
      orderId: '',
      orderHistoryShow: false,
      status: '',
      isOrderActive: '',
      order_id: '',
      DeleteOrderDiv: '',
      toggleSideBar: false,
      dialogImageUrl: '',
      searchcustomer: 100,
      convertUTC: false,
      dialogVisible: false,
      isCompanyTimeline: true,
      isCompanyRelationship: false,
      isCompanygallery: false,
      show: false,
      history: [],
      searchdata: '',
      showDetails: false,
      details: {},
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      endDate: new Date(),
      dateRange: [],
      output: null,
      lang: {
        type: 'en',
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days'
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      confirmText: {
        type: String,
        default: 'APPLY'
      },
      ajaxCallinprogress: true,
      showIllustrator: false,
      shortcuts: [
        {
          text: 'Today',
            onClick: () => {
							this.startDate = new Date(
								new Date().getFullYear(),
								new Date().getMonth(),
								new Date().getDate()
							);
							this.endDate = new Date();
							this.dateRange = [this.startDate, this.endDate];
              this.get_order_history();
						}
        },
        {
          text: "This Week",
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - new Date().getDay()
            );
            this.endDate = new Date();
            this.dateRange = [this.startDate, this.endDate];
            this.get_order_history();
          }
        },
        {
          text: "This Month",
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            );
            this.endDate = new Date();
            this.dateRange = [this.startDate, this.endDate];
            this.get_order_history();
          }
        },
        {
          text: "This Year",
          onClick: () => {
            this.startDate = new Date(new Date().getFullYear(), 0, 1);
            this.endDate = new Date();
            this.dateRange = [this.startDate, this.endDate];
            this.get_order_history();
          }
        }
      ],
      count: 0,
      limit: 10,
      skip: 0,
      start: 1,
      end: 0,
      total_orders: 0,
      passed_key: {
        key: 'closed_time',
        value: -1
      },
      is_manage_order_refund: false
    }
  },
  components: {
    CreateProduct,
    DeleteItem,
    ShowOrderDetails,
    ManageOrderRefund
    //flatPickr
  },
  methods: {
    showRefundPopup(order_id) {
        this.order_id = order_id
        this.is_manage_order_refund = true;
        setTimeout(() => {
            this.$modal.show("manage-order-refund")
        }, 500);
    },
    hideRefundPopup() {
        this.is_manage_order_refund = false;
        setTimeout(() => {
            this.$modal.hide("manage-order-refund")
            this.get_order_history();
        }, 500);
      
    },
    deleteOrderHistory(orderid) {
      this.order_id = orderid
      this.isOrderActive = false
      this.DeleteOrderDiv = true
      this.$modal.show('delete-item-modal')
    },
    print() {
      // Pass the element id here
      this.$htmlToPaper('printMe', () => {
        this.$log.debug('Printing done or got cancelled!')
      })
    },
    createemployee() {
      this.$modal.show('bill-details')
    },
    hideemployee() {
      this.$modal.hide('bill-details')
    },
    OrderedItems() {
      ;(this.OrderedItemsTag = true), (this.DeletedItemsTag = false)
    },
    DeletedItems() {
      ;(this.OrderedItemsTag = false), (this.DeletedItemsTag = true)
    },
    resetDate() {
      let startDate = new Date()
      startDate.setHours(
        this.loggedInUser.business_day_start_time.split(':')[0],
        this.loggedInUser.business_day_start_time.split(':')[1]
      )
      let endDate = new Date()
      this.dateRange = [startDate, endDate]
      this.get_order_history()
      this.convertUTC = false
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    toggleShow() {
      this.show = !this.show
    },
    showOrderHistory(order_id, orderId, status) {
      this.orderHistoryShow = true
      this.orderId = orderId
      this.order_id = order_id
      this.status = status;
      setTimeout(() => {
        // EventBus.$emit('callorderDetails', order_id)
        this.$modal.show('order-show-details')
      }, 500);
    },
    hideOrderHistory() {
      this.show_deleted_items = false
      this.OrderedItemsTag = true
      this.DeletedItemsTag = false
      this.orderHistoryShow = false
      setTimeout(() => {
        this.$modal.hide('order-show-details')
        this.get_order_history();
      }, 500);
    },
    companyTimeline() {
      this.isCompanyTimeline = true
      this.isCompanyRelationship = false
      this.isCompanygallery = false
    },
    relationshipPage() {
      this.isCompanyRelationship = true
      this.isCompanyTimeline = false
      this.isCompanygallery = false
    },
    galleryPage() {
      this.isCompanyRelationship = false
      this.isCompanyTimeline = false
      this.isCompanygallery = true
    },
    toggleBtn() {
      this.toggleSideBar = !this.toggleSideBar
    },
    toggleBtn1() {
      this.toggleSideBar = false
    },
    get_order_history(param) {
      console.log(this.dateRange)
      this.history = []
      this.ajaxCallinprogress = true
      this.convertUTC = true
      if (param == 'prev') this.count--
      else if (param == 'next') this.count++
      else if (param == 'init') {
        this.count = 0
      } else if (param == 'searchdata') {
        this.count = 0
      }
      this.$http
        .post('orders/order_history', {
          searchdata: this.searchdata,
          dateRange: this.dateRange,
          skip: this.count * this.limit,
          limit: this.limit,
          sort_key: this.passed_key.key,
          sort_value: this.passed_key.value
        })
        .then(res => {
          if (res.data.status_id == 1) {
            this.history = res.data.response.order_history
            this.total_orders = res.data.response.total_orders
            this.start = this.count * this.limit + 1
            let tmp = this.count * this.limit + this.limit
            this.end = tmp < this.total_orders ? tmp : this.total_orders
            this.ajaxCallinprogress = false
            if (
              this.history.length == 0 &&
              !this.searchdata &&
              !this.ajaxCallinprogress
            ) {
              this.showIllustrator = true
            } else {
              this.showIllustrator = false
            }
          }
        })
    },
    setPasskey: function(newKey) {
      this.$set(this.passed_key, 'key', newKey)
      var value = this.passed_key.value == -1 ? 1 : -1
      this.$set(this.passed_key, 'value', value)
      this.get_order_history()
      // if (this.count == 0) this.get_order_history()
      // else this.count = 0
    }
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('customerdetailsLoad', false)
      let startDate
      this.startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - new Date().getDay()
      );
      let endDate = new Date()
      this.dateRange = [this.startDate, this.endDate]
      this.get_order_history()
    }, 2000)
  },
  // watch: {
  //   dateRange() {
  //     this.get_order_history();
  //   }
  // },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    }
  },
  created() {
    // this.resetDate()
    // this.get_order_history();
  }
}
</script>
<style scoped>
.w-150 {
  width: 150px;
}
.content-left.specmeter {
  color: white !important;
  flex: 0 0 130px !important;
}
.left-flex-summary.content-left.specmeter {
  color: white !important;
  flex: 0 0 170px !important;
}
.content-right {
  color: white !important;
}
.content-right .right-div {
  color: white !important;
}
.div-content {
  flex: 0 0 293px;
}
.deposit-details {
  border-radius: 8px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #005dae, #004fe3);
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.table.table-closed-orders tbody tr td,
.table.table-closed-orders thead tr th {
  border-bottom: 1px solid #a8adb4 !important;
}
.page-content {
  background-color: transparent !important;
}
.hide-arrow {
  visibility: hidden;
}
.sorting-icon {
  cursor: pointer;
}
.order-details-modal-tr td {
  font-weight: 600;
  color: #4e5764;
}
span.orderactions i {
  color: #cdd2da !important;
  cursor: pointer;
}
.contact-item.header-contact:nth-of-type(odd) {
  background-color: rgba(224, 227, 230, 0.5);
}
.contact-item.header-contact:hover {
  background: #f5f5f5;
}
.contact-item.contact-header:hover {
  cursor: default;
}
.table.tablebill.closeOrders th.thbill {
  color: #fff;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 13px !important;
  padding: 5px 8px !important;
  text-transform: none !important;
  background-color: #00448b;
  height: 40px;
}
.table.table-closed-orders {
  border-radius: 7.5px !important;
  background-color: #dee8f2 !important;
}
.table.tablebill td.tdbill {
  color: #4e5764;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 13px;
  padding: 8px 8px !important;
}
.table.tablebill tfoot th.tfbill {
  padding: 10px 8px !important;
}
.table.tablebill tfoot tr {
  border-top: dotted 0.5px #b8b9bb;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablebill tbody tr {
  height: auto !important;
}
a.add-customer-heading {
  font-weight: 600;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #00448b !important;
}
.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
.companyTimelineTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.companyRelationTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  border-left: none !important;
  cursor: pointer;
  /* border-radius: 0 20px 20px 0 !important; */
}
.companyGalleryTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.activeClass {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.closed-orders .avatar {
  background: #00448b;
}
.closed-orders .table td {
  padding: 0rem 1.8rem 0.1rem 2.2rem !important;
  cursor: pointer !important;
  font-weight: 600;
}
.closed-orders .avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.product-name {
  font-weight: 600 !important;
}
.taxes-heading {
  font-size: 14px;
  font-weight: bold;
  color: #00448b;
  padding-left: 14px;
}
.v-modal-layout {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.list-group-item.subheader {
  border-bottom: 1px solid #e0e3e6;
  min-height: 4.8rem;
  height: 4.8rem;
  font-weight: 500;
  font-size: 13px;
  line-height: 44px;
}
.filter-list {
  position: fixed;
  right: 0;
  width: 378px;
  margin-right: -378px;
  bottom: 0;
  top: 60px;
  z-index: 101;
  transition: all 0.4s ease;
}
.filter-list.right-sidebar {
  margin-right: 0;
  background: #fff;
  top: 58px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  max-height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}
.paid-label {
  background-color: #1a9347 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 18px;
  padding: 5px 20px !important;
}
.void-label {
  background-color: #f29c1f !important;
  color: #303031 !important;
  font-weight: 600;
  border-radius: 18px;
  padding: 5px 25px !important;
}
.unpaid-label {
  background-color: #ef4f4f !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 18px;
  padding: 5px 10px !important;
}
.btn.ordered_item {
  height: 70px !important;
  width: 134px !important;
  background-color: #cddbeb !important;
  color: #00448b !important;
  font-weight: 600;
  text-transform: capitalize !important;
  border: 1px solid #00448b;
  margin-right: -3px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.btn.deleted_items {
  height: 70px !important;
  width: 134px !important;
  background-color: #cddbeb !important;
  color: #00448b !important;
  font-weight: 600;
  text-transform: capitalize !important;
  border: 1px solid #00448b;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.btn.ordered_item.ordered_items_active,
.btn.deleted_items.deleted_items_active {
  background-color: #00448b !important;
  color: #fff !important;
}
.btn.ordered_item.ordered_items_active::after,
.btn.deleted_items.deleted_items_active::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #00448b;
  content: '';
  pointer-events: none;
  background-color: transparent !important;
}

.whiteSpace {
  white-space: nowrap !important;
}
/* .category-manage-info {
  top: 60px;
}
@media (max-width: 780px) {
  .category-manage-info {
    top: 65px !important;
  }
} */


</style>
