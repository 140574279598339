<template>
    <div v-if="is_cashier_session_active" class="tableselection pl-1" style="width: 100%;">
        <div class="digi-table-loader d-flex justify-content-center" v-if="loadAllTables" style="margin: 0 auto">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div v-else class="tableselection-header">
            <div class="col-md-12 flex-wrap justify-content-between align-items-center mt-3 pb-5 borderBottomDashed" style="display: flex;">
                <div class="d-flex align-items-center flex-wrap">
                    <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                        <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                            <button class="btn btn-light-grey btn-fab-dc mr-3 mt-2" style="margin-top: 1px;">
                                <i class="icons icon-arrow-left"></i>
                            </button>
                        </el-tooltip>
                    </router-link>
                    <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                        <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                            <button class="btn btn-light-grey btn-fab-dc mr-3 mt-2" style="margin-top: 1px;">
                                <i class="icons icon-arrow-right"></i>
                            </button>
                        </el-tooltip>
                    </router-link>
                    <span class="font-weight-bold fs-15 pr-3 whiteSpace mt-2" v-if="!editTable">Select Order Type:</span>
                    <div v-if="!editTable" class="d-flex flex-wrap">
                        <div v-for="orderType in allOrderTypes"  :key="orderType._id" @click="changeOrderType(orderType)" class="orderTypeGreen mt-2" :class="{'orderTypeselected':selected_order_type  == orderType.order_type ,'orderTypeDisabled':!orderType.available}">
                            <img :src="orderType.image" alt="" :class="{'w-40':orderType.order_type == 'Take Away' || orderType.order_type == 'Dine In','w-42':orderType.order_type == 'Online','w-46':orderType.order_type == 'Deliveries'}" class="pl-2 pr-2">
                            <span>{{orderType.order_type}}</span>
                        </div>
                    </div>
                </div>
                <div v-if="tables.length > 0 && selectedTable.length > 0 && disableProgress == false" class="" style="text-align: right;padding-top: 9px;">
                    <button type="submit" @click="updateTable" :disabled="update_table_api_inprogress" class="btn btn-secondary btn-smm textUppercase">Next
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="update_table_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                </div>
                <div v-if="tables.length > 0 && selectedTable.length > 0 && disableProgress == true || takeAwayId != ''" class="" style="text-align: right;padding-top: 9px;">
                    <button type="submit" :disabled="discard_order_api_inprogress || confirm_move_api_inprogress" @click="discardMove()" class="btn btn-danger btn-smm textUppercase">DISCARD
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="discard_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                    <button type="submit" :disabled="discard_order_api_inprogress || confirm_move_api_inprogress" @click="confirmMove()" class="btn btn-secondary btn-smm textUppercase ml-2">CONFIRM MOVE
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="confirm_move_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                </div>
                <div class="" v-if="selected_order_type != '' && selected_delivery_type != '' && !editTable" style="text-align: right;padding-top: 9px;">
                    <button class="btn btn-danger btn-smm textUppercase" :disabled="discard_order_api_inprogress || new_order_type_api_inprogress" @click="discardOrderAPI()" v-if="inprogress_json.length">DISCARD ALL
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="discard_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                    <button type="submit" @click="newOrderType()" :disabled="discard_order_api_inprogress || new_order_type_api_inprogress" class="btn btn-secondary btn-smm textUppercase ml-2">Next
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="new_order_type_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                </div>
                <div class="" v-if="selected_order_type == 'Take Away' && !editTable" style="text-align: right;padding-top: 9px;">
                    <button class="btn btn-danger btn-smm textUppercase" :disabled='discard_order_api_inprogress || new_order_type_api_inprogress' @click="discardOrderAPI()" v-if="inprogress_json.length">DISCARD ALL
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="discard_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                    <button type="submit" @click="newOrderType()" :disabled='discard_order_api_inprogress || new_order_type_api_inprogress' class="btn btn-secondary btn-smm textUppercase ml-2">Next
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="new_order_type_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                    </button>
                </div>
            </div>
            <div class="col-md-12 align-items-center flex-wrap pb-2" style="display: flex;" v-if="selected_order_type == 'Dine In'">
                <div class="col-lg-9 col-md-8 flex-wrap" style="padding-top: 15px;">
                    <a class="select-seats">
                        <span class="available-select"></span>
                        Available - {{availability_count}}
                    </a>
                    <a class="select-seats">
                        <div class="occupied-select"></div>
                        Occupied - {{occupied_count}}
                    </a>
                    <a class="select-seats">
                        <div class="inprogress-select"></div>
                        Inprogress - {{inprogress_count}}
                    </a>
                    <a class="select-seats">
                        <div class="assingedtoothers-select"></div>
                        Assigned to Others - {{assigned_to_others}}
                    </a>
                    <!-- <a class="select-seats">
                        <i class="text-white icon-check s-4 legend-selected"></i>
                        Selected
                    </a> -->
                </div>
                <div class="col-lg-3 col-md-4 mt-1 pr-8">
                    <div class="m-input-icon m-input-icon--left m-input-icon--right ml-7 mt-1">
                        <input type="text" class="form-control m-input m-input--pill" placeholder="Search For Tables" v-model="search_key" @input="getTables()" autocomplete="off"/>
                        <span class="m-input-icon__icon m-input-icon__icon--left">
                            <span>
                                <i class="icon icon-magnify"></i>
                            </span>
                        </span>
                        <span class="m-input-icon__icon m-input-icon__icon--right">
                            <span>
                                <i v-if="search_key.length" @click="search_key = '';getTables()" class="icon icon-close-circle" style="padding-top: 1px;!important;font-size:20px !important;color:#cdd2da !important;cursor:pointer !important;"></i>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="digi-table-loader d-flex justify-content-center" v-if="loadAllTables" style="margin: 0 auto">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div> -->
        <div class="mb-15" style="display: flex;-webkit-flex-wrap: wrap !important;margin-left: 8px;" v-if="selected_order_type == 'Dine In'">
            <div v-if="!loadAllTables && tables.length == 0" class="row no-orders-row">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="height: 80%;margin-top:60px;" /><br>
                        <span class="font-weight-bold" style="font-size:24px !important">No Search Result Found</span>
                    </div>
                </div>
            </div>
            <template v-show="loggedInUser.service_type == 'Dine-In' || loggedInUser.service_type == 'Both'" v-for="(t, index) in tables">
                <span v-if="!disableProgress" :key="index" :class="{'availableTable': t.status == 'Available','occupiedTable': t.status == 'Occupied','occupiedSelectedTable':t.selected == true || t.status=='inprogress','inProgressTable':t.status == 'inprogress' && t.selected == false,'moveSelectedTable':t.order_id == selectedProducts.order_id, 'gray-and-disabled':t.can_gray_out}" @click="toggleSelectTable(index, t.can_gray_out, t.has_manager_created_order)">
                    <i v-if="t.selected" class="text-white icon-check s-4 selected-card"></i>
                    {{t.name}}
                    <span v-if="t.is_merged || (t.status=='inprogress' && t.is_merged) && t.merged_with" class="merge-Table">
                        Merged With {{t.merged_with}}
                    </span>
                    <span v-else-if="t.selected && selectedTable.length > 1">+ {{selectedTable.length -1}} More</span>
                    <span v-else-if="t.selected && selectedTable.length == 1 && t.number_of_tables"></span>
                    <span v-else-if="t.is_primary && t.number_of_tables != 0 && t.status !='Available'">
                        <span v-if="t.number_of_tables != 0">+</span>
                        <span v-if="t.number_of_tables != 0"> {{t.number_of_tables}} </span>
                        <span v-if="t.number_of_tables != 0">More</span>
                    </span>
                </span>
                <span v-else-if="disableProgress" :key="index" :class="{'availableTable': t.status == 'Available','occupiedTable': t.status == 'Occupied' && t.order_id != selectedProducts.order_id,'occupiedSelectedTable':t.selected == true || t.status=='inprogress','inProgressTableDisable':t.status == 'inprogress' && t.selected == false,'moveSelectedTable':t.order_id == selectedProducts.order_id, 'gray-and-disabled':t.can_gray_out}" @click="toggleSelectTable(index, t.can_gray_out, t.has_manager_created_order)">
                    <i v-if="t.selected " class="text-white icon-check s-4 selected-card"></i>
                    {{t.name}}
                    <span v-if="t.is_merged || (t.status=='inprogress' && t.is_merged) && t.merged_with " class="merge-Table">
                        Merged With {{t.merged_with}}
                    </span>
                    <span v-else-if="t.selected && selectedTable.length > 1">+ {{selectedTable.length -1}} More</span>
                    <span v-else-if="t.selected && selectedTable.length == 1 && t.number_of_tables"></span>
                </span>
            </template>
        </div>
        <div class="mb-15" :class="{'borderBottomDashed pb-8':inprogress_json.length}"  style="display: flex;-webkit-flex-wrap: wrap !important;margin-left: 8px;" v-if="selected_order_type == 'Deliveries' || selected_order_type == 'Online'">
           <template v-for="delivery in delivery_companies">
               <span :key="delivery._id" @click="selectDeliveryCo(delivery)" :class="{'delivery-disable': !delivery.available}">
                    <div class="orderTypeNew" :class="{'selectedBackground':selected_delivery_type == delivery.name,'delivery-disable': !delivery.available}">
                        <avatar
                            class="order-avatar pt-6"
                            v-if="delivery.image == ''"
                            :username="delivery.name.replace(/ .*/,'')"
                            :src="delivery.image"
                            :size="70"
                            :rounded="false"
                            color="#00448b"
                            background-color="#00448b">
                        </avatar>
                        <img :src="delivery.image" class="deliveryImage" :alt="delivery.name" v-else>
                    </div>
                    <div class="font-weight-bold text-center pt-3">{{delivery.name}}</div>
               </span>
            </template>
        </div>
        <div v-if="selected_order_type == 'Deliveries' || selected_order_type == 'Online' || selected_order_type == 'Take Away'">
            <div class="font-weight-bold text-black fs-16 pb-2 ml-5 mt-1" v-if="inprogress_json.length">In Progress Order</div>
            <div class="digi-table-loader d-flex justify-content-center" v-if="ajaxCallInProgress" style="margin: 0 auto">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="d-flex flex-wrap">
                <div class="col-lg-3 col-md-3 col-sm-6 mb-10" v-for="po in inprogress_json" :key="po._id">
                    <div class="card p-3 cardNew pointer">
                        <div @click="gotoTakeOrder(po._id)">
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <span class="fw-600">Order Start Time</span>
                                <span class="fw-600">{{po.time}}</span>
                            </div>
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <span class="fw-600">Waiter Name</span>
                                <span class="fw-600 nameTextTruncate" :title="po.customer_name">{{po.waiter_name}}</span>
                            </div>
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <span class="fw-600">Customer Name</span>
                                <span class="fw-600 nameTextTruncate" :title="po.customer_name">{{po.customer_name}}</span>
                            </div>
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <span class="fw-600">Order ID</span>
                                <span class="fw-600 text-secondary">{{po.order_id}}</span>
                            </div>
                            <div class="d-flex justify-content-between pt-2 pb-2">
                                <span class="fw-600">Order Value</span>
                                <span class="fw-600">{{po.order_value}}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center pt-4 pb-2">
                            <button type="submit" class="btn btn-danger btn-smm textUppercase" :disabled="discard_order_api_inprogress" @click="discardParticularOrder(po._id)">DISCARD ORDER
                                <half-circle-spinner :animation-duration="1000" :size="20" v-if="discard_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table-selection-popup-message v-if="table_selection_popup_message" @hideMessagePopupModal="hideMessagePopupModal" modal_name="table-selection-popup-message"></table-selection-popup-message>
    </div>
    <div v-else class="tableselection pl-1" style="width: 100%;">
        <h1 class="text-center text-danger">No Active Cashier Session</h1>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import OrderManage from './OrderManage'
import { EventBus } from './eventBus/nav-bar-event.js'
import MoveItemsService from './mixins/move_items.js';
import orderServices from "./mixins/orders";
import VueScrollTo from 'vue-scrollto';
import Avatar from 'vue-avatar';
const TableSelectionPopupMessage = () => import("./TableSelectionPopupMessage")
import { HalfCircleSpinner } from "epic-spinners";
export default {
    name: 'TableSelection',
    props: [
        'orderedTable',
        'order_id',
        'editOrderTable',
        'editTable',
        'inProgressOrder',
        'editTableFromSummary',
        'editOrder',
        'isDelivered'
    ],
    mixins:[MoveItemsService, orderServices],
    data() {
        return {
            delivery_companies:[],
            selected_order_type:'',
            selected_delivery_type:'',
            new_order_type:'',
            search_key:'',
            ajaxcall:true,
            loadAllTables: false,
            selectedTable: [],
            table_document: {},
            tables: [],
            selectedTableNames: [],
            order: {
                _id: null,
                type: 'Dine In',
                tables: [],
                discount: {
                    type: 'percent',
                    value: 0
                },
                roundOff: 0.0,
                orderDayNumber: null,
                items: {},
                customerId: null,
                currency: {
                    symbol: null,
                    text: ''
                },
                itemCount: 0,
                total: 0.0,
                itemsLength: 0
            },
            occupied_count: 0,
            availability_count: 0,
            inprogress_count: 0,
            assigned_to_others: 0,
            hideNext: true,
            disableProgress: false,
            moveId:'',
            selectedProducts:{},
            new_order_id:'',
            takeAwayId: '',
            dineInView: false,
            allOrderTypes:[],
            selectedOrderType:[],
            inprogress_json:[],
            ajaxCallInProgress:false,
            is_cashier_session_active: true,
            // loadAllTables: false,
            table_selection_popup_message: false,
            discard_order_api_inprogress: false,
            confirm_move_api_inprogress: false,
            new_order_type_api_inprogress: false,
            update_table_api_inprogress: false
        }
    },
    components: {
        OrderManage,
        VueScrollTo,
        Avatar,
        TableSelectionPopupMessage,
        HalfCircleSpinner
    },
    methods: {
        hideMessagePopupModal() {
            this.table_selection_popup_message = false;
            setTimeout(() => {
                this.$modal.hide('table-selection-popup-message')
            }, 500);
        },
        gotoTakeOrder(orderId){
            this.takeOrder(orderId)
        },
        discardMove(){
            if(this.dineInView == false || this.dineInView == undefined){
                this.$router.push({
                    name:'ManageOrder',
                    params:{
                        orderType: 'All Orders',
                    }
                })
            }
            else{
                this.$router.push({
                name:'ManageOrder',
                    params:{
                        orderType: 'Dine In',
                    }
                })
            }
        },
        /**
         * This method or function is used to discard all the orders
         */
        discardOrderAPI(){
            this.$swal({
                title: '',
                text:'Are you sure you want to discard All InProgress Orders?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.discard_order_api_inprogress = true;
                    let params = {
                        discard_all:true,
                        order_type:this.selected_order_type,
                    }
                    this.discardOrder(params).then(res=>{
                        if(res.status_id == 1){
                            this.$toasted.global.success(res.message)
                            this.getInProgressOrderAPI(this.selected_order_type)
                        }else{
                            this.$toasted.global.error(res.reason)
                        }
                        this.discard_order_api_inprogress = false;
                    }).catch((err) => {
                        this.discard_order_api_inprogress = false;
                    })
                }
            })
        },
        /**
         * This method or function is used to discard a order
         */
        discardParticularOrder(orderId){
            this.$swal({
                title: '',
                text:'Are you sure you want to discard the Current Order?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.discard_order_api_inprogress = true;
                    let params = {
                        orderId:orderId
                    }
                    this.discardOrder(params).then(res=>{
                        if(res.status_id == 1){
                            this.$toasted.global.success(res.message)
                            this.getInProgressOrderAPI(this.selected_order_type)
                        }else{
                            this.$toasted.global.error(res.reason)
                        }
                        this.discard_order_api_inprogress = false;
                    }).catch((err) => {
                        this.discard_order_api_inprogress = false;
                    })
                }
            })
        },
        confirmInprogress(index) {
            this.$swal({
                title: 'Order Inprogress',
                text: 'Order is in Progress. Do You Wish to Continue ?',
                type: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PROCEED',
                cancelButtonText: 'DISCARD',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                this.order['_id'] = this.tables[index]['order_id']
                this.order_id = this.tables[index]['order_id']
                this.selectedTableNames = this.tables[index]['merged_tables']
                this.order.type = 'Dine In'
                this.inProgressOrder = true
                this.editOrderTable = true
                this.updateTable()
                } else if (result.dismiss != 'close') {
                    this.$http.post('/orders/discard', {orderId: this.tables[index].order_id}).then(res => {
                        if (res.data.status_id == 1) {
                            this.$toasted.global.success('Order Discarded Successfully')
                            this.$emit('discard', true)
                            this.selectedTable = []
                            this.selectedTableNames = []
                            this.getTables()
                            return
                        }else{
                            this.$toasted.global.error(res.data.reason)
                        }
                    })
                }
            })
        },
        closetableselection() {
            EventBus.$emit('closeTableSelect', true)
        },
        /**
         * This method or function is used to select/deselect the tables
         */
        toggleSelectTable(index, gray_out, has_manager_created_order) {
            if(!gray_out) {
                if(has_manager_created_order) {
                    this.table_selection_popup_message = true;
                    setTimeout(() => {
                        this.$modal.show('table-selection-popup-message')
                    }, 500);
                } else {
                    if(!this.disableProgress){
                        if (this.tables[index].status == 'inprogress' && !this.editTable && this.tables[index].is_primary && this.selectedTable.length == 0) {
                            this.confirmInprogress(index)
                            return
                        } else if (this.tables[index].status == 'Occupied' && !this.tables[index].selected) {
                            return
                        } else if (this.tables[index].status == 'inprogress' && this.order_id != this.tables[index].order_id) {
                            return
                        } else if (this.tables[index].status == 'inprogress' && this.selectedTable.length && this.tables[index].order_id) {
                            if (this.selectedTable[0] == this.tables[index]['name'] || this.selectedTable[0]['name'] == this.tables[index]['name']) {
                                this.removePrimaryTable()
                                return
                            } else if (this.get_matched_table(index)) {
                                if (this.tables[index].status == 'inprogress') {
                                    this.selectedTable.splice(this.selectedTable.indexOf(this.tables[index]['name']),1)
                                } else {

                                    this.selectedTable.splice(this.selectedTable.indexOf(this.tables[index]['name']),1)
                                }

                                this.tables[index]['is_merged'] = false
                                this.tables[index]['merged_with'] = ''
                                this.tables[index]['selected'] = false
                                this.tables[index]['status'] = 'Available'

                                return
                            } else {
                                return
                            }
                        } else if (this.tables[index].status == 'inprogress' && this.tables[index].is_primary && !this.inProgressOrder) {
                            this.order['_id'] = this.tables[index]['order_id']
                            this.order_id = this.tables[index]['order_id']
                            this.selectedTableNames = this.tables[index]['merged_tables']
                            this.order.type = 'Dine In'
                            this.inProgressOrder = true
                            this.editOrderTable = true
                            this.updateTable()
                            return
                        } else if (this.selectedTable[0] == this.tables[index]['name'] && this.inProgressOrder) {
                            this.removePrimaryTable()
                            return
                        } else if (this.tables[index].status == 'inprogress' && !this.tables[index].is_primary) {
                            return
                        }
                        if (this.selectedTable.length) {
                            if (this.selectedTable[0]['_id'] == this.tables[index]['_id'] || this.selectedTable[0] == this.tables[index]['name']) {
                                // return
                                this.removePrimaryTable()
                                return
                            }
                            for (var i = 0; i < this.selectedTable.length; i++) {
                                if (this.selectedTable[i]['_id'] == this.tables[index]['_id'] || this.selectedTable[i] == this.tables[index]['name']) {
                                    if (typeof this.selectedTable[i] == 'object') {
                                        this.selectedTable[i]['selected'] = false
                                    }
                                    this.tables[index]['is_merged'] = false
                                    this.tables[index]['merged_with'] = ''
                                    this.tables[index]['selected'] = false
                                    this.tables[index]['status'] = 'Available'
                                    this.selectedTable.splice(i, 1)
                                    return
                                } else {
                                    this.tables[index]['is_merged'] = true
                                    this.tables[index]['selected'] = true
                                    if (this.selectedTable[0]['name']) {
                                        this.tables[index]['merged_with'] = this.selectedTable[0]['name']
                                    } else {
                                        this.tables[index]['merged_with'] = this.selectedTable[0]
                                    }
                                }
                            }
                        }
                        this.table_document = this.tables[index]
                        this.table_document.selected = true
                        this.selectedTable.push(this.table_document)
                        return
                    }
                    else{
                        this.moveTable(index)
                    }
                }
            } else {
            }
        },
        /**
         * This method or function is used to move the order from one table to another
         */
        async moveTable(index){
            if (index == -1) {
                if(this.selectedTable.length == 0 && this.takeAwayId == ''){
                    this.takeAwayId = this.selectedProducts.order_id
                }
                else if(this.takeAwayId !=''){
                    this.deselectTakeAwayTable()
                }
            } else if(this.tables[index].status == 'Occupied' && this.tables[index].order_id != this.selectedProducts.order_id) {
                if (this.tables[index].status == 'Occupied' && !this.tables[index].selected && this.selectedTable.length == 0 && this.takeAwayId == '') {
                    this.new_order_id = this.tables[index].order_id
                }
                if (this.selectedTable.length==1) {
                    if (this.selectedTable[0]['_id'] == this.tables[index]['_id'] || this.selectedTable[0] == this.tables[index]['name']) {
                        this.deselectOccupiedTable()
                        return
                    }
                    for (var i = 0; i = 1; i) {
                        if (this.selectedTable[i]['_id'] == this.tables[index]['_id'] || this.selectedTable[i] == this.tables[index]['name']) {
                            if (typeof this.selectedTable[i] == 'object') {
                                this.selectedTable[i]['selected'] = false
                            }
                            this.tables[index]['status'] = 'Occupied'
                            this.selectedTable.splice(i, 1)
                            return
                        }
                    }
                }
                if(this.selectedTable.length == 0 && this.takeAwayId == ''){
                    this.table_document = this.tables[index]
                    this.table_document.selected = true
                    this.selectedTable.push(this.table_document)
                }
            }
            else if(this.tables[index].status == 'Available' && this.tables[index].order_id != this.selectedProducts.order_id){
                if (this.selectedTable.length && this.new_order_id == '' && this.takeAwayId == '') {
                    if (this.selectedTable[0]['_id'] == this.tables[index]['_id'] || this.selectedTable[0] == this.tables[index]['name']) {
                        this.removePrimaryTable()
                        return
                    }
                    for (var i = 0; i < this.selectedTable.length; i++) {
                        if (this.selectedTable[i]['_id'] == this.tables[index]['_id'] || this.selectedTable[i] == this.tables[index]['name']) {
                            if (typeof this.selectedTable[i] == 'object') {
                                this.selectedTable[i]['selected'] = false
                            }
                            this.tables[index]['is_merged'] = false
                            this.tables[index]['merged_with'] = ''
                            this.tables[index]['selected'] = false
                            this.tables[index]['status'] = 'Available'
                            this.selectedTable.splice(i, 1)
                            return
                        } else {
                            this.tables[index]['is_merged'] = true
                            this.tables[index]['selected'] = true
                            if (this.selectedTable[0]['name']) {
                                this.tables[index]['merged_with'] = this.selectedTable[0]['name']
                            } else {
                                this.tables[index]['merged_with'] = this.selectedTable[0]
                            }
                        }
                    }
                }
                if(this.new_order_id == '' && this.takeAwayId == ''){
                    this.table_document = this.tables[index]
                    this.table_document.selected = true
                    this.selectedTable.push(this.table_document)
                }
            }
        },
        /**
         * This method or function is used to move the order from one table to another
         * @param {old_order_id} - old_order_id is the String
         * @param {new_order_id} - new_order_id is the String
         * @param {items} - items is the Array
         * @param {tables} - tables is the Array
         * @param {type} - type is the String
         * @param {customer_id} - customer_id is the String
         * @returns {response} - gets the Result
         */
        async confirmMove(){
            if(this.takeAwayId== ''){
                this.confirm_move_api_inprogress = true;
                let params = {
                    old_order_id: this.selectedProducts.order_id,
                    new_order_id: this.new_order_id,
                    items:this.selectedProducts.items,
                    tables:this.selectedTable.map(table => table.name),
                    type:'Dine In',
                    customer_id:null
                }
                let response = await this.orderMoveItems(params);
                if(this.dineInView == false || this.dineInView == undefined){
                    this.confirm_move_api_inprogress = false;
                    this.$router.push({
                        name:'ManageOrder',
                        params:{
                            orderType: 'All Orders',
                            order_id: response.order_id,
                            order_modal: true
                        }
                    })
                }
                else{
                    this.confirm_move_api_inprogress = false;
                    this.$router.push({
                        name:'ManageOrder',
                        params:{
                            orderType: 'Dine In',
                            order_id: response.order_id,
                            order_modal: true
                        }
                    })
                }
            }
            else{
                this.confirm_move_api_inprogress = true;
                let params = {
                    old_order_id: this.selectedProducts.order_id,
                    new_order_id: "",
                    items:this.selectedProducts.items,
                    tables:[],
                    type:this.new_order_type || 'Take Away',
                    customer_id:null
                }
                let response = await this.orderMoveItems(params);
                if(this.dineInView == false || this.dineInView == undefined){
                    this.confirm_move_api_inprogress = false;
                    this.$router.push({
                        name:'ManageOrder',
                        params:{
                            orderType: 'All Orders',
                            order_id: response.order_id,
                            order_modal: true
                        }
                    })
                }
                else{
                    this.confirm_move_api_inprogress = false;
                    this.$router.push({
                        name:'ManageOrder',
                        params:{
                            orderType: 'Dine In',
                            order_id: response.order_id,
                            order_modal: true
                        }
                    })
                }
            }
        },
        /**
         * This method or function is used to get matched tables
         */
        get_matched_table(index) {
            for (var i = 0; i < this.selectedTable.length; i++) {
                if (this.selectedTable[i] == this.tables[index].name || this.selectedTable[i]['name'] == this.tables[index].name) {
                    return true
                }
            }
            return false
        },
        /**
         * This method or function is used to fetch all dine-in orders
         * @param {order_id} - order_id is the String
         * @param {for} - for is the String
         * @returns {response} - gets the Result
         */
        async get_all_orders_dine_in(order_id) {
            try {
                let params = {
                    order_id: order_id,
                    for: 'web'
                }
                let res = await this.mobileGetOrderDetails(params)
                EventBus.$emit('TableView', res.response)
                this.showOrderDetails()
                EventBus.$emit('OrderId', order_id)
            } catch (reason) {

            }
        },
        showOrderDetails() {
            this.$modal.show('order-detail')
        },
        /**
         * This method or function is used to all the tables and status of assigned/inprogress/available tables details
         * @param {search_key} - search_key is the String
         * @param {order_id} - order_id is the String
         * @returns {response} - gets the Result
         */
        getTables() {
            this.loadAllTables = true
            this.tables = []
            this.$http.post('/orders/get_all_tables', { orderId: this.order_id,search_key:this.search_key }).then(res => {
                if (res.data.status_id == 1) {
                    this.tables = res.data.message
                    this.availability_count = res.data.availability_count
                    this.occupied_count = res.data.occupied_count
                    this.inprogress_count = res.data.inprogress_count,
                    this.assigned_to_others = res.data.assigned_to_others
                    if(this.ajaxcall){
                        this.allOrderTypes = res.data.order_types
                        this.allOrderTypes.forEach(orderType => {
                            if(orderType.order_type == 'Dine In'){
                                this.selected_order_type = orderType.order_type
                            }
                        })
                        this.ajaxcall = false
                    }
                    // if(this.ajaxcall){
                    //     this.allOrderTypes = res.data.order_types
                    //     this.allOrderTypes.sort(function(x,y){
                    //         const nameA = x.order_type;
                    //         const nameB = y.order_type;
                    //         if(nameA < nameB) {
                    //             return -1;
                    //         } 
                    //         if(nameA > nameB){
                    //             return 1;
                    //         }
                    //         return 0;
                    //     });
                    //     this.selected_order_type = this.allOrderTypes[1].order_type
                    //     this.ajaxcall = false
                    // }
                    if(this.selectedTable.length){
                        let value = this.selectedTable[0]._id
                        this.tables.forEach((el,index)=>{
                            if(el._id == value){
                                this.tables[index]['selected'] = true
                            }
                        })
                        if(this.selectedTable.length > 1){
                            this.tables.forEach((el,index)=>{
                                this.selectedTable.forEach((s,si)=>{
                                    if(s.is_merged){
                                        if(el._id == s._id){
                                            this.tables[index]['is_merged'] = true
                                            this.tables[index]['merged_with'] = this.selectedTable[0].name
                                            this.tables[index]['selected'] = true
                                        }
                                    }
                                })
                            })
                        }
                    }
                    this.loadAllTables = false
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            })
            .catch(res => {
                this.modal_msg = res.response.data.reason
                this.$refs.warning_modal.open()
            })
        },
        /**
         * This method or function is used to trigger the emits for taking the order
         */
        takeOrder(orderId) {
            if (this.order.type == 'Dine In') {
                if (this.selectedTableNames.length){
                    this.$router.push({
                        name: 'TakeOrderNew',
                        params: {
                            orderId,
                            selectTable: this.selectedTableNames,
                            newOrder: true,
                            orderType: 'Dine In',
                            inProgressOrder: this.inProgressOrder
                        }
                    })
                    // this.$router.push({
                    //     name: 'TakeOrderSelectedItem',
                    //     params: {
                    //         orderId,
                    //         selectTable: this.selectedTableNames,
                    //         newOrder: true,
                    //         orderType: 'Dine In',
                    //         inProgressOrder: this.inProgressOrder
                    //     }
                    // })
                    EventBus.$emit('closeTableSelect', true)
                }else{
                    // this.$toasted.global.error('Please Select Table or Order Type')
                }
            }else{
                this.$router.push({
                    name: 'TakeOrderNew',
                    params: {
                        orderId,
                        newOrder: true,
                        orderType: this.order.type || 'Take Away',
                        inProgressOrder: this.inProgressOrder
                    }
                })
                // this.$router.push({
                //     name: 'TakeOrderSelectedItem',
                //     params: {
                //         orderId,
                //         newOrder: true,
                //         orderType: this.order.type || 'Take Away',
                //         inProgressOrder: this.inProgressOrder
                //     }
                // })
                EventBus.$emit('closeTableSelect', true)
            }
        },
        /**
         * This method or function is used to update table
         * @param {orderId} - orderId is the String
         * @param {update} - update is the Boolean
         * @param {tables} - tables is the Array
         * @param {inProgressOrder} - inProgressOrder is the Boolean
         * @returns {response} - gets the Result
         */
        async updateTable() {
            try {
                this.update_table_api_inprogress = true;
                if (this.order.type == 'Take Away') {
                    this.updateOrderType()
                    this.takeOrder(this.order._id)
                } else if (this.selectedTable.length || this.selectedTableNames.length) {
                    this.selectedTable.forEach(element => {
                        if (typeof element == 'object') {
                            this.selectedTableNames.push(element.name)
                        } else {
                            this.selectedTableNames.push(element)
                        }
                    })
                    if (this.editOrderTable) {
                        let params = {
                            tables: [...new Set(this.selectedTableNames)],
                            orderId: this.order_id,
                            updateOrder: this.editOrder,
                            inProgressOrder: this.inProgressOrder
                        }
                        let res = await this.orderTables(params)
                        if (!this.order._id && res.order_id) {
                            this.order._id = res.order_id
                            this.orderId = res.order_id
                        }
                        // this.$toasted.global.success('Table Selected Successfully')
                        if (this.editTableFromSummary) {
                            this.$emit('updateSummary', true)
                            EventBus.$emit('CloseTableSelection', true)
                        } else if (this.editTable) {
                            EventBus.$emit('CloseTableSelection', true)
                            this.takeOrder(this.order._id)
                        } else {
                            this.takeOrder(this.order._id)
                        }
                    } else {
                        let params = {
                            tables: this.selectedTableNames,
                            orderId: this.order._id,
                            inProgressOrder: this.inProgressOrder
                        }
                        let res = await this.orderTables(params)
                        if (!this.order._id && res.order_id) {
                            this.order._id = res.order_id
                            this.orderId = res.order_id
                        }
                        this.$toasted.global.success('Table Selected Successfully')
                        this.takeOrder(this.order._id)
                        EventBus.$emit('close select modal')
                    }
                    this.update_table_api_inprogress = false;
                } else {

                }
            } catch (reason) {
                this.$toasted.global.error(reason)
                this.update_table_api_inprogress = false;
            }
        },
        /**
         * This method or function is used to update the changed order type
         * @param {orderType} - orderType is the String
         * @param {orderId} - orderId is the String
         * @param {update} - update is the Boolean
         * @param {is_delivered} - is_delivered is the Boolean
         * @returns {response} - gets the Result
         */
        async updateOrderType() {
            try {
                this.update_table_api_inprogress = true;
                let params = {
                    orderType: this.order.type,
                    orderId: this.order._id,
                    update: this.editOrder,
                    is_delivered: this.isDelivered
                }
                let res = await this.orderUpdateType(params)
                if (!this.order._id && res.order_id) {
                    this.order._id = res.order_id
                    this.orderId = res.order_id
                    if(this.order.type == 'Dine In'){
                        this.takeOrder(res.order_id)
                    }else{
                        if (this.editTableFromSummary) {
                            this.$emit('updateSummary', true)
                            EventBus.$emit('CloseTableSelection', true)
                        } else {
                            this.takeOrder(res.order_id)
                        }
                    }
                }
                if (this.loggedInUser.service_type == 'Both') {
                    this.$toasted.global.success(res.message)
                }
                this.update_table_api_inprogress = false;
            } catch (reason) {
                this.$toasted.global.error(reason)
                this.update_table_api_inprogress = false;
            }
        },
        /**
         * This method or function is used to change order type for the current order
         * @param {order_type} - order_type is the String
         * @returns {response} - gets the Result
         */
        changeOrderType(value){
            if(this.selectedTable.length == 0){
                this.selected_order_type = value.order_type
                this.order.type = value.order_type
                this.selected_delivery_type = ''
                if(value.order_type == 'Take Away'){
                    this.getInProgressOrderAPI(value.order_type)
                }else if(value.order_type == 'Deliveries' || value.order_type == 'Online'){
                    this.getDeliveryCoAPI(value._id)
                    this.getInProgressOrderAPI(value.order_type)
                }else if(value.order_type == 'Dine In'){
                    this.getTables()
                }
            }else{
                this.$toasted.global.error('Please Deselect the Selected Tables If You Want to Change the Order Type')
            }
        },
        selectDeliveryCo(value){
            if(value.available) {
                this.selected_delivery_type = value.name
            } else {
                return;
            }
        },
        /**
         * This method or function is used to fetch all inprogress orders
         * @param {order_type} - order_type is the String
         * @returns {response} - gets the Result
         */
        async getInProgressOrderAPI(value){
            try {
                let params = {
                    order_type:value
                }
                this.ajaxCallInProgress = true
                this.inprogress_json = []
                let res = await this.getInProgressOrder(params)
                this.ajaxCallInProgress = false
                this.inprogress_json = res.response.orders
            } catch (reason) {
                this.ajaxCallInProgress = false
                this.$toasted.global.error(reason)
            }
        },
        newOrderType(){
            this.newOrderTypeAPI()
        },
        /**
         * This method or function is used to  change the order type of current order
         * @param {orderType} - orderType is the String
         * @param {delivery_company} - delivery_company is the String
         * @returns {response} - gets the Result
         */
        async newOrderTypeAPI(){
            this.new_order_type_api_inprogress = true;
            try {
                let params = {
                    orderType:this.selected_order_type,
                    delivery_company:this.selected_delivery_type
                }
                let res = await this.orderUpdateType(params)
                this.$toasted.global.success(res.message)
                this.new_order_type_api_inprogress = false;
                this.takeOrder(res.order_id)
            } catch (reason) {
                this.$toasted.global.error(reason)
                this.new_order_type_api_inprogress = false;
            }
        },
        /**
         * This method or function is used to fetch all delivery companies
         * @param {order_type_id} - order_type_id is the String
         * @returns {response} - gets the Result
         */
        async getDeliveryCoAPI(id){
            try {
                let params = {
                    order_type_id:id
                }
                let res = await this.getDeliveryCo(params)
                this.delivery_companies = res.response.delivery_companies
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        /**
         * This method or function is used to check take away order
         * @returns {response} - gets the Result
         */
        checkInTakeAway(value) {
            if (!this.disableProgress) {
                this.order.type = value
                if (this.order_id) {
                    this.$swal({
                        title: 'Alert?',
                        text:'By Changing to' +value + ',The Selected Tables Will Be Removed. Are You Sure You Want To Continue?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#00448b',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then(result => {
                        if (result.value) {
                            if (this.selectedTable) {
                                this.removeTables()
                            }
                            this.order._id = this.order_id
                            this.updateOrderType()
                            if (!this.editTableFromSummary) {
                                this.takeOrder(this.order_id)
                            }
                            EventBus.$emit('UpdateOrderType')
                            EventBus.$emit('CloseTableSelection', this.order.type)
                        }
                    })
                }
            } else {
                this.new_order_type = value
                this.moveTable(-1);
            }
        },
        /**
         * This method or function is used to remove the selected primary table
         * @returns {response} - gets the Result
         */
        removePrimaryTable() {
            this.$swal({
                title: 'Alert?',
                text: 'Are you sure you want to de-select the selected tables ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    if (this.selectedTable) {
                        this.removeTables()
                    }
                    this.order._id = this.order_id
                    this.updateOrderType()
                    if (!this.editTableFromSummary) {
                        this.takeOrder(this.order_id)
                    }
                    EventBus.$emit('UpdateOrderType')
                    EventBus.$emit('CloseTableSelection', this.order.type)
                }
            })
        },
        /**
         * This method or function is used to deselect the selected table
         * @returns {response} - gets the Result
         */
        deselectTakeAwayTable(){
            this.$swal({
                title: 'Alert?',
                text: 'Are you sure you want to de-select the selected tables ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.takeAwayId = ''
                } else {
                    return
                }
            })
        },
        /**
         * This method or function is used to deselect the occupied table
         * @returns {response} - gets the Result
         */
        deselectOccupiedTable(){
            this.$swal({
                title: 'Alert?',
                text: 'Are you sure you want to de-select the selected tables ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    if (this.inProgressOrder && !this.editTable) {
                        this.$http.post('/orders/discard', { orderId: this.order_id }).then(res => {
                            if (res.data.status_id == 1) {
                                this.$toasted.global.success('Order Discarded Successfully')
                                this.$emit('discard', true)
                                this.order_id = ''
                                this.order._id = null
                                this.orderId = null
                                this.new_order_id = ''
                                this.selectedTable = []
                                this.selectedTableNames = []
                                this.getTables()
                            } else {
                                this.$toasted.global.error(res.data.reason)
                            }
                        })
                        this.removeOccupiedTables()
                    } else {
                        this.removeOccupiedTables()
                    }
                } else {
                    return
                }
            })
        },
        /**
         * This method or function is used to remove the occupied table
         */
        removeOccupiedTables() {
            for (var i = 0; i < this.selectedTable.length; i++) {
                for (var j = 0; j < this.tables.length; j++) {
                    if (this.selectedTable[i]['name'] == this.tables[j]['name'] || this.selectedTable[i] == this.tables[j]['name']) {
                        this.tables[j]['selected'] = false
                        this.tables[j]['is_merged'] = false
                        this.tables[j]['merged_with'] = ''
                        this.tables[j]['status'] = 'Occupied'
                        if (typeof this.selectedTable[i] == 'object') {
                            this.selectedTable[i]['selected'] = false
                            this.selectedTable[i]['is_merged'] = false
                            this.selectedTable[i]['merged_with'] = ''
                        }
                    }
                }
            }
            this.new_order_id = ''
            this.selectedTable.splice(0, this.selectedTable.length)
            this.selectedTable.splice(0, this.selectedTableNames.length)
        },
        /**
         * This method or function is used to check if order in progress
         * if true then continue with that order
         * else create new order
         * @param {orderId} - orderId is the String
         * @returns {response} - gets the Result
         */
        continueInprogress: function() {
            this.$swal({
                title: 'Order Inprogess',
                text: 'Order is in progress. Do you wish to continue?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'PROCEED',
                cancelButtonText: 'DISCARD'
            }).then(async result => {
                if (result.value) {
                    if (this.inProgressOrder && !this.editTable) {
                        let params = { orderId: this.order_id }
                        await this.orderDiscard();
                        this.$toasted.global.success('Order Discarded Successfully')
                        this.$emit('discard', true)
                        this.order_id = ''
                        this.order._id = null
                        this.orderId = null
                        this.selectedTable = []
                        this.selectedTableNames = []
                        this.getTables()
                    }
                    this.removeTables()
                }
            })
        },
        /**
         * This method or function is used to remove tables
         */
        removeTables() {
            for (var i = 0; i < this.selectedTable.length; i++) {
                for (var j = 0; j < this.tables.length; j++) {
                    if (this.selectedTable[i]['name'] == this.tables[j]['name'] || this.selectedTable[i] == this.tables[j]['name']) {
                        this.tables[j]['selected'] = false
                        this.tables[j]['is_merged'] = false
                        this.tables[j]['merged_with'] = ''
                        this.tables[j]['status'] = 'Available'
                        if (typeof this.selectedTable[i] == 'object') {
                            this.selectedTable[i]['selected'] = false
                            this.selectedTable[i]['is_merged'] = false
                            this.selectedTable[i]['merged_with'] = ''
                        }
                    }
                }
            }
            this.selectedTable.splice(0, this.selectedTable.length)
            this.selectedTable.splice(0, this.selectedTableNames.length)
        },
        /**
         * This method or function is used to fetch all order types
         * @param {search_key} - search_key is the String
         * @param {sort_key} - sort_key is the String
         * @param {sort_value} - sort_value is the Integer
         * @returns {response} - gets the Result
         */
        async getAllOrderType(key){
            try {
                let params = {
                    search_key:'',
                    "sort_key": "_id",
                    "sort_value": 1
                }
                let res = await this.$http.post('/order_type/get_all_order_types',params)
                if(res.data.status_id == 1){
                    this.allOrderTypes = res.data.response.order_types
                    const result = [];
                    const map = new Map();
                    for (const item of this.allOrderTypes) {
                        if(!map.has(item._id)){
                            map.set(item._id, true);
                            result.push({
                                _id: item._id,
                                available:item.status,
                                image:item.image,
                                order_type:item.order_type
                            });
                        }
                    }
                    console.log(result)
                    this.allOrderTypes = result
                    this.allOrderTypes.forEach((el,index)=>{
                        if(el.order_type == key){
                            this.changeOrderType(el)
                        }
                    })
                }
            } catch (reason) {

            }
        },
        /**
         * This method or function is used to call the cashier session api 
         * To check if cashier session is active or not
         * @returns {response} - gets the Result
         */
        checkCashierSession() {
            this.loadAllTables = true
            try {
                this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    this.modal_msg = res.data.reason;
                    console.log(res.data)
                    if (res.data.status_id == 1) {
                        this.is_cashier_session_active = true;
                    } else {
                        this.is_cashier_session_active = false;
                        this.$toasted.global.error(res.data.reason);
                    }
                    this.loadAllTables = false;
                }).catch((err) => {
                    console.log(err)
                });
            } catch (reason) {
                this.is_cashier_session_active = false;
                this.loadAllTables = false;
                this.$toasted.global.error(reason);
            }
        }
    },
    created() {
        if (this.orderedTable && this.editOrderTable && this.orderedTable.length) {
            this.selectedTable = this.orderedTable
        }
        if (this.$route.params.disableProgress) {
            this.disableProgress = true
            this.selectedProducts = this.$route.params.selectedProducts
            this.dineInView = this.$route.params.dineIn
        }
    },
    watch: {
        selectedTable() {
            EventBus.$emit('selectedTable', this.selectedTable)
        },
    },
    mounted() {
        this.checkCashierSession();
        if(this.$route.params.orderType == 'Take Away'){
            let key = this.$route.params.orderType
            this.getAllOrderType(key)
        }else if(this.$route.params.orderType == 'Deliveries'){
            let key = this.$route.params.orderType
            this.getAllOrderType(key)
        }else if(this.$route.params.orderType == 'Online'){
            let key = this.$route.params.orderType
            this.getAllOrderType(key)
        }else{
            this.getTables()
        }
        if (this.orderedTable && this.editOrderTable && this.orderedTable.length) {
            this.selectedTable = this.orderedTable
        }
        EventBus.$emit('customerdetailsLoad', false)
        EventBus.$emit('settingsLoad', false)
        EventBus.$emit('reportsLoad', false)
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser
        }
    }
}
</script>
<style>
.moveSelectedTable{
    background-color:#94bef0 !important;
    cursor: not-allowed;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
}
.legend-selected {
    border-radius: 100%;
    background-color: #1dc75d;
    margin-right: 8px !important;
}
.tableselection-header {
    background: #fff;
    /* height: 110px; */
    border-bottom: 1px solid #eee;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 99;
}
.box p {
    margin-top: 12px;
}
.box-avaliable {
    height: 80px;
    border-radius: 7px;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background: #f9a00b;
    background: -webkit-linear-gradient(
        to bottom,
        #f9a00b,
        #edde5d
    );
    background: linear-gradient(
        to bottom,
        #f5a623,
        #ffd184
    );
    margin: 0;
    padding: 4px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    margin: 15px;
    cursor: pointer;
}
.box-occupied {
    height: 80px;
    border-radius: 7px;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
        to bottom,
        #00448b,
        #00448b 51%,
        #69b3fc,
        #9fcbff
    );
    margin: 0;
    padding: 4px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    margin: 15px;
    cursor: pointer;
}
.takeAway {
    cursor: pointer;
    width: 105px;
    height: 100px;
    padding-top: 19px !important;
    padding: 13px;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
        to bottom,
        #1dc75d,
        #1ed262 51%,
        #17e264,
        #9fffac
    );
}
.deliveriesTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    padding-top: 16px !important;
    padding: 2px;
    font-weight: 600 !important;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
        to bottom,
        #1dc75d,
        #1ed262 51%,
        #17e264,
        #9fffac
    );
}
.admin-card {
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    width: 120px;
    height: 90px;
    border-radius: 15px;
    color: #fff;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    background-image: linear-gradient(
        to bottom,
        #f9a00b,
        #f9a00b 51%,
        #ffd184,
        #9fcbff
    );
}
.available-select {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #f9a00b;
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.occupied-select {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #00448b;
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.inprogress-select {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.assingedtoothers-select {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    box-shadow: 0.5px 1px 0 0 rgba(0, 0, 0, 0.16);
    background-color: #797777;
    margin-top: 3px !important;
    margin-right: 8px !important;
}
.select-seats {
    display: inline-flex;
    padding-right: 24px;
    cursor: pointer;
    color: #00448b !important;
    font-weight: 600;
}
.table-label {
    color: #303030;
    font-weight: 600;
}
.availableTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #f9a00b, #f9a00b 51%, #ffd184);
}
.occupiedTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
}
.availableTable.occupiedSelectedTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
}
.occupiedSelectedTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
}
.inProgressTable {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 28px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
}
.inProgressTableDisable{
    cursor: not-allowed !important;
    width: 105px;
    user-select: none;
    height: 100px;
    font-weight: 600;
    pointer-events: none;
    padding-top: 28px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    opacity: 0.5;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #ff3a3acf, #ff5151f2);
}
.merge-Table {
    font-size: 12px;
    font-weight: 600;
    margin-top: 0px;
}
.selected-card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px !important;
    border-radius: 100px;
    margin-top: -20px;
    background-color: #4caf50 !important;
    margin-left: 32px;
    z-index: 2;
}
.orderNotification {
    width: 17px;
    height: 17px;
    display: -webkit-box;
    position: relative;
    left: 52px;
    bottom: 52px;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 11px !important;
    border-radius: 100px;
    background-color: #ff3b3b !important;
}
.swal2-popup .swal2-close{
    color: #00448b !important;
}
.orderTypeGreen{
    border-radius: 5px !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24) !important;
    border: solid 0.5px #1dc75d !important;
    background-color: #1dc75d !important;
    color: #ffff !important;
    white-space: nowrap !important;
    cursor: pointer !important;
    height: 36px !important;
    margin-right: 15px;
    font-weight: 600;
    font-size: 15px;
    width: 150px;
    line-height: 2;
}
.orderTypeDisabled{
    pointer-events: none !important;
    opacity: 0.5 !important;
}
.borderBottomDashed{
    border-bottom:2px dashed #707070 !important
}
.orderTypeselected{
    background: #00448b !important;
    border:0.5px solid #00448b !important;
}
.no-orders-row {
    margin: 0 auto;
    top: 30%;
    position: relative;
    text-align: center;
}
.fw-600{
    font-weight: 600 !important;
}
.cardNew{
    background-color: rgb(237, 238, 239) !important;
    border-top: 3px solid #00448b !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    box-shadow: none !important;
}
.nameTextTruncate{
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
.selectedBackground{
  box-shadow: 0 1px 6px 0 rgba(28, 141, 255, 0.34);
  background: #00448b !important;
}
.deliveryImage{
    width: 70px;
    height: 70px;
    margin-top: -20px;
    border-radius: 8px;
    margin-left: 8px;
}
.w-30{
    width: 30px !important;
}
.w-25{
    width: 25px;
}
.w-28{
    width: 28px !important;
}
.w-46{
    width: 46px !important;
}
.w-42{
    width: 42px !important;
}
.w-40{
    width: 40px !important;
}
.orderTypeNew {
    cursor: pointer;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #dfe8f4, #dfe8f4 51%, #dfe8f4);
}
.whiteSpace{
    white-space: nowrap;
}
.gray-and-disabled {
    background-color:#797777 !important;
    cursor: not-allowed !important;
    width: 105px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    box-shadow: none !important;
    background-image: none !important;
}
.delivery-disable {
    cursor: not-allowed !important;
    opacity: 0.5;
}
</style>
