export default {
    methods: {
        async createSetting(param) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/outlet/store_settings", param)
                    if (response.data.status_id === 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.message)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
        async updateSetting(param) {            
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/outlet/settings_update/"+param._id, param)
                    if (response.data.status_id === 1) {
                        resolve(response.data)
                    } else {                   
                        reject(response.data.response)
                    }
                } catch(error){
                    reject(error.response.data.reason)
                }
            })
        },
    }
}