import store from '../.././store/index.js'

export function checkPermissions(to) {
    const actions = store.getters.loggedInUser.actions
    if (to.includes('?')) to = to.split('?')[0]
    to = '/' + to.split('/')[1]
    switch (to) {
        case '/':
        case '/printre':
        case '/access':
        case '/forbidden':
        case '/internetexplorer':
        case '/loader':
        case '/supplychain':
        case '/auditanalysis':
        case '/auditcount':
        case '/recipe':
        case '/dashboardsupplychain':
        case '/manageorder':

        case '/vieworder':
        case '/vieworderchef':
            return true
        case '/inventorymanage':
        case '/brandmanage':
        case '/categorymanage':
        case '/subcategorymanage':
        case '/productstypemanage':
        case '/addonsmanage':
        case '/ingredientManage':
        case '/combomanage':
        case '/loyaltyManage':
        case '/createproduct':
        case '/yieldmanager':
        case '/editproduct':
        case '/addonsectionmanage':
            if (actions.inventory && actions.inventory.read) return true
            else return false
        case '/employeemanage':
            if (actions.employees && actions.employees.read) return true
            else return false
        case '/customermanage':
        case '/customerdetails':
            if (actions.customers && actions.customers.read) return true
            else return false
        case '/reportsDashboard':
        case '/reportmanage':
        case '/salesSubCategory':
        case '/payReport':
        case '/salesCategory':
        case '/salesProduct':
        case '/outputTax':
        case '/taxInvoice':
        case '/orderReport':
        case '/dashboardLocation':
            if (actions.reports && actions.reports.read) return true
            else return false
        case '/tableselection':
        case '/checkout':
        case '/homenew':
            if (
                actions.orders &&
                actions.orders.manage_order &&
                actions.orders.take_order
            )
                return true
            else return false
        case '/takeordernew':
            if (actions.orders && actions.orders.take_order) return true
            else return false
        case '/closedorders':
            if (actions.orders && actions.orders.order_history) return true
            else return false
        case '/settings':
        case '/outletmanage':
        case '/outletdetailsnew':
        case '/setup':
        case '/addcombo':
        case '/editcombo':
        case '/viewcombo':
        case '/addpricing':
        case '/editpricing':
        case '/viewpricing':
        case '/orderTableView':
        case '/createoutlet':
            if (actions.settings && actions.settings.write) return true
            else return false
        default:
            if (to.match(/billingmanage/)) {
                if (actions.bills && actions.bills.read) return true
                else return false
            }
            return false
    }
}
