<template>
  <div class="page-layout blank home">
    <!-- <img src="https://cdn.digicollect.com/cdn/posv2/top_Illustrstion.png" style="float: right;right: 0px;"> -->
    <div style="height:100%;margin-top:10px;">
      <div class="row container align-items-center h-100">
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.reports"
        >
          <router-link to="/reportsDashboard">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/take_order.svg" class="img-class" />
              <h4 class="pt-9 fs-13">Reports</h4>
              <!-- <p class="">View Reports</p> -->
              <a class="btn btn-secondary btn-smm mt-6">View Reports</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.billing"
        >
          <router-link to="/billingmanage">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/billing_card.svg" class="img-class" />
              <h4 class="pt-2 fs-13">View and Manage</h4>
              <h4 class="fs-13">Billing</h4>
              <a class="btn btn-secondary btn-smm mt-6">View Billing</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.manage_employees"
        >
          <router-link to="/employeemanage">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/manage_employees.svg" class="img-class" />
              <h4 class="pt-9 fs-13">Manage Employees</h4>
              <!-- <p class="">Manage Company<br>Employees</p> -->
              <a class="btn btn-secondary btn-smm mt-6">View Employees</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.add_new_outlet"
        >
          <router-link to="/createoutlet">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add_outlet.svg" class="img-class" />
              <h4 class="pt-9 fs-13">Add New Outlet</h4>
              <!-- <p class="">Add New Outlet</p> -->
              <a class="btn btn-secondary btn-smm mt-6">Add Outlet</a>
            </div>
          </router-link>
        </div>

        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.add_product"
        >
          <router-link to="/inventorymanage?add_product=true">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add_product.svg" class="img-class" />
              <!-- <h4 class="pt-2 fs-13"></h4> -->
              <h4 class="pt-9 fs-13">Add New Product</h4>
              <!-- <p class="">Add New Product to <br>Inventory</p> -->
              <a class="btn btn-secondary btn-smm mt-6">Add Product</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.add_customer && loggedInUser.actions.customers"
        >
          <router-link to="/customermanage?add_customer=true">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add_customer.svg" class="img-class" />
              <!-- <h4 class="pt-2 fs-13"></h4> -->
              <h4 class="pt-9 fs-13">Add New Customer</h4>
              <!-- <p class="">Create New Customers<br>And Manage</p> -->
              <a class="btn btn-secondary btn-smm mt-6">Add Customer</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.add_customer && !loggedInUser.actions.customers"
        >
          <a @click="showCreateCustomer">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add_customer.svg" class="img-class" />
              <!-- <h4 class="pt-2 fs-13"></h4> -->
              <h4 class="pt-9 fs-13">Add New Customer</h4>
              <!-- <p class="">Create New Customers<br>And Manage</p> -->
              <a class="btn btn-secondary btn-smm mt-6">Add Customer</a>
            </div>
          </a>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.place_order"
        >
          <router-link to="/tableselection">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/take_order.svg" class="img-class" />
              <h4 class="pt-9 fs-13">Place New Order</h4>
              <!-- <p class="">Take A New Order</p> -->
              <a class="btn btn-secondary btn-smm mt-6">Take Order</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.manage_orders"
        >
          <router-link to="/manageorder">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/manage_order_card.svg" class="img-class" />
              <h4 class="pt-9 fs-13">Manage Orders</h4>
              <!-- <p class="">Select / Manage <br>Orders</p> -->
              <a class="btn btn-secondary btn-smm mt-6">Manage Orders</a>
            </div>
          </router-link>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-6 mb-30"
          v-if="loggedInUser.actions.home && loggedInUser.actions.home.orders_history"
        >
          <router-link to="/closedorders">
            <div class="text-center card-border">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/view_tables.svg" class="img-class" />
              <h4 class="pt-9 fs-13">Order History</h4>
              <!-- <p class="">View And Manage <br>Past Orders</p> -->
              <a class="btn btn-secondary btn-smm mt-6">View History</a>
            </div>
          </router-link>
        </div>
      </div>
      <div>
        <create-customer modal_name="customer-create" @close="closeCreateCustomer"></create-customer>
      </div>
    </div>
  </div>
</template>
<script>
import CreateCustomer from './CreateCustomer'

import { EventBus } from './eventBus/nav-bar-event'

export default {
  data() {
    return {}
  },
  mounted() {
    EventBus.$emit('customerdetailsLoad', false)
    EventBus.$emit('settingsLoad', false)
    EventBus.$emit('reportsLoad', false)
  },
  components: {
    CreateCustomer
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    }
  },
  methods: {
    closeCreateCustomer(flag) {
      this.$modal.hide('customer-create')
    },
    showCreateCustomer() {
      this.$modal.show('customer-create')
    }
  },
  created() {
    if (this.loggedInUser.role == 'default_order_receiver') {
      this.$router.push('manageorder')
    }
  },
  watch: {
    loggedInUser() {
      if (this.loggedInUser.role == 'default_order_receiver') {
        this.$router.push('manageorder')
      }
      if (!this.loggedInUser.actions.home && !this.loggedInUser.outlet) {
        this.$router.push('CustomerManage')
      }
    }
  }
}
</script>
<style scoped>
.home {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}
.home a {
  text-decoration: none !important;
}
.card-border {
  border-radius: 3px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.5px #cdd2da;
  background-color: #ffffff;
  height: 268px;
  padding-top: 24px;
  border-radius: 15px;
}
.card-border h4 {
  font-size: 12px;
  font-weight: bold;
  color: #454545;
}
.card-border p {
  font-weight: 600;
  color: #8b8b8b;
}
.home .row.container {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .home .row.container {
    max-width: 1000px !important;
  }
}
.page-layout.home {
  background-color: #f3f3f3;
}
.page-layout.blank.home {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.icon-star.checked {
  color: orange;
}
.icon-star {
  color: #cdd2da;
  line-height: 150px;
}
.img-class {
  width: 90px !important;
  height: 100px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
i.ico-transparent,
i.ico-bordered,
i.ico-rounded,
i.ico-color,
i.ico-light,
i.ico-dark {
  color: #444;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
  border: 1px solid #555;
  background-color: transparent;
  text-align: center;
  display: inline-block;
  margin-bottom: 5px;
  margin: 4px 8px 7px 0;
}
i.ico-lg {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 45px;
}
i.ico-light {
  border: transparent !important;
  background-color: rgba(0, 0, 0, 0.05);
}
i.ico-rounded {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
</style>
