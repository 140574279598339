<template>
  <div class="pl-4 pr-4">
    <span class="font-weight-bold text-white pl-2">Home Screen Gallery :</span>
    <div class="showPictures mt-5">
      <el-upload
        :action="$serverURL + 'outlet/add_images_for_homescreen?outlet_id='+this.$route.query.outletId"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :file-list="outletImageList"
        :on-remove="handleRemove"
        :params="params"
        :headers="headers"
        accept="image/x-png, image/jpeg"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      params: {
        Authorization: this.$cookie.get('Token')
      },
      headers: {
        Authorization: this.$cookie.get('Token')
      },
      outletImageList: []
    }
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    async getAllOutletPictures() {
      let res = await this.$http.post('/outlet/get_homescreen_images', {
        outlet_id: this.$route.query.outletId
      })
      if (res.data.status_id == 1) {
        this.outletImages = res.data.response.images_for_homescreen
        // this.outletImageList.splice(0, this.outletImageList.length)
        // this.outletImages.forEach(element => {
        //     var obj = new Object()
        //     obj.name = 'image.jpg'
        //     obj.url = element
        //     this.outletImages.push(obj)
        // })
      }
    }
  },
  mounted() {
    this.getAllOutletPictures()
  }
}
</script>
<style scoped>
.showPictures {
  background-color: #dee8f2 !important;
  height: 450px;
  border-radius: 12px !important;
  padding: 15px 10px 10px 15px;
}
</style>