<template>
  <div style="width:100%;overflow: visible;" id="printMe">
    <div class="container p-8 printing">
      <div class="row col-md-12 hidden-print" style="margin-bottom: 10px;">
        <div class="col-md-6">
          <span class="navlogo">
            <i class="logo-pos" style="color:#00448b!important;font-size: 21px;"></i>
          </span>
          <span class="print-pos-text hidden-sm-down ml-2">DigiPOS</span>
        </div>
        <div class="col-md-6" style="text-align: right;">
          <button
            type="button"
            class="btn btn-smm btn-secondary print-button"
            @click="PrintElem()"
            ref="myBtn"
          >
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/printer-img.svg" />
            Print Receipt
          </button>
        </div>
      </div>
      <hr class="hidden-print" />
      <table
        class="table table-print"
        style="background-image: url(/https://cdn.digicollect.com/cdn/posv2/receipt-bg1.svg);"
      >
        <tbody>
          <tr style="height: 10px!important;">
            <td class style="padding: 0px 10px;" rowspan="5">
              <img :src="details.outlet_details.profile_pic" />
            </td>
            <td class="text-right"></td>
          </tr>
          <tr style="height: 24px!important;">
            <td class></td>
            <td class="text-right" style="padding: 0px 10px;">{{details.outlet_details.outlet_name}}</td>
          </tr>
          <tr style="height: 24px!important;">
            <td class></td>
            <td class="text-right" style="padding: 0px 10px;">{{details.outlet_details.website}}</td>
          </tr>
          <tr style="height: 24px!important;">
            <td class></td>
            <td class="text-right" style="padding: 0px 10px;">
              <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/call-answer.svg" style="width:16px !important;" />
              {{details.outlet_details.phone_number}}
            </td>
          </tr>
          <tr style="height: 24px!important;">
            <td class></td>
            <td
              class="text-right"
              style="padding: 0px 10px;"
              v-if="!details.outlet_details.outlet_address.isLongForm"
            >
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/googlelocation.svg" style="width:10px !important;" />
              {{details.outlet_details.outlet_address.street}},{{details.outlet_details.outlet_address.city}},{{details.outlet_details.outlet_address.state}}, {{details.outlet_details.outlet_address.country}},{{details.outlet_details.outlet_address.zipcode}}
            </td>
            <td class="text-right" style="padding: 0px 10px;" v-else>
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/googlelocation.svg" style="width:10px !important;" />
              {{details.outlet_details.outlet_address.street}},{{details.outlet_details.outlet_address.street2}},{{details.outlet_details.outlet_address.city}},{{details.outlet_details.outlet_address.township}},{{details.outlet_details.outlet_address.state}}, {{details.outlet_details.outlet_address.country}}{{details.outlet_details.outlet_address.zipcode}}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table">
        <tbody>
          <tr style="height: 36px!important;">
            <td class style="padding: 0px 10px;color:#00448b;font-size: 16px;">Paid Receipt #</td>
            <td class style="padding: 0px 10px;color:#00448b;">Customer Name</td>
            <td class style="padding: 0px 10px;color:#00448b;">POS ID</td>
          </tr>
          <tr style="height: 20px!important;">
            <td
              class
              style="padding: 0px 10px;color:#f5a623;font-size: 16px;"
            >{{details.other_details.receipt_id}}</td>
            <td class style="padding: 0px 10px;">{{details.customer_details.customer_name}}</td>
            <td class style="padding: 0px 10px;">{{details.other_details.pos_id}}</td>
          </tr>
          <tr style="height: 36px!important;">
            <td class style="padding: 0px 10px;" v-if="details.customer_details.phone_number">
              <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/call-answer.svg" style="width:16px !important;" />
              {{details.customer_details.phone_number}}
            </td>
            <td class style="padding: 0px 10px;color:#00448b;">Tax Code</td>
            <td class style="padding: 0px 10px;color:#00448b;">Order Type</td>
          </tr>
          <tr style="height: 20px!important;">
            <td class style="padding: 0px 10px;" v-if="details.customer_details.email">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/close-envelope.svg" style="width:16px !important;" />
              {{details.customer_details.email}}
            </td>
            <td class style="padding: 0px 10px;">{{details.other_details.tax_code}}</td>
            <td class style="padding: 0px 10px;">{{details.other_details.order_type}}</td>
          </tr>
          <tr style="height: 36px!important;">
            <td class style="padding: 0px 10px;">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/calendar.svg" style="width:16px !important;" />
              {{details.created_date}}
            </td>
            <td class style="padding: 0px 10px;color:#00448b;">Generated By</td>
            <td class style="padding: 0px 10px;"></td>
          </tr>
          <tr style="height: 20px!important;">
            <td class style="padding: 0px 10px;"></td>
            <td class style="padding: 0px 10px;">{{details.other_details.generated_by}}</td>
            <td class style="padding: 0px 10px;"></td>
          </tr>
        </tbody>
      </table>
      <div class>
        <table class="table">
          <td class="text-left td-th" style="border-bottom:2px dotted #cdd2da!important;">Items</td>
          <td class="text-left td-th" style="border-bottom:2px dotted #cdd2da!important;">QTY</td>
          <td
            class="text-left td-th"
            style="border-bottom:2px dotted #cdd2da!important;"
          >Total Amount</td>
          <tbody>
            <template v-for="(item) in details.ordered_items">
              <tr :key="item._id">
                <td
                  style="padding: 1.6rem 0.8rem !important;max-width:350px !important;display:block !important;"
                  class="text-truncate"
                >{{item.product_name}}</td>
                <td>{{item.quantity}}</td>
                <td>{{item.product_price_exclusive}}</td>
              </tr>
              <!-- <span class="text-green font-600">Add-Ons :</span><br> -->
              <tr
                v-if="item.addons.length > 0"
                style="height: 10px!important;background-color: #e2e9f6;"
              >
                <td
                  colspan="3"
                  style="padding: 6px 2px 0px 0px!important;border-radius: 6px 0px 0px 0px;"
                >
                  <span class="text-green font-600 pl-3">Add-On :</span>
                </td>
              </tr>
              <tr
                v-if="item.addons.length > 0"
                v-for="(addon, index) in item.addons"
                :key="index"
                style="height: 20px!important;"
              >
                <td
                  style="padding: 0.3rem 1.2rem!important;background-color: #e2e9f6;border-radius: 0px 0px 0px 6px;padding: 0.6rem 1.2rem!important;"
                >{{addon.name}}</td>
                <td
                  style="padding: 0.6rem 1.2rem!important;background-color: #e2e9f6;padding: 0.6rem 1.2rem!important;"
                >{{addon.quantity}}</td>
                <td
                  style="padding: 0.6rem 1.2rem!important;background-color: #e2e9f6;border-radius: 6px 0px 0px 0px;padding: 0.6rem 1.2rem!important;"
                >{{addon.price}}</td>
              </tr>
              <!-- <tr v-if="item.addons.length > 0">
                                <td colspan="3" style="padding: 0.6rem 1.2rem!important;background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.6rem 1.2rem!important;">
                                    <div>
                                        <span class="text-green font-600">Add-Ons :</span><br>
                                        <div class="flex-adons pt-1" v-for="(addon, index) in item.addons" :key="index">
                                            <span>
                                                {{addon.name}}
                                                <span class="text-green">X {{addon.quantity}}</span>
                                                <span class="text-green" v-if="index != item.addons.length - 1">,</span>
                                            </span>
                                        </div>
                                    </div>
                                </td>
              </tr>-->
            </template>
            <tr
              style="height: 36px!important;"
              class="food-summ-print"
              v-for="(item,index) in details.category_summary"
              :key="index"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;text-transform:capitalize;"
                colspan="2"
              >{{item.name}}</td>
              <td class="text-left" style="padding: 0px 10px;">{{item.value}}</td>
            </tr>
            <!-- <tr v-if="details.beverage_summary" style="height: 36px!important;" class="food-summ-print">
                            <td class="text-right" style="padding: 0px 10px;" colspan="2">Beverage Summary

                            </td>
                            <td class="text-left" style="padding: 0px 10px;">{{details.beverage_summary}}</td>
            </tr>-->
            <tr
              v-if="details.item_discount"
              style="height: 36px!important;"
              class="food-summ-print"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Item Discount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.item_discount}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Sub - Total without Discount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.sub_total_without_discount}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print" v-if="details.items_discount_total">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Item Discount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.items_discount_total}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print" v-if="details.discount_amount">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Order Discount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.discount_amount}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Promotion/Entertainment</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.promotion_value}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Sub - Total with Discount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.sub_total}}</td>
            </tr>
            <tr
              v-if="details.order_additional_charges"
              style="height: 36px!important;"
              class="food-summ-print">
              <td class="text-right" style="padding: 0px 10px;" colspan="2">Order Additional Charges</td>
              <td class="text-left" style="padding: 0px 10px;">{{details.order_additional_charges}}</td>
            </tr>
            <tr
              v-for="(charges,index) in details.other_charges"
              :key="index"
              style="height: 36px!important;"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >{{charges.name}}</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{charges.value}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Reward Points</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.loyalty_amount}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Total Amount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.total_amount}}</td>
            </tr>
            <tr
              v-for="(t,index) in details.tax"
              :key="index"
              style="height: 36px!important;"
              class="food-summ-print"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >{{t.name}}</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{t.value}}</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="food-summ-print"
              v-if="details.is_round_off_required"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Gross Total</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.gross_total}}</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="food-summ-print"
              v-if="details.is_round_off_required && details.round_off"
            >
              <td class="text-right" style="padding: 0px 10px;" colspan="2">Round Off</td>
              <td class="text-left" style="padding: 0px 10px;">{{details.round_off}}</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="tr-grand-bg food-summ-print"
              v-if="details.is_deposit_used"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:1px solid #cdd2da!important;border-top:1px solid #cdd2da!important;"
                colspan="2"
              >Deposit Amount</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:1px solid #cdd2da!important;border-top:1px solid #cdd2da!important;"
              >{{details.deposit_amount}}</td>
            </tr>
            <tr
              style="height: 36px!important;background-color:#f5a623 !important;"
              class="tr-grand-bg food-summ-print"
            >
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:1px solid #cdd2da!important;border-top:1px solid #cdd2da!important;"
                colspan="2"
              >Grand Total</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:1px solid #cdd2da!important;border-top:1px solid #cdd2da!important;"
              >{{details.grand_total}}</td>
            </tr>
            <tr style="height: 36px!important;" class="food-summ-print" v-if="details.loyalty_points_earned > 0">
              <td
                class="text-right"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
                colspan="2"
              >Loyalty Points Earned</td>
              <td
                class="text-left"
                style="padding: 0px 10px;border-bottom:2px dotted #cdd2da!important;"
              >{{details.loyalty_points_earned}}</td>
            </tr>
            <tr style="height: 30px;" v-if="details.cash_details.length > 1">
              <td style="color:#00448b;font-size:16px;">Card Details</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="food-summ-print"
              v-for="(name,index) in details.cash_details"
              :key="index"
              v-if="details.cash_details.length >= 1"
            >
              <td
                class="text-left"
                style="padding: 0px 10px;color:#00448b;border-top:1px solid #cdd2da!important;padding-top: 12px;"
              >{{name.name}}</td>
              <td
                class="text-left"
                colspan="2"
                style="padding: 0px 10px;border-top:1px solid #cdd2da!important;padding-top: 12px;color:#303031;"
              >{{name.value}}</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="tr-grand-bg food-summ-print"
              v-if="details.is_deposit_used"
            >
              <td
                class="text-left text-secondary"
                style="padding: 0px 10px;font-size:16px;"
                colspan="2"
              >Deposit Info</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="tr-grand-bg food-summ-print"
              v-if="details.is_deposit_used"
            >
              <td class="text-left" style="padding: 0px 10px;color:#00448b;">Issued By :</td>
              <td class="text-left" style="padding: 0px 10px;color:#00448b;">Date of Issue :</td>
              <td class="text-left" style="padding: 0px 10px;color:#00448b;">Bank Name :</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="tr-grand-bg food-summ-print"
              v-if="details.is_deposit_used"
            >
              <td
                class="text-left"
                style="padding: 0px 10px;"
              >{{details.deposit_information.issued_by}}</td>
              <td
                class="text-left"
                style="padding: 0px 10px;"
              >{{details.deposit_information.date_of_issue}}</td>
              <td
                class="text-left"
                style="padding: 0px 10px;"
              >{{details.deposit_information.bank_name}}</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="tr-grand-bg food-summ-print"
              v-if="details.is_deposit_used"
            >
              <td class="text-left" style="padding: 0px 10px;color:#00448b;">Check/Transfer No :</td>
              <td class="text-left" style="padding: 0px 10px;color:#00448b;">Deposit Notes:</td>
            </tr>
            <tr
              style="height: 36px!important;"
              class="tr-grand-bg food-summ-print"
              v-if="details.is_deposit_used"
            >
              <td
                class="text-left"
                style="padding: 0px 10px;"
              >{{details.deposit_information.cheque_number}}</td>
              <td
                class="text-left"
                style="padding: 0px 10px;"
              >{{details.deposit_information.deposit_notes}}</td>
            </tr>
            <tr style="height: 30px;" v-if="details.payments.length >= 1">
              <td style="color:#00448b;font-size:16px;">Split Details</td>
            </tr>
            <tr style="height: 30px;" v-if="details.payments.length > 1">
              <td style="color:#00448b;padding: 0px 10px;">Customer Name</td>
              <td style="color:#00448b;padding: 0px 10px;">Paymnet Mode</td>
              <td style="color:#00448b;padding: 0px 10px;">Amount</td>
            </tr>
            <tr
              v-for="(paymentsdetails,index) in details.payments"
              :key="index"
              style="height: 30px;"
              v-if="details.payments.length >= 1"
            >
              <td style="color:#303031;padding: 0px 10px;">{{paymentsdetails.customer_name}}</td>
              <td style="color:#303031;padding: 0px 10px;">{{paymentsdetails.mode}}</td>
              <td style="color:#303031;padding: 0px 10px;">{{paymentsdetails.amount}}</td>
            </tr>
          </tbody>
        </table>
        <div class="pt-5 text-center">
          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qr-logo-dg.svg" class="qr-logo" />
          <qrcode-vue :value="value" :size="size" level="H" foreground="#00448b"></qrcode-vue>
        </div>
        <table style="margin:0 auto" cellspacing="0 " cellpadding="0 " width="100% ">
          <tbody>
            <tr style="height:50px;width:100%">
              <td>© Copyright 2013 - {{ new Date().getFullYear() }} DigiCollect - All Rights Reserved.</td>
              <td class="text-right">
                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/footer-print.svg" />
                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/Digicollect-Logo-print.svg" style="padding-left: 12px;" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event.js'
import QrcodeVue from 'qrcode.vue'
export default {
  data() {
    return {
      details: {},
      value: 'https://www.digicollect.com',
      size: 120
    }
  },
  components: {
    QrcodeVue
  },
  created() {
    this.getReceiptDetails(this.$route.query.id)
    EventBus.$emit('mobile', true)
    // if(this.$route.params.order_id){
    // this.details = this.$route.params.receipt_details
    // }
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body

      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    },
    PrintElem() {
      // Pass the element id here
      this.$htmlToPaper('printMe')
    },
    getReceiptDetails(order_id) {
      this.$http
        .post('/mobile/get_details_for_receipt', {
          order_id: order_id
        })
        .then(res => {
          if (res.data.status_id == 1) {
            EventBus.$emit('mobile', true)
            this.details = res.data.response
          }
        })
    }
  },
  mounted() {
    EventBus.$emit('mobile', true)
    this.toggleBodyClass('addClass', 'bodyprint')
    EventBus.$on('printReceipt', order_id => {
      this.getReceiptDetails(order_id)
    })
  },
  destroyed() {
    this.toggleBodyClass('removeClass', 'bodyprint')
  }
}
</script>

<style>
.td-th {
  text-transform: uppercase !important;
  color: #00448b !important;
}
.printing table {
  font-weight: 600;
}
.print-pos-text {
  color: #00448b !important;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.26px;
}
.printing .qr-logo {
  position: absolute;
  margin-top: 31px;
  margin-left: -27px;
}
.form-wrapper {
  width: 38.4rem;
  max-width: 38.4rem;
  background: #ffffff;
  text-align: center;
  margin: 0 auto;
}
.qty-receipt {
  color: #303031;
  padding-top: 5px;
  font-weight: 600;
}
.container.printing {
  border-radius: 18px;
  margin-top: 15px;
}

body {
  overflow: auto !important;
  height: auto;
}
body.bodyprint {
  overflow: visible !important;
}

body.bodyprint {
  overflow: visible !important;
}
body.bodyprint .horizontal-layout-1 #main > .digicontainer {
  overflow: auto;
}
body.bodyprint
  .horizontal-layout-1
  #main
  > .digicontainer
  > .dccontainer
  > .ps {
  overflow: visible;
}
body.bodyprint
  .horizontal-layout-1
  #main
  > .digicontainer
  > .dccontainer
  > section.ps-container
  .bigcontainer {
  overflow: visible;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  body.bodyprint {
    overflow: visible !important;
  }
  body.bodyprint .horizontal-layout-1 #main > .digicontainer {
    overflow: visible;
  }
  body.bodyprint
    .horizontal-layout-1
    #main
    > .digicontainer
    > .dccontainer
    > .ps {
    overflow: visible;
  }
  body.bodyprint
    .horizontal-layout-1
    #main
    > .digicontainer
    > .dccontainer
    > section.ps-container
    .bigcontainer {
    overflow: visible;
  }
  .printing table {
    overflow: visible;
  }
  .digitop {
    display: none !important;
  }
  .top-navbar {
    display: block !important;
  }
  .footercomponent {
    display: none !important;
  }
  .footercomponent {
    display: none !important;
  }
  .hidden-print {
    display: none !important;
  }
  .horizontal-layout-1 {
    overflow: visible !important;
  }
  .horizontal-layout-1 > #main {
    overflow: visible;
  }
  .horizontal-layout-1 #main > .digicontainer {
    overflow: visible !important;
  }
  .horizontal-layout-1 #main > .digicontainer > .dccontainer {
    overflow: visible !important;
  }
  .ps-container {
    overflow: visible !important;
  }
  .ps {
    overflow: visible !important;
  }
  .horizontal-layout-1
    #main
    > .digicontainer
    > .dccontainer
    > section.ps-container
    .bigcontainer {
    overflow-x: auto !important;
    overflow-y: auto !important;
    overflow: visible !important;
  }
  .content-block {
    overflow: visible !important;
  }
  .printing {
    height: 100vh !important;
    margin-bottom: 300px;
    height: auto;
    overflow: visible !important;
    page-break-after: always;
    min-height: 100vh;
    margin: 0;
  }
  body {
    overflow: visible !important;
    height: auto;
  }
  body,
  html {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100vh;
  }
}
</style>
