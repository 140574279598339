<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">{{product.name}}</span>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout pt-3 pl-2 pr-3 d-form">
                        <div class="d-form__group">
                            <label class="label-qty">Quantity</label>
                            <div class="input-group" style="width: 200px;">
                                <div class="input-group-append pointer" @click="selectedQuantity--"><span class="input-group-text btnqty" id="basic-addon2"><i class="btni icon icon-minus-circle darkBlue"></i></span></div>
                                <input type="text" class="form-control m-input inputorder" v-model="selectedQuantity" aria-describedby="basic-addon2" autocomplete="off">
                                <div class="input-group-prepend pointer" @click="selectedQuantity++"><span class="input-group-text btnqty" id="basic-addon2"><i class="btni icon icon-plus-circle darkBlue"></i></span></div>
                            </div>
                        </div>
                        <div class="d-form__group" v-if="product.variants && product.variants.length">
                            <label class="label-qty">Choose Variants</label>
                            <div class="row">
                                <div class="col-md-4" v-for="(v, i) in product.variants" :key="i">
                                    <div :class="{'selectedVariant': selectedVariant==v}" class="variantsdiv" @click="selectedVariant=v">
                                        <span class="variantsname">
                                            {{v.variant_name}}
                                        </span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="variantsdiv">
                                        <span class="variantsname">
                                            Medium
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="variantsdiv">
                                        <span class="variantsname">
                                            Large
                                        </span>
                                    </div>                                        
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="v-modal-footer text-center pt-5">
                        <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideQuantityOrder()">CANCEL</a>
                        <a class="btn btn-secondary btn-smm text-uppercase" @click="addProductVariant()">ADD</a>
                    </div>
                </div>
            </div>
        </modal>
    </div>    
</template>
<style>
.variantsdiv{
    cursor: pointer;
}
.variantsdiv:hover .variantsname{
    color: #00448b;
}
/* .selectedVariant {
    background-color: #00448b;
} */
</style>

<script>
import { EventBus } from './eventBus/nav-bar-event.js';
export default {
    props: ['modal_name', 'product', 'variant', 'quantity'],
    data() {
        return {}
    },
    methods: {
        hideQuantityOrder() {
            EventBus.$emit('closeQuantityOrder', this.modal_name);
        },
        addProductVariant() {
            if(
                !!this.product && (this.selectedQuantity >= 1) &&
                (
                    (
                        Object.keys(this.selectedVariant).length === 0 && 
                        this.selectedQuantity <= parseInt(this.product.quantity)
                    ) || 
                    (
                        (this.product.variants.length && Object.keys(this.selectedVariant).length) &&
                        this.selectedQuantity <= parseInt(this.selectedVariant.variant_quantity)
                    )
                )
            ){
                this.$emit('addProductVariant', {
                    quantity: this.selectedQuantity,
                    variant: this.selectedVariant,
                    product: this.product,
                })
                EventBus.$emit('closeQuantityOrder', this.modal_name);
            }
        }
    },
    computed: {
        selectedVariant: {
            get() {
                return this.variant;
            },
            set(newVariant) {
                this.$emit('updateSelectedVariant', newVariant);
                this.$emit('updateSelectedQuantity', 1);
            }
        },
        selectedQuantity: {
            get() {
                return this.quantity;
            },
            set(newQuantity) {
                this.$emit('updateSelectedQuantity', newQuantity);
            }
        },
    },
    watch: {
        selectedQuantity(newValue, oldValue) {
            if(newValue < 1){
                this.selectedQuantity = 1;
            }
            if(this.product.variants && newValue > parseInt(this.variant.variant_quantity)){
                this.selectedQuantity = parseInt(this.variant.variant_quantity);
            } else {
                if(newValue > parseInt(this.product.quantity)){
                    this.selectedQuantity = parseInt(this.product.quantity);
                }
            }
        }
    },
}
</script>
