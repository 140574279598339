<template>
  <div class="page-layout blank report-manage">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="d-flex ml-2 mr-2 justify-content-between align-items-center flex-wrap">
          <div class="d-flex flex-wrap">
            <router-link
              :disabled="!$routerHistory.hasPrevious()"
              :to="{ path: $routerHistory.previous().path }"
            >
              <el-tooltip
                class="item pull-right"
                effect="dark"
                content="Click to Go Back"
                placement="top"
              >
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-left"></i>
                </button>
              </el-tooltip>
            </router-link>
            <router-link
              :disabled="!$routerHistory.hasForward()"
              :to="{ path: $routerHistory.next().path }"
            >
              <el-tooltip
                class="item pull-right"
                effect="dark"
                content="Click to Go Forward"
                placement="top"
              >
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-right"></i>
                </button>
              </el-tooltip>
            </router-link>
            <div class>
              <date-picker
                v-if="convertUTC == false"
                v-model="dateRange"
                @confirm="onDateChange()"
                format="MM/DD/YYYY hh:mm A"
                :first-day-of-week="7"
                range
                :show-second="false"
                :shortcuts="shortcuts"
                :lang="lang"
                :clearable="true"
                confirm
                class="date-width"
                :not-after="new Date()"
                confirm-text="APPLY"
                type="datetime"
              ></date-picker>
              <date-picker
                v-else
                v-model="dateRange"
                class="date-width"
                @confirm="onDateChange()"
                format="MM/DD/YYYY hh:mm A"
                :first-day-of-week="7"
                range
                :show-second="false"
                :shortcuts="shortcuts"
                :lang="lang"
                :clearable="true"
                confirm
                :not-after="new Date()"
                confirm-text="APPLY"
                type="datetime"
              >></date-picker>
              <!-- <date-picker
                v-else
                v-model="dateRange"
                @confirm="onDateChange()"
                format="DD-MM-YYYY hh:mm A"
                :first-day-of-week="7"
                range
                :show-second="false"
                :shortcuts="shortcuts"
                :lang="lang"
                :clearable="true"
                confirm
                :not-after="new Date()"
                confirm-text="APPLY"
                type="datetime"
                :value-type="valueType">
              ></date-picker>-->
            </div>
            <div class="col-lg-4 col-md-4 col-sm-3 resetAlign" v-if="!loggedInUser.outlet">
              <div class style="padding-top: 4px;">
                <v-select
                  label="name"
                  class="clearNone"
                  v-model="selected"
                  :clearSearchOnSelect="true"
                  :selected="selected"
                  :filterable="false"
                  :options="options"
                  @search="onSearch"
                  :clearable="false"
                ></v-select>
              </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1">
              <button type="submit" @click="resetTable" class="btn btn-secondary btn-smm">RESET</button>
            </div>
          </div>
          <!-- <div>
            <span
              @click="downloadPdf"
              class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2"
            >
              <img src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg" />
              <span class="text-white font-weight-bold ml-1">Pdf</span>
            </span>
          </div> -->
        </div>
      </h2>
      <div class="canvas_div_pdf row no-gutters justify-content-between pt-3">
        <div>
          <div class="dropdown">
            <button
              @click="download_report('OrderReport.csv')"
              class="btn btn-secondary"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg"
                class="pb-2 mr-2"
                style="width:15px;"
              />Export As CSV
            </button>
            <a href="#" id="reports"></a>

            <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#" @click="download_report('Tax Invoice.csv')">CSV</a>
              <a class="dropdown-item" href="#" @click="download_report('Tax Invoice.xlsx')">Excel</a>
              <a class="dropdown-item" href="#" @click="download_pdf()">PDF</a>
              <a href="#" id="reports"></a>
            </div>-->
          </div>
        </div>

        <div class="d-flex">
          <!-- <span class="item-per-page">Item Per Page</span>
          <select class="pagination-select">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>-->
          <div class="d-flex align-items-center">
            <span
              class="page-info px-2 hidden-md-down"
            > {{getSkipCount}} -{{total_reports>skip+limit?skip+limit:total_reports}} of {{total_reports}}</span>
            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
              <button type="button" class="btn btn-icon d-none d-md-inline el-tooltip item">
                <i class="icon icon-chevron-left" @click="previousPage()"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Next" placement="top">
              <button type="button" class="btn btn-icon d-none d-md-inline el-tooltip item">
                <i class="icon icon-chevron-right" @click="nextPage()"></i>
              </button>
            </el-tooltip>
          </div>
          <div class="breadcome-heading">
            <div class="form-group formsearch has-warning has-feedback">
              <input
                v-model="searchKey"
                @input="onkeySearch"
                type="text"
                class="form-control textremove holder"
                id="inputWarning2"
                aria-describedby="inputWarning2Status"
                placeholder="Search..."
              />
              <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="reports-label-top">ORDER REPORT</div>
          <div class="summaryBreakdown mt-4">
            <table class="table table-reports table-fixed">
              <thead>
                <tr>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'outlet_name' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'outlet_name'}"
                      @click="updateSort('outlet_name')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('outlet_name')"
                      :class="{'hide-arrow': sort.key != 'outlet_name'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    OUTLET
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'date' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'date'}"
                      @click="updateSort('date')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('date')"
                      :class="{'hide-arrow': sort.key != 'date'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    DATE
                    <br />(DD/MM/YYYY)
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'customer_name' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'customer_name'}"
                      @click="updateSort('customer_name')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('customer_name')"
                      :class="{'hide-arrow': sort.key != 'customer_name'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    CUSTOMER NAME
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'receipt_id' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'receipt_id'}"
                      @click="updateSort('receipt_id')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('receipt_id')"
                      :class="{'hide-arrow': sort.key != 'receipt_id'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    RECEIPT ID
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'order_id' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'order_id'}"
                      @click="updateSort('order_id')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('order_id')"
                      :class="{'hide-arrow': sort.key != 'order_id'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    ORDER ID
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'time' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'time'}"
                      @click="updateSort('time')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('time')"
                      :class="{'hide-arrow': sort.key != 'time'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    OPEN BILL
                    <br />TIME (HH:MM)
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'active_time' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'active_time'}"
                      @click="updateSort('active_time')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('active_time')"
                      :class="{'hide-arrow': sort.key != 'active_time'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    ORDER ACTIVE
                    <br />TIME (Min)
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'total_sale' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'total_sale'}"
                      @click="updateSort('total_sale')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('total_sale')"
                      :class="{'hide-arrow': sort.key != 'total_sale'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    TOTAL SALE
                  </th>
                  <th>
                    <span
                      class="sorting-icon"
                      v-if="sort.key == 'status' && sort.value == 1"
                      :class="{'hide-arrow': sort.key != 'status'}"
                      @click="updateSort('status')"
                    >
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span
                      style="font-size: 16px !important;"
                      class="sorting-icon"
                      v-else
                      @click="updateSort('status')"
                      :class="{'hide-arrow': sort.key != 'status'}"
                    >
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    STATUS
                  </th>
                  <th>Admin</th>
                </tr>
              </thead>
              <tbody v-if="data_exists">
                <tr :key="report._id" v-for="report in order_report">
                  <td style="padding: 0 27px!important;">{{report.outlet_name}}</td>
                  <td style="padding: 0 29px!important;">{{report.date}}</td>
                  <td
                    style="padding: 0 29px!important;"
                    :title="report.customer_name"
                    class="text-truncate d-block"
                  >{{report.customer_name}}</td>
                  <td style="padding: 0 29px!important;">{{report.receipt_id}}</td>
                  <td style="padding: 0 29px!important;">{{report.order_id}}</td>
                  <td style="padding: 0 29px!important;">{{report.time}}</td>
                  <td
                    style="padding: 0 29px!important;"
                  >{{round_minutes(report.active_time).replace('.',':')}}</td>
                  <td
                    style="padding: 0 29px!important;"
                  >{{report.total_sale}}</td>
                  <td style="padding: 0 29px!important;" v-if="report.status == 'closed'">
                    <span class="success-label">CLOSED</span>
                  </td>
                  <td style="padding: 0 29px!important;" v-if="report.status == 'cancelled'">
                    <span class="failure-label">CANCELLED</span>
                  </td>
                  <td style="padding: 0 29px!important;" v-if="report.status == 'voided'">
                    <span class="failure-label">VOIDED</span>
                  </td>
                  <td style="padding: 0 27px!important;">
                    <el-tooltip class="item" effect="dark" content="View More" placement="top">
                      <span class="pointer" @click="showMoreReport(report)">
                        <i class="icon icon-eye text-secondary"></i>
                      </span>
                      <!-- <span @click="showMoreReport(report)" class="imageView">
                        <img
                          src="https://cdn.digicollect.com/cdn/posv2/reportImages/lightEye.svg"
                          class="imageMore"
                          style="width:25px;"
                        />
                        <img
                          src="https://cdn.digicollect.com/cdn/posv2/reportImages/eye.svg"
                          class="hoverImage"
                          style="width:25px;"
                        />
                      </span>-->
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="!data_exists && searchKey.length == 0">
                <tr>
                  <td colspan="7" class="text-center d-block">
                    <strong>No Order Reports</strong>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="searchKey.length>0 && !data_exists">
                <tr>
                  <!-- {{searchKey.length}} -->
                  <td colspan="7" class="text-center d-block">
                    <strong>No Search Results Found</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr
                  style="background-image: linear-gradient(to bottom, #ffb333, #e29005);border-radius: 0px 0px 7px 7px;"
                  v-if="data_exists"
                >
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black">TOTAL</td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td
                    style="padding: 0 23px!important;"
                    class="font-weight-bold text-black"
                  >{{total_row.total_sale}}</td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;" class="font-weight-bold text-black"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <more-orderreport
      v-if="isMoreOrderReportOpen"
      @close="closeMoreOrderReport"
      :report="selectedReport"
      modal_name="order-report"
    ></more-orderreport>
  </div>
</template>
<script>
import { EventBus } from '../eventBus/nav-bar-event.js'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import MoreOrderreport from './MoreOrderreport'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
export default {
  data() {
    const value2date = dateRange => {
      const date = new Date(dateRange)
      return dateRange
        ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        : null
    }
    const date2value = date => {
      return date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        : null
    }
    return {
      valueType: {
        value2date,
        date2value
      },
      searchKey: '',
      convertUTC: false,
      selectedReport: {},
      isMoreOrderReportOpen: false,
      mobile: false,
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      dateRange: [],
      selected: {
        _id: null,
        name: 'All Outlets'
      },
      options: [],
      lang: {
        type: 'en',
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days'
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      confirmText: {
        type: String,
        default: 'APPLY'
      },
      shortcuts: [
        {
          text: 'Today',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        },
        {
          text: 'This Week',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - new Date().getDay()
            )
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        },
        {
          text: 'This Month',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            )
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        },
        {
          text: 'This Year',
          onClick: () => {
            this.startDate = new Date(new Date().getFullYear(), 0, 1)
            this.endDate = new Date()
            this.dateRange = [
              this.startDate.setHours(
                this.loggedInUser.business_day_start_time.split(':')[0],
                this.loggedInUser.business_day_start_time.split(':')[1]
              ),
              this.endDate
            ]
          }
        }
      ],
      order_report: [],
      total_row: [],
      data_exists: false,
      total_reports: 0,
      skip: 0,
      limit: 10,
      sort: {
        key: 'date',
        value: -1
      },
      timer: null
    }
  },
  methods: {
    downloadPdf() {
      var HTML_Width = $('.canvas_div_pdf').width()
      var HTML_Height = $('.canvas_div_pdf').height()
      var top_left_margin = 15
      var PDF_Width = HTML_Width + top_left_margin * 2
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2
      var canvas_image_width = HTML_Width
      var canvas_image_height = HTML_Height

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1

      html2canvas($('.canvas_div_pdf')[0], { allowTaint: true }).then(function(
        canvas
      ) {
        canvas.getContext('2d')

        console.log(canvas.height + '  ' + canvas.width)

        var imgData = canvas.toDataURL('image/jpeg', 1.0)
        var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        )

        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height)
          pdf.addImage(
            imgData,
            'JPG',
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          )
        }
        pdf.save('Order Report.pdf')
      })
    },
    resetTable() {
      this.selected = { _id: null, name: 'All Outlets' }
      let startDate = new Date()
      startDate.setHours(
        this.loggedInUser.business_day_start_time.split(':')[0],
        this.loggedInUser.business_day_start_time.split(':')[1]
      )
      let endDate = new Date()
      this.dateRange = [startDate, endDate]
      this.onDateChange()
      this.convertUTC = false
    },
    onkeySearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getOrderReport()
      }, 300)
    },
    closeMoreOrderReport() {
      this.$modal.hide('order-report')
      this.isMoreOrderReportOpen = false
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search(loading, search, vm) {
      this.$http
        .post('/outlet/get', {
          search_key: search
        })
        .then(res => {
          vm.options = res.data.outlets
          loading(false)
        })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    showMoreReport(report) {
      this.selectedReport = report
      this.isMoreOrderReportOpen = true
      setTimeout(() => {
        this.$modal.show('order-report')
      }, 100)
    },
    download_report(name) {
      let a = document.getElementById('reports')
      let payload = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        skip: this.skip,
        sort: this.sort,
        report_name: 'order_report',
        outlet_id: this.selected._id
      }
      this.$http
        .post('/reports/sales_report/download', payload)
        .then(response => {
          let csv = escape(response.data)
          let dataurl = 'data:application/csv;charset=utf-8,' + csv
          a.href = dataurl
          a.download = name
          a.click()
        })
    },
    download_pdf() {
      let a = document.getElementById('reports')
      let payload = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        skip: this.skip,
        sort: this.sort,
        report_name: 'order_report',
        outlet_id: this.selected._id
      }
      this.$http({
        method: 'POST',
        responseType: 'blob',
        url: '/reports/sales_report/download/pdf',
        data: payload
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        a.href = url
        a.download = 'order_report.pdf'
        a.click()
      })
    },
    getOrderReport() {
      let payload = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        skip: this.skip,
        sort: this.sort,
        outlet_id: this.selected._id,
        search: this.searchKey
      }
      this.$http
        .post('/reports/menu_reports/orders', payload)
        .then(response => {
          if (response.data.status_id == 1) {
            this.data_exists = true
            this.order_report = response.data.order_report
            this.total_row = response.data.total_row
            this.total_reports = response.data.total_docs
            if (this.limit == 0) {
              this.limit = 10
            }
          } else {
            this.data_exists = false
            this.limit = 0
            this.skip = 0
            this.total_reports = 0
          }
        })
    },
    updateSort(paid_date) {
      this.sort.key = paid_date
      this.sort.value = this.sort.value == 1 ? -1 : 1
      this.getOrderReport()
    },
    nextPage() {
      let skip_temp =
        this.skip + this.limit > this.total_reports
          ? this.total_reports
          : this.skip + this.limit
      if (skip_temp >= this.total_reports) {
        return
      }
      this.skip = skip_temp
      this.getOrderReport()
    },
    previousPage() {
      if (this.skip == 0) {
        return
      }
      this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit
      this.getOrderReport()
    },
    onDateChange() {
      this.convertUTC = true
      if (!this.dateRange[0] && !this.dateRange[1]) {
        this.startDate = new Date()
        this.endDate = new Date()
        // this.startDate.setHours(0, 0, 0)
        // this.endDate;
        this.startDate
        this.dateRange = [this.startDate, this.endDate]
      }
      // this.endDate.setHours(23, 59, 59);
      // this.dateRange[1].setHours(23, 59, 59);
      this.endDate
      this.dateRange[1]
      this.skip = 0
      this.getOrderReport()
    },
    round_minutes(min) {
      return min.toFixed(2)
    }
  },
  components: {
    DatePicker,
    vSelect,
    MoreOrderreport
  },
  mounted() {
    setTimeout(() => {
      let startDate = new Date()
      startDate.setHours(
        this.loggedInUser.business_day_start_time.split(':')[0],
        this.loggedInUser.business_day_start_time.split(':')[1]
      )
      console.log(startDate)
      let endDate = new Date()
      this.dateRange = [startDate, endDate]
      EventBus.$emit('customerdetailsLoad', false)
      EventBus.$emit('settingsLoad', false)
      EventBus.$emit('reportsLoad', true)
      this.getOrderReport()
    }, 2000)
  },
  created() {
    if (this.$route.query.mobile == 'true') {
      this.mobile = true
      EventBus.$emit('mobile', true)
    }
    this.$http.post('/outlet/get', {}).then(res => {
      this.selected = {
        _id: null,
        name: 'All Outlets'
      }
      this.options = [
        {
          _id: null,
          name: 'All Outlets'
        }
      ].concat(res.data.outlets)
    })
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    getSkipCount() {
      if (this.total_reports == 0) {
        return 0
      } else {
        let skip_count =
          this.skip > this.total_reports ? this.total_reports : this.skip + 1
        return skip_count
      }
    }
  },
  watch: {
    selected: function(newVal, oldVal) {
      if (newVal._id != oldVal._id) {
        this.onDateChange()
      }
    }
  }
}
</script>
<style scoped>

.reports-label-top {
  font-size: 12px;
  color: rgb(48, 48, 49);
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
  border: 2px solid #005dae;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  position: absolute;
  z-index: 9;
  text-transform: uppercase;
}
select:focus {
  outline: 0px !important;
}
ul.pager li a {
  cursor: pointer;
  text-decoration: none !important;
}
.pagination-select {
  background: transparent;
  border: none;
  margin-left: 10px;
  border-bottom: 1px solid #00448b;
  border-radius: 0px !important;
  padding-right: 15px !important;
  background-position: right 5px center !important;
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%22.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
}
.pager {
  padding-left: 28px;
  list-style: none;
  display: inline-flex;
}
.pageone {
  margin-left: 30px;
}
.summaryBreakdown {
  border: solid 1px #94bef0;
  border-radius: 10px;
}
.btn-checkout {
  border-radius: 23px;
  background-image: linear-gradient(to bottom, #ffb333, #e29005);
  color: #303031;
  padding: 0px 10px;
  margin: 0px;
  font-size: 14px !important;
  font-weight: 600;
  height: 3.4rem;
  line-height: 2.6rem;
  text-transform: capitalize;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-checkout i {
  color: #303031;
  font-size: 16px !important;
  font-weight: 600;
  padding-left: 16px;
}
.borderTableRadius {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.table-fixed tbody {
  display: block;
  width: 100%;
  height: 380px;
  max-height: 390px;
  overflow-y: auto;
}
.table-fixed thead,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: flex;
  flex: 1 1 0%;
}
.table-fixed tbody tr,
.table-fixed tbody td {
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  font-weight: 600;
}
.table-fixed thead th {
  align-items: center;
}
.table-fixed tfoot {
  line-height: 52px;
}
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.summaryBreakdown {
  overflow-x: auto;
}
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #94bef0;
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 9px solid #00448b;
}
.success-label {
  background-color: #1a9347 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 18px;
  padding: 5px 12px !important;
}
.failure-label {
  background-color: #e82828 !important;
  color: #fff !important;
  font-weight: 600;
  border-radius: 18px;
  padding: 5px 12px !important;
}
.imageView:hover .imageMore {
  display: none;
}
.hoverImage {
  display: none;
}
.imageView:hover .hoverImage {
  display: block;
}
@media screen and (max-width: 617px) {
  .titleMargin {
    padding-left: 10px !important;
  }
  .headingCategory {
    padding-left: 60px;
  }
  .backButtonAlign {
    padding-left: 1px;
  }
}
</style>
