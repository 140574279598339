<template>
    <div>
        <modal class="map-modal" :name="modal_name" transition="nice-modal-fade" :delay="100">
			<div class="v-modal-content">
				<div class="v-modal-header">
                    <div style="margin: 0 auto;">
					    <span class="v-modal-dialog-title v-modal-title headerPosition">SELECT IN MAP</span>
                    </div>
                    <div>    
					    <span class="icon icon-close mr-2 text-lightBlue pointer" @click="CancelMap"></span>
                    </div>    
				</div>
				<div class="v-modal-body">
					<div class="v-modal-layout">
						<div class="col-lg-12">
							<location-picker v-model="company_location" :options="options"></location-picker>
						</div>
					</div>
				</div>
				<div class="v-modal-dialog-actions">
					<button type="button" class="btn btn-white btn-smm btn-no-border-blue ml-3" style="color: #00448b;background-color: #fff; box-shadow: none;" @click="CancelMap()">CANCEL</button>
					<button type="button" class="btn btn-secondary btn-smm" style="box-shadow: none;" @click="AddMap()"> ADD</button>
				</div>
			</div>
		</modal>
    </div>
</template>
<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDxnWFRwG5Q4wcdbTwqFm0rUhJiasY6-ec&libraries=places"></script>
<script>
import { LocationPicker } from "vue2-location-picker";
import axios from 'axios';
export default {
    props: ['modal_name'],
      data() {
        return {
            place: null ,// optional
            // selectMap: false,
            options: {
                // is not
                map: {
                    center: { lat: 12.9716, lng: 77.5946 },
                },
                marker: {
                    center: { lat: 12.9716, lng: 77.5946 },
                },
                autocomplete: {
                    /** autocomplete options **/
                }
            },
            company_location: {
                lat: 12.9716,
                lng: 77.5946
            },
           company_address: {}
            // finds:[{company_location:{lat: 41.0082376,lng: 28.97835889999999}},{company_location:{lat: 41.0082376,lng: 28.97835889999999}}]
        }
    },
    components: {
        LocationPicker,
        axios
    },
    methods: {
        CancelMap () {
            this.$modal.hide(this.modal_name);
        },
        AddMap(){
            this.$modal.hide('map-create');
            this.$emit('update_address' , this.company_address);
        },
        getAddress() {
            var street  = '';
            var country = '';
            var city = '';
            var postal_code = '';
            var state = '';
            axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.company_location.lat + ','+ this.company_location.lng + '&key=AIzaSyDxnWFRwG5Q4wcdbTwqFm0rUhJiasY6-ec')
                .then(res =>{
                    var location_address = res.data.results[0]['address_components']
                    location_address.forEach(element => {
                        if (element['types'].includes('route')){
                            street = element['long_name'] + " "
                        }
                        if (element['types'].includes('sublocality_level_2'))  {
                            street = street.concat(element['long_name']," ")
                        }
                        if (element['types'].includes('sublocality_level_1') && !element['types'].includes('sublocality_level_2')) {
                            street = street.concat(element['long_name']," ")
                        }
                        if (element['types'].includes('country')){
                            country = element['long_name']
                        }
                        if (element['types'].includes('administrative_area_level_2') || element['types'].includes('locality') ){
                            city = element['long_name']
                        }
                        if (element['types'].includes('postal_code')){
                            postal_code = element['long_name']
                        }   
                        if (element['types'].includes('administrative_area_level_1')){
                            state = element['long_name']
                        }
                    });
                    this.company_address.street = street;
                    this.company_address.country = country;
                    this.company_address.city = city;
                    this.company_address.zip_code = postal_code
                    this.company_address.state = state;
                    this.company_address.latitude = this.company_location.lat;
                    this.company_address.longitude = this.company_location.lng;
                    })
                }
    },
    watch:{
		// company_location:function(newVal){
        //     this.$log.debug(see_location)
		// 	see_location(this.company_location,this);
		// },
        'company_location.lat'(newval, oldval){
            this.getAddress();
        },
        'company_location.lng'(newval, oldval){
            this.getAddress();
        }
	}
}
</script>
<style>
.map_container, #map{
    min-height: 400px!important;
}
</style>

