<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">{{note.name}}</span>
            </div>
            <div class="v-modal-body">
                <div class="v-modal-layout">
                    <form>
                        <div class="form-group mt-0 contactGroup">
                            <label style="color: #00448b !important;">Note</label>
                            <textarea class="form-control note" v-model="note.value" type="text" name="note" rows="5"
                                :maxlength="noteLength"
                                style="border:1px solid #a9aaad !important;padding-left:5px !important;"></textarea>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                {{noteLength - note.value.length}} / {{noteLength}}</p>
                        </div>
                    </form>
                </div>
                <div class="v-modal-footer text-center pt-5">
                    <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
                        @click="closeNote()">CANCEL</a>
                    <button class="btn btn-secondary btn-smm text-uppercase" @click="submitNote()"
                        :class="{disabled:note.value.length < 1}">SUBMIT</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    export default {
        props: ['modal_name', 'note'],
        data() {
            return {
                noteLength: 200
            }
        },
        methods: {
            submitNote() {
                this.$emit('addNote', this.note)
            },
            closeNote() {
                this.$emit('closeNote', true)
            }
        }
    }

</script>
<style>
    .form-control.note {
        height: inherit !important;
        border: 1px solid #cdd2da !important;
        border-radius: 5px !important;
    }

</style>
