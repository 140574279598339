<template>
    <div class="d-flex align-items-center">
        <div class="container">
            <h6>{{orders.title}}</h6>
            <span class="text-capitalize">{{orders.body.msg.replace(/_/g, " ")}}</span>
        </div>
    </div>
</template>
<script>
export default {
    props:['orders','manage_order'],
    methods:{
        reloadPage(){
            window.location.reload()
        }
    }
}
</script>