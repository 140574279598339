<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="delete-modal-item-component final-modal popup-modal-all-width-height-height-full"
        @opened="opened" @before-close="beforeClose">
        <div class="v-modal-content" v-if="!DeleteOrderDiv">
            <div class="general-section" style="padding: 15px 0px;">
            <div class="v-modal-body">
                <span class="pull-right" style="cursor:pointer;" @click="closeModal()">
                    <i class="icon icon-close" style="font-size: 18px!important; color: #0d84f2;"></i>
                </span>
                <div class="">
                    <div class="text-center pl-6">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/spoilage.svg" class="SpoilageImg" />
                    </div>
                    <p class="deleteItemText mt-3 text-center">{{product_name}}</p>
                    <p class="text-center" v-if="voidTrue == true">
                        <span class="reasonVoid">Void</span>
                    </p>
                    <p class="text-center" v-if="voidTrue == false">
                        <span class="reasonCancel">Cancelled</span>
                    </p>
                    <p class="deleteNextItem text-center">
                        Enter No. of Items Needs To be
                        <br />Deleted
                    </p>
                    <div class="mt-2 text-center">
                        <input class="deleteItemModal" name="quantity" type="number"
                            v-validate="'required|numeric|min_value:1|max_value:' + product_quantity"
                            v-model="delete_quantity" :max="product_quantity" :min="1"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');" autofocus
                            style="border: 1px solid #cdd2da !important;" />
                    </div>
                    <div v-if="errors.has('quantity')" class="text-danger mt-2 mb-2 pl-0 text-center">
                        {{errors.first('quantity')}}</div>
                    <p class="text-center pt-4 pb-2 font-weight-bold">Consider Items As</p>
                    <div class="" style="overflow-y: auto;height: 300px;padding-bottom: 120px;overflow-x: hidden;">
                        <div class="row">
                            <div v-for="(deletereason,index) in deletereasons" :key="index" class="col-md-6 px-0">
                                <div class="customReasons"
                                    :class="{isSelectedReason: deletereason._id.includes(deleted_id)}"
                                    @click="selectedReason(deletereason,index)">
                                    <span class="d-block">
                                        <img class="avatar" v-if="deletereason.image_url != ''"
                                            :src="deletereason.image_url"
                                            style="background-color: transparent !important;" />
                                        <avatar class="product-avatar content-center"
                                            style="background-color: transparent !important;" v-else
                                            :username="deletereason.name.replace(/ .*/,'')" :src="deletereason.image_url"
                                            :size="40" :rounded="false" color="#f5a623" background-color="transparent">
                                        </avatar>
                                    </span>
                                    <span>{{deletereason.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="!commentsTrue">
                            <p class="font-600 pt-4 pb-2">Comments</p>
                            <textarea v-model="comments" name="comments" :maxlength="commentsLength" class="form-control"
                                style="height: 96px !important;
                border: 1px solid #a2a4a9 !important;
                border-radius: 7px !important;
                padding: 10px 6px !important;
                background-color: transparent !important;font-weight:600;"></textarea>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                {{commentsLength - comments.length}} / {{commentsLength}}</p>
                        </div>
                        <div v-if="commentsTrue">
                            <p class="font-600 pt-4 pb-2">Comments*</p>
                            <textarea v-model="comments" class="form-control" v-validate="'required'"
                                :maxlength="commentsLength" name="comments" style="height: 96px !important;
                border: 1px solid #a2a4a9 !important;
                border-radius: 7px !important;
                padding: 10px 6px !important;
                background-color: transparent !important;font-weight:600;"></textarea>
                            <span class="invalid-feedback-form" v-show="errors.has('comments') && isSubmitted"
                                style="display:inline;">Comment is Required</span>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                {{commentsLength - comments.length}} / {{commentsLength}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="v-modal-footer text-center pt-2 pb-4 modal-bg">
                <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase mr-5" :disabled="delete_item_api_inprogress" @click="cancelDeleteItem()"
                    style="border: 1px solid #0d84f2!important;">CANCEL</a>
                <button class="btn btn-secondary btn-smm text-uppercase"
                    @click="DeleteItem(delete_quantity,reason,comments)" :disabled="delete_item_api_inprogress">DELETE
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="delete_item_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
        <div class="v-modal-content" v-else>
            <div class="general-section" style="padding: 15px 0px;">
            <div class="v-modal-body">
                <span class="pull-right" style="cursor:pointer;" @click="closeModal()">
                    <i class="icon icon-close" style="font-size: 18px!important; color: #0d84f2;"></i>
                </span>
                <div class="">
                    <div class="text-center pl-6">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/spoilage.svg" class="SpoilageImg" />
                    </div>
                    <p class="deleteItemText mt-6 text-center">Delete Order {{temp_order_id}}</p>
                    <p class="text-center" v-if="voidTrue == true">
                        <span class="reasonVoid">Void</span>
                    </p>
                    <p class="text-center" v-if="voidTrue == false">
                        <span class="reasonCancel">Cancelled</span>
                    </p>
                    <p class="text-center pt-4 pb-2 text-center font-weight-bold">Consider Order As</p>
                    <div class="" style="overflow-y: auto;height: 300px;padding-bottom: 120px;overflow-x: hidden;">
                        <div class="row">
                            <div v-for="(deletereason,index) in deletereasons" :key="index" class="col-md-6 px-0">
                                <div class="customReasons"
                                    :class="{isSelectedReason: deletereason._id.includes(deleted_id)}"
                                    @click="selectedReason(deletereason,index)">
                                    <span class="d-block">
                                        <img class="avatar" v-if="deletereason.image_url != ''"
                                            :src="deletereason.image_url"
                                            style="background-color: transparent !important;" />
                                        <avatar class="product-avatar content-center"
                                            style="background-color: transparent !important;" v-else
                                            :username="deletereason.name.replace(/ .*/,'')" :src="deletereason.image_url"
                                            :size="40" :rounded="false" color="#f5a623" background-color="transparent">
                                        </avatar>
                                    </span>
                                    <span>{{deletereason.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="!commentsTrue">
                            <p class="font-600 pt-4 pb-2">Comments</p>
                            <textarea v-model="comments" name="comments" :maxlength="commentsLength" class="form-control"
                                style="height: 96px !important;
                border: 1px solid #a2a4a9 !important;
                border-radius: 7px !important;
                padding: 10px 6px !important;
                background-color: transparent !important;font-weight:600;"></textarea>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                {{commentsLength - comments.length}} / {{commentsLength}}</p>
                        </div>
                        <div v-if="commentsTrue">
                            <p class="font-600 pt-4 pb-2">Comments*</p>
                            <textarea v-model="comments" name="comments" :maxlength="commentsLength" v-validate="'required'"
                                class="form-control" style="height: 96px !important;
                border: 1px solid #a2a4a9 !important;
                border-radius: 7px !important;
                padding: 10px 6px !important;
                background-color: transparent !important;font-weight:600;"></textarea>
                            <span class="invalid-feedback-form" v-show="errors.has('comments') && isSubmitted"
                                style="display:inline;">Comment is Required</span>
                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                {{commentsLength - comments.length}} / {{commentsLength}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="v-modal-footer text-center pt-2 pb-4 modal-bg">
                <button class="btn btn-white btn-smm btn-no-border-blue text-uppercase mr-5" :disabled="delete_order_api_inprogress" @click="cancelDeleteItem()"
                    style="border: 1px solid #0d84f2!important;">CANCEL</button>
                <button class="btn btn-secondary btn-smm text-uppercase" :disabled="delete_order_api_inprogress" v-if="$route.path== '/closedorders'"
                    @click="deleteCloseOrder(order_id,reason,comments)">DELETE
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="delete_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
                <button v-else class="btn btn-secondary btn-smm text-uppercase" :disabled="delete_order_api_inprogress"
                    @click="deleteOneOrder(order_id,reason,comments)">DELETE
                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="delete_order_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                </button>
            </div>
        </div>
    </modal>
</template>
<script>
    import VModal from 'vue-js-modal'
    import ManageOrderService from './mixins/manage_order.js'
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'
    import {
        types
    } from 'util'
    import Avatar from 'vue-avatar'
    import printers from "./mixins/printers.js"
    import Swal from 'sweetalert2'
    import { HalfCircleSpinner } from "epic-spinners";

    function initialState() {
        return {
            temp_product_quantity: 0,
            isSpoilt: false,
            reason: '',
            comments: '',
            DeleteOrderDiv: true,
            deletereasons: [],
            isSelected: false,
            commentsTrue: false,
            commentsLength: 100,
            isSubmitted: false,
            voidTrue: false,
            deleted_id: null,
            delete_order_api_inprogress: false,
            delete_item_api_inprogress: false
        }
    }
    export default {
        props: [
            'modal_name',
            'delete_quantity',
            'item_id',
            'product_name',
            'view_type',
            'type',
            'product_quantity',
            'order_id',
            'DeleteOrderDiv',
            'temp_order_id',
            'isOrderActive'
        ],
        mixins: [ManageOrderService,printers],
        data() {
            return initialState()
        },
        components: {
            VModal,
            Avatar,
            Swal,
            HalfCircleSpinner
        },
        methods: {
            opened() {
                this.getReasons()
            },
            beforeClose() {
                this.reset()
            },
            reset() {
                Object.assign(this.$data, initialState())
            },
            DeleteModal() {
                this.$modal.show('delete-item-modal')
            },
            cancelDeleteItem() {
                this.comments = ''
                this.reason = ''
                this.delete_quantity = this.product_quantity
                this.$modal.hide('delete-item-modal')
            },
            DeleteItem(delete_quantity, reason, comments) {
                console.log('delete item')
                this.isSubmitted = true
                this.$validator.validateAll().then(async result => {
                    if (result) {
                        this.isSubmitted = false
                        this.delete_item_api_inprogress = true;
                        // let category_data = []
                        // let data = JSON.parse(sessionStorage.getItem("categories_printer"))
                        // if(data){
                        //     data.forEach(y => {
                        //         y.printers.forEach(x => {
                        //             if(y._id == x.category_id){
                        //                 category_data.push({category_id: x.category_id,printer_id:x.printer_id})
                        //             }
                        //         })
                        //     })
                        // }
                        let params = {
                            delete_quantity : delete_quantity,
                            item_id: this.item_id,
                            view_type: this.view_type,
                            type: this.type,
                            order_id: this.order_id,
                            reason: reason,
                            comments: comments,
                            // printer_details: category_data
                        }
                        let response = await this.deleteItemnewConfirm(params)
                        console.log(response)
                        this.$toasted.global.success(response.response.message)
                        if(response.response.last_item) {
                            this.$router.push('/manageorder');
                            let ticket_data = []
                            ticket_data = response.response.ticket_details
                            Swal({
                                title: 'Print Tickets',
                                text: 'Do You Want to Print Tickets ?',
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#00448b',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Continue Without Printing',
                                allowOutsideClick: false
                            }).then(result => {
                                if (result.value) {
                                    this.show_item_deleted = true
                                    ticket_data.forEach(z => {
                                        this.store_ticket_data = z
                                        this.printTicket(z)
                                    })
                                    this.$modal.hide(this.modal_name)
                                    this.$emit("hideDeleteItemModal")
                                } else {
                                }
                            })
                            this.delete_item_api_inprogress = false;
                        } else {
                            let ticket_data = []
                            ticket_data = response.response.ticket_details
                            this.$emit('item_delete_success');
                            Swal({
                                title: 'Print Tickets',
                                text: 'Do You Want to Print Tickets ?',
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#00448b',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Continue Without Printing',
                                allowOutsideClick: false
                            }).then(result => {
                                if (result.value) {
                                    this.show_item_deleted = true
                                    ticket_data.forEach(z => {
                                        this.store_ticket_data = z
                                        this.printTicket(z)
                                    })
                                    this.$modal.hide(this.modal_name)
                                    this.$emit("hideDeleteItemModal")
                                } else {
                                }
                            })
                            this.delete_item_api_inprogress = false;
                        }
                    }
                })
            },
            deleteOneOrder(order_id, reason, comments) {
                console.log('delete order')
                this.isSubmitted = true
                this.$validator.validateAll().then(async result => {
                    if (result) {
                        this.isSubmitted = false
                        this.delete_order_api_inprogress = true;
                        try {
                            var order_response = await this.deleteOrder(order_id, reason, comments)
                            this.$toasted.global.success("Order Deleted Successfully")
                            // this.$emit('item_delete_success');
                            setTimeout(() => {
                                this.delete_order_api_inprogress = false;
                                this.$router.push('/manageorder');
                            }, 2000);
                        }
                        catch(error) {
                            this.$toasted.global.error(error)
                            this.delete_order_api_inprogress = false;
                        }
                    }
                })
            },
            deleteCloseOrder(order_id, reason, comments) {
                console.log('delete order')
                this.isSubmitted = true
                this.$validator.validateAll().then(async result => {
                    if (result) {
                        this.isSubmitted = false
                        this.delete_order_api_inprogress = true;
                        try {
                            let params = {
                                order_id: order_id,
                                reason: reason,
                                comments: comments
                            }
                            let response = await this.deleteOrderClose(params)
                            this.$toasted.global.success("Order Deleted Successfully")
                            this.$emit('item_delete_success');
                            // let ticket_data = []
                            // ticket_data = response.response.ticket_details
                            // Swal({
                            //     title: 'Print Tickets',
                            //     text: 'Do You Want to Print Tickets ?',
                            //     type: 'warning',
                            //     showCancelButton: true,
                            //     confirmButtonColor: '#00448b',
                            //     cancelButtonColor: '#d33',
                            //     confirmButtonText: 'Yes',
                            //     cancelButtonText: 'Continue Without Printing',
                            //     allowOutsideClick: false
                            // }).then(result => {
                            //     if (result.value) {
                            //         this.show_item_deleted = true
                            //         ticket_data.forEach(z => {
                            //             this.store_ticket_data = z
                            //             this.printTicket(z)
                            //         })
                            //         this.$modal.hide(this.modal_name)
                            //         this.$emit("hideDeleteItemModal")
                            //     } else {
                            //     }
                            // })
                            this.$modal.hide('delete-item-modal')
                            // this.$toasted.global.success(reason)
                            this.$emit('closed_order_history_delete')
                            setTimeout(() => {
                                this.delete_order_api_inprogress = false;
                            }, 2000);
                        }
                        catch (error) {
                            this.$toasted.global.error(error)
                            this.delete_order_api_inprogress = false;
                        }
                    }
                })
            },
            getReasons() {
                this.$http
                    .post('/orders/get_reasons_to_delete', {
                        order_id: this.order_id,
                        item_id: this.item_id,
                        is_available: true,
                        isOrderActive: this.isOrderActive
                    })
                    .then(response => {
                        if (response.data.status_id == 1) {
                            let deleteType = response.data.type
                            if (deleteType == 'cancelled') {
                                this.voidTrue = false
                            } else if (deleteType == 'void') {
                                this.voidTrue = true
                            }
                            this.deletereasons = response.data.response
                            this.deleted_id = this.deletereasons[0]._id
                            this.reason = response.data.response[0].name
                            if (this.deletereasons[0].name == 'Others') {
                                this.commentsTrue = true
                                this.comments = ''
                            } else {
                                this.commentsTrue = false
                                this.comments = ''
                            }
                        }
                    })
            },
            selectedReason(deletereason, index) {
                this.deleted_id = this.deletereasons[index]._id
                if (deletereason.name == 'Others') {
                    this.reason = deletereason.name
                    this.commentsTrue = true
                    this.comments = ''
                } else {
                    this.reason = deletereason.name
                    this.commentsTrue = false
                    this.comments = ''
                }
            },
            closeModal() {
                this.$modal.hide(this.modal_name)
            }
        },
        mounted() {
            EventBus.$on('CloseDeleteComponent', (value,response) => {
                console.log(value,response)
                this.cancelDeleteItem()
                let ticket_data = response.ticket_details
                setTimeout(() => {
                    Swal({
                        title: 'Print Tickets',
                        text: 'Do You Want to Print Tickets ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#00448b',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Continue Without Printing',
                        allowOutsideClick: false
                    }).then(result => {
                        if (result.value) {
                            this.show_order_deleted = true
                            ticket_data.forEach(z => {
                                this.store_ticket_data = z
                                this.printTicket(z)
                            })
                            this.$modal.hide(this.modal_name)
                            this.$emit("hideDeleteItemModal")
                        } else {
                        }
                    })
                }, 500);
            })
            this.temp_product_quantity = this.delete_quantity
        },
        created() {
            this.temp_product_quantity = this.delete_quantity
            const dict = {
                custom: {
                    quantity: {
                        required: () => 'Quantity is Required',
                        numeric: () => 'Quantity should be Numeric',
                        min_value: () => 'Quantity should be greater than 0',
                        max_value: () =>
                            'Quantity should be less than ' + this.product_quantity
                    }
                }
            }
            this.$validator.localize('en', dict)
        }
    }
</script>
<style>
    .product-avatar.content-center {
        margin: 0 auto !important;
    }

    .delete-modal-item-component.v--modal-overlay .v--modal-box {
        /* top: 78px !important;
        left: 0px !important;
        max-width: 630px !important;
        width: 100% !important; */
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.43) !important;
        /* border-radius: 15px !important;
        max-height: 580px !important;
        margin: 0 auto !important; */
    }

    /* .delete-modal-item-component.v--modal-overlay .v--modal-box .v-modal-layout {
        max-height: inherit !important;
    } */

    /* .delete-modal-item-component .v-modal-body {
        overflow: auto !important;
        height: 500px !important;
    } */

    .delete-modal-item-component .v-modal-content {
        background-color: #f2f5f8;
    }

    .deleteItemModal {
        height: 36px !important;
        border-radius: 7px !important;
        border: solid 0.5px #cdd2da !important;
        text-align: center !important;
        width: 140px;
        height: 30px !important;
    }

    .deleteItemText {
        font-size: 22px;
        font-weight: bold;
        color: #303031;
    }

    .deleteNextItem {
        font-size: 13px;
        font-weight: 600;
        color: #707070;
    }

    .form-check-label input[type='radio']:checked~.radio-icon:before,
    .form-check-label input[type='radio']:checked~.custom-control-indicator:before,
    .custom-checkbox input[type='radio']:checked~.radio-icon:before,
    .custom-checkbox input[type='radio']:checked~.custom-control-indicator:before,
    .custom-radio input[type='radio']:checked~.radio-icon:before,
    .custom-radio input[type='radio']:checked~.custom-control-indicator:before {
        border-color: #0d84f2 !important;
    }

    .form-check-label input[type='radio']:checked~.radio-icon:before,
    .form-check-label input[type='radio']:checked~.custom-control-indicator:before,
    .custom-checkbox input[type='radio']:checked~.radio-icon:before,
    .custom-checkbox input[type='radio']:checked~.custom-control-indicator:before,
    .custom-radio input[type='radio']:checked~.radio-icon:before,
    .custom-radio input[type='radio']:checked~.custom-control-indicator:before {
        border-color: #0d84f2 !important;
    }

    .form-check-label input[type='radio']~.radio-icon+span,
    .form-check-label input[type='radio']~.custom-control-indicator+span,
    .custom-checkbox input[type='radio']~.radio-icon+span,
    .custom-checkbox input[type='radio']~.custom-control-indicator+span,
    .custom-radio input[type='radio']~.radio-icon+span,
    .custom-radio input[type='radio']~.custom-control-indicator+span {
        padding-left: 0px !important;
    }

    .reasonVoid {
        background-image: linear-gradient(to bottom, #ff3a3a, #c51a1a);
        padding: 8px 24px;
        border-radius: 18px;
        color: #fff;
        font-weight: bold;
    }

    .reasonCancel {
        background-image: linear-gradient(to bottom, #f5a623, #ffa20b);
        padding: 8px 24px;
        border-radius: 18px;
        color: #303031;
        font-weight: bold;
    }

    .customReasons {
        color: #0d84f2;
        border-radius: 6px;
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        cursor: pointer;
        padding: 13px 10px;
        text-align: center;
        margin: 10px;
        font-weight: bold;
        text-transform: capitalize;
        word-break: break-word;
        height: 105px;
    }

    .isSelectedReason {
        box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
        background-image: linear-gradient(to bottom, #1ebe5a, #1a9347);
        border-radius: 6px;
        cursor: pointer;
        padding: 13px 10px;
        text-align: center;
        margin: 10px;
        color: #fff !important;
        font-weight: bold;
        text-transform: capitalize;
        word-break: break-word;
    }

    .displayNone {
        display: none !important;
    }
</style>