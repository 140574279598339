<template>
    <div class="top-navbar mat-fuse-dark-700-bg horizontal-style-1">
        <div class="fuse-navigation">
            <div class="nav horizontal">
                <div class="nav-collapsable nav-item pos-menubar-mob">
                    <nav class="navbar navbar-expand-lg navbar-light" id="nav-main">
                        <div class="navbar-collapse" v-if="loggedInUser.role != 'customer'"
                            :class="{ active: isActive }">
                            <ul role="menubar" class="dc-menu-demo dc-menu--horizontal dc-menu menuScroll flexScroll">
                                <li role="menuitem" ref="dropdownMenu" tabindex="0" class="dc-menu-item"
                                    style="border-bottom-color: transparent;"
                                    v-if="loggedInUser.actions.home && loggedInUser.outlet">
                                    <router-link to="/home" class="nav-link menulink">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/home.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Home</span>
                                    </router-link>
                                </li>
                                <li v-if="loggedInUser.role == 'default_order_taker'" role="menuitem" tabindex="0"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/approvalstatus" class="nav-link menulink">
                                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/place-order-blue.png"
                                            style="height:15px;">
                                        <span class="ml-1">Approval Status</span>
                                    </router-link>
                                </li>
                                <li v-else-if="loggedInUser.role == 'default_manager'" role="menuitem" tabindex="0"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <a class="nav-link menulink dropdown-toggle togglePlace" id="navbarOrderLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        :class="{'router-link-active': subIsActive('/approverequests') || subIsActive('/pendingapproval') || subIsActive('/pendingorder')}">
                                        <img src="/static/images/newspaper_blue.svg" style="height:15px;">
                                        <span class="ml-1">Approvals</span>
                                    </a>
                                    <div class="dropdown-menu menu-dropdown" aria-labelledby="navbarOrderLink">
                                        <router-link to="/approverequests" class="dropdown-item nav-link">
                                            <span>Approval History</span>
                                        </router-link>
                                        <router-link to="/pendingapproval" class="dropdown-item nav-link">
                                            <span>Waiter Requests</span>
                                        </router-link>
                                        <router-link to="/pendingorder" class="dropdown-item nav-link">
                                            <span>Customer Orders</span>
                                        </router-link>
                                    </div>
                                </li>
                                <li role="menuitem" tabindex="0" v-if="loggedInUser.actions.customers"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/customermanage" class="nav-link menulink custmer"
                                        :class="{ usersli: activateCustomersRouter == true }">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Customers</span>
                                    </router-link>
                                </li>
                                <li role="menuitem" tabindex="0" v-if="loggedInUser.actions.employees"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/employeemanage" class="nav-link menulink">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/employees.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Employees</span>
                                    </router-link>
                                </li>
                                <li v-if="loggedInUser.role != 'default_order_taker'" role="menuitem" tabindex="0"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/loyaltyManage" class="nav-link menulink"
                                        :class="{ 'router-link-active': String($route.path).startsWith('/loyaltyManage')}">
                                        <i class="logo-scm text-secondary" style="margin-right:5px !important"></i>
                                        <span class="ml-1">Loyalty</span>
                                    </router-link>
                                </li>
                                <li v-if="loggedInUser.outlet" role="menuitem" tabindex="0"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/manageprinter" class="nav-link menulink">
                                        <i class="icon icon-printer text-secondary"></i>
                                        <span class="ml-0">Manage Printer</span>
                                    </router-link>
                                </li>
                                <!-- <li role="menuitem" tabindex="0" class="dc-menu-item"
                                    style="border-bottom-color: transparent;">
                                    <router-link to="/pendingorder" class="nav-link menulink">
                                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/place-order-blue.png"
                                            style="height:15px;">
                                        <span class="ml-1">Pending Order</span>
                                    </router-link>
                                </li> -->
                                <li class="nav-item dropdown dc-menu-item" v-if="loggedInUser.outlet"
                                    style="border-bottom-color: transparent;">
                                    <a class="nav-link menulink dropdown-toggle togglePlace" id="navbarOrderLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        :class="{'router-link-active': subIsActive('/closedorder') || subIsActive('/manageorder') || subIsActive('/takeordernew')|| subIsActive('/tableselection')|| subIsActive('/vieworder')}">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/orders.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Orders</span>
                                    </a>
                                    <div class="dropdown-menu menu-dropdown" aria-labelledby="navbarOrderLink">
                                        <router-link v-if="loggedInUser.actions.orders.manage_order" to="/manageorder"
                                            class="dropdown-item nav-link">
                                            <span>Manage Order</span>
                                        </router-link>
                                        <router-link v-if="loggedInUser.actions.orders.order_history" to="/closedorders"
                                            class="dropdown-item nav-link">
                                            <span>Order History</span>
                                        </router-link>
                                        <a v-if="loggedInUser.actions.orders.take_order && loggedInUser.service_type == 'Take-Away'"
                                            @click="createTakeAwayOrder" class="dropdown-item nav-link">
                                            <span>Take Order</span>
                                        </a>
                                        <router-link v-else-if="loggedInUser.actions.orders.take_order"
                                            to="/tableselection" class="dropdown-item nav-link">
                                            <span>Take Order</span>
                                        </router-link>
                                    </div>
                                </li>
                                <li v-if="loggedInUser.role != 'default_order_taker'" role="menuitem" tabindex="0"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/inventorymanage" class="nav-link menulink"
                                        :class="{ 'router-link-active': String($route.path).startsWith('/inventorymanage')}">
                                        <img src="/static/images/products-blue.svg" alt="products" width="15" />
                                        <span class="ml-1">Products</span>
                                    </router-link>
                                </li>
                                <li role="menuitem" tabindex="0" v-if="loggedInUser.actions.bills && loggedInUser.outlet_id" class="dc-menu-item"
                                    style="border-bottom-color: transparent;">
                                    <router-link to="/billingmanage" class="nav-link menulink">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/billing.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Receipts</span>
                                    </router-link>
                                </li>
                                <!-- <li role="menuitem" tabindex="0" class="dc-menu-item" style="border-bottom-color: transparent;">
									<router-link to="/ordermanage" class="nav-link menulink">
										<img src="https://cdn.digicollect.com/cdn/posv2/orders.svg" style="height:15px;">
										<span class="ml-2">Orders</span>
									</router-link>
								</li> -->
                                <!-- <li role="menuitem" tabindex="0" class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/products" class="nav-link menulink"
                                            :class="{ 'router-link-active': String($route.path).startsWith('/products')}">
                                            <img src="/images/images-new-theme/products_active.svg" width="18" height="18" alt="">
                                            <span class="ml-1">Products</span>
                                    </router-link>
                                </li> -->
                                <li role="menuitem" tabindex="0" class="dc-menu-item"
                                    style="border-bottom-color: transparent;">
                                    <router-link to="/reportsDashboard" class="nav-link menulink"
                                        :class="{ reportsli: activateReportsRouter == true }">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/reporting.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Reports</span>
                                    </router-link>
                                </li>
                                <!-- <li v-if="loggedInUser.outlet" role="menuitem" tabindex="0"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/" class="nav-link menulink"
                                        :class="{ printerli: $route.path == '/'}">
                                        <i class="icon icon-printer text-secondary"></i>
                                        <span class="ml-0">Manage Printers</span>
                                    </router-link>
                                </li> -->
                                <li role="menuitem" tabindex="0" v-if="loggedInUser.actions.settings"
                                    class="dc-menu-item" style="border-bottom-color: transparent;">
                                    <router-link to="/outletmanage" class="nav-link menulink"
                                        :class="{ settingsli: String($route.path).startsWith('/outletdetailsnew')}">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/settings.svg"
                                            style="height:15px;">
                                        <span class="ml-1">Settings</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="navbar-collapse" v-else :class="{ active: isActive }">
                            <ul role="menubar" class="dc-menu-demo dc-menu--horizontal dc-menu menuScroll flexScroll">
                                <li role="menuitem" ref="dropdownMenu" tabindex="0"
                                    class="dc-menu-item cursor-not-allowed" style="border-bottom-color: transparent"
                                    :class="{'tab-active': String($route.path).startsWith('/place_order')}">
                                    <a href="javascript:;" @click="placeOrder()"
                                        class="nav-link menulink cursor-not-allowed"
                                        :class="{'router-link-active':String($route.path).startsWith('/place_order')}">
                                        <img v-if="String($route.path).startsWith('/place_order')"
                                            src="https://cdn.digicollect.com/cdn/posv2/images/place_order.svg"
                                            style="height:15px;">
                                        <img v-else
                                            src="https://cdn.digicollect.com/cdn/posv2/images/place_order_selected.svg"
                                            style="height:15px;">
                                        <span class="ml-1"
                                            :class="{'text-black':!String($route.path).startsWith('/place_order')}">Place
                                            Order</span>
                                    </a>
                                </li>
                                <li role="menuitem" tabindex="0" class="dc-menu-item cursor-not-allowed"
                                    style="border-bottom-color: transparent"
                                    :class="{'tab-active': String($route.path).startsWith('/view_customer_order')}">
                                    <a href="javascript:;" @click="myOrder()" class="nav-link menulink custmer"
                                        :class="{'router-link-active':String($route.path).startsWith('/view_customer_order')}">
                                        <img v-if="String($route.path).startsWith('/view_customer_order')"
                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/orders.svg"
                                            style="height:15px;">
                                        <img v-else
                                            src="https://cdn.digicollect.com/cdn/posv2/images/my_order_selected.svg"
                                            style="height:15px;">
                                        <span class="ml-1"
                                            :class="{'text-black':!String($route.path).startsWith('/view_customer_order')}">My
                                            Order</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Navbar from "./Navbar";
    import _default, {
        mapGetters
    } from "vuex";
    import {
        EventBus
    } from "./eventBus/nav-bar-event.js";

    export default {
        name: "menubar",
        data() {
            return {
                isActive: false,
                activateCustomersRouter: false,
                activateAnalyticsRouter: false,
                activatePoliciesRouter: false,
                activateSettingsRouter: false,
                activateReportsRouter: false,
                window: {
                    width: 0
                }
            };
        },
        components: {
            Navbar
        },
        methods: {
            documentClick(e) {
                let el = this.$refs.dropdownMenu
                let target = e.target
            },
            subIsActive(input) {
                const paths = Array.isArray(input) ? input : [input];
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0; // current path starts with this path string
                });
            },
            subsubIsActive(input) {
                const paths = Array.isArray(input) ? input : [input];
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0; // current path starts with this path string
                });
            },
            activatecustomerdetailsClass(changecolor) {
                this.activateCustomersRouter = changecolor;
            },
            activatesettingsdetailsClass(changecolor) {
                this.activateSettingsRouter = changecolor;
            },
            activatereportsdetailsClass(changecolor) {
                this.activateReportsRouter = changecolor;
            },
            activateanalyticsClass(changecolor) {
                this.activateAnalyticsRouter = changecolor;
            },
            activatepoliciesClass(changecolor) {
                this.activatePoliciesRouter = changecolor;
            },
            createTakeAwayOrder() {
                // this.$http.post("/orders/type",{
                //   orderType:'Take Away',
                //   orderId:'',
                //   update:false,
                //   is_delivered:''
                // }).then(res => {
                //   if(res.data.status_id == 1){
                //     this.$router.push({
                //         name: "TakeOrderNew",
                //         params: {
                //           orderId:res.data.order_id,
                //           newOrder: true,
                //           orderType: "Take Away",
                //           inProgressOrder:false
                //         }
                //       });
                //   }
                // })
                this.$router.push({
                    name: "TakeOrderNew",
                    params: {
                        orderId: '',
                        newOrder: true,
                        orderType: "Take Away",
                        inProgressOrder: false
                    }
                });
            },
            OpenSCMDashboard() {
                this.$router.push({
                    name: "DashboardSupplyChain"
                });
            },
            placeOrder() {
                // if(String(this.$route.path).startsWith('/view_customer_order')){
                //     EventBus.$emit("update_order")
                // }
            },
            myOrder() {
                if (String(this.$route.path).startsWith('/place_order')) {
                    // this.$toasted.global.error("Placing an Order is in Progress")
                }
            }
            // ,
            // select: function() {
            //   this.isActive = !this.isActive;
            // }
        },

        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        },
        mounted: function () {
            EventBus.$on("topBar", toggle => {
                this.isActive = !this.isActive;
            });
            EventBus.$on("customerdetailsLoad", changecolor => {
                this.activatecustomerdetailsClass(changecolor);
            });
            EventBus.$on("settingsLoad", changecolor => {
                this.activatesettingsdetailsClass(changecolor);
            });
            EventBus.$on("reportsLoad", changecolor => {
                this.activatereportsdetailsClass(changecolor);
            });
            EventBus.$on("analyticsload", changecolor => {
                this.activateanalyticsClass(changecolor);
            });
            EventBus.$on("policiesload", changecolor => {
                this.activatepoliciesClass(changecolor);
            });
            setTimeout(() => {
                $(document).ready(function () {
                    $('ul.menuScroll.flexScroll').flexMenu();
                });
            }, 1000);
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".flexMenu-viewMore").length) {
                    $(".flexMenu-popup").slideUp(500);
                }
            })
            // $('.dropdown-submenu a.test').on("click", function(e){
            //   $(this).next('ul.closeMenu').toggle();
            //   $(this).next('ul.closeMenuInventory').hide();
            //   e.stopPropagation();
            //   e.preventDefault();
            // });
            //  $(document).on("click", function(event){
            //   if(!$(event.target).closest(".dropdown-submenu a.test").length){
            //       $(".closeMenu").slideUp(500);
            //       $(".closeMenuInventory").slideUp(500);
            //   }
            // });
            $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
                if (!$(this).next().hasClass('show')) {
                    $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
                }
                var $subMenu = $(this).next(".dropdown-menu");
                $subMenu.toggleClass('show');


                $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
                    $('.dropdown-submenu .show').removeClass("show");
                });
                return false;
            });
            // $('.dropdown-submenu a.inventorymenu').on("click", function(e){
            //   $(this).next('ul.closeMenu').hide();
            //   $(this).next('ul.closeMenuInventory').toggle();
            //   e.stopPropagation();
            //   e.preventDefault();
            // });
            //  $(document).on("click", function(event){
            //   if(!$(event.target).closest(".dropdown-submenu a.inventorymenu").length){
            //       $(".closeMenuInventory").slideUp(500);
            //   }
            // })

        },
        created() {
            document.addEventListener('click', this.documentClick)
        },
        destroyed() {
            document.removeEventListener('click', this.documentClick)
            EventBus.$off('menubarHandle')
        }
    };
</script>
<style>
    .test.dropdown-toggle::after {
        transform: rotate(270deg) !important;
    }

    .inventorymenu.dropdown-toggle::after {
        transform: rotate(270deg) !important;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-menu.dropdown-width li a {
        height: 38px !important;
        color: rgb(48, 48, 49) !important;
        line-height: 38px !important;
        background: transparent;
        font-weight: 500;
    }

    .dropdown-menu.dropdown-width li a:hover {
        color: #00448b !important;
    }

    .dropdown-menu.dropdown-width {
        min-width: 13rem !important;
        margin: 0.25rem 0rem 0 !important;
    }

    .scm-submenu {
        display: none;
    }

    .scm-menu:hover .scm-submenu {
        display: block !important;
    }

    .dc-menu--horizontal>.dc-menu-item {
        border-bottom: 2px solid transparent;
    }

    .dropdown-menu.closeMenu>li a.dropdown-sub-item.nav-link {
        color: rgb(48, 48, 49) !important;
        font-weight: 500 !important;
    }

    .dropdown-menu.closeMenuInventory>li a.dropdown-sub-item.nav-link {
        color: rgb(48, 48, 49) !important;
        font-weight: 500 !important;
    }

    .dropdown-menu.dropdown-width .dropdown-toggle.togglePlace::after {
        position: relative;
        top: 13px;
        float: right;
    }

    /* .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 81%;
  margin-top: -1px;
} */
    .dropdown-submenu .dropdown-menu .togglePlace:hover {
        color: #00448b !important;
    }

    .dropdown-menu.closeMenu>li a.dropdown-sub-item.nav-link:hover {
        color: #00448b !important;
    }

    .dropdown-menu.closeMenuInventory>li a.dropdown-sub-item.nav-link:hover {
        color: #00448b !important;
    }

    .dropdown-menu .dropdown-sub-item.nav-link.router-link-exact-active.router-link-active {
        color: #00448b !important;
    }

    .dropdown-menu.dropdown-width li a.togglePlace.router-link-active {
        color: #00448b !important;
    }

    .dropdown-submenu .dropdown-menu {
        margin-top: -1px;
        margin-left: 0px;
        margin-right: 0px;
        top: 6px;
        left: 103%;
    }

    .horizontal-style-1 {
        display: block;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        height: 48px;
        max-height: 48px;
        min-height: 48px;
    }

    .mat-fuse-dark-700-bg {
        background-color: #fff !important;
        color: #303031 !important;
    }

    .menuZindex {
        z-index: 1 !important;
    }

    .fuse-navigation {
        display: flex;
        flex: 1 0 auto;
    }

    .nav.horizontal {
        display: flex;
        flex-direction: row;
    }

    .nav-link.submenu.analyticsli {
        color: #00448b !important;
    }

    .fuse-navigation>.nav {
        margin: 0;
        padding: 0;
        width: 100%;
        border-bottom: 1px solid #94bef0;
    }

    .nav.horizontal>.nav-item.nav-collapsable {
        position: relative;
    }

    .nav>.nav-item.nav-collapsable {
        background: 0 0;
        transition: background 0s ease-in-out;
    }

    .nav .nav-item.nav-collapsable {
        display: block;
    }

    .navbar {
        padding: 0px !important;
        background: transparent !important;
    }

    /* ul.el-menu > li.el-menu-item > .nav-link.menulink:first-child {
    margin: 0 15px 0px 0px;
    } */

    .nav ul>li>.nav-link.menulink i {
        font-size: 20px !important;
        margin-right: 10px !important;
        margin-left: 0px !important;
    }

    .top-navbar #toggle {
        position: absolute;
        right: 20px;
        top: 14px;
        z-index: 999;
        width: 40px;
        height: 40px;
        cursor: pointer;
        float: right;
        visibility: hidden;
        opacity: 0;
    }

    .top-navbar #toggle .span {
        border-radius: 10px;
        background: #0e5fc2;
        transition: all 0.3s ease-out;
        backface-visibility: hidden;
    }

    .top-navbar #top.span.active {
        transform: rotate(45deg) translateX(3px) translateY(5px);
    }

    .top-navbar #middle.span.active {
        opacity: 0;
    }

    .top-navbar #bottom.span.active {
        transform: rotate(-45deg) translateX(8px) translateY(-10px);
    }

    .top-navbar #toggle {
        position: absolute;
        right: 20px;
        top: 68px;
        z-index: 999;
        width: 40px;
        height: 40px;
        cursor: pointer;
        float: right;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        visibility: hidden;
        opacity: 0;
    }

    .top-navbar #toggle .span {
        border-radius: 10px;
        background: #00448b;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .nav-link.menulink.settingsli,
    .nav-link.menulink.printerli,
    .nav-link.menulink.reportsli {
        border-bottom: 2px solid #00448b;
    }

    @media only screen and (max-width: 899px) {
        .top-navbar #navbarNav .dc-menu-item {
            display: none;
        }
    }

    @media only screen and (max-width: 768px) {
        .top-navbar .logo-header {
            padding: 0;
        }

        .top-navbar #toggle {
            visibility: visible;
            opacity: 1;
            margin-top: 6px;
        }

        .top-navbar #toggle .span {
            height: 4px;
            margin: 5px 0;
            transition: all 0.3s ease-out;
            backface-visibility: visible;
            visibility: visible;
            opacity: 1;
        }

        /* .top-navbar #navbarNav .el-menu-item {
      display: none;
    } */

        .top-navbar #navbarNav.active {
            margin: 0px 0;
            visibility: visible;
            opacity: 1;
            z-index: 999 !important;
            background-color: #fff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: 0px solid rgba(0, 0, 0, 0.15);
            -webkit-border-radius: 2px;
            border-radius: 2px;
            -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            padding-bottom: 10px;
        }

        .top-navbar #navbarNav.active .el-menu--horizontal .el-menu-item {
            text-align: center;
            float: none;
            display: block;
            height: 100%;
            width: 100%;
            border-top: 1px solid #eaeaeb;
            font-size: 18px;
        }

        .top-navbar #navbarNav.active .dc-menu--horizontal .dc-menu-item {
            text-align: center;
            float: none;
            display: block;
            height: 100%;
            width: 100%;
            border-top: 1px solid #eaeaeb;
            font-size: 18px;
        }

        /* .top-navbar .nav-collapsable.nav-item {
    z-index: 4 !important;
  } */


    }

    /* @media only screen and (max-width: 899px) {
 .horizontal-style-1 {
    height: 0px;
    max-height: 0px;
    min-height: 0px;
  }
} */
    @media only screen and (max-width: 900px) {
        .navbar.navbar-expand-lg.navbar-absolute {
            position: absolute;
        }


        /* .dc-menu--horizontal > .dropdown.dc-menu-item {
    height: auto !important;
  } */

        .nav-item.dropdown.dc-menu-item.show>.dropdown-menu.show {
            position: absolute;
            top: 45px;
            width: 100%;
            box-shadow: none;
        }

        .top-navbar #navbarNav.active {
            width: 230px !important;
            height: 100vh;
        }

        .dc-menu-item .dropdown-item.nav-link {
            padding-left: 42px;
        }

        .dc-menu-item.show .dropdown-menu.show {
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            transition-property: color, background-color, opacity;
        }
    }

    /* dc-css */

    .dc-menu {
        background-color: transparent;
    }

    .dc-menu--horizontal {
        margin-left: 15px;
    }

    .dc-menu--horizontal>.dc-submenu {
        float: left;
        height: 47px;
    }

    .dc-menu-item {
        padding: 0 0px !important;
    }

    .dc-menu--horizontal>.dc-menu-item {
        height: 47px !important;
        line-height: 47px !important;
        margin: 0 0px;
    }

    .dropdown-menu .dropdown-item.nav-link span {
        color: #303031 !important;
        font-size: 14px;
    }

    .dropdown-menu .dropdown-item.nav-link span.text-secondary {
        color: #00448b !important;
    }

    .dropdown-menu .dropdown-item.nav-link.router-link-exact-active.router-link-active span {
        color: #00448b !important;
    }

    /* .dc-menu-item .nav-link.menulink.router-link-exact-active.router-link-active {
        color: #303031 !important;
        background: #409eff08 !important;
    } */

    .dc-menu-item .nav-link.menulink.router-link-exact-active.router-link-active:before {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        margin: 0 0px;
        position: absolute;
        right: 0;
        background-color: #00448b;
    }

    .dc-menu-item .nav-link.menulink.usersli,
    .dc-menu-item .nav-link.menulink.meterLoad {
        color: #303031 !important;
        background: #409eff08 !important;
    }

    .dc-menu-item .nav-link.menulink.usersli:before,
    .dc-menu-item .nav-link.menulink.meterLoad:before {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        margin: 0 0px;
        position: absolute;
        right: 0;
        background-color: #00448b;
    }

    /* .dc-menu--horizontal>.dc-menu-item:first-child {
        margin: 0 24px;
    } */
    /* .nav-link > span {
    padding-left: 8px !important;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    color: #00448b;
    } */

    .dc-menu--horizontal>.dc-submenu .dc-submenu__icon-arrow {
        margin-left: 11px !important;
        margin-top: 12px !important;
        color: #00448b;
        font-weight: 600;
    }

    .dc-menu--popup-bottom-start {
        margin-top: 1px !important;
    }

    .dc-menu--popup-bottom-start .nav-link {
        height: 36px !important;
    }

    .dc-menu-item.is-active .router-link-exact-active span {
        color: #00448b !important;
        font-weight: 500;
    }

    .dc-menu--horizontal .dc-menu-item a,
    .dc-menu--horizontal .dc-menu-item a:hover {
        color: #00448b;
    }

    .dc-menu--horizontal .dc-menu-item:hover,
    .dc-menu--horizontal .dc-submenu__title:hover {
        background-color: transparent !important;
    }

    .dc-menu--popup-bottom-start span {
        color: #303031 !important;
        padding-left: 5px !important;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }

    .dc-submenu.is-opened>.dc-submenu__title .dc-submenu__icon-arrow {
        transform: inherit !important;
    }

    .dc-menu--horizontal>.dc-menu-item.is-active {
        border-bottom: 2px solid #00448b !important;
        color: #303031 !important;
        background: #409eff08 !important;
    }

    .dc-menu--horizontal>.dc-submenu .dc-submenu__title {
        height: 47px !important;
        line-height: 47px !important;
        color: #00448b !important;
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: 0.7px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
    }

    .dc-submenu__title {
        color: #303031 !important;
        padding: 0 6px !important;
    }

    .dc-submenu__title img {
        padding-right: 10px !important;
    }

    .dc-menu--horizontal {
        border-bottom: solid 0px #e6e6e6 !important;
    }

    .dc-tabs__item.is-active {
        color: #00448b !important;
    }

    .dc-collapse-item__header {
        height: 45px !important;
        line-height: 45px !important;
        font-weight: 600 !important;
        text-transform: uppercase;
        color: #ffffff !important;
        background-color: #00448b !important;
        border-bottom: 0px solid #ebeef5;
        font-size: 14px !important;
        padding-left: 12px !important;
        border-radius: 18px 18px 18px 18px !important;
    }

    .dc-collapse-item__arrow.is-active {
        padding-left: 14px;
    }

    .dc-collapse-item__header.is-active {
        border-radius: 18px 18px 0px 0px !important;
    }

    .dc-collapse-item__arrow {
        line-height: 41px !important;
        color: #fff !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        border-bottom: 0px solid #ebeef5 !important;
        border-radius: 18px 18px 0px 0px !important;
    }

    .dc-collapse-item__content {
        border: 1px solid #eee !important;
        padding-bottom: 0px !important;
        border-radius: 0px 0px 18px 18px !important;
    }

    .dc-collapse {
        border-top: 0px solid #ebeef5 !important;
        border-bottom: 0px solid #ebeef5 !important;
    }

    .dc-menu--collapse .dc-menu .dc-submenu,
    .dc-menu--popup {
        min-width: 150px;
    }

    .card-header a:hover {
        text-decoration: none !important;
    }

    .dc-collapse-item__wrap {
        border-bottom: 0px solid #ebeef5 !important;
    }

    .dc-submenu__title i {
        margin-right: 10px !important;
        margin-left: 0px !important;
    }

    .dc-menu {
        border-radius: 2px;
        list-style: none;
        position: relative;
        margin: 0;
        padding-left: 0;
        background-color: transparent;
    }

    .dc-menu--horizontal {
        border-bottom: 0 solid #e6e6e6 !important;
        padding-left: 4px !important;
    }

    .dc-menu:after,
    .dc-menu:before {
        display: table;
        content: "";
    }

    .dc-menu-item:first-child {
        margin-left: 0;
    }

    .dc-menu--horizontal .dc-menu-item {
        float: left;
        height: 60px;
        line-height: 60px;
        margin: 0;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        border-bottom: 5px solid transparent;
    }

    /* .dc-menu--horizontal .dc-menu-item {
        border-bottom: 0 solid transparent !important;
    } */

    .dc-menu--horizontal>.dc-menu-item {
        height: 47px !important;
        line-height: 47px !important;
        margin: 0;
    }

    .dc-menu--horizontal .dc-menu-item {
        border-bottom: 0px solid transparent;
    }

    .dc-menu--horizontal>.dc-menu-item {
        height: 47px !important;
        line-height: 47px !important;
        margin: 0 0px;
    }

    .dc-menu li {
        list-style: none;
    }

    .dc-menu-item,
    .dc-submenu__title {
        height: 56px;
        line-height: 56px;
        font-size: 14px;
        color: #48576a;
        padding: 0 20px;
        cursor: pointer;
        position: relative;
        transition: border-color 0.3s, background-color 0.3s, color 0.3s;
        box-sizing: border-box;
        white-space: nowrap;
    }

    .dc-menu-item {
        padding: 0 !important;
    }

    .dropdown-menu.show {
        display: block;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        transition-property: color, background-color, opacity !important;
    }

    .nav ul>li>.nav-link.menulink {
        height: 47px !important;
        letter-spacing: 0.25px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .dc-menu--horizontal .dc-menu-item a,
    .dc-menu--horizontal .dc-menu-item a:hover {
        color: #00448b !important;
    }

    .dc-menu-item * {
        vertical-align: middle;
    }

    .nav ul>li>.nav-link.menulink i {
        font-size: 18px !important;
        margin-right: 10px !important;
        margin-left: 0 !important;
    }

    .menuScroll a {
        height: inherit !important;
    }

    .dc-menu--horizontal .dc-menu-item {
        float: left;
        height: 48px;
        line-height: 50px;
        margin: 0;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        border-bottom: 5px solid transparent;
    }

    .dc-menu--horizontal>.dc-menu-item {
        height: 47px !important;
        line-height: 47px !important;
        margin: 0;
    }

    .dc-menu li {
        list-style: none;
    }

    .dc-menu-item {
        padding: 0 !important;
    }

    .nav ul>li>.nav-link {
        padding-left: inherit;
    }

    .nav ul>li>.nav-link.dropdown-toggle.router-link-active {
        /* border-bottom: 2px solid #00448b !important;
  color: #00448b !important; */
        border-bottom: 2px solid #00448b !important;
        color: #00448b !important;
        background: #409eff08 !important;
    }

    .nav ul>li>.nav-link.dropdown-toggle {
        height: 47px !important;
        letter-spacing: 0.25px;
        padding-left: 14px;
        padding-right: 14px;
    }

    a.dropdown-item.nav-link {
        padding: 0px 24px 0px 10px;
    }

    .dc-menu-item .dropdown-toggle::after {
        margin-top: 4px !important;
        margin-left: 10px !important;
        vertical-align: middle !important;
    }

    .nav-link>span {
        padding-left: 5px !important;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        text-align: left;
        /* color: #00448b; */
        color: #00448b;
    }

    .dropdown-menu.menu-dropdown.show {
        min-width: 15rem;
        padding: 0.5rem 0;
        margin: 0.25rem 0.4rem 0;
    }

    a.dropdown-item.nav-link.menu-sub-link {
        height: 3.8rem;
        line-height: 3.8rem;
    }

    a.dropdown-item.nav-link.menu-sub-link span {
        font-size: 14px;
    }

    .dc-menu--horizontal .dc-menu-item a,
    .dc-menu--horizontal .dc-menu-item a.dropdown-item.nav-link.menu-sub-link span:hover {
        color: #00448b !important;
    }

    .dc-menu--horizontal>.dc-menu-item {
        /* border-bottom: 2px solid transparent !important; */
    }

    .dc-menu--horizontal>.dc-menu-item:hover {
        border-bottom: 2px solid #00448b;
    }

    .external-icon {
        width: 16px;
        height: 17px;
        margin-right: 12px;
    }

    .trans-icon,
    .alarm-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .tab-active {
        border-bottom: 2px solid #00448b !important;
    }

    .cursor-not-allowed {
        cursor: default !important;
    }
</style>