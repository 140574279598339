<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="edit-product final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">EDIT {{edit_product_details.name}}</span>
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pt-3" style="padding-right: 18px;max-height: 463px !important; overflow-x: hidden !important;overflow-y: scroll !important">
                        <div class="row first-middle-input flex-nowrap">
                            <div class="col-lg-3">
                                <div class="profile-left text-center">
                                    <div class="profile-image">
                                        <my-upload
                                        field="img"
                                        @crop-success="cropSuccess"
                                        @crop-upload-success="cropUploadSuccess"
                                        @crop-upload-fail="cropUploadFail"
                                        v-model="show"
                                        :width="100"
                                        langType="en"
                                        :height="100"
                                        :url="$serverURL + '/products/upload_file'"
                                        :params="params"
                                        :headers="headers"
                                        img-format="png"
                                        ></my-upload>
                                        <img
                                        v-if="edit_product_details.image"
                                        :src="edit_product_details.image"
                                        style="width: 90px;"
                                        />
                                        <img v-else :src="imgDataUrl" style="width: 90px;" />
                                    </div>
                                    <div class="mb-4 mt-4 profile-button">
                                        <a class="btn btn-secondary btn-smm" @click="toggleShow" v-html="picture_message"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 px-0">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6>
                                        <div class="d-flex pt-3" id="product_status_edit_proc">
                                            <span style="font-weight: 600;"> Product Status:<sup>★</sup></span>
                                            <el-switch v-model="edit_product_details.status" active-text="Yes" inactive-text="No" class="pl-8" ></el-switch>
                                        </div>
                                        <div class="d-flex pt-4" id="variants_avail_edit_proc">
                                            <span style="font-weight: 600;"> Variants Available:<sup>★</sup></span>
                                            <el-switch v-model="edit_product_details.variant" active-text="Yes" inactive-text="No" class="pl-4" @change="removeVariant" ></el-switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="text-center">
                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qr-code.svg" class="qr-logo" />
                                    <qrcode-vue :value="value" :size="size" background="#cddbeb" level="H" foreground="#00448b" style="margin-top: 18px;"></qrcode-vue>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group contactGroup">
                                    <input :maxlength="pName" name="product_name" v-validate="'required'" class="inputContact" type="text" v-model="edit_product_details.name" id="product_name_edit_proc" required/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Product Name<sup>★</sup></label>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_name')" style="display: inline !important">{{errors.first('product_name')}}</span>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{pName - edit_product_details.name.length}} / {{pName}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1" :class="{'mb-5':!edit_product_details.variant}">
                            <div style="width: 100%;">
                                <el-radio v-model="edit_product_details.product_is" label="MENU" id="menu_item_edit_proc">Menu Item</el-radio>
                                <el-radio v-model="edit_product_details.product_is" label="INGREDIENT" id="ingr_edit_proc">Ingredients</el-radio>
                                <el-radio v-model="edit_product_details.product_is" label="BOTH" id="both_edit_proc">Both</el-radio>
                            </div>
                        </div>
                        <div class="row" v-if="!edit_product_details.variant">
                            <div style="width: 100%;margin-left: 17px;">
                                <span class="pr-3 text-secondary">Type<sup>★</sup>:</span>
                                <el-radio-group v-model="edit_product_details.type" v-for="type in getallTypes" :key="type._id" v-validate="'required'" name="productType">
                                    <el-radio :label="type.name" :value="type" :id="type.name">{{type.name}}</el-radio>
                                </el-radio-group>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('productType')">Please Select Type</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group contactGroup" style="margin-top: -5px;">
                                    <textarea class="inputContact mt-2" style="padding: 5px 10px 2px 0px!important;margin-top: 16px!important;" id="desc_edit_proc" type="text" v-model="edit_product_details.description" :maxlength="pDesc" required></textarea>
                                    <span class="highlightContact"></span>
                                    <label class="labelContact">Description</label>
                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{pDesc - edit_product_details.description.length}} / {{pDesc}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="!edit_product_details.variant">
                            <div class="row pb-3">
                                <div class="col-sm-6">
                                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="margin-top:-24px;width:100%;">
                                            <input
                                            class="inputContact"
                                            type="number"
                                            step="any"
                                            name="measure"
                                            v-validate="'required|max:15'"
                                            v-on:keypress="isNumber($event)"
                                            min="0"
                                            required
                                            v-model.number="edit_product_details.measure"
                                            id="size_edit_proc"
                                            />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">
                                            Size or Measure
                                            <sup>★</sup>
                                            </label>
                                            <span
                                            class="invalid-feedback-form text-danger fs-13"
                                            v-show="errors.has('measure')"
                                            style="display: inline !important"
                                            >{{errors.first('measure')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group" style="margin-top:-30px !important;">
                                    <label class="fs-14" style="color:#00448b;">Unit of Measurement</label>
                                    <v-select
                                        label="name"
                                        index="_id"
                                        class="select-width clearNone optionsHeight vSelectWidth"
                                        placeholder="Search & Select Select Unit Of Measurement"
                                        :filterable="true"
                                        :options="measurements"
                                        v-model="edit_product_details.uom"
                                        @search="getAllMeasurements"
                                        @search:blur="getAllMeasurements"
                                        required
                                        v-validate="'required'"
                                        name="productUom"
                                        id="uom_edit_proc"
                                    ></v-select>
                                    <span
                                        class="invalid-feedback-form text-danger fs-13"
                                        v-show="errors.has('productUom')"
                                    >{{errors.first('productUom')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="margin-top:-24px;width:100%;">
                                            <input class="inputContact" type="number" step="any" name="product_price"
                                            id="sprice_edit_proc" v-validate="'required|max:8'" v-on:keypress="isNumber($event)" min="0" required v-model.number="edit_product_details.price"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Price<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_price')" style="display: inline !important">{{errors.first('product_price')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="margin-top: -24px;width:100%;">
                                            <input class="inputContact" type="number" step="any" 
                                            id="qty_edit_proc" v-on:keypress="isNumber($event)" min="0" required v-model.number="edit_product_details.quantity" v-validate="'required|max:8'" name="product_quantity"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Quantity<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13"  v-show="errors.has('product_quantity')" style="display: inline !important">{{errors.first('product_quantity')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group contactGroup" style="margin-top: 0px;">
                                        <input :maxlength="pId" name="product_id" 
                                            id="product_id_edit_proc" v-validate="'required'" required class="inputContact" type="text" v-model.trim="edit_product_details.product_id"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Product ID<sup>★</sup></label>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_id')" style="display: inline !important">{{errors.first('product_id')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{pId - edit_product_details.product_id.length}} / {{pId}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group contactGroup" style="margin-top: 0px;">
                                        <input :maxlength="sku" class="inputContact" type="text" 
                                            id="sku_edit_proc" name="product_sku" v-validate="'required|alpha_num'" required v-model.trim="edit_product_details.stock_keeping_unit"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Stock Keeping Unit (SKU)<sup>★</sup></label>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('product_sku')" style="display: inline !important">{{errors.first('product_sku')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{sku - edit_product_details.stock_keeping_unit.length}} / {{sku}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="fs-14" style="color:#00448b;">Category<sup>★</sup></label>
                                    <v-select
                                        class="filterName optionsHeight vSelectWidth"
                                        :class="{'clearNone':edit_product_details.category_id == ''}"
                                        label="name"
                                        index="_id"
                                        name="category"
                                        placeholder="Categories Search"
                                        :filterable="true"
                                        :options="filter_data.categories"
                                        v-model="edit_product_details.category_id"
                                        @search="get_categories_for_products_edit"
                                        @input="changeCategory"
                                        v-validate="'required'"
                                            id="category_edit_proc"
                                    >
                                        <span slot="no-options">
                                            No matches found. Please create new Category
                                            <a class="text-secondary p-0 m-0 w-0" style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;" @click="createcategory()">Here</a>
                                        </span>
                                    </v-select>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('category')">{{errors.first('category')}}</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="fs-14" style="color:#00448b;">Sub Category<sup>★</sup></label>
                                    <v-select
                                        class="filterName optionsHeight vSelectWidth"
                                        :class="{'clearNone':edit_product_details.sub_category_id == ''}"
                                        label="sub_category_name"
                                        index="sub_category_id"
                                        name="subcategory"
                                        placeholder="Sub Categories Search"
                                        :filterable="true"
                                        :clearable="true"
                                        :options="filter_data.subcategories"
                                        v-model="edit_product_details.sub_category_id"
                                        @search="getAllSubCategoriesforEdit"
                                        @input="changeSubCatgory"
                                        v-validate="'required'"
                                            id="sub_category_edit_proc"
                                    ></v-select>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('subcategory')">{{errors.first('subcategory')}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="fs-14" style="color:#00448b;">Product Type</label>
                                    <v-select
                                        class="filterName optionsHeight vSelectWidth"
                                        :class="{'clearNone':edit_product_details.product_type_id == ''}"
                                        label="name"
                                        index="_id"
                                        placeholder="Product Type Search"
                                        :filterable="true"
                                        :options="filter_data.ProductType"
                                        v-model="edit_product_details.product_type_id"
                                        @search="getAllProductTypesforEdit"
                                            id="product_type_edit_proc"
                                    ></v-select>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="fs-14" style="color: rgb(13, 132, 242);">Brand</label>
                                    <v-select
                                        class="filterName optionsHeight vSelectWidth"
                                        :class="{'clearNone':edit_product_details.brand_id == ''}"
                                        label="name"
                                        value="None"
                                        index="_id"
                                        placeholder="Brands Search"
                                        :filterable="true"
                                        :options="filter_data.brands"
                                        v-model="edit_product_details.brand"
                                        @search="get_brands_for_products"
                                        @search:blur="get_brands_for_products"
                                            id="brand_edit_proc"
                                    >
                                        <span slot="no-options">
                                            No matches found. Please create new Brand
                                            <a class="text-secondary p-0 m-0 w-0" style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;" @click="showbrandproduct()">Here</a>
                                        </span>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group contactGroup">
                                    <label class="fs-14" style="color:#00448b;">Tags</label>
                                    <vue-tags-input
                                        v-model="edit_product_details.tag"
                                        name="tags"
                                        id="tags"
                                        :tags="edit_product_details.tags"
                                        @tags-changed="newTags => tags = newTags"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row" v-if="tax_name.length && tax_percentage.length">
                            <div class="col-sm-6">
                                <div class="form-group contactGroup">
                                    <input class="inputContact" type="text" id="editAddTaxGroup" value="General Tax" required v-model="tax_name" name="tax_name"/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Tax Name</label>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('tax_name')">Tax Name is Required</span>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="form-group contactGroup">
                                    <input class="inputContact" type="number" min="0" step="any" id="editAddTaxGroup" value="10%" required v-model="tax_percentage" name="tax_percentage"/>
                                    <span class="highlightContact"></span>
                                    <span class="barContact"></span>
                                    <label class="labelContact">Tax Percentage</label>
                                    <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('tax_percentage')">Tax Percentage is Required</span>
                                </div>
                            </div>
                            <div class="col-sm-1 mt-8">
                                <span class="icon icon-close pointer" style="color:red;font-size:18px" @click="editRemoveTax(index)"></span>
                            </div>
                        </div> -->
                        <div v-if="edit_product_details.variant">
                            <h6 class="pt-3" style="font-weight: bold;color: #303031;width: 100%;">Variants</h6>
                        </div>
                        <div v-if="edit_product_details.variant">
                            <div v-for="(item,index) in edit_product_details.variants" :key="index" class="mt-4">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="form-group contactGroup">
                                        <input class="inputContact" :key="index" type="text" v-validate="'required'" 
                                            id="name_var_edit_proc" required :name="'variant_name' + index" v-model="item.variant_name" :maxlength="pVname"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Name<sup>★</sup></label>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('variant_name'+ index)" style="display:inline !important;">Required</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{pVname - item.variant_name.length}} / {{pVname}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group contactGroup">
                                        <input
                                        :name="'product_variant_id' + index"                                        
                                        v-validate="'required'"
                                        :maxlength="pVId"
                                        required
                                        class="inputContact"
                                        type="text"
                                        v-model.trim="item.product_id"
                                        id="product_id_var_edit_proc"
                                        />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">
                                        Product ID
                                        <sup>★</sup>
                                        </label>
                                        <span
                                        class="invalid-feedback-form text-danger fs-13"
                                        v-show="errors.has('product_variant_id'+ index)"
                                        style="display: inline !important"
                                        >Required</span>
                                        <p
                                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                        >{{pVId - item.product_id.length}} / {{pVId}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group contactGroup" style="margin-top: 0px;width:100%;">
                                        <input
                                        class="inputContact"
                                        type="text"
                                        :name="'variant_sku' + index.toString()"
                                        v-validate="'required|alpha_num'"
                                        required
                                        v-model.trim="item.stock_keeping_unit"
                                        id="sku_var_edit_proc"
                                        :maxlength="vsku"
                                        />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">
                                        Stock Keeping Unit (SKU)
                                        <sup>★</sup>
                                        </label>
                                        <span
                                            class="invalid-feedback-form text-danger fs-13"
                                            v-show="errors.has('variant_sku'+index.toString())"
                                            style="display: inline !important"
                                            >Required & Valid</span>
                                            <p
                                            class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            >{{vsku - item.stock_keeping_unit.length}} / {{vsku}}</p>
                                    </div>
                                </div>                                
                            </div>
                            <div class="row mt-4">
                                <div class="col-sm-4">
                                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="margin-top:-24px;width:100%;">
                                            <input
                                            class="inputContact"
                                            type="number"
                                            step="any"
                                            :name="'vmeasure'+index"
                                            v-validate="'required|max:15'"
                                            v-on:keypress="isNumber($event)"
                                            min="0"
                                            required
                                            v-model.number="item.measure"
                                            id="size_var_edit_proc"
                                            />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">
                                            Size or Measure
                                            <sup>★</sup>
                                            </label>
                                            <span v-if="item.measure.length < 1"
                                                class="invalid-feedback-form text-danger fs-13"
                                                v-show="errors.has('vmeasure'+index)"
                                                style="display: inline !important"
                                                >Required</span>
                                                <span v-else
                                                class="invalid-feedback-form text-danger fs-13"
                                                v-show="errors.has('vmeasure'+index)"
                                                style="display: inline !important"
                                                >Max 15 Digits</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group" style="margin-top:-30px !important;">
                                        <label class="fs-14" style="color:#00448b;">Unit of Measurement</label>
                                        <v-select
                                            label="name"
                                            index="_id"
                                            class="select-width clearNone optionsHeight vSelectWidth"
                                            placeholder="Search & Select"
                                            :filterable="true"
                                            :options="measurements"
                                            v-model="item.uom"
                                            @search="getAllMeasurements"
                                            @search:blur="getAllMeasurements"
                                            v-validate="'required'"
                                            required
                                            :name="'vuom'+index"
                                            data-vv-as="Unit of Measurement"
                                            id="suom_var_edit_proc"
                                        ></v-select>
                                        <span
                                            class="invalid-feedback-form text-danger fs-13"
                                            v-show="errors.has('vuom'+index)"
                                        >Required</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="margin-top:-23px;width:100%;">
                                            <input
                                            :key="index"
                                            class="inputContact"
                                            v-validate="'required|max:8'"
                                            v-on:keypress="isNumber($event)"
                                            :name="'variant_quantity' + index"
                                            id="addVariant"
                                            type="number"
                                            min="0"
                                            step="any"
                                            required
                                            v-model.number="item.variant_quantity"
                                            />
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">
                                            Quantity
                                            <sup>★</sup>
                                            </label>
                                            <span
                                            class="invalid-feedback-form text-danger fs-13"
                                            v-if="item.variant_quantity.length == 0"
                                            v-show="errors.has('variant_quantity' + index)"
                                            style="display: inline !important"
                                            >Required</span>
                                            <span
                                            v-else
                                            class="invalid-feedback-form text-danger fs-13"
                                            v-show="errors.has('variant_quantity' + index)"
                                            style="display: inline !important"
                                            >Max. 8 Digits</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            class="row justify-content-between">
                            <div class="col-sm-4 pt-4">
                                <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                                    <div class="form-group contactGroup" style="margin-top:-23px;width:100%;">
                                        <input
                                        :key="index"
                                        class="inputContact"
                                        v-validate="'required|max:8'"
                                        v-on:keypress="isNumber($event)"
                                        :name="'variant_price' + index"
                                        id="addVariantpr"
                                        type="number"
                                        min="0"
                                        step="any"
                                        required
                                        v-model.number="item.variant_price"
                                        />
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">
                                        Price
                                        <sup>★</sup>
                                        </label>
                                        <span
                                        class="invalid-feedback-form text-danger fs-13"
                                        v-if="item.variant_price.length == 0"
                                        v-show="errors.has('variant_price' + index)"
                                        style="display: inline !important"
                                        >Required</span>
                                        <span
                                        v-else
                                        class="invalid-feedback-form text-danger fs-13"
                                        v-show="errors.has('variant_price' + index)"
                                        style="display: inline !important"
                                        >Max. 8 Digits</span>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 pt-8" v-if="!index == 0">
                                    <el-tooltip class="item" effect="dark" content="Delete Variant" placement="top">
                                        <button class="btn btn-smm btn-outline-danger" @click="editRemoveVariants(index)">
                                            <i class="icon icon-delete mr-1" style="width: 18px;height: 18px;line-height: 18px;font-size: 16px;"></i>
                                            Delete
                                        </button>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="row">
                                <div style="width: 100%;margin-left: 17px;">
                                    <span class="pr-3 text-secondary">Type<sup>★</sup>:</span>
                                    <el-radio-group v-model="item.type" v-for="type in getallTypes" :key="type._id" v-validate="'required'" :name="'type'+index" >
                                        <el-radio :label="type.name" :id="type.name+index">{{type.name}}</el-radio>
                                    </el-radio-group>
                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('type'+index)">Please Select Type</span>
                                </div>
                            </div>
                        </div>
                        <div class v-if="edit_product_details.variant">
                            <a class="nav-link text-secondary" @click="editAddVariants()" style="height: 24px;padding: 0 12px 0px 0px;margin-top: 12px;line-height: 18px !important;">
                                <i class="icon icon-plus-circle" style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 6px;color: #00448b;"></i>
                                <span>Add Variant</span>
                            </a>
                        </div>
                        </div>
                    </div>
                    <div class="v-modal-footer text-center pt-5">
                        <a class="btn btn-link btn-smm btn-no-border-blue text-uppercase ml-1" @click="cancelEditProduct()">CANCEL</a>
                        <a class="btn btn-secondary btn-smm text-uppercase" @click="validate_edited_product(edit_product_details)">SAVE</a>
                    </div>
                </div>
            </div>
        </modal>
        <div>
            <create-brand @close="hideCreateBrand" modal_brand="brand-create"></create-brand>
        </div>
        <div>
            <create-category
                @createCategory="createCategory"
                :bool="categoryBool"
                :category="category"
                modal_category="category-create"
            ></create-category>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { SweetModal } from 'sweet-modal-vue'
import myUpload from 'vue-image-crop-upload'

import UploadPicture from './UploadPicture'
import CreateBrand from './CreateBrand'
import CreateCategory from './CreateCategory'

import BrandServices from './mixins/brands'
import CategoryServices from './mixins/categories'
import productServices from './mixins/products'
import { EventBus } from './eventBus/nav-bar-event'

export default {
    props: ['modal_name'],
    data() {
        return {
            show: false,
            imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg', // the datebase64 url of created image
            product_details: {},
            disabled: true,
            image: '',
            picture_message: 'Change Image',
            modal_msg: '',
            variants_list: [],
            gallerylist: [],
            name: '',
            validPhone: true,
            uploadedImage: '',
            pName: 100,
            pDesc: 1000,
            pId: 16,
            pVId:16,
            sku: 30,
            vsku:30,
            pBrand: 30,
            pCategory: 45,
            pTags: 30,
            pQuantity: 10,
            pPrice: 10,
            pVname: 50,
            singleBrand: null,
            tax_name: '',
            tax_percentage: '0',
            fromVariants: false,
            fromSubCategory: false,
            fromproductType: false,
            selectedSubCategory: {},
            selectedCategory: {},
            selectedProductType: {},
            selectedBrand:{},
            dontpushArray: false,
            getallTypes: [],
            selectedType: '',
            value: 'https://example.com',
            size: 100,
            measurements: [],
            edit_product_details: {
                name: '',
				description: '',
				quantity: 0,
				price: 0,
				measure:'',
				uom:'',
				currency: '',
				product_id: '',
				stock_keeping_unit: '',
				brand: '',
				product_is:'BOTH',
				category_id: '',
				sub_category_id: '',
				product_type_id: '',
				status: true,
				tags: [],
				tax: [],
				variants: [],
				type: '',
				total_tax: 0,
				variant: false,
				image: '',
                gallery: [],
                _id:''
			},
        }
    },
    components: {
        'my-upload': myUpload,
        UploadPicture,
        CreateBrand,
        CreateCategory,
        SweetModal,
        QrcodeVue
    },
    mixins: [productServices, BrandServices, CategoryServices],
    methods: {
        isNumber: function(evt,index) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        hideCreateBrand() {
            this.$modal.show(this.modal_name)
            this.$modal.hide('brand-create')
        },
        hideeditproduct() {
            this.$modal.hide('product-edit')
        },
        handlePictureCardPreview(file) {
            if (file.url) {
                this.image = file.url
                this.dialogVisible = true
            } else {
                this.image = file.response.url
                this.dialogVisible = true
            }
        },
        toggleShow() {
            this.show = !this.show
        },
        cropSuccess(imgDataUrl, field) {
            this.uploadedImage = imgDataUrl
            this.picture_message = 'Change Image'
        },
        cropUploadSuccess(jsonData, field) {
            this.edit_product_details.image = jsonData
            this.picture_message = 'Change Image'
        },
        cropUploadFail(status, field) {
            // TODO:
        },
        showUploadedImages(gallery) {
            return 'https://cdn.digicollect.com/cdn/dcpos/pics/47521fc0-f9d4-11e8-8890-f23c91b0d497/8525bb7f-ff8c-41d1-bf45-0cdd93cbbd3e.png'
        },
        showproductvariants(product_id) {
            EventBus.$emit('ProductVariant', product_id)
            this.$modal.show('product_info')
        },
        showbrandproduct() {
            this.$modal.show('brand-create')
            this.$modal.hide(this.modal_name)
        },
        createcategory() {
            this.$modal.show('create-category')
            EventBus.$emit('Show Category', false)
        },
        removeVariant() {
            if(this.edit_product_details.variants.length){
            this.edit_product_details.product_id = ''
            this.edit_product_details.stock_keeping_unit = ''
            this.edit_product_details.variant = false
            this.edit_product_details.variants = []
            }
        }
    },
    mounted() {
        this.getAllTypes()
        EventBus.$on('editProductDetails', product_id => {
        this.$http
            .post('products/get_product_by_id', { _id: product_id, edit: true })
            .then(res => {
                if (res.data.status_id == 1) {
                    this.selectedCategory = {name: res.data.response.result.category,_id: res.data.response.result.category_id}
                    this.selectedSubCategory = {sub_category_name: res.data.response.result.sub_category,sub_category_id: res.data.response.result.sub_category_id}
                    this.selectedProductType = {name: res.data.response.result.product_type,_id: res.data.response.result.product_type_id}
                    this.edit_product_details = res.data.response.result
                    if (this.edit_product_details.variant == true) {
                        this.edit_product_details.price = 0
                        this.edit_product_details.quantity = 0
                    }
                }
            })
            .then(res => {
                this.getAllSubCategoriesforEdit()
                this.getAllProductTypesforEdit()
            })
        })
        EventBus.$on('categoryCreated', res => {
            this.$modal.show(this.modal_name)
        })
        EventBus.$on('createdCategoryId', newId => {
            this.getCategory(newId)
        })
        EventBus.$on('createdBrandId', async newId => {
            try {
                let res = await this.brandsGetId(newId)
                this.singleBrand = res.message
            } catch (reason) {
                // TODO: handle reason
            }
        })
        EventBus.$on('editProduct', productDetails => {
        this.fromVariants = true
        this.product_id = productDetails.productId
        this.$http
            .post('products/get_product_by_id', {
                _id: productDetails.productId,
                edit: true
            })
            .then(res => {
                if (res.data.status_id == 1) {
                    this.selectedCategory = {name: res.data.response.result.category,_id: res.data.response.result.category_id}
                    this.selectedSubCategory = {sub_category_name: res.data.response.result.sub_category,sub_category_id: res.data.response.result.sub_category_id}
                    this.selectedProductType = {name: res.data.response.result.product_type,_id: res.data.response.result.product_type_id}
                    this.edit_product_details = res.data.response.result
                    if (this.edit_product_details.variant == true) {
                        this.edit_product_details.price = 0
                        this.edit_product_details.quantity = 0
                    }
                }
            })
            .then(res => {
                this.getAllSubCategoriesforEdit()
                this.getAllProductTypesforEdit()
            })
        })
        EventBus.$on('productSubCategory', productDetails => {
        this.fromSubCategory = true
        this.product_id = productDetails.productId
        this.$http
            .post('products/get_product_by_id', {
                _id: productDetails.productId,
                edit: true
            })
            .then(res => {
                if (res.data.status_id == 1) {
                    this.selectedCategory = {name: res.data.response.result.category,_id: res.data.response.result.category_id}
                    this.selectedSubCategory = {sub_category_name: res.data.response.result.sub_category,sub_category_id: res.data.response.result.sub_category_id}
                    this.selectedProductType = {name: res.data.response.result.product_type,_id: res.data.response.result.product_type_id}
                    this.edit_product_details = res.data.response.result
                    if (this.edit_product_details.variant == true) {
                        this.edit_product_details.price = 0
                        this.edit_product_details.quantity = 0
                    }
                }
            })
            .then(res => {
                this.getAllSubCategoriesforEdit()
                this.getAllProductTypesforEdit()
            })
        })
        EventBus.$on('productProductType', productDetails => {
        this.fromproductType = true
        this.product_id = productDetails.productId
        this.$http
            .post('products/get_product_by_id', {
                _id: productDetails.productId,
                edit: true
            })
            .then(res => {
                if (res.data.status_id == 1) {
                    this.selectedCategory = {name: res.data.response.result.category,_id: res.data.response.result.category_id}
                    this.selectedSubCategory = {sub_category_name: res.data.response.result.sub_category,sub_category_id: res.data.response.result.sub_category_id}
                    this.selectedProductType = {name: res.data.response.result.product_type,_id: res.data.response.result.product_type_id}
                    this.edit_product_details = res.data.response.result
                    if (this.edit_product_details.variant == true) {
                        this.edit_product_details.price = 0
                        this.edit_product_details.quantity = 0
                    }
                }
            })
            .then(res => {
                this.getAllSubCategoriesforEdit()
                this.getAllProductTypesforEdit()
            })
        })
    },
    destroyed() {
        EventBus.$off('editProductDetails')
        EventBus.$off('createdCategoryId')
        EventBus.$off('createdBrandId')
        EventBus.$off('editProduct')
        EventBus.$off('productSubCategory')
        EventBus.$off('productProductType')
    },
    watch: {
        singleBrand(newBrand, oldBrand) {
            this.edit_product_details.brand = newBrand
        },
        singleCategory(newCategory, oldCategory) {
            this.edit_product_details.category = newCategory
        }
    },
    created() {
        this.get_brands_for_products()
        this.get_categories_for_products_edit()
        this.getAllMeasurements()
    }
}
</script>
<style>
    .edit-product .v-modal-layout {
        max-height: 383px !important;
    }
    .vSelectWidth.v-select.single .selected-tag {
        width: 250px !important;
    }
    .qr-logo {
        position: absolute;
        margin-top: 28px;
        margin-left: -18px;
        width: 40px;
    }
</style>
