<template>
  <div class="page-layout blank">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex"
            style="white-space:nowrap !important;"
          >
            <router-link
              style="color: transparent!important;"
              :disabled="!$routerHistory.hasPrevious()"
              :to="{ path: $routerHistory.previous().path }"
            >
              <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-left"></i>
                </button>
              </el-tooltip>
            </router-link>
            <router-link
              style="color: transparent!important;"
              :disabled="!$routerHistory.hasForward()"
              :to="{ path: $routerHistory.next().path }"
            >
              <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-right"></i>
                </button>
              </el-tooltip>
            </router-link>
            <div
              class="list-title"
              style="font-weight: 500;"
              v-if="
                loggedInUser.actions.settings &&
                  loggedInUser.actions.settings.view_other_outlets
              "
            >
              <router-link to="/createoutlet" class="btn btn-secondary btn-smm">+ Add New Outlet</router-link>
              <a
                @click="ShowOrganisationDetails()"
                class="btn btn-secondary btn-smm ml-2"
              >Organisation Details</a>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading d-xl-inline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  type="text"
                  class="form-control textremove holder"
                  placeholder="Search..."
                  v-model="searchkey"
                  @input="getOutlets()"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right" v-if="total_outlets">
                <span class="page-info px-2">
                    {{start}} - {{end}} of
                    {{total_outlets}}
                </span>
                <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                    <button
                    :disabled="count <= 0"
                    :class="{'disabled':ajaxCallinprogress}"
                    v-on:click="count--"
                    type="button"
                    class="btn btn-icon el-tooltip item"
                    >
                    <i class="icon icon-chevron-left"></i>
                    </button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Next" placement="top">
                    <button
                    :class="{'disabled':ajaxCallinprogress}"
                    :disabled="end >= total_outlets"
                    v-on:click="count++"
                    type="button"
                    class="btn btn-icon el-tooltip item"
                    >
                    <i class="icon icon-chevron-right"></i>
                    </button>
                </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div class="category-manage-info" style="top:58px!important;">OUTLETS</div>
      <div class="page-layout carded left-sidebar mt-2"
        style="overflow: auto !important;padding-bottom: 5px;">
        <div class="page-content-wrapper" style="background-color: #005dae!important;padding: 10px!important;">
          <div class="page-content">
            <div class="demo-content">
              <table class="table table-bg-outlet table-scroll">
                <thead>
                  <tr>
                    <th style="white-space:nowrap">Outlet Name</th>
                    <th style="white-space:nowrap">Outlet Type</th>
                    <th
                      class="text-center"
                      style="padding: 1.4rem 0.1rem !important;white-space:nowrap"
                    >No. of Tables</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Website</th>
                    <th class="text-center">Admin</th>
                  </tr>
                </thead>
                <tbody @scroll="infiniteScrollOutlets">
                  <tr v-if="ajaxCallinprogress">
                    <td
                      class="text-center justify-content-center"
                      colspan="7"
                      style="padding: 0px;"
                    >
                      <div class="digi-table-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-else-if="!outlets.length"
                    style="height: 100%;"
                    class="justify-content-center"
                  >
                    <td colspan="7" class="text-center">
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" />
                      <p
                        class="text-secondary font-20"
                        style="font-size: 22px; font-weight: 600;"
                      >No Search Results Found</p>
                    </td>
                  </tr>
                  <tr v-else v-for="outlet in outlets" :key="outlet._id">
                    <td
                      style="padding: 0px 12px!important;max-width: 170px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                    >
                      <span
                        @click="redirectToDetails(outlet._id)"
                        class="outlet-name"
                      >{{ outlet.name }}</span>
                    </td>
                    <td
                      style="padding: 0px 12px!important;margin-left: 10px !important;"
                      class="text-capitalize"
                    >{{ outlet.outlet_type }}</td>
                    <td
                      style="padding: 0px!important;"
                      class="text-center"
                    >{{ outlet.no_of_tables }}</td>
                    <td
                      style="padding: 0px !important;max-width: 170px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-left: 10px !important;"
                      :title="outlet.email"
                    >{{ outlet.email }}</td>
                    <td
                      style="padding: 0px 12px!important;white-space:nowrap;margin-left: 12px !important;"
                      id="testing-code"
                    >{{ outlet.phone_number }}</td>
                    <td style="padding: 0px 12px!important;">{{ outlet.website }}</td>
                    <td
                      class="text-center"
                      style="white-space:nowrap;padding: 0px 12px !important;"
                    >
                      <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                        <button
                          class="btn btn-icon companyactions actionicons mr-2"
                          @click="redirectToDetails(outlet._id)"
                        >
                          <i class="icon icon-pencil" style="color:#00448f!important;"></i>
                        </button>
                      </el-tooltip>
                      <el-tooltip
                        class="item mr-3"
                        effect="dark"
                        content="Copy Address"
                        placement="top"
                      >
                        <span
                          v-clipboard="outlet.address || ''"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                        >
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/copy-address.svg" />
                        </span>
                      </el-tooltip>
                      <!-- <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                        <span @click="deleteOutlet(outlet._id)">
                          <img src="https://cdn.digicollect.com/cdn/posv2/trash.svg">
                        </span>
                      </el-tooltip>-->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <organisation-details
        :organisation_details="organisation_details"
        modal_odetails="orga-details"
      ></organisation-details>
    </div>
  </div>
</template>
<style>
.outlet-name {
  font-weight: 600;
  cursor: pointer;
  color: #00448b;
  text-transform: capitalize;
}
.table.table-bg-outlet {
  border-radius: 7.5px !important;
  background-color: #dee8f2 !important;
}
.table.table-bg-outlet tbody tr {
  border-bottom: 1px solid #a8adb4 !important;
}
.table.table-bg-outlet tbody tr:last-child {
  border-bottom: none !important;
}
</style>

<script>
import EditOutlet from './EditOutlet'
import OrganisationDetails from './OrganisationDetails'

import { EventBus } from './eventBus/nav-bar-event.js'

export default {
  // props: ['modal_odetails'],
  data() {
    return {
      outlets: [],
      ajaxCallinprogress: false,
      searchkey: '',
      organisation_details: {},
      count: 0,
      limit: 10,
      start: 0,
      end: 0,
      total_outlets: 0,
      outlet_count: 0,
    }
  },
  components: {
    EditOutlet,
    OrganisationDetails
  },
  mounted() {
    EventBus.$emit('customerdetailsLoad', false)
    EventBus.$emit('reportsLoad', false)
  },

  created() {
    this.getOutlets()
  },
  methods: {
    onCopy: function(e) {
      this.$toasted.global.success('Address Copied To Clipboard Successfully')
    },
    onError: function(e) {
      this.$toasted.global.error('Failed to copy texts')
    },
    ShowOrganisationDetails() {
      this.$http.get('/outlet/get_organisation_details').then(res => {
        if (res.data.status_id == 1) {
          this.organisation_details = res.data.response
        }
      })
      this.$modal.show('orga-details')
    },
    ClearSearch() {
      this.searchkey = ''
    },
    getOutlets() {
      this.ajaxCallinprogress = true
      this.$http
        .post('/outlet/get', { search_key: this.searchkey.trim(), skip: this.count * this.limit, limit: this.limit })
        .then(res => {
          this.ajaxCallinprogress = false
          if (res.data.status_id == 1) {
            this.outlets = res.data.outlets
            this.total_outlets = res.data.total
            this.start = this.count * this.limit + 1
            let tmp = this.count * this.limit + this.limit
            this.end = tmp < this.total_outlets ? tmp : this.total_outlets
            this.outlet_count = res.data.outlets.length
          }
        })
        .catch(error => {
          this.ajaxCallinprogress = false
        })
    },
    async infiniteScrollOutlets(e) {
        const {target} = e;
        if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                //this code will run when the user scrolls to the bottom of this div so
                //you could do an api call here to implement lazy loading
                try {
                    // this.loading = true
                    let result = await this.$http.post('/outlet/get', { search_key: this.searchkey.trim(), skip: this.outlet_count, limit: 10 })
                    if (result.data.status_id == 1) {
                        if (result.data.payload.skip == this.outlet_count) {
                            let tempFiles = result.data.outlets
                            tempFiles.forEach(el => {
                                this.outlets.push(el)
                            })
                            this.outlet_count += result.data.outlets.length;
                            // this.loading = false
                        }
                        // if (result.all_customers.length < 10) {
                        //     document.getElementById('spinner').style.display = 'none'
                        //     this.loading = false
                        // }
                    }
                } catch (reason) {

                }
                // this.bottom = true;
        }
    },
    deleteOutlet(outlet_id_tobe_deleted) {
      this.$swal({
        title: 'Are you sure?',
        text: "You can't revert your action",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes Delete it!',
        cancelButtonText: 'No, Cancel it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(result => {
        if (result.value) {
          this.$http
            .post('outlet/delete_outlet', { outlet_id: outlet_id_tobe_deleted })
            .then(res => {
              if (res.data.status_id == 1) {
                this.$swal({
                  title: res.data.message,
                  text: '',
                  icon: 'success',
                  type: 'success'
                })
                this.outlets = res.data.outlets
              } else {
                this.$swal({
                  title: 'Cannot Delete Outlet',
                  text: res.data.reason,
                  icon: 'warning',
                  type: 'warning'
                })
              }
            })
        }
      })
    },
    redirectToDetails(outlet_id) {
      this.$router.push({
        path: '/outletdetailsnew',
        query: { outletId: outlet_id }
      })
    }
  },
  watch: {
    count() {
      this.getOutlets()
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    }
  }
}
</script>

<style>
  /* .iconsearch {
      line-height: 34px;
      background-color: #00448f !important;
      color: #fff;
      border-radius: 0px 100px 100px 0px;
  } */
</style>