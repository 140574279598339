export default {
    methods: {
        async getScmProducts(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/scm/get_products", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async getScmProductDetails(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/scm/get_product_details", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        openSuccessModal(message) {
            this.success_msg = message;
            this.$refs.success_modal.open();
            setTimeout(() => {
                this.$refs.success_modal.close();
            }, 2000);
        },
        openWarningModal(message) {
            this.warning_msg = message;
            this.$refs.warning_modal.open();
            setTimeout(() => {
                this.$refs.warning_modal.close();
            }, 2000);
        },
        async storeRecipe(data, product_id) {
            return new Promise((resolve, reject) => {
                this.$http.post('/scm/update_recipes/' + product_id, data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.openSuccessModal(response.data.message);
                        } else {
                            reject(new Error(response.data.reason));
                            this.openWarningModal(response.data.reason);
                        }
                    })
                    .catch(error => {
                        reject(error.response.data.reason);
                    })
            })
        },
        async getRecipeByProductId(product_id, variant_id, recipe_name) {
            let params = {
                'variant_id': variant_id,
                'product_id': product_id,
            }
            if (recipe_name) {
                params['recipe_name'] = recipe_name
            }
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post('/scm/get_recipes_by_product_id', params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async getRecipeCount(product_id) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get('/scm/get_recipe_count/' + product_id)
            })
        },
        async getScmMeasurements(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/scm/get_unit_of_measurements", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                } catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async storeMeasurement(data) {
            return new Promise((resolve, reject) => {
                this.$http.post('/scm/add_unit_of_measurement', data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.openSuccessModal(response.data.message);
                        } else {
                            reject(new Error(response.data.reason));
                            this.openWarningModal(response.data.reason);
                        }
                    })
                    .catch(error => {
                        reject(error.response.data.reason);
                    })
            })
        },
        async updateMeasurement(data) {
            return new Promise((resolve, reject) => {
                this.$http.put('/scm/update_unit_of_measurement', data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.openSuccessModal(response.data.message);
                        } else {
                            reject(new Error(response.data.reason));
                            this.openWarningModal(response.data.reason);
                        }
                    })
                    .catch(error => {
                        reject(error.response.data.reason);
                    })
            })
        },
        async deleteMeasurementByID(measurementID) {
            return new Promise((resolve, reject) => {
                this.$http.delete('/scm/delete_unit_of_measurement/' + measurementID)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                            this.openSuccessModal(response.data.message);
                        } else {
                            reject(response.reason)
                            let resp = "Can't Delete. " + response.data.reason
                            this.openWarningModal(resp);
                        }
                    })
                    .catch(error => {
                        reject(error.response.data.reason);
                    })
            })
        },
        async getIngredients(params){
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/products/get_all_ingredients",params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                } catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async getVariants(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post("/products/get_all_ingredient_variants", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                    }
                } catch(error) {
                    reject(error.response.data.reason)
                }
            })
        }
    }
}