<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="280"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 27em !important;">
                
                <div  class="mt-4 v-modal-body" style="font-size: 16px">
                    <!-- {{countdown}} -->
                    <!-- <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-if="payment_status == 'in_progress'">
                        <div class="text-center fs-16 fw-800 text-blue">Payment In Progress!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_progress.svg" style="width:30%; margin-top:20px"/>
                        <div class="text-center fs-16  text-black mt-3">Please do not close or leave this page</div>
                    </div> -->
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-if="payment_status == 'success'">
                        <div class="text-center fs-16 fw-800 text-green">Thank you!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_success.svg" style="width:30%; margin-top:20px" />
                        <div class="text-center fs-16 text-black mt-3">Successful payment.  <br>Have a great day!</div>
                    </div>
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d" v-else-if="payment_status == 'failure'">
                        <div class="text-center fs-16 fw-800 text-red">Payment Declined!</div>
                        <img src="https://cdn.digicollect.com/cdn/posv2/images/pay_declined.svg" style="width:30%; margin-top:20px" />
                        
                        <div v-if="from_which_screen == 'payment_scan_pay_tab'" class="text-center fs-16 text-black mt-3">Please Check Card Details and try again!</div>
                        <div v-else class="text-center fs-16 text-black mt-3">Please try again!</div>
                    </div>
                </div>
                <!-- <div class="payment-footer" v-if="payment_status == 'in_progress'">
                    <div class="text-center fs-16 fw-800 text-white">Please wait...</div>
                </div> -->
                <div class="payment-footer" v-if="payment_status == 'success'">
                    <div class="text-center fs-16 fw-800 text-white">Payment Done.</div>
                </div>
                <div class="payment-footer" v-else-if="payment_status == 'failure'" style="background-image: linear-gradient(to right, #828282, #131f2b 101%)!important;">
                    <div class="text-center fs-16 fw-800 text-white">Payment failed.</div>
                </div>
            </div>  
        </modal>
    </div>
</template>

<script>
import { EventBus } from './eventBus/nav-bar-event.js';

export default {
    props: ["modal_name","payment_status", 'from_which_screen'],
    mixins: [],
    data() {
        return {
        }
    },
    methods: {
        
    }, 
    mounted() {
        setTimeout(() => {
            if(this.from_which_screen == 'payment_scan_pay_tab' && this.payment_status == 'success') {
                this.$modal.hide(this.modal_name)
                let previous_user_token = JSON.parse(localStorage.getItem('stored_previous_token_when_scan_and_pay'));
                if(previous_user_token) {
                    this.$cookie.set("Token", previous_user_token, {
                        expires: 7,
                        domain: window.location.hostname
                    });
                    // this.$cookie.set("session_id", response.data.session_id, {
                    //     expires: 7,
                    //     domain: window.location.hostname
                    // });
                    // sessionStorage.setItem('session_id', response.data.session_id);
                    this.$store.commit("setToken", previous_user_token);
                }
                this.$router.replace('/');
                EventBus.$emit('call_logged_inuser_after_scan_and_pay');
            } else if(this.from_which_screen == 'payment_scan_pay_tab' && this.payment_status == 'failure') {
                this.$modal.hide(this.modal_name)
            }
        }, 5000);
    },
    watch: {
    },
    computed:{
    },
}
</script>
<style>
.payment-footer{
    background-image: linear-gradient(to right, #037ae0, #00448b 101%)!important;
    color: #fff;
    width: 100%;
    text-align: -webkit-center;
    display:flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: center !important;
    padding: 15px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.new-modal-small.v--modal-overlay .v--modal-box.v--modal {
    height: 350px !important;
}
</style>