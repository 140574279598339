
<style>
.heading-category{
  font-weight: 600!important;
  color: #303031!important;
  padding-left: 10px!important;
  margin-bottom: 5px!important;
  font-size: 14px;
}
.statusdot {
  display: block;
  position: absolute;
  right: 5px;
  top: -4px;
  z-index: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff3b3b;
}
.header-search {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  position: relative;
  min-height: 96px;
  background-image: none;
  z-index: 49;
  padding: 16px !important;
}
.form-control.note-textarea {
  border-radius: 7px !important;
  border: solid 1px #cdd2da !important;
}
.note-add {
  cursor: pointer;
}
.showdiscount,
.shownote {
  border-bottom: solid 1px #94bef0 !important;
  padding-bottom: 10px !important;
}
.DollarTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: #fff;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.PerTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b;
  background: #fff;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.form-control.discount-input {
  border-radius: 100px !important;
  border: solid 1px #cdd2da !important;
  padding: 7px 10px !important;
}
.header-content {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex: 1 0 auto;
}
.dropdown-category {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: center;
  align-items: center;
  order: 1;
}
.header-board-name {
  font-size: 16px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  flex: 1 0 auto;
  order: 2;
  margin-bottom: 0 !important;
}
.btn.btn-order {
  border: solid 0.5px #cdd2da !important;
  border-radius: 50px;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f2f2f2;
  font-size: 12px !important;
  font-weight: bold;
  color: #9e9e9e;
  height: 32px;
  min-width: 13rem !important;
}
.btn.btn-order.dropdown-toggle::after {
  margin-left: 6.255em;
}
.form-control.input-order {
  border-radius: 50px !important;
  border: solid 0.5px #d5d5d5 !important;
  background-image: linear-gradient(
    to bottom,
    #efefef,
    #ffffff 25%,
    #ffffff 48%,
    #ffffff 78%,
    #efefef
  ) !important;
}
.label-order {
  font-weight: 600;
  color: #9e9e9e;
  line-height: 1.79;
  letter-spacing: 0.3px;
}
.unavaliable-order {
  text-align: center;
  display: block;
  position: absolute;
  background: rgba(175, 175, 175, 0.59);
  color: #e82828;
  width: 100%;
  top: 26px;
}
.unavaliable-order h6 {
  line-height: 1.79;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 auto;
}
.list-card-labels {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 6px;
}
.list-card-labels .list-card-label {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 32px;
  height: 6px;
  border-radius: 6px;
  margin: 0 6px 6px 0;
}
.btn-smm-new {
  height: 34px;
  padding: 0 18px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 34px;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
.list-group .list-group-item h3 {
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e5764;
  padding-bottom: 5px;
}
.list-group .list-group-item p {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303031;
}
.list-group.userdetails {
  border-radius: 0px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
  padding-top: 0px;
}
.list-group.userdetails .list-group-item.two-line {
  min-height: 6.2rem;
}
.list-group.userdetails .list-group-item {
  background-color: transparent;
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.list-group .list-group-item {
  padding: 0px;
}
.p-16-mod {
  padding: 0px 0px 10px;
}
.userdetailsblock {
  width: 250px;
  background-color: #f5f6f9;
  max-width: 260px;
}
/* New Box CSS */
.page-layout.simple {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}
.page-layout.simple.right-sidebar.inner-sidebar {
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.content-section {
  display: flex;
  min-height: 100%;
}

.fuse-sidebar {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  z-index: 1000;
}
.fuse-sidebar.left-positioned {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .sidebar.locked-open {
  padding-left: 10px;
  box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.16);
  border-left: solid 1px #cdd2da;
  background-color: #ffffff;
}
.page-layout.simple.right-sidebar.inner-sidebar .content-section .sidebar {
  order: 2;
}
.fuse-sidebar.locked-open {
  position: relative !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .center-section {
  order: 1;
}
.page-layout.simple.left-sidebar.inner-sidebar .content-section .center-section,
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .center-section {
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 992px) {
  .g-mb-0--lg {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 0) {
  .g-pa-20 {
    padding: 15px 15px 15px 20px !important;
  }
  .g-mb-10 {
    margin-bottom: 0.71429rem !important;
  }
  .g-mr-15 {
    margin-right: 15px !important;
  }
  .g-pr-15 {
    padding-right: 15px !important;
  }
  .g-pl-15 {
    padding-left: 15px !important;
  }
  .g-mt-3 {
    margin-top: 0px !important;
  }
  .g-mb-3 {
    margin-bottom: 0.21429rem !important;
  }
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.list-unstyled.itemslist {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}
.u-shadow-v11 {
  box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #cdd2da;
  background-color: #f9fbff;
}
.rounded {
  border-radius: 4px !important;
}
.media.media-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 1;
}
.media.media-item .media-body {
  -ms-flex: 1;
  flex: 1;
}
.media.media-item.unavaliable-item {
  opacity: 0.5;
}
.g-height-40 {
  height: 40px;
}

.g-width-40 {
  width: 40px !important;
}
.g-color-main {
  color: #00448b !important;
}
.g-font-weight-600 {
  font-weight: 600 !important;
}
.g-color-gray-dark-v4 {
  color: #4e5764 !important;
  line-height: 2.25;
  letter-spacing: 0.3px;
}
.g-font-size-13 {
  font-size: 13px !important;
}
.u-link-v5 {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
}
.itemimg {
  border-right: solid 0.5px #cdd2da;
}

.RUCsGf {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  margin: -5px;
  overflow: hidden;
}
/* .a-HZnjzd-xb-QBLLGd {
        flex: 1 1 150px;
        min-width: 150px;
        max-width: 250px;
    } */
.Ccie2c {
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin-right: 10px;
}
.a-dkl3Ye .L202Xe {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.L202Xe {
  background-color: #fff;
  margin: 7px;
  overflow: hidden;
  position: relative;
}
.preview {
  width: 100px;
  margin: 0 1px 8px 0;
  z-index: 0 !important;
}
.description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.toggle-source-preview {
  cursor: pointer;
  font-size: 12px;
  padding-left: 16px;
  color: #00448b;
}
.btn-select-table {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.21 !important;
  letter-spacing: 0.6px !important;
  color: #00448b !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
}
.btn-pay {
  width: 100% !important;
  background-color: #00448b !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 2.5px;
  font-size: 14px!important;
  text-transform: capitalize!important
}
.btn-place-order{
  width: 100% !important;
  background-color: #f5a623 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border-radius: 2.5px;
  font-size: 14px!important;
  text-transform: capitalize!important
}
.btn-pay .badge {
  float: right !important;
  top: 7px !important;
  font-weight: 600;
}
.tfoot-total-order {
  border-top: solid 0px #94bef0;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablesubtotal tr {
  height: 10px !important;
}
.table.tablesubtotal th {
  padding: 4px !important;
}
.tablesubtotaltd,
.tablesubtotalth {
  text-align: right !important;
}
.table.tablecart th.thcart {
  color: #303031;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px !important;
  padding: 5px 8px !important;
  text-transform: none !important;
  border-bottom: 0px solid transparent !important;
}
.table.tablecart td.tdcart {
  color: #4e5764;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px;
  padding: 5px 8px !important;
}
.table.tablecart tfoot th.tfcart {
  padding: 10px 8px !important;
}
.table.tablecart tfoot tr {
  border-top: dotted 0.5px #b8b9bb;
  border-bottom: solid 0.5px #94bef0;
}
.table.tablecart tbody tr {
  height: auto !important;
}
a.add-customer-heading {
  font-weight: 600;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #00448b !important;
  text-decoration: none !important;
}
.takeOrderTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px 0 0 20px !important;
}
.manageOrderTab {
  border: 1px solid #00448b;
  height: 34px !important;
  color: #00448b !important;
  background: #fff !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 5px 15px 5px 15px;
  font-size: 14px !important;
  font-weight: 500;
  cursor: pointer;
  border-left: none !important;
  border-radius: 0 20px 20px 0 !important;
  margin-left: -3px;
}
.activeClassDiscount {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.activeClass {
  background: #00448b !important;
  color: #fff !important;
  font-weight: bold;
}
.task.card {
  /* width: 310px !important; */
  height: auto !important;
  background-color: #ffffff !important;
  /* border: solid 1px #e0e3e6 !important; */
  border-top: solid 3px #00448b;
  border-radius: 5px 5px 0 0 !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}
.card-title {
  margin-top: 0rem !important;
  /* height: 41px; */
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  background-color: #f6f6f6;
  color: inherit;
}
.taskDropdown {
  -webkit-box-shadow: none !important;
  border: none !important;
  background-color: #f6f6f6;
  border-bottom: solid 1px #e0e3e6 !important;
  border-radius: 0px 0px 5px 5px;
}
.overDueImage {
  width: 10px;
  height: 10px;
  object-fit: contain;
  opacity: 0.6;
}
.taskDelete {
  color: #94bef0;
  float: right;
}
.task.card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.taskDropdown.card-body {
  border-left: solid 1px #e0e3e6 !important;
  border-right: solid 1px #e0e3e6 !important;
  /* border-bottom: solid 1px #e0e3e6 !important; */
}
.task-title.card-title {
  border-left: solid 1px #e0e3e6 !important;
  border-right: solid 1px #e0e3e6 !important;
}
.taskToggle.dropdown-toggle::after {
  display: none;
}
.collapsed.dropdown-toggle::after {
  float: right !important;
  margin-right: 12px !important;
  margin-top: 11px !important;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.card-title {
  margin-bottom: 0 !important;
  /* transition: 0.8s all ease !important; */
}
.TaskGroup {
  position: relative;
}
.scheduleTask {
  margin-top: 6px !important;
}
.searchProducts {
  width: 100%;
  height: 34px;
  border-radius: 100px;
  border: solid 1px #d5d5d5 !important;
  padding-left: 35px;
  padding-right: 35px;
  background-image: linear-gradient(
    to bottom,
    #efefef,
    #ffffff 25%,
    #ffffff 48%,
    #ffffff 78%,
    #efefef
  );
}
.searchIcon {
  color: #cfcfcf !important;
  position: absolute;
  pointer-events: cursor;
  top: 22px;
  font-size: 19px;
  left: 15px;
  display: inline-block;
}
.closeIcon {
  color: #cdd2da !important;
  position: absolute;
  pointer-events: cursor;
  top: 22px;
  font-size: 19px;
  right: 10px;
  display: inline-block;
}
.activeOrder a {
  border: 1px solid #00448b;
  height: 32px !important;
  color: #00448b !important;
  line-height: 32px !important;
  text-transform: none !important;
  margin: 0 auto;
  font-size: 14px !important;
  font-weight: 500;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-right: none;
  display: flex;
  justify-content: center;
  background: #fff !important;
  text-decoration: none;
  cursor: pointer;
}
.completedOrder a {
  border: 1px solid #00448b;
  height: 32px !important;
  color: #00448b !important;
  line-height: 32px !important;
  text-transform: none !important;
  margin: 0 auto;
  font-size: 14px !important;
  font-weight: 500;
  display: flex;
  justify-content: center;
  background: #fff !important;
  text-decoration: none;
  cursor: pointer;
}
.closedOrder a {
  border: 1px solid #00448b;
  height: 32px !important;
  color: #00448b !important;
  line-height: 32px !important;
  text-transform: none !important;
  margin: 0 auto;
  font-size: 14px !important;
  font-weight: 500;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border-left: none;
  display: flex;
  justify-content: center;
  background: #fff !important;
  text-decoration: none;
  cursor: pointer;
}
.orderStatus {
  height: auto;
  border: solid 5px #f6f6f6;
  background-color: #ffffff;
}
.activeOrder .orderActive {
  background-color: #00448b !important;
  color: #ffff !important;
}
.completedOrder .orderActive {
  background-color: #00448b !important;
  color: #ffff !important;
}
.closedOrder .orderActive {
  background-color: #00448b !important;
  color: #ffff !important;
}
.activeTask {
  border-top: solid 3px #1a9347 !important;
}
.border-dotted {
  border-bottom: solid 1px #b8b9bb;
  border-bottom-style: dashed;
}
.receivedOrder {
  border-bottom: solid 1px #f5a623;
  position: relative;
  margin-left: 36px;
  margin-right: 8px;
  left: 0;
  bottom: 11px;
}
.manageOptions.v-select.single.searchable .dropdown-toggle {
  height: 32px;
  margin-right: 10px;
}
.makeOrder {
  position: relative;
  bottom: 9px;
}
.deepSkyBlue {
  font-weight: 600;
  color: #00448b !important;
}
.noteTextArea {
  width: 100%;
  height: 100px !important;
  border: solid 1px #cdd2da;
  border-radius: 5px !important;
}
.card-title.paytype {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #b5b5b5;
  background: transparent;
}
.card-body.pay-body {
  padding: 1rem !important;
  border-left: 6px solid #a1caff00 !important;
  margin-bottom: 15px !important;
  border-radius: 0px;
}
.payselected {
  position: absolute;
  top: -1rem;
  right: -0.8rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 18px !important;
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}
.payselected.online {
  background: #06bb00;
  color: #fff;
}
.payselected.online:before {
  content: "\ea7b";
}
.card-title.paytype {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #b5b5b5;
  background: transparent;
}
.card-body.pay-body {
  padding: 1rem !important;
  border-left: 6px solid #a1caff00 !important;
  margin-bottom: 15px !important;
  border-radius: 0px;
}
.text-dec-none {
    text-decoration: none !important;
}
</style>

<template>
    <div class="page-layout simple right-sidebar inner-sidebar">
        <div class="" style="height: 100%;">
			<div id="mail" style="margin-right: 0px;margin-left: 15px;height: 100%;" v-if="isTakeOrder">
				<div class="content-section">
					<div class="fuse-sidebar sidebar left-positioned open locked-open preview" v-if="isTakeOrder">
						<div class="preview-elements details-block" v-show="!addQuantity">
							<div class="content">
                                <div class="description">
                                    <div class="description-text d-inline-block mt-1" style="width: 50%;border-right: 1px solid #eee;">
                                        <router-link :to="{path:'/addordercustomer', query: {'order': order}}" class="add-customer-heading d-inline-block mt-1 text-truncate" :title="order.customer.first_name" style="max-width: 120px;">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/customers.svg" style="height: 12px;margin-bottom: 2px;" class="pr-2">{{order.customer.first_name || 'Add Customer'}}
                                        </router-link>
                                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                            <button type="button"  class="btn btn-icon actionicons d-inline-block pull-right mr-0 mb-1" v-if="order.customer.first_name" @click="order.customer={}" style="padding: 0rem 0rem;">
                                                <i class="icon icon-delete">
                                                </i>
                                            </button>
                                        </el-tooltip>
                                            <!-- <img v-else src="https://cdn.digicollect.com/cdn/posv2/customers.svg" style="height: 14px;" class="pr-2"> -->
                                    </div>
                                    <div class="btn-group toggle-source-preview d-inline-block" role="group" style="width: 50%;">
                                        <!-- <button id="btnGroupVerticalDrop1" v-if="order.tableNumber" type="button" class="btn btn-select-table dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            {{order.tableNumber}}
                                        </button> -->
                                         <button id="btnGroupVerticalDrop1" type="button" class="btn btn-select-table dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="tableselectionclick()">
                                            Select Table
                                        </button>
                                        <!-- <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -108px, 0px); top: 0px; left: 0px; will-change: transform;">
                                            <a @click="order.tableNumber = 'Table 1'" class="dropdown-item">Table 1</a>
                                            <a @click="order.tableNumber = 'Table 2'" class="dropdown-item">Table 2</a>
                                            <a @click="order.tableNumber = 'Table 3'" class="dropdown-item">Table 3</a>
                                            <a @click="order.tableNumber = 'Table 4'" class="dropdown-item">Table 4</a>
                                            <a @click="order.tableNumber = 'Table 5'" class="dropdown-item">Table 5</a>
                                            <a @click="order.tableNumber = 'Table 6'" class="dropdown-item">Table 6</a>
                                            <a @click="order.tableNumber = 'Table 7'" class="dropdown-item">Table 7</a>
                                        </div> -->
                                    </div>
                                </div>
                                <hr style="border-top: 0.5px solid #94bef0;margin-top: 0rem;">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table tablecart">
                                                <thead>
                                                    <tr style="border-bottom: solid 0.5px #e0e3e6;">
                                                        <th class="thcart">Items</th>
                                                        <th class="thcart">Tax</th>
                                                        <th class="thcart">Price</th>
                                                        <th class="thcart"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(product, index) in order.products" :key="index">
                                                        <td class="tdcart deepSkyBlue pointer" @click="showAddQuantity()">{{product.name + ' x ' + product.editedQuantity}}</td>
                                                        <td class="tdcart">{{product.currency + ((product.tax[0].tax_percentage/100) * product.price).toFixed(2)}}</td>
                                                        <td class="tdcart">{{product.currency + Number(product.price).toFixed(2)}}</td>
                                                        <td class="tablesubtotaltd">
                                                            <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                                <button type="button" @click="removeProductFromOrder(index)" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                                                    <i class="icon icon-delete">
                                                                    </i>
                                                                </button>
                                                            </el-tooltip>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="!order.products.length" style="background:#f3f3f3;">
                                                        <td colspan="4" class="text-center tdcart" style="color:#303031!important;"> <strong>No Items</strong></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th class="tfcart">ADD</th>
                                                        <th class="tfcart text-secondary">Additonal Charges</th>
                                                        <th class="tfcart text-secondary">
                                                            <span class="note-add" @click="addNote();addDiscountdiv = false;">Note</span>
                                                        </th>
                                                        <th class="tfcart  text-secondary">
                                                            <span class="note-add" @click="addDiscount(); addNotediv = false;"> Discount
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="shownote pt-4" v-show="addNotediv">
                                        <textarea class="form-control note-textarea" v-model="order['note']" rows="2" style="height: 80px!important;"></textarea>
                                        <div class="text-center pt-4 pb-2">
                                             <a  class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideNotediv();order.note=''">CANCEL</a>
                                            <button type="submit" class="btn btn-secondary btn-smm" @click="hideNotediv()" >SAVE</button>
                                        </div>
                                    </div>
                                    <div class="showdiscount pt-4" v-show="addDiscountdiv">
                                       <div class="row">
                                           <div class="col-md-4">
                                                <span class="pull-right pointer">
                                                    <a class="DollarTab" @click="percentageTab()" :class="{ activeClassDiscount : isPercentage == true }">%</a>
                                                    <a class="PerTab" @click="dollarTab()" :class="{activeClassDiscount: isDollar == true}">$</a>
                                                </span>
                                           </div>
                                           <div class="col-md-8">
                                               <input class="form-control discount-input">
                                           </div>
                                       </div>
                                    </div>
                                    <table class="table tablesubtotal">
                                        <!-- <tbody>
                                            <tr class="type">
                                                <th class="tablesubtotalth">Subtotal</th>
                                                <td class="tablesubtotaltd">${{order['subtotal'].toFixed(2)}}</td>
                                            </tr>
                                            <tr class="size">
                                                <th class="tablesubtotalth">Tax </th>
                                                <td class="tablesubtotaltd">${{order['tax'].toFixed(2)}}</td>
                                            </tr>
                                            <tr class="location">
                                                <th class="tablesubtotalth">Discount</th>
                                                <td class="tablesubtotaltd">${{order['discount'].toFixed(2)}}</td>
                                            </tr>
                                            <tr class="owner">
                                                <th class="tablesubtotalth">Roundoff</th>
                                                <td class="tablesubtotaltd">${{order['roundoff'].toFixed(2)}}</td>
                                            </tr>
                                    </tbody> -->
                                    <tfoot class="tfoot-total-order">
                                        <tr>
                                            <th class="tfcart text-secondary tablesubtotaltd">Grand Total</th>
                                            <th class="tfcart tablesubtotaltd">${{Number(order['subtotal'] + order['tax'] - order['discount']).toFixed(2)}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div class="pt-3">
                                    <a class="text-secondary">
                                        <span>Save For Later</span>
                                    </a>
                                    <a class="text-secondary pl-6">
                                        <span>Discard</span>
                                    </a>
                                </div>
                            </div>
                            <!-- <button class="btn btn-pay mt-3" @click="showPayBill()">
                                <span class="pull-left">Pay</span>
                                <span class="pull-right">${{Number(order['subtotal'] + order['tax'] - order['discount']).toFixed(2)}}</span>
                            </button> -->
                            <div class="row">
                                <div class="col-md-6">
                                    <button class="btn btn-place-order mt-3 text-center">
                                        Place Order
                                        <!-- <span class="pull-right">$24.00</span> -->
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-pay mt-3 mr-3 text-center" @click="createOrder()">
                                    Pay
                                        <!-- <span class="pull-right">$24.00</span> -->
                                    </button>
                                </div>
                            </div>
                            <span class="text-success mt-1" style="font-size: 14px;display: block;font-weight: 600;" v-show="showSuccessMessage">Order Placed Successfully</span>
                            <!-- <span class="text-danger mt-1" style="font-size: 14px;display: block;font-weight: 600;">Order Placed Successfully</span> -->
						</div>
                        <div class="preview-elements details-block" v-show="addQuantity">
							 <div class="description p-2">
                                <div class="description-text">
                                        Burger/Large
                                </div>
                                <div class="btn-group toggle-source-preview" @click="showAddQuantity()" role="group">
                                    <i class="icon icon-close-circle fs-16"></i>
                                </div>
                            </div>
                             <hr style="border-top: 0.5px solid #e0e3e6;">
                             <div class="d-form__group">
                                <label class="label-qty">Quantity</label>
                                <div class="input-group" style="width: 200px;margin: 0 auto;">
                                    <div class="input-group-append"><span class="input-group-text btnqty" id="basic-addon2"><i class="btni icon icon-minus-circle"></i></span></div>
                                    <input type="text" class="form-control m-input inputorder" value="12" aria-describedby="basic-addon2" autocomplete="off">
                                    <div class="input-group-prepend"><span class="input-group-text btnqty" id="basic-addon2"><i class="btni icon icon-plus-circle"></i></span></div>
                                </div>
                            </div>
                            <div class="d-form__group">
                                <label class="label-qty">Note</label>
                                   <textarea class="form-control noteTextArea"></textarea>
                            </div>
                            <div class="p-4 d-block" align="center">
                                  <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1" @click="showAddQuantity()">CANCEL</a>
                                  <a class="btn btn-secondary btn-smm text-uppercase" @click="showAddQuantity()">SAVE</a>
                            </div>
						</div>
					</div>
                    <div class="center-section pt-5" v-if="isTableSelection">
                        <table-selection></table-selection>
				    </div>
					<div class="center-section pt-5" v-if="isTableNonSelection">
						<div class="row pb-4 pr-2">
							<div class="col-md-3">
								<label class="label-order">Select Category</label><br>
								<div class="btn-group" role="group" style="padding-top: 4px;">
									<button id="btnGroupVerticalDrop1" type="button" class="btn btn-order dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
										{{categorySelected || 'All'}}
									</button>
									<div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" x-placement="top-start"
                                        style="position: absolute; transform: translate3d(0px, -108px, 0px); top: 0px; left: 0px; will-change: transform;">
                                        <a class="dropdown-item" @click="categorySelected = 'All'" href="javascript:void(0)">All</a>
                                        <a v-for="(category, index) in categories" :key="index" @click="categorySelected = category.name" class="dropdown-item" href="javascript:void(0)">{{category.name}}</a>
										<!-- <a class="dropdown-item" href="#">Beverages</a>
										<a class="dropdown-item" href="#">Ingredient</a>
                                        <a class="dropdown-item" href="#">Snacks</a>
										<a class="dropdown-item" href="#">Meals</a> -->
									</div>
								</div>
							</div>
							<div class="col-md-9">
								<label class="label-order">Find Product By Name, Barcode </label>
                                 <span class="pull-right pointer makeOrder">
                                    <a class="takeOrderTab text-dec-none" href="javascript:void(0)" :class="{ activeClass : isTakeOrder == true }" @click="takeOrder()">Take Order </a>
                                    <a class="manageOrderTab text-dec-none" href="javascript:void(0)" :class="{activeClass: isManageOrder == true}" @click="manageOrder()">Manage Order</a>
                                    <!-- <span class="statusdot"></span> -->
                                </span>
								<div class="m-input-icon m-input-icon--left m-input-icon--right">
                                    <input type="text" class="form-control m-input m-input--pill" placeholder="Start Typing To See Product" autocomplete="off">
                                    <span class="m-input-icon__icon m-input-icon__icon--left"><span><i class="icon icon-magnify"></i></span></span>
                                    <span class="m-input-icon__icon m-input-icon__icon--right"><span><i class="icon icon-close-circle"></i></span></span>
                                </div>
							</div>
						</div>
                        <div>
                            <div class="RUCsGf">
                                <p class="heading-category">Beverages</p>
                                <masonry :cols="{default: 4, 1920: 5, 1800: 5, 1600: 4, 1400: 4, 1300: 4, 1280: 4, 1180: 3, 992: 3, 768: 2, 400: 1}" :gutter="{default: '10px'}">
                                    <!-- <div v-for="(product,index) in products" :key="index" class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div :class="product.quantity <=0  ? 'unavaliable-item' : ''" class="media media-item u-shadow-v11 rounded g-mb-10" @click="showQuantity(product)">
                                                <div v-if="product.quantity <= 0" class="unavaliable-order">
                                                    <h6>Unavaliable</h6>
                                                </div>
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" :src="product.img_url || 'https://cdn.digicollect.com/cdn/posv2/img/sugar.png'" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">{{product.name}}</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - {{product.quantity}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/burger.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" >Burger</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/milk.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Milk</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/milkshake.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Milkshake</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </masonry>
                                <p class="heading-category">Snacks</p>
                                <masonry :cols="{default: 4, 1920: 5, 1800: 5, 1600: 4, 1400: 4, 1300: 4, 1280: 4, 1180: 3, 992: 3, 768: 2, 400: 1}" :gutter="{default: '10px'}">
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </masonry>
                                <p class="heading-category">Ingredients</p>
                                <masonry :cols="{default: 1, 1920: 5, 1800: 5, 1600: 4, 1400: 4, 1300: 4, 1280: 4, 1180: 3, 992: 3, 768: 2, 400: 1}" :gutter="{default: '10px'}">
                                    <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="Ccie2c a-HZnjzd-xb-QBLLGd">
                                        <div class="L202Xe">
                                            <div class="media media-item u-shadow-v11 rounded g-mb-10">
                                                <div class="itemimg d-flex align-self-center g-pa-20 g-mt-3">
                                                    <img class="g-width-40 g-height-40" src="https://cdn.digicollect.com/cdn/posv2/img/sugar.png" alt="Image Description">
                                                </div>
                                                <div class="media-body g-pa-20">
                                                    <a class="d-block u-link-v5 text-truncate g-color-main g-color-primary--hover g-font-weight-600 g-mb-3" href="javascript:void(0)">Sugar</a>
                                                    <span class="g-font-size-13 g-color-gray-dark-v4 g-mr-15">
                                                        Remaining - 20
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </masonry>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isManageOrder">
                <div class="col-lg-12">
                    <div class="p-1 mt-2">
                        <!-- <label class="label-order pl-4 mt-1">Find Order By Order ID, Customer Name.</label> -->
                        <span class="pull-right pointer pr-1">
                            <a class="takeOrderTab" :class="{ activeClass : isTakeOrder == true }" @click="takeOrder()">Take Order </a>
                            <a class="manageOrderTab" :class="{activeClass: isManageOrder == true}" @click="manageOrder()">Manage Order</a>
                        </span>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-10 col-md-8 col-sm-10 col-xs-12 pr-4">
                            <div class="pl-4" style="width:60%">
                                <div class="form-group contactGroup">
                                    <span class="icon icon-close-circle closeIcon text-secondary pointer"></span>
                                    <span class="icon icon-magnify iconsearch  searchIcon text-secondary pointer"></span>
                                    <input class="searchProducts"
                                           v-model="searchKey"
                                           placeholder="Start Typing To See Orders"
                                           type="text" name="prodsearchkey">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-2 col-xs-12 mt-3 pull-right hidden-sm-down">
                            <v-select class="manageOptions font-weight-bold timelineOptions" placeholder="All Orders" :filterable="true" :options="taskDropdown" v-model="taskOptions"></v-select>
                        </div>
                    </div> -->
                    <div class="row pl-2 pr-2">
                        <div class="col-md-4 mt-3 pl-6" v-for="(order, index) in orders" :key="index" >
                            <div class="card task" :class="{activeTask : order.orderStatus == 'active'}">
                                <a data-toggle="collapse" href="#collapseOverDue" role="button" aria-expanded="false" aria-controls="collapseOverDue" class="card-title task-title taskToggle dropdown-toggle  pl-3 pointer pb-2">
                                <div class=" pr-3 row no-gutters align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14 fw-normal">Order ID</span>
                                            </div>
                                        </div>
                                        <div class="defaultBlue fs-14 font-weight-bold">
                                            {{order.orderId}}
                                        </div>
                                    </div>
                                </a>
                                <div id="collapseOverDue" class="card-body taskDropdown collapse show">
                                    <div class="pr-2 row no-gutters align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14">Table  Number</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold">
                                            {{order.tableNumber}}
                                        </div>
                                    </div>
                                    <div class="pr-2 pt-4 row no-gutters align-items-center justify-content-between">
                                        <div class="col">
                                            <div class="row no-gutters align-items-center">
                                                <span class="fs-14">Customer Name</span>
                                            </div>
                                        </div>
                                        <div class="fs-14 font-weight-bold">
                                            {{order.customer.first_name}}
                                        </div>
                                    </div>
                                    <div class="defaultBlue font-weight-bold pt-4 fs-14">
                                        Status
                                    </div>
                                    <div class="pt-4 row no-gutters align-items-center justify-content-between">
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-xl-4 col-xs-4 activeOrder">
                                            <a :class="{ orderActive : order.orderStatus == 'active' }" @click="activeOrder(index)">
                                                Active
                                            </a>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-xl-4 col-xs-4 completedOrder">
                                            <a :class="{ orderActive : order.orderStatus == 'completed' }" @click="completedOrder(index)">
                                                Completed
                                            </a>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-xl-4 col-xs-4 closedOrder">
                                            <a :class="{ orderActive : order.orderStatus == 'closed' }" @click="closeOrder(index)">
                                                Close
                                            </a>
                                        </div>
                                    </div>
                                    <div class="defaultBlue font-weight-bold pt-4 fs-14" v-if="order.note.length">
                                        Note
                                    </div>
                                    <div class="pt-3 fs-14">
                                        <div>{{order.note}}</div>
                                        <!-- <div>2. Put less sugar for coffee</div>      -->
                                    </div>
                                    <div class="orderStatus pb-3 mt-4">
                                        <div class="row no-gutters align-items-center justify-content-between col-lg-12 border-dotted">
                                            <div class="col">
                                                <div class="row no-gutters align-items-center">
                                                    <span class="fs-14 font-weight-bold">Items</span>
                                                </div>
                                            </div>
                                            <div class="fs-14 font-weight-bold mr-5">
                                                Quantity
                                            </div>
                                            <!-- <div class="fs-14 font-weight-bold">
                                                Status
                                            </div> -->
                                        </div>
                                        <div v-for = "(p, index) in order.products" :key="index" class="row no-gutters align-items-center justify-content-between col-lg-12 pt-4">
                                            <div class="col">
                                                <div class="row no-gutters align-items-center">
                                                    <el-checkbox v-model="p.status">{{p.name}}</el-checkbox>
                                                </div>
                                            </div>
                                            <div class="fs-14 font-weight-bold mr-12">
                                                {{p.editedQuantity}}
                                            </div>
                                            <!-- <div class="fs-14 font-weight-bold">
                                                <el-switch v-model="p.status"></el-switch>
                                            </div> -->
                                        </div>
                                        <!-- <div :class="{receivedOrder: status == true}"></div> -->
                                        <!-- <div class="row no-gutters align-items-center justify-content-between col-lg-12 pt-4">
                                            <div class="col">
                                                <div class="row no-gutters align-items-center">
                                                    <el-checkbox v-model="status">Coffee/Medium </el-checkbox>
                                                </div>
                                                <div class="pl-7 pt-1">
                                                    Pour less sugar for coffee
                                                    and keep it warm.
                                                </div>
                                            </div>
                                            <div class="fs-14 font-weight-bold mr-12">
                                                02
                                            </div>
                                            <div class="fs-14 font-weight-bold">
                                                <el-switch v-model="status"></el-switch>
                                            </div>
                                        </div>
                                        <div class="row no-gutters align-items-center justify-content-between col-lg-12 pt-4">
                                            <div class="col">
                                                <div class="row no-gutters align-items-center">
                                                    <el-checkbox v-model="status">Milkshake</el-checkbox>
                                                </div>
                                            </div>
                                            <div class="fs-14 font-weight-bold mr-12">
                                                01
                                            </div>
                                            <div class="fs-14 font-weight-bold">
                                                <el-switch v-model="status"></el-switch>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="quantity-order" transition="nice-modal-fade">
                <div class="v-modal-content">
                    <div class="v-modal-header">
                        <span class="v-modal-dialog-title v-modal-title">{{showProduct.name}}</span>
                    </div>
                    <div class="v-modal-body">
                        <div class="v-modal-layout pt-3 pl-2 pr-3 d-form">
                            <div class="d-form__group">
                                <label class="label-qty">Quantity</label>
                                <div class="input-group" style="width: 200px;">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text btnqty" id="basic-addon2">
                                            <i class="btni icon icon-minus-circle" v-on:click="changesProdQuantity(-1)">

                                            </i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control m-input inputorder"  v-model="editedQuantity"  aria-describedby="basic-addon2" autocomplete="off">
                                    <div class="input-group-append">
                                        <span class="input-group-text btnqty" v-on:click="changesProdQuantity(1)" id="basic-addon2">
                                            <i class="btni icon icon-plus-circle"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="v-modal-footer text-center pt-5">
                            <a  class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideQuantity()">CANCEL</a>
                            <button type="submit" class="btn btn-secondary btn-smm" @click="addProductToOrder()">ADD</button>
                        </div>
                    </div>
                </div>
            </modal>
            <!-- <modal name="pay-bill" transition="nice-modal-fade">
                <div class="v-modal-content">
                    <div class="v-modal-header">
                        <span class="v-modal-dialog-title v-modal-title">PAYMENT</span>
                    </div>
                    <div class="v-modal-body" style="padding: 15px 0px;overflow-y: auto;overflow-x: hidden;">
                        <div class="v-modal-layout">
                            <h6 class="d-inline-block pl-5 pr-2" style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;">Order Summary</h6>
                            <div class="row pl-5 pr-5">
                                <div class="col-md-8" style="border-right: solid 2px #cdd2da;">
                                    <table class="table tablecart border-bottom-dotted">
                                        <thead>
                                            <tr style="border-bottom: solid 1px #b8b9bb;border-bottom-style: dashed;">
                                                <th class="thcart" style="width: 60%;font-size: 14px;">Items</th>
                                                <th class="thcart" style="width: 20%;font-size: 14px;">Tax</th>
                                                <th class="thcart" style="width: 20%;font-size: 14px;">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="tdcart items text-secondary" style="width: 60%;font-size: 14px;">Burger/Large X 2</td>
                                                <td class="tdcart" style="width: 20%;font-size: 14px;">$1</td>
                                                <td class="tdcart" style="width: 20%;font-size: 14px;">$12</td>
                                            </tr>
                                            <tr>
                                                <td class="tdcart items text-secondary" style="width: 60%;font-size: 14px;">Coffee/Medium</td>
                                                <td class="tdcart" style="width: 20%;font-size: 14px;">$1</td>
                                                <td class="tdcart" style="width: 20%;font-size: 14px;">$06</td>
                                            </tr>
                                            <tr>
                                                <td class="tdcart items text-secondary" style="width: 60%;font-size: 14px;">Milkshake </td>
                                                <td class="tdcart" style="width: 20%;font-size: 14px;">$1</td>
                                                <td class="tdcart" style="width: 20%;font-size: 14px;">$12</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table tablesubtotal">
                                        <tbody>
                                            <tr class="type">
                                                <td class="tdcart"></td>
                                                <th class="tablesubtotalth">Subtotal</th>
                                                <td class="tablesubtotaltd" style="padding-right: 40px !important;width: 33%;">$24.00</td>
                                            </tr>
                                            <tr class="size">
                                                <td class="tdcart"></td>
                                                <th class="tablesubtotalth">Tax </th>
                                                <td class="tablesubtotaltd" style="padding-right: 40px !important;width: 33%;">$1.00</td>
                                            </tr>
                                            <tr class="location">
                                                <td class="tdcart"></td>
                                                <th class="tablesubtotalth">Discount</th>
                                                <td class="tablesubtotaltd" style="padding-right: 40px !important;width: 33%;">$1.00</td>
                                            </tr>
                                            <tr class="owner">
                                                <td class="tdcart"></td>
                                                <th class="tablesubtotalth">Roundoff</th>
                                                <td class="tablesubtotaltd" style="padding-right: 40px !important;width: 33%;">$00.00</td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="tfoot-total-order">
                                            <tr>
                                                <td class="tdcart"></td>
                                                <th class="tfcart text-secondary tablesubtotaltd">Total</th>
                                                <th class="tfcart tablesubtotaltd" style="padding-right: 40px !important;width: 33%;">$24.00</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <div class="card" style="width: 8rem;border: 0px solid transparent;box-shadow: none;margin: 0 auto;">
                                            <img class="card-img-top" src="https://cdn.digicollect.com/cdn/posv2/cash.svg" alt="Card image cap">
                                            <div class="card-body pay-body text-center p-1">
                                                <h4 class="card-title paytype">Cash</h4>
                                            </div>
                                        </div>
                                        <div class="card" style="width: 8rem;border: 0px solid transparent;box-shadow: none;margin: 0 auto;">
                                            <img class="card-img-top" src="https://cdn.digicollect.com/cdn/posv2/debit-card.svg" alt="Card image cap">
                                            <div class="card-body pay-body text-center p-1">
                                                <h4 class="card-title paytype">Card</h4>
                                            </div>
                                            <i class="icon- payselected online s-4"></i>
                                        </div>
                                        <div class="card" style="width: 8rem;border: 0px solid transparent;box-shadow: none;margin: 0 auto;">
                                            <img class="card-img-top" src="https://cdn.digicollect.com/cdn/posv2/digipay.svg" alt="Card image cap">
                                            <div class="card-body pay-body text-center p-1">
                                                <h4 class="card-title paytype">DigiPay</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="v-modal-footer text-center pt-5">
                            <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hidePayBill()">CANCEL</a>
                            <button type="submit" class="btn btn-secondary btn-smm" @click="hidePayBill()" >SUBMIT</button>
                        </div>
                    </div>
                </div>
            </modal> -->
        </div>
    </div>
</template>


<script>
import { EventBus } from './eventBus/nav-bar-event.js'
import ManageOrder from "./ManageOrder"
import TableSelection from "./TableSelection"
import orderServices from "./mixins/orders.js"
import { setTimeout } from 'timers';

export default {
    data() {
        return {
            isTakeOrder: true,
            isManageOrder: false,
            isTableSelection: false,
            isTableNonSelection:true,
            // taskDropdown: [
            //     'Newest First',
            //     'Active First',
            //     'Completed'
            // ],
            counter: 0,
            // taskOptions: null,
            status: false,
            isActive: true,
            isCompleted: false,
            isClose: false,
            addQuantity: false,
            addNotediv: false,
            addDiscountdiv: false,
            categorySelected: '',
            showProduct: {},
            productSearchKey: '',
            searchKey: '',
            showSuccessMessage: false,
            selectTable: 'Select Table',
            editedQuantity: 1,
            order: {
                orderId: ('OD' + new Date().valueOf()).substring(0, 16),
                customer: {},
                tableNumber: '',
                products: [],
                subtotal: 0,
                tax: 0,
                discount: 0,
                roundoff: 0,
                orderStatus: 'active',
                completedBy: '',
                closedBy: '',
                createdAt: '',
                closedAt: '',
                note: '',
                completedAt: '',
                isPaid: false,
                paymentMethod: 'Cash',
                date: new Date()
            },
            isPercentage: true,
            isDollar: false
        }
    },
    mixins: [orderServices],
    methods: {
        increment() {
        this.counter++;
      },
        showQuantity(product) {
            if (product.quantity > 0) {
                this.$modal.show('quantity-order');
                this.showProduct = product;
            }
        },
        changesProdQuantity(quantity) {
            this.editedQuantity += quantity;
        },
        hideQuantity() {
            this.$modal.hide('quantity-order');
        },
        addCustomerOrder() {
            this.$modal.show('add-order-customer');
        },
        addCustomerhideOrder() {
            this.$modal.hide('add-order-customer');
        },
        takeOrder() {
            this.isTakeOrder = true;
            this.isManageOrder = false;
        },
        tableselectionclick() {
            this.isTableNonSelection = false;
            this.isTableSelection = true;
        },
        // closetableselection(){
        //     this.isTableNonSelection = true;
        //     this.isTableSelection = false;
        // },
        manageOrder() {
            this.isTakeOrder = false;
            this.isManageOrder = true;
        },
        activeOrder(orderIndex) {
            this.orders[orderIndex].orderStatus = "active";
        },
        completedOrder(orderIndex) {
            this.orders[orderIndex].orderStatus = "completed";
        },
        closeOrder(orderIndex) {
            this.orders[orderIndex].orderStatus = "closed";
        },
        createOrder() {
            if(this.order.customer) {
                this.showSuccessMessage = true;
                this.$store.commit('addOrder', this.order);
                this.order = {orderId: ('OD' + new Date().valueOf()).substring(0, 16),
                              customer: {},
                              tableNumber: '',
                              products: [],
                              subtotal: 0,
                              tax: 0,
                              discount: 0,
                              roundoff: 0,
                              orderStatus: 'active',
                              completedBy: '',
                              closedBy: '',
                              createdAt: '',
                              closedAt: '',
                              note: '',
                              completedAt: '',
                            };
                setTimeout(()=>{this.showSuccessMessage = false}, 2000);
            } else {

            }
        },
        showAddQuantity() {
            this.addQuantity = !this.addQuantity;
        },
        addNote() {
            this.addNotediv = !this.addNotediv;
        },
        addDiscount() {
            this.addDiscountdiv = !this.addDiscountdiv;
        },
        hideNotediv() {
            this.addNotediv = false;
        },
        addProductToOrder() {
            this.showProduct['editedQuantity'] = this.editedQuantity;
            // this.order.totalAmount += this.editedQuantity * (Number(this.showProduct.tax[0].tax_percentage/100 * this.showProduct.price) + Number(this.showProduct.price));
            this.order.subtotal += this.editedQuantity * this.showProduct.price;
            this.order.tax += this.showProduct.tax[0].tax_percentage/100  * this.showProduct.price;
            var prod = JSON.parse(JSON.stringify(this.showProduct))
            prod['status'] = false
            this.order.products.push(prod);
            this.editedQuantity = 1
            this.hideQuantity();
        },
        removeProductFromOrder(productIndex) {
            var productDeleted = this.order.products[productIndex];
            // this.order.totalAmount -= productDeleted.editedQuantity * (Number(productDeleted.tax[0].tax_percentage/100 * productDeleted.price) + Number(productDeleted.price));
            this.order.subtotal -= productDeleted.editedQuantity * productDeleted.price;
            this.order.tax -= productDeleted.tax[0].tax_percentage/100  * this.showProduct.price;
            this.order.products.splice(productIndex, 1);
            this.editedQuantity = 1;
        },
        showPayBill() {
            this.$modal.show('pay-bill');
        },
        hidePayBill() {
            this.$modal.hide('pay-bill');
        },
        percentageTab() {
            this.isPercentage = true;
            this.isDollar = false;
        },
        dollarTab() {
            this.isPercentage = false;
            this.isDollar = true;
        }
    },
    components: {
        ManageOrder,
        TableSelection
    },
    watch: {
        productSearchKey: function (val, oldVal) {
            this.products = this.products.filter(function (prod) {
                return Boolean(prod.name.match(new RegExp(this.productSearchKey, 'i')));
            });
        }
    },
    created() {
        if (this.$route.query.customer) {
            this.order['customer'] = this.$route.query.customer;
        }
    },
    mounted() {
        EventBus.$on('closeTableSelect', table => {
            this.isTableSelection = false;
            this.isTableNonSelection = true;
        })
    }
}
</script>