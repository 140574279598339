<template>
  <div>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">Edit Card Provider</span>
        </div>
        <div class="v-modal-body">
          <div class="v-modal-layout pt-1">
            <div class="row first-middle-input">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group contactGroup" style="margin-top: -15px;">
                      <input
                        class="inputContact"
                        type="text"
                        v-model.trim="card.name"
                        required
                        name="cardName"
                        v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                        autofocus
                        :maxlength="cardprovider"
                      />
                      <span class="highlightContact"></span>
                      <span class="barContact"></span>
                      <label class="labelContact">
                        Card Provider
                        <sup>★</sup>
                      </label>
                      <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('cardName')"
                        style="display:contents;"
                      >{{errors.first('cardName')}}</span>
                      <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                      >{{cardprovider - card.name.length}} / 40</p>
                      <div
                        v-show="nameExists"
                        class="text-danger fs-13"
                      >Card Provider Already Exists</div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 pt-3">
                        <label class="text-secondary col-md-4 row">Is Card Provider Available?</label>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="optionsRadios"
                              id="optionsRadios2"
                              v-model="card.status"
                              value="true"
                            />
                            <span class="radio-icon"></span>
                            <span>Yes</span>
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="optionsRadios"
                              id="optionsRadios2"
                              v-model="card.status"
                              value="false"
                            />
                            <span class="radio-icon"></span>
                            <span>No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="v-modal-footer text-center pt-5">
              <button :disabled="edit_cash_save_api_inprogress"
                class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
                @click="cancel"
              >CANCEL</button>
              <button class="btn btn-secondary btn-smm text-uppercase ml-5" :disabled="edit_cash_save_api_inprogress" @click="save">SAVE
                  <half-circle-spinner :animation-duration="1000" :size="20" v-if="edit_cash_save_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import CardServices from './mixins/cards'
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: ['modal_name', 'card'],
  data() {
    return {
      cardprovider: 40,
      nameExists: false,   
      edit_cash_save_api_inprogress: false   
    }
  },
  mixins: [CardServices],
  components: {
    HalfCircleSpinner
  },
  methods: {
    cancel() {
      this.nameExists = false
      this.$modal.hide(this.modal_name)
    },
    async save() {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          // this.card.status = this.card.status == 'true' ? true : false
          this.edit_cash_save_api_inprogress = true;
          this.card.outlet_id = this.$route.query.outletId
          let response = await this.updateCard(this.card)
          this.$swal({
            title: 'Card Provider Updated Successfully',
            text: '',
            type: 'success'
          })
          this.$emit('cardEdited', true)
          this.cancel()
        this.edit_cash_save_api_inprogress = false;
        }
      } catch (reason) {
        this.nameExists = true
        // TODO: Handle Error
        this.edit_cash_save_api_inprogress = false;
      }
    }
  },
  created() {
    const dict = {
      custom: {
        cardName: {
          required: () => 'Card Name is Required',
          regex: () => 'Enter Valid Card Name'
        }
      }
    }
    this.$validator.localize('en', dict)
  }
}
</script>
