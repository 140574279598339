<template>
    <div class="page-layout blank brand-manage">
		<div class="pb-10">
			<h2 class="headerblock">
				<div class="row flex-nowrap">
					<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
						<div class="list-title" style="font-weight: 500;">
                            <a class="btn btn-yellow-checkout btn-smm" @click="$router.go(-1)">
                                Back
                                <i class="icon-arrow-left"></i>
                            </a>
							<!-- <button class="btn btn-checkout" > Back
                          	<i class="icon-arrow-left" style="font-size: 20px!important;"></i></button> -->
						</div>
					</div>
					<div v-if="allChangesWillBeSaved" class="col-lg-8 col-md-8 col-sm-8 col-xs-12" style="text-align: right;">
						<!-- <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;">RESET</a> -->
						<button type="submit" class="btn btn-secondary btn-smm">All Changes Will Be Saved</button>
					</div>
				</div>
			</h2>
            <div v-if="showIllustrator" class="row no-orders-row">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-orders-yet.svg" style="width:26%;margin-top:20px;">
                    </div>
                </div>
            </div>
            <div class="page-layout carded left-sidebar">
				<div class="page-content-wrapper">
					<div class="page-content">
						<div class="demo-content">
							<table class="table table-striped">
								<thead>
                                    <tr>
                                        <th style="width: 10%;"></th>
                                        <th style="width: 30%;">Category Name</th>
                                        <th style="width: 30%;">Category Type</th>
                                        <th>Description</th>
                                    </tr>
								</thead>
                                <draggable :list="categories" :element="'tbody'" @change="changeDisplayOrder($event)" style="cursor: grabbing!important;">
                                    <tr v-for="category in categories" :key="category._id" v-if="category.name">
                                        <td>
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/dragable.svg">
                                        </td>
                                        <td style="padding: 0px 12px!important;" :title="category.name">{{category.name}}</td>
                                        <td style="padding: 0px 12px!important;">{{category.type||'-'}}</td>
                                        <td style="padding: 0px 12px!important;width: 550px;overflow: hidden;text-overflow: ellipsis;display: inline-block;padding-top: 15px!important;" class="text-justify text-truncate d-block" >{{category.description||'-'}}</td>
                                    </tr>
                                </draggable>
							</table>
						</div>
					</div>
				</div>
            </div>
		</div>
    </div>
</template>
<style>
.btn-checkout{
    border-radius: 23px!important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffb333), to(#e29005))!important;
    background-image: linear-gradient(to bottom, #ffb333, #e29005)!important;
    color: #303031!important;
    padding: 0px 10px!important;
    margin: 0px;
    font-size: 14px!important;
    font-weight: 600!important;
    height: 3.4rem!important;
    line-height: 2.6rem!important;
    text-transform: capitalize!important;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)!important;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)!important;
}
</style>
<script>
import draggable from "vuedraggable";
import CategoryServices from "./mixins/categories.js";
export default {
    data(){
        return {
            allChangesWillBeSaved:false
        }
    },
    components: {
      draggable
    },
    mixins: [CategoryServices],
    created(){
        this.getCategories();
    },
    methods:{
        changeDisplayOrder(event) {
            this.allChangesWillBeSaved = true
        if (event.moved) {
          this.updateDisplayOrder(event.moved.element._id, event.moved.newIndex);
        }
	  },
    }
}
</script>