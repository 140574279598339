<template>
  <div style="padding: 14px;" class="w-100 view-cour-comp">
    <div class="text-right">
      <span @click="manageorderScreen()" class="pointer">
        <i class="icon icon-close pr-1" style="color:#ff7b7b!important;"></i>
      </span>
    </div>
    <div>
      <div class="top-view-order">
        <div class="page-header text-auto p-1 Feb pt-4 pb-3">
          <!-- HEADER CONTENT-->
          <div class="header-content d-flex font-600">
            <!-- HEADER TOP -->
            <div class="row w-100 text-white">
              <div class="col-md-4" style="border-right: 1px solid #fff;">
                <div class="d-flex pb-3">
                  <div
                    class="col"
                    v-if="orderdetails.table_number"
                  >Table No : {{orderdetails.table_number}}</div>
                  <div class="col" v-else>{{orderdetails.order_type || 'Take Away'}}</div>1
                  <div class="col text-right">
                    <i
                      class="icon icon-clock"
                      style="color:#fff!important;font-size: 15px!important;"
                    ></i>
                    {{orderdetails.start_time}}
                  </div>
                </div>
                <div class="d-flex pb-3">
                  <div class="col">Order ID : {{orderdetails.order_id}}</div>
                  <div class="col text-right">Sequence No : {{orderdetails.order_number}}</div>
                </div>
                <div class="d-flex pb-3">
                  <div class="col">Customer Name : {{orderdetails.customer_name}}</div>
                </div>
                <div class="d-flex pb-2 pt-3">
                  <div class="col" v-if="orderdetails.showPrepared">
                    <span class="text-white pr-3">Finish Order :</span>
                    <span v-if="orderdetails.canPrepare">
                      <el-switch
                        :disabled="!orderdetails.canPrepare"
                        v-model="orderdetails.isPrepared"
                        @change="prepareOrder(orderdetails.isPrepared)"
                      ></el-switch>
                    </span>
                    <span v-else>
                      <el-switch
                        v-model="orderdetails.isPrepared"
                        @change="prepareOrder(orderdetails.isPrepared)"
                      ></el-switch>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4" style="border-right: 1px solid #fff;">
                <div class="d-block pb-3">
                  <div class="d-flex">
                    <div class="col">Order Status</div>
                  </div>
                  <div class="view-vizard-big d-flex mt-6 mb-3">
                    <div class="stepwizard" v-if="orderdetails.order_status">
                      <div class="stepwizard-row setup-panel">
                        <div class="stepwizard-step" style="left:0% !important;">
                          <div
                            class="wizardSteps pointer"
                            :class="{ 'greenCircle': orderdetails.order_status.fire == 1,'redCircle': orderdetails.order_status.fire == 0,'orangeCircle': orderdetails.order_status.fire == 2}"
                          >
                            <a
                              class="btn-circle"
                              :class="{ 'greenCircle': orderdetails.order_status.fire == 1,'redCircle': orderdetails.order_status.fire == 0,'orangeCircle': orderdetails.order_status.fire == 2}"
                            >S</a>
                          </div>
                        </div>
                        <div class="stepwizard-step" style="left:41% !important;">
                          <div
                            class="wizardSteps pointer"
                            :class="{ 'greenCircle': orderdetails.order_status.prepared == 1,'redCircle': orderdetails.order_status.prepared == 0,'orangeCircle': orderdetails.order_status.prepared == 2}"
                          >
                            <a
                              class="btn-circle"
                              :class="{ 'greenCircle': orderdetails.order_status.prepared == 1,'redCircle': orderdetails.order_status.prepared == 0,'orangeCircle': orderdetails.order_status.prepared == 2}"
                            >F</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-block pb-3">
                  <div class="col pb-1">Display Status for :</div>
                  <div class="col">
                    <el-checkbox v-model="orderCourse_display" :disabled="!disable_course">
                      <span
                        style="color:#fff !important;font-size: 13px !important;"
                        class="font-weight-bold"
                      >Courses</span>
                    </el-checkbox>
                    <el-checkbox v-model="orderItem_display" :disabled="!disable_item">
                      <span
                        style="color:#fff !important;font-size: 13px !important;"
                        class="font-weight-bold"
                      >Items</span>
                    </el-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                Notes:
                <textarea class="text-area-manage" v-model="orderdetails.order_note" disabled></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-card">
        <table class="table">
          <thead>
            <tr>
              <th>ITEMS</th>
              <th class="text-center">QTY</th>
              <th>FINISHED?</th>
              <th class="text-center" style="width: 20%;">STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(course,index) in orderdetailsCourse">
              <tr style="background-color: #ddecff;height: 40px;" :key="index">
                <td
                  style="padding:0.2rem 1.2rem;color: #303031;"
                  class="font-600"
                >Course {{course.course}}</td>
                <td></td>
                <td
                  v-if="course.isHold"
                  class="font-600"
                  style="color: #303031;padding:0.2rem 1.2rem!important;"
                >
                  <el-tooltip content="On Hold" effect="dark" placement="top">
                    <span class="ml-3">
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg" />
                    </span>
                  </el-tooltip>
                </td>
                <td class="tdcart font-600" style="color: #303031;padding:0.2rem 1.2rem!important;">
                  <el-checkbox
                    :disabled="!course.isFired || course.isDelivered || course.isHold"
                    class="orderPrepared"
                    @input="coursePrepare(course.isPrepared,course.course)"
                    style="left: 8px;"
                    v-model="course.isPrepared"
                  ></el-checkbox>
                </td>
                <td style="padding:0.2rem 1.2rem;" class="text-center" v-if="orderCourse_display">
                  <div class="view-vizard-small" v-if="course.course_status">
                    <div class="stepwizard">
                      <div class="stepwizard-row setup-panel">
                        <div class="stepwizard-step" style="left:50% !important;">
                          <div
                            class="wizardSteps pointer"
                            :class="{ 'greenCircle': course.course_status.fire == 1,'redCircle': course.course_status.fire == 0,'orangeCircle': course.course_status.fire == 2}"
                          >
                            <a
                              class="btn-circle"
                              :class="{ 'greenCircle': course.course_status.fire == 1,'redCircle': course.course_status.fire == 0,'orangeCircle': course.course_status.fire == 2}"
                            >S</a>
                          </div>
                        </div>
                        <div class="stepwizard-step" style="left:50% !important;">
                          <div
                            class="wizardSteps pointer"
                            :class="{ 'greenCircle': course.course_status.prepared == 1,'redCircle': course.course_status.prepared == 0,'orangeCircle': course.course_status.prepared == 2}"
                          >
                            <a
                              class="btn-circle"
                              :class="{ 'greenCircle': course.course_status.prepared == 1,'redCircle': course.course_status.prepared == 0,'orangeCircle': course.course_status.prepared == 2}"
                            >F</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td v-else></td>
                <td></td>
              </tr>
              <template v-for="(item,index) in course.item_details">
                <tr style="height: 40px;" :key="index">
                  <td style="padding:0.4rem 1.2rem;" class="text-secondary font-600">
                    <el-tooltip
                      v-if="item.product_type == 'Non-Veg'"
                      content="Non-Veg"
                      placement="top"
                    >
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="margin-top: -4px;" />
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="item.product_type == 'Veg'"
                      content="Veg"
                      placement="top"
                    >
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="margin-top: -4px;" />
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="item.product_type == 'Egg'"
                      content="Egg"
                      placement="top"
                    >
                      <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="margin-top: -4px;" />
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="item.product_type == 'Pescatarian'"
                      content="Pescatarian"
                      placement="top"
                    >
                      <img style="width:14px;margin-top: -4px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                    </el-tooltip>
                    <span v-if="item.hasVariant">
                      <span v-if="item.variant_name != ''">{{item.product_name + '/' + item.variant_name}}</span>
                      <span v-else>{{item.product_name}}</span>
                      <span v-if="item.is_combo">
                        <el-popover placement="left-end" width="200" trigger="hover" popper-class="combo-popover">
                          <div v-for="(ic,index) in item.combo_details" :key="index">
                            <span>{{ic.product_name}} X {{ic.quantity}}</span>
                          </div>
                          <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                        </el-popover>
                      </span>
                    </span>
                    <span v-else>{{item.product_name}}
                      <span v-if="item.is_combo">
                        <el-popover placement="left-end" width="200" trigger="hover" popper-class="combo-popover">
                          <div v-for="(ic,index) in item.combo_details" :key="index">
                            <span>{{ic.product_name}} X {{ic.quantity}}</span>
                          </div>
                          <el-button slot="reference" style="background: transparent !important;padding:12px 0px !important;"><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg"></el-button>
                        </el-popover>
                      </span>
                    </span>
                    <br />
                    <span v-if="item.item_note !=''">
                      Note :
                      <span style="color:red!important;">{{item.item_note}}</span>
                    </span>
                  </td>
                  <td
                    class="text-secondary font-600 text-center"
                    style="padding:0.4rem 1.2rem;"
                  >{{item.selected_quantity}}</td>
                  <td v-if="item.isHold" style="padding:0.2rem 1.2rem;">
                    <el-tooltip content="On Hold" effect="dark" placement="top">
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg" />
                      </span>
                    </el-tooltip>
                  </td>
                  <td v-if="item.isHold" style="padding:0.2rem 1.2rem;">
                    <el-tooltip content="On Hold" effect="dark" placement="top">
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg" />
                      </span>
                    </el-tooltip>
                  </td>
                  <td v-if="item.unAvailable" style="padding:0.2rem 1.2rem;">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Item Unavailable"
                      placement="top-start"
                    >
                      <img
                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/item_unavailable.svg"
                        style="width: 34px!important;height: 19px!important;"
                      />
                    </el-tooltip>
                  </td>
                  <td class="tdcart" style="padding:0.4rem 1.2rem;" v-else>
                    <el-checkbox
                      class="orderPrepared"
                      style="left: 8px;"
                      @input="itemPreparded(item)"
                      v-model="item.isPrepared"
                      :disabled="!item.isFired || item.isDelivered || item.unAvailable || item.isHold"
                    ></el-checkbox>
                  </td>
                  <td style="padding:0.4rem 1.2rem;" class="text-center" v-if="orderItem_display">
                    <div class="view-vizard-small d-flex" v-if="item.item_status">
                      <div class="stepwizard">
                        <div class="stepwizard-row setup-panel">
                          <div class="stepwizard-step" style="left:50% !important;">
                            <div
                              class="wizardSteps pointer"
                              :class="{ 'greenCircle': item.isFired,'redCircle':!item.isFired}"
                            >
                              <a
                                class="btn-circle"
                                :class="{ 'greenCircle':item.isFired,'redCircle':!item.isFired}"
                              >S</a>
                            </div>
                          </div>
                          <div class="stepwizard-step" style="left:50% !important;">
                            <div
                              class="wizardSteps pointer"
                              :class="{ 'greenCircle': item.isPrepared,'redCircle':!item.isPrepared}"
                            >
                              <a
                                class="btn-circle"
                                :class="{ 'greenCircle': item.isPrepared,'redCircle':!item.isPrepared}"
                              >F</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-else></td>
                  <td style="padding:0.4rem 1.2rem;">
                    <el-dropdown>
                      <span class="el-dropdown-link pointer">
                        <i class="icon icon-dots-vertical iconHorizontal"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown" class="waiterDropdown">
                        <span @click="itemAvailable(item)" v-if="!item.isHold && !item.isDelivered">
                          <el-dropdown-item v-if="!item.unAvailable">Unavailable</el-dropdown-item>
                          <el-dropdown-item v-else>Available</el-dropdown-item>
                        </span>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
                <!-- <tr v-if="item.addons.length>0" :key="index+'ffg'">
                  <td colspan="9" style="padding: 0px!important;">
                    <div
                      class="b-r-7 pl-2 pt-1 pb-1"
                      style="background-color:rgba(210, 201, 201, 0.25) !important;"
                    >
                      <div class="text-green font-600">Add-On :</div>
                      <div class="d-flex flex-wrap mt-1">
                        <div
                          v-for="(addon,addon_index) in item.addons"
                          :key="addon_index"
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div class="col pl-0 mb-1">
                            <div class="d-flex ml-0 pl-0">
                              <el-tooltip
                                v-if="addon.type=='Non-Veg'"
                                content="Non-Veg"
                                placement="top"
                              >
                                <img class="mr-1" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                              </el-tooltip>
                              <el-tooltip
                                v-else-if="addon.type=='Veg'"
                                content="Veg"
                                placement="top"
                              >
                                <img class="mr-1" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                              </el-tooltip>
                              <el-tooltip
                                v-else-if="addon.type=='Egg'"
                                content="Egg"
                                placement="top"
                              >
                                <img class="mr-1" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                              </el-tooltip>
                              <el-tooltip
                                v-else-if="addon.type == 'Pescatarian'"
                                content="Pescatarian"
                                placement="top"
                              >
                                <img class="mr-1" style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                              </el-tooltip>
                              <span
                                class="font-weight-bold"
                                :class="{'ml-1':addon.type != ''}"
                              >{{addon.name}}</span>
                              <span class="text-green ml-1 font-weight-bold">X {{addon.quantity}}</span>
                              <span
                                class="text-green font-weight-bold"
                                v-if="addon_index != item.addons.length - 1"
                              >,</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr> -->
                <tr :key="index">
                    <td colspan="9" style="padding: 0px!important;">
                        <div class="b-r-7 pl-2 pt-2 pb-1 ml-1 mr-3 mb-2"
                            style="background-color:#fff !important;border-radius: 0px 0px 4px 4px;">
                              <div class="text-blue font-600 fs-14 py-1">
                                  <span>{{item.variant_name || ""}}</span>
                              </div>
                        </div>
                    </td>
                </tr>
                <template v-if="item.modifiers">
                    <tr v-if="item.modifiers.hasOwnProperty('for_secondary') || item.modifiers.hasOwnProperty('for_primary')" :key="index">
                        <td colspan="9" style="padding: 0px!important;">
                            <div class="b-r-7 pl-2 pt-2 pb-1 ml-1 mr-3 mb-2"
                                style="background-color:#fff !important;border-radius: 0px 0px 4px 4px;">
                                <div>
                                    <div v-if="item.modifiers.hasOwnProperty('for_secondary')" class="text-blue font-600 fs-14 py-1">
                                        <span>{{item.variant_name || ""}}</span>
                                    </div>
                                    <div class="bg-white mb-2" v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                        <span class="font-weight-bold">{{modifier.m_item_name}}</span>
                                        <span class="text-green ml-1 font-weight-bold" v-if="modifier.quantity > 0"> X {{modifier.quantity}}</span>
                                    </div>
                                    
                                    <div v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index"
                                        class="d-block w-100 mb-2 bg-white">
                                        <div class="text-blue font-600 fs-14 py-1">
                                            <span>{{modifier.modifier_name}}</span>
                                        </div>
                                        <div class="col pl-0 mb-1" v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                            <div class="d-flex ml-0 pl-0">
                                                <span class="font-weight-bold"
                                                    >{{modifier_item.m_item_name}}</span>
                                                <span class="text-green ml-1 font-weight-bold" v-if="modifier_item.quantity > 0"> X {{modifier_item.quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                <!-- <tr v-if="item.modifiers.length>0" :key="index">
                  <td colspan="9" style="padding: 0px!important;">
                      <div class="b-r-7 pl-2 pt-1 pb-1 ml-3 mr-3 mb-2"
                          style="background-color:#fff !important;border-radius: 12px;">
                          <div class="text-green font-600">Modifiers :</div>
                          <div class="d-flex flex-wrap mt-1">
                              <div v-for="(modifier,modifier_index) in item.modifiers" :key="modifier_index"
                                  class="d-flex justify-content-between align-items-center">
                                  <div class="col pl-0 mb-1">
                                      <div class="d-flex flex-wrap ml-0 pl-0">
                                          <span class="fw-bold text-capitalize" style="color: #000 !important;">{{modifier.modifier_name}}</span>
                                          <span class="ml-1" v-if="modifier.modifier_list.length > 0">-</span>
                                          <span v-for="(modifier_item, modifier_item_index) in modifier.modifier_list" :key="'modifier_item_index'+modifier_item_index" class="ml-1 d-flex align-items-end text-capitalize"
                                              >
                                              {{modifier.modifier_list[modifier_item_index]}}
                                              <span v-if="modifier.modifier_list.length > 1 && (modifier_item_index < (modifier.modifier_list.length - 1))">,</span>
                                              </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </td>
              </tr> -->
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div class="onholddiv" v-if="orderdetails.isOrderOnHold">
      <span>On Hold</span>
    </div>-->
  </div>
</template>
<script>
import ManageOrderService from './mixins/manage_order.js'
import orderServices from './mixins/orders'
export default {
  mixins: [ManageOrderService, orderServices],
  data() {
    return {
      hold_order: '',
      hold_course: '',
      orderdetails: '',
      orderdetailsCourse: '',
      showPreparedCard: false,
      isPrepared: false,
      isDelivered: false,
      isHold: false,
      isFired: false,
      orderType: null,
      hold_Status: false,
      orderCourse_display: false,
      orderItem_display: false,
      disable_course: false,
      disable_item: false
    }
  },
  methods: {
    manageorderScreen() {
      if (this.orderdetails.table_number) {
        this.orderType = 'All Orders'
      } else {
        this.orderType = 'All Orders'
      }
      this.$router.push({
        name: 'ManageOrder',
        params: {
          orderType: this.orderType
        }
      })
      this.$emit('getTablesForChef')
    },
    maintainOrderState(res) {
      this.orderdetails = res.response.order_details
      this.orderdetailsCourse = res.response.course_details
      for (var course_index in this.orderdetailsCourse) {
        for (var item_index in this.orderdetailsCourse[course_index]
          .item_details) {
          if (
            this.orderdetailsCourse[course_index].item_details[item_index]
              .item_status == 'prepared'
          ) {
            this.orderdetailsCourse[course_index].item_details[item_index]
              .preparedCount++
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
          } else if (
            this.orderdetailsCourse[course_index].item_details[item_index]
              .item_status == 'placed'
          ) {
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
          } else if (
            this.orderdetailsCourse[course_index].item_details[item_index]
              .item_status == 'preparing'
          ) {
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
          } else if (
            this.orderdetailsCourse[course_index].item_details[item_index]
              .item_status == 'delivered'
          ) {
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = true
          } else if (
            this.orderdetailsCourse[course_index].item_details[item_index]
              .item_status == 'hold'
          ) {
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = false
          } else if (
            this.orderdetailsCourse[course_index].item_details[item_index]
              .item_status == 'unavailable'
          ) {
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = true
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = true
          } else {
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isDelivered = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isPrepared = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isHold = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].unAvailable = false
            this.orderdetailsCourse[course_index].item_details[
              item_index
            ].isFired = false
          }
        }
      }
    },
    async getOrderById() {
      try {
        let params = {
          order_id: this.$route.params.id,
          target: 'WEB'
        }
        if (params.order_id) {
          let res = await this.getorderDetails(params)
          this.stateMaintain(res)
          this.maintainOrderState(res)
        }
      } catch (reason) {}
    },
    stateMaintain(res) {
      if (res.response.order_details.display_status) {
        this.disable_course = res.response.order_details.display_status.courses
        this.disable_item = res.response.order_details.display_status.items
        this.orderCourse_display =
          res.response.order_details.display_status.courses
        this.orderItem_display = res.response.order_details.display_status.items
      }
    },
    // Order Operations
    async prepareOrder(isprepared) {
      try {
        let params = {
          order_id: this.$route.params.id,
          isPrepared: isprepared,
          get_details: true
        }
        let res = await this.orderPrepareApi(params)
        if (res.status_id == 1) {
          this.maintainOrderState(res)
          this.$toasted.global.success(res.response.message)
        }
      } catch (reason) {
        this.$toasted.global.error(reason)
      }
    },
    // Based on Course Operations
    async coursePrepare(prepareCourse, courseno) {
      try {
        let params = {
          order_id: this.$route.params.id,
          isPrepared: prepareCourse,
          course: courseno
        }
        let res = await this.coursePrepareApi(params)
        if (res.status_id == 1) {
          this.maintainOrderState(res)
          this.$toasted.global.success(res.response.message)
        }
      } catch (reason) {
        this.orderdetailsCourse.forEach((element, index) => {
          if (element.course == courseno) {
            this.orderdetailsCourse[index].isPrepared = false
          }
        })
        this.$toasted.global.error(reason)
      }
    },
    // Based on Item Operations
    async itemPreparded(item) {
      try {
        let params = {
          isPrepared: item.isPrepared,
          item_id: item.item_id,
          course: item.course,
          order_id: this.$route.params.id
        }
        let res = await this.itemPreparedApi(params)
        if (res.status_id == 1) {
          this.maintainOrderState(res)
          this.$toasted.global.success(res.response.message)
        }
      } catch (reason) {
        this.$toasted.global.error(reason)
      }
    },
    async itemAvailable(item) {
      if (item.unAvailable == false) {
        item.unAvailable = true
      } else {
        item.unAvailable = false
      }
      try {
        let params = {
          unAvailable: item.unAvailable,
          item_id: item.item_id,
          course: item.course,
          order_id: this.$route.params.id
        }
        let res = await this.itemAvailableApi(params)
        if (res.status_id == 1) {
          this.maintainOrderState(res)
          this.$toasted.global.success(res.response.message)
        }
      } catch (reason) {
        this.$toasted.global.error(reason)
      }
    }
  },
  mounted() {
    this.getOrderById()
  }
}
</script>

<style scoped>
.top-view-order {
  border-radius: 5px;
  background-image: linear-gradient(278deg, #3a3985 36%, #00448b 74%);
}
.text-area-manage {
  border-radius: 5px;
  background: transparent;
  color: red;
  border: 1px solid #fff;
  width: 100%;
  padding: 5px;
  height: 140px;
}
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
.page-content-card {
  border-radius: 12px;
  border: solid 2.5px #f6f6f6;
  background-color: #fff;
  margin-top: 20px;
  font-weight: 600;
  margin-bottom: 25px;
}
.addon-view {
  border-radius: 6px;
  border: 2px solid #eee;
}
.view-vizard-big {
  background-color: #fff;
  border-radius: 35px;
  padding: 8px;
}
.view-vizard-big .stepwizard-step {
  left: 8% !important;
}
.view-vizard-big .btn-circle {
  line-height: 2.7 !important;
}
.view-vizard-small .stepwizard-step {
  left: 0% !important;
}
.view-vizard-small .wizardSteps {
  width: 26px !important;
  height: 26px !important;
}
.view-vizard-small .stepwizard {
  width: 60% !important;
}
.view-vizard-small .stepwizard-row:before {
  width: 60% !important;
  top: 14px !important;
  left: 50% !important;
}
.view-vizard-small .btn-circle {
  line-height: 2 !important;
}
.wizardSteps {
  border: none !important;
}
.greenCircle {
  background: #1a9347 !important;
  color: #fff !important;
}
.redCircle {
  background: #e82828 !important;
  color: #fff !important;
}
.orangeCircle {
  background: #f5a623 !important;
  color: #fff !important;
}
.btn-circle {
  padding: 5px 0 !important;
  line-height: 2.6 !important;
}
.whiteSpace {
  white-space: nowrap;
}
.pointerEventsNone {
  pointer-events: none !important;
}
.view-cour-comp .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #46d830 !important;
  border-color: #46d830 !important;
  border-radius: 50% !important;
}
.view-cour-comp .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #46d830 !important;
  border-color: #46d830 !important;
  border-radius: 50% !important;
}
.view-cour-comp .el-checkbox__inner {
  border-radius: 50% !important;
}
.view-vizard-big {
  background-color: #fff;
  border-radius: 35px;
  padding: 8px;
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard-row:before {
  top: 16px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.wizardSteps {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.view-vizard-big .stepwizard-step {
  left: 8% !important;
}
.view-vizard-big .btn-circle {
  line-height: 2.7 !important;
}
.view-vizard-small .stepwizard-step {
  left: 0% !important;
}
.view-vizard-small .wizardSteps {
  width: 26px !important;
  height: 26px !important;
}
.view-vizard-small .btn-circle {
  line-height: 2 !important;
}
.wizardSteps {
  border: none !important;
}
/* .onholddiv {
  position: absolute;
  width: 97.5%;
  text-align: center;
  height: 40px;
  background-color: #606266;
  opacity: 1;
  top: 40%;
  right: 0px;
  left: 0px;
  margin-left: 15px;
  z-index: 999 !important;
  pointer-events: all !important;
  line-height: 2;
}
.onholddiv span {
  color: #fff !important;
    font-size: 16px !important;
    font-weight: 600;
    padding-top: 12px!important;
    line-height: 40px;
}
.OnHoldClass {
  opacity: 0.7 !important;
  pointer-events: none !important;
} */
</style>