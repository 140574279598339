<template>
  <div>
    <modal :name="modal_name" transition="nice-modal-fade" class="edit-product">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">EDIT {{edit_product_details.name}}</span>
        </div>
        <div class="v-modal-body pr-0">
          <div class="v-modal-layout pt-3" style="padding-right: 18px;max-height: 410px !important; overflow-x: hidden !important;overflow-y: scroll !important">
            <div class="row first-middle-input flex-nowrap">
              <div class="col-lg-3">
                <div class="profile-left text-center">
                  <div class="profile-image">
                    <my-upload
                      field="img"
                      @crop-success="cropSuccess"
                      @crop-upload-success="cropUploadSuccess"
                      @crop-upload-fail="cropUploadFail"
                      v-model="show"
                      :width="100"
                      langType="en"
                      :height="100"
                      :url="$serverURL + '/products/upload_file'"
                      :params="params"
                      :headers="headers"
                      img-format="png"
                    ></my-upload>
                    <img
                      v-if="edit_product_details.image"
                      :src="edit_product_details.image"
                      style="width: 90px;"
                    />
                    <img v-else :src="imgDataUrl" style="width: 90px;" />
                  </div>
                  <div class="mb-4 mt-4 profile-button">
                    <a
                      class="btn btn-secondary btn-smm"
                      @click="toggleShow"
                      v-html="picture_message"
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">

                <div class="row">
                  <div class="col-sm-12">
                    <h6
                      style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;"
                      class="pt-3"
                    >Product Information</h6>
                     <div class="row">
                      <div class="col-sm-12">
                        <label class="fs-14" style="color:#00448b;">
                            Status
                            <sup>★</sup>
                          </label>
                        <div class="pb-2 pt-1">
                          <el-switch v-model="edit_product_details.status" active-text="Active" inactive-text="Inactive"></el-switch>
                        </div>
                        <!-- <div class="form-group contactGroup">
                          <label class="fs-14" style="color:#00448b;">
                            Status
                            <sup>★</sup>
                          </label>
                          <select
                            class="form-control"
                            style="width:100%;"
                            v-model="edit_product_details.status"
                            v-on:change="changestatus(edit_product_details.status, edit_product_details._id)"
                            v-validate="'required'"
                            required
                            name="product_status"
                          >
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                          <span
                            class="invalid-feedback-form text-danger fs-13"
                            v-show="errors.has('product_status')"
                          >{{errors.first('product_status')}}</span>
                        </div> -->
                      </div>
                    </div>
                    <div class="form-group contactGroup">
                      <input
                        @input="exists=false"
                        :maxlength="pName"
                        name="product_name"
                        v-validate="'required'"
                        class="inputContact"
                        type="text"
                        v-model="edit_product_details.name"
                        required
                      />
                      <span class="highlightContact"></span>
                      <span class="barContact"></span>
                      <label class="labelContact">Product Name<sup>★</sup></label>
                      <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('product_name')"
                        style="display: inline !important"
                      >{{errors.first('product_name')}}</span>
                      <div
                        v-show="exists && edit_product_details.name.trim()!=''"
                        class="text-danger"
                      >Product Name Already Exists</div>
                      <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                      >{{pName - edit_product_details.name.length}} / {{pName}}</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group contactGroup" style="margin-top: -5px;">
                      <input
                        class="inputContact"
                        :maxlength="pDesc"
                        type="text"
                        v-model="edit_product_details.description"
                        required
                      />
                      <span class="highlightContact"></span>
                      <span class="barContact"></span>
                      <label class="labelContact">Description</label>
                      <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                      >{{pDesc - edit_product_details.description.length}} / {{pDesc}}</p>
                    </div>
              </div>
            </div>
            <div class="row mt-4" v-if="!variant">
              <div class="col-sm-6">
                <div class="form-group contactGroup" style="margin-top:-15px;width: 100%;">
                  <input
                    class="inputContact"
                    name="product_price"
                    v-validate="'required|min_value:0|max_value:9999999999'"
                    type="number"
                    v-model="edit_product_details.price"
                    required
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Price<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('product_price')"
                    style="display: inline !important"
                  >{{errors.first('product_price')}}</span>
                  <!-- <p
                    class="pull-right text-right text-grey font-600 fs-12 pt-1"
                  >{{pPrice - edit_product_details.price.toString().length}} / {{pPrice}}</p> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group contactGroup" style="margin-top: -15px;">
                  <input
                    name="product_quantity"
                    class="inputContact"
                    type="text"
                    v-model="edit_product_details.quantity"
                    v-validate="'required|numeric|min_value:0|max_value:9999999999'"
                    required
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Quantity<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('product_quantity')"
                    style="display: inline !important"
                  >{{errors.first('product_quantity')}}</span>
                  <!-- <p
                    class="pull-right text-right text-grey font-600 fs-12 pt-1"
                  >{{pQuantity - edit_product_details.quantity.toString().length}} / {{pQuantity}}</p> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group contactGroup" style="margin-top: 0px;">
                  <input
                    @input="idexists=false"
                    :maxlength="pId"
                    name="product_id"
                    v-validate="'required'"
                    required
                    class="inputContact"
                    type="text"
                    v-model="edit_product_details.product_id"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">
                    Product ID
                    <sup>★</sup>
                  </label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('product_id')"
                    style="display: inline !important"
                  >{{errors.first('product_id')}}</span>
                  <div v-show="idexists" class="text-danger fs-13">Product ID Already Exists</div>
                  <p
                    class="pull-right text-right text-grey font-600 fs-12 pt-1"
                  >{{pId - edit_product_details.product_id.length}} / {{pId}}</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group contactGroup" style="margin-top: 0px;">
                  <input
                    @input="skuexists=false"
                    :maxlength="sku"
                    class="inputContact"
                    type="text"
                    name="product_sku"
                    v-validate="'required'"
                    required
                    v-model="edit_product_details.stock_keeping_unit"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Stock Keeping Unit (SKU)<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('product_sku')"
                    style="display: inline !important"
                  >{{errors.first('product_sku')}}</span>
                  <div
                    v-show="skuexists"
                    class="text-danger fs-13"
                  >Stock Keeping Unit Product Already Exists</div>
                  <!-- <p
                    class="pull-right text-right text-grey font-600 fs-12 pt-1"
                  >{{sku - edit_product_details.stock_keeping_unit.toString().length}} / {{sku}}</p> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="fs-14" style="color: rgb(13, 132, 242);">Brand</label>
                  <v-select
                    class="filterName clearNone"
                    label="name"
                    value="None"
                    placeholder="Brands Search"
                    :filterable="true"
                    :options="filter_data.brands"
                    v-model="edit_product_details.brand"
                    @search="get_brands_for_products"
                  >
                    <span slot="no-options">
                      No matches found. Please create new Brand
                      <a
                        class="text-secondary p-0 m-0 w-0"
                        style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;"
                        @click="showbrandproduct()"
                      >Here</a>
                    </span>
                  </v-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="fs-14" style="color:#00448b;">
                    Category
                    <sup>★</sup>
                  </label>
                  <v-select
                    class="filterName clearNone"
                    label="name"
                    value="None"
                    placeholder="Categories Search"
                    :filterable="true"
                    :options="filter_data.categories"
                    v-model="edit_product_details.category"
                    @search="get_categories_for_products"
                  >
                    <span slot="no-options">
                      No matches found. Please create new Category
                      <a
                        class="text-secondary p-0 m-0 w-0"
                        style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;"
                        @click="createcategory()"
                      >Here</a>
                    </span>
                  </v-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group contactGroup">
                  <label class="fs-14" style="color:#00448b;">Tags</label>
                  <vue-tags-input
                    v-model="edit_product_details.tag"
                    name="tags"
                    id="tags"
                    :tags="edit_product_details.tags"
                    @tags-changed="newTags => tags = newTags"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="tax_name.length && tax_percentage.length">
              <div class="col-sm-6">
                <div class="form-group contactGroup">
                  <input
                    class="inputContact"
                    type="text"
                    id="editAddTaxGroup"
                    value="General Tax"
                    required
                    v-model="tax_name"
                    name="tax_name"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Tax Name</label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('tax_name')"
                  >Tax Name is Required</span>
                </div>
              </div>
              <div class="col-sm-5">
                <div class="form-group contactGroup">
                  <input
                    class="inputContact"
                    type="number"
                    min="0"
                    step="any"
                    id="editAddTaxGroup"
                    value="10%"
                    required
                    v-model="tax_percentage"
                    name="tax_percentage"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Tax Percentage</label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('tax_percentage')"
                  >Tax Percentage is Required</span>
                </div>
              </div>
              <div class="col-sm-1 mt-8">
                <span
                  class="icon icon-close pointer"
                  style="color:red;font-size:18px"
                  @click="editRemoveTax(index)"
                ></span>
              </div>
            </div>
            <div>
              <h6 class="pt-3" style="font-weight: bold;color: #303031;width: 100%;">Variants</h6>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salutation"
                    :value="true"
                    v-model="variant"
                  />
                  <span class="radio-icon"></span>
                  <span
                    class="form-check-description"
                    style="color: #4e5764;font-size: 12px;line-height: 1.25;"
                  >Product With Variants</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salutation"
                    :value="false"
                    v-model="variant"
                  />
                  <span class="radio-icon"></span>
                  <span
                    class="form-check-description"
                    style="color: #4e5764;font-size: 12px;line-height: 1.25;"
                  >Product With No Variants</span>
                </label>
              </div>
            </div>
            <div
              class="row mt-4"
              v-for="(item,index) in edit_product_details.variants"
              :key="index"
            >
              <div class="col-sm-4">
                <div class="form-group contactGroup">
                  <input
                    class="inputContact"
                    :key="index"
                    type="text"
                    v-validate="'required'"
                    required
                    :name="'variant_name' + index"
                    v-model="item.variant_name"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Name<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('variant_name'+ index)"
                    style="display:inline !important;"
                  >Required</span>
                  <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{pVname - item.variant_name.length}} / {{pVname}}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group contactGroup">
                  <input
                    class="inputContact"
                    v-validate="'required|numeric|min_value:0'"
                    required
                    :name="'variant_quantity' + index"
                    v-model="item.variant_quantity"
                    type="text"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Quantity<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('variant_quantity'+ index)"
                  >Required</span>
                </div>
              </div>
              <div class="col-sm-3 pt-4">
                <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                  <div class="form-group contactGroup" style="margin-top:-24px;width: 100%;">
                    <input
                      class="inputContact"
                      v-validate="'required'"
                      type="number"
                      required
                      :name="'variant_price' + index"
                      step="any"
                      v-model="item.variant_price"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Price<sup>★</sup></label>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('variant_price'+ index)"
                    >Required</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-1 mt-7">
                <span
                  class="icon icon-close pointer"
                  style="color:red;font-size:18px;position: relative;right: 12px;"
                  @click="editRemoveVariants(index)"
                ></span>
              </div>
            </div>
            <div class v-if="variant">
              <a
                class="nav-link text-secondary"
                @click="editAddVariants()"
                style="height: 24px;padding: 0 12px 0px 0px;margin-top: 12px;line-height: 18px !important;"
              >
                <i
                  class="icon icon-plus-circle"
                  style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 6px;color: #00448b;"
                ></i>
                <span>Add Variant</span>
              </a>
            </div>
            <div
              class="row mt-2"
              v-if="variant_name.length && variant_quantity.length && variant_price.length && variant"
            >
              <div class="col-sm-4">
                <div class="form-group contactGroup">
                  <input
                    class="inputContact"
                    :maxlength="pVname"
                    id="editAddVariant"
                    type="text"
                    v-model="variant_name"
                    v-validate="'required'"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Name<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('variant_name')"
                    style="display:inline !important;"
                  >Required</span>
                  <p
                    class="pull-right text-right text-grey font-600 fs-12 pt-1"
                  >{{pVname - variant_name.length}} / {{pVname}}</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group contactGroup">
                  <input
                    class="inputContact"
                    :maxlength="pQuantity"
                    id="editAddVariant"
                    min="0"
                    type="number"
                    v-model="variant_quantity"
                  />
                  <span class="highlightContact"></span>
                  <span class="barContact"></span>
                  <label class="labelContact">Quantity<sup>★</sup></label>
                  <span
                    class="invalid-feedback-form text-danger fs-13"
                    v-show="errors.has('variant_quantity')"
                  >Variant Quantity is Required</span>
                  <p
                    class="pull-right text-right text-grey font-600 fs-12 pt-1"
                  >{{pQuantity - variant_quantity}} / {{pQuantity}}</p>
                </div>
              </div>
              <div class="col-sm-3 pt-4">
                <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                  <div class="form-group contactGroup" style="margin-top:-24px;width: 100%;">
                    <input
                      class="inputContact"
                      :maxlength="pId"
                      id="editAddVariant"
                      min="0"
                      step="any"
                      type="number"
                      v-model="variant_price"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Price<sup>★</sup></label>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('variant_price')"
                    >Variant Price is Required</span>
                    <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{pId - variant_price}} / {{pId}}</p>
                  </div>
                  <!-- <div class="col-sm-1 mt-2">
                    <span
                      class="icon icon-close pointer"
                      style="color:red;font-size:18px;position: relative;right: 12px;"
                      @click="editRemoveVariants()"
                    ></span>
                  </div> -->
                </div>
              </div>
              <!-- <div class="d-block">
                <h6 class="fs-13 mt-5 ml-5">Variant Type</h6>
                <div class="row ml-5 mb-4">
                  <div class="d-flex mt-4">
                    <span class="mt-5">
                      <el-checkbox class="borderColor"></el-checkbox>
                    </span>
                    <el-tooltip class="item" effect="dark" content="Vegetarian" placement="bottom">
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/posv2/vegetarian.svg" class="productType" />
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="d-flex mt-4 ml-6">
                    <span class="mt-5">
                      <el-checkbox class="borderColor"></el-checkbox>
                    </span>
                    <el-tooltip class="item" effect="dark" content="Vegan" placement="bottom">
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/posv2/vegan.svg" class="productType" />
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="d-flex mt-4 ml-6">
                    <span class="mt-5">
                      <el-checkbox class="borderColor"></el-checkbox>
                    </span>
                    <el-tooltip class="item" effect="dark" content="Spicy" placement="bottom">
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/posv2/spicy.svg" class="productType" />
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="d-flex mt-4 ml-6">
                    <span class="mt-5">
                      <el-checkbox class="borderColor"></el-checkbox>
                    </span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Signature Dish"
                      placement="bottom"
                    >
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/posv2/dish.svg" class="productType" />
                      </span>
                    </el-tooltip>
                  </div>
                  <div class="d-flex mt-4 ml-6">
                    <span class="mt-5">
                      <el-checkbox class="borderColor"></el-checkbox>
                    </span>
                    <el-tooltip class="item" effect="dark" content="Halal" placement="bottom">
                      <span class="ml-3">
                        <img src="https://cdn.digicollect.com/cdn/posv2/halal.svg" class="productType" />
                      </span>
                    </el-tooltip>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="v-modal-footer text-center pt-5">
            <a
              class="btn btn-link btn-smm btn-no-border-blue text-uppercase ml-1"
              @click="cancelCategoryEditProduct()"
            >CANCEL</a>
            <a
              class="btn btn-secondary btn-smm text-uppercase"
              @click="category_edited_product(edit_product_details)"
            >SAVE</a>
          </div>
        </div>
      </div>
    </modal>
    <div>
      <create-brand @close="hideCreateBrand" modal_brand="brand-create"></create-brand>
    </div>
    <div>
      <create-category
        @createCategory="createCategory"
        :bool="categoryBool"
        :category="category"
        modal_category="category-create"
      ></create-category>
    </div>
    <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue'
import myUpload from 'vue-image-crop-upload'

import UploadPicture from './UploadPicture'
import CreateBrand from './CreateBrand'
import CreateCategory from './CreateCategory'

import BrandServices from './mixins/brands'
import CategoryServices from './mixins/categories'
import productServices from './mixins/products'
import { EventBus } from './eventBus/nav-bar-event'

export default {
  props: ['modal_name'],
  data() {
    return {
      show: false,
      imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg', // the datebase64 url of created image
      product_details: {},
      disabled: true,
      image: '',
      picture_message: 'Change Image',
      modal_msg: '',
      variants_list: [],
      gallerylist: [],
      name: '',
      validPhone: true,
      uploadedImage: '',
      pName: 100,
      pDesc: 1000,
      pId: 10,
      sku: 10,
      pBrand: 30,
      pCategory: 45,
      pTags: 30,
      pQuantity: 10,
      pPrice: 10,
      pVname: 50,
      singleBrand: null,
      edit_product_details: {
        _id: '',
        name: '',
        image: '',
        description: '',
        price: 0,
        quantity: 0,
        product_id: '',
        stock_keeping_unit: '',
        brand: '',
        category: '',
        status: false,
        tag: '',
        tags: [],
        variants: []
      },
      tax_name: '',
      tax_percentage: '0'
    }
  },
  components: {
    'my-upload': myUpload,
    UploadPicture,
    CreateBrand,
    CreateCategory,
    SweetModal
  },
  mixins: [productServices, BrandServices, CategoryServices],
  methods: {
    hideCreateBrand() {
      this.$modal.show(this.modal_name)
      this.$modal.hide('brand-create')
    },
    hideeditproduct() {
      this.$modal.hide('product-edit')
    },
    handlePictureCardPreview(file) {
      if (file.url) {
        this.image = file.url
        this.dialogVisible = true
      } else {
        this.image = file.response.url
        this.dialogVisible = true
      }
    },
    toggleShow() {
      this.show = !this.show
    },
    cropSuccess(imgDataUrl, field) {
      this.uploadedImage = imgDataUrl
      this.picture_message = 'Change Image'
    },
    cropUploadSuccess(jsonData, field) {
      this.edit_product_details.image = jsonData
      this.picture_message = 'Change Image'
    },
    cropUploadFail(status, field) {
      // TODO:
    },
    showUploadedImages(gallery) {
      return 'https://cdn.digicollect.com/cdn/dcpos/pics/47521fc0-f9d4-11e8-8890-f23c91b0d497/8525bb7f-ff8c-41d1-bf45-0cdd93cbbd3e.png'
    },
    showproductvariants(product_id) {
      EventBus.$emit('ProductVariant', product_id)
      this.$modal.show('product_info')
    },
    showbrandproduct() {
      this.$modal.show('brand-create')
      this.$modal.hide(this.modal_name)
    },
    createcategory() {
      this.$modal.show('create-category')
      EventBus.$emit('Show Category', false)
    }
  },
  mounted() {
    const dict = {
      custom: {
        product_price: {
          required: () => 'Price is Required and Must be Numeric',
          min_value: () => 'Price should be minimum 0',
          max_value: () => 'Price should be maximum 10 digits'
        },
        product_quantity: {
          required: () => 'Quantity is Required and Must be Numeric',
          min_value: () => 'Quantity should be minimum 0',
          max_value: () => 'Quantity should be maximum 10 digits'
        },
        product_sku: {
          required: () => 'SKU is Required and Must be Numeric',
          min_value: () => 'SKU should be minimum 0',
          max_value: () => 'SKU should be maximum 10 digits'
        }
      }
    }
    this.$validator.localize('en', dict)
    EventBus.$on('editProductDetails', product_id => {
      this.$http
        .post('products/get_product_by_id', { _id: product_id, edit: true })
        .then(res => {
          if (res.data.status_id == 1) {
            this.edit_product_details = res.data.response.result
            this.variant = res.data.response.result.variant
            this.tags = res.data.response.result.tags
          }
        })
    })
    EventBus.$on('categoryCreated', res => {
      this.$modal.show(this.modal_name)
    })
    EventBus.$on('createdCategoryId', newId => {
      this.getCategory(newId)
    })
    EventBus.$on('createdBrandId', async newId => {
      try {
        let res = await this.brandsGetId(newId)
        this.singleBrand = res.message
      } catch (reason) {
        // TODO: handle reason
      }
    })
  },
  destroyed() {
    EventBus.$off('editProductDetails')
    EventBus.$off('createdCategoryId')
    EventBus.$off('createdBrandId')
  },
  watch: {
    singleBrand(newBrand, oldBrand) {
      this.edit_product_details.brand = newBrand
    },
    singleCategory(newCategory, oldCategory) {
      this.edit_product_details.category = newCategory
    }
  }
}
</script>
<style>
.edit-product .v-modal-layout {
  max-height: 383px !important;
}
</style>
