<template>
    <modal name="brand-edit" transition="nice-modal-fade" class="final-modal">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title"
                    >EDIT {{ brand.name }}</span
                >
            </div>
            <div class="v-modal-body">
                <div class="v-modal-layout">
                    <form>
                        <div class="form-group mt-0 contactGroup">
                            <input
                                class="inputContact"
                                v-validate="'required'"
                                type="text"
                                v-model="brand.name"
                                name="name"
                                :maxlength="limit.name"
                                id="brand_name_edit_brand"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">
                                Brand Name
                                <sup>★</sup>
                            </label>
                            <span
                                class="invalid-feedback-form"
                                v-show="errors.has('name')"
                                >{{ errors.first('name') }}</span
                            >
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >
                                {{ limit.name - brand.name.length }} /
                                {{ limit.name }}
                            </p>
                        </div>
                        <div class="form-group contactGroup">
                            <input
                                class="inputContact"
                                v-validate="'required'"
                                type="text"
                                v-model="brand.supplier"
                                name="supplier"
                                id="brand_name_supplier_brand"
                                :maxlength="limit.supplier"
                            />
                            <span class="highlightContact"></span>
                            <span class="barContact"></span>
                            <label class="labelContact">
                                Supplier
                                <sup>★</sup>
                            </label>
                            <span
                                class="invalid-feedback-form"
                                v-show="errors.has('supplier')"
                                >{{ errors.first('supplier') }}</span
                            >
                            <p
                                class="pull-right text-right text-grey font-600 fs-12 pt-1"
                            >
                                {{ limit.supplier - brand.supplier.length }} /
                                {{ limit.supplier }}
                            </p>
                        </div>
                    </form>
                </div>
                <div class="v-modal-footer text-center pt-5">
                    <a
                        class="btn btn-link btn-smm btn-no-border-blue text-uppercase ml-1"
                        @click="cancel"
                        >CANCEL</a
                    >
                    <button
                        class="btn btn-secondary btn-smm text-uppercase"
                        :disabled="editing"
                        @click="save"
                    >
                        SAVE
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import brandServices from './mixins/brands'

function initialState() {
    return {
        limit: {
            name: 45,
            supplier: 60
        },
        editing: false
    }
}

export default {
    props: ['brand'],
    data() {
        return initialState()
    },
    mixins: [brandServices],
    methods: {
        cancel() {
            this.editing = false
            this.$emit('close', false)
        },
        reset() {
            Object.assign(this.$data, initialState())
        },
        async save() {
            try {
                this.editing = true
                let result = await this.$validator.validate()
                if (result) {
                    let params = this.brand
                    let res = await this.brandsUpdate(params)
                    // this.modal_msg = 'Brand Updated Successfully'
                    // this.$refs.success_modal.open()
                    this.$swal(
                        'Updated!',
                        'Brand Updated Successfully.',
                        'success'
                    )
                    this.reset()
                    this.$emit('close', true)
                    this.editing = false
                } else {
                    this.editing = false
                }
            } catch (reason) {
                this.modal_msg = reason
                this.$swal({
                    title: this.modal_msg,
                    text: '',
                    type: 'warning'
                })
                this.editing = false
            }
        }
    },
    created() {
        const dict = {
            custom: {
                name: {
                    required: () => 'Brand Name is Required'
                },
                supplier: {
                    required: () => 'Supplier Name is Required'
                }
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
