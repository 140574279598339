<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="280"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 27em !important;">
                <div  class="v-modal-body d-flex align-items-center justify-content-center" style="font-size: 16px">
                    <h4 class="black-text fs-2qx fw-bold text-center my-7">
                        Please do not click back button or close or refresh page
                    </h4>
                </div>
            </div>  
        </modal>
    </div>
</template>

<script>

export default {
    props: ["modal_name"],
    mixins: [],
    data() {
        return {
        }
    },
    methods: {
    }, 
    mounted() {
    },
    watch: {
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
}
</script>
<style>
.payment-footer{
    background-image: linear-gradient(to right, #037ae0, #00448b 101%)!important;
    color: #fff;
    width: 100%;
    text-align: -webkit-center;
    display:flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: center !important;
    padding: 15px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.new-modal-small.v--modal-overlay .v--modal-box.v--modal {
    height: 350px !important;
}
</style>