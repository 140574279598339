<template>
	<div class="page-layout blank employees-manage">
		<div class="pb-10">
			<h2 class="headerblock">
				<div class="row">
					<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
						<router-link style="color: transparent!important;"
							:disabled="!$routerHistory.hasPrevious()"
							:to="{ path: $routerHistory.previous().path }">
							<el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
							<button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
								<i class="icons icon-arrow-left"></i>
							</button>
						</el-tooltip>
						</router-link>
						<router-link style="color: transparent!important;"
							:disabled="!$routerHistory.hasForward()"
							:to="{ path: $routerHistory.next().path }">
						<el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
							<button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
								<i class="icons icon-arrow-right"></i>
							</button>
							</el-tooltip>
						</router-link>
					</div>
					<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
						<div class="breadcome-heading d-xl-inline">
							<div class="form-group formsearch has-warning has-feedback">
								<input type="text" class="form-control textremove holder" :maxlength="searchcustomer"
									v-model="filterJSON.search_key" @input="getemployees()" id="inputWarning2"
									aria-describedby="inputWarning2Status" placeholder="Search...">
								<span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true" v-if="hide_cross"></span>
								<span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true" style="color:red;" v-if="show_cross"></span>
							</div>
						</div>
						<!-- <el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
							<a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)" style="margin-top: 2px;padding-top: 2px;">
								<i class="icons icon-arrow-left"></i>
							</a>
						</el-tooltip> -->
						<!-- <router-link
							:disabled="!$routerHistory.hasForward()"
							:to="{ path: $routerHistory.next().path }">
							<el-tooltip class="item pull-right" effect="dark" content="Click to Go Forward" placement="top">
								<button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
									<i class="icons icon-arrow-right"></i>
								</button>
							</el-tooltip>
						</router-link>
						<router-link
							:disabled="!$routerHistory.hasPrevious()"
							:to="{ path: $routerHistory.previous().path }">
							<el-tooltip class="item pull-right" effect="dark" content="Click to Go Back" placement="top">
								<button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
									<i class="icons icon-arrow-left"></i>
								</button>
							</el-tooltip>
							</router-link> -->
						<div class="pull-right" style="margin-top: -3px;" v-if="employeesCount">
							<span class="page-info px-2 hidden-md-down"> {{filterJSON.skip + 1}} -
								{{filterJSON.skip + employees.length}} of {{employeesCount}}</span>
							<el-tooltip class="item" effect="dark" content="Previous" placement="top">
								<button type="button" :disabled="this.filterJSON.skip <= 0" :class="{'disabled':ajaxCallinprogress}" class="btn btn-icon d-none d-md-inline el-tooltip item" @click="employeePagination(true, false)">
									<i class="icon icon-chevron-left"></i>
								</button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="Next" placement="top">
								<button type="button" :disabled="filterJSON.skip + employees.length >= employeesCount" :class="{'disabled':ajaxCallinprogress}" class="btn btn-icon d-none d-md-inline el-tooltip item" @click="employeePagination(false, true)">
									<i class="icon icon-chevron-right"></i>
								</button>
							</el-tooltip>
						</div>
					</div>
				</div>
			</h2>
            <div class="category-manage-info mb-2"> EMPLOYEES </div>
			<div class="page-layout carded left-sidebar"
                    style="overflow: auto !important;padding-bottom: 5px;">
                    <div class="page-content-wrapper" style="background-color: #005dae!important;padding: 10px!important;">
					<div class="page-content">
						<div class="demo-content">
							<table class="table table-scroll">
								<thead>
									<tr>
										<th style="width:1%;flex: 0 0 70px!important"></th>
										<th style="flex:1 1 100px">
											<span style="cursor:pointer;">
												NAME
											</span>
										</th>
										<th style="flex:1 1 160px">
											<span style="cursor:pointer;">
												EMAIL
											</span>
										</th>
										<th style="flex:1 1 72px" class="hidden-sm-down">
											<span style="cursor:pointer;">
												PHONE
											</span>
										</th>
										<th style="flex: 1 1 72px;">
											<span style="cursor:pointer;">
												ROLES
											</span>
										</th>
										<th style="flex: 1 1 72px;">
											<span style="cursor:pointer;">
												OUTLET
											</span>
										</th>
										<th v-if="loggedInUser.actions.employees.edit" class="text-center">Admin</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="ajaxCallinprogress">
										<td colspan="7" class="text-center justify-content-center">
											<div class="digi-table-loader">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</td>
									</tr>
									<tr v-for="employee in employees" :key="employee.id">
										<td style="flex: 0 !important">
											<img src="https://cdn.digicollect.com/cdn/dccrm/profile_pics/defaults/person.png" v-if="!employee.profile_pic"
												style="width: 41px;height:41px;border-radius: 100%;">
											<img :src="employee.profile_pic" v-else
												style="width: 41px;height:41px;border-radius: 100%;">
										</td>
										<td class="font-600" style="overflow: hidden;text-overflow: ellipsis;display: inline-block;white-space: nowrap;flex:1 1 100px;text-transform: capitalize;" v-text="employee.full_name"></td>
										<td v-text="employee.email" style="overflow: hidden;text-overflow: ellipsis;display: inline-block;white-space: nowrap;flex:1 1 160px"></td>
										<td v-text="employee.phone || '-' " class="hidden-sm-down" style="flex:1 1 72px"></td>
										<td style="text-transform:capitalize;flex: 1 1 72px;" v-text="employee.user_role == 'default_admin' ? 'General Manager':employee.user_role.replace('default_', ' ').split('_').join(' ')">
										</td>
										<td style="flex: 1 1 72px;" class="text-truncate">
											<span v-if="employee.outlet.length > 0">
                                                {{employee.outlet[0] || "-"}} 
                                                <span v-if="employee.outlet.length > 1" class="text-secondary fs-12 font-weight-bold">+{{employee.outlet.length-1}} More</span>
                                            </span>
                                            <span v-else>
                                                -
                                            </span>
										</td>
										<td v-if="loggedInUser.actions.employees.edit" class="text-center">
											<el-tooltip class="item" effect="dark" content="Edit" placement="top">
												<button class="btn btn-icon companyactions actionicons"
													@click="editemployee(JSON.parse(JSON.stringify(employee)))">
													<i class="icon icon-pencil"></i>
												</button>
											</el-tooltip>
										</td>
									</tr>
									<tr v-if="!employees.length && !ajaxCallinprogress" style="background-color:transparent!important;border-bottom: 0px solid #a8adb4!important;">
										 <td
											colspan="7"
											class="text-center justify-content-center"
											style="display:block;"
											>
											<img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg"  style="margin-top: 300px;"/>
											<br/>
											<p
												class="text-secondary font-20"
												style="font-size: 22px; font-weight: 600;"
											>No Search Results Found</p>
										</td>
									</tr>
									<!-- <tr v-show="!employees.length && !ajaxCallinprogress">
										<td colspan="7" class="text-center justify-content-center">
											<strong>No Employess Found.</strong>
										</td>
									</tr> -->
								</tbody>
							</table>
						</div>
					</div>					
				</div>
				<sweet-modal ref="success_modal" icon="success">
					{{ modal_msg }}
				</sweet-modal>
				<sweet-modal ref="warning_modal" icon="warning">
					{{ modal_msg }}
				</sweet-modal>
			</div>
		</div>
		<modal name="employee-edit" transition="nice-modal-fade" class="employee-edit-modal final-modal popup-modal-all-width-height-height-500">
			<div class="v-modal-content">
				<div class="v-modal-header">
					<span class="v-modal-dialog-title v-modal-title">EDIT EMPLOYEE</span>
				</div>
      			<div class="general-section" style="padding: 15px 0px;">
				<div class="v-modal-body">
					<edit-employee @employeeEdited="getemployees();" :employee="selectedEmployee">
					</edit-employee>
				</div>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
import Avatar from "vue-avatar";
import { SweetModal } from "sweet-modal-vue";
import { mapGetters } from "vuex";

import EditEmployee from "./EditEmployee";

import EmployeeServices from "./mixins/employees.js";
import { EventBus } from "./eventBus/nav-bar-event.js";

export default {
	name: "EmployeeManage",
	data() {
		return {
			show: false,
			selectedEmployee: null,
			sortOrderValue: 1,
			modal_msg: "",

          searchcustomer: 100,
		};
	},
	mixins: [EmployeeServices],
	components: {
		Avatar,
		EditEmployee,
		SweetModal
	},
	methods: {
		handleRemove(file, fileList) {},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		toggleShow() {
			this.show = !this.show;
		},
		/**
		 * crop success
		 *
		 * [param] imgDataUrl
		 * [param] field
		 */
		cropSuccess(imgDataUrl, field) {
			this.imgDataUrl = imgDataUrl;
		},
		/**
		 * upload success
		 *
		 * [param] jsonData  server api return data, already json encode
		 * [param] field
		 */
		cropUploadSuccess(jsonData, field) {},
		/**
		 * upload fail
		 *
		 * [param] status    server api return error status, like 500
		 * [param] field
		 */
		cropUploadFail(status, field) {},
		createemployee() {
			this.$modal.show("employee-create");
		},
		hideemployee() {
			this.$modal.hide("employee-create");
		},
		editemployee(employee) {
			if(employee.user_role=='default_admin'&& this.loggedInUser.role!='default_admin'){
				this.modal_msg = "Cannot Edit Admin"
				this.$refs.warning_modal.open()
				setTimeout(() => {
					this.$refs.warning_modal.close()
				}, 2000);
			}
			else{
				this.selectedEmployee = employee;
				this.$modal.show("employee-edit");
			}
		}
	},
	mounted() {
		EventBus.$emit("customerdetailsLoad", false);
		EventBus.$emit("settingsLoad", false);
		EventBus.$emit("reportsLoad", false);
	},
	computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser;
		}
	}
};
</script>

<style>
	.employees-manage .table td {
		padding: 0px 13px !important;
	}

	.avatar {
		background: #00448b;
	}

	.avatar {
		width: 4rem;
		min-width: 4rem;
		height: 4rem;
		line-height: 4rem;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		font-size: 16px !important;
		font-weight: 600;
		text-align: center;
		color: #fff;
		background-color: #c3c3ba;
	}

	.avatar.avatar-trans {
		background-color: transparent !important;
	}

	.font-weight-bold.product-name {
		font-weight: bold !important;
		color: #00448b;
	}

	.employees-manage .sorting-icon {
		cursor: pointer;
		visibility: hidden;
	}

	.employees-manage .sorting-icon.show-icon {
		cursor: pointer;
		visibility: visible;
	}

	.hidden {
		display: none;
	}

	th:hover .sorting-icon {
		visibility: visible !important;
		cursor: pointer;
	}

	.v-select-label {
		font-size: 14px !important;
		color: #00448b !important;
	}

</style>
