<template>
    <div  class="access-denied-text">
        <p>
            <span>This Application</span>
            is not compatible with IE<br> Open in Chrome or Firefox 
        </p>
    </div>		
</template>
<script>
export default {
    
}
</script>
<style>
.access-denied-text {
    position: relative;
    top: 46%;
    /* left: 35%; */
    justify-content: center;
    display: flex;
    font-size: 24px;
    text-align: center;
    color: #00448b;
}
</style>
