<template>
    <div class>
        <div>
            <ul class="nav nav-tabs nav-site" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#void-Settings" role="tab" aria-controls="void-Settings" aria-expanded="true">Void Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#cancelled-setting" role="tab" aria-controls="cancelled-setting">Cancelled Settings</a>
                </li>
            </ul>
            <div class="tab-content mt-6">
                <div role="tabpanel" class="tab-pane fade active show" id="void-Settings" aria-labelledby="void-Settings">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-center newGeneralBox mr-3 ml-4 mb-3">
                            <span class="text-black font-weight-bold">Enable Void Closed Orders :</span>
                            <el-switch active-text="Yes" v-model="void_delete_order" inactive-text="No" @change="changeStatus()"></el-switch>
                        </div>
                        <div class="col-md-12 row justify-content-between no-gutters align-items-center">
                            <span class="text-white font-weight-bold pl-2 hidden-xs-down">Void Reasons Configuration :</span>
                            <button class="btn btn-smm btn-secondary btnWhite" @click="addReason()">+Add Reason</button>
                        </div>
                        <div class="col-md-12" style="padding-top: 8px;">
                            <div class="table-row">
                                <table class="table table-cash-outlet network-setting-table">
                                    <thead>
                                        <tr>
                                            <th style="width:1% !important;flex: 0 0 80px !important;"></th>
                                            <th>Reason</th>
                                            <th class="hidden-sm-down">REASON STATUS</th>
                                            <th>ADMIN</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="voidReasons.length < 1 && !ajaxCallInProgress">
                                        <tr>
                                            <td colspan="4" class="text-center justify-content-center">No Void Reasons Found</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="ajaxCallInProgress" style="border-bottom: transparent !important">
                                            <td colspan="4" class="text-center justify-content-center">
                                                <div class="digi-table-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-for="voidReason in voidReasons" :key="voidReason._id">
                                            <td style="flex: 0 0 80px !important;">
                                                <img v-if="voidReason.image_url" class="avatar avatar-trans mx-2" :src="voidReason.image_url"/>
                                                <avatar v-else class="mx-2 modalavatar" :username="voidReason.name.replace(/ .*/,'')" :src="voidReason.image_url" :size="40" :rounded="true" color="#fff"></avatar>
                                            </td>
                                            <td style="width: 160px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">{{voidReason.name}}</td>
                                            <td class="hidden-sm-down">
                                                <el-switch v-model="voidReason.is_available" @change="changeReasonStatus(voidReason)"></el-switch>
                                            </td>
                                            <td>
                                                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                    <a class="btn btn-icon companyactions actionicons" @click="editReason(voidReason._id)" style="padding: 0.4rem .2rem;">
                                                        <i class="icon icon-pencil"></i>
                                                    </a>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                    <a class="btn btn-icon actionicons" @click="deleteReason(voidReason)" style="padding: 0.4rem .2rem;">
                                                        <i class="icon icon-delete"></i>
                                                    </a>
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="cancelled-setting" aria-labelledby="cancelled-setting">
                    <div class="col-md-12">
                        <div class="col-md-12 row justify-content-between no-gutters align-items-center">
                            <span class="text-white font-weight-bold pl-2 hidden-xs-down">Cancelled Reasons Configuration :</span>
                            <button class="btn btn-smm btn-secondary btnWhite" @click="addCancelReason">+Add Reason</button>
                        </div>
                        <div class="col-md-12" style="padding-top: 8px;">
                            <div class="table-row">
                                <table class="table table-cash-outlet network-setting-table">
                                    <thead>
                                        <tr>
                                            <th style="width:1% !important;flex: 0 0 80px !important;"></th>
                                            <th>Reason</th>
                                            <th class="hidden-sm-down">REASON STATUS</th>
                                            <th>ADMIN</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="cancelReasons.length < 1 && !ajaxCallInProgress">
                                        <tr>
                                            <td colspan="4" class="text-center justify-content-center">No Cancelled Reasons Found</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="ajaxCallInProgress" style="border-bottom: transparent !important">
                                            <td colspan="4" class="text-center justify-content-center">
                                                <div class="digi-table-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-for="cancelReason in cancelReasons" :key="cancelReason._id">
                                            <td style="flex: 0 0 80px !important;">
                                                <img v-if="cancelReason.image_url" class="avatar avatar-trans mx-2" :src="cancelReason.image_url"/>
                                                <avatar v-else class="mx-2 modalavatar" :username="cancelReason.name.replace(/ .*/,'')" :src="cancelReason.image_url" :size="40" :rounded="true" color="#fff"></avatar>
                                            </td>
                                            <td style="width: 160px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">{{cancelReason.name}}</td>
                                            <td class="hidden-sm-down">
                                                <el-switch v-model="cancelReason.is_available" @change="changeReasonStatus(cancelReason)"></el-switch>
                                            </td>
                                            <td>
                                                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                    <a class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;" @click="editCancelReason(cancelReason._id)">
                                                        <i class="icon icon-pencil"></i>
                                                    </a>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                    <a class="btn btn-icon actionicons" @click="deleteReason(cancelReason)" style="padding: 0.4rem .2rem;">
                                                        <i class="icon icon-delete"></i>
                                                    </a>
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-reason modal_name="create-reason" @save="getVoidReason()" :voidoutletId="voidoutletId"></add-reason>
        <edit-reason modal_name="update-reason" @save="getVoidReason()"></edit-reason>
        <add-cancel-reason modal_name="create-cancel-reason" @save="getCancelReason()" :voidoutletId="voidoutletId"></add-cancel-reason>
        <edit-cancel-reason modal_name="update-cancel-reason" @save="getCancelReason()" :cancelReasonId="cancelReasonId"></edit-cancel-reason>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue'
import AddReason from './AddReason'
import EditReason from './EditReason'
import AddCancelReason from './AddCancelReason'
import EditCancelReason from './EditCancelReason'
import Avatar from 'vue-avatar'
import { EventBus } from './eventBus/nav-bar-event.js'
export default {
    props: ['voidoutletId','delete_void_order'],
    data() {
        return {
            voidReasons: [],
            cancelReasons: [],
            modal_msg: '',
            reasonId: '',
            cancelReasonId: '',
            imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg',
            ajaxCallInProgress: false,
            void_delete_order:false
        }
    },
    components: {
        AddReason,
        EditReason,
        SweetModal,
        AddCancelReason,
        EditCancelReason,
        Avatar
    },
    methods: {
        addReason() {
            this.$modal.show('create-reason')
        },
        editReason(reasonId) {
            EventBus.$emit('getVoidReasonById', reasonId)
            this.$modal.show('update-reason')
        },
        addCancelReason() {
            this.$modal.show('create-cancel-reason')
        },
        editCancelReason(cancelReasonId) {
            EventBus.$emit('getCancelReasonById', cancelReasonId)
            this.$modal.show('update-cancel-reason')
        },
        /**
         * This method or function is used to get the cancel reason list
         * @param {type} - type is the String
         * @param {is_default} - is_default is the Boolean
         * @param {outlet_id} - outlet_id is the String
         * @returns {response} - Get the result
         */
        async getCancelReason() {
            this.ajaxCallInProgress = true
            this.cancelReasons = []
            let response = await this.$http.post('/outlet/get_all_reasons', {
                type: 'cancelled',
                is_default: false,
                outlet_id: this.voidoutletId
            })
            if (response.data.status_id == 1) {
                this.ajaxCallInProgress = false
                this.cancelReasons = response.data.response
            }
        },
        /**
         * This method or function is used to get the Void reason list
         * @param {type} - type is the String
         * @param {is_default} - is_default is the Boolean
         * @param {outlet_id} - outlet_id is the String
         * @returns {response} - Get the result
         */
        async getVoidReason() {
            this.ajaxCallInProgress = true
            this.voidReasons = []
            let response = await this.$http.post('/outlet/get_all_reasons', {
                type: 'void',
                is_default: false,
                outlet_id: this.voidoutletId
            })
            if (response.data.status_id == 1) {
                this.ajaxCallInProgress = false
                this.voidReasons = response.data.response
            }
        },
        /**
         * This method or function is used to change the reason status
         * @param {voidReason} - voidReason is the Object
         * @returns {response} - Get the result
         */
        changeReasonStatus(voidReasonByID) {
            let _id = voidReasonByID._id
            let name = voidReasonByID.name
            let type = voidReasonByID.type
            let is_available = voidReasonByID.is_available
            let voidReason = {
                _id,
                name,
                type,
                is_available
            }
            this.$http.put('/outlet/update/' + voidReason._id, voidReason).then(response => {
                if (response.data.status_id == 1) {
                    this.$toasted.global.success('Reason Status Updated Successfully')
                } else {
                    this.$toasted.global.error('Cannot Change Reason Status')
                }
            })
        },
        /**
         * This method or function is used to Delete the reason
         * @returns {response} - Get the result
         */
        deleteReason(voidReason) {
            this.$swal({
                title: 'Are you sure?',
                text: 'Are you sure to Delete the Reason ' + voidReason.name + ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                this.deleteReasonByID(voidReason)
                }
            })
        },
        /**
         * This method or function is used to delete the reason by id
         * @param {_id} - _id is the String
         * @returns {response} - Get the result
         */
        deleteReasonByID(voidReason) {
            this.$http.delete('/outlet/delete/' + voidReason._id, { _id: voidReason._id }).then(response => {
                if (response.data.status_id == 1) {
                    this.$swal({
                    title: 'Deleted Successfully',
                    text: '',
                    type: 'success'
                    })
                    this.getVoidReason()
                    this.getCancelReason()
                } else {
                    this.modal_msg = response.data.reason
                    this.$swal({
                    title: this.modal_msg,
                    text: '',
                    type: 'warning'
                    })
                }
            }).catch(res => {
                this.modal_msg = res.response.data.reason
                this.$swal({
                    title: this.modal_msg,
                    text: '',
                    type: 'warning'
                })
            })
        },
        /**
         * This method or function is used to change the status of void
         * @param {params} - params is the Object
         * @returns {response} - Get the result
         */
        async changeStatusOfVoid(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/outlet/change_outlet_status_to_delete_order',params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        /**
         * This method or function is used to call the change status api
         * @param {delete_void_order} - delete_void_order is the Object
         * @param {outlet_id} - outlet_id is the String
         * @returns {response} - Get the result
         */
        async changeStatus(){
            try {
                let params = {
                    delete_void_order:this.void_delete_order,
                    outlet_id:this.voidoutletId
                }
                let res = await this.changeStatusOfVoid(params)
                this.$toasted.global.success(res.message)
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
    },
    mounted() {
        this.void_delete_order = this.delete_void_order
        this.getCancelReason()
        this.getVoidReason()
    }
}
</script>
<style scoped>
.borderBottom {
    border-bottom: 1px solid #a8adb4 !important;
}
.general-settings-tab-outlet {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #dee8f2;
    margin: 16px;
}
.newGeneralBox{
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #dee8f2;
    padding: 10px;
}
.general-settings-tab-outlet .inputContact,
.general-settings-tab-outlet .digi-form {
    border-bottom: 1px solid #a8adb4 !important;
    background: transparent !important;
}
.general-settings-tab-outlet .vue-tel-input {
    background: transparent !important;
    border: transparent !important;
    border-bottom: 1px solid #a8adb4 !important;
}
.tax-tab-outlet {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #dee8f2;
    margin: 16px;
    padding-top: 20px;
    padding-bottom: 7px;
}
.mediaSize .other-table-content {
    padding: 20px;
    background-color: #dee8f2 !important;
    padding-bottom: 20px;
    border-radius: 12px;
}
.mediaSize.other-settings-tab-outlet .inputContact {
    border-bottom: solid 1px #a8adb4 !important;
    background: transparent;
}
.other-settings-other .vue-tags-input {
    background-color: transparent !important;
}
.other-settings-other .vue__time-picker input.display-time {
    background: transparent !important;
}
.send-report-div {
    background-color: #dee8f2 !important;
    padding-bottom: 20px;
    border-radius: 12px;
}
.business-div {
    background-color: #dee8f2 !important;
    padding-bottom: 20px;
    border-radius: 12px;
    margin-top: 20px;
}
.business-div .vue__time-picker input.display-time {
    background: #dee8f2 !important;
}
input:required {
    box-shadow: none !important;
}
.icon-edit-void,
.icon-delete-void {
    cursor: pointer;
}
.form-check.form-check-inline.void-checkbox {
    border-radius: 20px;
    border: solid 0.5px #c7e1ff;
    background-color: #ebf6ff;
    padding: 7px 7px 6px 11px;
    font-size: 13px;
    margin-bottom: 10px;
}
.form-check.form-check-inline.void-checkbox
  .form-check-label
  input[type='checkbox']
  ~ .checkbox-icon {
    top: -1px !important;
    left: -1px !important;
    font-size: 22px !important;
}
.voiddiv {
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #dee8f2;
}
.voiddiv_cancelled {
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #dee8f2;
}
.form-check.form-check-inline.void-checkbox
  .form-check-label
  input[type='checkbox']:checked
  ~ .checkbox-icon {
    color: #00448b !important;
}
.icon-edit-void .icon.icon-pencil {
    color: #00448b !important;
    font-size: 18px !important;
    padding-left: 8px !important;
}
.void-pophover {
    position: absolute;
    background-color: #fff;
    z-index: 9999;
    width: 400px;
}
.void-pophover .popover {
    max-width: 400px;
}
.form-control.void-input-pop {
    border: 1px solid #cdd2da !important;
    border-radius: 24px 0px 0px 24px !important;
    border-right: 0px !important;
}
.icon-delete-void .icon.icon-delete {
    color: #ff6565 !important;
    font-size: 18px !important;
}
@media (max-width: 1100px) {
    .col-4.settings-cols {
        flex: 0 0 80% !important;
        max-width: 80% !important;
    }
}
@media (max-width: 1100px) {
    .col-8.settings-cols {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 20px;
    }
}
.contactActiveTab {
    color: #fff !important;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-right: solid 18px #cdd2da;
    background-color: #00448b;
    border-right-color: #00448b;
    overflow: inherit !important;
    padding-left: 42px !important;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.38);
}
.supplyactive.contactActiveTab {
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    border-right: solid 18px #cdd2da;
    background-color: #00448b;
    border-right-color: #00448b;
    overflow: inherit !important;
    padding-left: 42px !important;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.38);
    color: #fff !important;
}
.scmminustop {
    margin-top: -15px;
}
label[for='taxCode'] {
    display: none;
}

.res-settings {
    box-shadow: 0 1px 5px 0 rgba(187, 132, 132, 0.16) !important;
    background-color: #dee8f2 !important;
    margin-bottom: 16px;
    padding-bottom: 6px;
    border-radius: 8px;
}
.select-card-checkbox {
    height: 80px;
    line-height: 79px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.occupiedTable-regenerate {
    cursor: pointer;
    width: 100px;
    height: 100px;
    font-weight: 600;
    padding-top: 36px !important;
    padding: 10px;
    text-align: center;
    border-radius: 7px;
    margin-left: 18px;
    display: inline-grid;
    margin-top: 18px;
    color: #ffffff;
    -webkit-box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#00448b),
        color-stop(51%, #00448b),
        to(#69b3fc)
    );
    background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
}
.table-row {
    border: 1px solid #a8adb3;
    border-radius: 18px;
    margin-top: 10px;
    background-color: #dee8f2;
}
.v-select.cash-v-select input[type='search'] {
    position: absolute !important;
}
.outletdetails label.digi-label-outlet {
    top: 13px;
    position: absolute;
    font-size: 12px;
    color: #aaa;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.digi-form:focus ~ label.digi-label-outlet,
.digi-form:valid ~ label.digi-label-outlet {
    top: -15px;
    font-size: 13px;
    color: #00448b;
}
.heading-details {
    font-weight: 600;
    color: #00448b;
    font-size: 14px;
}

.verticalTab {
    height: 41px;
    display: flex;
    width: 100%;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 24px;
    position: relative;
    justify-content: center;
    color: #f7f6f6 !important;
    font-weight: 600;
}
.verticalTab:hover {
    background-color: #eeeeee47;
}

.contactSidebar {
    padding-bottom: 30px;
    background-image: url('https://cdn.digicollect.com/cdn/posv2/arora1.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
}
.table.table-cash-outlet tbody tr {
    border-bottom: 1px solid #a8adb4;
}
.table.table-cash-outlet tbody tr:last-child {
    border-bottom: none !important;
}
.table.table-payment tbody tr {
    border-bottom: 1px solid #a8adb4;
}
.table.table-payment tbody tr:last-child {
    border-bottom: none !important;
}
.contactTitle {
    text-align: center;
    display: block;
    font-size: 14px;
    color: #fff !important;
    font-weight: 600 !important;
    padding-top: 6px;
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;
}

.existingVendors {
    border: 1px solid #00448b;
    height: 34px !important;
    color: #00448b !important;
    background: #fff !important;
    line-height: 32px !important;
    text-transform: none !important;
    padding: 5px 15px 5px 15px;
    font-size: 14px !important;
    font-weight: 500;
    border-left: none !important;
    cursor: pointer;
    border-radius: 20px 0 0 20px !important;
}
.newReq {
    border: 1px solid #00448b;
    height: 34px !important;
    color: #00448b !important;
    background: #fff !important;
    line-height: 32px !important;
    text-transform: none !important;
    padding: 5px 15px 5px 15px;
    font-size: 14px !important;
    font-weight: 500;
    cursor: pointer;
    border-left: none !important;
    border-radius: 0 20px 20px 0 !important;
    margin-left: -3px;
}
.hotel-website {
    font-size: 14px;
    font-weight: 600;
    color: #00448b !important;
}
.table.table-outlet-details td {
    padding: 0.6rem 1.2rem !important;
}
.decline {
    font-size: 14px;
    font-weight: 600;
    color: #e82828 !important;
    padding-right: 20px;
}
.popTitle {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    display: block;
    padding-top: 6px;
    padding-bottom: 2px;
}
.new-count {
    color: #ffa104 !important;
    font-weight: 600;
}
.popAvailable {
    font-size: 14px;
    font-weight: bold;
    color: #00448b;
    line-height: 35px;
}
.text-bold {
    font-weight: 600;
}
.text-muted {
    color: #4e5764 !important;
    font-weight: 600 !important;
}
.font-weight-600.text-green,
.font-weight-600.text-green i {
    color: #1a9347 !important;
}
.font-weight-600.text-red,
.font-weight-600.text-red i {
    color: red !important;
}
.font-weight-600 {
    font-weight: 600;
}
.card-outlet {
    border-radius: 7px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #cdd2da;
    margin-top: 50px;
    margin-right: 26px;
}
.outlet-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (max-width: 1100px) {
    .mediaSize {
        padding: 0 0 !important;
    }
}

.currentPopup {
    overflow-x: hidden;
}
.nav-tabs.nav-site {
    border-bottom: 1px solid #cdd2da;
    padding-left: 14px;
    padding-right: 14px;
}
.nav-tabs.nav-site .nav-item {
    margin-bottom: -2px;
}
.nav-tabs.nav-site .nav-link.active {
    box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16) !important;
    background: #00448b !important;
    color: #fff !important;
    border-bottom: 1px solid transparent;
    font-weight: 600;
}
.nav-tabs.nav-site .nav-link {
    height: 35px !important;
    border: 1px solid #cdd2da;
    background-color: #efefef;
    color: #00448b;
    margin-right: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 1px;
}
.table td {
    vertical-align: middle;
    color: #4e5764;
    font-weight: 600;
}
.nav-tabs.nav-site .nav-link.active:not(.dropdown-toggle):after,
.nav-tabs.nav-site .nav-item.show .nav-link:not(.dropdown-toggle):after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: transparent;
}
.whiteSpace {
    white-space: nowrap;
}
@media screen and (max-width: 1200px) {
    .tableOverflow {
        overflow-x: auto;
        display: block;
    }
}
.tableOverflow:hover::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #94bef0;
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
    border: 9px solid #00448b;
}
.displayNone {
    display: none !important;
}
.btnWhite {
    background-color: #fff !important;
    color: #00448b !important;
}
.network-setting-table thead,
.network-setting-table tr,
.network-setting-table td,
.network-setting-table th {
    display: flex;
    flex: 1 1 0%;
}
.network-setting-table tbody tr,
.network-setting-table tbody td {
    align-items: center;
    font-weight: 600;
}
.network-setting-table tbody {
    display: block;
    width: 100%;
    max-height: 477px;
    overflow-y: auto;
}
.table.network-setting-table tbody tr {
    border-bottom: 1px solid #a8adb4 !important;
}
.tableImage {
    background-color: transparent !important;
}
.avatar {
    background: #00448b;
}
.avatar {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    line-height: 4rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 16px !important;
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: #c3c3ba;
}
.avatar.avatar-trans {
    background-color: transparent !important;
}
.vue-avatar--wrapper.modalavatar {
    display: inline-block !important;
}
</style>