<template>
  <div>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">VIEW MORE</span>
        </div>
        <div
          class="v-modal-body pt-0"
          style="padding: 15px 0px;overflow-y: auto !important;overflow-x: hidden !important;max-height:500px;"
        >
          <div class="v-modal-layout p-5">
            <form>
              <div class="contactGroup">
                <label class="text-secondary fs-14">Cash Register ID</label>
                <input
                  class="inputContact pt-0"
                  type="text"
                  :value="report.cash_register.id"
                  autocomplete="off"
                  readonly
                />
              </div>
              <div class="contactGroup mt-5">
                <label class="text-secondary fs-14">Receipt Number</label>
                <input
                  class="inputContact pt-0"
                  type="text"
                  :value="report.receipt_id"
                  autocomplete="off"
                  readonly
                />
              </div>
              <div class="contactGroup mt-5">
                <label class="text-secondary fs-14">Order Type</label>
                <input
                  class="inputContact pt-0"
                  type="text"
                  :value="report.type"
                  autocomplete="off"
                  readonly
                />
              </div>
              <div class="contactGroup mt-5">
                <label class="text-secondary fs-14">Employee</label>
                <input
                  class="inputContact pt-0"
                  type="text"
                  :value="report.employee_name"
                  autocomplete="off"
                  readonly
                />
              </div>
            </form>
          </div>
          <div class="v-modal-footer text-center pt-5 pb-3">
            <button type="submit" class="btn btn-secondary btn-smm" @click="hideMoreReport()">CLOSE</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { EventBus } from '../eventBus/nav-bar-event.js'
export default {
  props: ['modal_name', 'report'],
  methods: {
    hideMoreReport() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.moreReportHeader {
  background-color: #94bef0;
}
.moreReportHeader th {
  color: #ffff !important;
}
.table-header-fixed tbody {
  display: block;
  width: 100%;
  height: 320px;
  max-height: 390px;
  overflow-y: auto;
}
.table-header-fixed thead,
.table-header-fixed tr,
.table-header-fixed td,
.table-header-fixed th {
  display: flex;
  flex: 1 1 0%;
}
.table-header-fixed tbody tr,
.table-header-fixed tbody td {
  align-items: center;
}
.table-header-fixed thead th {
  align-items: center;
}
</style>
