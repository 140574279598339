<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" @opened="opened" class="final-modal popup-modal-all-width-height">
            <div class="v-modal-content">
                <div class="v-modal-header" style="background-color: #005dae;">
                    <span class="v-modal-dialog-title v-modal-title">GENERATE TAX INVOICE</span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body" style="padding: 0px!important;">
                    <div class="pl-5 pr-5 pt-5">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class>
                                    <span class="text-secondary vselectLabel">
                                        Customer Name
                                        <sup>★</sup>
                                    </span>
                                    <input class="form-control" @keyup="getSearchCustomer" id="input-search" type="text"
                                        @click="ShowDropdown()" v-model="customer.first_name"
                                        placeholder="Search Customer & Select" />
                                    <div class="dropdown-v-select" v-if="dropdwon_generate">
                                        <div v-for="(customer, index) in customers"
                                            @click="updateInvoiceCustomer(customer)" :key="index">{{customer.full_name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 pt-2">
                                <div class="form-group contactGroup" style="padding-top: 0px;">
                                    <label for="inputSattus" class="col-form-label text-secondary fs-14"
                                        style="font-size:14px!important;">
                                        Email
                                        <sup>★</sup>
                                    </label>
                                    <vue-tags-input v-model="email" name="tags" id="tags" :tags="emails"
                                        @tags-changed="newTags => emails = newTags"
                                        placeholder="Add Email & Press Enter" />
                                    <div v-show="invalidEmail" class="text-danger fs-13">Invalid Email Address Detected
                                    </div>
                                </div>
                            </div>
                            <div class="text-secondary pt-3 col-md-12 fs-15">
                                Generate Tax Invoice To
                                <sup>★</sup>
                            </div>
                            <div class="row col-md-12">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="company d-flex justify-content-between"
                                        @click="invoice_for = 'COMPANY'">
                                        <span class>
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images/cityscape.svg" />
                                        </span>
                                        <span class="text-white font-weight-bold fs-14 pt-1">Company</span>
                                        <span class="mr-5">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images/GroupGreen.svg"
                                                v-if="invoice_for == 'COMPANY'" />
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images/gen-img1.svg"
                                                v-else />
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="company d-flex justify-content-between"
                                        @click="invoice_for = 'PERSONAL'">
                                        <span class>
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images/teddyBear.svg" />
                                        </span>
                                        <span class="text-white font-weight-bold fs-14 pt-1">Customer</span>
                                        <span class="mr-5">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images/GroupGreen.svg"
                                                v-if="invoice_for == 'PERSONAL'" />
                                            <img src="https://cdn.digicollect.com/cdn/posv2/images/gen-img1.svg"
                                                v-else />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 pt-3">
                                <el-checkbox v-model="isItemLevel">Generate Itemwise Invoice</el-checkbox>
                            </div>
                            <div v-if="invoice_for == 'PERSONAL'" class="col-md-12 mt-5">
                                <div class="row">
                                    <div class="font-weight-bold text-black col-md-6" style="padding-top: 7px;">Customer
                                        Information</div>
                                    <div class="col-md-6" @click="showEditCustomer">
                                        <button class="btn btn-secondary btn-smm" style="float: right;">
                                            <i class="icon icon-pencil fs-12"></i>
                                            <span>Edit information</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="customerInfo mt-3">
                                    <div class="col-md-12 d-flex pt-1 pb-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Customer Name
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span :title="customer.full_name" class="font-600 customerInfoWidth"
                                                :class="{'text-danger': !customer.full_name, 'text-secondary': customer.full_name}">{{customer.full_name || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                    <hr style="border-top: 1px solid #a8adb3;" />
                                    <div class="col-md-12 d-flex pt-1 pb-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Phone
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600" :title="customer.phone_number"
                                                :class="{'text-danger': !customer.phone_number, 'text-secondary': customer.phone_number}">{{customer.phone_number || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                    <hr style="border-top: 1px solid #a8adb3;" />
                                    <div class="col-md-12 d-flex pt-1 pb-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Email
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600 customerInfoWidth" :title="customer.email"
                                                :class="{'text-danger': !customer.email, 'text-secondary': customer.email}">{{customer.email || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                    <hr style="border-top: 1px solid #a8adb3;" />
                                    <div class="col-md-12 d-flex pt-1 pb-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Customer Tax Code
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600 customerInfoWidth" :title="customer.tax_code"
                                                :class="{'text-danger': !customer.tax_code, 'text-secondary': customer.tax_code}">{{customer.tax_code || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                    <hr style="border-top: 1px solid #a8adb3;" />
                                    <div class="col-md-12 d-flex pt-1 pb-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Customer Address
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600" :title="customer.personal_full_address"
                                                :class="{'text-danger': !customer.personal_full_address, 'text-secondary': customer.personal_full_address}">{{customer.personal_full_address || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-danger">
                                    <sup>★</sup> Please make sure Mandatory Information are Available
                                </span>
                            </div>
                            <div v-else class="col-md-12 mt-5">
                                <div class="row">
                                    <div class="font-weight-bold text-black col-md-6" style="padding-top: 7px;">Company
                                        Information</div>
                                    <div class="col-md-6">
                                        <button class="btn btn-secondary btn-smm" style="float: right;"
                                            @click="showEditCustomer">
                                            <i class="icon icon-pencil fs-12"></i>
                                            <span>Edit information</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="customerInfo mt-3">
                                    <div class="col-md-12 d-flex pt-1 pb-3">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Company Name
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600 customerInfoWidth" :title="customer.company_name"
                                                :class="{'text-danger': !customer.company_name, 'text-secondary': customer.company_name}">{{customer.company_name || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                    <hr style="border-top: 1px solid #a8adb3;" />
                                    <div class="col-md-12 d-flex pt-2 pb-2">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Company Tax Code
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600 customerInfoWidth" :title="customer.company_tax_code"
                                                :class="{'text-danger': !customer.company_tax_code, 'text-secondary': customer.company_tax_code}">{{customer.company_tax_code || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                    <hr style="border-top: 1px solid #a8adb3;" />
                                    <div class="col-md-12 d-flex pt-2">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-weight-bold fs-13">
                                                Company Address
                                                <sup class="fs-10">★</sup>:
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <span class="font-600" :title="customer.company_full_address"
                                                :class="{'text-danger': !customer.company_full_address, 'text-secondary': customer.company_full_address}">{{customer.company_full_address || 'Not Available'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-danger">
                                    <sup>★</sup> Please Make Sure Mandatory Information are Available
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pb-5 pt-5">
                    <a class="btn btn-link btn-smm"
                        style="text-transform: uppercase !important;font-size: 12px !important;border:1px solid #00448b"
                        @click="closeInvoice()">CANCEL</a>
                    <button type="submit" class="btn btn-secondary btn-smm mr-2" style="background:#1a9347 !important" @click="printInvoice">PRINT</button>
                    <button type="submit" class="btn btn-secondary btn-smm ml-2" @click="sendEmail">SEND</button>
                </div>
            </div>
        </modal>
        <edit-customer modal_name="customer-edit" @close="closeEditCustomer()" @save="saveEditCustomer"></edit-customer>
    </div>
</template>
<script>
    import CustomerServices from './mixins/customer'
    import OrderServices from './mixins/orders'
    import EditCustomer from './EditCustomer'
    import printers from './mixins/printers.js'
    import Swal from 'sweetalert2'
    import {
        EventBus
    } from './eventBus/nav-bar-event'
    const SearchSelect = () => import('./SearchSelect.vue')

    function initialState() {
        return {
            customer: '',
            email: '',
            emails: [],
            customers: [],
            invoice_for: 'COMPANY',
            editCustomer: null,
            dropdwon_generate: false,
            CustomerName: '',
            isItemLevel: true,
            details: null,
            // loadComplete:false,
            // ledgersLoading:true,
            input: '',
            email_regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/,
            invalidEmail: false
        }
    }

    export default {
        props: ['modal_name', 'customerId', 'orderId', 'outletId'],
        mixins: [CustomerServices, OrderServices, printers],
        data() {
            return initialState()
        },
        components: {
            EditCustomer,
            SearchSelect,
            Swal
        },
        methods: {
            updateInvoiceCustomer(customer) {
                this.dropdwon_generate = false
                if (customer && customer._id != this.customerId) {
                    this.customerId = customer._id
                    this.opened()
                }
            },
            ShowDropdown() {
                this.dropdwon_generate = !this.dropdwon_generate
            },
            closeEditCustomer() {
                this.$modal.hide('customer-edit')
                this.$modal.show(this.modal_name)
            },
            saveEditCustomer() {
                this.$modal.hide('customer-edit')
                this.$modal.show(this.modal_name)
            },
            showEditCustomer() {
                if (this.customer.full_name === 'Open Guest') {
                    this.$swal({
                        title: 'Warning!',
                        text: 'Cannot Edit Open Guest',
                        type: 'warning'
                    })
                    return
                }
                this.$modal.hide(this.modal_name)
                let id = this.customer._id
                EventBus.$emit('editCustomerApi', id)
                this.$modal.show('customer-edit')
            },
            closeCreateCustomer(flag) {
                this.$modal.hide('customer-create')
            },
            async opened() {
                this.getSearchCustomer()
                let params = {
                    _id: this.customerId
                }
                let res = await this.getCustomerInfo(params)
                this.customer = res.response
                this.editCustomer = this.customer
                this.emails = [{
                    text: this.customer.email
                }]
            },
            reset() {
                Object.assign(this.$data, initialState())
            },
            closeInvoice() {
                this.reset()
                this.getSearchCustomer()
                this.$emit('closeinvoice')
            },
            async getSearchCustomer() {
                this.input = document.getElementById('input-search').value
                try {
                    let params = {
                        properties: ['first_name', 'last_name', 'full_name'],
                        search: {
                            term: this.input,
                            fields: ['first_name', 'last_name']
                        },
                        sort: {
                            by: 'first_name',
                            order: 1
                        },
                        skip: 0,
                        limit: 20
                    }
                    let res = await this.customerGetCustomerWith(params)
                    // if (res.search_term == this.input) {
                    this.customers = res.response
                    // this.getCustomerInfo()
                    // }
                } catch (reason) {
                    // TODO: Handle Error
                }
            },
            withoutCustomerItems(){
                this.store_printing_invoice = this.details
                this.printWithoutCustomerItems(this.details)
            },
            withoutCompanyItems(){
                this.store_printing_invoice = this.details
                this.printWithoutCompanyItems(this.details)
            },
            withCompanyItems(){
                this.store_printing_invoice = this.details
                this.printWithCompanyItems(this.details)
            },
            withCustomerItems(){
                this.store_printing_invoice = this.details
                this.printWithCustomerItems(this.details)
            },
            printInvoice(){
                if (
                    this.customer == '' ||
                    this.customer.first_name == '' ||
                    this.emails == ''
                ) {
                    this.$swal({
                        title: 'Warning!',
                        text: 'Please Fill The Required Fields',
                        type: 'warning'
                    })
                } else {
                    if (this.invoice_for == 'PERSONAL') {
                        if (
                            this.customer.first_name == '' ||
                            this.customer.phone_number == '' ||
                            this.customer.tax_code == '' ||
                            this.customer.personal_address == ''
                        ) {
                            this.$swal({
                                title: 'Warning!',
                                text: 'Please Provide Mandatory Fields',
                                type: 'warning'
                            })
                        } else {
                            var emailToSend = []
                            console.log(this.emails)
                            for (var i = 0; i < this.emails.length; i++) {
                                if (this.emails[i].text.match(this.email_regex)) {
                                    emailToSend.push(this.emails[i].text)
                                    this.invalidEmail = false
                                } else {
                                    this.invalidEmail = true
                                    break
                                    // emailToSend = this.emails
                                }
                            }
                            if (!this.invalidEmail) {
                                this.$http
                                    .post('/reports/tax_invoice/email', {
                                        order_id: this.orderId,
                                        customer_id: this.customerId,
                                        outlet_id: this.outletId,
                                        emails: emailToSend,
                                        invoice_for: this.invoice_for,
                                        isItemLevel: this.isItemLevel
                                    })
                                    .then(res => {
                                        this.details = res.data.response.tax_details
                                        if(this.invoice_for == 'PERSONAL' && this.isItemLevel == true){
                                            this.withCustomerItems()
                                        }
                                        else if(this.invoice_for == 'PERSONAL' && this.isItemLevel == false){
                                            this.withoutCustomerItems()
                                        }
                                        else if(this.invoice_for == 'COMPANY' && this.isItemLevel == true){
                                            this.withCompanyItems()
                                        }
                                        else if(this.invoice_for == 'COMPANY' && this.isItemLevel == false){
                                            this.withoutCompanyItems()
                                        }
                                        this.$modal.hide(this.modal_name)

                                    })
                            }
                        }
                    } else {
                        if (
                            this.customer.company_name == '' ||
                            this.customer.company_tax_code == '' ||
                            this.customer.company_address == ''
                        ) {
                            this.$swal({
                                title: 'Warning!',
                                text: 'Please Provide Mandatory Fields',
                                type: 'warning'
                            })
                        } else {
                            var emailToSend = []
                            for (var i = 0; i < this.emails.length; i++) {
                                if (this.emails[i].text.match(this.email_regex)) {
                                    this.invalidEmail = false
                                    emailToSend.push(this.emails[i].text)
                                } else {
                                    this.invalidEmail = true
                                    break
                                    // emailToSend = this.emails
                                }
                            }
                            if (!this.invalidEmail) {
                                this.$http
                                    .post('/reports/tax_invoice/email', {
                                        order_id: this.orderId,
                                        customer_id: this.customerId,
                                        outlet_id: this.outletId,
                                        emails: emailToSend,
                                        invoice_for: this.invoice_for,
                                        isItemLevel: this.isItemLevel
                                    })
                                    .then(res => {
                                        this.details = res.data.response.tax_details
                                        if(this.invoice_for == 'PERSONAL' && this.isItemLevel == true){
                                            this.withCustomerItems()
                                        }
                                        else if(this.invoice_for == 'PERSONAL' && this.isItemLevel == false){
                                            this.withoutCustomerItems()
                                        }
                                        else if(this.invoice_for == 'COMPANY' && this.isItemLevel == true){
                                            this.withCompanyItems()
                                        }
                                        else if(this.invoice_for == 'COMPANY' && this.isItemLevel == false){
                                            this.withoutCompanyItems()
                                        }
                                        this.$modal.hide(this.modal_name)
                                    })
                            }
                        }
                    }
                }
            },
            sendEmail() {
                if (
                    this.customer == '' ||
                    this.customer.first_name == '' ||
                    this.emails == ''
                ) {
                    this.$swal({
                        title: 'Warning!',
                        text: 'Please Fill The Required Fields',
                        type: 'warning'
                    })
                } else {
                    if (this.invoice_for == 'PERSONAL') {
                        if (
                            this.customer.first_name == '' ||
                            this.customer.phone_number == '' ||
                            this.customer.tax_code == '' ||
                            this.customer.personal_address == ''
                        ) {
                            this.$swal({
                                title: 'Warning!',
                                text: 'Please Provide Mandatory Fields',
                                type: 'warning'
                            })
                        } else {
                            var emailToSend = []
                            console.log(this.emails)
                            for (var i = 0; i < this.emails.length; i++) {
                                if (this.emails[i].text.match(this.email_regex)) {
                                    emailToSend.push(this.emails[i].text)
                                    this.invalidEmail = false
                                } else {
                                    this.invalidEmail = true
                                    break
                                    // emailToSend = this.emails
                                }
                            }
                            if (!this.invalidEmail) {
                                this.$http
                                    .post('/reports/tax_invoice/email', {
                                        order_id: this.orderId,
                                        customer_id: this.customerId,
                                        outlet_id: this.outletId,
                                        emails: emailToSend,
                                        invoice_for: this.invoice_for,
                                        isItemLevel: this.isItemLevel
                                    })
                                    .then(res => {
                                        this.details = res.response.tax_details
                                        if (res.data.status_id){
                                            this.$swal('Updated', 'Mail Sent Successfully', 'success')
                                        }
                                        else {
                                            this.$swal('Warning!', res.data.reason, 'warning')
                                        }
                                        this.$modal.hide(this.modal_name)
                                    })
                            }
                        }
                    } else {
                        if (
                            this.customer.company_name == '' ||
                            this.customer.company_tax_code == '' ||
                            this.customer.company_address == ''
                        ) {
                            this.$swal({
                                title: 'Warning!',
                                text: 'Please Provide Mandatory Fields',
                                type: 'warning'
                            })
                        } else {
                            var emailToSend = []
                            for (var i = 0; i < this.emails.length; i++) {
                                if (this.emails[i].text.match(this.email_regex)) {
                                    this.invalidEmail = false
                                    emailToSend.push(this.emails[i].text)
                                } else {
                                    this.invalidEmail = true
                                    break
                                    // emailToSend = this.emails
                                }
                            }
                            if (!this.invalidEmail) {
                                this.$http
                                    .post('/reports/tax_invoice/email', {
                                        order_id: this.orderId,
                                        customer_id: this.customerId,
                                        outlet_id: this.outletId,
                                        emails: emailToSend,
                                        invoice_for: this.invoice_for,
                                        isItemLevel: this.isItemLevel
                                    })
                                    .then(res => {
                                        EventBus.$emit('BillingManage', true)
                                        this.$modal.hide(this.modal_name)
                                        if (res.data.status_id)
                                            this.$swal('Updated', 'Mail Sent Successfully', 'success')
                                        else this.$swal('Updated', res.data.reason, 'warning')
                                    })
                            }
                        }
                    }
                }
            }
        },
        created() {
            this.getSearchCustomer()
            initialState()
        }
    }
</script>

<style scoped>
    .company {
        background: #005dae;
        padding-top: 20px;
        padding-left: 10px;
        padding-bottom: 20px;
        border-radius: 12px;
        margin-top: 10px;
        cursor: pointer;
    }

    .customerInfo {
        border: 1px solid #a8adb3;
        border-radius: 12px;
        padding: 10px;
        margin-top: 10px;
    }

    .customerInfoWidth {
        width: 280px;
        display: block;
        word-break: break-word;
    }

    .dropdown-v-select {
        background-color: #fff;
        position: absolute;
        width: 95.5%;
        z-index: 99;
        border: 1px solid #a8adb3;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        max-height: 186px;
        overflow: auto;
    }

    .dropdown-v-select div {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        cursor: pointer;
    }

    .dropdown-v-select div:hover {
        background-color: #00448b;
        cursor: pointer;
        color: #fff;
    }
</style>