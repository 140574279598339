export default {
    data() {
        return {
            count: 0,
            limit: 10,
            skip: 0,
            start: 1,
            end: 0,
            search: '',
            modal_msg: '',
            ajaxCallInProgress: false,
            showIllustrator: false,
            passed_key: {
                key: 'name',
                value: 1
            },
        }
    },
    methods: {
        addAddonSection() {
            this.$http.post("/add_ons/section/create", this.addonsection)
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.addonsection = {
                            name: '',
                            description: '',
                            status: true
                        }
                        this.$emit('savesection')
                        this.$modal.hide("addon-create-section");
                        this.$swal({
                            title: 'Addon Section  Added Successfully',
                            text: '',
                            type: 'success'
                        })
                    } else {
                        this.modal_msg = response.data.response;
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(response => {
                    this.modal_msg = response.data.response;
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                });
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey);
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value);
            this.getAllAddonsSections();
            // if (this.count == 0) this.getAllAddonsSections();
            // else this.count = 0;
        },
        getAllAddonsSections(param) {
            if (param == 'prev') this.count--;
            else if (param == 'next') this.count++;
            else if (param == 'init') {
                this.count = 0;
            } else if (param == 'search') {
                this.count = 0;
            }
            this.getAllAddonSection = []
            this.ajaxCallInProgress = true
            this.$http.post("/add_ons/section/get", {
                skip: this.count * this.limit,
                limit: this.limit,
                search_key: this.search,
                sort_key: this.passed_key.key,
                sort_value: this.passed_key.value
            }).then(res => {
                if (res.data.status_id == 1) {
                    this.getAllAddonSection = res.data.result
                    this.totalAddonSection = res.data.total_sections
                    this.start = this.count * this.limit + 1;
                    this.end = this.count * this.limit + this.limit < this.totalAddonSection ?
                        this.count * this.limit + this.limit : this.totalAddonSection
                }
                this.ajaxCallInProgress = false
                if (this.getAllAddonSection.length == 0 && !this.ajaxCallInProgress && !this.search) {
                    this.showIllustrator = true
                } else {
                    this.showIllustrator = false
                }
            })
        },
        saveEditAddonSection() {
            let name = this.editaddonsection.name
            let description = this.editaddonsection.description
            let status = this.editaddonsection.status
            let _id = this.editaddonsection._id
            let details = {
                name,
                description,
                status,
                _id
            }
            this.$http.post("/add_ons/section/edit", details)
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.$emit('editsection')
                        this.$modal.hide(this.modal_name);
                        this.$swal({
                            title: 'Addon Section Updated Successfully',
                            text: '',
                            type: 'success'
                        })
                    } else {
                        this.modal_msg = response.data.response;
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(response => {
                    this.modal_msg = response.data.response;
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                });
        },
        checkPageCount() {
            if(this.count >= 1){
                if(this.totalAddonSection <= this.count * this.limit + 1){                    
                    this.count--;
                }
            }
            this.getAllAddonsSections()
        }
    }
}