import { render, staticRenderFns } from "./CostProduct.vue?vue&type=template&id=866b4056&scoped=true&"
import script from "./CostProduct.vue?vue&type=script&lang=js&"
export * from "./CostProduct.vue?vue&type=script&lang=js&"
import style0 from "./CostProduct.vue?vue&type=style&index=0&id=866b4056&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "866b4056",
  null
  
)

export default component.exports