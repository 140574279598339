<template>
    <div class="page-layout blank promotions-manage">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="d-flex justify-content-between">
                    <div class="col-lg-4 col-md-6">
                        <div class="list-title" style="font-weight: 500;">
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                    <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                        <i class="icons icon-arrow-left"></i>
                                    </button>
                                </el-tooltip>
                            </router-link>
                            <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                                <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-right"></i>
                                </button>
                                </el-tooltip>
                            </router-link>
                            <a class="btn btn-secondary btn-smm" @click="showComboAdd()">+ Add New Combo</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="d-flex ml-3 mt-1 pull-right">
                            <span class="pointer exportButton font-weight-bold pl-2 d-flex align-items-center">
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg" />
                                <span class="text-white font-weight-bold ml-1 hidden-sm-down">Export</span>
                            </span>
                            <span class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2">
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/pdf-file.svg" />
                                <span class="text-white font-weight-bold ml-1 hidden-sm-down">Pdf</span>
                            </span>
                            <span class="ml-3 pointer printButton pl-2 d-flex align-items-center">
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/printer.svg" />
                                <span class="text-white font-weight-bold ml-1 hidden-sm-down">Print</span>
                            </span>
                        </div>
                    </div>
                </div>
            </h2>
            <div v-if="showIllustrator" class="row no-orders-row">
                <div class="col-lg-12 text-center">
                    <div class="noorders">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:25px;" />
                        <p class="text-center text-secondary fs-28 font-600 pt-5">No Combos Yet!!!</p>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="breadcome-heading d-xl-inline">
                            <div class="form-group formsearch has-warning has-feedback">
                                <input type="text" class="form-control textremove holder" v-model="search" @input="getAllCombo('search')" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search..."/>
                                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                            </div>
                        </div>
                        <div class="pull-right mr-1" v-if="total">
                            <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{total}}</span>
                            <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                                <button :disabled="count<=0" v-on:click="getAllCombo('prev')" :class="{'disabled':ajaxCallInProgress}" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-left"></i>
                                </button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Next" placement="top">
                                <button :disabled="end >= total" v-on:click="getAllCombo('next')" :class="{'disabled':ajaxCallInProgress}" type="button" style="padding:.1rem !important;" class="btn btn-icon d-none d-md-inline el-tooltip item">
                                    <i class="icon icon-chevron-right"></i>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 14px;">
                    <div style="font-size: 12px;color: #303031;font-weight: 600;padding: 5px 10px;text-align: center;border: 1px solid #00448b;border-radius: 18px;background-color: #fff;margin-top: -12px;;left: 0;right:0;margin-left: auto;margin-right: auto;width: 200px;position: absolute;z-index:3;">COMBOS</div>
                    <div class="page-layout carded left-sidebar" style="background-color: #00448b!important;padding: 10px!important;">
                        <div class="page-content-wrapper">
                            <div class="page-content">
                                <div class="demo-content">
                                    <table class="table table-scroll">
                                        <thead>
                                        <tr>
                                            <th>
                                                <span v-on:click="setPasskey('name')" class="cursor-th" >
                                                    <span v-if="passed_key.key=='name' && passed_key.value== -1" :class="{'hide-arrow': passed_key.key != 'name'}" class="sorting-icon">
                                                        <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                                                    </span>
                                                    <span style="font-size: 16px !important;" v-else :class="{'hide-arrow': passed_key.key != 'name'}" class="sorting-icon">
                                                        <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                                                    </span> COMBO NAME
                                                </span>
                                            </th>
                                            <th>Category</th>
                                            <th>Sub Category</th>
                                            <th>Product Type</th>
                                            <th>STATUS</th>
                                            <th>ADMIN</th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="ajaxCallInProgress">
                                            <tr style="border-bottom:none !important;">
                                            <td colspan="6" class="text-center justify-content-center">
                                                <div class="digi-table-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                </div>
                                            </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="combos.length == 0 && !ajaxCallInProgress">
                                            <tr style="border-bottom:none !important;">
                                                <td v-if="search" colspan="6" class="text-center justify-content-center">
                                                    <strong style="display:block;padding-top:38px;">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                                                    <br />
                                                    <p
                                                        class="text-secondary font-20"
                                                        style="font-size: 22px; font-weight: 600;"
                                                    >No Combos Found</p>
                                                    </strong>
                                                </td>
                                                <td v-if="!search" colspan="6" class="text-center justify-content-center">
                                                    <strong style="display:block;padding-top:38px;">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                                                    <br />
                                                    <p
                                                        class="text-secondary font-20"
                                                        style="font-size: 22px; font-weight: 600;"
                                                    >No Combos Found</p>
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(combo,index) in combos" :key="index">
                                                <td class="text-secondary" @click="showCombo(combo._id)" style="cursor:pointer !important;max-width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"  :title="combo.name">{{combo.name}}</td>
                                                <td style="max-width: 230px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;" :title="combo.category_id.name">{{combo.category_id.name}}</td>
                                                <td style="max-width: 220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;" :title="combo.sub_category_id.name">{{combo.sub_category_id.name}}</td>
                                                <td style="max-width: 230px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;" v-if="combo.product_type_id" :title="combo.product_type_id.name">{{combo.product_type_id.name || '-'}}</td>
                                                <td style="max-width: 230px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;" v-else>-</td>
                                                <td>
                                                    <el-switch v-model="combo.status" @change="updateComboStatus(combo._id,combo.status)"></el-switch>
                                                </td>
                                                <td>
                                                    <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                        <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;" @click="editComboDetails(combo._id)">
                                                            <i class="icon icon-pencil"></i>
                                                        </button>
                                                    </el-tooltip>
                                                    <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                        <button type="button" class="btn btn-icon actionicons ml-3" style="padding: 0.4rem .2rem;" @click="deleteCombo(combo)">
                                                            <i class="icon icon-delete"></i>
                                                        </button>
                                                    </el-tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <add-combo modal_name="combo-add" @saveCombo="getAllCombo()"></add-combo>
        <edit-combo modal_name="combo-edit" @editCombo="getAllCombo()"></edit-combo>
        <view-combo modal_name="combo-view" @saved="getAllCombo()"></view-combo>
    </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event';
const AddCombo = () => import('./AddCombo');
const ViewCombo = () => import('./ViewCombo');
const EditCombo = () => import('./EditCombo')
import combo from './mixins/combo.js'
export default {
    mixins: [combo],
    data(){
        return{
            start:1,
            end:10,
            ajaxCallInProgress:false,
            total:0,
            count:0,
            search:'',
            combos:[],
            skip:0,
            limit:10,
            passed_key:{
                key: 'name',
                value: 1
            },
            showIllustrator:false
        }
    },
    methods:{
        showComboAdd(){
            // EventBus.$emit('getComboDetails')
            // this.$modal.show('combo-add')
            this.$router.push({
                name: 'AddCombo',
            })
        },
        editComboDetails(id){
            // let params = {
            //     _id:id
            // }
            // EventBus.$emit('getEditData',params)
            // this.$modal.show('combo-edit')
            this.$router.push({
                name: 'EditCombo',
                query: { id: id },
            })
        },
        showCombo(id){
            // let params = {
            //     _id:id
            // }
            // EventBus.$emit('viewcombo',params)
            // this.$modal.show('combo-view')
            this.$router.push({
                name: 'ViewCombo',
                query: { id: id },
            })
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getAllCombo()
        },
        async getAllCombo(param){
            try {
                if (param == 'prev') this.count--
                else if (param == 'next') this.count++
                else if (param == 'init') {
                    this.count = 0
                } else if (param == 'search') {
                    this.count = 0
                }
                this.ajaxCallInProgress = true
                this.combos = []
                let params = {
                    search:this.search,
                    skip:this.count * this.limit,
                    limit:this.limit,
                    sort_key:this.passed_key.key,
                    sort_value:this.passed_key.value
                }
                let res = await this.getAllComboApi(params)
                this.ajaxCallInProgress = false
                this.combos = res.combos
                this.total = res.total_count
                this.start = this.count * this.limit + 1
                this.end =this.count * this.limit + this.limit <
                            this.total ?
                            this.count * this.limit + this.limit :
                            this.total
                if (this.combos.length == 0 && !this.ajaxCallInProgress && !this.search) {
                    this.showIllustrator = true
                } else {
                    this.showIllustrator = false
                }
            } catch (reason) {
                
            }
        },
        async updateComboStatus(id,status){
            try {
                let params = {
                    _id:id,
                    status:status
                }
                let res = await this.updateComboStatusApi(params)
                this.$toasted.global.success(res.message)
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        deleteCombo(combo){
            this.$swal({
                title: 'Are you sure?',
                text:
                    'Are you sure To delete the Combo ' +
                    combo.name +
                    ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#00448b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.confirmDelete(combo)
                }
            })
        },
        async confirmDelete(combo){
            try {
                let params ={
                    _id:combo._id
                } 
                let res = await this.confirmDeleteApi(params)
                this.getAllCombo()
                this.$swal({
                    title: res.response,
                    text: '',
                    type: 'success'
                })
            } catch (reason) {
                this.$swal({
                    title: reason,
                    text: '',
                    type: 'warning'
                })
            }
        }
    },
    mounted(){
        this.getAllCombo()
    },
    components:{
        AddCombo,
        ViewCombo,
        EditCombo
    }
}
</script>
<style scoped>
.exportButton {
  width: auto;
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #1a9347;
  font-size: 14px;
}
.exportButton img {
  width: 13px;
}
.pdfButton img {
  width: 13px;
}
.printButton img {
  width: 13px;
}
.pdfButton {
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #e82828;
  width: 66px;
  font-size: 14px;
}
.printButton {
  width: 66px;
  font-size: 14px;
  padding-right: 7px;
  height: 25px;
  border-radius: 4px;
  background-color: #00448b;
}
</style>