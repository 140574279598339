<template>
    <div class="w-100 view-cour-comp pt-1">
        <div class="d-flex justify-content-center align-items-center" v-if="ajaxCallInProgress">
            <div class="digi-table-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div v-else-if="!ajaxCallInProgress && order_message">
            <h1 class="text-danger text-center" >{{order_message}}</h1>
        </div>
        <div v-else :class="{OnHoldClass:orderdetails.isOrderOnHold}">
            <div class="top-view-order">
                <div class="page-header text-auto p-1 Feb pt-4 pb-3">
                    <!-- HEADER CONTENT-->
                    <div class="header-content d-flex font-600">
                        <!-- HEADER TOP -->
                        <div class="row w-100 text-white">
                            <div class="col-md-6" style="border-right: 1px solid #fff;">
                                <div class="d-flex pb-3">
                                    <div class="col" v-if="orderdetails.table_number">Table No :
                                        {{orderdetails.table_number}}</div>
                                    <div class="col text-capitalize" v-else>{{orderdetails.order_type || 'Take Away'}}</div>
                                    <div class="col text-right">
                                        <i class="icon icon-clock"
                                            style="color:#fff!important;font-size: 15px!important;"></i>
                                        {{orderdetails.start_time}}
                                    </div>
                                </div>
                                <div class="d-flex pb-3">
                                    <div class="col">Order ID : {{orderdetails.order_id}}</div>
                                    <div class="col text-right">Sequence No : {{orderdetails.order_number}}</div>
                                </div>
                                <div class="d-flex pb-3">
                                    <div class="col">Customer Name : {{orderdetails.customer_name}}</div>
                                </div>
                                <div v-if="order_details" class="row pb-4">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 pt-3">
                                        <span v-if="order_details.response" class="pl-3 d-flex w-100">
                                            <div class="pt-3">
                                                <a class="notification-cart">
                                                    <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/white-cart.png"
                                                        style="height: 12px;margin-bottom:3px;" class="pr-1" />
                                                    <span class="badge-for-cart">{{order_details.response.items_count}}</span>
                                                </a>
                                                <span class="total-take-order" v-if="order_details.response.items_count > 0">
                                                    Total:
                                                    <span class="total-amount text-white">{{order_details.order_total}}</span>
                                                </span>
                                                <span class="total-take-order whiteSpace" v-else>Cart Empty</span>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 pt-3">
                                        <button class="pointer d-flex justify-content-between bg-orange-image align-items-center pl-2 pr-2 py-2 b-r-7 w-100 ml-sm-auto ml-0" v-if="order_details.response.status !== 'pending' && order_details.payment_status == 'UNPAID'" :disabled="!this.loggedInUser.configure_payment_device" @click="payOrder" id="chechkout_button" style="max-width: 200px;">
                                            <span>
                                                <img src="https://cdn.digicollect.com/cdn/posv2/pay-img.svg" />
                                            </span>
                                            <span class="fw-800">Pay</span>
                                            <span>
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/checkout-arrow.svg" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" style="border-right: 1px solid #fff;">
                                <div class="d-block pb-3">
                                    <div v-if="order_details" class="d-flex">
                                        <div class="col-3" style="white-space: nowrap;">Order Status</div>
                                        <div v-if="order_details.response" class="col text-right">
                                            <template v-if="order_details.response.status !== 'pending' && order_details.payment_status == 'UNPAID'">
                                                <el-tooltip class="item" effect="dark" content="Add Item" placement="top" >
                                                    <span v-if="!orderdetails.isPrepaid" class="mr-4"
                                                        style="cursor:pointer;" @click="addMoreItems(orderdetails._id)">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus.svg" />
                                                    </span>
                                                </el-tooltip>
                                            </template>
                                            <el-tooltip class="item" effect="dark" content="Item Summary"
                                                placement="top">
                                                <span class="mr-3" style="cursor:pointer;"
                                                    @click="itemSummary()">
                                                    <img
                                                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/newspaper.svg" />
                                                </span>
                                            </el-tooltip>
                                            <el-dropdown trigger="click" class="text-right" >
                                                <el-tooltip class="item" effect="dark" content="Convention" placement="top">
                                                    <span class="mr-3" style="cursor:pointer;">
                                                        <img src="https://cdn.digicollect.com/cdn/posv2/images/info_order.svg" />
                                                    </span>
                                                </el-tooltip>
                                                <el-dropdown-menu slot="dropdown" class="priority-cursor">
                                                    <span class="ml-5">Convention :</span>
                                                    <el-dropdown-item disabled class="mt-1">
                                                        <span class="text-black">O - Ordered</span>
                                                    </el-dropdown-item>
                                                    <el-dropdown-item disabled>
                                                        <span class="text-black">S - Started</span>
                                                    </el-dropdown-item>
                                                    <el-dropdown-item disabled>
                                                        <span class="text-black">F - Finished</span>
                                                    </el-dropdown-item>
                                                    <el-dropdown-item disabled>
                                                        <span class="text-black">D - Delivered</span>
                                                    </el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </div>
                                    </div>
                                    <div class="view-vizard-big d-flex mt-6 mb-3">
                                        <div class="stepwizard" v-if="orderdetails.order_status"
                                            style="left:6% !important;">
                                            <div class="stepwizard-row setup-panel maxstepwizard">
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.active == 1,'redCircle': orderdetails.order_status.active == 0,'orangeCircle': orderdetails.order_status.active == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.active == 1,'redCircle': orderdetails.order_status.active == 0,'orangeCircle': orderdetails.order_status.active == 2}">O</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.fire == 1,'redCircle': orderdetails.order_status.fire == 0,'orangeCircle': orderdetails.order_status.fire == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.fire == 1,'redCircle': orderdetails.order_status.fire == 0,'orangeCircle': orderdetails.order_status.fire == 2}">S</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.prepared == 1,'redCircle': orderdetails.order_status.prepared == 0,'orangeCircle': orderdetails.order_status.prepared == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.prepared == 1,'redCircle': orderdetails.order_status.prepared == 0,'orangeCircle': orderdetails.order_status.prepared == 2}">F</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step" style="left:0% !important;">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': orderdetails.order_status.delivered == 1,'redCircle': orderdetails.order_status.delivered == 0,'orangeCircle': orderdetails.order_status.delivered == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': orderdetails.order_status.delivered == 1,'redCircle': orderdetails.order_status.delivered == 0,'orangeCircle': orderdetails.order_status.delivered == 2}">D</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-block pb-3 display_status_checkbox">
                                    <div class="col pb-1">Display Status for :</div>
                                    <div class="col">
                                        <el-checkbox v-model="orderCourse_display" :disabled="!disable_course">
                                            <span style="color:#fff !important;font-size: 13px !important;"
                                                class="font-weight-bold">Courses</span>
                                        </el-checkbox>
                                        <el-checkbox v-model="orderItem_display" :disabled="!disable_item">
                                            <span style="color:#fff !important;font-size: 13px !important;"
                                                class="font-weight-bold">Items</span>
                                        </el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-content-card">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="col-4" style="color:#303031!important;">ITEMS</th>
                            <!-- <th></th>
                            <th></th> -->
                            <th class="text-center col-4" style="color:#303031!important;padding: 1.4rem 0.3rem !important;">QTY</th>
                            <th class="text-center col-4" style="color:#303031!important;width:170px;min-width:170px;max-width:170px;">STATUS</th>
                        </tr>
                    </thead>
                    <tbody v-if="ajaxCallInProgress">
                        <tr>
                            <td colspan="3" class="text-center">
                                <div class="digi-table-loader">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else-if="orderdetailsCourse.length <=0">
                        <tr>
                            <td colspan="3" class="text-center">
                                <img
                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_data_view_order.svg" />
                                <br />
                                <span class="text-secondary font-600" style="font-size:18px!important;">
                                    All Items are Deleted or Moved
                                    <br />to Another Table
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody style="background: #f6f6f6!important;" v-else>
                        <template v-for="(course,cindex) in orderdetailsCourse">
                            <tr style="background-color: #ddecff;height: 40px;" :key="cindex">
                                <td style="padding:0.2rem 1.2rem;color: #303031;" class="font-600">Course
                                    {{course.course}}</td>
                                <!-- <td style="padding:0.2rem 1.2rem;color: #303031;" class="whiteSpace font-600">
                                </td>
                                <td style="padding:0.2rem 1.2rem;color: #303031;" class="whiteSpace font-600"
                                    v-if="course.showHold">
                                </td>
                                <td v-else></td> -->
                                <td style="padding:0.2rem 0.3rem !important;color: #303031;"></td>
                                <td style="padding:0.2rem 1.2rem;" class="text-center">
                                    <div v-if="course.isHold">
                                        <span class="fs-14 text-danger fw-600">ON HOLD</span>
                                    </div>
                                    <div v-else>
                                    <div class="view-vizard-small d-flex" v-if="orderCourse_display && course.course_status">
                                        <div class="stepwizard">
                                            <div class="stepwizard-row setup-panel">
                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.active == 1,'redCircle': course.course_status.active == 0,'orangeCircle': course.course_status.active == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.active == 1,'redCircle': course.course_status.active == 0,'orangeCircle': course.course_status.active == 2}">O</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.fire == 1,'redCircle': course.course_status.fire == 0,'orangeCircle': course.course_status.fire == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.fire == 1,'redCircle': course.course_status.fire == 0,'orangeCircle': course.course_status.fire == 2}">S</a>
                                                    </div>
                                                </div>

                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.prepared == 1,'redCircle': course.course_status.prepared == 0,'orangeCircle': course.course_status.prepared == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.prepared == 1,'redCircle': course.course_status.prepared == 0,'orangeCircle': course.course_status.prepared == 2}">F</a>
                                                    </div>
                                                </div>
                                                <div class="stepwizard-step">
                                                    <div class="wizardSteps pointer"
                                                        :class="{ 'greenCircle': course.course_status.delivered == 1,'redCircle': course.course_status.delivered == 0,'orangeCircle': course.course_status.delivered == 2}">
                                                        <a class="btn-circle"
                                                            :class="{ 'greenCircle': course.course_status.delivered == 1,'redCircle': course.course_status.delivered == 0,'orangeCircle': course.course_status.delivered == 2}">D</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="view-vizard-small d-flex justify-content-center" v-else-if="!orderCourse_display">
                                        -
                                    </div>
                                    </div>
                                </td>
                            </tr>
                            <template v-for="(item,index) in course.item_details">
                                <tr style="height: 40px;" :key="index">
                                    <td style="padding:0.4rem 1.2rem;" class="text-secondary font-600">
                                        <el-tooltip v-if="item.product_type == 'Non-Veg'" content="Non-Veg"
                                            placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                style="margin-top: -4px;width: 11px;" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.product_type == 'Veg'" content="Veg"
                                            placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                style="margin-top: -4px;width: 11px;" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.product_type == 'Egg'" content="Egg"
                                            placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                style="margin-top: -4px;width: 11px;" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.product_type == 'Pescatarian'" content="Pescatarian"
                                            placement="top">
                                            <img style="width:11px;margin-top: -4px;"
                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme//https://cdn.digicollect.com/cdn/posv2/psec.svg" />
                                        </el-tooltip>
                                        <span v-if="item.hasVariant">{{item.product_name}}/ {{item.variant_name}}
                                            <span v-if="item.is_combo">
                                                <el-popover placement="left-end" width="200" trigger="hover"
                                                    popper-class="combo-popover">
                                                    <div v-for="(ic,index) in item.combo_details" :key="index">
                                                        <span>{{ic.product_name}} X {{ic.quantity}}</span>
                                                    </div>
                                                    <el-button slot="reference"
                                                        style="background: transparent !important;padding:12px 0px !important;">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                    </el-button>
                                                </el-popover>
                                            </span>
                                        </span>
                                        <span v-else>{{item.product_name}}
                                            <span v-if="item.is_combo">
                                                <el-popover placement="left-end" width="200" trigger="hover"
                                                    popper-class="combo-popover">
                                                    <div v-for="(ic,index) in item.combo_details" :key="index">
                                                        <span>{{ic.product_name}} X {{ic.quantity}}</span>
                                                    </div>
                                                    <el-button slot="reference"
                                                        style="background: transparent !important;padding:12px 0px !important;">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                                                    </el-button>
                                                </el-popover>
                                            </span>
                                        </span>
                                        <br />
                                        <span v-if="item.item_note !=''">
                                            Note :
                                            <span style="color:red!important; word-break: break-all;">{{item.item_note}}</span>
                                        </span>
                                    </td>
                                    <!-- <td style="padding:0.4rem 1.2rem;" class="text-center"></td>
                                    <td style="padding:0.4rem 1.2rem;" class="text-center"></td> -->
                                    <td class="text-secondary font-600 text-center" style="padding:0.4rem 0.3rem !important;">
                                        {{item.selected_quantity}}</td>
                                    <td style="padding:0.4rem 1.2rem;" class="text-center">
                                        <div v-if="course.isHold">
                                            <span class="fs-14 text-danger fw-600">ON HOLD</span>
                                        </div>
                                        <div v-else>
                                        <div class="" v-if="item.item_status == 'refund'">
                                            <span class="fs-14 text-danger fw-600">REFUND</span>
                                        </div>
                                        <div v-else>
                                        <div class="view-vizard-small d-flex" v-if="orderItem_display && item.item_status">
                                            <div class="stepwizard">
                                                <div class="stepwizard-row setup-panel">
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer" :class="{'redCircle':item.item_status == 'pending', 'greenCircle':item.item_status != 'pending'}">
                                                            <a class="btn-circle" :class="{'redCircle':item.item_status == 'pending', 'greenCircle':item.item_status != 'pending'}">O</a>
                                                        </div>
                                                    </div>
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer"
                                                            :class="{ 'greenCircle': item.isFired,'redCircle':!item.isFired}">
                                                            <a class="btn-circle"
                                                                :class="{ 'greenCircle':item.isFired,'redCircle':!item.isFired}">S</a>
                                                        </div>
                                                    </div>
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer"
                                                            :class="{ 'greenCircle': item.isPrepared,'redCircle':!item.isPrepared}">
                                                            <a class="btn-circle"
                                                                :class="{ 'greenCircle': item.isPrepared,'redCircle':!item.isPrepared}">F</a>
                                                        </div>
                                                    </div>
                                                    <div class="stepwizard-step">
                                                        <div class="wizardSteps pointer"
                                                            :class="{ 'greenCircle': item.isDelivered,'redCircle':!item.isDelivered}">
                                                            <a class="btn-circle"
                                                                :class="{ 'greenCircle': item.isDelivered,'redCircle':!item.isDelivered}">D</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="view-vizard-small d-flex justify-content-center" v-else-if="!orderItem_display">
                                            -
                                        </div>
                                        </div>
                                        </div>
                                    </td>
                                </tr>
                                <!-- <tr v-if="item.addons.length>0" :key="index+'indexindex'">
                                    <td colspan="9" style="padding: 0px!important;">
                                        <div class="b-r-7 pl-2 pt-1 pb-1 ml-3 mr-3 mb-2"
                                            style="background-color:#fff !important;border-radius: 12px;">
                                            <div class="text-green font-600">Add-On :</div>
                                            <div class="d-flex flex-wrap mt-1">
                                                <div v-for="(addon,addon_index) in item.addons" :key="addon_index"
                                                    class="d-flex justify-content-between align-items-center">
                                                    <div class="col pl-0 mb-1">
                                                        <div class="d-flex ml-0 pl-0">
                                                            <el-tooltip v-if="addon.type=='Non-Veg'" content="Non-Veg"
                                                                placement="top">
                                                                <img class="mr-1"
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                                    style="width: 11px;" />
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="addon.type=='Veg'" content="Veg"
                                                                placement="top">
                                                                <img class="mr-1"
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                                    style="width: 11px;" />
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="addon.type=='Egg'" content="Egg"
                                                                placement="top">
                                                                <img class="mr-1"
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                                    style="width: 11px;" />
                                                            </el-tooltip>
                                                            <el-tooltip v-else-if="addon.type == 'Pescatarian'"
                                                                content="Pescatarian" placement="top">
                                                                <img class="mr-1" style="width:11px;"
                                                                    src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                            </el-tooltip>
                                                            <span class="font-weight-bold"
                                                                :class="{'ml-1':addon.type != ''}">{{addon.name}}</span>
                                                            <span class="text-green ml-1 font-weight-bold">X
                                                                {{addon.quantity}}</span>
                                                            <span class="text-green font-weight-bold"
                                                                v-if="addon_index != item.addons.length - 1">,</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->
                                <!-- <tr v-if="item.modifiers.length > 0" :key="index+'indexindex2'">
                                    <td colspan="6" style="padding: 0.6rem 1.2rem!important;background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.6rem 1.2rem!important;">
                                        <div>
                                            <span class="text-green font-600">Modifiers :</span><br>
                                            <div class="flex-adons pt-1" v-for="(modifier, modifier_index) in item.modifiers" :key="modifier_index+'modifier_index'" >
                                                <div class="showType veg" >
                                                    <el-tooltip content="Veg" placement="top">
                                                        <div></div>
                                                    </el-tooltip>
                                                </div>
                                                <span class="font-weight-bold text-capitalize">{{modifier.modifier_name}}</span>
                                                <span class="ml-1" v-if="modifier.modifier_list.length > 0">-</span>
                                                    <span v-for="(modifier_item, modifier_item_index) in modifier.modifier_list" :key="'modifier_item_index'+modifier_item_index" class="ml-1 text-capitalize"
                                                        >
                                                        {{modifier.modifier_list[modifier_item_index]}}
                                                        <span v-if="modifier.modifier_list.length > 1 && (modifier_item_index < (modifier.modifier_list.length - 1))">,</span>
                                                        </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->
                                <template v-if="item.modifiers">
                                <tr v-if="item.modifiers.hasOwnProperty('for_secondary') || item.modifiers.hasOwnProperty('for_primary')" :key="index">
                                    <td colspan="3" style="padding: 0px!important;">
                                        <div class="b-r-7 pl-2 pt-2 pb-1 ml-1 mr-3 mb-2"
                                            style="background-color:#fff !important;border-radius: 0px 0px 4px 4px;">
                                            <div>
                                                <div class="bg-white mb-2" v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                    <span class="font-weight-bold">{{modifier.m_item_name}}</span>
                                                    <span class="text-green ml-1 font-weight-bold" v-if="modifier.quantity > 0"> X {{modifier.quantity}}</span>
                                                </div>
                                                
                                                <div v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index"
                                                    class="d-block w-100 mb-2 bg-white">
                                                    <div class="text-blue font-600 fs-14 py-1">
                                                        <span>{{modifier.modifier_name}}</span>
                                                    </div>
                                                    <div class="col pl-0 mb-1" v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                        <div class="d-flex ml-0 pl-0">
                                                            <span class="font-weight-bold"
                                                                >{{modifier_item.m_item_name}}</span>
                                                            <span v-if="modifier_item.quantity > 0" class="text-green ml-1 font-weight-bold"> X {{modifier_item.quantity}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="onholddiv" v-if="orderdetails.isOrderOnHold">
            <button class="btn btn-checkout">Order On
                Hold</button>
        </div>
        <pay-bill :for_approvals="false" @update_cust="update_cust" modal_name="pay-bill" :summary="order" :isPopUp="isPopUp"
            @updateCheckout="updateSummary" :editOrder="false" :inProgress="inProgress" :isDelivered="isDelivered"
            @close="closePayBill" v-if="showbillpay" :storeorderId="storeorderId" :sourcemanage="'viewOrder'">
        </pay-bill>
        <delete-item :order_id="order_id" :delete_quantity="delete_quantity" :item_id="item_id"
            :product_name="product_name" :product_quantity="delete_quantity" :DeleteOrderDiv="DeleteOrderDiv"
            :temp_order_id="temp_order_id" modal_name="delete-item-modal" :isOrderActive="true"
            @checkorderStatus="checkorderStatus" @checkitemStatus="checkitemStatus" v-if="deletevieworder">
        </delete-item>
        <move-items @hideMoveItems="hideMoveItems" v-if="moveItemsModal == true" :move_orders="order"
            :created_time="start_time" modal_name="move-items"></move-items>
        <edit-item-summary @hideItemsSummary="hideItemsSummary" v-if="showItemSummary" modal_name="item-summary">
        </edit-item-summary>
		<payment-method-b-2-c modal_name="method_payment_b2c" from_screen="my_order" :customer_name="orderdetails.customer_name" :order="pay_modal_order_data" @closePayment="closePaymentMethodB2C" v-if="showpay_b2c"></payment-method-b-2-c>
    </div>
</template>
<script>
    import { EventBus } from './eventBus/nav-bar-event.js'
    import ManageOrderService from './mixins/manage_order.js'
    import orderServices from './mixins/orders'
    import PayBill from './PayBill'
    import DeleteItem from './DeleteItem'
    import EditItemSummary from './EditItemSummary'
    const MoveItems = () => import('./MoveItems.vue')
    import global from './global.js'
	const PaymentMethodB2C = () => import('./PaymentMethodB2C')
    export default {
        mixins: [ManageOrderService, orderServices],
        data() {
            return {
                items_actions: '',
                noOfPax: 1,
                hold_order: '',
                hold_course: '',
                orderdetails: '',
                orderdetailsCourse: '',
                showPreparedCard: false,
                isPrepared: false,
                isDelivered: false,
                isHold: false,
                isFired: false,
                orderType: null,
                hold_Status: false,
                order: '',
                isPopUp: false,
                order_id: '',
                delete_quantity: '',
                item_id: '',
                product_name: '',
                product_quantity: '',
                DeleteOrderDiv: false,
                temp_order_id: '',
                selectedOrderId: '',
                moveItemsModal: false,
                start_time: '',
                order_id: null,
                orderCourse_display: false,
                orderItem_display: false,
                disable_course: false,
                disable_item: false,
                inProgress: false,
                deletevieworder: false,
                showbillpay: false,
                ajaxCallInProgress: false,
                storeorderId: '',
                showEditItem: false,
                order_details: null,
                summary_loading : false,
                showItemSummary : false,
                order_message: '',
                from_edit: true,
                showpay_b2c: false,
                pay_modal_order_data: {
                    total: 0,
                    type: ''
                }
            }
        },
        components: {
            PayBill,
            DeleteItem,
            MoveItems,
            EditItemSummary,
            PaymentMethodB2C
        },
        methods: {
            hideMoveItems() {
                this.getOrderById()
                this.moveItemsModal = false
                this.$modal.hide('move-items')
            },
            hideItemsSummary() {
                this.showItemSummary = false;
            },
            showMoveItems(order_id, order) {
                this.start_time = order.start_time
                if (order_id) {
                    this.selectedOrderId = order_id
                }
                this.$http.post('/orders/summary', {
                        order_id: this.selectedOrderId,
                        in_progress: true,
                        for_move_items: true
                    })
                    .then(res => {
                        if (res.data.status_id == 1) {
                            this.order = res.data.order
                            this.inProgress = true
                            this.isDelivered = false
                            this.moveItemsModal = true
                            setTimeout(() => {
                                this.$modal.show('move-items')
                            }, 300)
                        } else {
                            this.$toasted.global.error(res.data.reason)
                        }
                    })
            },
            DeleteModal(item_id, quantity, product_name, variant_name = '') {
                this.deletevieworder = true
                this.item_id = item_id
                this.delete_quantity = quantity
                this.order_id = this.$route.params.id
                if (variant_name) {
                    this.product_name = product_name + '/' + variant_name
                } else {
                    this.product_name = product_name
                }
                this.temp_order_id = ''
                this.DeleteOrderDiv = false
                this.$modal.show('delete-item-modal')
            },
            EditModal(item_id) {

            },
            async itemSummary(){
                this.showItemSummary = true;
                setTimeout(() => {
                    this.$modal.show('item-summary');
                }, 500);
            },
            DeleteOrderModal(order_id, temp_order_id) {
                this.deletevieworder = true
                this.item_id = ''
                this.delete_quantity = ''
                this.product_name = ''
                this.order_id = order_id
                this.temp_order_id = temp_order_id
                this.DeleteOrderDiv = true
                this.$modal.show('delete-item-modal')
            },
            cancelDeleteItem() {
                this.deletevieworder = false
                this.$modal.hide('delete-item-modal')
            },
            addMoreItems(orderId) {
                localStorage.setItem("edit_item",true)
                if(this.orderdetails.order_type == 'Online | store_company'){
                    window.location.href = global.POS_URL+`/place_order?outlet_id=${this.loggedInUser.outlet_id}&order_type=${'Online'}&delivery_company=${'store_company'}&order_id=${orderId}&from_edit=${this.from_edit}`;
                }
                else{
                    window.location.href = global.POS_URL+`/place_order?outlet_id=${this.loggedInUser.outlet_id}&table_id=${this.orderdetails.table_number}&order_id=${orderId}&from_edit=${this.from_edit}`
                }
            },
            updateSummary() {
                this.showPayBill()
            },
            update_cust() {
                this.getOrderById()
            },
            closePayBill(boolean) {
                this.showbillpay = false
                this.$modal.hide('pay-bill')
                if (boolean) {
                    this.$router.push({
                        name: 'ManageOrder'
                    })
                }
            },
            showPayBill(order_id) {
                this.storeorderId = order_id
                this.showbillpay = true
                if (order_id) {
                    this.selectedOrderId = order_id
                }
                let params = {
                    order_id: this.selectedOrderId,
                    web: true
                }
                if (!this.orderdetails.isDelivered) params.in_progress = true
                this.$http.post('/orders/summary', params).then(res => {
                    try{
                        if (res.data.status_id == 1) {
                            this.order = res.data.order
                            EventBus.$emit('summaryChanged', res.data.order)
                            this.isDelivered = true
                            this.inProgress = false
                            setTimeout(() => {
                                this.$modal.show('pay-bill')
                            }, 100)
                        } else {
                            this.$toasted.global.error(res.data.reason)
                        }
                    }
                    catch(error){
                        this.$toasted.global.error("Order Already Moved to Different State")
                    }
                })
            },
            manageorderScreen() {
                if (this.$route.params && this.$route.params.dineIn) {
                    this.$router.push({
                        name: 'OrderTableView'
                    })
                } else {
                    this.$router.push({
                        name: 'ManageOrder',
                        params: {
                            orderType: this.$route.params.orderType
                        }
                    })
                }
            },
            checkorderStatus() {
                this.manageorderScreen()
            },
            async checkitemStatus() {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'WEB'
                    }
                    if (params.order_id) {
                        let res = await this.getorderDetails(params)
                        if (res.response.course_details.length) {
                            this.maintainOrderState(res)
                        } else {
                            this.manageorderScreen()
                        }
                    }
                } catch (reason) {}
            },
            maintainOrderState(res) {
                this.orderdetails = res.response.order_details
                console.log(this.orderdetails)
                this.orderdetailsCourse = res.response.course_details
                for (var course_index in this.orderdetailsCourse) {
                    for (var item_index in this.orderdetailsCourse[course_index].item_details) {
                        if (this.orderdetailsCourse[course_index].item_details[item_index].item_status == 'prepared') {
                            this.orderdetailsCourse[course_index].item_details[item_index].preparedCount++
                            this.orderdetailsCourse[course_index].item_details[item_index].isPrepared = true
                            this.orderdetailsCourse[course_index].item_details[item_index].isHold = false
                            this.orderdetailsCourse[course_index].item_details[item_index].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[item_index].isFired = true
                            this.orderdetailsCourse[course_index].item_details[item_index].isDelivered = false
                        } else if (this.orderdetailsCourse[course_index].item_details[item_index].item_status ==
                            'placed') {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'preparing'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'delivered'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'hold'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                        } else if (
                            this.orderdetailsCourse[course_index].item_details[item_index]
                            .item_status == 'unavailable'
                        ) {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = true
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = true
                        } else {
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isDelivered = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isPrepared = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isHold = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].unAvailable = false
                            this.orderdetailsCourse[course_index].item_details[
                                item_index
                            ].isFired = false
                        }
                    }
                }
            },
            async getOrderById() {
                try {
                    this.ajaxCallInProgress = true
                    let res = await this.getorderB2CDetails()
                    this.order_details = res
                    this.stateMaintain(res)
                    this.maintainOrderState(res)
                    this.ajaxCallInProgress = false
                } catch (reason) {
                    this.$toasted.global.error(reason)
                    this.order_message = reason
                    this.ajaxCallInProgress = false
                    if(this.order_message == 'Order Closed Successfully.') {
                        if(this.loggedInUser.role == 'customer'){
                            window.location.href = localStorage.getItem("set_location")
                        }
                    }
                }
            },
            stateMaintain(res) {
                if (res.response.order_details.display_status) {
                    this.disable_course = res.response.order_details.display_status.courses
                    this.disable_item = res.response.order_details.display_status.items
                    this.orderCourse_display =
                        res.response.order_details.display_status.courses
                    this.orderItem_display = res.response.order_details.display_status.items
                }
            },
            incrementPax() {
                if (this.noOfPax == 1000) {
                    return
                }
                this.noOfPax = Number(this.noOfPax) + 1
            },
            decrementPax() {
                if (this.noOfPax == 1) {
                    return
                }
                this.noOfPax = Number(this.noOfPax) - 1
            },
            placeOrder() {

            },
            // Send Together For Order,Course
            async sendAllTogetherOrder(sendallTogether) {
                try {
                    let params = {
                        target: 'ORDER',
                        action: sendallTogether,
                        order_id: this.$route.params.id,
                        get_details: true
                    }
                    let res = await this.sendAllTogetherApiCall(params)
                    let temp_res = {
                        response: res
                    }
                    this.maintainOrderState(temp_res)
                    this.$toasted.global.success(res.message)
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async sendTogetherCourse(sendtogethercourse, courseno) {
                try {
                    let params = {
                        target: 'COURSE',
                        action: sendtogethercourse,
                        course: courseno,
                        order_id: this.$route.params.id,
                        get_details: true
                    }
                    let res = await this.sendAllTogetherApiCall(params)
                    let temp_res = {
                        response: res
                    }
                    this.maintainOrderState(temp_res)
                    this.$toasted.global.success(res.message)
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Order Operations
            async prepareOrder(isprepared) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isPrepared: isprepared,
                        get_details: true
                    }
                    let res = await this.orderPrepareApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async deliverOrder(isdelivered) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isDelivered: isdelivered,
                        get_details: true
                    }
                    let res = await this.orderDeliveApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Fire For Order,Course,Item
            async orderFire(fireOrder) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'ORDER',
                        action: fireOrder,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async courseFire(fireCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'COURSE',
                        action: fireCourse,
                        course: courseno,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemFire(item) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'ITEM',
                        action: item.isFired,
                        course: item.course,
                        item_id: item.item_id,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Hold and Unhold For Order,Course and Item
            async holdOrderApiCall(ishold) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isHold: ishold,
                        get_details: true
                    }
                    let res = await this.holdWholeOrder(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    setTimeout(() => {
                        this.orderdetails.isOrderOnHold = false
                    }, 500)
                    this.$toasted.global.error(reason)
                }
            },
            async unholdOrderApiCall() {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isHold: false,
                        get_details: true
                    }
                    let res = await this.holdWholeOrder(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (error) {
                    setTimeout(() => {
                        this.orderdetails.isOrderOnHold = false
                    }, 500)
                    this.$toasted.global.error(reason)
                }
            },
            async holdCourse(status, courseno) {
                let params = {
                    order_id: this.$route.params.id,
                    isHold: status,
                    course: courseno
                }
                try {
                    let res = await this.courseHoldApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.orderdetailsCourse.forEach((element, cindex) => {
                        if (element.course == courseno) {
                            this.orderdetailsCourse[cindex].isHold = false
                        }
                    })

                    this.$toasted.global.error(reason)
                }
            },
            async itemHold(item) {
                if (item.isHold == false) {
                    item.isHold = true
                } else {
                    item.isHold = false
                }
                try {
                    let params = {
                        isHold: item.isHold,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemHoldApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Based on Course Operations
            async coursePrepare(prepareCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isPrepared: prepareCourse,
                        course: courseno
                    }
                    let res = await this.coursePrepareApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.orderdetailsCourse.forEach((element, cindex) => {
                        if (element.course == courseno) {
                            this.orderdetailsCourse[cindex].isPrepared = false
                        }
                    })
                    this.$toasted.global.error(reason)
                }
            },
            async courseDelivered(deliverCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isDelivered: deliverCourse,
                        course: courseno
                    }
                    let res = await this.courseDeliverApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Based on Item Operations
            async itemPreparded(item) {
                try {
                    let params = {
                        isPrepared: item.isPrepared,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemPreparedApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemDelivered(item) {
                try {
                    let params = {
                        isDelivered: item.isDelivered,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemDeliveredApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemAvailable(item) {
                if (item.unAvailable == false) {
                    item.unAvailable = true
                } else {
                    item.unAvailable = false
                }
                try {
                    let params = {
                        unAvailable: item.unAvailable,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: this.$route.params.id
                    }
                    let res = await this.itemAvailableApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            closePaymentMethodB2C() {
                this.showpay_b2c = false
				setTimeout(() => {
					this.$modal.hide('method_payment_b2c')
				}, 500);
            },
            payOrder(){
                console.log(this.order_details)
                this.pay_modal_order_data = {
                    total: this.order_details.order_total,
                    type: this.order_details?.response?.order_details?.order_type || 'Dine In'
                }
                this.showpay_b2c = true
				setTimeout(() => {
					this.$modal.show('method_payment_b2c')
				}, 500);
            },
        },
        beforeRouteLeave (to, from, next) {
            let message = "<div class='d-block'>Order is in progress Data would be lost, Do you want to proceed?</div>";
            // <img src='/static/images/exclamation-yellow.svg' class='d-block h-100px mx-auto mb-5'/><br>
            let options = {
                html: true,
                loader: false,
                reverse: false, // switch the button positions (left to right, and vise versa)
                okText: 'Yes',
                cancelText: 'No',
                animation: 'fade',
                type: 'basic',
                verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                backdropClose: true,
            };
            this.$dialog.confirm(message, options)
            .then( () => {
                next();
            })
            .catch( ()=> {
                next(false);
            });
        },
        mounted() {
            this.getOrderById()
            if (this.loggedInUser.outlet_type == 'pop-up') {
                this.isPopUp = true
            } else {
                this.isPopUp = false
            }
            EventBus.$on('PaymentComplete', value => {
                this.manageorderScreen()
            })
            EventBus.$on("update_order",() => {
                this.addMoreItems(this.orderdetails._id)
            })
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }
        }
    }

</script>

<style scoped>
    .top-view-order {
        border-radius: 0px ​0px 5px 5px;
        background-image: linear-gradient(278deg, #00448b 36%, #00448b 74%);
    }

    .text-area-manage {
        border-radius: 5px;
        background: transparent;
        color: red;
        border: 1px solid #fff;
        width: 100%;
        padding: 5px;
        height: 140px;
    }

    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 6px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
    }

    .round input[type='checkbox'] {
        visibility: hidden;
    }

    .round input[type='checkbox']:checked+label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .round input[type='checkbox']:checked+label:after {
        opacity: 1;
    }

    .page-content-card {
        border-radius: 12px;
        border: solid 2.5px #f6f6f6;
        background-color: #fff;
        margin-top: 20px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .addon-view {
        border-radius: 6px;
        border: 2px solid #eee;
    }

    .view-vizard-big {
        background-color: #fff;
        border-radius: 35px;
        padding: 8px;
    }

    .stepwizard {
        display: table;
        width: 100%;
        position: relative;
    }

    .stepwizard-row {
        display: table-row;
    }

    .stepwizard-row:before {
        top: 16px;
        bottom: 0;
        position: absolute;
        content: ' ';
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: 0;
    }

    .stepwizard-step {
        display: table-cell;
        text-align: center;
        position: relative;
    }

    .wizardSteps {
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        color: #94bef0 !important;
        text-align: center;
        display: initial;
        padding: 6px 0;
        font-size: 13px;
        font-weight: 600;
        line-height: 2.5;
        border-radius: 15px;
        text-decoration: none;
    }

    .view-vizard-big .stepwizard-step {
        left: 8% !important;
    }

    .view-vizard-big .btn-circle {
        line-height: 2.7 !important;
    }

    .view-vizard-small .stepwizard-step {
        left: 0% !important;
    }

    .view-vizard-small .wizardSteps {
        width: 26px !important;
        height: 26px !important;
    }

    .view-vizard-small .stepwizard-row:before {
        width: 80% !important;
        top: 14px !important;
    }

    .view-vizard-small .btn-circle {
        line-height: 2 !important;
    }

    .wizardSteps {
        border: none !important;
    }

    .greenCircle {
        background: #1a9347 !important;
        color: #fff !important;
    }

    .redCircle {
        background: #e82828 !important;
        color: #fff !important;
    }

    .orangeCircle {
        background: #f5a623 !important;
        color: #fff !important;
    }

    .btn-circle {
        padding: 5px 0 !important;
        line-height: 2.6 !important;
    }

    .whiteSpace {
        white-space: nowrap;
    }

    .pointerEventsNone {
        pointer-events: none !important;
    }

    .maxstepwizard:before {
        width: 80% !important;
    }

    .onholddiv {
        position: absolute;
        width: 97.5%;
        text-align: center;
        height: 40px;
        background-color: #606266;
        opacity: 1;
        top: 40%;
        right: 0px;
        left: 0px;
        margin-left: 15px;
        z-index: 999 !important;
        pointer-events: all !important;
    }

    .OnHoldClass {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }

    .onholddiv .btn-checkout {
        border-radius: 23px;
        top: 3px;
        background-image: linear-gradient(to bottom, #ffb333, #e29005);
        color: #303031;
        padding: 0px 10px;
        margin: 0px;
        font-size: 14px !important;
        font-weight: 600;
        height: 3.4rem;
        line-height: 2.6rem;
        text-transform: capitalize;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .view-cour-comp .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #46d830 !important;
        border-color: #46d830 !important;
        border-radius: 50% !important;
    }

    .view-cour-comp .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #46d830 !important;
        border-color: #46d830 !important;
        border-radius: 50% !important;
    }

    .view-cour-comp .el-checkbox__inner {
        border-radius: 50% !important;
    }

    .no-for-pax {
        border: 1px solid #eee !important;
        border-radius: 5px;
        background-color: #fbfbfb;
        color: #303031;
        height: 25px;
        width: 70px;
        text-align: center;
        font-weight: 600;
    }

    .icon-width {
        width: 15px !important;
        height: 15px !important;
    }
    .notification-cart .badge-for-cart {
        padding: 1px 4px !important;
        font-size: 10px;
        border-radius: 100px;
        background: red;
        left: -11px;
        top: -7px;
        position: relative;
        color: #fff;
        font-weight: 600;
    }
</style>
