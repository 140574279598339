<template>
  <div class="page-layout blank report-manage">
    <!-- <div  v-if="loadInvoice == false" class="loadInvoice">
      <div class="digi-table-loader loaderDiv">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div> -->
    <div class="row justify-content-center mt-10" v-if="ajaxCallInProgress">
      <div class="digi-table-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="pb-10" v-if="!ajaxCallInProgress">
      <h2 class="headerblock">
        <div class="d-flex ml-2 mr-2 justify-content-between align-items-center flex-wrap">
          <div class="d-flex flex-wrap">
            <router-link
							:disabled="!$routerHistory.hasPrevious()"
							:to="{ path: $routerHistory.previous().path }">
              <el-tooltip class="item pull-right" effect="dark" content="Click to Go Back" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-left"></i>
                </button>
              </el-tooltip>
						</router-link>
            <router-link
							:disabled="!$routerHistory.hasForward()"
							:to="{ path: $routerHistory.next().path }">
						  <el-tooltip class="item pull-right" effect="dark" content="Click to Go Forward" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-right"></i>
                </button>
							</el-tooltip>
						</router-link>
            <div class="">
              <date-picker
                v-if="convertUTC == false"
                v-model="dateRange"
                @confirm="onDateChange()"
                format="MM/DD/YYYY hh:mm A"
                :first-day-of-week="7"
                range
                class="date-width"
                :show-second="false"
                :shortcuts="shortcuts"
                :lang="lang"
                :clearable="true"
                confirm
                :not-after="new Date()"
                confirm-text="APPLY"
                type="datetime"
              ></date-picker>
              <date-picker
                  v-else
                  v-model="dateRange"
                  @confirm="onDateChange()"
                  format="MM/DD/YYYY hh:mm A"
                  :first-day-of-week="7"
                  range
                  class="date-width"
                  :show-second="false"
                  :shortcuts="shortcuts"
                  :lang="lang"
                  :clearable="true"
                  confirm
                  :not-after="new Date()"
                  confirm-text="APPLY"
                  type="datetime"
                ></date-picker>
                <!-- <date-picker
                  v-else
                  v-model="dateRange"
                  @confirm="onDateChange()"
                  format="DD-MM-YYYY hh:mm A"
                  :first-day-of-week="7"
                  range
                  :show-second="false"
                  :shortcuts="shortcuts"
                  :lang="lang"
                  :clearable="true"
                  confirm
                  :not-after="new Date()"
                  confirm-text="APPLY"
                  type="datetime"
                  :value-type="valueType"
                ></date-picker> -->
              </div>
            <div class="ml-4" v-if="!loggedInUser.outlet">
              <div class style="padding-top: 4px;">
                <v-select class="clearNone" label="name" v-model="selected" :clearSearchOnSelect="true" :selected="selected" :filterable="false" :options="options" @search="onSearch" :clearable="false"></v-select>
              </div>
            </div>
            <div class="ml-4 resetMargin">
              <button type="submit" @click="resetTable" class="btn btn-secondary btn-smm">RESET</button>
            </div>
          </div>
          <div>
              <!-- <span @click="downloadPdf" class="pointer ml-3 d-flex align-items-center font-weight-bold pdfButton pl-2">
              <img src="https://cdn.digicollect.com/cdn/posv2/scm/pdf-file.svg">
              <span class="text-white font-weight-bold ml-1">Pdf</span>
              </span> -->
          </div>
        </div>
      </h2>
      <div class="row no-gutters justify-content-between pt-3 canvas_div_pdf">
        <div>
          <div class="dropdown">
            <button class="btn btn-secondary" @click="download_report('Tax Invoice.csv')" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/exports.svg" class="pb-2 mr-2" style="width:15px;" />Export As CSV
            </button>
            <a href="#" id="reports"></a>


            <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#" @click="download_report('Tax Invoice.csv')">CSV</a>
              <a class="dropdown-item" href="#" @click="download_report('Tax Invoice.xlsx')">Excel</a>
              <a class="dropdown-item" href="#" @click="download_pdf()">PDF</a>
              <a href="#" id="reports"></a>
            </div> -->
          </div>
        </div>

        <div class="d-flex">
          <!-- <span class="item-per-page">Item Per Page</span>
          <select class="pagination-select">
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>-->
          <div class="d-flex align-items-center" v-if="total_reports">
            <span class="page-info px-2 hidden-md-down"> {{getSkipCount}} -{{total_reports>skip+limit?skip+limit:total_reports}} of {{total_reports}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                  <button type="button" class="btn btn-icon d-none d-md-inline el-tooltip item">
                      <i class="icon icon-chevron-left" @click="previousPage()"></i>
                  </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                  <button type="button" class="btn btn-icon d-none d-md-inline el-tooltip item">
                      <i class="icon icon-chevron-right" @click="nextPage()"></i>
                  </button>
              </el-tooltip>
          </div>
          <div class="breadcome-heading">
            <div class="form-group formsearch has-warning has-feedback">
              <input @input="onkeySearch" v-model="searchKey" type="text" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
              <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
            </div>
          </div>
        </div>
        <div class="col-md-12">
           <div
                class="reports-label-top"

              >TAX INVOICE REPORT</div>
          <div class="summaryBreakdown mt-4">
            <table class="table table-reports table-fixed">
              <thead>
                <tr>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'outlet_name' && sort.value == 1" :class="{'hide-arrow': sort.key != 'outlet_name'}" @click="updateSort('outlet_name')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('outlet_name')" :class="{'hide-arrow': sort.key != 'outlet_name'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    OUTLET
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'customer_name' && sort.value == 1" :class="{'hide-arrow': sort.key != 'customer_name'}" @click="updateSort('customer_name')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('customer_name')" :class="{'hide-arrow': sort.key != 'customer_name'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    CUSTOMER NAME
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'company_name' && sort.value == 1" :class="{'hide-arrow': sort.key != 'company_name'}" @click="updateSort('company_name')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('company_name')" :class="{'hide-arrow': sort.key != 'company_name'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    COMPANY NAME
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'customer_tax_id' && sort.value == 1" :class="{'hide-arrow': sort.key != 'customer_tax_id'}" @click="updateSort('customer_tax_id')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('customer_tax_id')" :class="{'hide-arrow': sort.key != 'customer_tax_id'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    CUSTOMER TAX ID
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'paid_date' && sort.value == 1" :class="{'hide-arrow': sort.key != 'paid_date'}" @click="updateSort('paid_date')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('paid_date')" :class="{'hide-arrow': sort.key != 'paid_date'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    PAYMENT DATE
                    (dd/mm/yyyy)
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'paid_time' && sort.value == 1" :class="{'hide-arrow': sort.key != 'paid_time'}" @click="updateSort('paid_time')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('paid_time')" :class="{'hide-arrow': sort.key != 'paid_time'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    PAYMENT TIME
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'total_tax' && sort.value == 1" :class="{'hide-arrow': sort.key != 'total_tax'}" @click="updateSort('total_tax')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('total_tax')" :class="{'hide-arrow': sort.key != 'total_tax'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    VAT
                  </th>
                  <th>
                    <span class="sorting-icon" v-if="sort.key == 'total' && sort.value == 1" :class="{'hide-arrow': sort.key != 'total'}" @click="updateSort('total')">
                      <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                    </span>
                    <span style="font-size: 16px !important;" class="sorting-icon" v-else @click="updateSort('total')" :class="{'hide-arrow': sort.key != 'total'}">
                      <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                    </span>
                    TOTAL
                  </th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody v-if="data_exists">
                <template v-if="loadInvoice">
                  <div class="row justify-content-center mt-10">
                    <div class="digi-table-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </template>
                <template v-else>
                <tr :key="index" v-for="(report,index) in tax_invoice_report">
                  <td style="padding: 0 27px!important;" class="text-truncate d-block" :title="report.outlet_name">{{report.outlet_name}}</td>
                  <td style="padding: 0 27px!important;" class="text-truncate d-block" :title="report.payments.customer_name">{{report.payments.customer_name || '-'}}</td>
                  <td style="padding: 0 27px!important;" class="text-truncate d-block" :title="report.company_name">{{report.company_name || '-'}}</td>
                  <td style="padding: 0 27px!important;">{{report.payments.customer_tax_code || '-'}}</td>
                  <td style="padding: 0 27px!important;">{{report.paid_date || '-'}}</td>
                  <td style="padding: 0 27px!important;">{{report.paid_time || '-'}}</td>
                  <td style="padding: 0 27px!important;">{{report.total_tax || '-'}}</td>
                  <td style="padding: 0 27px!important;">{{report.total || '-'}}</td>
                  <td style="padding: 0 27px!important;">
                    <el-tooltip class="item pull-right" effect="dark" content="View Receipt" placement="top">
                      <span class="pointer" @click="showReceipts(report._id)">
                        <i class="icon icon-eye text-secondary"></i>
                      </span>
                    </el-tooltip>
                  </td>
                </tr>
                </template>
              </tbody>
              <tbody v-else-if="!data_exists && searchKey.length == 0">
                  <tr>
                  <td colspan="7" class="text-center d-block">
                      <strong>No Tax Reports</strong>
                  </td>
                  </tr>
              </tbody>
              <tbody v-else-if="searchKey.length>0 && !data_exists">
                  <tr>
                  <!-- {{searchKey.length}} -->
                  <td colspan="7" class="text-center d-block">
                      <strong>No Search Results Found</strong>
                  </td>
                  </tr>
              </tbody>
              <tfoot>
                <tr style="background-image: linear-gradient(to bottom, #ffb333, #e29005);border-radius: 0px 0px 7px 7px;" v-if="data_exists">
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black">TOTAL</td>
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black"></td>
                  <td style="padding: 0 23px!important;whitespace: nowrap;word-break: break-all;line-height: 50px;" class="font-weight-bold text-black">{{total_row.total_tax}}</td>
                  <td style="padding: 0 23px!important;word-break: break-all;line-height: 50px;" class="font-weight-bold text-black">{{total_row.total}}</td>
                  <td style="padding: 0 23px!important;whitespace: nowrap" class="font-weight-bold text-black"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!-- View Reciepts -->
        <div class="filter-list" :class="{'right-sidebar': toggleSideBar}" ref="content">
          <div class="list-group-item subheader">
            <span class="font-600">
              Paid Receipt No. : {{viewReceipts.receipt_id}}
            </span>
            <div class="pull-right" style="margin-left: auto; cursor: pointer; display: inline-flex;">
              <!-- <a class="btn btn-secondary btn-smm mr-3 d-none d-md-block">Download </a>  -->

              <!-- <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" v-on:click="toggleBtn1()">
                  <i class="icon-email"></i>
                </span>
                <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="createPDF()">
                  <i class="icon-download"></i>
                </span>
                <span class="pt-1 item" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="toggleBtn()">
                  <i class="icon-close-circle-outline"></i>
                </span> -->

              <div class="pull-right" style="margin-left: auto; cursor: pointer; display: inline-flex;">
                <!-- <a class="btn btn-secondary btn-smm mr-3 d-none d-md-block">Download </a>  -->

                <!-- <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="myFunction()">
                    <i class="icon-email"></i>
                  </span>
                  <span class="pt-1 item pr-3" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" @click="createPDF()">
                    <i class="icon-download"></i>
                  </span> -->
                <span class="pt-1 item" tabindex="0" style="cursor: pointer;outline-width: 0px!important;" v-on:click="toggleBtn1()">
                  <i class="icon-close-circle-outline"></i>
                </span>
              </div>
            </div>
          </div>
          <table class="table">
            <tr style="height:20px!important;">
              <td rowspan="3" style="padding: 4px 20px!important;">
                <img :src="viewReceipts.profile_pic" style="width:74px;height:70px;border-radius: 50%;" />
              </td>
              <td class="pull-right" style="padding: 4px!important;">
                {{viewReceipts.outlet_name}}
              </td>
            </tr>
            <tr style="height:20px!important;">
              <td style="padding: 4px!important;" class="pull-right">{{viewReceipts.outlet_email_address}}</td>
            </tr>
            <tr style="height:20px!important;">
              <td style="padding: 4px!important;" class="pull-right">{{viewReceipts.outlet_phone_number}}</td>
            </tr>
          </table>
          <hr style="border-top: 0.5px solid #94bef0;">
          <div class="row pl-2 pr-3">
            <div class="col-md-6">
              <div class="title font-weight-bold text-secondary">DigiPOS</div>
            </div>
            <div class="col-md-6">
              <div class="title font-weight-bold pull-right">{{viewReceipts.closed_time}}</div>
            </div>
          </div>
          <hr style="border-top: 1.5px solid #94bef0;">
          <div class="outlet-address pt-3">
            Outlet Address : {{viewReceipts.outlet_address}}
          </div>
          <div class="content" v-if="showBilling">
            <hr style="border-top: 0.5px solid #94bef0;">
            <div class="content" style="padding-left:5px;padding-right:5px;" v-if="showBilling">
              <div class="info-line mb-2 text-center pt-4">
                <div class="info mb-1 font-600">Paid Receipt No : {{viewReceipts.receipt_id}}</div>
                <div class="info mb-1 font-600">POS ID : {{viewReceipts.pos_id}}</div>
                <div class="info mb-1 font-600">Tax Code : {{viewReceipts.outlet_tax_id}}</div>
                <div class="info mb-1 font-600">Order Type : {{viewReceipts.order_type}}</div>
                <div v-if="viewReceipts.order_type == 'Dine In'" class="info mb-1 font-600">Table Number : {{viewReceipts.table_number}}</div>
              </div>
              <hr style="border-top: 1.5px solid #94bef0;">
              <div class="info-line mb-2 mt-2 text-center">
                <div class="info server-by font-600">Served by {{viewReceipts.served_by}}</div>
                <div class="info mb-1 server-by font-600">Generated by {{viewReceipts.outlet_name}} in {{viewReceipts.outlet_location}}</div>
              </div>
              <div class="">
                <table v-if="!isPopUp" class="table tablebill">
                  <thead style="background-color:#00448b!important;">
                    <tr style="border-bottom: dashed 1px #b8b9bb;height: 30px;">
                      <th class="thbill" style="width: 35%;color:#fff!important;">Items</th>
                      <th class="thbill" style="width: 27%;color:#fff!important;">Qty</th>
                      <th class="thbill" style="width: 15%;color:#fff!important;">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item,index) in viewReceipts.item_details">
                      <tr :key="index">
                        <td v-if="item.hasVariant" class="tdcart deepSkyBlue pointer td-truncate" style="padding: 0.6rem 0.8rem !important;width: 30%;">
                          <img v-if="item.variant_type == 'Non-Veg'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="padding-right: 5px;">
                          <img v-else-if="item.variant_type == 'Veg'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="padding-right: 5px;">
                          <img v-else-if="item.variant_type == 'Egg'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="padding-right: 5px;">
                          <img v-else-if="item.variant_type == 'Pescatarian'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" style="padding-right: 5px;width: 20px;">
                          {{item.product_name}}/{{item.variant_name}}<br> Rate: {{item.price}}
                        </td>
                        <td v-else class="tdcart deepSkyBlue pointer td-truncate" style="padding: 0.6rem 0.8rem !important;width: 30%;">
                          <img v-if="item.product_type == 'Non-Veg'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" style="padding-right: 5px;">
                          <img v-else-if="item.product_type == 'Veg'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" style="padding-right: 5px;">
                          <img v-else-if="item.product_type == 'Egg'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" style="padding-right: 5px;">
                          <img v-else-if="item.product_type == 'Pescatarian'" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" style="padding-right: 5px;width: 20px;">

                          {{item.product_name}}<br> Rate: {{item.price}}</td>
                        <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.quantity}}<br> Discount: {{item.discount}}</td>
                        <!-- <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.price}}</td> -->
                        <!-- <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.discount.amount}}</td> -->
                        <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 25%;">{{item.total}}</td>
                      </tr>
                      <tr :key="index" v-if="item.addons.length > 0">
                        <td colspan="3" style="padding: 0.6rem 1.2rem!important;background-color: #e2e9f6;border-radius: 6px;box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);padding: 0.6rem 1.2rem!important;">
                          <div>
                            <span class="text-blue font-600 pl-3">
                                <span>Size :</span>
                                <span>Small</span>
                            </span><br>
                            <span class="text-black font-600 pl-3">Modifiers :</span><br>
                            <div class="flex-adons pt-1" v-for="(addon, index) in item.addons" :key="index" >
                              <div class="showType" v-if="addon.type == 'N/A'" :class="{'veg':addon.type == 'Veg','nonveg':addon.type == 'Non-Veg','egg':addon.type == 'Egg','pescatarian':addon.type == 'Pescatarian'}">
                                  <div></div>
                              </div>
                              <el-tooltip class="item" effect="dark" :content="addon.type" placement="top" v-else>
                                <div class="showType" :class="{'veg':addon.type == 'Veg','nonveg':addon.type == 'Non-Veg','egg':addon.type == 'Egg','pescatarian':addon.type == 'Pescatarian'}">
                                    <div></div>
                                </div>
                              </el-tooltip>
                              <span>
                                {{addon.name}}
                                <span class="text-green">X {{addon.quantity}}</span>
                                <span v-if="index != item.addons.length - 1" class="text-green">,</span>
                                <!-- <span v-if="item.addons.length > 1 || item.asddons[-1]['quantity'] != item.addons[item.addons.length - 1]['quantity']">,</span> -->
                              </span>
                          </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr v-for="(item,index) in viewReceipts.category_summary" :key="index">
                      <td class="tdbill" colspan="2" style="line-height:0.25 !important;border-top: 2px dotted #94bef0;text-align: right;text-transform: capitalize!important;">
                        {{item.name}}
                      </td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{item.value}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;line-height:0.25 !important;">Sub-Total Without Discount</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">{{viewReceipts.sub_total_without_discount}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" style="text-align: right;line-height:0.25 !important;" colspan="2">
                        Item Discount
                      </td>
                      <td class="tdbill" style="line-height:0.25 !important;">({{viewReceipts.item_level_discount}})</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;line-height:0.25 !important;">Order Discount</td>
                      <td class="tdbill" style="line-height:0.25 !important;">({{viewReceipts.order_discount.amount}})</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;line-height:0.25 !important;">Sub-Total With Discount</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;border-bottom: 2px dotted #94bef0!important;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">{{viewReceipts.sub_total_with_discount}}</td>
                    </tr>
                    <tr v-for="(charge ,index) in viewReceipts.other_charges" :key="index">
                      <td class="tdbill" style="text-align: right;line-height:0.25 !important;" colspan="2">
                        {{charge.name}}<span v-if="charge.type == 'PERCENTAGE'">({{charge.tax_rate}}%)</span>
                          <span v-if="charge.discount_included">
                            <el-tooltip class="item" effect="dark" content="Discount not applicable for this charge" placement="top">
                              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                            </el-tooltip>
                          </span>
                      </td>
                      <td class="tdbill" style="line-height:0.25 !important;">{{charge.value}}</td>
                    </tr>
                    <tr v-if="viewReceipts.loyalty_applied">
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Reward Points</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">{{viewReceipts.loyalty_amount}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Total Amount</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{viewReceipts.total_amount}}</td>
                    </tr>
                    <tr v-for="(tax, index) in viewReceipts.order_tax" :key="index">
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{tax.name}}({{tax.rate}}%)</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{tax.value}}</td>
                    </tr>
                    <tr v-if="viewReceipts.is_round_off_required">
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Gross Total</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{viewReceipts.gross_total}}</td>
                    </tr>
                    <tr v-if="viewReceipts.is_round_off_required">
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Round Off</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{viewReceipts.round_off}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Loyalty Points Earned</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{viewReceipts.loyalty_points_earned}}</td>
                    </tr>
                  </tfoot>
                  <tfoot>
                    <tr style="">
                      <th class="tfbill text-secondary" colspan="2" style="line-height:0.25 !important;text-align: right;color:#303031!important;background-color: #f5a623;border-top-left-radius: 6px;border-bottom-left-radius: 6px;">Grand Total</th>
                      <th class="tfbill" style="line-height:0.25 !important;color:#303031!important;background-color: #f5a623;border-top-right-radius: 6px;border-bottom-right-radius: 6px;">{{viewReceipts.grand_total}}</th>
                    </tr>
                  </tfoot>
                  <tfoot v-if="viewReceipts.is_deposit_used">
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;">Deposit Used</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;">{{viewReceipts.customer_deposit_used}}</td>
                    </tr>
                  </tfoot>
                </table>
                <table v-else class="table tablebill popup">
                  <thead>
                    <tr style="border-bottom: dashed 1px #b8b9bb;">
                      <th class="thbill" style="width: 30%;">Items</th>
                      <th class="thbill" style="width: 15%;">Qty</th>
                      <th class="thbill" style="width: 25%;">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody v-for="(vendor, index) in viewReceipts.vendor_item_details" :key="index">
                    <tr style="height: 13px;background-color: #f6f6f6;">
                      <td colspan="" class="text-center" style="padding: 0.6rem 1.2rem!important;">
                        <strong>
                          {{vendor.vendor_name}}
                        </strong>
                      </td>
                    </tr>
                    <tr v-for="(item, index) in vendor.items" :key="index">
                      <td v-if="item.hasVariant" class="tdcart deepSkyBlue pointer td-truncate" style="padding: 0.6rem 0.8rem !important;width: 30%;">
                        {{item.product_name}}/{{item.variant_name}}<br>Rate :{{item.price}}
                      </td>
                      <td v-else class="tdcart deepSkyBlue pointer td-truncate" style="padding: 0.6rem 0.8rem !important;width: 30%;">{{item.product_name}}<br>Rate :{{item.price}}</td>
                      <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 15%;">{{item.quantity}}
                        <br>Discount :{{item.discount}}
                      </td>
                      <td class="tdcart" style="padding: 0.6rem 0.8rem !important;width: 25%;">{{item.total}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;text-align: right;" colspan="3">
                        Food Summary
                      </td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{vendor.food_summary}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;">Beverage Summary</td>
                      <td></td>
                      <td class="tdbill">{{vendor.beverage_summary}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;">Additional Charges</td>
                      <td></td>
                      <td class="tdbill">{{vendor.vendor_additional_charges}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" style="text-align: right;" colspan="3">
                        Item Discount
                      </td>
                      <td></td>
                      <td class="tdbill">{{vendor.vendor_discount}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">Subtotal</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{vendor.vendor_sub_total}}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr style="height: 40px!important;background-color:rgb(246, 246, 246) !important;" class="TaxDiscountTr">
                      <td colspan="6" style="text-align:left;border-top: 1px dotted rgba(13, 132, 242, 0.55);"><strong>Aggregate Order Summary</strong></td>
                    </tr>
                    <tr>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;text-align: right;" colspan="3">
                        Vendor Food Summary
                      </td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{viewReceipts.vendor_food_summary}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;">Vendor Beverage Summary</td>
                      <td></td>
                      <td class="tdbill">{{viewReceipts.vendor_beverages_summary}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;">Vendor Additional Charges</td>
                      <td></td>
                      <td class="tdbill">{{viewReceipts.vendor_additional_charges}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" style="text-align: right;" colspan="3">
                        Vendor Item Discount
                      </td>
                      <td></td>
                      <td class="tdbill">{{viewReceipts.vendor_discount}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">Subtotal</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{viewReceipts.vendor_sub_total}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">Order Additional Charges</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">$01</td>
                    </tr>
                    <tr>
                      <td class="tdbill" style="text-align: right;" colspan="3">
                        Order Discount
                      </td>
                      <td></td>
                      <td class="tdbill">$24</td>
                    </tr>
                    <tr v-for="(charge, index) in viewReceipts.other_charges" :key="index">
                      <td class="tdbill" style="text-align: right;" colspan="3">
                        {{charge.name}}<span v-if="charge.type == 'PERCENTAGE'">({{charge.tax_rate}}%)</span>
                          <span v-if="charge.discount_included">
                            <el-tooltip class="item" effect="dark" content="Discount not applicable for this charge" placement="top">
                              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg">
                            </el-tooltip>
                          </span>
                      </td>
                      <td></td>
                      <!-- {{charges}} -->
                      <td class="tdbill">{{charges.value}}</td>
                    </tr>
                    <tr v-if="viewReceipts.loyalty_applied">
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Reward Points</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;padding: 1.6rem 0.9rem !important;line-height:0.25 !important;">{{viewReceipts.loyalty_amount}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">Total Amount</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{viewReceipts.total_amount}}</td>
                    </tr>
                    <tr v-for="(tax, index) in viewReceipts.order_tax" :key="index">
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">{{tax.name}}</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{tax.value}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">Gross Total</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{viewReceipts.gross_total}}</td>
                    </tr>
                    <tr>
                      <td class="tdbill" colspan="3" style="text-align: right;border-top: 2px dotted #94bef0;;">Round Off</td>
                      <td style="border-top: 2px dotted #94bef0;;"></td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{viewReceipts.round_off}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;border-top: 2px dotted #94bef0;line-height:0.25 !important;">Loyalty Points Earned</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;line-height:0.25 !important;">{{viewReceipts.loyalty_points_earned}}</td>
                    </tr>
                  </tfoot>
                  <tfoot v-if="viewReceipts.is_deposit_used">
                    <tr>
                      <td class="tdbill" colspan="2" style="text-align: right;">Deposit Used</td>
                      <td class="tdbill" style="border-top: 2px dotted #94bef0;;">{{viewReceipts.customer_deposit_used}}</td>
                    </tr>
                  </tfoot>
                  <tfoot>
                    <tr style="background-color: #f5a623;">
                      <th class="tfbill text-secondary" colspan="3" style="text-align: right;color:#303031!important;">Grand Total</th>
                      <td></td>
                      <th class="tfbill" style="color:#303031!important;">{{viewReceipts.grand_total}}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <span class="details-heading">Customer Details</span>
              <div class="digiblock-section" style="background: transparent;">
                <div class="section-container a-Zm">
                  <div class="sections-div transdiv">
                    <div class="div-content">
                      <div class="content-left specbilldetail">Customer Name</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det textCaps">
                          {{viewReceipts.customer_name|| '-'}}
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Company Name</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det" v-bind:title="viewReceipts.customer_company_name">
                          {{viewReceipts.payments[0].customer_company|| '-'}}
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Company Address</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det" v-if="viewReceipts.company_address[0].street != '' || viewReceipts.company_address[0].street2 != '' || viewReceipts.company_address[0].township != '' || viewReceipts.company_address[0].city != '' || viewReceipts.company_address[0].state != '' || viewReceipts.company_address[0].country != ''">
                            {{viewReceipts.company_address[0].street}}<span v-if="viewReceipts.company_address[0].street != '' && viewReceipts.company_address[0].street2 != ''">,</span>{{viewReceipts.company_address[0].street2}}<span v-if="viewReceipts.company_address[0].township != ''">,</span>{{viewReceipts.company_address[0].township}}<span v-if="viewReceipts.company_address[0].city != ''">,</span>{{viewReceipts.company_address[0].city}}<span v-if="viewReceipts.company_address[0].state != ''">,</span>{{viewReceipts.company_address[0].state}}<span v-if="viewReceipts.company_address[0].country != ''">,</span>{{viewReceipts.company_address[0].country}}
                        </span>
                        <span v-else class="right-div bill-det">
                          -
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Email</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det" v-bind:title="viewReceipts.customer_email">
                          {{viewReceipts.payments[0].customer_email|| '-'}}
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Phone</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det">
                          {{viewReceipts.payments[0].customer_phone_number|| '-'}}
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Tax Code</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det">
                          {{viewReceipts.payments[0].customer_tax_code || '-'}}
                        </span>
                      </div>
                    </div>
                    <div v-if="viewReceipts.is_deposit_used">
                      <span class="details-heading">Deposit Details</span>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Issued By</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{viewReceipts.deposit_information.issued_by|| '-'}}
                          </span>
                        </div>
                      </div>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Date</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{viewReceipts.deposit_information.date_of_issue_formatted || '-'}}
                          </span>
                        </div>
                      </div>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Bank Name</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{viewReceipts.deposit_information.bank_name|| '-'}}
                          </span>
                        </div>
                      </div>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Cheque/Transfer No</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{viewReceipts.deposit_information.cheque_number || '-'}}
                          </span>
                        </div>
                      </div>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Deposit Notes</div>
                        <div class="content-right text-right">
                            <span class="right-div bill-det">
                            {{viewReceipts.deposit_information.deposit_notes || '-'}}
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span class="details-heading">Payment Details</span>
              <div class="digiblock-section" style="background: transparent;">
                <div class="section-container a-Zm">
                  <div class="sections-div transdiv">
                    <div class="div-content">
                      <div class="content-left specbilldetail">Payment Status</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det">
                          PAID
                        </span>
                      </div>
                    </div>
                    <div class="div-content" v-if="viewReceipts.payments.length == 0">
                      <div class="content-left specbilldetail">Payment Method</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det" v-if="viewReceipts.payments && viewReceipts.payments.length">
                          {{viewReceipts.payments[0]['mode']}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="viewReceipts.payments.length > 1" class="details-heading">Split Details</span>
              <div v-if="viewReceipts.payments.length > 1" class="digiblock-section" style="background: transparent;">
                <div v-for="(payment,index) in viewReceipts.payments" :key="index" class="section-container a-Zm">
                  <div class="sections-div transdiv">
                    <div class="div-content">
                      <div class="content-left specbilldetail">Customer Name</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det">
                          {{payment.customer_name}}
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Payment Method</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det" v-if="payment.mode == 'OTHERS'">
                          {{payment.payment_method}}
                        </span>
                        <span class="right-div bill-det" v-else>
                          {{payment.mode}}
                        </span>
                      </div>
                    </div>
                    <div class="div-content">
                      <div class="content-left specbilldetail">Amount</div>
                      <div class="content-right text-right">
                        <span class="right-div bill-det">
                          {{payment.amount}}
                        </span>
                      </div>
                    </div>
                    <div class="" v-if="payment.mode == 'CARD'">
                      <div class="div-content">
                        <div class="content-left specbilldetail">Name On Card</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{payment.name_on_card}}
                          </span>
                        </div>
                      </div>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Card Provider</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{payment.card_provider}}
                          </span>
                        </div>
                      </div>
                      <div class="div-content">
                        <div class="content-left specbilldetail">Transaction ID</div>
                        <div class="content-right text-right">
                          <span class="right-div bill-det">
                            {{payment.transaction_id}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="mt-2" style="border-top: dashed 1px #94bef0;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '../eventBus/nav-bar-event.js'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import orderService from '../mixins/orders'
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

export default {
  mixins: [orderService],
  data() {
    const value2date = (dateRange) => {
      const date = new Date(dateRange)
      return dateRange ? new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000) : null
    }
    const date2value = (date) => {
      return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000) : null
    }
    return {
      valueType: {
        value2date,
        date2value
      },
      searchKey: '',
      orders: {},
      convertUTC: false,
      mode: null,
      toggleSideBar: false,
      dialogImageUrl: '',
      dialogVisible: false,
      isCompanyTimeline: true,
      isCompanyRelationship: false,
      isCompanygallery: false,
      show: false,
      startdate: '',
      enddate: '',
      selectedOrder: null,
      details: [],
      searchdata: '',
      reciept: {},
      showBilling: false,
      output: null,
      endDate: new Date(),
      dateRange: [],
      currency: '',
      grand_total: '',
      vendor_additional_charges: '',
      vendor_beverages_summary: '',
      vendor_food_summary: '',
      vendor_sub_total: '',
      order_discount: {},
      other_charges: [],
      order_tax: [],
      vendor_discount: '',
      vendor_item_details: [],
      receipt_id: null,
      round_off: '',
      isPopUp: '',
      food_summary: '',
      beverages_summary: '',
      item_details: '',
      gross_total: '',
      total_amount: '',
      additional_charges: '',
      discount: '',
      created_time: '',
      served_by: '',
      generated_by: '',
      item_level_discount: '',
      is_round_off_required: false,
      selectedOrderId: null,
      selectedOrderCustomerId: null,
      payments: [],
      customer_email: null,
      customer_name: null,
      customer_phone_number: null,
      customer_company_name: null,
      customer_tax_code: null,
      order_type: '',
      outlet_tax_code: '',
      outlet_address: '',
      table_id: '',
      outlet_name: '',
      outlet_phone_number: '',
      outlet_email_address: '',
      outlet_location: '',
      pos_id: '',
      showIllustrator: false,
      count: 0,
      limit: 10,
      skip: 0,
      start: 1,
      end: 0,
      total_orders: 0,
      image: '',
      closed_time: null,
      passed_key: {
        key: 'created_time',
        value: 1
      },
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear() + 1, 0, 1),
      dateRange: [],
      selected: {
        _id: null,
        name: 'All Outlets'
      },
      options: [],
      lang: {
        type: 'en',
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days'
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      confirmText: {
        type: String,
        default: 'APPLY'
      },
      shortcuts: [
        {
          text: 'Today',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
            this.endDate = new Date()
            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
          }
        },
        {
          text: 'This Week',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - new Date().getDay()
            )
            this.endDate = new Date()
            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
          }
        },
        {
          text: 'This Month',
          onClick: () => {
            this.startDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
            )
            this.endDate = new Date()
            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
          }
        },
        {
          text: 'This Year',
          onClick: () => {
            this.startDate = new Date(new Date().getFullYear(), 0, 1)
            this.endDate = new Date()

            this.dateRange = [this.startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]), this.endDate]
          }
        }
      ],
      tax_invoice_report: [],
      total_row: [],
      data_exists: true,
      total_reports: 0,
      skip: 0,
      limit: 10,
      sort: {
        key: 'paid_date',
        value: 1
      },
      recieptResponse: null,
      viewReceipts: {},
      recieptResponse: null,
      timer: null,
      loadInvoice: false,
      ajaxCallInProgress:false
    }
  },
  components: {
    DatePicker,
    vSelect
  },
  methods: {
    downloadPdf() {
      var HTML_Width = $(".canvas_div_pdf").width();
      var HTML_Height = $(".canvas_div_pdf").height();
      var top_left_margin = 15;
      var PDF_Width = HTML_Width+(top_left_margin*2);
      var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;


      html2canvas($(".canvas_div_pdf")[0],{allowTaint:true}).then(function(canvas) {
        canvas.getContext('2d');

        console.log(canvas.height+"  "+canvas.width);


        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);


        for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
        }
        pdf.save("Tax Invoive Report.pdf");
      });
    },
    resetTable() {
      this.selected = {'_id': null, 'name': 'All Outlets'};
      let startDate = new Date();
      startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]);
      let endDate = new Date();
      this.dateRange = [startDate, endDate];
      this.onDateChange();
      this.convertUTC = false;
    },
    onkeySearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getTaxInvoiceReport()
      }, 300)
    },
    toggleBtn() {
      this.toggleSideBar = !this.toggleSideBar
    },
    toggleBtn1() {
      this.toggleSideBar = false
    },
    async showReceipts(order_id) {
      try {
        let res = await this.orderHistoryDetails({ order_id: order_id })
        this.toggleSideBar = !this.toggleSideBar
        this.isPopUp = res.isPopup
        this.showBilling = true
        if (this.isPopUp) {
          this.vendor_additional_charges =
            res.response.vendor_additional_charges
          this.vendor_beverages_summary = res.response.vendor_beverages_summary
          this.vendor_food_summary = res.response.vendor_food_summary
          this.vendor_discount = res.response.vendor_discount
          this.vendor_item_details = res.response.vendor_item_details
          this.vendor_sub_total = res.response.vendor_sub_total
        } else {
          this.item_details = res.response.item_details
          this.food_summary = res.response.food_summary
          this.beverages_summary = res.response.beverages_summary
          this.discount = res.response.order_discount.amount
        }
        this.viewReceipts = res.response
        this.customer_name = order.customer_name
        this.customer_email = order.customer_email
        this.customer_company_name = order.customer_company
        this.customer_tax_code = order.customer_tax_code
        this.created_time = order.created_time
        this.customer_phone_number = order.customer_phone_number
        this.order_tax = res.response.order_tax
        this.other_charges = res.response.other_charges
        this.order_discount = res.response.order_discount
        this.currency = res.response.currency
        this.grand_total = res.response.grand_total
        this.round_off = res.response.round_off
        this.gross_total = res.response.gross_total
        this.total_amount = res.response.total_amount
        this.payments = res.response.payments
        this.generated_by = res.response.generated_by
        this.served_by = res.response.served_by
        this.receipt_id = res.response.receipt_id
        this.item_level_discount = res.response.item_level_discount
        this.order_type = res.response.order_type
        this.table_id = res.response.table_number
        this.outlet_tax_code = res.response.outlet_tax_id
        this.outlet_address = res.response.outlet_address
        this.outlet_phone_number = res.response.outlet_phone_number
        this.outlet_email_address = res.response.outlet_email_address
        this.outlet_name = res.response.outlet_name
        this.outlet_location = res.response.outlet_location
        this.pos_id = res.response.pos_id
        this.is_round_off_required = res.response.is_round_off_required
        this.closed_time = res.response.closed_time
        this.image = res.response.profile_pic
      } catch (error) {}
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search(loading, search, vm) {
      this.$http
        .post('/outlet/get', {
          search_key: search
        })
        .then(res => {
          vm.options = res.data.outlets
          loading(false)
        })
    },
    download_report(name) {
      let a = document.getElementById('reports')
      let payload = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        skip: this.skip,
        sort: this.sort,
        report_name: 'tax_invoice',
        outlet_id: this.selected._id
      }
      this.$http
        .post('/reports/sales_report/download', payload)
        .then(response => {
          let dataurl =
            'data:application/octet-stream;charset=utf-8,' + response.data
          a.href = dataurl
          a.download = name
          a.click()
        })
    },
    download_pdf() {
      let a = document.getElementById('reports')
      let payload = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        skip: this.skip,
        sort: this.sort,
        report_name: 'tax_invoice',
        outlet_id: this.selected._id
      }
      this.$http({
        method: 'POST',
        responseType: 'blob',
        url: '/reports/sales_report/download/pdf',
        data: payload
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        a.href = url
        a.download = 'tax_invoice.pdf'
        a.click()
      })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getTaxInvoiceReport() {
      this.loadInvoice = true;
      this.tax_invoice_report = [];
      let payload = {
        start_date: this.dateRange[0],
        end_date: this.dateRange[1],
        skip: this.skip,
        sort: this.sort,
        outlet_id: this.selected._id,
        search: this.searchKey
      }
      this.$http.post('/reports/tax_report/invoice1', payload)
        .then(response => {
          this.ajaxCallInProgress = false
          if (response.data.status_id == 1) {
            this.data_exists = true
            this.tax_invoice_report = response.data.tax_report
            this.total_row = response.data.total_row
            this.total_reports = response.data.total_docs
            if (this.limit == 0) {
              this.limit = 10
            }
            this.loadInvoice = false
          } else {
            if(response.data.hasOwnProperty('message')) {
              this.$toasted.global.error(response.data.message)
            } else {
              this.$toasted.global.error(response.data.reason)
            }
            this.data_exists = false
            this.skip = 0
            this.limit = 0
            this.total_reports = 0
            this.loadInvoice = false
          }
        }).catch((err) => {
            this.loadInvoice = false
          if(err.response) {
            if(response.data.hasOwnProperty('message')) {
              this.$toasted.global.error(response.data.message)
            } else {
              this.$toasted.global.error(response.data.reason)
            }
          } else {
            this.$toasted.global.error(err)
          }
        })
    },
    updateSort(paid_date) {
      this.sort.key = paid_date
      this.sort.value = this.sort.value == 1 ? -1 : 1
      this.getTaxInvoiceReport()
    },
    nextPage() {
      let skip_temp =
        this.skip + this.limit > this.total_reports
          ? this.total_reports
          : this.skip + this.limit
      if (skip_temp >= this.total_reports) {
        return
      }
      this.skip = skip_temp
      this.getTaxInvoiceReport()
    },
    previousPage() {
      if (this.skip == 0) {
        return
      }
      this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit
      this.getTaxInvoiceReport()
    },
    onDateChange() {
      this.convertUTC = true;
      if (!this.dateRange[0] && !this.dateRange[1]) {
        this.startDate = new Date()
        this.endDate = new Date()
        // this.startDate.setHours(0, 0, 0)
        this.startDate
        this.dateRange = [this.startDate, this.endDate];
      }
      // this.endDate.setHours(23, 59, 59);
      // this.dateRange[1].setHours(23, 59, 59);
      this.endDate;
      this.dateRange[1];
      this.skip = 0;
      this.getTaxInvoiceReport()
    }
    // formatTime(time) {
    //     let time_split = time.split(':')
    //     let hour = time_split[0]
    //     let minute = time_split[1]
    //     if(hour<10){
    //         hour = '0'+hour
    //     }
    //     if(minute<10){
    //         minute ='0'+ minute
    //     }
    //     return hour + ':' + minute
    // },
  },
  mounted() {
    this.ajaxCallInProgress = true
    setTimeout(() => {
      let startDate = new Date();
      startDate.setHours(this.loggedInUser.business_day_start_time.split(":")[0],this.loggedInUser.business_day_start_time.split(":")[1]);
      let endDate = new Date();
      this.dateRange = [startDate, endDate];
      EventBus.$emit('customerdetailsLoad', false)
      EventBus.$emit('settingsLoad', false)
      EventBus.$emit('reportsLoad', true)
      this.getTaxInvoiceReport();
      }, 2000);
  },
  created() {
    this.$http.post('/outlet/get', {}).then(res => {
      this.selected = {
        _id: null,
        name: 'All Outlets'
      }
      this.options = [
        {
          _id: null,
          name: 'All Outlets'
        }
      ].concat(res.data.outlets)
    })
    if (this.$route.query.mobile == 'true') {
      this.mobile = true
      EventBus.$emit('mobile', true)
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    getSkipCount() {
      if (this.total_reports == 0) {
        return 0
      } else {
        let skip_count =
          this.skip > this.total_reports ? this.total_reports : this.skip + 1
        return skip_count
      }
    }
  },
  watch: {
    selected: function(newVal, oldVal) {
      if (newVal._id != oldVal._id) {
        this.onDateChange()
      }
    },
    mobile() {
      EventBus.$emit('mobile', true)
    }
  }
}
</script>
<style scoped>
select:focus {
  outline: 0px !important;
}
.reports-label-top {
  font-size: 12px;
  color: rgb(48, 48, 49);
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
  /* border: 2px solid rgb(13, 132, 242); */
  border: 2px solid #00448b;
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  position: absolute;
  z-index: 9;
  text-transform: uppercase;
}
.list-group-item.subheader {
    border-bottom: 1px solid #e0e3e6;
    min-height: 4.8rem;
    height: 4.8rem;
    font-weight: 500;
    font-size: 13px;
    line-height: 44px;
    background: #cddbeb !important;
}
.outlet-address {
    padding-left: 12px;
    padding-right: 12px;
    font-weight: 600;
    text-transform: capitalize !important;
}
 .right-sidebar .table td {
    color: #4e5764 !important;
    font-weight: 600;
}
.billing-mamage .table tbody td {
    border-bottom: 1px solid transparent !important;
}
ul.pager li a {
  cursor: pointer;
  text-decoration: none !important;
}
.filter-list {
  position: fixed;
  right: 0;
  width: 425px;
  margin-right: -425px;
  bottom: 0;
  top: 60px;
  z-index: 101;
  transition: all 0.4s ease;
}
.filter-list.right-sidebar {
  margin-right: 0;
  background: #cddbeb !important;
  top: 58px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.25);
  max-height: 100%;
  overflow: auto;
  padding-bottom: 20px;
  overflow-x: hidden;
}
.pagination-select {
  background: transparent;
  border: none;
  margin-left: 10px;
  border-bottom: 1px solid #00448b;
  border-radius: 0px !important;
  padding-right: 15px !important;
  background-position: right 5px center !important;
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%22.38%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
}
.pager {
  padding-left: 28px;
  list-style: none;
  display: inline-flex;
}
.pageone {
  margin-left: 30px;
}
.summaryBreakdown {
  border: solid 1px #94bef0;
  border-radius: 10px;
}
.btn-checkout {
  border-radius: 23px;
  background-image: linear-gradient(to bottom, #ffb333, #e29005);
  color: #303031;
  padding: 0px 10px;
  margin: 0px;
  font-size: 14px !important;
  font-weight: 600;
  height: 3.4rem;
  line-height: 2.6rem;
  text-transform: capitalize;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.btn-checkout i {
  color: #303031;
  font-size: 16px !important;
  font-weight: 600;
  padding-left: 16px;
}
.borderTableRadius {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.table-fixed tbody {
  display: block;
  width: 100%;
  height: 380px;
  max-height: 390px;
  overflow-y: auto;
}
.table-fixed thead,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: flex;
  flex: 1 1 0%;
}
.table-fixed tbody tr,
.table-fixed tbody td {
  align-items: center;
  font-weight: 600;
}
.table-fixed thead th {
  align-items: center;
}
.table-fixed tfoot {
  line-height: 52px;
}
.hide-arrow {
  visibility: hidden;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}
.summaryBreakdown {
  overflow-x: auto;
  background-color: #00448b;
  padding: 10px;
}
.table.table-reports {
  border-radius: 7.5px;
  background-color: #dee8f2;
}
.table.table-reports tbody tr {
  border-bottom: 1px solid #a8adb4 !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #94bef0;
  border: 4px solid transparent;
  background-clip: content-box; /* THIS IS IMPORTANT */
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 9px solid #00448b;
}
.loadInvoice{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loaderDiv{
  left: 50%;
  top: 50%;
  margin-left: -4em;
}
.table.tablebill th.thbill {
  color: #303031;
  line-height: 1.25;
  letter-spacing: 0.3px;
  font-size: 12px !important;
  padding: 5px 8px !important;
  text-transform: none !important;
  border-bottom: 0px solid transparent !important;
}
.table.tablebill  tr{
  height: 36px !important;
}
</style>
