import { EventBus } from '../eventBus/nav-bar-event.js'
export default {
    data() {
        return {
            count: 0,
            limit: 10,
            skip: 0,
            start: 1,
            end: 0,
            search: '',
            modal_msg: '',
            ajaxCallInProgress: false,
            showIllustrator: false,
            passed_key: {
                key: 'name',
                value: 1
            },
            addonTypeId: ''
        }
    },
    methods: {
        saveAddon() {
            this.getAllAddonsType.forEach(element => {
                if (element.name == this.addoncreate.type_id) {
                    this.addonTypeId = element
                }
            })
            let addonCreate = {
                name: this.addoncreate.name,
                quantity: this.addoncreate.quantity,
                image_url: this.addoncreate.image_url,
                price: this.addoncreate.price,
                category_ids: this.addoncreate.category_ids,
                uom: this.addoncreate.uom._id,
                flavour_ids: this.addoncreate.flavour_ids,
                addon_section_id: this.addoncreate.addon_section_id._id,
                type_id: this.addonTypeId,
                is_available: this.addoncreate.is_available,
                measure: this.addoncreate.measure
            }
            this.$http
                .post('/add_ons/addon/create', addonCreate)
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.addoncreate = {
                            name: '',
                            quantity: '',
                            image_url: '',
                            price: '',
                            category_ids: [],
                            uom: '',
                            flavour_ids: [],
                            addon_section_id: '',
                            type_id: '',
                            measure: '',
                            is_available: true
                        }
                        this.temp_categories_ids = []
                        this.$emit('addonSaved')
                        this.$modal.hide('addon-create')
                        this.$swal({
                            title: 'Addon Added Successfully',
                            text: '',
                            type: 'success'
                        })
                    } else {
                        this.modal_msg = response.data.response
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(response => {
                    this.modal_msg = response.data.response
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },
        getAddonsType() {
            this.$http
                .post('/add_ons/flavours/get', {
                    flavour_type: 'type'
                })
                .then(res => {
                    this.getAllAddonsType = res.data.result
                })
        },
        getOthers() {
            this.$http
                .post('/add_ons/flavours/get', {
                    flavour_type: 'flavour'
                })
                .then(res => {
                    this.getOthersOptions = res.data.result
                })
        },
        setPasskey: function(newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.getAllAddons()
        },
        getAllAddons(param) {
            if (param == 'prev') this.count--
            else if (param == 'next') this.count++
            else if (param == 'init') {
                this.count = 0
            } else if (param == 'search') {
                this.count = 0
            }
            this.ajaxCallInProgress = true
            this.getAllAddonsForManage = []
            this.$http
                .post('/add_ons/addon/get', {
                    skip: this.count * this.limit,
                    limit: this.limit,
                    search_key: this.search,
                    sort_key: this.passed_key.key,
                    sort_value: this.passed_key.value
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.getAllAddonsForManage = res.data.result
                        this.totalAddons = res.data.total_addons
                        this.start = this.count * this.limit + 1
                        this.end =
                            this.count * this.limit + this.limit <
                            this.totalAddons
                                ? this.count * this.limit + this.limit
                                : this.totalAddons
                    }
                    this.ajaxCallInProgress = false
                    if (
                        this.getAllAddonsForManage.length == 0 &&
                        !this.ajaxCallInProgress &&
                        !this.search
                    ) {
                        this.showIllustrator = true
                    } else {
                        this.showIllustrator = false
                    }
                })
        },
        editAddon(details) {
            this.$http
                .post('/add_ons/addon/edit', details)
                .then(response => {
                    if (response.data.status_id == 1) {
                        if (this.byAddonSection == true) {
                            EventBus.$emit('saveByAddonSection', this.sectionId)
                            this.$modal.hide(this.modal_name)
                            this.$swal({
                                type: 'success',
                                title: 'Addon Updated Successfully',
                                text: '',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        } else {
                            this.$emit('addonEdited')
                            this.$modal.hide(this.modal_name)
                            this.$swal({
                                title: 'Addon Updated Successfully',
                                text: '',
                                type: 'success'
                            })
                        }
                    } else {
                        this.modal_msg = response.data.response
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(response => {
                    this.modal_msg = response.data.response
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },
        checkPageCount() {
            if (this.count >= 1) {
                if (this.totalAddons <= this.count * this.limit + 1) {
                    this.count--
                }
            }
            this.getAllAddons()
        }
    }
}
