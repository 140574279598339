export default {
  methods: {
    async createTax(params) {
      return new Promise(async (resolve, reject) => {
        try{
          const response = await this.$http.post("outlet/add_tax_rule", params)
          if (response.data.status_id === 1) {
            resolve(response.data)
          } else {
            reject(response.data.reason)
          }
        } catch(error) {
            reject(error.response.data.reason)
        }
      })
    },
    async updateTax(params) {
      return new Promise(async (resolve, reject) => {
        try{
          const response = await this.$http.post("outlet/edit_tax_rule", params)
          if (response.data.status_id === 1) {
            resolve(response.data)
          } else {
            reject(response.data.reason)
          }
        } catch(error) {
            reject(error.response.data.reason)
        }
      })
    },
    async deleteTax(params) {
      return new Promise(async (resolve, reject) => {
        try{
          const response = await this.$http.post("outlet/remove_tax_rule", params)
          if (response.data.status_id === 1) {
            resolve(response.data)
          } else {
            reject(response.data.reason)
          }
        } catch(error) {
            reject(error.response.data.reason)
        }
      })
    },
  }
}
