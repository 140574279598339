<template>
  <div>
    <modal :name="modal_odetails" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">ORGANISATION DETAILS</span>
        </div>
        <div class="general-section" style="padding: 15px 0px;">
        <div class="v-modal-body" style="padding: 0px!important;">
          <div
            class=""
            style="padding: 15px!important;margin-left: 12px;margin-right: 12px;"
          >
            <div class="orga-info mt-3">
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-weight-bold fs-13"
                    style="line-height: 59px!important;"
                  >Organisation Logo :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/company-logo.svg" style="width: 60px!important;" />
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Organisation :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_name}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Website :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-600 customerInfoWidth text-secondary">-</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Email :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_email}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Phone Number :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-600 customerInfoWidth text-secondary">-</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Telephone Number :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-600 customerInfoWidth text-secondary">-</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Time Zone :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_timezone}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Organisation Address</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_name}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Street :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_street}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">City :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.Atlanta || '-'}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">State :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_state}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Zip Code :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_pincode}}</span>
                </div>
              </div>
              <hr style="border-top: 1px solid #c0c4cc;" />
              <div class="col-md-12 d-flex pt-3 pb-2">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span class="font-weight-bold fs-13">Country :</span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <span
                    class="font-600 customerInfoWidth text-secondary"
                  >{{organisation_details.account_country}}</span>
                </div>
              </div>
            </div>
            <h6 class="text-secondary pt-4 pl-1 font-600">Daily Reports Configuration:</h6>
            <div class="col-lg-12 pt-2 pl-1">
              <div class="form-group contactGroup" style="padding-top: 0px;">
                <label
                  for="inputSattus"
                  class="col-form-label text-secondary fs-14 font-600"
                  style="font-size:14px!important;"
                >
                  Business Day Start Time
                  <sup>★</sup>
                </label>
                <!-- <el-time-picker class="el-date-orga"
                        v-model="organisation_details.send_aggregation_report_at"
                        placeholder="Arbitrary time">
                </el-time-picker>-->
                <vue-timepicker
                  class="organizationDate"
                  v-model="organisation_details.business_day_start_time"
                  format="HH:mm"
                  close-on-complete
                ></vue-timepicker>
                <span
                  class="text-danger"
                  v-show="isInvalid"
                  v-if="isSubmitted"
                >Bussiness Start Time is Required</span>
              </div>
            </div>
            <div class="col-lg-12 pt-2 pl-1">
              <div class="form-group contactGroup" style="padding-top: 0px;">
                <label
                  for="inputSattus"
                  class="col-form-label text-secondary fs-14 font-600"
                  style="font-size:14px!important;"
                >
                  Report Generating Time ({{organisation_details.account_timezone}})
                  <sup>★</sup>
                </label>
                <!-- <el-time-picker class="el-date-orga"
                        v-model="organisation_details.send_aggregation_report_at"
                        placeholder="Arbitrary time">
                </el-time-picker>-->
                <vue-timepicker
                  class="organizationDate"
                  v-model="organisation_details.send_aggregation_report_at"
                  format="HH:mm"
                  close-on-complete
                ></vue-timepicker>
              </div>
            </div>
            <div class="col-lg-12 pt-2 pl-1">
              <div class="form-group contactGroup" style="padding-top: 0px;">
                <label
                  for="inputSattus"
                  class="col-form-label text-secondary fs-14 font-600"
                  style="font-size:14px!important;"
                >
                  Email
                  <sup>★</sup>
                </label>
                <vue-tags-input
                  v-model="email"
                  name="invalidEmail"
                  id="tags"
                  v-validate="{required:true,regex:/^[^@]+@\w+(\. \w+)+\w$/}"
                  :tags="organisation_details.aggregation_sales_report_email"
                  @tags-changed="newTags => organisation_details.aggregation_sales_report_email = newTags"
                  placeholder="Add Emails & Press Enter"
                  @input="makeEmailValidationFalse"
                />
                <div v-show="errors.has('invalidEmail')" class="text-danger fs-13">Invalid Email Address Detected</div>
                <div v-show="noEmail && organisation_details.aggregation_sales_report_email.length == 0" class="text-danger fs-13">Please Enter Email Address</div>
              </div>
            </div>
            <div class="col-lg-12 pt-4 pl-1">
              <el-checkbox
                v-model="organisation_details.send_order_placed_report"
              >Send Order Placed Report</el-checkbox>
              <el-checkbox
                v-model="organisation_details.send_aggregate_sales_report_by_customer"
              >Send Aggregate Sales Report by Customer</el-checkbox>
            </div>
            <div class="col-lg-12 pt-4 pl-1">
              <el-checkbox
                v-model="organisation_details.send_daily_sales_report_by_customer"
              >Send Daily Sales Report by Customer</el-checkbox>
            </div>
          </div>
        </div>
        </div>
        <div class="v-modal-dialog-actions modal-bg">
          <a
            @click="HideOrganisationDetails"
            class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
            style="border:1px solid #00448b;margin-right: 20px!important;"
          >CANCEL</a>
          <button
            class="btn btn-secondary btn-smm text-uppercase"
            @click="saveOrganisation()"
            :disabled="disableSave"
          >SAVE</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<style scoped>
.orga-info {
  border: 1px solid #c0c4cc;
  border-radius: 18px;
}
.el-date-orga.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.v-modal-layout {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
  props: ['modal_odetails', 'organisation_details'],
  components: {
    VueTimepicker
  },
  data() {
    return {
      email: '',
      send_aggregation_report_at: '',
      emails: [],
      report_emails: [],
      email_regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/,
      invalidEmail: false,
      noEmail: false,
      aggregation_sales_report_email: [],
      disableSave: false,
      yourTimeValue: { HH: '00', mm: '00' },
      time_list: [],
      isInvalid: false,
      isSubmitted: false,
      send_order_placed_report: false,
      send_aggregate_sales_report_by_customer: false,
      send_daily_sales_report_by_customer: false
    }
  },
  created() {
    // if(this.organisation_details){
    //     this.send_aggregation_report_at = this.organisation_details.send_aggregation_report_at
    //     this.aggregation_sales_report_email = this.organisation_details.aggregation_sales_report_email
    // }
  },
  components: {
    VueTimepicker
  },
  methods: {
    ShowOrganisationDetails() {
      this.$modal.show('orga-details')
    },
    HideOrganisationDetails() {
      this.report_emails.length = 0
      this.disableSave = false
      this.$modal.hide('orga-details')
    },
    setScheduler() {
      this.$http
        .get('/report_scheduler/schedule_daily_aggregation_sales_report')
        .then(res => {
          if (res.data.status_id == 1) {
            this.disableSave = false
            this.$modal.hide('orga-details')
            this.$swal({
              title: 'Success',
              text: 'Updated Successfully',
              type: 'success'
            })
          } else {
            this.disableSave = false
            this.$swal({
              title: 'Warning',
              text: res.data.reason,
              type: 'warning'
            })
          }
        })
    },
    saveOrganisation() {
      if (this.organisation_details.business_day_start_time == '') {
        this.isSubmitted = true
        this.isInvalid = true
        this.disableSave = false
      } else {
        this.updateOrganisation()
      }
    },
    updateOrganisation() {
      this.isSubmitted = false
      this.disableSave = true
      this.organisation_details.aggregation_sales_report_email.forEach(
        element => {
          if (element['text']) {
            if (element['text'].match(this.email_regex)) {
              this.report_emails.push(element['text'])
            } else {
              this.report_emails.length = 0
              this.invalidEmail = true
              this.disableSave = false
              return
            }
          } else {
            if (element.match(this.email_regex)) {
              this.report_emails.push(element)
            } else {
              this.report_emails.length = 0
              this.invalidEmail = true
              return
            }
          }
        }
      )
      if (this.report_emails.length) {
        let temp_date = new Date()
        // this.time_list = this.organisation_details.send_aggregation_report_at.split(":")
        // let temp_date1 = Date.UTC(temp_date.getUTCFullYear(),temp_date.getUTCMonth(),temp_date.getUTCDate(),this.time_list[0],this.time_list[1])
        // let temp_date2 = new Date(temp_date1)
        if (
          typeof this.organisation_details.send_aggregation_report_at ==
          'object'
        ) {
          this.organisation_details.send_aggregation_report_at =
            this.organisation_details.send_aggregation_report_at['HH'] +
            ':' +
            this.organisation_details.send_aggregation_report_at['mm']
        } else {
          this.organisation_details.send_aggregation_report_at = this.organisation_details.send_aggregation_report_at
        }

        if (
          typeof this.organisation_details.business_day_start_time == 'object'
        ) {
          this.organisation_details.business_day_start_time =
            this.organisation_details.business_day_start_time['HH'] +
            ':' +
            this.organisation_details.business_day_start_time['mm']
        } else {
          this.organisation_details.business_day_start_time = this.organisation_details.business_day_start_time
        }

        this.$http
          .post('/outlet/update_organisation_settings', {
            report_date: this.organisation_details.send_aggregation_report_at,
            emails: this.report_emails,
            send_order_placed_report: this.organisation_details
              .send_order_placed_report,
            send_aggregate_sales_report_by_customer: this.organisation_details
              .send_aggregate_sales_report_by_customer,
            send_daily_sales_report_by_customer: this.organisation_details
              .send_daily_sales_report_by_customer,
            business_day_start_time: this.organisation_details
              .business_day_start_time
          })
          .then(res => {
            if (res.data.status_id == 1) {
              this.report_emails.length = 0
              this.disableSave = false
              this.invalidEmail = false
              // this.$modal.hide('orga-details')

              this.setScheduler()
            } else {
              this.disableSave = false
              this.isInvalid = false
              this.$swal({
                title: 'Error',
                text: 'Report Generation Not Set. Please Try Again',
                type: 'error'
              })
            }
          })
      } else {
        this.noEmail = true
        this.disableSave = false
        this.isInvalid = false
        return
      }
    },
    makeEmailValidationFalse() {
      this.noEmail = false
      this.invalidEmail = false
    }
    // getOrganisationDetails(){
    //     this.$http.get('/outlet/get_organisation_details').then(res => {
    //         if(res.data.status_id == 1){
    //             this.organisation_details = res.data.response
    //         }
    //     })
    // }
  }
}
</script>
