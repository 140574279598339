import Swal from 'sweetalert2'
import {
    mapGetters
} from 'vuex'
import {
    EventBus
} from './../eventBus/nav-bar-event'

export default {
    data() {
        return {
            categories: [],
            category: {
                name: '',
                description: '',
                image_url: ''
            },
            totalCategories: 0,
            search: '',
            searchProduct: '',
            modal_msg: '',
            categoryBool: {
                saving: false,
                editing: false
            },
            categoryProductsID: '',
            categoryProducts: [],
            createdCategoryId: null,
            singleCategory: {},
            ajaxCallInProgress: false,
            showIllustrator: false,
            count: 0,
            limit: 10,
            skip: 0,
            start: 1,
            end: 0,
            passed_key: {
                key: 'name',
                value: 1
            }
        }
    },

    methods: {
        createCategory(category) {
            this.$http
                .post('/categories/create_category', category)
                .then(response => {
                    if (response.data.status_id == 1) {
                        this.$modal.hide('create-category')
                        this.$swal({
                            title: 'Category Added Successfully',
                            text: '',
                            type: 'success'
                        })
                        this.$emit('saving')
                        this.category = {
                            name: '',
                            description: '',
                            type: '',
                            image_url: ''
                        }
                    } else {
                        this.modal_msg = response.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                    this.categoryBool.saving = false
                })
                .catch(response => {
                    this.modal_msg = response.data.reason
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                    this.categoryBool.saving = false
                })
        },
        deleteCategory(category) {
            this.$http
                .post('/categories/delete_category', {
                    category_id: category.category_id
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        Swal(
                            'Deleted!',
                            category.category_name +
                            ' Category has been deleted.',
                            'success'
                        )
                        this.checkPageCount()
                        this.$emit('saving')
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },
        confirmDelete(category) {
            Swal({
                title: 'Are you sure?',
                text: 'Are you sure To delete the Category ' +
                    category.category_name +
                    ' ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.deleteCategory(category)
                }
            })
        },
        updateCategory(category) {
            this.$http
                .post('/categories/edit_category', category)
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.$modal.hide('edit-category')
                        this.$emit('editSaving')
                        this.modal_msg = 'Category Updated Succesfully'
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'success'
                        })
                    } else {
                        this.modal_msg = res.data.reason
                        this.$swal({
                            title: this.modal_msg,
                            text: '',
                            type: 'warning'
                        })
                    }
                    this.categoryBool.editing = false
                })
                .catch(res => {
                    this.categoryBool.editing = false
                    this.modal_msg = res.data.reason
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },

        // getCategories() {
        //   this.ajaxCallInprogress = true;
        //   this.$http
        //     .get("/categories/get", {
        //       params: {
        //         search: this.search
        //       }
        //     })
        //     .then(res => {
        //       if (res.data.status_id == 1) {
        //         this.categories = res.data.message.categories;
        //         this.totalCategories = res.data.message.total;
        //       }
        //       this.ajaxCallInprogress = false;
        //       if(!this.ajaxCallInprogress && this.categories.length == 0 && !this.search){
        //         this.showIllustrator = true
        //       }else{
        //         this.showIllustrator = false
        //       }
        //     })
        //     .catch(res => {
        //       this.modal_msg = res.response.data.reason;
        //       this.$swal({
        //         title: this.modal_msg,
        //         text: '',
        //         type: 'warning'
        //       })
        //     });
        // },
        getCategory(id) {
            this.$http
                .get('/categories/get/' + id)
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.singleCategory = res.data.message
                    }
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason
                    this.$swal({
                        title: this.modal_msg,
                        text: '',
                        type: 'warning'
                    })
                })
        },
        showCreateCategory() {
            this.$modal.show('create-category')
        },
        hideCreateCategory() {
            this.$modal.hide('create-category')
            this.$modal.hide('edit-category')
        },
        getProductsForCategory(id, searchData) {
            return new Promise((resolve, reject) => {
                if (id)
                    this.$http
                    .get('categories/get/' + id + '/products', {
                        params: {
                            search: searchData
                        }
                    })
                    .then(res => {
                        if (res.data.status_id == 1)
                            resolve(res.data.message)
                        else reject(res.data.reason)
                    })
                    .catch(res => reject(res.response.data.reason))
            })
        },
        updateDisplayOrder(id, index) {
            this.$http
                .post('categories/get/' + id + '/display_order', {
                    index
                })
                .then(res => {
                    if (res.data.status_id == 1) this.getCategories()
                })
                .catch(res => {
                    this.modal_msg = res.response.data.reason
                    this.$refs.warning_modal.open()
                })
        },
        async searchProductsForCategory(id) {
            this.products = await this.getProductsForCategory(
                // this.categoryProductsID,
                id,
                this.searchProduct
            )
        },
        updateSearchProduct(newValue) {
            this.searchProduct = newValue
        },
        manageCategories(param) {
            if (param == 'prev') this.count--
            else if (param == 'next') this.count++
            else if (param == 'init') {
                this.count = 0
            } else if (param == 'search') {
                this.count = 0
            }
            this.ajaxCallInProgress = true
            this.categories = []
            this.$http
                .post('/categories/manage_category_type', {
                    skip: this.count * this.limit,
                    limit: this.limit,
                    searchdata: this.search.trim(),
                    sort_key: this.passed_key.key,
                    sort_value: this.passed_key.value
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.categories = res.data.response.all_categories
                        this.totalCategories = res.data.response.total_categories
                        this.start = this.count * this.limit + 1
                        this.end =
                            this.count * this.limit + this.limit <
                            this.totalCategories ?
                            this.count * this.limit + this.limit :
                            this.totalCategories
                    }
                    this.ajaxCallInProgress = false
                    if (
                        this.categories.length == 0 &&
                        !this.ajaxCallInProgress &&
                        !this.search
                    ) {
                        this.showIllustrator = true
                    } else {
                        this.showIllustrator = false
                    }
                })
        },
        setPasskey: function (newKey) {
            this.$set(this.passed_key, 'key', newKey)
            var value = this.passed_key.value == -1 ? 1 : -1
            this.$set(this.passed_key, 'value', value)
            this.manageCategories()
            // if (this.count == 0) this.manageCategories()
            // else this.count = 0
        },
        changestatus(status, category_id) {
            this.$http
                .post('/categories/edit_category_status', {
                    status: status,
                    category_id: category_id
                })
                .then(res => {
                    if (res.data.status_id == 1) {
                        this.$toasted.global.success(
                            'Category Status Updated Successfully'
                        )
                    } else {
                        this.$toasted.global.error(
                            'Cannot Update Category Status'
                        )
                    }
                })
            // this.manageCategories();
        },
        checkPageCount() {
            if (this.count >= 1) {
                if (this.totalCategories <= this.count * this.limit + 1) {
                    this.count--;
                }
            }
            this.manageCategories()
        }
    },
    watch: {
        searchProduct(newKey, oldKey) {
            this.searchProductsForCategory()
        },
        createdCategoryId(newId, oldId) {
            EventBus.$emit('createdCategoryId', newId)
        }
    },
    computed: {
        ...mapGetters({
            loggedInUser: 'loggedInUser'
        })
    }
}
