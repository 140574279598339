<template>
  <div class="page-layout simple right-sidebar inner-sidebar">
    <div class style="height: 100%;">
      <div id="mail" style="margin-right: 0px;margin-left: 15px;height: 100%;">
        <div class="content-section">
          <!-- <div class="fuse-sidebar sidebar left-positioned open locked-open preview">
						<div class="preview-elements details-block">
							<div class="content">
                                <div class="description">
                                    <div class="description-text" style="cursor:pointer;">
                                        <a class="add-customer-heading"><img src="https://cdn.digicollect.com/cdn/posv2/customers.svg" style="height: 14px;" class="pr-2">Add Customer</a>
                                    </div>
                                    <div class="btn-group toggle-source-preview" role="group">
                                        <button id="btnGroupVerticalDrop1" type="button" class="btn btn-select-table dropdown-toggle fuse-ripple-ready" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            FF1234
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -108px, 0px); top: 0px; left: 0px; will-change: transform;">
                                            <a class="dropdown-item fuse-ripple-ready">Table 1</a>
                                            <a class="dropdown-item fuse-ripple-ready">Table 2</a>
                                        </div>
                                    </div>
                                </div>
                                <hr style="border-top: 0.5px solid #94bef0;">
                                <div class="row">
                                    <div class="col-md-12">
                                        <table class="table tablecart">
                                            <thead>
                                                <tr style="border-bottom: solid 0.5px #e0e3e6;">
                                                    <th class="thcart">Items</th>
                                                    <th class="thcart">Tax</th>
                                                    <th class="thcart">Price</th>
                                                    <th class="thcart"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="tdcart items">Burger/Large X 2</td>
                                                    <td class="tdcart">$1</td>
                                                    <td class="tdcart">$12</td>
                                                    <td class="tablesubtotaltd">
                                                        <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                                                            <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                                                <i class="icon icon-delete"></i>
                                                            </button>
                                                        </el-tooltip>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tdcart items">Coffee/Medium</td>
                                                    <td class="tdcart">$1</td>
                                                    <td class="tdcart">$06</td>
                                                    <td class="tablesubtotaltd">
                                                        <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                                                            <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                                                <i class="icon icon-delete"></i>
                                                            </button>
                                                        </el-tooltip>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="tdcart items">Milkshake </td>
                                                    <td class="tdcart">$1</td>
                                                    <td class="tdcart">$12</td>
                                                    <td class="tablesubtotaltd">
                                                        <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                                                            <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                                                <i class="icon icon-delete"></i>
                                                            </button>
                                                        </el-tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th class="tfcart">ADD</th>
                                                    <th class="tfcart"></th>
                                                    <th class="tfcart text-secondary">
                                                        <span class="note-add" @click="addNote()">Note</span>
                                                    </th>
                                                    <th class="tfcart text-secondary">
                                                        <span class="note-add" @click="addDiscount()"> Discount
                                                        </span>
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div class="shownote pt-4" v-show="addNotediv">
                                    <textarea class="form-control note-textarea" rows="2" style="height: 80px!important;"></textarea>
                                    <div class="text-center pt-4 pb-2">
                                            <a  class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideNotediv()">CANCEL</a>
                                        <button type="submit" class="btn btn-secondary btn-smm" @click="hideNotediv()" >SAVE</button>
                                    </div>
                                </div>
                                <div class="showdiscount pt-4" v-show="adddiscountdiv">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <span class="pull-right pointer">
                                                <a class="DollarTab">%</a>
                                                <a class="PerTab">$</a>
                                            </span>
                                        </div>
                                        <div class="col-md-8">
                                            <input class="form-control discount-input">
                                        </div>
                                    </div>
                                </div>
                                <table class="table tablesubtotal">
                                    <tbody>
                                        <tr class="type">
                                            <th class="tablesubtotalth">Subtotal</th>
                                            <td class="tablesubtotaltd">$24.00</td>
                                        </tr>
                                        <tr class="size">
                                            <th class="tablesubtotalth">Tax </th>
                                            <td class="tablesubtotaltd">$1.00</td>
                                        </tr>
                                        <tr class="location">
                                            <th class="tablesubtotalth">Discount</th>
                                            <td class="tablesubtotaltd">$1.00</td>
                                        </tr>
                                        <tr class="owner">
                                            <th class="tablesubtotalth">Roundoff</th>
                                            <td class="tablesubtotaltd">$00.00</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="tfoot-total-order">
                                        <tr>
                                            <th class="tfcart text-secondary tablesubtotaltd">Total</th>
                                            <th class="tfcart tablesubtotaltd">$24.00</th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div class="pt-3">
                                    <a class="text-secondary">
                                        <span>Save For Later</span>
                                    </a>
                                    <a class="text-secondary pl-6">
                                        <span>Discard</span>
                                    </a>
                                </div>
                            </div>
                            <button class="btn btn-pay mt-3">
                                    PAY
                                <span class="badge badge-secondary">$24.00</span>
                            </button>
						</div>
          </div>-->
          <div class="center-section pt-5">
            <div class="row pb-2 pr-2">
              <!-- <div class="col-md-3">
								<label class="label-order">Select Category</label>
								<div class="btn-group" role="group">
									<button id="btnGroupVerticalDrop1" type="button" class="btn btn-order dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
										All
									</button>
									<div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -108px, 0px); top: 0px; left: 0px; will-change: transform;">
                                        <a class="dropdown-item fuse-ripple-ready" href="#">Starters</a>
										<a class="dropdown-item fuse-ripple-ready" href="#">Beverages</a>
										<a class="dropdown-item fuse-ripple-ready" href="#">Ingredient</a>
                                        <a class="dropdown-item fuse-ripple-ready" href="#">Snacks</a>
										<a class="dropdown-item fuse-ripple-ready" href="#">Meals</a>
									</div>
								</div>
              </div>-->
              <div class="col-md-6">
                <label class="label-order">Find Customers By Name</label>
                <div class="m-input-icon m-input-icon--left m-input-icon--right">
                  <input
                    type="text"
                    class="form-control m-input m-input--pill"
                    placeholder="Start Typing To See Customers"
                    autocomplete="off"
                  />
                  <span class="m-input-icon__icon m-input-icon__icon--left">
                    <span>
                      <i
                        class="icon icon-magnify"
                        style="
                                                        padding-top: 2px;"
                      ></i>
                    </span>
                  </span>
                  <span class="m-input-icon__icon m-input-icon__icon--right">
                    <span>
                      <i
                        class="icon icon-close-circle"
                        style="
                                                        padding-top: 2px;"
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-md-6 mt-6">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Back to Previous Page"
                  placement="top"
                >
                  <a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)">
                    <i class="icons icon-arrow-left"></i>
                  </a>
                </el-tooltip>
              </div>
            </div>
            <div class="contacts-customer">
              <div class="row">
                <div class="col-md-4">
                  <div class="list-group-item three-line" @click="addCustomerOrder()">
                    <div class="icon">
                      <i class="icon-plus"></i>
                    </div>
                    <div class="list-item-content">
                      <h3 class="add-new-text">Add New Customer</h3>
                    </div>
                  </div>
                </div>
                <!-- <router-link to="/ordermanage"> -->
                <div class="col-md-4" v-for="(customer,index) in customers" :key="index">
                  <div class="list-group-item three-line" @click="redirectOnselect(customer)">
                    <div class="avatar">
                      <img :src="customer.img_url || 'https://cdn.digicollect.com/cdn/posv2/img/man-1.png'" />
                    </div>
                    <div class="list-item-content">
                      <h3>{{customer.first_name}}</h3>
                      <h4>{{customer.email}}</h4>
                      <p>{{customer.phone_number}}</p>
                    </div>
                  </div>
                </div>
                <!-- </router-link> -->
                <!-- <div class="col-md-4">
                                    <div class="list-group-item three-line">
                                        <div class="avatar">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/img/man.png">
                                        </div>
                                        <div class="list-item-content">
                                            <h3>John Dou</h3>
                                            <h4>john_dou@gmail.com</h4>
                                            <p>+91 123 456 7896  </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="list-group-item three-line">
                                        <div class="avatar">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/img/businessman.png">
                                        </div>
                                        <div class="list-item-content">
                                            <h3>Jimmy Falcon</h3>
                                            <h4>jimmy_fal@gmail.com</h4>
                                            <p>+91 123 456 7897  </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="list-group-item three-line">
                                        <div class="avatar">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/img/boy.png">
                                        </div>
                                        <div class="list-item-content">
                                            <h3>Graham Geroge</h3>
                                            <h4>graham_geroge@gmail.com</h4>
                                            <p>+91 123 456 7898   </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="list-group-item three-line">
                                        <div class="avatar">
                                            <img src="https://cdn.digicollect.com/cdn/posv2/img/boy.png">
                                        </div>
                                        <div class="list-item-content">
                                            <h3>James Brown</h3>
                                            <h4>james_br@gmail.com</h4>
                                            <p>+91 123 456 7899 </p>
                                        </div>
                                    </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <modal name="quantity-order" transition="nice-modal-fade">
                <div class="v-modal-content">
                    <div class="v-modal-header">
                        <span class="v-modal-dialog-title v-modal-title">Coffee</span>
                    </div>
                    <div class="v-modal-body">
                        <div class="v-modal-layout pt-3 pl-2 pr-3 d-form">
                            <div class="d-form__group">
                                <label class="label-qty">Quantity</label>
                                <div class="input-group" style="width: 200px;">
                                    <div class="input-group-prepend"><span class="input-group-text btnqty" id="basic-addon2"><i class="btni icon icon-plus-circle"></i></span></div>
                                    <input type="text" class="form-control m-input inputorder" value="12" aria-describedby="basic-addon2" autocomplete="off">
                                    <div class="input-group-append"><span class="input-group-text btnqty" id="basic-addon2"><i class="btni icon icon-minus-circle"></i></span></div>
                                </div>
                            </div>
                            <div class="d-form__group">
                                <label class="label-qty">Choose Variants</label>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="variantsdiv seletedvariant">
                                            <span class="variantsname">
                                                Regular
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="variantsdiv">
                                            <span class="variantsname">
                                                Medium
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="variantsdiv">
                                            <span class="variantsname">
                                                Large
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="v-modal-footer text-center pt-5">
                            <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hideQuantity()">CANCEL</a>
                        </div>
                    </div>
                </div>
      </modal>-->
      <modal name="customer-create" transition="nice-modal-fade">
        <div class="v-modal-content">
          <div class="v-modal-header">
            <span class="v-modal-dialog-title v-modal-title">ADD NEW CUSTOMER</span>
          </div>
          <div class="v-modal-body">
            <create-customer></create-customer>
            <!-- <div class="v-modal-footer text-center pt-5">
                            <a  class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hidecustomer()">CANCEL</a>
                            <button type="submit" class="btn btn-secondary btn-smm" @click="hidecustomer()" >SAVE</button>
            </div>-->
          </div>
        </div>
      </modal>
      <!-- <modal name="customer-create" transition="nice-modal-fade">
                <div class="v-modal-content">
                    <div class="v-modal-header">
                        <span class="v-modal-dialog-title v-modal-title">ADD NEW CUSTOMER</span>
                    </div>
                    <div class="v-modal-body">
                        <div class="v-modal-layout pt-3 pl-2 pr-3">
                            <div class="row first-middle-input">
                                <div class="col-md-12">
                                    <div class="preview-elements pb-2">
                                        <h6 style="font-weight: bold;color: #303031">Personal Information</h6>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio"  name="salutation" value="Mr">
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description">Mr.</span>
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" name="salutation" value="Mrs">
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description">Mrs.</span>
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input class="form-check-input" type="radio" name="salutation" value="Miss">
                                                <span class="radio-icon"></span>
                                                <span class="form-check-description">Miss</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group contactGroup" style="margin-top: -10px;">
                                                <input class="inputContact" type="text" required>
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">First Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group contactGroup" style="margin-top: -10px;">
                                                <input class="inputContact" type="text" required>
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Middle Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group contactGroup" style="margin-top: -10px;">
                                                <input class="inputContact" type="text" required>
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Last Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-4">
                                <div class="col-sm-12">
                                    <h6 style="font-weight: bold;color: #303031">Communication Information</h6>
                                    <div class="form-group contactGroup" style="margin-top: -10px;">
                                        <input class="inputContact" type="text" value="john_dou@gmail.com">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Email</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-3">
                                <div class="col-sm-12">
                                    <vue-tel-input v-model="phone" placeholder="Enter Your Phone Number"
                                        @onInput="onInput" >
                                    </vue-tel-input>
                                </div>
                            </div>
                            <div class="pt-4">
                                <el-checkbox v-model="checkedoption">Opt for Promotional Emails and Offers Email</el-checkbox>
                            </div>
                            <div class="row pt-4">
                                <div class="col-sm-12">
                                    <h6 style="font-weight: bold;color: #303031">Outlets</h6>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio"  name="salutation" value="Bangalore">
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description">Bangalore</span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="salutation" value="Mumbai">
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description">Mumbai</span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="salutation" value="New York">
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description">New York</span>
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input class="form-check-input" type="radio" name="salutation" value="China">
                                            <span class="radio-icon"></span>
                                            <span class="form-check-description">China</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="v-modal-footer text-center pt-5">
                            <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="addCustomerhideOrder()">CANCEL</a>
                            <a class="btn btn-secondary btn-smm" style="text-transform: uppercase !important;" @click="addCustomerhideOrder()">ADD</a>
                        </div>
                    </div>
                </div>
      </modal>-->
    </div>
  </div>
</template>
<style>
/* @media only screen and (max-width: 1024px) {
        .contacts-customer .list-group-item h3 {
           font-size: 11px!important;
        }
        .contacts-customer .list-group-item h4 {
            max-width: 108px!important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 11px;
        }
        .contacts-customer .list-group-item p {
            font-size: 11px!important;
        }
        .list-group-item.three-line .icon {
            padding: 10px!important;
        }
        .contacts-customer .list-group-item>.avatar {
            width: 50px!important;
            height: 50px!important;
        }
        .contacts-customer .avatar>img {
            max-width: 50px!important;
            max-height: 50px!important;
        }
        .contacts-customer .list-group-item.three-line>.avatar {
            margin-top: 0.2rem!important;
        }
        .contacts-customer .list-group-item.three-line {
            min-height: 70px!important;
        }
        .list-group-item.three-line .icon .icon-plus {
            font-size: 26px!important;
        }
        h3.add-new-text {
            line-height: 50px!important;
        }
    }
    .close-icon .icon-close{
        color: #b8d4ff!important;
    }
    .close-icon{
        text-decoration: none!important;
    }
    .note-add {
        cursor: pointer;
    }
    .form-control.note-textarea {
        border-radius: 7px !important;
        border: solid 1px #cdd2da !important;
    }
    .showdiscount,
    .shownote {
        border-bottom: solid 1px #94bef0 !important;
        padding-bottom: 10px !important;
    }

    .form-control.discount-input {
        border-radius: 100px !important;
        border: solid 1px #cdd2da !important;
        padding: 7px 10px!important;
    }
    .contacts-customer .list-group-item.three-line {
        min-height: 80px;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        border: solid 1px #e3e3e3;
        background-color: #f6f6f6;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        cursor: pointer;
        margin-top: 20px;
    }
    .contacts-customer{
        padding-right: 12px;
    }
    .tdcart.items{
        font-size: 14px;
        font-weight: 600;
        line-height: 1.21;
        letter-spacing: 0.6px;
        color: #00448b!important;
    }
     .contacts-customer .list-group-item.three-line>.avatar {
         margin-top: 1.2rem;
     }
     h3.add-new-text{
         margin: 0 auto!important;
         vertical-align: middle;
         line-height: 60px!important;
         font-size: 14px;
         font-weight: bold;
         color: #00448b!important;
     }
     .list-group-item.three-line .icon{
         border: solid 1px #e3e3e3;
         background-color: #ffffff;
         border-radius: 100%;
         padding: 15px;
     }
      .list-group-item.three-line .icon .icon-plus{
         font-size: 30px!important;
         color: #00448b!important
     }
     .contacts-customer .list-group-item>.avatar {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin: 0 1.6rem 0 0;
        -webkit-border-radius: 100%;
        border-radius: 100%;
    }
    .contacts-customer .list-group-item {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border: none;
        padding: 0.8rem 1.6rem;
        min-height: 4.8rem;
        line-height: 1.6rem;
        text-decoration: none;
    }
    .contacts-customer .list-group-item h3 {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.43;
        letter-spacing: 0.6px;
        margin: 0px;
        color: #303031;
    }
    .contacts-customer .list-group-item h4 {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: 0.5px;
        color: #4e5764;
        margin: 1px 0 3px 0;
    }
    .contacts-customer .list-group-item p {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: 0.5px;
        color: #4e5764;
        margin: 0;
    }
    .contacts-customer .avatar>img {
        max-width: 40px;
        max-height: 40px;
    }
    .contacts-customer .list-group-item>.list-item-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .header-search {
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        position: relative;
        min-height: 96px;
        background-image: none;
        z-index: 49;
        padding: 16px!important
    }

    .header-content {
        flex-flow: row wrap;
        box-sizing: border-box;
        display: flex;
        place-content: stretch space-between;
        align-items: stretch;
        flex: 1 0 auto;
    }
    .dropdown-category {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        max-height: 100%;
        place-content: center;
        align-items: center;
        order: 1;
    }
    .header-board-name {
        font-size: 16px;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: center;
        flex: 1 0 auto;
        order: 2;
        margin-bottom: 0!important;
    }
	.btn.btn-order{
		border: solid 0.5px #cdd2da!important;
		border-radius: 50px;
  		background-color: #f2f2f2;
		font-size: 12px!important;
		font-weight: bold;
        color: #9e9e9e;
        height: 32px;
        min-width: 13rem !important;
    }
    .btn.btn-order.dropdown-toggle::after {
        margin-left: 6.255em;
    }
	.form-control.input-order{
		border-radius: 50px!important;
		border: solid 0.5px #d5d5d5!important;
		background-image: linear-gradient(to bottom, #efefef, #ffffff 25%, #ffffff 48%, #ffffff 78%, #efefef)!important;
	}
	.label-order{
		font-weight: 600;
        color: #9e9e9e;
        line-height: 1.79;
        letter-spacing: 0.3px;
	}
	.unavaliable-order{
		text-align: center;
		display: block;
		position: absolute;
		background: rgba(175, 175, 175, 0.59);
		color: #e82828;
		width: 100%;
        top: 26px;
    }
    .unavaliable-order h6  {
        line-height: 1.79;
        letter-spacing: 0.3px;
        font-weight: 600;
        font-size: 14px;
        margin: 0 auto;
    }
    .list-card-labels {
		flex-flow: row wrap;
		box-sizing: border-box;
		display: flex;
		margin-bottom: 6px;
	}
	.list-card-labels .list-card-label {
		touch-action: none;
		user-select: none;
		-webkit-user-drag: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		width: 32px;
		height: 6px;
		border-radius: 6px;
		margin: 0 6px 6px 0;
	}
	.btn-smm-new {
		height: 34px;
		padding: 0 18px;
		border-radius: 18px;
		font-size: 12px;
		line-height: 34px;
		text-transform: none;
		font-weight: 600;
		box-shadow: none !important;
	}
	.list-group .list-group-item h3 {
		font-size: 12px !important;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #4e5764;
		padding-bottom: 5px;
	}
	.list-group .list-group-item p {
		font-size: 14px !important;
		font-weight: 600 !important;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #303031;
	}
	.list-group.userdetails {
		border-radius: 0px;
		-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0);
		padding-top: 0px;
	}
	.list-group.userdetails .list-group-item.two-line {
		min-height: 6.2rem;
	}
	.list-group.userdetails .list-group-item {
		background-color: transparent;
		border: 0px solid rgba(0, 0, 0, 0.125);
	}
	.list-group .list-group-item {
		padding: 0px;
	}
	.p-16-mod {
		padding: 0px 0px 10px;
	}
	.userdetailsblock {
		width: 250px;
		background-color: #f5f6f9;
		max-width: 260px;
	} */
/* New Box CSS */
/* .page-layout.simple {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		width: 100%;
		min-width: 100%;
	}
	.page-layout.simple.right-sidebar.inner-sidebar {
		flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
		height: 100%;
	}
    .content-section {
		display: flex;
		min-height: 100%;
	} */

/* .fuse-sidebar {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 300px;
        min-width: 300px;
        max-width: 300px;
        z-index: 1000;
    }
    .fuse-sidebar.left-positioned {
        left: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    .page-layout.simple.right-sidebar.inner-sidebar .content-section .sidebar.locked-open {
        padding-left: 10px;
        box-shadow: -1px 0 4px 0 rgba(0, 0, 0, 0.16);
        border-left: solid 1px #cdd2da;
        background-color: #ffffff;
    }
    .page-layout.simple.right-sidebar.inner-sidebar .content-section .sidebar {
        order: 2;
    }
    .fuse-sidebar.locked-open {
        position: relative!important;
        -webkit-transform: translateX(0)!important;
        transform: translateX(0)!important;
    }
    .page-layout.simple.right-sidebar.inner-sidebar .content-section .center-section {
        order: 1;
    } */
/* .page-layout.simple.left-sidebar.inner-sidebar .content-section .center-section, .page-layout.simple.right-sidebar.inner-sidebar .content-section .center-section {
        flex: 1 1 auto;
        -webkit-overflow-scrolling: touch;
    }
    @media (min-width: 992px) {
        .g-mb-0--lg {
            margin-bottom: 0 !important;
        }
    }
    @media (min-width: 0) {
        .g-pa-20 {
            padding: 15px 15px 15px 20px !important;
        }
        .g-mb-10 {
            margin-bottom: 0.71429rem !important;
        }
        .g-mr-15 {
            margin-right: 15px !important;
        }
        .g-pr-15 {
            padding-right: 15px !important;
        }
        .g-pl-15 {
            padding-left: 15px !important;
        }
        .g-mt-3 {
            margin-top: 0px !important;
        }
        .g-mb-3 {
            margin-bottom: 0.21429rem !important;
        }
    }
    .mb-0, .my-0 {
        margin-bottom: 0!important;
    }
    .list-unstyled.itemslist {
        padding-left: 0;
        list-style: none;
        margin-top: 0;
    }
    .u-shadow-v11 {
        box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.16);
        border: solid 0.5px #cdd2da;
        background-color: #f9fbff;
    }
    .rounded {
        border-radius: 4px !important;
    }
    .media.media-item {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        opacity: 1;
    }
    .media.media-item .media-body {
        -ms-flex: 1;
        flex: 1;
    }
    .media.media-item.unavaliable-item {
        opacity: 0.5;
    }
    .g-height-40 {
        height: 40px;
    }

    .g-width-40 {
        width: 40px !important;
    }
    .g-color-main {
        color: #00448b !important;
    }
    .g-font-weight-600 {
        font-weight: 600 !important;
    }
    .g-color-gray-dark-v4 {
        color: #4e5764 !important;
        line-height: 2.25;
        letter-spacing: 0.3px;
    }
    .g-font-size-13 {
        font-size: 13px !important;
    }
    .u-link-v5 {
        text-decoration: none;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        outline: none;
    }
    .itemimg {
        border-right: solid 0.5px #cdd2da;
    }

    .RUCsGf {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 100%;
        margin: -5px;
        overflow: hidden;
    } */
/* .a-HZnjzd-xb-QBLLGd {
        flex: 1 1 150px;
        min-width: 150px;
        max-width: 250px;
    } */
/* .Ccie2c {
        overflow: hidden;
        position: relative;
        vertical-align: top;
        margin-right: 10px;
    }
    .a-dkl3Ye .L202Xe {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .L202Xe {
        background-color: #fff;
        margin: 7px;
        overflow: hidden;
        position: relative;
    }
    .preview {
        width: 100px;
        margin: 0 1px 8px 0;
        z-index: 0 !important;
    }
    .description {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .toggle-source-preview {
        cursor: pointer;
        font-size: 12px;
        padding-left: 16px;
        color: #00448b;
    }
    .btn-select-table{
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 1.21 !important;
        letter-spacing: 0.6px !important;
        color: #00448b !important;
        background-color: transparent !important;
        text-transform: capitalize !important;
    }
    .btn-pay{
        width: 100%!important;
        background-color:#00448b!important;
        color: #fff!important;
        text-align: left;
        font-weight: 600!important;
    }
    .btn-pay .badge{
        float: right!important;
        top: 7px!important;
        font-weight: 600;
    }
    .tfoot-total-order{
        border-top: solid 0.5px #94bef0;
        border-bottom: solid 0.5px #94bef0;
    }
    .table.tablesubtotal tr{
        height: 10px!important;
    }
    .table.tablesubtotal th {
        padding: 4px!important;
    }
    .tablesubtotaltd, .tablesubtotalth{
        text-align: right!important;
    }
    .table.tablecart th.thcart {
        color: #303031;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 12px !important;
        padding: 5px 8px !important;
        text-transform: none !important;
    }
    .table.tablecart td.tdcart {
        color: #4e5764;
        line-height: 1.25;
        letter-spacing: 0.3px;
        font-size: 12px;
        padding: 5px 8px !important;
    }
    .table.tablecart tfoot th.tfcart {
        padding: 10px 8px !important;
    }
    .table.tablecart tfoot tr {
        border-top: dotted 0.5px #b8b9bb;
        border-bottom: solid 0.5px #94bef0;
    }
    .table.tablecart tbody tr {
        height: auto !important;
    }
    a.add-customer-heading{
        font-weight: 600;
        line-height: 1.21;
        letter-spacing: 0.6px;
        color: #00448b!important;
    } */
</style>
<script>
import { EventBus } from "./eventBus/nav-bar-event.js";
import { mapGetters } from "vuex";
import CreateCustomer from "./CreateCustomer";
export default {
  data() {
    return {
      checkedoption: true,
      addNotediv: false,
      adddiscountdiv: false,
      customer: {}
    };
  },
  created() {
    if (this.$route.query.customer) {
      this.customer = this.$route.query.customer;
    }
  },
  components: { CreateCustomer },
  computed: {
    ...mapGetters({
      customers: "customers"
    })
  },
  methods: {
    showQuantity() {
      this.$modal.show("quantity-order");
    },
    hideQuantity() {
      this.$modal.hide("quantity-order");
    },
    addCustomerOrder() {
      this.$modal.show("customer-create");
    },
    addCustomerhideOrder() {
      this.$modal.hide("customer-create");
    },
    redirectOnselect(customer) {
      // this.orderData['customer'] = customer;
      // this.$router.push({path: '/ordermanage', query: {'order':this.orderData}});
      this.$router.push({
        path: "/ordermanage",
        query: { customer: customer }
      });
    },
    addNote() {
      this.addNotediv = !this.addNotediv;
    },
    addDiscount() {
      this.adddiscountdiv = !this.adddiscountdiv;
    },
    hideNotediv() {
      this.addNotediv = false;
    }
  },
  mounted() {
    EventBus.$emit("customerdetailsLoad", false);
  }
};
</script>
