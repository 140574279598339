<template>
  <div>
    <modal
      :name="modal_name"
      transition="nice-modal-fade"
      class="final-modal table-selection-modal"
    >
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title">TABLE SELECTION</span>
        </div>
        <div class="v-modal-body pl-0 pr-0 pt-0">
          <div
            class="v-modal-layout d-form"
            style="max-height:400px;overflow: auto !important;;"
          >
            <table-selection
              :orderedTable="orderedTable"
              :order_id="order_id"
              :editOrder="editOrder"
              class="mb-30"
              :editTable="editTable"
              :editOrderTable="true"
              :editTableFromSummary="true"
              @updateSummary="emitUpdateSummary"
              :isDelivered="isDelivered"
            ></table-selection>
          </div>

          <div class="v-modal-footer text-center pt-5 pb-3">
            <a
              class="btn btn-link btn-smm"
              style="text-transform: uppercase !important;font-size: 12px !important;"
              @click="hideTable"
            >CANCEL</a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event.js'

export default {
  name: 'TableSelectionModal',
  props: ['modal_name', 'orderedTable', 'order_id', 'editOrder', 'isDelivered'],
  data() {
    return {
      editTable: true,
      selectTable: []
    }
  },
  mounted() {
    EventBus.$on('CloseTableSelection', value => {
      this.hideTable(value)
      EventBus.$emit('loadOrders')
    })
    EventBus.$on('close select modal', () => {
      this.$modal.hide(this.modal_name)
    })
    EventBus.$on('selectedTable', value => {
      this.selectTable = value
    })
  },
  components: {
    TableSelection: () => import('./TableSelection.vue')
  },
  methods: {
    emitUpdateSummary() {
      this.$emit('updateSummary', true)
    },
    hideTable(value) {
      if (this.selectTable.length) {
        this.$emit('updateSummary')
        this.$emit('updateCheckout', true)
        this.$modal.hide(this.modal_name)
        EventBus.$emit('show order summary')
      } else if (value == 'Take Away') {
        this.$emit('updateSummary')
        this.$emit('updateCheckout', true)
        this.$modal.hide(this.modal_name)
      } else {
        this.$toasted.global.error('Please Select Table To Proceed Further')
      }
    }
  }
}
</script>
<style>
.table-selection-modal .tableselection-header {
  background: #cddbeb !important;
}
</style>