<template>
    <div>
        <h2 class="headerblock">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div class="list-title" style="font-weight: 500;">
                        <a class="btn btn-secondary btn-smm" @click="AddNewVochers()">+ Add New Vochers Promotion </a>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <div class="breadcome-heading  d-xl-inline">
                        <div class="form-group formsearch has-warning has-feedback">
                            <input type="text" class="form-control textremove holder" placeholder="Search...">
                            <!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" v-on:click=" searchkey = '',start = 1,skip = 0, filterProducts()"></span> -->
                            <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
                        </div>
                    </div>
                    <el-tooltip class="item" effect="dark" content="Back to Previous Page" placement="top">
                        <a class="btn btn-light-grey btn-fab-dc mr-3 pull-right" @click="$router.go(-1)" style="margin-top: 2px;padding-top: 2px;">
                            <i class="icons icon-arrow-left"></i>
                        </a>
                    </el-tooltip>
                    <!-- <div class="pull-right">
                        <span class="page-info px-2 hidden-md-down">Showing 12 - 2 of 15</span>
                        <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                            <button  type="button" class="btn btn-icon d-none d-md-inline el-tooltip item"><i class="icon icon-chevron-left"></i></button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Next" placement="top">
                            <button  type="button" class="btn btn-icon d-none d-md-inline el-tooltip item"><i class="icon icon-chevron-right"></i></button>
                        </el-tooltip>
                    </div> -->
                </div>
            </div>
        </h2>
        <div class="page-layout carded left-sidebar">
            <div class="page-content-wrapper">
                <div class="page-content">
                    <div class="demo-content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>VOUCHER/COUPON <br>CODE</th> 
                                    <th>VOUCHER/COUPON DESCRIPTION</th> 
                                    <th>MSRP PRICE</th> 
                                    <th>SALE PRICE AFTER<br>
                                        DISCOUNT</th> 
                                    <th>Qty</th> 
                                    <th>EXPIRY DATE AND TIME <br>(DD/MM/YYYY)</th> 
                                    <th>QR ACTIONS</th> 
                                    <th>Actions</th> 
                                </tr>    
                            </thead>   
                            <tbody>
                                <tr>
                                    <td>ABC1234DTJUKA</td>  
                                    <td>Shop For $1,000.00 And Pay $900.00</td>  
                                    <td>$50.00</td>  
                                    <td>$50.00</td>  
                                    <td>20</td>     
                                    <td>15/01/2019, 10:00 A.M.</td>  
                                    <td>
                                         <el-tooltip class="item" effect="dark" content="Download" placement="top">
                                            <span>
                                                <i class="icon icon-download" style="color:#5ca7ec;"></i> 
                                            </span>
                                         </el-tooltip>
                                          <el-tooltip class="item" effect="dark" content="Share" placement="top">
                                            <span>
                                                <i class="icon icon-share-variant" style="padding-left:10px;color:#5ca7ec;"></i>
                                            </span>
                                          </el-tooltip>
                                    </td>
                                    <td>
                                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                            <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem .2rem;" @click="EditNewVochers()">
                                                <i class="icon icon-pencil"></i>
                                            </button>
                                        </el-tooltip>
                                        <el-tooltip class="ite m" effect="dark" content="Delete" placement="top">
                                            <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;">
                                                <i class="icon icon-delete"></i>
                                            </button>
                                        </el-tooltip>
                                    </td> 
                                </tr>    
                            </tbody> 
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <modal name="vochers-create" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">Add New Voucher/Coupon </span>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <h6 class="col-md-12">Promotion Information</h6>
                        <form>
                            <div class="col-md-12 form-group mt-0 contactGroup">      
                                <input class="inputContact"  type="text"  name="brandName" autofocus>
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">Promotion Description<sup>★</sup></label>
                                <!-- <span class="invalid-feedback-form" v-show="errors.has('brandName')">{{ errors.first('brandName') }}</span> -->
                            </div>
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Expiry Date</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Expiry Time</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">MSRP Price</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Quantity</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Discount</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Sale Price After Discount</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                            </div> 
                        </form>
                        <div class="v-modal-footer text-center pt-5">
                            <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1" @click="CancelNewVochers()">CANCEL</a>
                            <button class="btn btn-secondary btn-smm text-uppercase">SAVE</button>
                        </div>
                    </div>          
                </div>
            </div>
        </modal>
        <modal name="vochers-edit" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">Edit General Promotion</span>
                </div>
                 <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <h6 class="col-md-12">Promotion Information</h6>
                        <form>
                            <div class="col-md-12 form-group mt-0 contactGroup">      
                                <input class="inputContact"  type="text"  name="brandName" autofocus>
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">Promotion Description<sup>★</sup></label>
                                <!-- <span class="invalid-feedback-form" v-show="errors.has('brandName')">{{ errors.first('brandName') }}</span> -->
                            </div>
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Expiry Date</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Expiry Time</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">MSRP Price</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Quantity</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Discount</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                    <div class="form-group contactGroup">      
                                        <input class="inputContact" type="text"  name="brandSupplier">
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Sale Price After Discount</label>
                                        <!-- <span class="invalid-feedback-form" v-show="errors.has('brandSupplier')">Supplier Name is Required</span> -->
                                    </div>
                                </div>
                            </div> 
                        </form>
                        <div class="v-modal-footer text-center pt-5">
                            <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1" @click="EditCancelNewVochers()">CANCEL</a>
                            <button class="btn btn-secondary btn-smm text-uppercase">SAVE</button>
                        </div>
                    </div>          
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
export default {
    //  props: ['modal_name'],
    data() {
      return {
        value1: true,
        value2: true
      }
    },
    methods:{
      AddNewVochers() {
        this.$modal.show('vochers-create');
      },
      CancelNewVochers() {
        this.$modal.hide("vochers-create");
      },
       EditNewVochers() {
        this.$modal.show('vochers-edit');
      },
      EditCancelNewVochers() {
        this.$modal.hide("vochers-edit");
      },
    },
    components: {
        
    }
}
</script>
