import Vue from 'vue'
import VeeValidate from 'vee-validate'
import Router from 'vue-router'
import Loader from '@/components/Loader'
import Home from '@/components/Home'
import Homenew from '@/components/Homenew'
import InventoryManage from '@/components/InventoryManage'
import CategoryManage from '@/components/CategoryManage'
import AddonsManage from '@/components/AddonsManage'
import BrandManage from '@/components/BrandManage'
import PromotionsManage from '@/components/PromotionsManage'
import EmployeeManage from '@/components/EmployeeManage'
import ProductsTypeManage from '@/components/ProductsTypeManage'
import SubCategoryManage from '@/components/SubCategoryManage'
import CustomerManage from '@/components/CustomerManage'
import CustomerDetails from '@/components/CustomerDetails'
import BillingManage from '@/components/BillingManage'
import ReportManage from '@/components/Reports/ReportManage'
import AddOrderCustomer from '@/components/AddOrderCustomer'
import ClosedOrders from '@/components/ClosedOrders'
import Settings from '@/components/Settings'
import EmailBill from '@/components/EmailBill'
import SetUp from '@/components/SetUp'
import Access from '@/components/Access'
import TableSelection from '@/components/TableSelection'
import OutletManage from '@/components/OutletManage'
import OutletDetails from '@/components/OutletDetails'
// import OrderDetails from '@/components/OrderDetails';
import CreateOutlet from '@/components/CreateOutlet'
import CreateAddon from '@/components/CreateAddon'
import EditOutlet from '@/components/EditOutlet'
import OutletDetailsNew from '@/components/OutletDetailsNew'
import ProductsVariants from '@/components/ProductsVariants'
import ManageOrder from '@/components/ManageOrder'
import TakeOrder from '@/components/TakeOrder'
import TakeOrderNew from '@/components/TakeOrderNew'
import PaymentScreenScanAndPay from '@/components/PaymentScreenScanAndPay'
import CheckOut from '@/components/CheckOut'
import CategoryPriority from '@/components/CategoryPriority'
import Printre from '@/components/Printre'
import YieldManager from '@/components/YieldManager'
import AddComboNew from '@/components/AddComboNew'
import EditComboNew from '@/components/EditComboNew'
import ViewComboNew from '@/components/ViewComboNew'
import {
    mapGetters
} from 'vuex'
import InternetExplorer from '@/components/InternetExplorer'
import OpenMenuManage from '@/components/OpenMenuManage'
import CreateOpenMenu from '@/components/CreateOpenMenu'
import ForBidden from '@/components/ForBidden'
import ReportsDashboard from '@/components/ReportsDashboard'
import DashboardLocation from '@/components/Reports/DashboardLocation'
import PayReport from '@/components/Reports/PayReport'
import SalesSubcategory from '@/components/Reports/SalesSubcategory'
import SalesCategory from '@/components/Reports/SalesCategory'
import SalesProduct from '@/components/Reports/SalesProduct'
import OutputTax from '@/components/Reports/OutputTax'
import TaxInvoice from '@/components/Reports/TaxInvoice'
import OrderReport from '@/components/Reports/OrderReport'
import InventoryStock from '@/components/Reports/InventoryStock'
import CostProduct from '@/components/Reports/CostProduct'
import CheckStock from '@/components/Reports/CheckStock'
import AllActivity from '@/components/Reports/AllActivity'
import Promotion from '@/components/Reports/Promotion'
import EmployeeActivity from '@/components/Reports/EmployeeActivity'
import SupplyChain from '@/components/SCM/SupplyChain'
import AuditAnalysis from '@/components/SCM/AuditAnalysis'
import AuditCount from '@/components/SCM/AuditCount'
import Recipe from '@/components/SCM/Recipe'
import DashboardSupplyChain from '@/components/SCM/DashboardSupplyChain'
import AddonSectionManage from '@/components/AddonSectionManage'
import LoyaltyManage from '@/components/Loyalty/LoyaltyManage'
import CreatePromotions from '@/components/Loyalty/CreatePromotions'
import IngredientManage from '@/components/IngredientManage'
import ViewOrder from '@/components/ViewOrder'
import ViewOrderChef from '@/components/ViewOrderChef'
import ComboManage from '@/components/ComboManage'
import CreateProductNew from '@/components/CreateProductNew'
import EditProductNew from '@/components/EditProductNew'
import AddPricing from '@/components/AddPricing'
import EditPricing from '@/components/EditPricing'
import ViewPricing from '@/components/ViewPricing'
import AddIngredientNew from '@/components/CreateIngredientNew'
import EditIngredientNew from '@/components/EditIngredientNew'
import OrderTableView from '@/components/OrderTableView'
import Login from '@/components/Login/Login'
import GuestLogin from '@/components/Login/GuestLogin'
import VerifyOtp from '@/components/VerifyOtp'
import MyOrder from '@/components/MyOrder'
import PendingOrder from '@/components/PendingOrder'
import ApproveRequests from '@/components/ApproveRequests'
import PendingApproval from '@/components/PendingApproval'
import ApprovalStatus from '@/components/ApprovalStatus'
import PlaceCustomerOrder from '@/components/PlaceCustomerOrder'
import SelectPrinters from '@/components/SelectPrinters'
import SelectPrintersNew from '@/components/SelectPrintersNew'
import ManagePrinters from '@/components/ManagePrinters'
import PageNotFound from '@/components/PageNotFound'
import TakeOrderOld from '@/components/TakeOrderOld'
import PaymentScreen from '@/components/PaymentScreen'
import PaymentScreenAch from '@/components/PaymentScreenAch'
import DownloadInventory from '@/components/DownloadInventory'
Vue.use(Router)
Vue.use(VeeValidate)

// function load(component) {
//     return () => System.import(`components/${component}.vue`)
// }

export default new Router({
    mode: 'history',
    routes: [
        // path: '/',
        // name: 'Home',
        // component: Home
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'Homenew',
            component: Homenew
        },
        {
            path: '/inventorymanage',
            name: 'InventoryManage',
            component: InventoryManage
        },
        {
            path: '/addonsmanage',
            name: 'AddonsManage',
            component: AddonsManage
        },
        {
            path: '/productstypemanage',
            name: 'ProductsTypeManage',
            component: ProductsTypeManage
        },
        {
            path: '/subcategorymanage',
            name: 'SubCategoryManage',
            component: SubCategoryManage
        },
        {
            path: '/forbidden',
            name: 'ForBidden',
            component: ForBidden
        },
        {
            path: '/categorymanage',
            name: 'CategoryManage',
            component: CategoryManage
        },
        {
            path: '/brandmanage',
            name: 'BrandManage',
            component: BrandManage
        },
        {
            path: '/promotionsmanage',
            name: 'PromotionsManage',
            component: PromotionsManage
        },
        {
            path: '/employeemanage',
            name: 'EmployeeManage',
            component: EmployeeManage
        },
        {
            path: '/customermanage',
            name: 'CustomerManage',
            component: CustomerManage
        },
        {
            path: '/customerdetails',
            name: 'CustomerDetails',
            component: CustomerDetails
        },
        {
            path: '/billingmanage/:id?',
            name: 'BillingManage',
            component: BillingManage
        },
        {
            path: '/reportmanage',
            name: 'ReportManage',
            component: ReportManage
        },
        {
            path: '/manageorder',
            name: 'ManageOrder',
            component: ManageOrder
        },
        // {
        //   path: "/addordercustomer",
        //   name: "AddOrderCustomer",
        //   component: AddOrderCustomer
        // },
        {
            path: '/addonsectionmanage',
            name: 'AddonSectionManage',
            component: AddonSectionManage
        },
        {
            path: '/loyaltyManage',
            name: 'LoyaltyManage',
            component: LoyaltyManage
        },
        {
            path: '/closedorders',
            name: 'ClosedOrders',
            component: ClosedOrders
        },
        {
            path: '/settings',
            name: 'Settings',
            component: Settings
        },
        // {
        //   path: "/emailbill",
        //   name: "EmailBill",
        //   component: EmailBill
        // },
        {
            path: '/setup',
            name: 'SetUp',
            component: SetUp
        },
        {
            path: '/tableselection',
            name: 'TableSelection',
            component: TableSelection
        },
        {
            path: '/access',
            name: 'Access',
            component: Access
        },
        {
            path: '/outletmanage',
            name: 'OutletManage',
            component: OutletManage
        },
        {
            path: '/outletdetails',
            name: 'OutletDetails',
            component: OutletDetails
        },
        // {
        //   path: '/orderdetails',
        //   name: 'OrderDetails',
        //   component: OrderDetails
        // },
        {
            path: '/createoutlet',
            name: 'CreateOutlet',
            component: CreateOutlet
        },
        {
            path: '/createaddon',
            name: 'CreateAddon',
            component: CreateAddon
        },
        {
            path: '/createpromotions',
            name: 'CreatePromotions',
            component: CreatePromotions
        },
        {
            path: '/editoutlet',
            name: 'EditOutlet',
            component: EditOutlet
        },
        {
            path: '/outletdetailsnew',
            name: 'OutletDetailsNew',
            component: OutletDetailsNew
        },
        {
            path: '/productsvariants',
            name: 'ProductsVariants',
            component: ProductsVariants
        },
        {
            path: '/takeorder',
            name: 'TakeOrder',
            component: TakeOrder
        },
        {
            path: '/takeordernew',
            name: 'TakeOrderNew',
            component: TakeOrderNew
        },
        {
            path: '/place_order',
            name: 'PlaceCustomerOrder',
            component: TakeOrderNew
        },
        {
            path: '/loader',
            name: 'Loader',
            component: Loader
        },
        {
            path: '/checkout',
            name: 'CheckOut',
            component: CheckOut
        },
        {
            path: '/internetexplorer',
            name: 'InternetExplorer',
            component: InternetExplorer
        },
        {
            path: '/reportsDashboard',
            name: 'ReportsDashboard',
            component: ReportsDashboard
        },
        {
            path: '/dashboardLocation',
            name: 'DashboardLocation',
            component: DashboardLocation
        },
        {
            path: '/payReport',
            name: 'PayReport',
            component: PayReport
        },
        {
            path: '/salesSubCategory',
            name: 'SalesSubcategory',
            component: SalesSubcategory
        },
        {
            path: '/salesCategory',
            name: 'SalesCategory',
            component: SalesCategory
        },
        {
            path: '/salesProduct',
            name: 'SalesProduct',
            component: SalesProduct
        },
        {
            path: '/outputTax',
            name: 'OutputTax',
            component: OutputTax
        },
        {
            path: '/taxInvoice',
            name: 'TaxInvoice',
            component: TaxInvoice
        },
        {
            path: '/orderReport',
            name: 'OrderReport',
            component: OrderReport
        },
        {
            path: '/inventorystock',
            name: 'InventoryStock',
            component: InventoryStock
        },
        {
            path: '/costproduct',
            name: 'CostProduct',
            component: CostProduct
        },
        {
            path: '/checkstock',
            name: 'CheckStock',
            component: CheckStock
        },
        {
            path: '/allactivity',
            name: 'AllActivity',
            component: AllActivity
        },
        {
            path: '/promotion',
            name: 'Promotion',
            component: Promotion
        },
        {
            path: '/employeeactivity',
            name: 'EmployeeActivity',
            component: EmployeeActivity
        },
        {
            path: '/openmenumanage',
            name: 'OpenMenuManage',
            component: OpenMenuManage
        },
        {
            path: '/createopenmenu',
            name: 'CreateOpenMenu',
            component: CreateOpenMenu
        },
        {
            path: '/printre',
            name: 'Printre',
            component: Printre
        },
        {
            path: '/categorypriority',
            name: 'CategoryPriority',
            component: CategoryPriority
        },
        {
            path: '/supplychain',
            name: 'SupplyChain',
            component: SupplyChain
        },
        {
            path: '/auditanalysis',
            name: 'AuditAnalysis',
            component: AuditAnalysis
        },
        {
            path: '/auditcount',
            name: 'AuditCount',
            component: AuditCount
        },
        {
            path: '/dashboardsupplychain',
            name: 'DashboardSupplyChain',
            component: DashboardSupplyChain
        },
        {
            path: '/recipe',
            name: 'Recipe',
            component: Recipe
        },
        {
            path: '/ingredientManage',
            name: 'IngredientManage',
            component: IngredientManage
        },
        {
            path: '/vieworder/:id?',
            name: 'ViewOrder',
            component: ViewOrder
        },
        {
            path: '/view_customer_order/:id?',
            name: 'MyOrder',
            component: MyOrder
        },
        {
            path: '/vieworderchef/:id?',
            name: 'ViewOrderChef',
            component: ViewOrderChef
        },
        {
            path: '/combomanage',
            name: 'ComboManage',
            component: ComboManage
        },
        {
            path: '/createproduct',
            name: 'CreateProductNew',
            component: CreateProductNew
        },
        {
            path: '/yieldmanager',
            name: 'YieldManager',
            component: YieldManager
        },
        {
            path: '/editproduct',
            name: 'EditProductNew',
            component: EditProductNew
        },
        {
            path: '/addcombo',
            name: 'AddCombo',
            component: AddComboNew
        },
        {
            path:'/editcombo',
            name:'EditCombo',
            component:EditComboNew
        },
        {
            path:'/viewcombo',
            name:'ViewCombo',
            component:ViewComboNew
        },
        {
            path:'/addpricing',
            name:'AddPricing',
            component:AddPricing
        },
        {
            path:'/editpricing',
            name:'EditPricing',
            component:EditPricing
        },
        {
            path:'/viewpricing',
            name:'ViewPricing',
            component:ViewPricing
        },
        {
            path: '/addingredient',
            name: 'AddIngredientNew',
            component: AddIngredientNew
        },
        {
            path: '/editingredient',
            name: 'EditIngredientNew',
            component: EditIngredientNew
        },
        {

            path:'/orderTableView',
            name:'OrderTableView',
            component:OrderTableView
        },
        {
            path:'/pendingorder',
            name:'PendingOrder',
            component:PendingOrder
        },
        {
            path: '/approverequests',
            name: 'ApproveRequests',
            component:ApproveRequests
        },
        {
            path: '/pendingapproval',
            name: 'PendingApproval',
            component:PendingApproval
        },
        {
            path: '/approvalstatus',
            name: 'ApprovalStatus',
            component: ApprovalStatus
        },
        {
            path:'/manageprinters',
            name:'SelectPrinters',
            component:SelectPrinters
        },
        {
            path:'/printer',
            name:'SelectPrintersNew',
            component:SelectPrintersNew
        },
        {
            path:'/manageprinter',
            name:'ManagePrinters',
            component:ManagePrinters
        },
        {
            path:'/order_selected',
            name:'TakeOrderOld',
            component:TakeOrderOld
        },
        {
            path: '/place_order/payment_screen',
            name: 'PaymentScreen',
            component: PaymentScreen
        },
        {
            path: '/place_order/payment_screen_ach',
            name: 'PaymentScreenAch',
            component: PaymentScreenAch
        },
        {
            path: '/download_inventory',
            name: 'DownloadInventory',
            component: DownloadInventory
        },
        {
            path: '/payments/scan_and_pay',
            name: 'ScanAndPay',
            component: PaymentScreenScanAndPay
        },
        // ================================================= Login ==============================================================
        {path:'/login', name:'Login', component: Login},
        {path:'/guestlogin', name:'GuestLogin', component: GuestLogin},

        // ================================================= OTP ==============================================================
        {path:'/verifyOtp', name:'VerifyOtp', component: VerifyOtp},



        // ***** Use other routes above this line only if you have a specific need *****


        // ================================================= Page Not Found ==============================================================
        {path:'/pagenotfound', name:'PageNotFound', component: PageNotFound},

        {path:'*', name:'PageNotFoundError', redirect: '/pagenotfound'}
    ],
    computed: {
        ...mapGetters({
            loggedInUser: 'loggedInUser'
        })
    },
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    x: 0,
                    y: 0
                })
            }, 500)
        })
    }
})
