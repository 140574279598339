<template>
    <div class="main-bg font-family-login">
        <div class="cr-backimg" :style="bgCover">
            <div class="d-flex flex-column" style="width: 100%;min-height:95%; height:100%;">
                <div class="top">
                    <span class="dc dc-new company-name" style="color: #00448b !important;">DigiCollect</span>
                    <span class="text-default-blue crm-right-logo" style="font-size: 20px;color: #00448b !important;font-weight: 600;font-family: 'Poppins', sans-serif !important;"><img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/pos-logo.svg" alt="DigiPOS" class="digiadmin-logo" style="width: 35px; height: 35px;"> DigiPOS</span>
                </div>
                <div v-if="activediv == 'login'" class="middle d-flex h-100 w-100">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column justify-content-center pl-10 ml-auto mr-auto" style="height:100%;max-width: 450px; margin-top: -5%;">
                        <span class="text-default-blue sign-in mb-5">Sign in</span>
                        <div v-if="enter_email_screen">
                            <p class="text-grey-default mb-5" v-if="emails_pin_login.length > 0"><span class="font-weight-bold" style="cursor: pointer; color: #005dae;" @click="showLoginUsingPin">Login Using Pin</span></p>
                        </div>
                        <form action="javascript:;">
                            <div v-if="enter_email_screen">
                                <p class="text-default-heading mb-5">Email Address/Phone Number<span class="star ml-1">*</span></p>
                                <input type="text" autocomplete="off" ref="text" id="text" @keyup.enter="goToNext('login')" class="digi-form-full-bordered mb-2" autofocus name="userid" placeholder="Enter Email Address/Phone Number" @input="checkUserId" v-model="form.login_id">
                                <span class="text-danger fw-600 fs-14 mb-5" style="text-transform: none !important;" v-show="errors.has('userid')">Please enter a valid Email Address/Phone Number</span>
                                <span v-if="is_email_phone_valid" class="text-danger fw-600 fs-14 mb-5" style="text-transform: none !important;">{{is_email_phone_valid_text}}</span>
                                <div v-if="!login_btn_disable" class="mt-8 text-right" style="max-width: 450px;">
                                    <button class="btn btn-new-primary" type="button" @click="goToNext('login')" style="width: 160px !important;">Next</button>
                                </div>
                                <div v-else class="mt-8" style="position: relative;max-width: 450px;float: right;">
                                    <button class="btn btn-new-primary" disabled style="width: 160px !important;height: 4rem !important;">Checking</button>
                                    <hollow-dots-spinner style="position: absolute;margin: 0;top: 28px;width: 160px !important;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                    <!-- <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" /> -->
                                </div>
                            </div>
                            <div v-if="user_not_verified">
                                <h1 v-if="login_type == 'email'" class="fs-14 mb-6 font-weight-bold">Email Address: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <h1 v-if="login_type == 'phone'" class="fs-14 mb-6 font-weight-bold">Phone Number: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <div class="text-center" style="max-width: 380px">
                                    <div class="mb-10 ml-auto mr-auto" style="max-width: 264px">
                                        <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/posv2/duck.svg" alt="Duck" />
                                    </div>
                                    <p class="text-default-heading mb-5" style="font-size: 30px; font-weight: 700;">Verification Pending!!</p>
                                    <p class="text-default-heading mb-10" style="font-size: 18px;">Your Verification link has been expired, Resend Link to verify</p>
                                    <div class="">
                                        <div class="d-flex align-items-center">
                                            <button class="btn btn-danger mb-5" :disabled="resend_btn_disable" style="border-radius: 5px !important;width: 160px !important;" @click="cancelResendLink">Cancel</button>
                                            <button class="btn btn-new-primary mb-5 ml-3" :disabled="resend_btn_disable" @click="resendUserVerifyLink" style="width: 160px !important;">Resend Link
                                                <hollow-dots-spinner v-if="resend_btn_disable" style="position: absolute;margin: 0;top: 25px;width: 160px !important;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                            </button>
                                        </div>
                                        <div v-if="resend_verification_link_success" class="text-default-blue fs-14 mt-2">{{resend_verification_link_success}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="enter_pass_screen">
                                <h1 v-if="login_type == 'email'" class="fs-14 mb-6 font-weight-bold">Email Address: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <h1 v-if="login_type == 'phone'" class="fs-14 mb-6 font-weight-bold">Phone Number: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <div v-if="!sign_in_using_otp">
                                    <p class="text-default-heading mb-5">Enter Password<span class="star ml-1">*</span></p>
                                    <div class="d-flex">
                                        <input v-if="!showPassword" @keyup.enter="loginUsingPassword('one-step')" id="name" ref="name" style="padding-right: 40px !important;" oncopy="return false" onpaste="return false" oncut="return false" type="password"  autocomplete="off" class="digi-form-full-bordered mb-5 pr-8" placeholder="Enter Password" v-model="form.password" name="password">
                                        <input v-else type="text" @keyup.enter="loginUsingPassword('one-step')" id="name" ref="name" style="padding-right: 40px !important;" oncopy="return false" onpaste="return false" oncut="return false"  autocomplete="off" class="digi-form-full-bordered mb-5 pr-8" placeholder="Enter Password" v-model="form.password" name="password">
                                        <span v-if="!showPassword" style="margin-left: -30px; margin-top: 9px;" @click="showPassword = !showPassword">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" alt="eye" style="width: 20px;">
                                        </span>
                                        <span v-else style="margin-left: -30px; margin-top: 9px;" @click="showPassword = !showPassword">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" alt="eye" style="width: 20px;">
                                        </span>
                                    </div>
                                    <p v-if="password_error_text" class="text-danger mb-5">{{password_error_text}}</p>
                                    <p class="default-link mb-4" @click="forgotPassword" v-if="signedIn">Forgot Password ?</p>
                                    <div class="d-flex align-items-center mb-5">
                                        <button type="button" class="btn btn-new-outline-primary " @click="cancelPassScreen" style="width: 160px !important; text-transform: none !important;line-height: 20px !important; border-radius: 5px !important;" :disabled="signedIn">Cancel</button>
                                        <div class="ml-3">
                                            <div v-if="!signedIn">
                                                <button class="btn btn-new-primary" type="button" @click="loginUsingPassword('one-step')" style="width: 160px !important;">Sign In</button>
                                            </div>
                                            <div v-else class="" style="position: relative;">
                                                <button class="btn btn-new-primary" disabled  style="width: 160px !important;height: 4rem !important;">Signing In</button>
                                                <hollow-dots-spinner style="position: absolute;width: 160px !important;margin: 0;top: 28px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                                <!-- <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="signedIn">
                                        <p class="fs-14 mt-0"> <span class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using OTP</p>
                                    </div>
                                    <div v-else>
                                        <p class="fs-14 mt-0"> <span @click="goToOtpLogin('send')" class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using OTP</p>
                                    </div>
                                    <!-- <div>
                                        <p class="fs-14 mt-0"> <span @click="goToOtpLogin('send')" class="text-default-blue pointer font-weight-bold">Click here</span> to Sign in using OTP</p>
                                    </div> -->
                                </div>
                                <div v-if="sign_in_using_otp">
                                    <span class="text-default-heading mr-10">Enter OTP Code</span>
                                    <div>
                                    <div class="d-flex align-items-start">
                                        <div class="">
                                            <v-otp-input
                                            ref="otpInput"
                                            :input-classes="!otpInvalid ? 'otp-input' : 'otp-input-warning'"
                                            separator=""
                                            :num-inputs="6"
                                            input-type="number"
                                            :should-auto-focus="true"
                                            :is-input-num="true"
                                            @on-complete="otpEntered"
                                            @on-change="otpChangeDetected"
                                            />
                                            <div class="mt-1">
                                                <span style="color: #f5a623;font-size: 14px;">OTP Valid for {{Math.floor(minutes/60)}}:{{countdownseconds}} minutes</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="color: #3f3f3f">
                                                <button class="btn btn-new-primary pl-3 pr-3" type="button" :disabled="!countdown == 0" @click="goToOtpLogin('resend')" style="width: 160px !important;">Resend code</button>
                                                <br>
                                                <span v-if="countdown > 0" class="mt-1"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                            </div>
                                            <div v-if="code_resending" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                        </div>
                                    </div>
                                    <div v-if="resend_code_success_text" class="mt-2">
                                        <span class="text-default-blue fs-14">{{resend_code_success_text}}</span>
                                    </div>
                                    <div v-if="otp_error_text" class="mt-2">
                                        <span class="text-danger fs-14">{{otp_error_text}}</span>
                                    </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-5 mt-6">
                                        <button type="button" class="btn btn-new-outline-primary" @click="cancelOtpScreen" style="width: 160px; text-transform: none !important;line-height: 20px !important; border-radius: 5px !important;width: 160px !important;" :disabled="signedIn">Cancel</button>
                                        <div class="ml-3">
                                            <div v-if="!signedIn">
                                                <button class="btn btn-new-primary sign-in-otp" :disabled="!otp_signin_btn" type="button" @click="loginUsingOtp('one-step')" style="min-width: 110px;width: 160px !important;">Sign In</button>
                                            </div>
                                            <div v-else class="" style="position: relative;">
                                                <button class="btn btn-new-primary" disabled style="width: 160px !important;height: 4rem !important;">Signing In</button>
                                                <hollow-dots-spinner style="position: absolute;margin: 0;top: 28px;display: flex;justify-content: center;width: 160px !important;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                                <!-- <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" /> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="signedIn">
                                        <p class="fs-14 mt-0"> <span class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using Password</p>
                                    </div>
                                    <div v-else>
                                        <p class="fs-14 mt-0"> <span @click="goToPassLogin" class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using Password</p>
                                    </div>
                                    <!-- <div>
                                        <p class="fs-14 mt-0"> <span @click="goToPassLogin" class="text-default-blue pointer font-weight-bold">Click here</span> to Sign in using Password</p>
                                    </div> -->
                                </div>
                            </div>
                            <div v-if="two_factor_authentication">
                                <h1 v-if="login_type == 'email'" class="fs-14 mb-6 font-weight-bold">Email Address: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <h1 v-if="login_type == 'phone'" class="fs-14 mb-6 font-weight-bold">Phone Number: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <div v-if="two_factor_auth_pass_screen_active">
                                    <p class="text-default-heading mb-5">Enter Password<span class="star ml-1">*</span></p>
                                    <div class="d-flex">
                                        <input v-if="!showPassword" id="name" ref="name" @keyup.enter="otpInputActive" style="padding-right: 40px !important;" oncopy="return false" onpaste="return false" oncut="return false" type="password"  autocomplete="off" class="digi-form-full-bordered mb-5 pr-8" placeholder="Enter Password" v-model="form.password" name="password">
                                        <input v-else type="text" id="name" ref="name" @keyup.enter="otpInputActive" style="padding-right: 40px !important;" oncopy="return false" onpaste="return false" oncut="return false"  autocomplete="off" class="digi-form-full-bordered mb-5 pr-8" placeholder="Enter Password" v-model="form.password" name="password">
                                        <span v-if="!showPassword" style="margin-left: -30px; margin-top: 9px;" @click="showPassword = !showPassword">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" alt="eye" style="width: 20px;">
                                        </span>
                                        <span v-else style="margin-left: -30px; margin-top: 9px;" @click="showPassword = !showPassword">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" alt="eye" style="width: 20px;">
                                        </span>
                                    </div>
                                    <p v-if="password_error_text" class="text-danger mb-5">{{password_error_text}}</p>
                                    <p v-if="signedIn" class="default-link mb-5">Forgot Password ?</p>
                                    <p v-if="!signedIn" class="default-link mb-5" @click="forgotPassword">Forgot Password ?</p>
                                    <!-- <div class="d-flex align-items-center mb-5">
                                        <button type="button" class="btn btn-new-outline-primary " @click="cancelTwoFactor" style="width: 160px !important; text-transform: none !important;line-height: 20px !important; border-radius: 5px !important;" :disabled="signedIn">Cancel</button>
                                        <button class="btn btn-new-primary ml-5" style="width: 160px !important;" type="button" :disabled="signedIn" @click="otpInputActive">Next</button>
                                    </div> -->
                                    <div class="d-flex align-items-center mb-5" v-if="!signedIn">
                                        <button type="button" class="btn btn-new-outline-primary " @click="cancelTwoFactor" style="min-width: 110px;width: 160px !important; text-transform: none !important;line-height: 20px !important; border-radius: 5px !important;">Cancel</button>
                                        <button class="btn btn-new-primary ml-5" style="min-width: 110px;width: 160px !important;" type="button" :disabled="signedIn || form.password == ''" @click="otpInputActive">Next</button>
                                    </div>
                                    <div class="d-flex align-items-center mb-5" v-else>
                                        <button type="button" class="btn btn-new-outline-primary " style="min-width: 110px;width: 160px !important; text-transform: none !important;line-height: 20px !important; border-radius: 5px !important;" disabled>Cancel</button>
                                        <div class="" style="position: relative;">
                                            <button class="btn btn-new-primary ml-5" style="min-width: 110px;width: 160px !important;height: 4rem !important;" type="button" disabled>Next</button>
                                            <hollow-dots-spinner style="position: absolute;width: 160px !important;margin: 0;top: 28px;display: flex;justify-content: center;margin-left: 21px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                        </div>
                                    </div>
                                    <p class="fs-14 mt-0" v-if="!signedIn"> <span @click="goToOtpLogin('send', 'two-factor-otp')" class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using OTP</p>
                                    <p class="fs-14 mt-0" v-if="signedIn"> <span class="text-default-blue font-weight-bold"  style="cursor: no-drop !important;">Click Here</span> to Sign in using OTP</p>
                                </div>
                                <div v-if="!two_factor_auth_pass_screen_active">
                                    <span class="text-default-heading mr-10">Enter OTP Code</span>
                                    <div class="d-flex align-items-start">
                                        <div class="">
                                            <v-otp-input
                                            ref="otpInput"
                                            input-type="number"
                                            :input-classes="!otpInvalid ? 'otp-input' : 'otp-input-warning'"
                                            separator=""
                                            :num-inputs="6"
                                            :should-auto-focus="true"
                                            :is-input-num="true"
                                            @on-complete="otpEntered"
                                            @on-change="otpChangeDetected"
                                            />
                                            <div class="mt-1">
                                                <span style="color: #f5a623;font-size: 14px;">OTP Valid for {{Math.floor(minutes/60)}}:{{countdownseconds}} minutes</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="color: #3f3f3f">
                                                <button class="btn btn-new-primary pl-3 pr-3" type="button" :disabled="!countdown == 0" @click="goToOtpLogin('resend')" style="width: 160px !important;">Resend code</button>
                                                <br>
                                                <span v-if="countdown > 0" class="mt-1"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                            </div>
                                            <div v-if="code_resending" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                        </div>
                                    </div>
                                    <div v-if="resend_code_success_text" class="mt-2">
                                        <span class="text-default-blue fs-14">{{resend_code_success_text}}</span>
                                    </div>
                                    <div v-if="otp_error_text" class="mt-2">
                                        <span class="text-danger fs-14">{{otp_error_text}}</span>
                                    </div>
                                    <div class="mt-10" v-if="!two_step_signed_in_otp">
                                        <button class="btn btn-new-primary mb-5 sign-in-otp" :disabled="!otp_signin_btn" type="button" @click="loginUsingOtp('two-step')" style="min-width: 110px;width: 160px !important;">Sign In</button>
                                    </div>
                                    <div v-else class="mb-5 mt-10" style="position: relative;">
                                        <button class="btn btn-new-primary" disabled  style="width: 160px !important;height: 4rem !important;">Signing In</button>
                                        <hollow-dots-spinner style="position: absolute;margin: 0;top: 28px;display: flex;justify-content: center;width: 160px !important;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                        <!-- <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" /> -->
                                    </div>
                                    <div>
                                        <p class="fs-14 mt-0" v-if="!otp_signin_btn && password_button"> <span @click="goToPassLogin('two-factor-password')" class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using Password</p>
                                        <p class="fs-14 mt-0" v-if="otp_signin_btn && password_button"> <span class="text-default-blue font-weight-bold" style="cursor: no-drop !important;">Click Here</span> to Sign in using Password</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="is_otp_login">
                                <h1 v-if="login_type == 'email'" class="fs-14 mb-2 font-weight-bold">Email Address: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <h1 v-if="login_type == 'phone'" class="fs-14 mb-2 font-weight-bold">Phone Number: <span class="text-default-blue">{{form.login_id}}</span></h1>
                                <div class="py-5">
                                    <div class="error-box"  v-if="otpInvalid">
                                        <div class="d-flex justify-content-between">
                                            <div @click="errorBoxCancel">
                                                <img class="pointer" src="/static/images/ionic-ios-add-circle.svg" alt="" width="25px">
                                            </div>
                                            <div class="pl-2">
                                                <span>Sign in attempts failed. {{otp_error_text}}. <span v-if="otp_error_text == 'Invalid OTP. 1 Attempts Left.' || otp_error_text == 'Invalid OTP. 2 Attempts Left.' ">Kindly Enter correct code or your account will get locked for next six hours</span> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span class="text-default-heading mr-10">Enter OTP Code</span>
                                    <div class="d-flex align-items-start">
                                        <div class="" @keyup.enter="loginUsingOtp('two-step')">
                                            <v-otp-input
                                            ref="otpInput"
                                            :input-classes="!otpInvalid ? 'otp-input' : 'otp-input-warning'"
                                            separator=""
                                            :num-inputs="6"
                                            :should-auto-focus="true"
                                            :is-input-num="true"
                                            @on-complete="otpEntered"
                                            @on-change="otpChangeDetected"
                                            />
                                            <div class="">
                                                <span style="color: #f5a623;font-size: 14px;">OTP Valid for {{Math.floor(minutes/60)}}:{{countdownseconds}} minutes</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="color: #3f3f3f">
                                                <button class="btn btn-new-primary pl-3 pr-3" type="button" :disabled="!countdown == 0" @click="goToOtpLogin('resend')" style="min-width: 110px;width: 160px !important;">Resend code</button>
                                                <br>
                                                <span v-if="countdown > 0"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                            </div>
                                            <div v-if="code_resending" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                        </div>
                                    </div>
                                    <div v-if="resend_code_success_text" class="mt-2">
                                        <span class="text-default-blue fs-14">{{resend_code_success_text}}</span>
                                    </div>
                                    <div v-if="otp_error_text" class="mt-2">
                                        <span class="text-danger fs-14">{{otp_error_text}}</span>
                                    </div>
                                    <!-- <p class="default-link mt-10" @click="forgotPassword">Forgot Password ?</p> -->
                                    <div class="mt-10" v-if="!two_step_signed_in_otp">
                                        <button class="btn btn-new-primary mb-5 sign-in-otp" :disabled="!otp_signin_btn" type="button" @click="loginUsingOtp('two-step')" style="min-width: 110px;width: 160px !important;">Sign In</button>
                                    </div>
                                    <div v-else class="mb-5 mt-10" style="position: relative;">
                                        <button class="btn btn-new-primary" disabled style="min-width: 110px;width: 160px !important;height: 4rem !important;">Signing In</button>
                                        <hollow-dots-spinner style="position: absolute;margin: 0;top: 28px;display: flex;justify-content: center;width: 160px !important;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                    </div>
                                    <div>
                                        <p class="fs-14 mt-0" v-if="!otp_signin_btn && password_button"> <span @click="goToPassLogin('two-factor-password')" class="text-default-blue pointer font-weight-bold">Click Here</span> to Sign in using Password</p>
                                        <p class="fs-14 mt-0" v-if="otp_signin_btn && password_button"> <span class="text-default-blue font-weight-bold" style="cursor: no-drop !important;">Click Here</span> to Sign in using Password</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-if="activediv == 'login_using_pin'" class="middle d-flex py-10 px-20 h-100 w-100">
                    <div class="middle-left"></div>
                    <div class="middle-right pb-10 d-flex flex-column justify-content-center pl-10 ml-auto mr-auto">
                        <div class="d-flex align-items-center justify-content-between flex-wrap" >
                            <span class="text-default-blue" style="font-size: 20px;color: #005dae !important;">LogIn Using Pin</span>
                            <button class="btn btn-new-outline-primary mr-5" style="border-radius: 5px !important; line-height: 1.5;" @click="addAnotherAccount" >Add another Account</button>
                        </div>
                        <form action="javascript:;">
                            <div>
                                <div v-for="(email, email_index) in emails_pin_login" :key="email_index">
                                    <label class="form-check-label position-relative mt-3">
                                        <input class="form-check-input" v-model="selected_email_for_pin_login" name="emails_pin_login" style="top: 5px;left: -5px;width: 20px;height: 20px;" type="radio"
                                            :value="emails_pin_login[email_index]">
                                        <span class="radio-icon" style="width: 20px;height: 20px;"></span>
                                        <span class="form-check-label-text">{{email}}</span>
                                    </label>
                                </div>
                                <div v-if="selected_email_for_pin_login" class="selected-email text-dark fs-14 ml-5 mt-5" style="max-width: 300px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                    <img class="mr-2" src="/static/images/user_icon.svg" width="18" alt="user" />
                                    <span>{{selected_email_for_pin_login}}</span>
                                </div>
                            </div>
                            <div class="mt-8 login-pin-otp">
                                <h1 class="fs-14 mb-3">Enter Pin</h1>
                                <v-otp-input
                                    ref="loginPinInput"
                                    input-classes="otp-input"
                                    separator=""
                                    :num-inputs="4"
                                    input-type="number"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    @on-complete="loginPinEntered"
                                    @on-change="loginPinChangeDetected"
                                    />
                                <div class="">
                                    <span v-if="login_pin_error_text" class="text-danger fs-14">{{login_pin_error_text}}</span>
                                </div>
                            </div>
                            <div class="mt-8 text-right ml-6" style="max-width: 300px;">
                                <button v-if="login_pin_api_call" class="btn btn-new-primary" :disabled="true" type="button" style="width: 160px !important;">Logging In</button>
                                <button v-else class="btn btn-new-primary" :disabled="login_pin_disabled" @click="loginPinApiCall" type="button"  style="width: 160px !important;">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-if="activediv == 'forgot password'" class="middle d-flex pb-10 w-100" style="height:100%;">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column justify-content-center pl-10 ml-auto mr-auto" style="max-width: 450px; margin-top: -5%; height: 100%;">
                        <span class="text-default-blue forgot-password mt-5 mb-15">Forgot Password</span>
                        <p class="text-default-heading mb-5">Email Address/Phone Number<span class="star ml-1">*</span></p>
                        <input autocomplete="off" id="forgot" ref="forgot" style="max-width: 450px;" type="text" class="digi-form-full-bordered mb-2" placeholder="Enter Email Address/Phone number" v-model="form.login_id">
                        <div v-if="is_email_phone_valid" class="text-danger fw-600 fs-14" style="text-transform: none !important;">{{is_email_phone_valid_text}}</div>
                        <div v-if="is_email_phone_valid_text_forget_pass" class="text-danger fw-600 fs-14" style="text-transform: none !important;">{{is_email_phone_valid_text_forget_pass}}</div>
                        <div class="mt-10 forgot-password-button">
                            <button class="btn btn-new-outline-primary mb-5 mr-5" @click="forgotPasswordCancel" style="width: 160px !important;">Cancel</button>
                            <button :disabled="forget_pass_btn_disable" class="btn btn-new-primary mb-5" @click="goToNext('forgot-pass')"  style="width: 160px !important;">Submit</button>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="activediv == 'otp sent message'" class="middle d-flex pt-5 pb-10 px-20" style="height:100%;">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column pb-10 pl-20">
                        <img class="mb-12" style="height: 12em;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/https://cdn.digicollect.com/cdn/posv2/images/parrot.svg" alt="parrot">
                        <p v-if="is_using_mobile" class="text-default-heading text-center mb-10" style="font-size: 18px;">A reset password link <br>has been sent to your Phone Number <span class="text-default-blue">XXXXXX{{form.login_id.slice(-4)}}</span></p>
                        <p v-if="is_using_email" class="text-default-heading text-center mb-10" style="font-size: 18px;">A reset password link <br>has been sent to your Email Address <span class="text-default-blue">XXXXXX{{form.login_id.split("@")[0].slice(-2)}}@{{form.login_id.split("@")[1]}}</span></p>
                        <span class="text-center">
                            <button class="btn btn-new-primary mb-5" @click="activediv = 'verify otp'">OK</button>
                        </span>
                    </div>
                </div> -->
                <div v-if="activediv == 'verify otp'" class="middle d-flex pb-10 w-100" style="height:100%;">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column justify-content-center pl-10 ml-auto mr-auto" style="max-width: 450px; margin-top: -5%; height: 100%;">
                        <span class="text-default-blue forgot-password mt-5 mb-5">Forgot Password</span>
                        <h1 v-if="is_using_email" class="fs-14 mb-6 font-weight-bold">Email Address: <span class="text-default-blue">{{form.login_id}}</span></h1>
                        <h1 v-if="is_using_mobile" class="fs-14 mb-6 font-weight-bold">Phone Number: <span class="text-default-blue">{{form.login_id}}</span></h1>
                        <div style="width: 36em;">
                            <span class="text-default-heading mr-10">Enter OTP Code</span>
                            <div class="d-flex align-items-start">
                                <div class="">
                                    <v-otp-input
                                    ref="otpInput"
                                    :input-classes="!otpInvalid ? 'otp-input' : 'otp-input-warning'"
                                    separator=""
                                    :num-inputs="6"
                                    input-type="number"
                                    :should-auto-focus="true"
                                    :is-input-num="true"
                                    @on-complete="otpEntered"
                                    @on-change="otpEntered"
                                    />
                                    <div class="mt-1">
                                        <span style="color: #f5a623;font-size: 14px;">OTP Valid for {{Math.floor(minutes/60)}}:{{countdownseconds}} minutes</span>
                                    </div>
                                </div>
                                <div>
                                    <div style="color: #3f3f3f">
                                        <button class="btn btn-new-primary pl-3 pr-3" type="button" :disabled="!countdown == 0" @click="getOtpForgetPass('resend')" style="width: 160px !important;">Resend code</button>
                                        <br>
                                        <span v-if="countdown > 0" class="mt-1"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                    </div>
                                    <div v-if="code_resending" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                </div>
                            </div>
                            <div v-if="resend_code_success_text" class="mt-2">
                                <span class="text-default-blue fs-14">{{resend_code_success_text}}</span>
                            </div>
                            <div v-if="otpInvalid" class="mt-2">
                                <span class="text-danger fs-14">{{otp_error_text}}</span>
                            </div>
                            <div class="d-flex forgot-password-button align-items-center mt-10 mb-5">
                                <div>
                                    <button class="btn btn-new-outline-primary mr-5" @click="forgotPasswordCancel" style="width: 160px !important;">Cancel</button>
                                </div>
                                <div class="" v-if="!forgot_pass_otp_success">
                                    <button class="btn btn-new-primary" type="button" @click="verifyOtpForgotPass" style="width: 160px !important;">Next</button>
                                </div>
                                <div v-else class="" style="position: relative;">
                                    <button class="btn btn-new-primary" disabled  style="width: 160px !important;height: 4rem !important;">Verifying</button>
                                    <hollow-dots-spinner style="position: absolute;margin: 0;top: 28px;display: flex;justify-content: center;width: 160px !important;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                    <!-- <hollow-dots-spinner style="position: absolute;left: 35px;margin: 0;top: 25px;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#fff'" /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="activediv == 'set new password'" class="middle d-flex pt-5 pb-10 px-20 w-100" style="height:100%;">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column pb-10 pl-10">
                        <span class="text-default-blue mt-5 mb-10" style="font-size: 35px;">Reset Password</span>
                        <h1 v-if="is_using_email" class="fs-14 mb-6 font-weight-bold">Email Address: <span class="text-default-blue">{{form.login_id}}</span></h1>
                        <h1 v-if="is_using_mobile" class="fs-14 mb-6 font-weight-bold">Phone Number: <span class="text-default-blue">{{form.login_id}}</span></h1>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <span class="text-default-heading mb-5">New Password<span class="star ml-1">*</span></span>
                                <div class="d-flex aligm-items-center mt-5 position-relative" style="max-width: 450px;">
                                    <input autocomplete="off" style="padding-right: 40px !important;" v-if="!showNewPassword" oncopy="return false" onpaste="return false" oncut="return false" type="password" @input="onPassInput" class="digi-form-full-bordered" placeholder="Enter Password" v-model="form.password" name="new_password" v-validate="{min:8,regex:/^(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=()!*]).*$/}">
                                    <input autocomplete="off" style="padding-right: 40px !important;" v-else type="text" oncopy="return false" onpaste="return false" oncut="return false" class="digi-form-full-bordered" @input="onPassInput" placeholder="Enter Password" v-model="form.password" name="new_password" v-validate="{min:8,regex:/^(?=.{8,})(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=()!*]).*$/}">
                                    <span @click="showNewPassword = !showNewPassword" style="width: 18px;position: absolute;right: 47px;bottom: 9px">
                                        <img v-if="!showNewPassword" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" alt="eye" style="width: 18px;">
                                        <img v-else src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" alt="eye" style="width: 18px;">
                                    </span>
                                    <password-meter :pwd="form.password" id="password" style="display:none"></password-meter>
                                    <!-- <span class="invalid-feedback-form text-danger mb-5 mt-0" v-show="errors.has('new_password')">{{ errors.first("new_password") }}</span> -->
                                    <span class="ml-2 mt-2">
                                        <el-tooltip effect="light" placement="left">
                                            <div slot="content" style="color: #3f3f3f; font-size: 12px;">
                                                <span style="font-weight: 600;">Password must contain the following</span>
                                                <br>
                                                <span style="width:200px">
                                                    <span>
                                                        <i v-if="is_uppercase_pwd" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        At-least one uppercase letter (ex: A to Z)
                                                    </span><br>
                                                    <span>
                                                        <i v-if="is_number_pwd" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        At-least one digit (ex: 0 to 9)
                                                    </span><br>
                                                    <span>
                                                        <i v-if="is_special_character_pwd" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        At-least one special character (ex: $,#,@,!,%,^,&,*,(,),)<br>
                                                    </span>
                                                    <span>
                                                        <i v-if="min_pwd_length" class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                        <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                        Minimum 8 characters
                                                    </span><br>
                                                </span>
                                            </div>
                                            <i class="icon-information text-default-blue fs-18"></i>
                                        </el-tooltip>
                                    </span>
                                </div>
                                <span class="invalid-feedback-form text-danger mb-5" style="margin-top: 4px !important;" v-show="errors.has('new_password')">Please Enter New Password</span>
                            </div>
                        </div>
                        <div class="row mt-7">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <span class="text-default-heading mb-5 mt-5">Confirm Password<span class="star ml-1">*</span></span>
                                <div class="d-flex aligm-items-center mt-5 position-relative" style="max-width: 450px;">
                                    <input v-if="!showConfirmNewPassword" style="padding-right: 40px !important;margin-right:32px;" oncopy="return false" onpaste="return false" oncut="return false" type="password" name="confirmpassword" class="digi-form-full-bordered" placeholder="Enter Password" v-model="confirmPassword" v-validate="'required'">
                                    <input v-else type="text" style="padding-right: 40px !important;margin-right:32px;" oncopy="return false" onpaste="return false" oncut="return false" class="digi-form-full-bordered" name="confirmpassword" placeholder="Enter Password" v-model="confirmPassword" v-validate="'required'">
                                    <span @click="showConfirmNewPassword = !showConfirmNewPassword" style="width: 18px;position: absolute;right: 45px;top: 9px">
                                        <img v-if="!showConfirmNewPassword" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/hide-password.svg" alt="eye" style="width: 18px;">
                                        <img v-else src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/show-password.svg" alt="eye" style="width: 18px;">
                                    </span>
                                </div>
                                <!-- <p v-if="form.password != confirmPassword && confirmPassword" class="text-danger">Passwords does not match</p> -->
                                <p v-if="errorMessage" class="text-danger">{{errorMessage}}</p>
                                <!-- <span class="invalid-feedback-form text-danger mb-5" style="margin-top: 4px !important;" v-show="errors.has('confirmpassword')">Please Enter Password</span> -->
                            </div>
                        </div>
                        <span class="mt-7">
                            <button class="btn btn-new-outline-primary mb-5 mr-5" @click="forgotPasswordCancel" :disabled="reset_password_api_inprogress" style="width: 160px !important;">Cancel</button>
                            <button class="btn btn-new-primary mb-5" @click="resetPassword" :disabled="reset_password_api_inprogress" style="width: 160px !important;">
                                Submit
                                <hollow-dots-spinner v-if="reset_password_api_inprogress" style="position: absolute;margin: 0;top: 28px;display: flex;justify-content: center;width: 160px !important;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </span>
                    </div>
                </div>
                <div v-if="activediv == 'reset successfull'" class="middle d-flex pt-5 pb-10 px-20 w-100" style="height:100%;">
                    <div class="middle-left"></div>
                    <div class="middle-right d-flex flex-column pb-10 pl-10">
                        <img class="mb-12" style="height: 12em;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/parrot.svg" alt="parrot">
                        <p class="text-default-heading text-center mb-5" style="font-size: 30px; font-weight: 700;">Success</p>
                        <p v-if="is_using_mobile" class="text-default-heading text-center mb-10" style="font-size: 18px;">Changed your password Successfully and a confirmation <br>has been sent to your phone number <span class="text-default-blue">{{form.login_id.slice(0,2)}}XXXXXX{{form.login_id.slice(-4)}}</span></p>
                        <p v-if="is_using_email" class="text-default-heading text-center mb-10" style="font-size: 18px;">Changed your password Successfully and a confirmation <br>has been sent to your Email Address <span class="text-default-blue">{{form.login_id.slice(0,2)}}XXXXXX{{form.login_id.split("@")[0].slice(-2)}}@{{form.login_id.split("@")[1]}}</span></p>
                        <span class="text-center">
                            <button class="btn btn-new-primary mb-5" :disabled="resend_btn_disable" @click="resetSuccessfull">OK</button>
                        </span>
                    </div>
                </div>
                <div class="text-center px-4 pb-4 copyright_link text-sm-right" style="bottom: 0; right: 0;">
                    <span class="copyright text-default-blue" style="color: #005dae !important;">© Copyright 2014 - {{ new Date().getFullYear() }} DigiCollect - All Rights Reserved.</span>
                </div>
            </div>
        </div>
        <div v-if="resend_success_full_btn_clicked" class="full-page-loader">
            <div class="d-flex align-items-center justify-content-center">
                <div class="digi-table-loader inverse">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import auth from '../mixins/auth';
import global from '../global'
import PasswordMeter from "../PasswordMeter";
export default {
    data() {
        return {
            bgCover: "background: url('https://cdn.digicollect.com/cdn/posv2/pos_login_back.png') left center no-repeat;",
            loginPage: "crm",
            account_type: "Personal",
            min_pwd_length: false,
            is_uppercase_pwd: false,
            is_number_pwd: false,
            is_special_character_pwd: false,
            enter_pass_screen: false,
            sign_in_using_otp: false,
            otp_signin_btn: false,
            show_countdown: false,
            enter_email_screen: true,
            two_factor_authentication: false,
            is_two_factor_authentication: true,
            two_factor_auth_pass_screen_active: true,
            user_not_verified: false,
            is_email_phone_valid: false,
            is_email_phone_valid_text: '',
            password_error_text: '',
            login_type: '',
            login_btn_disable: false,
            resend_verification_link_success: '',
            code_resending: false,
            resend_code_success_text: '',
            otp_error_text: '',
            two_step_signed_in_otp: false,
            forget_pass_btn_disable: false,
            forgot_pass_otp_success: false,
            is_using_email: false,
            is_using_mobile: false,
            resend_btn_disable: false,
            is_email_phone_valid_text_forget_pass: '',
            is_login_using_pin: false,
            emails_pin_login: [],
            selected_email_for_pin_login: '',
            login_pin_api_call: false,
            login_pin: '',
            login_pin_error_text: '',
            login_pin_disabled: true,
            reset_password_api_inprogress: false,
            resend_success_full_btn_clicked: false,
            is_otp_login: false,
            minutes_timeout_id: undefined,
            minutes: 899,
            password_button: false,
            countdownseconds: 60,
        }
    },
    mixins: [auth],
    components: {
        HollowDotsSpinner,
        PasswordMeter
    },
    mounted(){
        this.$refs.text.focus();
        // if (this.$route.query.redirect_to) {
        //     let url = this.$route.query.redirect_to;
        //     let decode = decodeURI(url);
        //     if (decode == "https://crm.digicollect.com" || decode == "https://testcrm.digicollect.com" || decode == "http://testcrm.digicollect.com") {
        //         this.loginPage = "crm";
        //         this.bgCover = "background: url('https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/crm_login.svg') left center no-repeat";
        //     } else if (decode == "https://pos.digicollect.com" || decode == "https://testpos.digicollect.com" || decode == "http://testpos.digicollect.com") {
        //         this.loginPage = "pos";
        //         this.bgCover = "background: url('https://cdn.digicollect.com/cdn/posv2/images/login_screen_pos_back.svg') left center no-repeat";
        //     } else if (decode == "https://scm.digicollect.com" || decode == "https://testscm.digicollect.com" || decode == "http://testscm.digicollect.com") {
        //         this.loginPage = "scm";
        //         this.bgCover = "background: url('https://cdn.digicollect.com/cdn/dc_crm_fe/https://cdn.digicollect.com/cdn/posv2/images/scm_login_new.svg') left center no-repeat";
        //     } else if (decode == "https://hms.digicollect.com" || decode == "https://testhms.digicollect.com" || decode == "http://testhms.digicollect.com") {
        //         this.loginPage = "hms";
        //         this.bgCover = "background: url('https://cdn.digicollect.com/cdn/dc_crm_fe/https://cdn.digicollect.com/cdn/posv2/images/hms-login-bg.svg') left center no-repeat";
        //     } else if (decode == "https://helpdesk.digicollect.com" || decode == "https://testhelpdesk.digicollect.com" || decode == "http://testhelpdesk.digicollect.com") {
        //         this.loginPage = "helpdesk";
        //         this.bgCover = "background: url('https://cdn.digicollect.com/cdn/dc_crm_fe/https://cdn.digicollect.com/cdn/posv2/images/helpdesk_login_new.svg') left center no-repeat";
        //     } else if (decode == "https://cloud.digicollect.com" || decode == "https://testcloud.digicollect.com" || decode == "http://testcloud.digicollect.com") {
        //         this.loginPage = "cloud";
        //         this.bgCover = "background: url('https://cdn.digicollect.com/cdn/dc_crm_fe/https://cdn.digicollect.com/cdn/posv2/images/cloud_login_new.svg') left center no-repeat";
        //     }
        // }
        localStorage.removeItem("set_location")
        this.emails_pin_login = localStorage.getItem('email_ids_for_pin_login') ? JSON.parse(localStorage.getItem('email_ids_for_pin_login')) : [];
        if(this.emails_pin_login.length > 0) {
            this.selected_email_for_pin_login = this.emails_pin_login[0]
        }
        window.history.forward(1)
        if (this.$cookie.get("Token") && this.$route.path == '/login') {
            this.$router.push("/");
        }
    },
    created() {
        const dict = {
            custom: {
                new_password: {
                    min: () => "Minimum 8 characters",
                    regex: () => "Invalid format",
                },
            }
        };
        this.$validator.localize("en", dict);
    },
    methods: {
        focus() {
            // this.is_email_phone_valid_text = false
            this.is_email_phone_valid_text_forget_pass = false
        },
        /**
         * This method or function is used to set the time interval for showing the time in which the otp will expire
         */
        countDownMinutes() {
            this.show_minutes = true
            if(this.countdownseconds == 0){
                this.countdownseconds = 60;
            }
            if (this.minutes > 0) {
                this.minutes_timeout_id = setTimeout(() => {
                    this.minutes -= 1;
                    this.countdownseconds -=1;
                    this.countDownMinutes();
                }, 1000);
            } else if (this.minutes === 0) {
                this.show_minutes = false
                this.countdownseconds = 0;
                this.is_using_email = false
                this.is_using_mobile = false
            }
        },
        errorBoxCancel(){
            this.otpInvalid = false
        },
        forgotPassword(){
            this.activediv = 'forgot password'
            this.$nextTick(() => {
                this.$refs.forgot.focus();
            });  
        },
        forgotPasswordCancel(){
            this.activediv = 'login';
            if(this.is_otp_login == true){
                this.enter_email_screen = false;
                this.login_btn_disable = false;
                this.enter_pass_screen = false;
                this.forget_pass_btn_disable = false;
                this.is_two_factor_authentication = true;
                this.two_factor_auth_pass_screen_active = true;
                this.two_factor_authentication = false;
                this.forgot_pass_otp_success = false;
                this.is_email_phone_valid_text_forget_pass = ''
                this.$nextTick(() => {
                    this.$refs.name.focus();
                }); 
            }else{
                this.enter_email_screen = false;
                this.login_btn_disable = false;
                this.enter_pass_screen = true;
                this.forget_pass_btn_disable = false;
                this.is_two_factor_authentication = true;
                this.two_factor_auth_pass_screen_active = true;
                this.two_factor_authentication = false;
                this.forgot_pass_otp_success = false;
                this.is_email_phone_valid_text_forget_pass = ''
                this.$nextTick(() => {
                    this.$refs.name.focus();
                });  
            }
        },
        showLoginUsingPin() {
            this.activediv = 'login_using_pin';
        },
        addAnotherAccount() {
            this.activediv = 'login';
        },
        loginPinEntered(value) {
            this.login_pin = value;
            this.login_pin_disabled = false;
            this.login_pin_error_text = ''
        },
        loginPinChangeDetected(val) {
            if(val.length<6) {
                this.login_pin_disabled = true;
            }
        },
        /**
         * This method or function is used to login the user using pin
         * @param {email} - email is the String
         * @param {pin} - pin is the integer
         * @returns {response} - gets the Result
         * After success result will get the token in response that we will store in the cookies
         */
        async loginPinApiCall() {
            try {
                this.login_pin_api_call = true;
                let params = {
                    email: this.selected_email_for_pin_login,
                    pin: this.login_pin
                }
                await this.$http.post('/mobile/login', params).then((response)=>{
                    if(response.data.status_id == 1) {
                        if(response.data.user_data.Authorization) {
                            this.$cookie.set("Token", response.data.user_data.Authorization, {
                                expires: 7,
                                domain: window.location.hostname
                            });
                            // this.$cookie.set("session_id", response.data.session_id, {
                            //     expires: 7,
                            //     domain: window.location.hostname
                            // });
                            // sessionStorage.setItem('session_id', response.data.session_id);
                            // this.$store.commit("setToken", response.data.user_data.Authorization);
                            window.location.href = global.POS_URL;
                        }
                    } else {
                        this.$refs.loginPinInput.clearInput();
                        this.login_pin_api_call = false;
                        this.login_pin_disabled = true;
                        this.login_pin = '';
                        this.login_pin_error_text = response.data.reason;
                    }
                }).catch((err)=>{
                    this.$refs.loginPinInput.clearInput();
                    this.login_pin_api_call = false;
                    this.login_pin_disabled = true;
                    this.login_pin = '';
                    this.login_pin_error_text = err.response.data.reason;
                });
            }
            catch(e) {
                console.log(e);
            }
        },
        cancelPassScreen() {
            this.enter_email_screen = true;
            this.enter_pass_screen = false;
            this.login_btn_disable = false;
            this.$nextTick(() => {
                this.$refs.text.focus();
            }); 
        },
        cancelOtpScreen() {
            this.enter_email_screen = true;
            this.enter_pass_screen = false;
            this.sign_in_using_otp = false;
            this.login_btn_disable = false;
            this.$nextTick(() => {
                this.$refs.name.focus();
            }); 
        },
        cancelTwoFactor() {
            this.two_factor_authentication = false;
            this.enter_email_screen = true;
            this.enter_pass_screen = false;
            this.login_btn_disable = false;
            this.$nextTick(() => {
                this.$refs.name.focus();
            });
        },
        /**
         * This method or function is used to validate the email and phone number using the regex
         */
        checkUserId() {
            if (this.form.login_id.match(/[A-Za-z]/g)) {
                this.login_type = 'email';
                let email_regex = /^\w+([-.]\w+)*@\w+([-.]\w+)*\.\w{2,4}$/;
                let check = email_regex.test(this.form.login_id);
                if (!check) {
                    this.is_email_phone_valid = true;
                    this.is_email_phone_valid_text = "Please enter a valid Email Address/Phone Number";
                } else {
                    this.is_email_phone_valid = false;
                    this.is_email_phone_valid_text = "";
                }
            } else {
                this.login_type = 'phone';
                let check = this.form.login_id.match(/^\d{10}$/);
                if (!check) {
                    this.is_email_phone_valid = true;
                    this.is_email_phone_valid_text = "Please enter a valid Email Address/Phone Number";
                } else {
                    this.is_email_phone_valid = false;
                    this.is_email_phone_valid_text = "";
                }
            }
            this.$forceUpdate();
        },
        /**
         * This method or function is used to validate the email/phone and then calling login check api
         * to check if the user exists or not
         * if exists then continue to password enter screen
         * else will show the message that user doesn't exist
         */
        goToNext(type) {
            this.otpInvalid = false;
            this.otp_error_text = '';
            this.password_error_text = '';
            if (this.form.login_id.match(/[A-Za-z]/g)) {
                let check = this.form.login_id.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/);
                if (!check) {
                    this.is_email_phone_valid = true;
                    this.is_email_phone_valid_text = "Please enter a valid Email Address/Phone Number";
                } else {
                    this.is_email_phone_valid = false;
                    if(type == 'login') {
                        this.loginCheck();
                    }
                    else {
                        this.is_using_email = true;
                        this.is_using_mobile = false;
                        this.getOtpForgetPass('send');
                    }
                }
            } else {
                // let check = this.form.login_id.match(/(\+?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/);
                let check = this.form.login_id.match(/^\d{10}$/);
                if (!check) {
                    this.is_email_phone_valid = true;
                    this.is_email_phone_valid_text = "Please enter a valid Email Address/Phone Number";
                    this.signedIn = false;
                } else {
                    this.is_email_phone_valid = false;
                    if(type == 'login') {
                        this.loginCheck();
                    }
                    else {
                        this.is_using_email = false;
                        this.is_using_mobile = true;
                        this.getOtpForgetPass('send');
                    }
                }
            }
        },
        /**
         * This method or function is used to check the user exists or not
         * @param {login_id} - login_id is the String/Integer
         * @returns {response} - gets the Result
         */
        async loginCheck() {
            this.login_btn_disable = true;
            let params = {
                login_id: this.form.login_id
            }
            await this.$http.post(global.ADMIN_API + "/auth/", params).then((response)=>{
                if(response.data.status_id == 1) {
                    this.form.login_id == '';
                    if(response.data.is_two_step_verification == true) {
                        this.two_factor_authentication = true;
                        this.enter_email_screen = false;
                        this.is_otp_login = false
                    }else if(response.data.is_otp_login == true) {
                        this.two_factor_authentication = false;
                        this.is_otp_login = true
                        this.goToOtpLogin('send')
                        this.enter_email_screen = false;
                    }else {
                        this.enter_pass_screen = true;
                        this.enter_email_screen = false;
                        this.is_otp_login = false
                        this.$nextTick(() => {
                            this.$refs.name.focus();
                        }); 
                    }
                }
            }).catch((err)=>{
                this.login_btn_disable = false;
                if(err.response.data.reason == 'Login Id Not Verified. Please Verify') {
                    this.enter_email_screen = false;
                    this.user_not_verified = true;
                } else {
                    this.is_email_phone_valid = true;
                    this.is_email_phone_valid_text = err.response.data.reason;
                }
                // if(err.response.data.reason == 'Registration matching query does not exist.' || err.response.data.reason == 'Invalid Email Id or Password.') {
                // }
            });
        },
        cancelResendLink() {
            window.location.href = global.POS_URL;
        },
        /**
         * This method or function is used to resend the user verification link if user not verified
         * @param {login_id} - login_id is the String/Integer
         * @returns {response} - gets the Result
         */
        async resendUserVerifyLink() {
            this.resend_btn_disable = true;
            try {
                let params = {
                    login_id: this.form.login_id,
                }
                let response = await this.resendVerify(params);
                if(response.status_id == 1) {
                    this.resend_verification_link_success = response.response;
                    setTimeout(() => {
                        this.resend_verification_link_success = '';
                        window.location.href = global.POS_URL;
                    }, 2000);
                }
            }
            catch(err) {
                this.resend_verification_link_success = err;
                this.resend_btn_disable = false;
            }
        },
        /**
         * This method or function is used to login the user using password will call the login api
         * @param {login_id} - login_id is the String/Integer
         * @param {password} - password is the String/Integer
         * @returns {response} - gets the Result
         */
        async loginUsingPassword(type) {
            if(this.form.password.length == 0) {
                this.password_error_text = 'Please Enter a Password';
            }
            else {
                this.signedIn = true;
                // try {
                let params = {
                    login_id: this.form.login_id,
                    password: this.form.password
                }
                await this.$http.post(global.ADMIN_API + "/auth/", params).then((response)=>{
                    if(response.data.status_id == 1) {
                        if(type == 'one-step') {
                            if(response.data.token) {
                                this.$cookie.set("Token", response.data.token, {
                                    expires: 7,
                                    domain: window.location.hostname
                                });
                                this.$cookie.set("session_id", response.data.session_id, {
                                    expires: 7,
                                    domain: window.location.hostname
                                });
                                // sessionStorage.setItem('session_id', response.data.session_id);
                                if (this.$route.query.redirect_to) {
                                    window.location.href = this.$route.query.redirect_to;
                                } else {
                                    this.$store.commit("setToken", response.data.token);
                                    window.location.href = global.POS_URL;
                                }
                            }
                        }
                        else if(type == 'two-step') {
                            if(response.data.message == 'Please Enter Otp For Verification.' || response.data.is_two_step_verification == true) {
                                this.two_factor_auth_pass_screen_active = false;
                                this.countDownTimer();
                                this.signedIn = false;
                            }
                        }

                    }
                }).catch((err)=>{
                    // if(err.response.status == 400 || err.response.status == 401) {
                        this.password_error_text = err.response.data.reason;
                        this.signedIn = false;
                        this.form.password = "";
                    // }
                });

                // }
                // catch(err) {
                //     this.password_error_text = err;
                //     this.signedIn = false;
                //     this.form.password = "";
                // }
            }
        },
        forgotPasswordCancel(){
            this.activediv = 'login';
            this.enter_email_screen = false;
            this.login_btn_disable = false;
            this.enter_pass_screen = true;
            this.forget_pass_btn_disable = false;
            this.is_two_factor_authentication = true;
            this.two_factor_auth_pass_screen_active = true;
            this.two_factor_authentication = false;
            this.forgot_pass_otp_success = false;
            this.is_email_phone_valid_text_forget_pass = '',
            this.$nextTick(() => {
                this.$refs.name.focus();
            }); 
        },
        /**
         * This method or function is used to login the user using OTP will call the login api
         * @param {login_id} - login_id is the String/Integer
         * @param {otp} - otp is the Integer
         * @returns {response} - gets the Result
         */
        async loginUsingOtp(type) {
            if(type == 'one-step') {
                this.signedIn = true;
            }
            else {
                this.two_step_signed_in_otp = true;
            }
            let params = {
                login_id: this.form.login_id,
                otp: this.otpValue
            }
            if(params.otp == 0) {
                this.otpInvalid = true;
                this.otp_error_text = 'Please enter correct otp.';
                if(type == 'one-step') {
                    this.signedIn = false;
                }
                else {
                    this.two_step_signed_in_otp = false;
                }
                this.$refs.otpInput.clearInput();
                this.otpValue = "";
            } else {
                await this.$http.post(global.ADMIN_API + "/auth/", params).then((response)=>{
                    if(response.data.status_id == 1 && response.data.token) {
                        this.$cookie.set("Token", response.data.token, {
                            expires: 7,
                            domain: window.location.hostname
                        });
                        this.$cookie.set("session_id", response.data.session_id, {
                            expires: 7,
                            domain: window.location.hostname
                        });
                        // sessionStorage.setItem('session_id', response.data.session_id);
                        if (this.$route.query.redirect_to) {
                            window.location.href = this.$route.query.redirect_to;
                        } else {
                            this.$store.commit("setToken", response.data.token);
                            window.location.href = global.POS_URL;
                        }
                    }
                }).catch((err)=>{
                    // if(err.response.status == 400 || err.response.status == 401) {
                        this.otpInvalid = true;
                        this.otp_error_text = err.response.data.reason;
                        if(type == 'one-step') {
                            this.signedIn = false;
                            this.handleClearInput();
                        }
                        else {
                            this.two_step_signed_in_otp = false;
                            this.handleClearInput();
                        }
                        this.$refs.otpInput.clearInput();
                        this.otpValue = "";
                    // }
                })
            }
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
        otpInputActive() {
            this.loginUsingPassword('two-step');
            this.password_button = false
            clearTimeout(this.minutes_timeout_id);
            this.minutes = 899;
            this.countdown = 59;
            this.countdownseconds = 60;
            this.countDownMinutes();
        },
        /**
         * This method or function is used to show the OTP login section
         */
        async goToOtpLogin(type, new_type) {
            this.otpInvalid = false;
            this.otp_error_text = '';
            this.password_error_text = '';
            if(type == 'send') {
                this.sign_in_using_otp = true;
                clearTimeout(this.minutes_timeout_id);
                this.minutes = 899;
                this.countdown = 60;
                this.countdownseconds = 60;
                this.countDownMinutes();
                if(new_type && new_type =='two-factor-otp') {
                    this.password_button = true
                    this.two_factor_auth_pass_screen_active = false
                }
            }
            if(type == 'resend') {
                this.code_resending = true;
                this.otpInvalid = false;
                clearTimeout(this.minutes_timeout_id);
                this.minutes = 899;
                this.countdown = 60;
                this.countdownseconds = 60;
                this.countDownMinutes();
            }
            // else {
            //     this.code_resending = true;
            //     this.otpInvalid = false;
            //     this.countdown = 60;
            // }
            this.countDownTimer();
            try {
                let params = {
                    login_id: this.form.login_id,
                    otp_for: 'login'
                }
                let response = await this.sendLoginOtp(params);
                if(response.status_id == 1) {
                    if(type == 'resend') {
                        this.resend_code_success_text = response.message;
                        setTimeout(() => {
                            this.resend_code_success_text = '';
                        }, 2000);
                        this.code_resending = false;
                    }
                }
            }
            catch(err) {
                this.resend_code_success_text = err;
            }
        },
        /**
         * This method or function is used to show password login section
         */
        goToPassLogin(new_type) {
            this.otpInvalid = false;
            this.otp_error_text = '';
            this.password_error_text = '';
            this.sign_in_using_otp = false;
            if(new_type && new_type =='two-factor-password') {
                this.two_factor_auth_pass_screen_active = true
            }
            this.$nextTick(() => {
                this.$refs.name.focus();
            }); 
        },
        /**
         * This method or function is used to do the password validation using regex
         */
        onPassInput(e) {
            if(e.target.value.match(/^(?=.{8,}$)/)) {
                this.min_pwd_length = true;
            }
            else {
                this.min_pwd_length = false;
            }
            if(e.target.value.match(/^(?=.*[A-Z])/)) {
                this.is_uppercase_pwd = true;
            } else {
                this.is_uppercase_pwd = false;
            }
            if(e.target.value.match(/^(?=.*[0-9])/)) {
                this.is_number_pwd = true;
            } else {
                this.is_number_pwd = false;
            }
            if(e.target.value.match(/^(?=.*[!@#$%^&*()])/)) {
                this.is_special_character_pwd = true;
            } else {
                this.is_special_character_pwd = false;
            }
        },
        /**
         * This method or function is used to open the forgot password page
         * @param {login_id} - login_id is the String/Integer
         * @param {otp_for} - otp_for is the String
         */
        async getOtpForgetPass(type) {
            if(type == 'send') {
                this.forget_pass_btn_disable = true;
                clearTimeout(this.minutes_timeout_id);
                this.minutes = 899;
                this.countdown = 60;
                this.countdownseconds = 60;
                this.countDownMinutes();
            }
            else {
                this.code_resending = true;
                this.otpInvalid = false;
                clearTimeout(this.minutes_timeout_id);
                this.minutes = 899;
                this.countdown = 60;
                this.countdownseconds = 60;
                this.countDownMinutes();
            }
            this.countDownTimer();
            let params = {
                login_id: this.form.login_id,
                otp_for: 'reset'
            }
            await this.$http.post(global.ADMIN_API + "/auth/otp", params).then((response)=>{
                if(response.data.status_id == 1) {
                    if(type == 'resend') {
                        this.resend_code_success_text = response.data.message;
                        setTimeout(() => {
                            this.resend_code_success_text = '';
                        }, 2000);
                        this.code_resending = false;
                    }
                    else {
                        this.activediv = 'verify otp';
                    }
                    this.forget_pass_btn_disable = false;
                }
            }).catch((err)=>{
                // if(err.response.status == 400 || err.response.status == 401) {
                    this.forget_pass_btn_disable = false;
                    if(type == 'send') {
                        this.is_email_phone_valid_text_forget_pass = err.response.data.reason;
                    } else if(type == 'resend') {
                        this.resend_code_success_text = err.response.data.reason;
                    }
                // }
            });
        },
        /**
         * This method or function is used to verify the otp for forgot password
         * @param {login_id} - login_id is the String/Integer
         * @param {otp} - otp is the Integer
         * @param {otp_for} - otp_for is the String
         * @returns {response} - gets the Result
         */
        async verifyOtpForgotPass() {
            this.forgot_pass_otp_success = true;
            let params = {
                login_id: this.form.login_id,
                otp: this.otpValue,
                otp_for: 'reset'
            }
            await this.$http.post(global.ADMIN_API + "/auth/otp/verify", params).then((response)=>{
                if(response.data.status_id == 1 ) {
                    this.activediv = 'set new password';
                }
            }).catch((err)=>{
                // if(err.response.status == 400 || err.response.status == 401) {
                    this.otp_error_text = err.response.data.reason;
                    this.otpInvalid = true;
                    this.otpValue = "";
                    this.handleClearInput();
                    this.forgot_pass_otp_success = false;
                // }
            })
        },
        /**
         * This method or function is used to reset the password
         * @param {login_id} - login_id is the String/Integer
         * @param {password} - password is the String/Integer
         * @returns {response} - gets the Result
         */
        async resetPasswordConfirm() {
            try {
                this.reset_password_api_inprogress = true;
                let params = {
                    login_id: this.form.login_id,
                    password: this.form.password
                }
                let response = await this.passwordReset(params);
                if(response.status_id == 1) {
                    this.activediv = "reset successfull";
                }
                this.reset_password_api_inprogress = false;
            }
            catch(err) {
                this.errorMessage = err;
                this.reset_password_api_inprogress = false;
            }
        },
        async resetPassword() {
            this.$validator.validateAll().then(async result => {
                if (result && this.form.password && this.confirmPassword === this.form.password && !this.errorMessage) {
                    this.resetPasswordConfirm();
                } else if (!this.form.password || !this.confirmPassword) {
                    this.errorMessage = "Please fill both fields";
                } else if(this.confirmPassword !== this.form.password) {
                    this.errorMessage = "Confirm Password not Matched";
                }
            })
        }
    },
    watch: {
        "$route.path": function (path) {
            if (this.$cookie.get("Token") && path == '/login') {
                this.$router.push("/");
            }
        },
    }
}
</script>

<style scoped>
    .forgot-password{
        font-family: 'Poppins', sans-serif !important;
        font-size: 35px;
    }
    .image-holder {
        width: 500px;
        background: #fff;
        height: 500px;
        border-radius: 50%;
        bottom: -90px;
        position: absolute;
        left: -60px;
    }
    .group-image {
        width: 350px;
        height: 350px;
        right: 60px;
        position: absolute;
        top: 60px;
    }
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 60px;
        padding-right: 60px;
    }
    .middle-left {
        width: 50%;
    }
    .middle-right {
        width: 50%;
    }
    .star {
        font-size: 12px;
        color: #F44336;
    }
    @media (max-width: 1100px) {
        .middle-left {
            display: none;
        }
        .middle-right {
            width: 100%;
            justify-content: center;
        }
        .middle {
            max-width: 767px;
            margin: 0 auto;
        }
        .cr-backimg {
            background: #FFF !important;
        }
    }
    @media screen and (min-width: 741px) {
        .crm-right-logo {
            margin-left: auto;
        }
        .text-default-heading{
            margin-top: 10px !important;
        }
    }
    @media (max-width: 740px) {
        .middle {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .middle-right {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
        .top {
            justify-content: center;
            flex-direction: column;
        }
    }
    @media screen and (min-width: 572px) {
        .crm-right-logo{
            font-size: 20px !important;
        }
        .company-name{
            font-size: 18px !important; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .sign-in{
            font-size: 30px;
        }
    }
     @media screen and (min-width: 768px) and (max-width:812px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 10px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 12px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
        }
        .middle{
            margin-top: 0px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-bottom: 20px !important;
        }
        #input::placeholder{
            font-size: 10px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 20px !important;
            margin-top: -20px !important;
        }
        .forgot-password-button{
            margin-top: 20px !important;
            margin-bottom: -40px !important;
        }
    }
    @media screen and (min-width: 668px) and (max-width:767px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 10px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 12px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
        }
        .middle{
            margin-top: 0px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-bottom: 20px !important;
        }
        #input::placeholder{
            font-size: 10px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 20px !important;
            margin-top: -20px !important;
        }
        .forgot-password-button{
            margin-top: 20px !important;
            margin-bottom: -40px !important;
        }
    }
    @media screen and (min-width: 569px) and (max-width:667px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 10px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 12px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
        }
        .middle{
            margin-top: 0px !important;
        }
        .text-right{
            margin-top: 15px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-bottom: 20px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 10px !important;
            margin-top: -20px !important;
        }
        .forgot-password-button{
            margin-top: 20px !important;
            margin-bottom: -40px !important;
        }
    }
    @media screen and (min-width: 568px) and (max-width:571px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 0px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 12px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-top: 0px;
        }
        .text-default-heading{
            margin-top: 10px;
            margin-bottom: 10px !important;
        }
        .font-weight-bold{
            margin-bottom: -5px;
        }
        .text-right{
            margin-top: 10px !important;
        }
        .middle{
            margin-top: 0px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-top: -10px;
            margin-bottom: 0px !important;
        }
        .font-weight-bold{
            margin-top: -10px !important;
            margin-bottom: 5px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .default-link{
            margin-top: -10px !important;
        }
        .align-items-start{
            margin-top: 10px !important;
        }
        .sign-in-otp{
            margin-top: -30px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 10px !important;
            margin-top: -40px !important;
        }
        .forgot-password-button{
            margin-top: 5px !important;
            margin-bottom: -40px !important;
        }
    }
    @media screen and (min-width: 376px) and (max-width:567px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 10px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 12px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
        }
        .middle{
            margin-top: -65px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-bottom: 20px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 20px !important;
            margin-top: -20px !important;
        }
        .forgot-password-button{
            margin-top: 20px !important;
            margin-bottom: -40px !important;
        }
    }
    @media screen and (min-width: 321px) and (max-width:375px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 10px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 12px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
        }
        .middle{
            margin-top: -65px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-bottom: 20px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 20px !important;
            margin-top: -20px !important;
        }
        .forgot-password-button{
            margin-top: 20px !important;
            margin-bottom: -40px !important;
        }
    }
    @media screen and (min-width: 0px) and (max-width:320px) {
        .company-name{
            font-family: 'Poppins', sans-serif !important;
            padding-top: 10px;
            font-size: 18px; 
            font-weight: 600;
            padding-bottom: 15px;
        }
        .crm-right-logo{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px !important;
        }
        .copyright{
            font-size: 10px !important;
            text-align: center;
        }
        .sign-in{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
        }
        .middle{
            margin-top: -65px !important;
        }
        .digiadmin-logo{
            width: 30px !important;
            height: 25px !important;
        }
        .text-grey-default{
            margin-bottom: 20px !important;
        }
        .text-danger{
            font-size: 12px !important;
        }
        .digi-form-full-bordered{
            height: 30px !important;
        }
        ::placeholder{
            font-size: 12px !important;
        }
        .forgot-password{
            font-family: 'Poppins', sans-serif !important;
            font-size: 20px;
            margin-bottom: 20px !important;
            margin-top: -20px !important;
        }
        .forgot-password-button{
            margin-top: 20px !important;
            margin-bottom: -40px !important;
        }
    }
    .error-box {
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        background-color: #eedfdf;
    }
</style>
