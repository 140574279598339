<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">ORDER SUMMARY</span>
                </div>
                <div class="v-modal-body" style="padding: 15px 0px;overflow-y: auto;overflow-x: hidden;">
                    <div class="v-modal-layout">
                        <div class="row pl-5 pr-5">
                            <div class="col-md-12">
                                <div class="pr-3 row no-gutters align-items-center justify-content-between">
                                    <div class="col-4">
                                        <div class="row no-gutters align-items-center">
                                            <span class="fs-14 fw-normal">Customer Name</span>
                                        </div>
                                    </div>
                                    <div class="fs-14 col-8 col-8 font-weight-bold">John Dou</div>
                                </div>
                                <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                                    <div class="col-4">
                                        <div class="row no-gutters align-items-center">
                                            <span class="fs-14 fw-normal">Table Number</span>
                                        </div>
                                    </div>
                                    <div class=" fs-14 col-8 font-weight-bold">FF1234</div>
                                </div>
                                <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                                    <div class="col-4">
                                        <div class="row no-gutters align-items-center">
                                            <span class="fs-14 fw-normal">Order Number</span>
                                        </div>
                                    </div>
                                    <div class="fs-14 col-8 font-weight-bold">DR123541</div>
                                </div>
                                <h6 class="d-inline-block pr-2 pt-4" style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;">Order Summary</h6>
                                <table class="table tablecart border-bottom-dotted">
                                    <thead>
                                        <tr style="border-bottom: solid 1px #b8b9bb;border-bottom-style: dashed;">
                                            <th class="thcart" style="font-size: 14px;">Items</th>
                                            <th class="thcart" style="font-size: 14px;">Quantity</th>
                                            <th class="thcart" style="font-size: 14px;">Tax</th>
                                            <th class="thcart" style="font-size: 14px;">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-dotted">
                                        <tr>
                                            <td class="tdcart items text-secondary" style="font-size: 14px;">Burger/Large X 2</td>
                                            <td class="tdcart items text-secondary" style="font-size: 14px;">12</td>
                                            <td class="tdcart" style="font-size: 14px;">$1</td>
                                            <td class="tdcart" style="font-size: 14px;">$12</td>
                                        </tr>
                                        <tr>
                                            <td class="tdcart items text-secondary" style="font-size: 14px;">Coffee/Medium</td>
                                            <td class="tdcart items text-secondary" style="font-size: 14px;">10</td>
                                            <td class="tdcart" style="font-size: 14px;">$1</td>
                                            <td class="tdcart" style="font-size: 14px;">$06</td>
                                        </tr>
                                        <tr>
                                            <td class="tdcart items text-secondary" style="font-size: 14px;">Milkshake </td>
                                            <td class="tdcart items text-secondary" style="font-size: 14px;">01</td>
                                            <td class="tdcart" style="font-size: 14px;">$1</td>
                                            <td class="tdcart" style="font-size: 14px;">$12</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table tablesubtotal">
                                    <tbody style="border-bottom:solid 0.5px #94bef0;"> 
                                        <tr class="type">
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <th class="tablesubtotalth font-weight-normal">Subtotal</th>
                                            <td class="tablesubtotaltd" style="padding-right: 10px !important;">$24.00</td>
                                        </tr>
                                        <tr class="size">
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <th class="tablesubtotalth font-weight-normal">Tax</th>
                                            <td class="tablesubtotaltd" style="padding-right: 10px !important;">$1.00</td>
                                        </tr>
                                        <tr class="location">
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <th class="tablesubtotalth font-weight-normal">Discount</th>
                                            <td class="tablesubtotaltd" style="padding-right: 10px !important;">$1.00</td>
                                        </tr>
                                        <tr class="owner">
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <th class="tablesubtotalth font-weight-normal">Roundoff</th>
                                            <td class="tablesubtotaltd" style="padding-right: 10px !important;">$00.00</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="tfoot-total-order">
                                        <tr>
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <td class="tdcart"></td>
                                            <th class="tfcart text-secondary tablesubtotaltd">Total</th>
                                            <th class="tfcart tablesubtotaltd" style="padding-right: 10px !important;">$24.00</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="v-modal-footer text-center pt-5">
                        <a class="btn btn-link btn-smm" style="text-transform: uppercase !important;font-size: 12px !important;" @click="hidePlaceOrder()">CANCEL</a>
                        <button type="submit" class="btn btn-secondary btn-smm" @click="hidePlaceOrder()" >SUBMIT</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>    
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event.js';
export default {
    props:['modal_name'],
    methods: {
        hidePlaceOrder(modal_name){
            EventBus.$emit('ClosePlaceOrder',modal_name);
        }
    }
}
</script>
<style>
.w-39{
    width:39% !important;
}
.tdcart.items.text-secondary{
    font-weight: 600;
}
</style>
