<template>
    <div>
        <modal :name="modal_name" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer'}"
            class="item-summary-component" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header" style="background: #005dae !important;">
                    <span class="v-modal-dialog-title v-modal-title" style="color: #fff !important;">ITEMS
                        SUMMARY</span>
                    <span @click="hideItemSummary()">
                        <i class="icon icon-close pointer" style="color: #fff !important;"></i>
                    </span>
                </div>
                <div class="newBox m-3">
                    <div class="v-modal-body pr-0 pt-0 pl-0"
                        style="max-height: 390px;overflow: auto;">
                        <div class="v-modal-layout">
                            <div class="digi-table-loader d-flex justify-content-center mt-10" v-if="summary_loading" style="margin: 0 auto">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div v-else-if="!summary_loading && order">
                                <table class="table">
                                    <thead>
                                        <tr class="header-background w-100 mt-2">
                                            <th style="width:40%">Products</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody class="item-summary-body">
                                        <template v-for="(course,cindex) in order.courses">
                                            <tr :key="cindex" style="height: 40px !important;">
                                                <td colspan="4" class="font-weight-bold">Course {{course.course}}</td>
                                            </tr>
                                            <template v-for="(item,item_index) in course.items">
                                                <tr :key="item._id + ''+ item_index" style="height:40px;">
                                                    <td class="font-600 col-4" v-if="item.variant_id" style="width:40%">
                                                        <el-tooltip v-if="item.variant_type == 'Non-Veg'"
                                                            content="Non-Veg" placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.variant_type == 'Veg'" content="Veg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.variant_type == 'Egg'" content="Egg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.variant_type == 'Pescatarian'"
                                                            content="Pescatarian" placement="top">
                                                            <img style="width:15px;"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                        </el-tooltip>
                                                        <span class="mt-1 text-secondary font-weight-bold"
                                                            :class="{'ml-1':item.variant_type != ''}">{{item.product_name + ' / ' + item.variant_name}}</span>
                                                    </td>
                                                    <td class="font-600 col-4" v-else>
                                                        <el-tooltip v-if="item.product_type == 'Non-Veg'"
                                                            content="Non-Veg" placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.product_type == 'Veg'" content="Veg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.product_type == 'Egg'" content="Egg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.product_type == 'Pescatarian'"
                                                            content="Pescatarian" placement="top">
                                                            <img style="width:15px;"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                        </el-tooltip>
                                                        <span class="mt-1 text-secondary font-weight-bold"
                                                            :class="{'ml-1':item.product_type != ''}">{{item.product_name}}</span>
                                                    </td>
                                                    <td class="font-600 col-2">{{item.quantity}}</td>
                                                    <td class="font-600 col-3">{{item.price}}</td>
                                                    <td class="font-600 col-3">{{item.total}}</td>
                                                </tr>
                                                <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                                    <td colspan="4" style="padding: 0 !important;border-radius: 6px; width: 100%;">
                                                        <table class="w-100">
                                                            <tbody>
                                                                <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                                                                    <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                                        <td class="font-600 col-4" style="width:40%;vertical-align:bottom">
                                                                            <div class="d-flex ml-0 pl-0">
                                                                                <span class="font-weight-bold"
                                                                                        >{{modifier.m_item_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-2" style="vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier.quantity}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier.price}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier.amount}}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                            <template v-if="item.modifiers.hasOwnProperty('for_primary')">
                                                                <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'">
                                                                    <tr>
                                                                        <td colspan="4" style="border-radius: 0px;padding: 0.4rem 1.2rem!important;">
                                                                            <div class="text-blue font-600 fs-14">
                                                                                <span>{{modifier.modifier_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                                        <td class="font-600 col-4" style="width:40%;vertical-align:bottom">
                                                                            <div class="d-flex ml-0 pl-0">
                                                                                <span class="font-weight-bold">{{modifier_item.m_item_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-2" style="vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier_item.quantity}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier_item.price}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier_item.amount}}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <!-- <tr v-if="item.addons.length>0">
                                                    <td colspan="4" class="pt-0 pl-1 pr-1">
                                                        <span class="item-collapse position-relative item-position"
                                                            @click="expandAddons(item,item_index)">
                                                            <i v-if="showAddons.includes(item._id)"
                                                                class="icon-chevron-double-up input-circle position-absolute text-white icon-style"></i>
                                                            <i v-else
                                                                class="icon-chevron-double-down text-white icon-style input-circle"></i>
                                                        </span>
                                                        <div class="addon-list">
                                                            <div class="d-flex" v-if="showAddons.includes(item._id)">
                                                                <span class="text-green font-600">Add-On:</span>
                                                            </div>
                                                            <div class="d-flex" v-else>
                                                                <span class="text-green font-600">Add-On:</span>
                                                                <span class="font-weight-bold ml-1">
                                                                    {{item.addons.length}}
                                                                    Add-On Available
                                                                </span>
                                                            </div>
                                                            <table class="w-100">
                                                                <tr v-if="item.addons.length == 0 && showAddons.includes(item._id)"
                                                                    class="font-weight-bold text-center">
                                                                    <td class="font-600">Add-On Not Available</td>
                                                                </tr>
                                                                <tr v-for="(addon,addon_index) in item.addons"
                                                                    style="height: 28px!important;" :key="addon_index"
                                                                    v-else-if="showAddons.includes(item._id)">
                                                                    <td class="font-600 pl-1 col-4">
                                                                        <el-tooltip v-if="addon.type_name=='Non-Veg'"
                                                                            content="Non-Veg" placement="top">
                                                                            <img class="mr-1"
                                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                                        </el-tooltip>
                                                                        <el-tooltip v-else-if="addon.type_name=='Veg'"
                                                                            content="Veg" placement="top">
                                                                            <img class="mr-1"
                                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                                        </el-tooltip>
                                                                        <el-tooltip v-else-if="addon.type_name=='Egg'"
                                                                            content="Egg" placement="top">
                                                                            <img class="mr-1"
                                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                                        </el-tooltip>
                                                                        <el-tooltip
                                                                            v-else-if="addon.type_name == 'Pescatarian'"
                                                                            content="Pescatarian" placement="top">
                                                                            <img class="mr-1" style="width:15px;"
                                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                                        </el-tooltip>
                                                                        <span
                                                                            class="mt-1 font-weight-bold word-break">{{addon.name}}</span>
                                                                    </td>
                                                                    <td class="font-600 col-2">
                                                                        {{addon.quantity}}</td>
                                                                    <td class="font-600 col-3">{{addon.price}}
                                                                    </td>
                                                                    <td class="font-600 col-3">{{addon.amount}}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr> -->
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-between bg-orange py-3 " v-if="!summary_loading && order">
                                <span class="font-weight-bold fs-16 col-9">Grand Total</span>
                                <span class="font-weight-bold fs-16 col-3">{{order.grand_total}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import ManageOrderService from './mixins/manage_order.js'
    import orderServices from './mixins/orders'
    export default {
        props: ['modal_name'],
        data() {
            return {
                showAddons: [],
                summary: '',
                grand_total_value: '',
                grand_total_amount: '',
                modal_msg: '',
                showpay: false,
                safariTrue: false,
                isActive: false,
                isTablet: false,
                order: null,
                summary_loading: false
            }
        },
        mixins: [ManageOrderService,orderServices],
        methods: {
            hideItemSummary() {
                this.$emit('hideItemsSummary')
                this.$modal.hide(this.modal_name)
            },
            expandAddons(item, item_index) {
                if (this.showAddons.find(x => x === item._id)) {
                    this.showAddons = this.showAddons.filter(function (x) {
                        if (x !== item._id) {
                            return x
                        }
                    })
                } else {
                    this.showAddons.push(item._id)
                }
            },
            async loadSummary(){
                let params = { orderId: this.$route.params.id, origin: 'my_order'}
                this.summary_loading = true
                let res = await this.getCheckout(params)
                this.order = res.order
                this.summary_loading = false
            },

        },
        created() {
            this.order.items.forEach((item, item_index) => {
                this.showAddons.push(item._id)
            })
        },
        mounted() {
            this.loadSummary()
            if (this.$route.path.startsWith('/pendingorder')) {
                this.loadPendingOrderSummary()
            }
            this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }
        }
    }

</script>
<style scoped>
    .customize:nth-of-type(odd) {
        background: #e0e0e0;
    }

    .item-summary-component .table td {
        padding: 0.6rem 1.2rem !important;
    }

    .add-custom-addon {
        width: 75px !important;
        border-bottom: none !important;
        color: #00448b !important;
    }

    .v-modal-layout {
        overflow: initial !important;
    }

    .bg-green-image {
        background-image: linear-gradient(83deg,
                #1a9347 8%,
                #1ca04d 53%,
                #20b256 93%);
        outline: 0;
    }

    .bg-blue-image {
        outline: 0;
        background: #005dae;
    }

    .bg-orange-image {
        outline: 0;
        background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
    }

    .b-r-7 {
        border-radius: 7px;
    }

    .table thead th {
        color: #fff !important;
        font-size: 14px !important;
        text-transform: capitalize !important;
        padding: 8px 10px !important
    }

    tbody:nth-of-type(odd) {
        background-color: #fff !important;
    }

    .addon-list {
        border: solid 1px #a2a2a2;
        border-radius: 7px;
        padding: 6px;
        background: #f5f6f9;
    }

    .item-collapse img {
        position: relative;
        right: 17px;
        display: block;
        float: right;
        bottom: 12px;
        height: 26px !important;
        width: 26px;
    }

    .item-rotate {
        transform: rotate(180deg);
    }

    .addon-list div {
        /* max-height: 0; */
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
    }

    .word-break {
        word-break: break-word;
    }

    .newBox {
        box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
        background: #fff;
        padding: 10px 0px;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #00448b;
    }

    .bg-orange {
        background-color: #fccb4a;
    }

    .header-background {
        background: #00448b;
    }

    .input-circle {
        border-radius: 50%;
        background: #00448b;
        color: #000;
        text-align: center;
        font-size: 16px;
        float: right;
    }

    .item-position {
        right: 10px;
        bottom: 10px;
    }

</style>
