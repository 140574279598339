<template>
    <div class="page-layout blank">
        <div class="pb-10" v-if="loggedInUser.outlet_id">
            <!-- <h2 class="headerblock">
                <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()" :to="{ path: $routerHistory.previous().path }">
                    <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                        <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                            <i class="icons icon-arrow-left"></i>
                        </button>
                    </el-tooltip>
                </router-link>
                <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()" :to="{ path: $routerHistory.next().path }">
                    <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                        <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                            <i class="icons icon-arrow-right"></i>
                        </button>
                    </el-tooltip>
                </router-link>
            </h2> -->
            <div class="category-manage-info mt-10" style="top:60px!important;">SELECT PRINTER</div>
            <div class="page-layout carded left-sidebar mt-3 printer-layout">
                <div class="page-content-wrapper">
                    <div v-if="ajaxCallinprogress" class="digi-table-loader text-white text-center">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div v-else class="page-content">
                        <div class="demo-content mt-6">
                            <div class="text-secondary fw-600 bg-white p-2 br-10px">Receipt</div>
                            <el-radio v-for="(receipt,receipt_index) in all_categories.receipts" :key="receipt_index" v-model="receipt_printer" @change="$forceUpdate()" class="mt-3 printer-selection" :label="receipt.printer_id">{{receipt.printer_name.replace('_',' ')}}</el-radio>
                        </div>
                        <div class="demo-content mt-3">
                            <div class="text-secondary fw-600 bg-white p-2 br-10px">Invoice</div>
                            <el-radio v-for="(invoice,invoice_index) in all_categories.invoices" :key="invoice_index" v-model="invoice_printer" @change="$forceUpdate()" class="mt-3 printer-selection" :label="invoice.printer_id">{{invoice.printer_name.replace('_',' ')}}</el-radio>
                        </div>
                        <div class="demo-content mt-3" v-for="(category,index) in all_categories.categories" :key="index">
                            <div class="text-secondary fw-600 bg-white p-2 br-10px">Category - {{category.category_name}}</div>
                            <el-radio v-model="category.printer" @change="$forceUpdate()" class="mt-3 printer-selection" :label="printer.printer_id" v-for="(printer,printer_index) in category.printers" :key="printer_index">{{printer.printer_name.replace('_',' ')}}</el-radio>
                        </div>
                    </div>
                    <div class="text-center pt-5">
                        <button class="btn btn-success btn-smm text-uppercase text-white ml-5" @click="proceed()">PROCEED</button>
                    </div>
                </div>
                <!-- <h4 class="page-content-wrapper font-weight-bold text-center pt-10 text-white" style="height:485px;padding-top:225px !important;" v-else>
                    Please Select Outlet
                </h4> -->
            </div>
        </div>
        <div v-else class="pb-10">
            <div class="page-layout carded left-sidebar mt-3 printer-layout">
                <div class="page-content-wrapper">
                    <div class="digi-table-loader text-white text-center">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import outlet_services from './mixins/outlets'
import cash_register from './mixins/cashRegister'
import global from "./global.js"
export default {
    data(){
        return{
            ajaxCallinprogress: false,
            cash_registers: [],
            invoice_printer: "",
            receipt_printer: "",
            all_categories: []
        }
    },
    mixins:[outlet_services,cash_register],
    methods:{
        async getAllCategory(){
            this.ajaxCallinprogress = true;
            this.all_categories = []
            let res = await this.getCategories()
            this.all_categories = res.response
            this.all_categories.receipts.forEach(x => {
                x['printer'] = ''
                this.receipt_printer = this.all_categories.receipts[0].printer_id
                this.$forceUpdate()
            })
            this.all_categories.invoices.forEach(x => {
                x['printer'] = ''
                this.invoice_printer = this.all_categories.invoices[0].printer_id
                this.$forceUpdate()
            })
            this.all_categories.categories.forEach(x => {
                x['printer'] = ''
                x.printer = x.printers[0].printer_id
                x.printers.forEach(y => [
                    y['printer'] = ''
                ])
                this.$forceUpdate()
            })
            let receipt_data = JSON.parse(sessionStorage.getItem("receipt_printer"))
            if(receipt_data){
                this.all_categories.receipts.forEach(x => {
                    x.printer = receipt_data.printer
                    this.receipt_printer = x.printer
                    this.$forceUpdate()
                })
            }
            else{
                this.all_categories.receipts.forEach(x => {
                    x.printer = this.receipt_printer
                    sessionStorage.setItem("receipt_printer",JSON.stringify(x))
                    this.$forceUpdate()
                })
            }
            let invoice_data = JSON.parse(sessionStorage.getItem("invoice_printer"))
            if(invoice_data){
                this.all_categories.invoices.forEach(x => {
                    x.printer = invoice_data.printer
                    this.invoice_printer = x.printer
                    this.$forceUpdate()
                })
            }
            else{
                this.all_categories.invoices.forEach(x => {
                    x.printer = this.invoice_printer
                    sessionStorage.setItem("invoice_printer",JSON.stringify(x))
                    this.$forceUpdate()
                })
            }
            // let category_data = JSON.parse(sessionStorage.getItem("categories_printer"))
            // if(category_data){
            //     this.all_categories.categories.forEach(x => {
            //         x.printers.forEach(y => {
            //             category_data.forEach(z => {
            //                 if(x._id === z.printers[0].category_id){
            //                     y.printer = z.printers[0].printer
            //                     x.printer = y.printer
            //                 }
            //             })
            //         })
            //     })
            // }
            // else{
            //     let data = []
            //     this.all_categories.categories.forEach(x => {
            //         x.printers.forEach(y => {
            //             y.printer = x.printer
            //             data.push(x)
            //         })
            //     })
            //     sessionStorage.setItem("categories_printer",JSON.stringify(data))
            // }
            this.$forceUpdate()
            this.ajaxCallinprogress = false;
        },
        async getCategories(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get(global.POS_API + "/outlet/get_printers_android")
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        proceed(){
            let data = []
            let receipts = false
            let invoices = false
            let printer = false
            this.all_categories.receipts.forEach(x => {
                if(this.receipt_printer == ' '){
                    receipts = true
                }
                if(!receipts){
                    x.printer = this.receipt_printer
                    sessionStorage.setItem("receipt_printer",JSON.stringify(x))
                }
            })
            if(receipts){
                this.$toasted.global.error("Please Select Printer for Receipt")
            }
            this.all_categories.invoices.forEach(x => {
                if(this.invoice_printer ==  ' '){
                    invoices = true
                }
                if(!invoices){
                    x.printer = this.invoice_printer
                    sessionStorage.setItem("invoice_printer",JSON.stringify(x))
                }
            })
            if(invoices){
                this.$toasted.global.error("Please Select Printer for Invoice")
            }
            this.all_categories.categories.forEach(x => {
                x.printers.forEach(y => {
                    if(x.printer == ''){
                        printer = true
                    }
                    if(!printer){
                        y.printer = x.printer
                        data.push(x)
                    }
                })
            })
            if(printer){
                this.$toasted.global.error("Please Select Printer for All the Categories")
            }
            if(!printer && !receipts && !invoices){
                // sessionStorage.setItem("categories_printer",JSON.stringify(data))
                this.$router.push("/home")
            }
        }
    },
    computed: {
		loggedInUser() {
			return this.$store.getters.loggedInUser
        }
    },
    mounted(){
        this.getAllCategory();
    }
}
</script>
<style scoped>
    .printer-layout{
        background-color: #005dae!important;
        padding: 10px!important;
    }
    .br-10px{
        border-radius: 10px;
    }
    .digi-table-loader div{
        background: #fff !important;
    }
    .digi-table-loader{
        margin: 0 auto !important;
    }
</style>