<template>
  <div>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
      <div class="v-modal-content">
        <div class="v-modal-header">
          <span class="v-modal-dialog-title v-modal-title" v-if="createByProduct">ADD NEW PRODUCT</span>
          <span class="v-modal-dialog-title v-modal-title" v-else>ADD NEW INGREDIENT</span>
        </div>
        <div class="v-modal-body pr-0">
          <div class="product-create-vmodal">
            <div
              class="v-modal-layout pt-3"
              style="padding-right: 18px;max-height: 400px !important; overflow-x: hidden !important;overflow-y: scroll !important"
            >
              <div class="row first-middle-input flex-nowrap">
                <div class="col-lg-3">
                  <div class="profile-left text-center">
                    <div class="profile-image">
                      <my-upload
                        field="img"
                        @crop-success="cropSuccess"
                        @crop-upload-success="cropUploadSuccess"
                        @crop-upload-fail="cropUploadFail"
                        v-model="show"
                        :width="100"
                        langType="en"
                        :height="100"
                        :url="$serverURL + '/products/upload_file'"
                        :params="params"
                        :headers="headers"
                        img-format="png"
                      ></my-upload>
                      <img :src="imgDataUrl" style="width: 90px;" />
                    </div>
                    <div class="mb-4 mt-4 profile-button">
                      <a
                        class="btn btn-secondary btn-smm"
                        @click="toggleShow"
                        v-html="picture_message"
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 px-0">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6
                        style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;"
                        class="pt-3"
                      >Product Information</h6>
                      <div class="d-flex pt-3" id="product_status_crec_proc">
                        <span style="font-weight: 600;">
                          Product Status:
                          <sup>★</sup>
                        </span>
                        <el-switch
                          v-model="productObj.status"
                          active-text="Yes"
                          inactive-text="No"
                          class="pl-8"
                        ></el-switch>
                      </div>
                      <div class="d-flex pt-4" id="variant_availabe_crec_proc">
                        <span style="font-weight: 600;">
                          Variants Available:
                          <sup>★</sup>
                        </span>
                        <el-switch
                          v-model="productObj.variant"
                          active-text="Yes"
                          inactive-text="No"
                          class="pl-4"
                        ></el-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qr-code.svg" class="qr-logo" />
                    <qrcode-vue
                      :value="value"
                      :size="size"
                      background="#cddbeb"
                      level="H"
                      foreground="#00448b"
                      style="margin-top: 18px;"
                    ></qrcode-vue>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group contactGroup">
                    <input
                      ref="search"
                      class="inputContact"
                      name="product_name"
                      v-validate="'required'"
                      :maxlength="pName"
                      required
                      autocomplete="off"
                      v-model="productObj.name"
                      type="text"
                      id="create_product_name"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">
                      Product Name
                      <sup>★</sup>
                    </label>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('product_name')"
                      style="display: inline !important"
                    >{{errors.first('product_name')}}</span>
                    <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{pName - productObj.name.length}} / {{pName}}</p>
                  </div>
                </div>
              </div>
              <div class="row" :class="{'mb-5':!productObj.variant}">
                <div style="width: 100%;margin: 5px 17px">
                  <el-radio v-model="productObj.product_is" label="MENU" id="menu_item">Menu Item</el-radio>
                  <el-radio
                    v-model="productObj.product_is"
                    label="INGREDIENT"
                    id="ingredients"
                  >Ingredients</el-radio>
                  <el-radio v-model="productObj.product_is" label="BOTH" id="both_prod_type">Both</el-radio>
                </div>
              </div>
              <div class="row" v-if="!productObj.variant">
                <div style="width: 100%;margin-left: 17px;">
                  <span class="pr-3 text-secondary font-600">
                    Type
                    <sup>★</sup>:
                  </span>
                  <el-radio-group
                    v-model="productObj.type"
                    v-for="type in getallTypes"
                    :key="type._id"
                    v-validate="'required'"
                    name="productType"
                  >
                    <el-radio :label="type.name" :id="type.name">{{type.name}}</el-radio>
                  </el-radio-group>
                  <span
                    class="invalid-feedback-form text-danger"
                    v-show="errors.has('productType')"
                  >Please Select Type</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group contactGroup" style="margin-top: -5px;">
                    <textarea
                      class="inputContact mt-2"
                      type="text"
                      id="description_create_product"
                      v-model="productObj.description"
                      :maxlength="pDesc"
                      required
                      style="padding: 5px 10px 2px 0px!important;margin-top: 16px!important;"
                    ></textarea>
                    <span class="highlightContact"></span>
                    <label class="labelContact">Description</label>
                    <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{pDesc - productObj.description.length}} / {{pDesc}}</p>
                  </div>
                </div>
              </div>
              <div v-if="!productObj.variant">
                <div class="row pb-3">
                  <div class="col-sm-6">
                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                      <div class="form-group contactGroup" style="margin-top:-24px;width:100%;">
                        <input
                          class="inputContact"
                          type="number"
                          step="any"
                          name="measure"
                          v-validate="'required|max:15'"
                          v-on:keypress="isNumber($event)"
                          min="0"
                          required
                          v-model.number="productObj.measure"
                          id="create_measure"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                          Size or Measure
                          <sup>★</sup>
                        </label>
                        <span
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('measure')"
                          style="display: inline !important"
                        >{{errors.first('measure')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-top:-30px !important;">
                      <label class="fs-14" style="color:#00448b;">Unit of Measurement</label>
                      <v-select
                        label="name"
                        index="_id"
                        class="select-width clearNone optionsHeight vSelectWidth"
                        placeholder="Search & Select Unit Of Measurement"
                        :filterable="true"
                        :options="measurements"
                        v-model="productObj.uom"
                        @search="getAllMeasurements"
                        @search:blur="getAllMeasurements"
                        required
                        v-validate="'required'"
                        name="productUom"
                        id="create_measurement"
                      ></v-select>
                      <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('productUom')"
                      >{{errors.first('productUom')}}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                      <div class="form-group contactGroup" style="margin-top:-24px;width:100%;">
                        <input
                          class="inputContact"
                          type="number"
                          step="any"
                          name="product_price"
                          v-validate="'required|max:8'"
                          v-on:keypress="isNumber($event)"
                          min="0"
                          required
                          v-model.number="productObj.price"
                          id="create_price"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                          Price
                          <sup>★</sup>
                        </label>
                        <span
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('product_price')"
                          style="display: inline !important"
                        >{{errors.first('product_price')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                      <div class="form-group contactGroup" style="margin-top: -24px;width:100%;">
                        <input
                          class="inputContact"
                          type="number"
                          step="any"
                          v-on:keypress="isNumber($event)"
                          min="0"
                          required
                          v-model.number="productObj.quantity"
                          v-validate="'required|max:8'"
                          name="product_quantity"
                          id="create_qty"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                          Quantity
                          <sup>★</sup>
                        </label>
                        <span
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('product_quantity')"
                          style="display: inline !important"
                        >{{errors.first('product_quantity')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group contactGroup" style="margin-top: 0px;">
                      <input
                        name="product_id"
                        v-validate="'required'"
                        :maxlength="pId"
                        required
                        class="inputContact"
                        type="text"
                        v-model.trim="productObj.product_id"
                        id="create_product_id"
                      />
                      <span class="highlightContact"></span>
                      <span class="barContact"></span>
                      <label class="labelContact">
                        Product ID
                        <sup>★</sup>
                      </label>
                      <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('product_id')"
                        style="display: inline !important"
                      >{{errors.first('product_id')}}</span>
                      <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                      >{{pId - productObj.product_id.length}} / {{pId}}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group contactGroup" style="margin-top: 0px;">
                      <input
                        class="inputContact"
                        type="text"
                        v-validate="'required|alpha_num'"
                        required
                        v-model.trim="productObj.stock_keeping_unit"
                        name="product_sku"
                        id="create_product_sku"
                        :maxlength="sku"
                      />
                      <span class="highlightContact"></span>
                      <span class="barContact"></span>
                      <label class="labelContact">
                        Stock Keeping Unit (SKU)
                        <sup>★</sup>
                      </label>
                      <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('product_sku')"
                        style="display: inline !important"
                      >{{errors.first('product_sku')}}</span>
                      <p
                        class="pull-right text-right text-grey font-600 fs-12 pt-1"
                      >{{sku - productObj.stock_keeping_unit.length}} / {{sku}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="fs-14" style="color:#00448b;">
                      Category
                      <sup>★</sup>
                    </label>
                    <v-select
                      name="category"
                      label="name"
                      index="_id"
                      class="select-width clearNone optionsHeight vSelectWidth"
                      placeholder="Search & Select Category"
                      :filterable="true"
                      :options="filter_data.categories"
                      v-model="productObj.category_id"
                      @search="get_categories_for_products"
                      @search:blur="get_categories_for_products"
                      @change="changeCategory()"
                      v-validate="'required'"
                      required
                      id="create_select_category"
                    >
                      <span slot="no-options">
                        No Matches Found. Please Create New Category
                        <a
                          class="text-secondary p-0 m-0 w-0"
                          style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;"
                          @click="createcategory()"
                        >Here</a>
                      </span>
                    </v-select>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('category')"
                    >{{errors.first('category')}}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="fs-14" style="color:#00448b;">
                      Sub Category
                      <sup>★</sup>
                    </label>
                    <v-select
                      v-if="productObj.category_id == ''"
                      label="sub_category_name"
                      id="sub_category_crec_prod"
                      class="select-width clearNone optionsHeight"
                      placeholder="Search & Select Sub Category"
                      :filterable="true"
                      required
                    >
                      <span slot="no-options">Please Select Category</span>
                    </v-select>
                    <v-select
                      v-else
                      name="subcategory"
                      label="sub_category_name"
                      id="sub_category_crec_prod"
                      index="sub_category_id"
                      class="select-width clearNone optionsHeight vSelectWidth"
                      placeholder="Search & Select Sub Category"
                      :filterable="true"
                      :options="filter_data.subcategories"
                      v-model="productObj.sub_category_id"
                      @search="getAllSubCategories"
                      @search:blur="getAllSubCategories"
                      @change="changeSubCatgory()"
                      v-validate="'required'"
                      required
                    ></v-select>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('subcategory')"
                    >{{errors.first('subcategory')}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="fs-14" style="color:#00448b;">Product Type</label>
                    <v-select
                      v-if="productObj.sub_category_id == ''"
                      label="name"
                      id="product_type_crec_prod"
                      class="select-width clearNone optionsHeight"
                      placeholder="Search & Select Product Type"
                      :filterable="true"
                      required
                    >
                      <span slot="no-options">Please Select Sub Category</span>
                    </v-select>
                    <v-select
                      v-else
                      label="name"
                      id="product_type_crec_prod"
                      index="_id"
                      class="select-width clearNone optionsHeight vSelectWidth"
                      placeholder="Search & Select Product Type"
                      :filterable="true"
                      :options="filter_data.ProductType"
                      v-model="productObj.product_type_id"
                      @search="getAllProductTypes"
                      @search:blur="getAllProductTypes"
                      required
                    ></v-select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="fs-14" style="color:#00448b;">Brand</label>
                    <v-select
                      label="name"
                      index="_id"
                      id="brand_crec_prod"
                      placeholder="Search & Select Brand"
                      :max="pBrand"
                      class="select-width clearNone optionsHeight vSelectWidth"
                      :filterable="true"
                      :options="filter_data.brands"
                      v-model="productObj.brand"
                      @search="get_brands_for_products"
                      @search:blur="get_brands_for_products"
                    >
                      <span slot="no-options">
                        No Matches Found. Please Create New Brand
                        <a
                          class="text-secondary p-0 m-0 w-0"
                          style="text-decoration:none;text-transform: none;min-width: 0px;height: 2rem;line-height: 2rem;"
                          @click="showbrandproduct()"
                        >Here</a>
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group contactGroup">
                    <label class="fs-14" style="color:#00448b;">Tags</label>
                    <vue-tags-input
                      v-model="productObj.tag"
                      name="tags"
                      id="tags"
                      :maxlength="pTags"
                      :tags="productObj.tags"
                      @tags-changed="newTags => tags = newTags"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-4" v-if="productObj.variants.length">
                <h6
                  class="pt-5 pl-4"
                  style="font-weight: 600;color: #00448b;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;width: 100%;"
                >Variants</h6>
              </div>
              <div
                class="row"
                v-for="(alternateVariants, index) in productObj.variants"
                :key="index"
              >
                <div class="col-sm-4">
                  <div class="form-group contactGroup">
                    <input
                      :key="index"
                      class="inputContact"
                      v-validate="'required'"
                      :name="'variant_name' + index"
                      id="addVariant"
                      :maxlength="pVname"
                      type="text"
                      v-model="alternateVariants.variant_name"
                      required
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">
                      Name
                      <sup>★</sup>
                    </label>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('variant_name' + index)"
                      style="display: inline !important"
                    >Required</span>
                    <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{pVname - alternateVariants.variant_name.length}} / {{pVname}}</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group contactGroup">
                    <input
                      :name="'product_variant_id' + index"
                      v-validate="'required'"
                      :maxlength="pVId"
                      required
                      class="inputContact"
                      type="text"
                      v-model.trim="alternateVariants.product_id"
                      id="create_product_variant_product_id"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">
                      Product ID
                      <sup>★</sup>
                    </label>
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('product_variant_id'+ index)"
                      style="display: inline !important"
                    >Required</span>
                    <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{pVId - alternateVariants.product_id.length}} / {{pVId}}</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group contactGroup" style="margin-top: 0px;width:100%;">
                    <input
                      class="inputContact"
                      type="text"
                      :name="'variant_sku' + index.toString()"
                      data-vv-as="SKU"
                      v-validate="'required|alpha_num'"
                      :maxlength="vsku"
                      required
                      v-model.trim="alternateVariants.stock_keeping_unit"
                      id="create_product_variant_sku"
                    />
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">
                      Stock Keeping Unit (SKU)
                      <sup>★</sup>
                    </label>
                    <!-- <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('variant_sku'+index.toString())"
                      style="display: inline !important"
                    >{{errors.first('variant_sku'+index.toString())}}</span>-->
                    <span
                      class="invalid-feedback-form text-danger fs-13"
                      v-show="errors.has('variant_sku'+index.toString())"
                      style="display: inline !important"
                    >Required & Valid</span>
                    <p
                      class="pull-right text-right text-grey font-600 fs-12 pt-1"
                    >{{vsku - alternateVariants.stock_keeping_unit.length}} / {{vsku}}</p>
                  </div>
                </div>
                <div class="row" style="width: 100%;margin-left: 0px;width:100%;">
                  <div class="col-sm-4">
                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                      <div class="form-group contactGroup" style="margin-top:-24px;width:100%;">
                        <input
                          class="inputContact"
                          type="number"
                          step="any"
                          :name="'vmeasure'+index"
                          v-validate="'required|max:15'"
                          v-on:keypress="isNumber($event)"
                          min="0"
                          required
                          v-model.number="alternateVariants.measure"
                          id="create_product_variant_measure"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                          Size or Measure
                          <sup>★</sup>
                        </label>
                        <span
                          v-if="alternateVariants.measure.length < 1"
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('vmeasure'+index)"
                          style="display: inline !important"
                        >Required</span>
                        <span
                          v-else
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('vmeasure'+index)"
                          style="display: inline !important"
                        >Max 15 Digits</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group" style="margin-top:-30px !important;">
                      <label class="fs-14" style="color:#00448b;">Unit of Measurement</label>
                      <v-select
                        label="name"
                        index="_id"
                        class="select-width clearNone optionsHeight vSelectWidth"
                        placeholder="Search & Select"
                        :filterable="true"
                        :options="measurements"
                        v-model="alternateVariants.uom"
                        @search="getAllMeasurements"
                        @search:blur="getAllMeasurements"
                        required
                        :name="'vuom'+index"
                        v-validate="'required'"
                        data-vv-as="Unit of Measurement"
                        id="create_product_variant_um"
                      ></v-select>
                      <span
                        class="invalid-feedback-form text-danger fs-13"
                        v-show="errors.has('vuom'+index)"
                      >Required</span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                      <div class="form-group contactGroup" style="margin-top:-23px;width:100%;">
                        <input
                          :key="index"
                          class="inputContact"
                          v-validate="'required|max:8'"
                          v-on:keypress="isNumber($event)"
                          :name="'variant_quantity' + index"
                          id="addVariant"
                          type="number"
                          min="0"
                          step="any"
                          required
                          v-model.number="alternateVariants.variant_quantity"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                          Quantity
                          <sup>★</sup>
                        </label>
                        <span
                          class="invalid-feedback-form text-danger fs-13"
                          v-if="alternateVariants.variant_quantity.length == 0"
                          v-show="errors.has('variant_quantity' + index)"
                          style="display: inline !important"
                        >Required</span>
                        <span
                          v-else
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('variant_quantity' + index)"
                          style="display: inline !important"
                        >Max. 8 Digits</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row justify-content-between"
                  style="width: 100%;margin-left: 0px;width:100%;"
                >
                  <div class="col-sm-4 pt-4">
                    <div class="input-group pt-2 mb-3" style="display: -webkit-inline-box;">
                      <div class="form-group contactGroup" style="margin-top:-23px;width:100%;">
                        <input
                          :key="index"
                          class="inputContact"
                          v-validate="'required|max:8'"
                          v-on:keypress="isNumber($event)"
                          :name="'variant_price' + index"
                          id="addVariantprice"
                          type="number"
                          min="0"
                          step="any"
                          required
                          v-model.number="alternateVariants.variant_price"
                        />
                        <span class="highlightContact"></span>
                        <span class="barContact"></span>
                        <label class="labelContact">
                          Price
                          <sup>★</sup>
                        </label>
                        <span
                          class="invalid-feedback-form text-danger fs-13"
                          v-if="alternateVariants.variant_price.length == 0"
                          v-show="errors.has('variant_price' + index)"
                          style="display: inline !important"
                        >Required</span>
                        <span
                          v-else
                          class="invalid-feedback-form text-danger fs-13"
                          v-show="errors.has('variant_price' + index)"
                          style="display: inline !important"
                        >Max. 8 Digits</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 pt-8" v-if="!index == 0">
                    <el-tooltip class="item" effect="dark" content="Delete Variant" placement="top">
                      <button class="btn btn-smm btn-outline-danger" @click="removeVariants(index)">
                        <i
                          class="icon icon-delete mr-1"
                          style="width: 18px;height: 18px;line-height: 18px;font-size: 16px;"
                        ></i>
                        Delete
                      </button>
                    </el-tooltip>
                  </div>
                </div>
                <div style="width: 100%;margin-left: 17px;padding-top:5px;">
                  <span class="pr-3 text-secondary">
                    Type
                    <sup>★</sup>:
                  </span>
                  <el-radio-group
                    v-model="alternateVariants.type"
                    v-for="type in getallTypes"
                    :key="type._id"
                    v-validate="'required'"
                    :name="'type'+index"
                  >
                    <el-radio :label="type.name" :id="type.name+index">{{type.name}}</el-radio>
                  </el-radio-group>
                  <span
                    class="invalid-feedback-form text-danger"
                    v-show="errors.has('type'+index)"
                  >Please Select Type</span>
                </div>
              </div>
              <div class="row" v-if="productObj.variants.length">
                <div class="col-sm-6">
                  <a
                    class="nav-link text-secondary btn btn-smm"
                    @click="addVariants()"
                    href="javascript:;"
                    style="height: 24px;padding: 0 12px 0px 0px !important;line-height: 18px !important;"
                  >
                    <i
                      class="icon icon-plus-circle"
                      style="width: 18px !important;height: 18px !important;line-height: 18px !important;font-size: 18px !important;margin-right: 3px;color: #00448b;"
                    ></i>
                    <span>Add Variants</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="v-modal-footer text-center pt-5">
              <a
                class="btn btn-link btn-smm btn-no-border-blue text-uppercase ml-1"
                @click="hidecreateproduct()"
              >CANCEL</a>
              <a class="btn btn-secondary btn-smm text-uppercase" @click="validate_product()">SAVE</a>
            </div>
            <sweet-modal ref="success_modal" icon="success">{{modal_msg}}</sweet-modal>
            <sweet-modal ref="warning_modal" icon="warning">{{modal_msg}}</sweet-modal>
          </div>
        </div>
      </div>
    </modal>
    <div>
      <create-brand modal_brand="brand-create" @close="hideCreateBrand"></create-brand>
    </div>
    <div>
      <create-category
        @createCategory="createCategory"
        :bool="categoryBool"
        :category="category"
        modal_category="create-category"
      ></create-category>
    </div>
    <sweet-modal ref="success_modal_create" icon="success">{{modal_msg}}</sweet-modal>
    <sweet-modal ref="warning_modal_create" icon="warning">{{modal_msg}}</sweet-modal>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import { SweetModal } from 'sweet-modal-vue'
import myUpload from 'vue-image-crop-upload'
import VueTagsInput from '@johmun/vue-tags-input'

import CreateBrand from './CreateBrand'
import CreateCategory from './CreateCategory'

import BrandServices from './mixins/brands'
import productServices from './mixins/products'
import CategoryServices from './mixins/categories'
import { EventBus } from './eventBus/nav-bar-event'

export default {
  props: ['modal_name', 'createByProduct'],
  data() {
    return {
      imgUrl: '',
      tag: '',
      tags: [],
      autocompleteItems: [
        {
          text: 'Spain'
        },
        {
          text: 'France'
        },
        {
          text: 'USA'
        },
        {
          text: 'Germany'
        },
        {
          text: 'China'
        }
      ],
      tax: [],
      variants: [],
      dialogImageUrl: '',
      dialogVisible: false,
      isCompanyTimeline: true,
      isCompanyRelationship: false,
      isCompanygallery: false,
      show: false,
      imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg', // the datebase64 url of created image
      currency: '$',
      picture_message: 'Upload Image',
      name: '',
      pName: 100,
      pDesc: 1000,
      pId: 16,
      pVId: 16,
      sku: 30,
      vsku: 30,
      pBrand: 30,
      pCategory: 45,
      pTags: 30,
      pQuantity: 10,
      pPrice: 10,
      pVname: 50,
      singleBrand: null,
      getallTypes: [],
      value: 'https://example.com',
      size: 100,
      measurements: []
    }
  },
  components: {
    'my-upload': myUpload,
    VueTagsInput,
    SweetModal,
    CreateBrand,
    CreateCategory,
    QrcodeVue
  },
  mixins: [productServices, BrandServices, CategoryServices],
  methods: {
    isNumber: function(evt, index) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeCategory() {
      this.productObj.sub_category_id = ''
      this.getAllSubCategories()
    },
    changeSubCatgory() {
      this.productObj.product_type_id = ''
      this.getAllProductTypes()
    },
    hideCreateBrand() {
      this.$modal.hide('brand-create')
      this.$modal.show(this.modal_name)
    },
    createcategory() {
      this.$modal.show('create-category')
      this.$modal.hide(this.modal_name)
    },
    showbrandproduct() {
      this.$modal.show('brand-create')
      this.$modal.hide(this.modal_name)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    toggleShow() {
      this.show = !this.show
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl
      this.picture_message = 'Change Image'
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      this.productObj.image = jsonData
      this.picture_message = 'Change Image'
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      this.imgDataUrl = 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg'
    },
    hidecreateproduct() {
      if (
        this.productObj.name != '' ||
        this.productObj.quantity != '' ||
        this.productObj.price != '' ||
        this.productObj.product_id != '' ||
        this.productObj.stock_keeping_unit != '' ||
        this.productObj.category_id != '' ||
        this.productObj.sub_category_id != ''
      ) {
        this.$swal({
          title: '',
          text: 'Do you want to discard?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#00448b',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(result => {
          if (result.value) {
            this.hidecreateproductConfirm()
          }
        })
      } else {
        this.hidecreateproductConfirm()
      }
    },
    hidecreateproductConfirm() {
      this.$modal.hide(this.modal_name)
      this.productObj.variants = []
      this.imgDataUrl = 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg'
      this.productObj = {
        name: '',
        description: '',
        quantity: 0,
        price: 0,
        currency: '',
        product_id: '',
        product_is: 'BOTH',
        stock_keeping_unit: '',
        brand: '',
        category_id: '',
        sub_category_id: '',
        product_type_id: '',
        status: true,
        tags: [],
        tax: [],
        variants: [],
        type: '',
        total_tax: 0,
        variant: false,
        image: '',
        gallery: []
      }
      this.skuexists = false
      this.variant_name = ''
      this.variant_quantity = ''
      this.variant_price = ''
      this.tax_name = ''
      this.tax_percentage = ''
    },
    companyTimeline() {
      this.isCompanyTimeline = true
      this.isCompanyRelationship = false
      this.isCompanygallery = false
    },
    relationshipPage() {
      this.isCompanyRelationship = true
      this.isCompanyTimeline = false
      this.isCompanygallery = false
    },
    galleryPage() {
      this.isCompanyRelationship = false
      this.isCompanyTimeline = false
      this.isCompanygallery = true
    },
    async updateTick() {
      this.$nextTick(() => {
        this.$validator.reset()
      })
    }
  },
  watch: {
    // singleBrand(newBrand, oldBrand) {
    //   this.productObj.brand_id= newBrand
    // },
    singleCategory(newCategory, oldCategory) {
      this.productObj.category_id = newCategory
    },
    singleSubCategory(newSubCategory, oldSubCategory) {
      this.productObj.sub_category_id = newSubCategory
    },
    singleProductType(newProductType, oldProductType) {
      this.productObj.product_type_id = newProductType
    },
    'productObj.variant'(newValue, oldValue) {
      this.errors.clear()
      if (newValue) {
        this.productObj.variants.push({
          variant_name: '',
          variant_price: 0,
          variant_quantity: '',
          type: '',
          status: true,
          product_id: '',
          stock_keeping_unit: '',
          measure: '',
          uom: ''
        })
      } else {
        this.productObj.variants = []
      }
      this.updateTick()
    }
  },
  created() {
    this.productObj.status = true
    this.imgDataUrl = 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg'
    // NOTE: DO Not Comment
    // NOTE WRITER: Abhishek Modi
    EventBus.$on('createdCategoryId', newId => {
      this.getCategory(newId)
    })
    EventBus.$on('createdBrandId', async newId => {
      try {
        let res = await this.brandsGetId(newId)
        this.singleBrand = res.message
      } catch (reason) {
        // TODO: handle reason
      }
    })
    this.skuexists = false
    EventBus.$on('categoryCreated', res => {
      this.$modal.show(this.modal_name)
    })
    this.get_brands_for_products()
    this.get_categories_for_products()
    this.getAllSubCategories()
    this.getAllProductTypes()
    this.getAllTypes()
    this.getAllMeasurements()
  },
  destroyed() {
    this.imgDataUrl = 'https://cdn.digicollect.com/cdn/pos/images-new-theme/default-product.svg'
    this.skuexists = false
    EventBus.$off('createdCategoryId')
    EventBus.$off('createdBrandId')
    EventBus.$off('categoryCreated')
  }
}
</script>
<style>
.closeTax {
  position: absolute;
  right: 12px;
}
.btn.dropdown-toggle.dropdown-currency {
  min-width: 2.8rem;
  background: transparent;
  border-bottom: 1px solid #c6c6c6 !important;
  height: 32px;
  padding: 0 2px 0 3px;
}
.product-create-vmodal .v-modal-layout {
  max-height: 420px !important;
  padding-right: 18px;
}
.v-select.single.searchable
  .dropdown-toggle.clearfix
  input[type='search'].form-control::placeholder {
  color: rgb(153, 143, 143) !important;
  opacity: 1;
}
.vSelectWidth.v-select.single .selected-tag {
  width: 250px !important;
}
.qr-logo {
  position: absolute;
  margin-top: 28px;
  margin-left: -18px;
  width: 40px;
}
</style>
