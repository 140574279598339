<template>
  <div class="page-layout blank category-manage-old">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row flex-nowrap">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="list-title d-flex" style="font-weight: 500;">
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasPrevious()"
                :to="{ path: $routerHistory.previous().path }"
              >
                <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-left"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <router-link
                style="color: transparent!important;"
                :disabled="!$routerHistory.hasForward()"
                :to="{ path: $routerHistory.next().path }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Click to Go Forward"
                  placement="top"
                >
                  <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                    <i class="icons icon-arrow-right"></i>
                  </button>
                </el-tooltip>
              </router-link>
              <a class="btn btn-secondary btn-smm" @click="showCreateCategory()">+ Add New Category</a>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading d-xl-inline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  :maxlength="searchcustomer"
                  type="text"
                  class="form-control textremove holder"
                  placeholder="Search..."
                  v-model="search"
                  @input="manageCategories('search')"
                />
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
            <div class="pull-right" v-if="totalCategories">
              <span class="page-info px-2 hidden-md-down">{{start}} - {{end}} of {{totalCategories}}</span>
              <el-tooltip class="item" effect="dark" content="Previous" placement="top">
                <button
                  :disabled="count<=0"
                  :class="{'disabled':ajaxCallInProgress}"
                  v-on:click="manageCategories('prev')"
                  type="button"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-left"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Next" placement="top">
                <button
                  :disabled="end >= totalCategories"
                  :class="{'disabled':ajaxCallInProgress}"
                  type="button"
                  v-on:click="manageCategories('next')"
                  class="btn btn-icon d-none d-md-inline el-tooltip item"
                >
                  <i class="icon icon-chevron-right"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </h2>
      <div v-if="showIllustrator" class="row no-orders-row">
        <div class="col-lg-12 text-center">
          <div class="noorders">
            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_inventory.svg" style="width: 26%;margin-top:20px;" />
            <p class="text-center text-secondary fs-28 font-600 pt-5">No Categories Yet!!!</p>
          </div>
        </div>
      </div>
      <div v-if="!showIllustrator">
        <div class="category-manage-info">Categories</div>
        <div
          class="page-layout carded left-sidebar"
          style="background-color: #00448b!important;padding: 10px!important;"
        >
          <div class="page-content-wrapper">
            <div class="page-content">
              <div class="demo-content">
                <table class="table table-scroll">
                  <thead>
                    <tr>
                      <th style="width: 1%; flex: 0 0 70px !important;"></th>
                      <th>
                        <span v-on:click="setPasskey('name')" class="cursor-th">
                          <span
                            v-if="passed_key.key=='name' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'name'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          CATEGORY NAME
                        </span>
                      </th>
                      <th>
                        <span>
                          <i
                            class="icon-arrow-down"
                            style="font-size: 16px!important;visibility: hidden;"
                          ></i>
                        </span>
                        Description
                      </th>
                      <th>No. OF SUB CATEGORIES</th>
                      <th>
                        <span v-on:click="setPasskey('status')" class="cursor-th">
                          <span
                            v-if="passed_key.key=='status' && passed_key.value== -1"
                            :class="{'hide-arrow': passed_key.key != 'status'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-up" style="font-size: 16px !important;"></i>
                          </span>
                          <span
                            style="font-size: 16px !important;"
                            v-else
                            :class="{'hide-arrow': passed_key.key != 'status'}"
                            class="sorting-icon"
                          >
                            <i class="icon-arrow-down" style="font-size: 16px !important;"></i>
                          </span>
                          STATUS
                        </span>
                      </th>
                      <th>
                        <span>
                          <i
                            class="icon-arrow-down"
                            style="font-size: 16px!important;visibility: hidden;"
                          ></i>
                        </span>
                        ADMIN
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="ajaxCallInProgress">
                      <td
                        colspan="7"
                        class="text-center justify-content-center"
                        style="0.6rem 1.2rem!important;"
                      >
                        <div class="digi-table-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-if="categories.length==0 && !ajaxCallInProgress"
                      style="background-color: transparent !important;border-bottom: none !important;"
                    >
                      <td v-if="search" colspan="11" class="text-center justify-content-center">
                        <strong style="display:block;padding-top:38px;">
                          <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_search_result.svg" style="margin-top: 300px;" />
                          <br />
                          <p
                            class="text-secondary font-20"
                            style="font-size: 22px; font-weight: 600;"
                          >No Search Results Found</p>
                        </strong>
                      </td>
                    </tr>
                    <tr v-for="category in categories" :key="category.category_id">
                      <td style="flex: 0 1 0% !important;">
                        <el-popover
                          placement="right"
                          class="productAvatar pointer"
                          width="124"
                          trigger="hover"
                        >
                          <div v-if="category.image_url" class="img-icon">
                            <img :src="category.image_url" style="border-radius:18px;" />
                          </div>
                          <div v-else>
                            <avatar
                              :username="category.category_name.replace(/ .*/,'')"
                              :src="category.image_url"
                              :size="100"
                              :rounded="true"
                              color="#fff"
                            ></avatar>
                          </div>
                          <a
                            slot="reference"
                            class="lightBlue moreMembers"
                            style="text-decoration: none!important;"
                          >
                            <avatar
                              :username="category.category_name.replace(/ .*/,'')"
                              :src="category.image_url"
                              :size="40"
                              :rounded="true"
                              color="#fff"
                            ></avatar>
                          </a>
                        </el-popover>
                      </td>
                      <td
                        class="font-weight-bold"
                        style="width: 180px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;"
                        :title="category.category_name"
                      >{{category.category_name}}</td>
                      <td
                        class="text-justify text-truncate d-block"
                        style="width: 200px !important;padding: 0px 30px 0px 43px !important;"
                        :title="category.description"
                      >{{ category.description || '-' }}</td>
                      <td style="padding: 0px 31px!important;" class="text-center">
                        <el-tooltip content="View Sub Category" placement="top">
                          <div
                            class="chip chipproducts"
                            style="width:140px!important;"
                            @click="showSubCategory(category.category_id)"
                            :class="{'clickDisabled':category.no_of_sub_categories == 0}"
                          >
                            <div
                              class="circle-profile-photo prj-total-users"
                            >{{ category.no_of_sub_categories || 0 }}</div>
                            <span v-if="category.no_of_sub_categories  > 1">Sub Category</span>
                            <span v-else>Sub Category</span>
                          </div>
                        </el-tooltip>

                        <!-- <el-tooltip content="View Sub Category" placement="top">
                          <span
                            :class="{'clickDisabled':category.no_of_sub_categories == 0}"
                            style="padding: 0px 50px!important;cursor:pointer !important;"
                            class="pointer text-secondary font-weight-bold fs-14 text-center"
                            @click="showSubCategory(category.category_id)"
                          >{{ category.no_of_sub_categories }}</span>
                        </el-tooltip>-->
                      </td>
                      <td
                        style="padding: 0px 31px!important;"
                        v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                      >
                        <el-switch
                          v-model="category.status"
                          @change="changestatus(category.status,category.category_id)"
                        ></el-switch>
                      </td>
                      <td style="padding: 0px 12px!important;" v-else>
                        <el-switch v-model="category.status"></el-switch>
                      </td>
                      <td
                        style="padding:0px 32px !important;"
                        v-if="loggedInUser.actions.inventory&&loggedInUser.actions.inventory.write"
                        class
                      >
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                          <button
                            class="btn btn-icon companyactions actionicons"
                            @click="showEditCategory(category.category_id)"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-pencil"></i>
                          </button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                          <button
                            type="button"
                            class="btn btn-icon actionicons"
                            @click="confirmDelete(category)"
                            style="padding: 0.4rem .2rem;"
                          >
                            <i class="icon icon-delete"></i>
                          </button>
                        </el-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="showIllustrator" class="row no-orders-row">
                  <div class="col-lg-12 text-center">
                    <div class="noorders">
                      <img
                        src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-orders-yet.svg"
                        style="height: 40%;margin-top:20px;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <edit-category
        edit_category="edit-category"
        :bool="categoryBool"
        :categoryId="categoryId"
        @editSaving="manageCategories()"
      ></edit-category>
      <create-category
        modal_category="create-category"
        :bool="categoryBool"
        @saving="manageCategories()"
      ></create-category>
    </div>
    <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
    <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    <sub-category-information modal_name="subcategory-information" :categoryId="categoryId"></sub-category-information>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { EventBus } from './eventBus/nav-bar-event.js'
import CreateCategory from './CreateCategory'
import EditCategory from './EditCategory'
import { SweetModal } from 'sweet-modal-vue'
import CategoryServices from './mixins/categories.js'
import Avatar from 'vue-avatar'
import SubCategoryInformation from './subCategoryInformation'
export default {
  data() {
    return {
      edit_category: {},
      filteredProducts: {},
      searchcustomer: 100,
      dialogImageUrl: '',
      showProductCategory: '',
      dialogVisible: false,
      Variants: false,
      value10: [],
      checked: true,
      value2: false,
      isCompanyTimeline: true,
      isCompanyRelationship: false,
      isCompanygallery: false,
      show: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      imgDataUrl: '', // the datebase64 url of created image
      categoryId: '',
      ajaxCallInProgress: false,
      productInfo: false,
      showProduct: '',
      getProductDetailsByID: ''
    }
  },
  mixins: [CategoryServices],
  components: {
    CreateCategory,
    EditCategory,
    draggable,
    Avatar,
    SweetModal,
    SubCategoryInformation
  },
  methods: {
    showSubCategory(categoryId) {
      this.categoryId = categoryId
      EventBus.$emit('subCategoryByCategory', categoryId)
      this.$modal.show('subcategory-information')
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    toggleShow() {
      this.show = !this.show
    },
    showCreateCategory() {
      this.$modal.show('create-category')
    },
    showEditCategory(categoryId) {
      EventBus.$emit('getCategoryTypeById', categoryId)
      this.showEditCategoryModal()
    },
    showEditCategoryModal() {
      this.$modal.show('edit-category')
    },
    getProductsFiltered: function(category) {
      this.filteredProducts[category.id] = this.getproducts({
        key: 'category',
        value: category.name
      })
      return this.filteredProducts[category.id]
    },
    changeDisplayOrder(event) {
      if (event.moved) {
        this.updateDisplayOrder(event.moved.element._id, event.moved.newIndex)
      }
    },
    formatPrice(value) {
      if (value) {
        let val = value.toString().replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else return 0
    }
  },
  computed: {
    showNoCategory() {
      let temp = this.categories.filter(value => {
        return value.name
      })
      return temp.length
    }
  },
  mounted() {
    EventBus.$emit('customerdetailsLoad', false)
    EventBus.$emit('settingsLoad', false)
    EventBus.$emit('reportsLoad', false)
  },
  created() {
    this.manageCategories('init')
  },
  destroyed() {}
}
</script>
<style>
.icon-down {
  color: #00448b !important;
  cursor: pointer;
}
.cursor-th {
  cursor: pointer;
}
.hide-arrow {
  visibility: hidden;
}
.category-manage-info {
  font-size: 12px;
  color: rgb(48, 48, 49);
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
  border: 2px solid rgb(13, 132, 242);
  border-radius: 18px;
  background-color: rgb(255, 255, 255);
  /* top: 52px;
  left: 0px;
  right: 0px; */
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  /* position: absolute; */
  z-index: 9;
  text-transform: uppercase;
}
th:hover .sorting-icon {
  visibility: visible !important;
  cursor: pointer;
}

td.icon-one {
  display: none !important;
}
.category-manage-old .sorting-icon {
  cursor: pointer;
}
.table-striped tbody tr:nth-of-type(odd) {
  border-radius: 3px !important;
  background-color: #f5f6f9 !important;
}
.category-manage-old .table td {
  padding: 1rem 2rem !important;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  -moz-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}
.chip.chipproducts {
  width: 115px !important;
}
.avatar {
  background: #00448b;
}
.avatar {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  line-height: 4rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  color: #fff;
  background-color: #c3c3ba;
}
.avatar.avatar-trans {
  background-color: transparent !important;
}
.font-weight-bold.product-name {
  font-weight: bold !important;
  color: #00448b;
}
.table .dragable-table-category td {
  cursor: grabbing;
}
.page-layout.carded {
  overflow: hidden !important;
}
.btn-icon:disabled {
  background: transparent !important;
}
.img-icon img {
  width: 100px !important;
}
.clickDisabled {
  pointer-events: none !important;
}
</style>

