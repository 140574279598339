<template>
    <div >
    <div id="lazy-load-orders" @scroll="scrollEnd" class="col-lg-12 text-center">
        <div v-if="ajaxCallInProgress" class="digi-table-loader d-flex justify-content-center"
            style="margin: 0 auto;margin-top: 50px !important;">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="row" v-else-if="orderCardView.length == 0 && !ajaxCallInProgress && searchdata == ''">
            <div class="col-lg-12 text-center">
                <div class="noorders">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-orders-yet.svg"
                        style="height: 40%;margin-top:60px;width:30% !important;" />
                </div>
            </div>
        </div>
        <div v-else-if="searchdata  && orderCardView.length == 0 && !ajaxCallInProgress" class="row no-orders-row">
            <div class="col-lg-12 text-center">
                <div class="noorders">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-results-to.svg"
                        style="height: 80%;margin-top:80px;" />
                </div>
            </div>
        </div>
        <div class="pl-3" v-if="orderCardView.length && !ajaxCallInProgress">
            <div class="card-manage-order pl-6 pb-5" >
                <div class="card task" v-for="order in orderCardView" :key="order._id"
                :class="{OnHoldClass : order.isOrderOnHold}">
                    <a data-toggle="collapse" role="button" aria-expanded="false" :href="'.' + order._id"
                        :aria-controls="order._id"
                        class="card-title task-title taskToggle dropdown-toggle pl-3 pointer pb-2">
                        <div class="pr-3 row no-gutters align-items-center ml-1 justify-content-between">
                            <div class="col">
                                <div id="ribbon-container" class="row no-gutters align-items-center">
                                    <a href="#" id="ribbon" class="fs-13 whiteSpace" v-if="order.table_number">Table.No
                                        - {{order.table_number}}</a>
                                    <a href="#" id="ribbon" class="fs-13 whiteSpace"
                                        v-else>{{ order.order_type ||'Take Away'}}</a>
                                </div>
                            </div>
                            <div>
                                <i class="icon icon-clock"></i>
                                <span class="ml-2 text-default fs-13 orderTime">{{order.last_modified_time}}</span>
                            </div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-3">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Order ID</span>
                                </div>
                            </div>
                            <div class="defaultBlue fs-13 font-weight-bold">{{order.order_id}}</div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">S.No</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.order_number}}</div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Customer Name</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.customer_name}}</div>
                        </div>
                    </a>
                    <div id="collapseOverDue" class="card-body taskDropdown collapse show"
                        v-if="loggedInUser.role != 'default_order_receiver'" :class="order._id">
                        <div class="row no-gutters align-items-center justify-content-between">
                            <div class="stepwizard">
                                <div class="stepwizard-row setup-panel">
                                    <div class="stepwizard-step">
                                        <div class="wizardSteps pointer"
                                            :class="{ 'greenCircle': order.order_status.fire == 1,'redCircle': order.order_status.fire == 0,'orangeCircle': order.order_status.fire == 2}">
                                            <a :class="{ 'greenCircle': order.order_status.fire == 1,'redCircle': order.order_status.fire == 0,'orangeCircle': order.order_status.fire == 2}"
                                                class="btn-circle">S</a>
                                        </div>
                                    </div>
                                    <div class="stepwizard-step" style="left:25% !important;width: 69%!important;">
                                        <div class="wizardSteps pointer"
                                            :class="{ 'greenCircle': order.order_status.prepared == 1,'redCircle': order.order_status.prepared == 0,'orangeCircle': order.order_status.prepared == 2}">
                                            <a :class="{ 'greenCircle': order.order_status.prepared == 1,'redCircle': order.order_status.prepared == 0,'orangeCircle': order.order_status.prepared == 2}"
                                                class="btn-circle">F</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="text-align: left;" v-if="loggedInUser.role != 'default_order_receiver'">
                            <div class="defaultBlue font-weight-bold pt-2 fs-14 pb-1" v-if="order.order_note != ''">Note
                            </div>
                            <div class="fs-14">
                                <span class="text-danger">{{order.order_note}}</span>
                            </div>
                        </div>
                        <div class="manage-action-swtich" v-if="order.showPrepared"
                            style="border-radius: 5px;background-image: linear-gradient(284deg, #00448b, #0367cc);margin-top: 16px;padding: 10px;">
                            <div class="row">
                                <div class="col-md-6 d-flex" v-if="order.showPrepared">
                                    <span class="pr-4 text-white font-600 whiteSpace">Finish Order</span>
                                    <span v-if="order.canPrepare">
                                        <el-switch style="display: block" :disabled="!order.canPrepare"
                                            v-model="order.isPrepared" 
                                            @change="prepareOrder(order.isPrepared,order._id)">
                                        </el-switch>
                                    </span>
                                    <span v-else>
                                        <el-switch style="display: block" v-model="order.isPrepared"
                                            @change="prepareOrder(order.isPrepared,order._id)">
                                        </el-switch>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center pt-3">
                            <button class="btn btn-smm btn-secondary" @click="showViewOrder(order._id)">View
                                Order</button>
                        </div>
                    </div>
                    <div v-if="loggedInUser.role == 'default_order_receiver'" class="table-line">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th class="text-left" style="color:#005dad!important;">Items</th>
                                    <th class="text-left" style="color:#303031!important;">Finishing</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <template>
                                <tbody v-if="ajaxCallInProgress" :key="order._id">
                                    <tr>
                                        <td colspan="8" class="text-center">
                                            <div class="digi-table-loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else-if="order.order_items_count <= 0" :key="order._id">
                                    <tr>
                                        <td colspan="8" class="text-center">
                                            <img
                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no_data_view_order.svg" />
                                            <br />
                                            <span class="text-secondary font-600" style="font-size:18px!important;">
                                                All Items are Deleted or Moved
                                                <br />to Another Table
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else :key="order._id">
                                    <template v-for="(course,cindex) in order.order_items">
                                        <tr class="horizontal-line" style="height: 40px;" :key="'A'+ cindex">
                                            <td style="padding:0.2rem 1.2rem;" class="font-600 text-left">
                                                <span style="color:#ef6d17">Course {{course.course}}</span>
                                            </td>
                                            <td>
                                                <span class="float-left"> 
                                                    <el-switch style="display: block" class="orderPrepared" @change="ToggleCourses(course.isPrepared,order._id, course.course)" v-model="course.isPrepared">
                                                    </el-switch>
                                                </span>
                                            </td>
                                            <td class="text-right; font-600 " style="padding:0.2rem 1.2rem;">
                                                <span class="ml-auto">Total: {{course.no_of_items}}</span>
                                            </td>
                                        </tr>
                                        <template v-for="(itemDetails,tindex) in course.item_details">
                                            <tr :key="'B'+ tindex" >
                                                <td class="text-left">
                                                    <span style="background-color:#ebeff8;z-index:2">
                                                        <span class="circle-class">{{itemDetails.selected_quantity}}</span>{{itemDetails.product_name}}
                                                    </span>
                                                    <span class="ml-2" v-if="itemDetails.item_note !=''">
                                                        <el-tooltip :content="itemDetails.item_note" placement="top">
                                                            <span> <img class="mr-4 mb-1" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/chef-notes.svg" ></span>
                                                        </el-tooltip>
                                                    </span> 
                                                    <el-tooltip v-if="itemDetails.product_type == 'Non-Veg'"
                                                        content="Non-Veg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg"
                                                            style="margin-top: -4px;width: 11px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="itemDetails.product_type == 'Veg'"
                                                        content="Veg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg"
                                                            style="margin-top: -4px;width: 11px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="itemDetails.product_type == 'Egg'"
                                                        content="Egg" placement="top">
                                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg"
                                                            style="margin-top: -4px;width: 11px;" />
                                                    </el-tooltip>
                                                    <el-tooltip v-else-if="itemDetails.product_type == 'Pescatarian'"
                                                        content="Pescatarian" placement="top">
                                                        <img style="width:11px;margin-top: -4px;"
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme//https://cdn.digicollect.com/cdn/posv2/psec.svg" />
                                                    </el-tooltip>
                                                </td>
                                                <td class="font-600" style="color: #303031;" v-if="itemDetails.isHold">
                                                    <span class="float-left">
                                                        <el-tooltip content="On Hold" effect="dark" placement="top">
                                                            <span class="ml-3">
                                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/holdIcon.svg"   style="width: 19px;height: 30px!important; margin-left: -3px;" />
                                                            </span>
                                                        </el-tooltip>
                                                    </span>
                                                </td>
                                                <td v-if="itemDetails.unAvailable">
                                                    <span class="float-left">
                                                        <el-tooltip class="item" effect="dark" content="Item Unavailable" placement="top-start">
                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/item_unavailable.svg"  style="width: 34px!important;height: 19px!important;" />
                                                        </el-tooltip>
                                                    </span>
                                                </td>
                                                <td v-else>
                                                    <span class="float-left"> 
                                                        <el-switch style="display: block" class="orderPrepared" @change="itemPreparded(itemDetails,order._id)" v-model="itemDetails.isPrepared" :disabled="!itemDetails.isFired || itemDetails.isDelivered || itemDetails.unAvailable || itemDetails.isHold">
                                                        </el-switch>
                                                    </span>
                                                </td>    
                                                <td class="text-center" v-if="order.display_status.items">
                                                    <div class="view-vizard-small d-flex"  v-if="itemDetails.item_status">
                                                        <div class="stepwizard">
                                                            <div class="stepwizard-row setup-panel">
                                                                <div class="stepwizard-step">
                                                                    <div class="wizardSteps pointer"
                                                                        :class="{ 'greenCircle': itemDetails.isFired,'redCircle':!itemDetails.isFired}">
                                                                        <a class="btn-circle" :class="{ 'greenCircle':itemDetails.isFired,'redCircle':!itemDetails.isFired}">S</a>
                                                                    </div>
                                                                </div>
                                                                <div class="stepwizard-step"
                                                                    style="left:25% !important;">
                                                                    <div class="wizardSteps pointer"
                                                                        :class="{ 'greenCircle': itemDetails.isPrepared,'redCircle':!itemDetails.isPrepared}">
                                                                        <a class="btn-circle" :class="{ 'greenCircle': itemDetails.isPrepared,'redCircle':!itemDetails.isPrepared}">F</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="text-left" :key="'B1'+ tindex">
                                                <td colspan="4">
                                                    <div class="text-blue fs-14 font-600 pt-1 pb-1">
                                                        <span>{{itemDetails.variant_name || ""}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <template v-if="itemDetails.modifiers">
                                            <tr class="text-left" v-if="itemDetails.modifiers.hasOwnProperty('for_secondary') || itemDetails.modifiers.hasOwnProperty('for_primary')" :key="'B1'+ tindex">
                                                <td colspan="4">
                                                    <div class="d-block w-100 pb-1" v-for="(modifier,mindex) in itemDetails.modifiers.for_secondary"  :key="'C1'+ mindex">
                                                        <!-- <div>
                                                            <span class="fw-700 text-secondary fs-14 fw-600">{{modifier.m_name}}</span> 
                                                        </div> -->
                                                        <div>
                                                            <span class="fw-700">{{modifier.m_item_name}}</span> 
                                                            <span class="text-green ml-1 fw-700" v-if="modifier.quantity > 0">X
                                                                {{modifier.quantity}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="d-block w-100 py-1" v-for="(modifier,mindex) in itemDetails.modifiers.for_primary"  :key="'C'+ mindex">
                                                        <div class="text-blue font-600">
                                                            <span>{{modifier.modifier_name}}</span>
                                                        </div>
                                                        <div v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                            <div>
                                                                <span class="fw-700">{{modifier_item.m_item_name}}</span> 
                                                                <span v-if="modifier_item.quantity > 0" class="text-green ml-1 fw-700">X
                                                                    {{modifier_item.quantity}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </template>
                                            
                                            <tr v-if="itemDetails.item_note !=''" :key="'B2'+ tindex"
                                                class="font-weight-bold text-left ml-2" style="background-color:#ebeff8">
                                                <td colspan="4">
                                                    <span class="ml-2" >Note: </span>
                                                    <span style="color:red!important;">{{order.order_note}}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </template>
                        </table>
                        <div class="manage-action-swtich prepared-order" v-if="order.showPrepared" style="">
                            <div class="row">
                                <div class="col-md-12 d-flex justify-content-between" v-if="order.showPrepared">
                                    <span class="pr-4 text-white font-600 whiteSpace">Finish Order</span>
                                    <span class="middle-line mb-2"></span>
                                    <span v-if="order.canPrepare" class="float-right pl-4 ml-auto">
                                        <el-switch style="display: block" :disabled="!order.canPrepare"
                                            v-model="order.isPrepared"
                                            @change="prepareOrder(order.isPrepared,order._id)"></el-switch>
                                    </span>
                                    <span v-else class="float-right ml-auto pl-4 mr-auto">
                                        <el-switch style="display: block" v-model="order.isPrepared"
                                            @change="prepareOrder(order.isPrepared,order._id)"></el-switch>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="onholddiv" v-if="order.isOrderOnHold && loggedInUser.role != 'default_order_receiver'">
                        <span class="font-weight-bold">ON HOLD</span>
                    </div>
                </div>
                <div class="cardEdit" v-if="loggedInUser.role != 'default_order_receiver'">
                    <div class="pl-1 pt-1 pb-1" data-toggle="collapse" role="button" :href="'.' + order._id"
                        :aria-controls="order._id" aria-expanded="false">
                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/maximize.svg"
                            :class="[order._id, 'collapse-icon', 'show']" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="digi-table-loader d-flex justify-content-center" v-if="loading" id="spinner"
        style="margin: 0 auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div> -->
    </div>
</template>
<script>
    import {
        EventBus
    } from './eventBus/nav-bar-event.js'
    import ManageOrderService from './mixins/manage_order.js'
    export default {
        mixins: [ManageOrderService],
        props: ['searchdata', 'type'],
        data() {
            return {
                changeCoursesStatus: false,
                orderCardView: [],
                count: 0,
                infinite_scroll_reached_end: false,
                loading: false,
                new_order_type: '',
                temp_showfilterData: {
                    order_type: [],
                    delivery_companies: [],
                    online_companies: [],
                    payment_status: ''
                },
                orderdetailsCourse: '',
                orderDetails: [],
                courseDetails: [],
                ajaxCallInProgress: false,
                orderCourse_display: false,
                orderItem_display: false,
                disable_course: false,
                disable_item: false,
                inProgress: false,
                hold_order: '',
                hold_course: '',
                showPreparedCard: false,
                isPrepared: false,
                isDelivered: false,
                isHold: false,
                isFired: false,
                orderType: null,
                hold_Status: false,
                order: '',
                isPopUp: false,
                order_id: '',
                delete_quantity: '',
                item_id: '',
                product_name: '',
                product_quantity: '',
                DeleteOrderDiv: false,
                temp_order_id: '',
                selectedOrderId: '',
                moveItemsModal: false,
                start_time: '',
                order_id: null,
                orderCourse_display: false,
                orderItem_display: false,
                disable_course: false,
                disable_item: false,
                inProgress: false,
                deletevieworder: false,
                showbillpay: false,
                storeorderId: '',
                orderTableData: true,
                bottom: false,
                // orders:[]
            }
        },
        methods: {
            showViewOrder(orderId) {
                this.$router.push({
                    name: 'ViewOrderChef',
                    params: {
                        id: orderId
                    }
                })
            },
            async ToggleCourses(isprepared, orderId, course){
                try {
                    let params = {
                        order_id: orderId,
                        isPrepared: isprepared,
                        course: course
                    }
                    let res = await this.coursePrepareApi(params)
                    if (res.status_id == 1) {
                        this.$toasted.global.success(res.response.message)
                        // this.handelResponse(res.response.order_details)
                        this.getManagerDetails()
                    }
                } catch (reason) {
                    console.log(reason)
                    this.$toasted.global.error(reason)
                }
            },
            async itemPreparded(item, order_id) {
                try {
                    let params = {
                        isPrepared: item.isPrepared,
                        item_id: item.item_id,
                        course: item.course,
                        order_id: order_id
                    }
                    let res = await this.itemPreparedApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res,params.item_id)
                        this.$toasted.global.success(res.response.message)
                        this.getManagerDetails()
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Order Operations
            async prepareOrder(isprepared, orderId) {
                try {
                    let params = {
                        order_id: orderId,
                        isPrepared: isprepared,
                        get_details: true
                    }
                    let res = await this.orderPrepareApi(params)
                    if (res.status_id == 1) {
                        this.$toasted.global.success(res.response.message)
                        this.handelResponse(res.response.order_details)
                        this.getManagerDetails()
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async courseDelivered(deliverCourse,order_id,courseno) {
                try {
                    let params = {
                        order_id: order_id,
                        isDelivered: deliverCourse,
                        course: courseno
                    }
                    let res = await this.courseDeliverApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async coursePrepare(prepareCourse,order_id,courseno) {
                try {
                    let params = {
                        order_id: order_id,
                        isPrepared: prepareCourse,
                        course: courseno
                    }
                    
                    let res = await this.coursePrepareApi(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.courseDetails.forEach((element, cindex) => {
                        if (element.course == courseno) {
                            this.courseDetails[cindex].isPrepared = false
                        }
                    })
                    this.$toasted.global.error(reason)
                }
            },
            stateMaintain(res) {
                let orderData =res.orders
                orderData.forEach((element, cindex) => {
                    if (element.display_status) {
                        this.disable_course = element.display_status.courses
                        this.disable_item = element.display_status.items
                        this.orderCourse_display = element.display_status.courses
                        this.orderItem_display = element.display_status.items
                    }
                })
            },
            infinite_scroll() {
                let payload = {
                    searchdata: this.searchdata,
                    skip: this.count,
                    limit: 10,
                    order_type: this.temp_showfilterData.order_type,
                    delivery_companies: [...new Set(this.temp_showfilterData.delivery_companies.map(x => x.name))],
                    online_companies: [...new Set(this.temp_showfilterData.online_companies.map(x => x.name))],
                    payment_status: this.temp_showfilterData.payment_status
                }
                this.loading = true
                this.$http.post('/orders/manage_orders', payload).then(res => {
                        let result = res.data.response
                        if (res.data.response.skip == this.count) {
                            let tempFiles = res.data.response.orders
                            
                            tempFiles.forEach(el => {
                                this.orderCardView.push(el)
                            })
                            this.count += res.data.response.orders.length
                            this.loading = false
                        }
                        if (res.data.response.orders.length < 10) {
                            this.infinite_scroll_reached_end = true
                            document.getElementById('spinner').style.display = 'none'
                            this.loading = false
                        }
                        this.maintainOrderState(result)
                        this.$forceUpdate()
                    })
                    .catch(error => {
                        this.loading = false
                    })
            },
            async orderFire(fireOrder) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'ORDER',
                        action: fireOrder,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async courseFire(fireCourse, courseno) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'COURSE',
                        action: fireCourse,
                        course: courseno,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            async itemFire(item) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        target: 'ITEM',
                        action: item.isFired,
                        course: item.course,
                        item_id: item.item_id,
                        get_details: true
                    }
                    let res = await this.fireActiveApiCall(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            // Hold and Unhold For Order,Course and Item
            async holdOrderApiCall(ishold) {
                try {
                    let params = {
                        order_id: this.$route.params.id,
                        isHold: ishold,
                        get_details: true
                    }
                    let res = await this.holdWholeOrder(params)
                    if (res.status_id == 1) {
                        this.maintainOrderState(res)
                        this.$toasted.global.success(res.response.message)
                    }
                } catch (reason) {
                    setTimeout(() => {
                        this.order.isOrderOnHold = false
                    }, 500)
                    this.$toasted.global.error(reason)
                }
            },
            async getmanageorderApi(params) {
                return new Promise(async (resolve, reject) => {
                    try{
                        let response = await this.$http.post('/orders/manage_orders', params)
                        let res = response.data.response
                        if (this.loggedInUser.role == 'default_order_receiver') {
                            this.stateMaintain(res)
                            this.maintainOrderState(res)
                        }
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                        } else {
                            reject(response.data.reason)
                            this.ajaxCallInProgress = false
                            this.$toasted.global.error(response.data.reason)
                        }
                    } catch(error){
                        this.$toasted.global.error(sessionStorage.getItem("error"))
                        this.ajaxCallInProgress = false
                        console.log(error)
                    }
                })
            },
            async getManagerDetails() {
                try {
                    this.ajaxCallInProgress = true
                    let temp_res = {
                        orders: []
                    }
                    EventBus.$emit('UpdateCard', temp_res, this.showfilterData, this.ajaxCallInProgress)
                    let params = {
                        skip: this.count,
                        limit: 10,
                        searchdata: this.searchdata,
                        order_type: this.filterData.order_type,
                        delivery_companies: this.filterData.delivery_companies,
                        online_companies: this.filterData.online_companies,
                        payment_status: this.filterData.payment_status,
                        category_list: this.filterData.categories
                    }
                    let res = await this.getmanageorderApi(params)
                    this.ajaxCallInProgress = false;
                    EventBus.$emit('UpdateCard', res.response, this.showfilterData, this.ajaxCallInProgress)
                } catch (reason) {
                    this.ajaxCallInProgress = false;
                    this.noSearchResult = false
                }
            },
            // async getManageDetails() {
            //     this.ajaxCallInProgress = true
            //     let res = await this.getmanageorderApi(params)
            //     this.stateMaintain(res)
            //     this.maintainOrderState(res)
            //     this.ajaxCallInProgress = false
            // },
            scrollEnd(e) {
                const {target} = e;
                if (Math.ceil(target.scrollTop) >= (target.scrollHeight - target.offsetHeight - 150)) {
                    //this code will run when the user scrolls to the bottom of this div so
                    //you could do an api call here to implement lazy loading
                    console.log('scroll end');
                    this.infinite_scroll();
                    this.bottom = true;

                }
            },
            // infinteScroll() {
            //     var scrollTop = document.getElementById('lazy-load-orders').scrollTop
            //     var scrollHeight = document.getElementById('lazy-load-orders').scrollHeight
            //     var offsetHeight = document.getElementById('lazy-load-orders').offsetHeight
            //     var clientHeight = document.getElementById('lazy-load-orders').clientHeight
            //     var contentHeight = scrollHeight - offsetHeight
            //     if (contentHeight == scrollTop) {
            //         if (!this.infinite_scroll_reached_end) {
            //             this.infinite_scroll()
            //         }
            //     }
            // },
            handelResponse(details) {
                this.orderCardView.forEach((el, index) => {
                    if (el._id == details._id) {
                        el = details
                    }
                })
            },
            maintainOrderState(res,item_id) {
                this.orderDetails = res.orders
                if (this.orderDetails == '' || this.orderDetails == undefined || this.orderDetails == null) {
                    if(res.response) {
                        if(res.response.hasOwnProperty('course_details')) {
                            this.courseDetails = res.response.course_details || []
                            for (var course_index in this.courseDetails) {
                                for (var item_index in this.courseDetails[course_index].item_details) {
                                    if(this.courseDetails[course_index].item_details[item_index].item_id == item_id){
                                    
                                        if (this.courseDetails[course_index].item_details[item_index].item_status == 'prepared') { 
                                            this.courseDetails[course_index].item_details[item_index].preparedCount++
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = true
                                            this.courseDetails[course_index].item_details[item_index].isHold = false
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                            this.courseDetails[course_index].item_details[item_index].isFired = true
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                        } else if (this.courseDetails[course_index].item_details[item_index].item_status ==
                                            'placed') { 
                                            this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                            this.courseDetails[course_index].item_details[item_index].isHold = false
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                            this.courseDetails[course_index].item_details[item_index].isFired = false
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                        } else if (
                                            this.courseDetails[course_index].item_details[item_index]
                                            .item_status == 'preparing'
                                        ) { 
                                            this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                            this.courseDetails[course_index].item_details[item_index].isHold = false
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                            this.courseDetails[course_index].item_details[item_index].isFired = true
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                        } else if (
                                            this.orderDetails[course_index].item_details[item_index].item_status == 'delivered'
                                        ) {
                                            this.courseDetails[course_index].item_details[item_index ].isDelivered = true
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = true
                                            this.courseDetails[course_index].item_details[item_index].isHold = false
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                            this.courseDetails[course_index].item_details[item_index].isFired = true
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = true
                                        } else if (
                                            this.orderDetails[course_index].item_details[item_index].item_status == 'hold'
                                        ) {
                                            this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                            this.courseDetails[course_index].item_details[item_index].isHold = true
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                            this.courseDetails[course_index].item_details[item_index].isFired = true
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                        } else if (
                                            this.orderDetails[course_index].item_details[item_index].item_status == 'unavailable'
                                        ) {
                                            this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                            this.courseDetails[course_index].item_details[item_index].isHold = false
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = true
                                            this.courseDetails[course_index].item_details[item_index].isFired = true
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                        } else {
                                            this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                            this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                            this.courseDetails[course_index].item_details[item_index].isHold = false
                                            this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                            this.courseDetails[course_index].item_details[item_index].isFired = false
                                            this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // this.orderDetails.forEach((element, orindex) => {
                    //     this.courseDetails = element.order_items
                    // })
                    this.orderDetails.forEach((element, cdindex) => {
                        element.order_items.forEach((order_item, tiindex) => {
                            order_item.item_details.forEach((item_details, otindex) => {
                                if (item_details.item_status == 'prepared') {
                                    item_details.preparedCount++
                                    item_details.isPrepared = true
                                    item_details.isHold = false
                                    item_details.unAvailable = false
                                    item_details.isFired = true
                                    item_details.isDelivered = false
                                } else if (item_details.item_status == 'placed') {
                                    item_details.isDelivered = false
                                    item_details.isPrepared = false
                                    item_details.isHold = false
                                    item_details.unAvailable = false
                                    item_details.isFired = false
                                    item_details.isDelivered = false
                                } else if (item_details.item_status == 'preparing') {
                                    item_details.isDelivered = false
                                    item_details.isPrepared = false
                                    item_details.isHold = false
                                    item_details.unAvailable = false
                                    item_details.isFired = true
                                    item_details.isDelivered = false
                                } else if (item_details.item_status == 'delivered' ) {
                                    item_details.isDelivered = true
                                    item_details.isPrepared = true
                                    item_details.isHold = false
                                    item_details.unAvailable = false
                                    item_details.isFired = true
                                } else if ( item_details.item_status == 'hold' ) {
                                    item_details.isPrepared = false
                                    item_details.isHold = true
                                    item_details.unAvailable = false
                                    item_details.isFired = true
                                } else if ( item_details.item_status == 'unavailable' ) {
                                    item_details.isDelivered = false
                                    item_details.isPrepared = false
                                    item_details.isHold = false
                                    item_details.unAvailable = true
                                    item_details.isFired = true
                                } else {
                                    item_details.isDelivered = false
                                    item_details.isPrepared = false
                                    item_details.isHold = false
                                    item_details.unAvailable = false
                                    item_details.isFired = false
                                }
                                this.$forceUpdate();
                            })
                        })
                    })
                }
                this.$forceUpdate();
            },
        },
        mounted() {
            this.getManagerDetails()
            // document.getElementById('lazy-load-orders').addEventListener('scroll', this.infinteScroll, false)
            EventBus.$on('UpdateCard', (res, details, call) => {
                console.log(res, details)
                this.ajaxCallInProgress = false
                this.orderCardView = res.orders
                this.count = res.orders.length
                this.temp_showfilterData = details
                this.ajaxCallInProgress = call
            })
            EventBus.$on("update_order",() => {
                this.getManagerDetails()
            })
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }
        }
    }
</script>
<style scoped>
    #lazy-load-orders {
        height: 690px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 30px;
        -ms-overflow-style: none;
        /* IE 11 */
        scrollbar-width: none;
        /* Firefox 64 */
    }

    #lazy-load-orders::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    .task.card {
        /* max-height: 340px!important; */
        /* overflow-y:scroll; */
        background-color: #ffffff !important;
        border-top: solid 3px #00448b;
        border-radius: 5px 5px 0 0 !important;
        border-left: none !important;
        border-right: none !important;
        border-bottom: none !important;
        margin-bottom: 1.3em;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .card-title {
        margin-top: 0rem !important;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        background-color: #f6f6f6;
        color: inherit;
        margin-bottom: 0 !important;
    }

    .taskDropdown {
        -webkit-box-shadow: none !important;
        border: none !important;
        background-color: #f6f6f6;
        border-bottom: solid 1px #e0e3e6 !important;
        border-radius: 0px 0px 5px 5px;
    }

    .task.card {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .taskDropdown.card-body {
        border-left: solid 1px #e0e3e6 !important;
        border-right: solid 1px #e0e3e6 !important;
    }

    .task-title.card-title {
        border-left: solid 1px #e0e3e6 !important;
        border-right: solid 1px #e0e3e6 !important;
    }

    .taskToggle.dropdown-toggle::after {
        display: none;
    }

    .collapsed.dropdown-toggle::after {
        float: right !important;
        margin-right: 12px !important;
        margin-top: 11px !important;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    .cardEdit {
        width: 30px;
        height: 30px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border: solid 1px #cdd2da !important;
        cursor: pointer;
    }

    .borderBottom {
        border-bottom: solid 1px #cdd2da !important;
    }

    @media all and (max-width: 1029px) {
        .cardEdit {
            display: none;
        }
    }

    @media all and (max-width: 767px) {
        .cardEdit {
            display: block;
        }
    }

    #ribbon-container {
        position: relative;
        left: -27px;
        overflow: visible;
        font-size: 17px;
        line-height: 0px;
    }

    #ribbon-container:before {
        content: '';
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        right: 0;
    }

    #ribbon-container:after {
        content: '';
        background: rgba(0, 0, 0, 0.3);
        display: block;
        position: absolute;
        bottom: -3px;
        right: 58px;
        left: 3px;
    }

    #ribbon-container a {
        display: block;
        padding: 12px;
        position: relative;
        background: #f5a623;
        overflow: visible;
        height: 18px;
        color: #303031;
        text-decoration: none;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
    }

    #ribbon-container a:after {
        content: '';
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        bottom: -15px;
        left: 0;
        border-top: 15px solid #c4851e;
        border-left: 15px solid transparent;
    }

    #ribbon-container a:before {
        content: '';
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        top: 0;
        right: -29px;
    }

    .orderTime {
        position: relative;
        color: #989595;
    }
    .stepwizard{
        display: table;
        width: 80% !important;
        position: relative;
    }
    .stepwizard-row:before {
        left: 15% !important;
        width: 69% !important;
    }
    .prepare-row {
        left: 15% !important;
        width: 69% !important;
        border:1px solid #fff
    }

    .progressRow::before {
        background: #1a9347 !important;
    }

    .preparedItems.dropdown-toggle::after {
        float: right !important;
        margin-right: 12px !important;
        margin-top: 11px !important;
    }

    .stepwizard-step {
        left: 7% !important;
    }

    .closedWizard {
        background-color: #1a9347;
        border: solid 1px #1a9347;
    }

    .whiteSpace {
        white-space: nowrap;
    }

    .holdTag {
        background: #e82828;
        color: #fff;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        padding: 0px 8px;
        font-weight: 600;
    }

    .btn-circle {
        line-height: 2.7 !important;
    }

    .greenCircle {
        background: #1a9347 !important;
        color: #fff !important;
    }

    .redCircle {
        background: #e82828 !important;
        color: #fff !important;
    }

    .orangeCircle {
        background: #f5a623 !important;
        color: #fff !important;
    }

    .wizardSteps {
        border: none !important;
    }

    .collapse-icon.collapse {
        display: block !important;
    }

    .collapse-icon.show {
        display: block !important;
        transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
    }

    .collapse-icon {
        width: 14px;
        margin-left: 3px;
        margin-top: 4px;
    }

    .collapsed .collapse-icon {
        display: block !important;
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    .collapse-icon.collapsing {
        position: relative;
        height: 0px !important;
        overflow: visible;
        -webkit-transition: height 0.35s ease !important;
        -o-transition: height 0.35s ease !important;
        transition: height 0.35s ease !important;
    }

    .onholddiv {
        position: absolute;
        width: 100%;
        text-align: center;
        height: 40px;
        background-color: #606266;
        opacity: 1;
        top: 40%;
        right: 0px;
        z-index: 999 !important;
        pointer-events: all !important;
        line-height: 2;
    }
    .onholddiv span {
        color: #fff !important;
        font-size: 16px !important;
    }

    .OnHoldClass {
        opacity: 0.7 !important;
        pointer-events: none !important;
    }

    .circle-class {
        background-color: #4e5764;
        border-radius: 50%;
        color: #fff;
        padding: 3px 7px 3px 7px;
        margin-right: 5px
    }
    .table-line {
        border-left: 1px solid #e0e3e6 !important;
        border-right: 1px solid #e0e3e6 !important;
        border-bottom: 1px solid #e0e3e6 !important;
    }
    .prepared-order {
        border-radius: 5px;
        background-image: linear-gradient(284deg, #00448b, #0367cc);
        margin-top: 16px;
        margin-bottom:10px;
        padding: 10px;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
    .line {
        left: 15% !important;
        width: 69% !important;
    }
    .horizontal-line {
        border-bottom: 1px solid #e0e3e6 !important;
        border-bottom-color:rgba(239, 109, 23, 0.35)
    }
    .middle-line{
        width: 100%;
        border-bottom: 1px dashed #fff;
    }
    .card-manage-order {
        -webkit-columns: 150px;
        -moz-columns: 150px;
        columns: 390px;
        -webkit-column-gap: 2em;
        -moz-column-gap: 2em;
        column-gap: 2em; 
    }
    .card-title.task-title{
        width: 100% !important;
    }
    .table-line{
        width: 100% !important;
    }
</style>