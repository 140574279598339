<template>
    <div class="page-layout simple right-sidebar inner-sidebar">
        <div class="pt-4 pb-3 row no-gutters align-items-center justify-content-between manage-order-header">
            <div v-if="loggedInUser.role == 'default_order_receiver'" class="col-md-12 pb-3" style="border-bottom: 2px solid #00448b;">
                <div class="text-center chef-manager-header">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/orders.svg" style="width: 21px!important; padding-right: 2px; margin-top: -3px;"/>
                    Manage Order
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between pt-4 mr-6 align-items-center" :class="{'mt-12':loggedInUser.role == 'default_order_receiver','mt-3':loggedInUser.role != 'default_order_receiver'}">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="m-input-icon m-input-icon--left m-input-icon--right ml-7 mt-1" style="width:100%">
                    <input
                        type="text"
                        class="form-control m-input m-input--pill"
                        placeholder="Find Order by Customer Name, Table Name,  Customer Phone Number or Order ID"
                        autocomplete="off"
                        v-model="searchdata"
                        v-debounce:200ms="searchManageOrderList" debounce-events="input"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                            <i class="icon icon-magnify"></i>
                        </span>
                    </span>
                    <span class="m-input-icon__icon m-input-icon__icon--right">
                        <span>
                            <i
                                class="icon icon-close-circle"
                                style="padding-top: 1px;!important;font-size:20px !important;color:rgba(0,0,0,0.54) !important;cursor:pointer !important;"
                                v-if="searchdata.length"
                                @click="searchdata='';searchManageOrderList();"
                            ></i>
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <button @click="showFilters()" class="btn btn-secondary btn-smm pull-right mt-2 ml-2">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/scm-new-theme/filter.svg">
                    Filter
                </button>
                <div v-if="loggedInUser.role == 'default_order_receiver'" class="col-lg-4 col-md-4 col-sm-4 d-flex justify-content-center ml-2 mt-1 pull-right" style="background: #fff; border-radius: 20px; width: 10em; height: 2.9em;">
                    <button class="btn d-flex btn-design">
                        <span class="d-flex justify-content-center align-items-center pointer mr-2"
                            style="width: 50%;" @click="orderToShow='grid'" value="grid">
                            <!-- <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/chef-grid.svg" width="21"> -->
                            <img src="https://cdn.digicollect.com/cdn/posv2/public/images/images-new-theme/chef-grid-active1.svg" v-if="orderToShow=='grid'" width="21"/>
                            <img src="https://cdn.digicollect.com/cdn/posv2/public/images/images-new-theme/chef-grid-inactive1.svg" v-else-if="orderToShow=='list'" width="21"/>
                        </span>
                            <span class="d-flex justify-content-center align-items-center pointer ml-2"
                            style="width: 20%;" @click="orderToShow='list'" value="list">
                            <!-- <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/chef-bars.svg" width="38"> -->
                            <img src="https://cdn.digicollect.com/cdn/posv2/public/images/images-new-theme/chef-bars-active1.svg" v-if="orderToShow=='list'" width="38"/>
                            <img src="https://cdn.digicollect.com/cdn/posv2/public/images/images-new-theme/chef-bars-inactive1.svg" v-else-if="orderToShow=='grid'" width="38"/>
                        </span>
                    </button>
                </div>

            </div>
        </div>
        <div>
            <div class="col-md-12 text-right pr-11 pt-5 mb-2" v-if="loggedInUser.is_cashier && loggedInUser.cashier_session">
                <button type="button" class="btn btn-secondary btn-smm" style="cursor: auto !important;">Open Bills : {{ bills.open }}</button>
                <button type="button" class="btn btn-secondary btn-smm ml-2" style="cursor: auto !important;">Closed Bills : {{ bills.closed }}</button>
            </div>
            <div :class="{'mt-5':loggedInUser.role != 'default_order_receiver','mt-5':loggedInUser.role == 'default_order_receiver'}">
                <div class="filterSelectedChips mb-3 ml-8 mr-10" v-if="showfilterData.order_type.length || showfilterData.delivery_companies.length || showfilterData.online_companies.length || showfilterData.payment_status || showfilterData.categories.length">
                    <div class="pull-right pointer resetChips" @click="removeAllChips()"><i class="icon icon-close text-white fs-20"></i></div>
                    <div class="d-flex align-items-center flex-wrap">
                        <span class="font-weight-bold text-white p-2 mt-2" v-if="showfilterData.order_type.length || showfilterData.delivery_companies.length || showfilterData.online_companies.length">Order Type:</span>
                        <span v-for="(order,index) in showfilterData.order_type" :key="index" class="mt-2">
                            <span class="filterChips" 
                            v-if="order == 'Deliveries' && showfilterData.delivery_companies.length == 0
                            || order == 'Online' && showfilterData.online_companies.length == 0
                            || order == 'Dine In' || order == 'Take Away'"
                            >
                                {{order}}
                                <i class="icon icon-close fs-12" @click="removeChips(order,'order')"></i>
                            </span>
                        </span>
                        <span class="mt-2" v-for="delivery in showfilterData.delivery_companies" :key="delivery._id">
                            <span class="filterChips" >
                                Deliveries | {{delivery.name}}
                                <i class="icon icon-close fs-12" @click="removeChips(delivery,'delivery')"></i>
                            </span>
                        </span>
                        <span v-for="online in showfilterData.online_companies" :key="online._id" class="mt-2">
                            <span class="filterChips" >
                                Online | {{online.name}}
                                <i class="icon icon-close fs-12" @click="removeChips(online,'online')"></i>
                            </span>
                        </span>
                        <span class="font-weight-bold text-white p-2 mt-2" v-if="showfilterData.payment_status">Order Status:</span>
                        <span class="filterChips mt-2" v-if="showfilterData.payment_status">
                            {{showfilterData.payment_status}}
                            <i class="icon icon-close fs-12" @click="removeChips(showfilterData.payment_status,'pay')"></i>
                        </span>
                        <span class="font-weight-bold text-white p-2 mt-2" v-show="showfilterData.categories.length">Categories:</span>
                        <span class="mt-2" v-for="category in showfilterData.categories" :key="category.category_id">
                            <span class="filterChips" >
                                {{category.name}}
                                <i class="icon icon-close fs-12" @click="removeChips(category,'category')"></i>
                            </span>
                        </span>
                    </div>
                </div>
                <div v-if="loggedInUser.role == 'default_manager' || loggedInUser.role == 'default_order_taker' || loggedInUser.role == 'default_admin'">
                    <manager-order-Card :searchdata="searchdata"></manager-order-Card>
                </div>
                <div v-else-if="loggedInUser.role == 'default_order_receiver'">
                    <div class="col-md-12 text-right pr-11">
                        <chef-order-card modal_name="chef-order-card" :searchdata="searchdata" v-if="orderToShow =='grid'"></chef-order-card>
                        <manage-order-list modal_name="manger-order-list" :searchdata="searchdata" v-if="orderToShow =='list'"></manage-order-list>
                    </div>
                </div>

            </div>
        </div>
        <div v-show="display_filters" class="scm-filters fadeInDown" style="bottom: auto;" :class="{'fadeInUp':hide_filters == true}">
            <filters :showfilterData="showfilterData" @hideFilters="hideFilters" @UpdateByFilter="UpdateByFilter"></filters>
        </div>
    </div>
</template>
<script>
const ManagerOrderCard = () => import('./ManagerOrderCard')
import ChefOrderCard from './ChefOrderCard'
import ManageOrderList from './ManageOrderList'
import { EventBus } from './eventBus/nav-bar-event.js'
import ManageOrderService from './mixins/manage_order.js'
import Filters from './Filters'
export default {
    data() {
        return {
            table_id: '',
            all_tables: [],
            searchdata: '',
            display_filters: false,
            hide_filters: false,
            orderToShow: "grid",
        }
    },
    methods: {
        // async infinite_scroll(e){
        //     const {target} = e;
        //     if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
        //         //this code will run when the user scrolls to the bottom of this div so
        //         //you could do an api call here to implement lazy loading
        //         try {
        //             this.loading = true
        //             let params ={
        //                 filter_json: {},
        //                 skip: this.count1,
        //                 limit: 10,
        //                 search_key:this.search_key
        //             }
        //             let res = await this.$http.post(this.contactsToShow == "My Contacts" ? "/contacts/list_view" : "/contacts/shared_contacts_list", params);
        //             if (res.data.status_id == 1) {
        //                 if (res.data.skip == this.count1) {
        //                     let tempFiles = res.data.response
        //                     tempFiles.forEach(el => {
        //                         this.contacts.push(el)
        //                     })
        //                     this.count1 += res.data.response.length;
        //                     this.loading = false
        //                 }
        //                 if (res.data.response.length < 10) {
        //                     document.getElementById('spinner').style.display = 'none'
        //                     this.loading = false
        //                 }
        //             }
        //         } catch (reason) {

        //         }
        //         this.bottom = true;

        //     }
        // },
        showFilters(){
            if(this.display_filters){
                this.hide_filters = true
                setTimeout(() => {
                    this.display_filters = false
                    this.hide_filters = false
                }, 500);
            }
            else{
                this.display_filters = true
            }
        },
        hideFilters(){
            this.hide_filters = true
            setTimeout(() => {
                this.display_filters = false
                this.hide_filters = false
            }, 500);
        },
        searchManageOrderList() {
            this.count = 0
            this.getDetails();
        },
        UpdateByFilter(params){
            console.log(params)
            this.count = 0
            this.showfilterData = {
                order_type:params.value1.order_type,
                delivery_companies:params.value1.delivery_companies,
                online_companies:params.value1.online_companies,
                payment_status:params.value1.payment_status,
                categories:params.value1.categories,
            }
            this.filterData = {
                order_type:params.value.order_type,
                delivery_companies:params.value.delivery_companies,
                online_companies:params.value.online_companies,
                payment_status:params.value.payment_status,
                categories:params.value.categories,
            }
            localStorage.setItem('manager_order_filter_data', JSON.stringify({filterData: this.filterData,showfilterData:this.showfilterData}))
            this.getDetails();
        },
        removeAllChips(){
            this.showfilterData = {
                order_type:[],
                delivery_companies:[],
                online_companies:[],
                payment_status:'',
                categories: []
            },
            this.filterData = {
                order_type:[],
                delivery_companies:[],
                online_companies:[],
                payment_status:'',
                categories: []
            }
            EventBus.$emit('removeAllChips')
        },
        removeChips(value,key){
            let params = {
                value:value,
                key:key
            }
            EventBus.$emit('removechipFromManage',params)
        },
        scroll() {
            window.onscroll = () => {
                let bottomOfWindow =
                Math.max(window.pageYOffset,document.documentElement.scrollTop,document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                if (bottomOfWindow) {
                    // TODO:
                }
            }
        },
    },
    mixins: [ManageOrderService],
    components: {
        ManagerOrderCard,
        ChefOrderCard,
        Filters,
        ManageOrderList
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser
        },
        bills() {
            return this.$store.state.bills
        }
    },
    mounted() {
        if(localStorage.getItem("manager_order_filter_data")) {
            let filterBothData = JSON.parse(localStorage.getItem('manager_order_filter_data'))
            if(!filterBothData.hasOwnProperty('filterData')) {
                localStorage.removeItem('manager_order_filter_data')
            }
            this.filterData = filterBothData.filterData
            this.showfilterData = filterBothData.showfilterData
        }
        this.scroll()
        EventBus.$emit('customerdetailsLoad', false)
        EventBus.$emit('settingsLoad', false)
        EventBus.$emit('reportsLoad', false)
    },
}
</script>
<style>
input::placeholder {
    color: #b4b4b4 !important;
    font-size: 14px;
}

.dropdown-filter-chef {
    width: 334px;
    position: absolute;
    background: #fff;
    border: 1px solid #c0c4cc;
    border-radius: 7px;
    left: -170px;
    right: 30px;
    display: none;
    padding: 14px;
    top: 40px;
}
.dropdown-filter-chef.filter_open_show {
    display: block !important;
}
.filter-heading-chef {
    color: #00448b !important;
    font-weight: 600;
    font-size: 14px !important;
}
.chef-manager-header {
    color: #00448b !important;
    font-weight: 600 !important;
}
.swal2-popup .swal2-input.input-limit {
    height: 2.625em;
    padding: 0 0.75em;
    width: 40% !important;
}
.text-swal-delete {
    color: #707070;
    font-weight: 600;
}
.manage-order-header {
    position: fixed !important;
    background-color: #fff !important;
    width: 100%;
    z-index: 999 !important;
}
.searchProducts {
    width: 100%;
    height: 34px;
    border-radius: 100px;
    border: solid 1px #d5d5d5 !important;
    padding-left: 35px;
    padding-right: 35px;
    background-image: linear-gradient(
        to bottom,
        #efefef,
        #ffffff 25%,
        #ffffff 48%,
        #ffffff 78%,
        #efefef
    );
}
.searchIcon {
    color: #cfcfcf !important;
    position: absolute;
    pointer-events: cursor;
    top: 22px;
    font-size: 19px;
    left: 15px;
    display: inline-block;
}
.closeIcon {
    color: #cdd2da !important;
    position: absolute;
    pointer-events: cursor;
    top: 22px;
    font-size: 19px;
    right: 10px;
    display: inline-block;
}
.orderPrepared .el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #94bef0 !important;
    border-radius: 15px !important;
}
.orderPrepared .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #46d830;
}
.moreInfoOrder {
    position: relative;
}
.orderDelivered {
    position: relative;
    vertical-align: text-bottom;
}
.updateQuantity.dropdown-toggle::after {
    display: none !important;
}
.quantityDropdown {
    position: absolute;
    top: 0px;
    left: 0px;
    will-change: transform;
    transform: translate3d(15px, 5px, 0px) !important;
}
.stepwizard-step p {
    margin-top: 0px;
    color: #94bef0;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 100%;
    position: relative;
}
.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
    opacity: 1 !important;
    color: #bbb;
}
.stepwizard-row:before {
    top: 16px;
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 2px;
    background-color: #ccc;
    z-index: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
    left: 11%;
}
.btn-circle {
    width: 30px;
    height: 30px;
    color: #94bef0 !important;
    text-align: center;
    display: initial;
    padding: 6px 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 2.5;
    border-radius: 15px;
    text-decoration: none;
}
.completedWizard .btn-circle {
    color: #fff !important;
}
.activeWizard {
    background-color: #1a9347;
}
.activeCircle {
    color: #ffff !important;
}
.icon.icon-clock {
    font-size: 16px;
}
.completedWizard {
    background-color: #00448b;
    border: 1px solid #00448b !important;
}
.completedCircle {
    color: #ffff !important;
    font-weight: 600;
    font-size: 13px;
}
.closedWizard {
    background-color: #00448b;
}
.closedCircle {
    color: #ffff !important;
}
.wizardSteps {
    border: solid 2px #94bef0;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #f6f6f6;
}
.activeWizard {
    background-color: #1a9347;
    border: solid 1px #1a9347;
}
.activeCircle {
    color: #1a9347 !important;
}
.completedWizard {
    background-color: #00448b;
}
.completedCircle {
    color: #ffff !important;
}
.closedWizard {
    background-color: #00448b;
    border: solid 1px #00448b;
}
.deliveredCircle {
    color: #00448b !important;
}
.manageViews {
    overflow-y: hidden;
    margin-top: 6px;
}
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .sidebar.manage-open {
    padding-left: 10px;
    background-color: #ffffff;
}
.fuse-sidebar.manage-open {
    position: relative !important;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
}
.preview {
    width: 100px;
    margin: 0 1px 8px 0;
    z-index: 0 !important;
}
.description {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.toggle-source-preview {
    cursor: pointer;
    font-size: 12px;
    padding-left: 16px;
    color: #00448b;
}
.page-layout.simple {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    min-width: 100%;
}
.page-layout.simple.right-sidebar.inner-sidebar {
    flex-direction: column;
    overflow-x: hidden;
    height: 100%;
}
.content-section {
    display: flex;
    min-height: 100%;
}

.fuse-sidebar {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    top: 0;
    position: relative;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 300px;
    min-width: 350px;
    max-width: 350px;
    z-index: 1000;
}
.fuse-sidebar.left-positioned {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.page-layout.simple.right-sidebar.inner-sidebar .content-section .sidebar {
    order: 2;
}

.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .center-section {
    order: 1;
}
.page-layout.simple.left-sidebar.inner-sidebar .content-section .center-section,
.page-layout.simple.right-sidebar.inner-sidebar
  .content-section
  .center-section {
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
}
.manageOptions.v-select.single.searchable .dropdown-toggle {
    height: 32px;
    margin-right: 10px;
}
.manageOptions.v-select.single .selected-tag{
    max-width: 180px !important;
}
.paidBill .el-switch__core {
    border: 1px solid #ff2424 !important;
    background: #ff2424 !important;
}
.whiteSpace {
    white-space: nowrap !important;
}
.btn-design {
    background-color: #fff;
    border: none;
    color: black;
    padding: 10px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 16px;
}
</style>