<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Items</th>
          <th class="text-center">Qty</th>
          <th>Tax</th>
          <!-- <th>Discount</th> -->
          <th style="text-align:right;">Price</th>
          <th style="width:10%;" v-if="!isSummary"></th>
        </tr>
      </thead>
      <tbody v-for="(vendor, vendorIndex) in order.vendors" :key="vendor._id">
        <tr style="height: 13px!important;background-color: #f6f6f6!important;">
          <td colspan="5" style="padding: 0.6rem 1.2rem!important;">
            <strong>{{vendor.name}}</strong>
          </td>
          <td class="pull-right" style="padding: 0.6rem 1.2rem!important;">
            <!-- <el-tooltip content="Delete" placement="top">
              <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .8rem;">
                <i class="icon icon-delete"></i>
              </button>
            </el-tooltip>-->
          </td>
        </tr>
        <tr v-for="(item, itemIndex) in vendor.items" :key="itemIndex">
          <th
            scope="row"
            class="text-secondary"
            v-if="item.variant_id" style="width: 100px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        display: inline-block;"
          >{{item.product_name + '/' + item.variant_name}}</th>
          <th scope="row" class="text-secondary" v-else style="width: 100px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        display: inline-block;"> {{item.product_name}}</th>
          <td class="text-center">
            <span @click="decreaseItemQuantity(vendorIndex, itemIndex)" v-if="!isSummary">
              <img src="https://cdn.digicollect.com/cdn/posv2/minus-blue.svg">
            </span>
            <span class="qty-checkout" v-if="!isSummary">
              <input class="qty-input-checkout" :value="item.quantity" disabled>
            </span>
            <span class="qty-checkout" v-else >
              {{item.quantity.toLocaleString()}}
            </span>
            <span @click="increaseItemQuantity(vendorIndex, itemIndex)" v-if="!isSummary">
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-yellow.svg">
            </span>
          </td>
          <td>{{order.currency.symbol}}{{item.tax.toFixed(2).toLocaleString()}}</td>
          <!-- <td>
            $0.00
            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
              <button
                class="btn btn-icon companyactions actionicons"
                style="padding: 0.4rem .8rem;"
              >
                <i class="icon icon-pencil"></i>
              </button>
            </el-tooltip>
          </td> -->
          <td style="text-align:right;">{{order.currency.symbol}}{{item.total.toFixed(2).toLocaleString()}}</td>
          <td v-if="!isSummary" style="padding: 0 13px!important;">
            <el-tooltip content="Add Note" placement="top">
              <span>
                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-note.svg" @click="AddNoteModal(vendorIndex, itemIndex)">
              </span>
            </el-tooltip>
            <el-tooltip content="Delete" placement="top">
              <button type="button" class="btn btn-icon actionicons" style="padding: 0.4rem .2rem;" @click="removeItem(vendor._id, item)">
                <i class="icon icon-delete"></i>
              </button>
            </el-tooltip>
          </td>
        </tr>
        <tr style="border-top:2px dotted #cdd2da;height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Total Amount (Including Taxes And Discount)</td>
          <td>{{order.currency.symbol}}{{vendor.sub_total.toFixed(2).toLocaleString()}}</td>
          <!-- <td></td> -->
        </tr>
        <tr style="height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Total Tax Amount</td>
          <td>{{order.currency.symbol}}{{vendor.total_tax.toFixed(2).toLocaleString()}}</td>
          <!-- <td></td> -->
        </tr>
        <tr style="height: 40px!important;" class="TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Total Amount (Excluding Taxes)</td>
          <td>{{order.currency.symbol}}{{vendor.total_without_taxes.toFixed(2).toLocaleString()}}</td>
          <!-- <td></td> -->
        </tr>
      </tbody>
      <tbody>
        <tr style="border-top:2px dotted #cdd2da;" class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Overall Total Amount (Including Taxes And Discount)</td>
          <td>{{order.currency.symbol}}{{order.sub_total.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Overall Total Taxes</td>
          <td>{{order.currency.symbol}}{{order.items_total_tax.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Overall Total Amount (Excluding Taxes)</td>
          <td>{{order.currency.symbol}}{{order.items_total_without_tax.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr :class="{'dottedHead': editOrder}" v-if="editOrder" class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Old Grand Total</td>
          <td>{{order.currency.symbol}}{{oldOrder.sub_total.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr :class="{'dottedHead': !editOrder}" class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Tax</td>
          <td>{{order.currency.symbol}}{{order.tax.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Additional Charges</td>
          <td>{{order.currency.symbol}}{{order.additional_amount.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Discount</td>
          <td>{{order.currency.symbol}}{{order.discount_amount.toFixed(2).toLocaleString()}}</td>
          <td></td>
        </tr>
        <tr class="backTaxes TaxDiscountTr" v-if="showRoundOffBox">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3">Round off</td>
          <td>
            <input class="roundoffInputCheckout" type="text" v-model="order.round_off" :disabled= editRoundoff>    
          </td>
          <td>
             <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                  <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 1.3rem;" @click="showRoundOff" v-if="editRoundoff">
                      <i class="icon icon-pencil"></i>
                  </button>
              </el-tooltip>
              <div class="right-close">
                  <el-tooltip class="item" effect="dark" content="Right" placement="top">
                      <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 0.2rem;" v-if="!editRoundoff" @click="rightEditRoundoff()">
                          <i class="icon icon-check"></i>
                      </button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Close" placement="top">
                      <button class="btn btn-icon companyactions actionicons" style="padding: 0.4rem 0.2rem;" v-if="!editRoundoff" @click="CloseRoundOff()" >
                          <i class="icon icon-close" style="color:red!important;"></i>
                      </button>
                  </el-tooltip>
              </div>
          </td>
        </tr>
        <tr class="grandTotalTr">
          <!-- <td></td> -->
          <!-- <td></td> -->
          <!-- <td></td> -->
          <td colspan="3" class="grandTotalText">Grand Total</td>
          <td class="grandValue">{{order.currency.symbol}}{{grandTotal.toLocaleString()}}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div>
      <add-note modal_name="add-note-checkout" :note="note" @addNote="addItemNote" @closeNote="AddNoteModalHide"></add-note>
    </div>
  </div>
</template>
<script>
import AddNote from "./AddNote";
export default {
  data() {
    return {
      note: {
        itemIndex: null,
        vendorIndex: null,
        value: '',
        name: null,
      },
      editRoundoff: true,
      round_off: 0.00
    };
  },
  props: ["order", "apiLock", "isSummary", "showRoundOffBox", "editOrder", "oldOrder"],
  methods: {
    showRoundOff(){
      this.round_off = this.order.round_off;
      this.editRoundoff = !this.editRoundoff;
    },
    CloseRoundOff() {
      this.editRoundoff = !this.editRoundoff;
      this.order.round_off = this.round_off;
    },
    rightEditRoundoff() {
      this.editRoundoff = !this.editRoundoff;
    },
    decreaseItemQuantity(vendorIndex, itemIndex) {
      if (!this.apiLock){
        this.$emit('updateAPILock', true);
        let item = JSON.parse(JSON.stringify(this.order.vendors[vendorIndex].items[itemIndex]));
        item.vendorId = this.order.vendors[vendorIndex]._id;
        item.quantity--;
        this.$emit('updateOrderItem', item);
      } else {
        this.$toasted.global.error('Updating quantity in progress');
      }
    },
    increaseItemQuantity(vendorIndex, itemIndex) {
      if (!this.apiLock){
        this.$emit('updateAPILock', true);
        let item = JSON.parse(JSON.stringify(this.order.vendors[vendorIndex].items[itemIndex]));
        item.vendorId = this.order.vendors[vendorIndex]._id;
        item.quantity++;
        this.$emit('updateOrderItem', item);
      } else {
        this.$toasted.global.error('Updating quantity in progress');
      }
    },
    AddNoteModal(vendorIndex, itemIndex) {
      this.note.itemIndex = itemIndex;
      this.note.vendorIndex = vendorIndex;
      this.note.value = this.order.vendors[vendorIndex].items[itemIndex].note;
      let name = this.order.vendors[vendorIndex].items[itemIndex].product_name;
      if(this.order.vendors[vendorIndex].items[itemIndex].variant_name){
        name += '/' + this.order.vendors[vendorIndex].items[itemIndex].variant_name;
      }
      this.note.name =  name;
      this.$modal.show('add-note-checkout');
    },
    addItemNote(note) {
      let vendorIndex = note.vendorIndex;
      let item = this.order.vendors[vendorIndex].items[note.itemIndex]
      let data = {
        productId: item.product_id,
        variantId: item.variant_id,
        orderId: this.order._id,
        vendorId: this.order.vendors[vendorIndex]._id,
        note: note.value
      }
      this.$http
        .post('/orders/add_item_note', data)
        .then(res => {
          if(res.data.status_id == 1){
            this.$emit('updateCheckout', true);
            this.$modal.hide('add-note-checkout');
          } else {
            this.$toasted.global.error(res.data.reason);
          }
        })
        .catch(err => {
          this.modal_msg = err.data.reason;
          this.$refs.warning_modal.open();
        })
    },
    AddNoteModalHide() {
      this.$modal.hide('add-note-checkout');
    },
    removeItem(vendorId, item) {
      let data = {
        productId: item.product_id,
        variantId: item.variant_id,
        vendorId: vendorId,
        orderId: this.order._id
      }
      this.$http
        .post('/orders/remove_item', data)
        .then(res => {
          if(res.data.status_id == 1){
            this.$emit('updateCheckout', true);
          } else {
            this.$toasted.global.error(res.data.reason);
          }
        })
        .catch(err => {
          this.modal_msg = err.data.reason;
          this.$refs.warning_modal.open();
        })
    }
  },
  computed: {
    grandTotal() {
      if(this.order.round_off != "") return (this.order.grand_total + parseFloat(this.order.round_off)).toFixed(2);
      return this.order.grand_total.toFixed(2);
    }
  },
  components: {
    AddNote
  },
  watch: {}
};
</script>
<style>
.dottedHead{
  border-top:2px dotted #cdd2da;
}
.roundoffInputCheckout{
    text-align:right;
    border: 1px solid #eee!important;
    border-radius: 10px;
}
.roundoffInputCheckout:disabled{
    border: 1px solid #f6f6f6!important;
    background: transparent;
}
.grandTotalTr {
  border-top: 1px solid #00448b;
  border-bottom: 1px solid #00448b;
}
.grandTotalTr td.grandTotalText {
  color: #00448b;
  font-size: 14px;
  font-weight: 800;
  text-align: right;
}
.grandTotalTr td.grandValue {
  color: #4e5764;
  font-weight: 800;
  text-align: right
}
tr.backTaxes {
  background-color: #f6f6f6;
  height: 40px !important;
}
.TaxDiscountTr td {
  color: #4e5764 !important;
  font-weight: 600;
}
.table .TaxDiscountTr td {
  padding: 0.6rem 1.2rem;
  text-align: right;
}
</style>
